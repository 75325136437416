import { type InputVariant } from "@digits-shared/components/UI/Elements/Form/Input"
import { themedStyles } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { css, keyframes } from "styled-components"

export const INPUT_INVALID_CLASS_NAME = "invalid"

export const SHAKE_KEYFRAMES = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(0, 0) rotate(0); }
  `

/*
  STYLES
*/

export const INPUT_BORDER_STYLES = css<{ $variant?: InputVariant }>`
  ${({ $variant }) => {
    switch ($variant) {
      case "bordered":
        return css`
          outline: none;
          border: 1px solid ${colors.secondary30};
          border-radius: 8px;
        `
      case "primary":
      default:
        return css`
          outline: none;
          border: none;
          border-radius: 0;
          border-top: 2px solid ${colors.transparent};

          ${themedStyles({
            light: css`
              border-bottom: 2px solid ${colors.translucentSecondary20};
            `,
            dark: css`
              border-bottom: 2px solid ${colors.translucentWhite10};
            `,
          })};
        `
    }
  }};
`

export const INPUT_BORDER_FOCUS_STYLES = css<{ $variant?: InputVariant }>`
  ${({ $variant }) => {
    switch ($variant) {
      case "bordered":
        return css`
          border: 1px solid ${colors.primary80};
        `
      case "primary":
      default:
        return css`
          box-shadow: none;
          border-radius: 0;
          border: none;
          border-bottom: 2px solid ${colors.transparent};
          border-top: 2px solid ${colors.transparent};

          ${themedStyles({
            light: css`
              border-image-source: linear-gradient(270deg, #42c6ff 1.95%, #01dacd 102.17%);
              border-image-slice: 1;
              border-image-width: 0 0 3px 0;
            `,
            dark: css`
              border-image-source: linear-gradient(
                to right,
                rgb(113, 245, 187) 0px,
                rgb(5, 158, 224) 100%
              );
              border-image-slice: 1;
              border-image-width: 0 0 2px 0;
            `,
          })};
        `
    }
  }};
`

export const INPUT_STYLES = css<{ $variant?: InputVariant }>`
  ${INPUT_BORDER_STYLES};

  ${({ $variant }) => {
    switch ($variant) {
      case "bordered":
        return css`
          color: ${colors.secondary};
          font-family: ${fonts.family.avenir};
          padding: 6px 8px;

          &::placeholder {
            font-family: ${fonts.family.avenir};
            color: #8492b2;
          }

          &::placeholder,
          &[disabled] {
            color: ${colors.translucentSecondary50};
          }
        `
      case "primary":
      default:
        return css`
          padding: 10px 2px;

          ${themedStyles({
            light: css`
              color: ${colors.secondary};

              &::placeholder,
              &[disabled] {
                color: rgba(16, 16, 16, 0.4);
              }
              &[disabled] {
                opacity: 0.5;
                pointer-events: none;
              }
            `,
            dark: css`
              color: ${colors.white};

              &:disabled,
              &:read-only {
                color: ${colors.translucentWhite20};
              }

              &::placeholder {
                color: ${colors.translucentWhite20};
              }
            `,
          })};
        `
    }
  }};

  font-size: 14px;
  line-height: inherit;
  width: 100%;
  transition: box-shadow 250ms ease-out;
  background-color: transparent;
  font-weight: ${fonts.weight.medium};

  &::placeholder {
    text-transform: none;
  }

  &:hover:enabled,
  &:focus-within {
    ${INPUT_BORDER_FOCUS_STYLES};
  }
`

export const FOCUS_VISIBLE_OUTLINE = css`
  outline: Highlight auto 1px; // Firefox
  outline: ${colors.accentBlue} auto 1px; // Others
`

/**
 * Hides an element while keeping it accessible to screen readers
 */
export const ACCESSIBLY_HIDDEN = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0px;
  white-space: nowrap;
`
