import {
  type MonetaryValue,
  type PeriodMonetaryValue,
} from "@digits-graphql/frontend/graphql-bearer"
import envHelper from "@digits-shared/helpers/envHelper"
import numeral from "numeral"
import stringHelper from "./stringHelper"

// Supported styles for displaying currency values. See:
// https://docs.google.com/document/d/1ulAtIogOhEpu1lkQg65mlne3ntGrEAqmcGM-182Fr9k/edit
// for a detailed explanation
export enum CurrencyStyle {
  // Used for atomic values (individual transactions) or when the user
  // has taken an action to view the details of a value. Shows full precision.
  Detail = "Detail",
  // Used when the value is an aggregation of other values. In USD, truncates the mantissa.
  Aggregation = "Aggregation",
  // Used when directionality is more important than precision. Abbreviates K, M, etc.
  Summary = "Summary",
}

export const DEFAULT_CURRENCY_MULTIPLIER = 1e6
const NUMBER_FORMAT = "0,0.00"

type FormatStyle = "decimal" | "percent" | "currency"

type SignDisplay = "auto" | "never" | "always" | "exceptZero" | "negative" | undefined

type RoundingMode =
  | "ceil"
  | "floor"
  | "expand"
  | "trunc"
  | "halfCeil"
  | "halfFloor"
  | "halfExpand"
  | "halfTrunc"
  | "halfEven"

const smallCapsMap = new Map<string, string>([
  ["K", "ᴋ"],
  ["M", "ᴍ"],
  ["B", "ʙ"],
])

// TS 5.5 introduced a `style` property which collides with our old currency style. Omitting it for now
type NumberFormatOptions = Omit<Intl.NumberFormatOptions, "style">

// Options for `currency` method
export interface CurrencyFormatOptions extends NumberFormatOptions {
  // Format the value according the specified style
  style: CurrencyStyle
  // Return an abbreviated currency in parts i.e. [$2.35, "k"] for 235,000
  returnInParts?: boolean
  // Return a monetary value that is the inverse of the actual value. i.e. $250 becomes -$250.
  invertValues?: boolean
  // Return an absolute monetary value. i.e. $250 stays $250, -$250 becomes $250.
  absolute?: boolean
  // Values equal to or larger than this value will be abbreviated when using Summary style
  minSummaryAbbreviationValue?: number

  // Intl.NumberFormatOptions
  formatStyle?: FormatStyle
  signDisplay?: SignDisplay
  roundingMode?: RoundingMode
}

class FormattersCache {
  private cache: { [key: string]: Intl.NumberFormat } = {}

  getOrCreate(opts: NumberFormatOptions, locale = "en-US") {
    const options = checkNumberOptions(opts)
    const key = this.cacheKey(options, locale)
    const formatter = this.cache[key] || new Intl.NumberFormat(locale, options)
    if (!this.cache[key]) {
      this.cache[key] = formatter
    }
    return formatter
  }

  // Only for tests
  clear() {
    this.cache = {}
  }

  private cacheKey(options: NumberFormatOptions, locale: string) {
    const keys = Object.keys(options).sort() as [keyof NumberFormatOptions]
    const optionsKey = keys
      .filter((key) => options[key] !== undefined)
      .map((key) => `${key}:${options[key]}`)
      .join("-")

    return `${locale}-${optionsKey}`
  }
}

const FORMATTERS = new FormattersCache()

function checkNumberOptions(options: NumberFormatOptions) {
  const {
    maximumFractionDigits,
    minimumFractionDigits,
    maximumSignificantDigits,
    minimumSignificantDigits,
    minimumIntegerDigits,
  } = options

  const newOptions = { ...options }
  if (minimumSignificantDigits === 0 || minimumIntegerDigits === 0) {
    if (envHelper.isDevelopment()) {
      throw new Error(
        `Invalid NumberFormatOptions, must fix: minimumSignificantDigits: ${minimumSignificantDigits}, minimumIntegerDigits: ${minimumIntegerDigits}`
      )
    }
    newOptions.minimumIntegerDigits = 1
    newOptions.minimumSignificantDigits = 1
  }

  if (maximumFractionDigits !== undefined) {
    if (minimumFractionDigits === undefined || minimumFractionDigits > maximumFractionDigits) {
      if (envHelper.isDevelopment()) {
        throw new Error(
          `Invalid NumberFormatOptions, must fix: minimumFractionDigits: ${minimumFractionDigits}, maximumFractionDigits: ${maximumFractionDigits}`
        )
      }
      newOptions.minimumFractionDigits = newOptions.maximumFractionDigits
    }
  }

  if (maximumSignificantDigits !== undefined) {
    if (
      minimumSignificantDigits === undefined ||
      minimumSignificantDigits > maximumSignificantDigits
    ) {
      if (envHelper.isDevelopment()) {
        throw new Error(
          `Invalid NumberFormatOptions, must fix: minimumSignificantDigits: ${minimumSignificantDigits}, maximumSignificantDigits: ${maximumSignificantDigits}`
        )
      }
      newOptions.minimumSignificantDigits = newOptions.maximumSignificantDigits
    }
  }

  return newOptions
}

export default {
  /**
   * Forces the provided number to be at least the provided min value, and at most the provided max.
   * If already between those values, the provided number is returned.
   */
  clamp: (num: number, min: number, max: number): number => Math.min(Math.max(min, num), max),

  /**
   * Returns a {@link Intl.NumberFormatOptions} that is cached by options & locale to avoid generating hundreds
   * of instances of {@link Intl.NumberFormat}.
   * See {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Performance}
   */
  numberFormatter: (options: Intl.NumberFormatOptions, locale?: string) =>
    FORMATTERS.getOrCreate(options, locale),

  /**
   * Creates an empty period with a $0.00 monetary value
   */
  zeroPeriodMonetaryAmount(): PeriodMonetaryValue {
    return {
      value: this.buildMonetaryAmount(),
      transactionsCount: 0,
      deltaPrevious: 0,
      isFirstOccurrencePeriod: false,
      deltaYearAgo: 0,
    }
  },

  /**
   * Creates a monetary value with default of $0.00
   */
  buildMonetaryAmount: (
    amount = 0,
    iso4217CurrencyCode = "USD",
    currencyMultiplier = DEFAULT_CURRENCY_MULTIPLIER
  ): MonetaryValue => ({
    iso4217CurrencyCode,
    currencyMultiplier,
    amount,
  }),

  /**
   * Creates a monetary value with default of $0.00 and multiplies the amount by the currency multiplier
   */
  buildMonetaryAmountFromUnmultiplied: (
    amount = 0,
    iso4217CurrencyCode = "USD",
    currencyMultiplier = DEFAULT_CURRENCY_MULTIPLIER
  ): MonetaryValue => ({
    iso4217CurrencyCode,
    currencyMultiplier,
    amount: Math.round(amount * currencyMultiplier),
  }),

  /**
   * Converts large int to USD currency format
   *
   * We store amounts as int64 multiplied by 1,000,000 to give us 6 decimal point precision
   * in our db to avoid inaccuracy iof floats - See https://goo.gl/my1oVc
   *
   * TODO: Need to return from the server multiplier and code with all amounts.
   * Currently we are returning some amounts without those.
   *
   * TODO: Some of this logic should be portable to other clients and could be done server side
   * to provide a more consistent experience for customers.
   *
   * @param {MonetaryValue} monetaryValue monetary value is comprised of a amount, a
   * multiplier (adjustment of the amount to convert back to float), and ISO 4217 currency
   * code for the amount
   * @param {CurrencyFormatOptions} options optional flags for formatting return
   * Decimal precision is ignored for all integers, n, such that abs(n) < 1000.
   * @return {string|array} if CurrencyFormatOptions.returnInParts is passed along with
   * CurrencyStyle.Summary, the return will be an array of the abbreviation and
   * abbreviation symbol i.e. k, m, b, etc.
   */
  currency(
    monetaryValue: MonetaryValue,
    options: CurrencyFormatOptions = { style: CurrencyStyle.Detail }
  ) {
    const { amount, currencyMultiplier } = monetaryValue
    // Apply multiplier to obtain the true value (avoid NaN when multiplier is zero)
    const valueMultiplier = currencyMultiplier === 0 ? 0 : amount / currencyMultiplier
    const absoluteValue = Math.abs(valueMultiplier)

    let value = valueMultiplier

    // Invert the sign if requested
    if (options.invertValues) {
      value *= -1
    }

    // If absolute is requested, use the absolute value and warn if both absolute and inverted are requested
    if (options.absolute) {
      value = absoluteValue
      if (options.invertValues) {
        console.error("You are using absolute and invert at the same time.")
      }
    }

    let formattedValue: string

    const {
      formatStyle,
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits,
      minimumSignificantDigits,
      maximumSignificantDigits,
      signDisplay,
      roundingMode,
      useGrouping,
      currencySign,
    } = options

    // Build the options for Intl.NumberFormat to reuse cached formatters
    const formatOptions: Intl.NumberFormatOptions & {
      signDisplay?: SignDisplay
      roundingMode?: RoundingMode
    } = {
      style: formatStyle || "currency",
      currency: monetaryValue.iso4217CurrencyCode || "USD",
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits,
      minimumSignificantDigits,
      maximumSignificantDigits,
      signDisplay,
      roundingMode,
      useGrouping,
      currencySign,
    }

    // For "aggregation" styles, drop the mantissa if the absolute value is 0 or at least 1.
    // For "summary" styles, drop the mantissa if the number is 0, between 1 and 1e6, or between 1e8 and 1e9.
    const shouldTruncate =
      options.minimumFractionDigits === undefined &&
      options.maximumFractionDigits === undefined &&
      ((options.style === CurrencyStyle.Aggregation &&
        (absoluteValue === 0 || absoluteValue >= 1)) ||
        (options.style === CurrencyStyle.Summary &&
          (absoluteValue === 0 ||
            (absoluteValue >= 1 && absoluteValue < 1e6) ||
            (absoluteValue >= 1e8 && absoluteValue <= 1e9))))

    if (shouldTruncate) {
      value = Math.trunc(value)
      formatOptions.minimumFractionDigits = 0
      formatOptions.maximumFractionDigits = 0
    }

    // Prevent negative zero when no fraction digits are shown.
    const noFraction =
      formatOptions.minimumFractionDigits === 0 && formatOptions.maximumFractionDigits === 0
    if (noFraction && absoluteValue < 0.5 && absoluteValue > 0) {
      value = absoluteValue
    }

    // Ensure -0 is not displayed
    if (value === 0) {
      value = absoluteValue
    }

    // If we are not summarizing or the value is below the abbreviation threshold, return the formatted string immediately.
    if (
      options.style !== CurrencyStyle.Summary ||
      absoluteValue < (options.minSummaryAbbreviationValue || 100000)
    ) {
      formattedValue = this.numberFormatter(formatOptions).format(value)
      return options.returnInParts ? [formattedValue, ""] : formattedValue
    }

    // Abbreviate the value using numeral for quick access to the abbreviation symbol. Use 3 significant digits.
    const abbreviatedValue = numeral(value).format("0.000a"),
      // The last character represents the abbreviation if the value is large enough.
      initialAbbreviationSymbol =
        absoluteValue >= 1000 ? abbreviatedValue.slice(-1).toUpperCase() : ""

    // Parse the abbreviated number back (as int if truncated, otherwise as float)
    const abbreviateNumber = shouldTruncate
      ? parseInt(abbreviatedValue.slice(0, -1), 10)
      : parseFloat(abbreviatedValue.slice(0, -1))

    // Format the abbreviated number using Intl.NumberFormat
    formattedValue = this.numberFormatter(formatOptions).format(abbreviateNumber)

    // If not truncating, remove any trailing zeros after the mantissa.
    if (!shouldTruncate) {
      let i = 0
      let trailingZeros = ""
      // Iterate from the end until a non-zero is found
      while ((!trailingZeros.length || trailingZeros[0] === "0") && i < formattedValue.length) {
        i += 1
        trailingZeros = formattedValue.slice(-i)
      }
      formattedValue =
        trailingZeros[0] === "." || trailingZeros[0] === ","
          ? formattedValue.slice(0, formattedValue.length - i)
          : formattedValue.slice(0, formattedValue.length - i + 1)
    }

    // Convert the abbreviation symbol to small capitals if a mapping exists.
    const abbreviationSymbol = smallCapsMap.has(initialAbbreviationSymbol)
      ? smallCapsMap.get(initialAbbreviationSymbol) || initialAbbreviationSymbol
      : initialAbbreviationSymbol

    return !options.returnInParts
      ? `${formattedValue}${abbreviationSymbol}`
      : [formattedValue, abbreviationSymbol]
  },

  /**
   * Returns the currency symbol given the currency code
   */
  currencySymbol(currencyCode: string, locale?: string) {
    const formatOptions: Intl.NumberFormatOptions = {
      style: "currency",
      currency: currencyCode,
      maximumSignificantDigits: 1,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
    return this.numberFormatter(formatOptions, locale).format(0).replace("0", "").trim()
  },

  /**
   * Compares 2 monetary values.
   *
   * Returns a negative integer if the left value is larger than the right, zero if they are the same,
   * or a positive integer if the right value is larger than the left
   */
  compareMonetaryValues(val1: MonetaryValue, val2: MonetaryValue) {
    if (val1.iso4217CurrencyCode !== val2.iso4217CurrencyCode) {
      throw new Error(`Comparing 2 different currencies \n${val1} \n${val2}`)
    }
    const v1 = val1.amount / val1.currencyMultiplier
    const v2 = val2.amount / val2.currencyMultiplier

    return v2 - v1
  },

  /**
   * Parses a number and unit string, eg "10px" into [10, "px"]
   *
   * @param {string} value to be parsed
   * @param {{value: number, unit: string}} out number object returned
   * @return {string}
   */
  parseUnit(value?: string, out = { value: 0, unit: "" }) {
    if (!value) return out
    out.value = parseFloat(value)
    const match = value.match(/[\d.\-+]*\s*(.*)/)
    out.unit = (match && match[1]) || ""
    return out
  },

  /**
   * Formats a percentage greater than or equal to 400 as a multiplier.
   * Example: 1400 => 15x, 50 => 50%
   */
  formatPercentage(percentage: number) {
    if (percentage >= 400) {
      const numberVal = percentage / 100 + 1
      // truncate multiplier if the resulting value ends in .0
      if (percentage % 100 === 0) {
        return `${Math.trunc(numberVal)}x`
      }
      return `${numberVal.toFixed(1)}x`
    }
    return `${percentage}%`
  },

  /**
   * Type check wrapper to determine if value provide is a number when it could be number or string.
   */
  isNumber(value?: unknown): value is number {
    return typeof value === "number"
  },

  /**
   * Parse a currency string into a number, handles rounding
   */
  parseFormattedCurrency(value: string | undefined) {
    const roundedFormattedNumber = numeral(value).format(NUMBER_FORMAT)
    return numeral(roundedFormattedNumber).value() ?? 0
  },

  /**
   * Converts a string into a number, suppporting all basic arithmetic including operator precedence
   *
   * @param {string} raw - The string to be parsed
   * @param {boolean} abs - Whether to return the absolute value
   * @returns {number | undefined} The parsed number (to 2 decimal places) or undefined if the string is invalid
   */
  calculate(raw: string, abs?: boolean): number | undefined {
    // strip dollar signs and commas from the raw string
    const cleaned = raw.replace(/[$,]/g, "")

    // Build an array containing the individual parts
    const parts = cleaned.match(
      // digits|operators|whitespace
      /(?:-?[\d.]+)|[-+*/]|\s+/g
    )

    // Test if everything was matched
    if (!parts || cleaned !== parts.join("")) {
      return undefined
    }

    // Remove all whitespace
    const trimmedParts = parts.map((part) => part.trim()).filter(Boolean)

    // Build a separate array containing parsed numbers
    const nums = trimmedParts.map(parseFloat)

    // Build another array with all operations reduced to additions
    const processed: number[] = []
    for (let i = 0; i < trimmedParts.length; i++) {
      const num = nums[i]
      if (num !== undefined && !isNaN(num)) {
        processed.push(num)
      } else {
        switch (trimmedParts[i]) {
          case "+":
            continue // ignore
          case "-":
            processed.push((nums[++i] || 0) * -1)
            break
          case "*":
            processed.push((processed.pop() || 0) * (nums[++i] || 0))
            break
          case "/":
            processed.push((processed.pop() || 0) / (nums[++i] || 1))
            break
          default:
            return undefined
        }
      }
    }

    // Add all numbers and return the result
    const value = processed.reduce((result, elem) => result + elem, 0)

    // Round to 2 decimal places
    // (in JS, there is no known way to do this reliably without using string parsing)
    // (https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary)
    const rounded = stringHelper.parseFloat("" + value, 2) || 0

    // Return the absolute value if abs is true
    return abs ? Math.abs(rounded) : rounded
  },

  // Implemented to match the algorithm used in go-services for Invoicing.
  // Don't change without matching the that implementation!
  bankersRounding(value: number): number {
    const epsilon = 0.0000001

    // Implement bankers rounding algorithm
    if (value === 0 || isNaN(value) || !isFinite(value)) {
      return value
    }

    if (value < 0) {
      return -this.bankersRounding(-value)
    }

    // separate the fractional part of the value
    const fracPart = value % 1
    const intPart = Math.floor(value)

    // If the fractional part is exactly 0.5, round to the nearest even integer
    if (Math.abs(fracPart - 0.5) < epsilon) {
      // The integer part is even, round down to it
      if (intPart % 2 < epsilon) {
        return intPart
      }

      // Else round up
      return Math.ceil(intPart + 0.5)
    }

    // Otherwise, round to the closest integer
    return Math.floor(value + 0.5)
  },
  /**
   * Formats a number with a limit, appending "+" if the value exceeds the limit.
   * Example: formatWithLimit(255, 100) returns "100+"
   * Pass Infinity as the limit for no truncation.
   */
  formatWithLimit(value: number, limit: number): string {
    if (value <= limit) {
      return value.toString()
    }
    return `${limit}+`
  },
}

export const numberHelperTestsOnly = {
  clearFormattersCache: () => FORMATTERS.clear(),
}
