import {
  ApolloError,
  type FetchResult,
  isApolloError,
  type NextLink,
  Observable,
  type Operation,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import BreakingVersionDetector from "@digits-shared/components/Error/BreakingVersionDetector"
import { GRPCErrorCode } from "@digits-shared/grpc/codes"
import type Session from "@digits-shared/session/Session"
import { type SessionLogoutState } from "@digits-shared/session/Session"
import { type SubscriptionObserver } from "zen-observable/esm"

export default (session: Session) => {
  const breakingVersionDetector = new BreakingVersionDetector(session)

  return onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        const { extensions } = error
        const code = extensions?.code
        const currentContext = operation.getContext()

        TrackJS?.console.info("GraphQL error: " + code, {
          operation: operation.operationName,
          variables: operation.variables,
          // Access HTTP headers from the response if available
          traceId: currentContext.response?.headers?.get("X-Digits-Trace"),
        })

        switch (code) {
          case GRPCErrorCode.Unauthenticated: {
            const errorState: SessionLogoutState = {
              code,
              message: "Your session has expired. Please log back in.",
              error,
            }

            if (currentContext.publicAPI) {
              // Clear session without retry or refresh
              return new Observable((observer) => {
                session
                  .clear(errorState)
                  .then(() => observer.error(errorState))
                  .finally(() => observer.complete())
              })
            }

            // Safeguard: if the operation has already been retried, log and propagate the error without retrying.
            if (currentContext.retryAttempt) {
              TrackJS?.console.info(
                "Operation already retried; no additional refresh will be attempted",
                {
                  operation: operation.operationName,
                  variables: operation.variables,
                }
              )
              return
            }

            // Mark the operation as retried.
            operation.setContext({
              ...currentContext,
              retryAttempt: true,
            })

            return new Observable((observer) => {
              session
                .attemptToRefreshToken(errorState)
                .then(() => {
                  // If the token refresh succeeded, retry the original operation
                  subscribeToOperationRetry(observer, operation, forward)
                })
                .catch((refreshError) => {
                  observer.error(refreshError)
                })
            })
          }

          case GRPCErrorCode.InvalidArgument:
          case GRPCErrorCode.NotFound:
          case GRPCErrorCode.Unimplemented:
            breakingVersionDetector.checkBreakingVersion()
            break
        }
      }
    }
  })
}

// Helper function to subscribe to the retried operation.
const subscribeToOperationRetry = (
  observer: SubscriptionObserver<FetchResult>,
  operation: Operation,
  forward: NextLink
) => {
  const subscriber = {
    next: (value: FetchResult) => {
      if (value.errors?.length) {
        subscriber.error(value.errors)
        return
      }

      TrackJS?.console.info("Operation retry succeeded", {
        operation: operation.operationName,
        variables: operation.variables,
      })
      observer.next(value)
    },
    error: (errorValue: unknown) => {
      TrackJS?.console.info("Operation failed on retry", {
        operation: operation.operationName,
        variables: operation.variables,
        errorValue,
      })
      observer.error(errorValue)
    },
    complete: () => {
      TrackJS?.console.info("Operation retry completed", {
        operation: operation.operationName,
      })
      observer.complete()
    },
  }

  return forward(operation).subscribe(subscriber)
}

// Add global unhandled promise rejection handler
export function unhandledRejectionHandler(event: PromiseRejectionEvent) {
  let error = event.reason
  if (
    typeof error === "object" &&
    error.message === "Please contact Support" &&
    !isApolloError(error)
  ) {
    // Handle non-ApolloError objects with "Please contact Support" message
    // Create an Error object from the reason
    error = new ApolloError({
      errorMessage: error.message,
      graphQLErrors: [{ message: error.message, path: error.path }],
    })
  }

  // Process the error if it's an Apollo error with "Please contact Support" message
  if (error instanceof Error) {
    const processedError = processApolloSupportError(error)
    // If the error was processed (meaning it's an Apollo error we care about)
    if (processedError) {
      // Prevent the default browser handling of the error
      event.preventDefault()
      TrackJS?.console.debug("Unhandled Rejection", {
        processedError,
      })

      // Log the processed error to TrackJS
      TrackJS?.track(processedError)
    }
  }
}
window.addEventListener("unhandledrejection", unhandledRejectionHandler)

// Helper function to process Apollo errors with "Please contact Support" message
function processApolloSupportError(error: Error | object | string) {
  if (error instanceof Error && error.message === "Please contact Support") {
    const details = extractApolloErrorDetails(error)
    if (details) {
      error.message = details
      return error
    }
  }
  return null
}

// Helper function to extract operation name and variables from an Apollo Error
function extractApolloErrorDetails(error: unknown) {
  if (error instanceof Error && isApolloError(error)) {
    const operationName = error.graphQLErrors?.[0]?.path?.[0]
    // Check graphQLErrors first
    if (typeof operationName === "string" && operationName.length) {
      return `GraphQL Error: ${operationName}`
    }
  }
  return null
}
