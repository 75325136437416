import { defined } from "@digits-shared/helpers/filters"

const FORMATTER = new Intl.NumberFormat("en-US", {})
const VOWELS = ["a", "e", "i", "o", "u"]

export default {
  pluralize(value: number, singular: string, plural: string) {
    const formattedValue = FORMATTER.format(value)
    const strs = (value === 1 ? singular : plural).split("{value}")
    return strs.join(formattedValue)
  },

  makePossessive(value: string) {
    const lastCharacter = value.charAt(value.length - 1)
    return lastCharacter === "s" ? `${value}’` : `${value}’s`
  },

  escapeRegExp(value: string) {
    return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  },

  containsSpecialCharacter(value: string) {
    const pattern = /^[A-Za-z0-9 ]+$/
    return !pattern.test(value)
  },

  displayUrl(fullUrl?: string | null) {
    if (!fullUrl) return undefined

    return fullUrl
      .replace(/^https?:\/\//, "")
      .replace(/^(www\.)?/, "")
      .replace(/\/$/, "")
  },

  httpAlwaysUrl(fullUrl?: string | null) {
    if (!fullUrl) return undefined

    let link = fullUrl
    if (!link.match(/^https?:\/\//)) {
      link = "http://".concat(link)
    }

    return link
  },

  truncate(value: string, maxLength: number, endingString = "…") {
    return value.length > maxLength ? value.substring(0, maxLength).trim() + endingString : value
  },

  truncateArray(values: string[], maxLength = 2, endingString = "etc") {
    if (values.length < 2) return values[0] || ""

    return `${[...values.slice(0, maxLength), values.length > maxLength ? endingString : undefined]
      .filter(defined)
      .join(", ")}.`
  },

  camelCaseToSpaces(value: string | undefined | null = "", uppercaseFirstLetter = false) {
    const camelCasedValue =
      value
        ?.replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/&/g, " & ")
        .trim() || ""

    return uppercaseFirstLetter
      ? (camelCasedValue.charAt(0)?.toUpperCase() ?? "") + camelCasedValue.slice(1)
      : camelCasedValue
  },

  camelCase(value: string, uppercaseFirstLetter = false) {
    const camelCasedValue = value
      .replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace("-", "").replace("_", ""))
      .replace(/\s(.)/g, (ltr) => ltr.toUpperCase())
      .replace(/\s/g, "")

    if (uppercaseFirstLetter) return value.charAt(0).toUpperCase() + camelCasedValue.slice(1)

    return camelCasedValue.replace(/^(.)/, (ltr) => ltr.toLowerCase())
  },

  snakeCase(value: string) {
    return value.replace(/[\w]([A-Z])/g, (m) => `${m[0]}_${m[1]}`).toLowerCase()
  },

  kebabToSpaces(value = "", uppercaseFirstLetter = false) {
    const formattedValue =
      value
        ?.replace(/-+/g, " ") // Replace hyphens with spaces
        .trim() || ""

    return uppercaseFirstLetter
      ? (formattedValue.charAt(0).toUpperCase() ?? "") + formattedValue.slice(1).toLowerCase()
      : formattedValue
  },

  capitalize(value = "", lowercaseString = true) {
    const rest = value.slice(1)
    return value.charAt(0).toUpperCase() + (lowercaseString ? rest.toLowerCase() : rest)
  },

  titleCase(value: string) {
    const tokens = value.split(" ")
    return tokens.map((t) => this.capitalize(t)).join(" ")
  },

  uncapitalize(value: string) {
    return value.charAt(0).toLowerCase() + value.slice(1)
  },

  apostrophize(value?: string | null) {
    if (!value) return ""
    const trimmedValue = value.trim()
    const lastChar = trimmedValue[trimmedValue.length - 1]?.toLowerCase()
    return lastChar !== "s" ? `${trimmedValue}‘s` : `${trimmedValue}‘`
  },

  timezone(value?: string | null) {
    if (!value) return "-"
    return value.replace("/", " - ").replace("_", " ")
  },

  trimTrailingForwardSlashesIfPresent(value: string) {
    return value.replace(/\/+$/, "")
  },

  initials(name: string): string {
    // Only keep alphabetical letters, whitespaces; remove empty strings after the split
    const nameParts = name
      .replaceAll(/[^a-zA-Z\s]/g, "")
      .split(" ")
      .filter((it) => !!it)
    if (!nameParts?.length) return name

    const firstLetter = nameParts[0]?.charAt(0).toUpperCase()
    // Second letter is the first letter of the second name part
    const secondPart = (nameParts.length > 1 && nameParts[1]) || ""
    const secondLetter = secondPart.charAt(0).toUpperCase()

    return firstLetter + secondLetter
  },

  firstInitial(name: string): string {
    // Only keep alphabetical letters
    return name
      .replaceAll(/[^a-zA-Z\s]/g, "")
      .charAt(0)
      .toUpperCase()
  },

  firstChar(values: string) {
    const firstChar = values[0] || ""
    return parseInt(firstChar, 10) ? "#" : firstChar
  },

  getIndefiniteArticle(word: string) {
    if (VOWELS.includes(this.firstChar(word).toLowerCase())) {
      return "an"
    }

    return "a"
  },

  parseFloat(value: string, fixed?: number) {
    const parsedValue = parseFloat(value)
    if (isNaN(parsedValue)) return undefined
    return fixed === undefined ? parsedValue : parseFloat(parsedValue.toFixed(fixed))
  },

  classNames(...classes: (string | false | undefined | null)[]) {
    const definedClasses = classes.filter(defined)
    return definedClasses.length ? definedClasses.join(" ") : undefined
  },

  simpleHashCode(value: string, offset = 0) {
    const strValue = value?.toString()
    if (typeof strValue !== "string") return 0
    return Math.max(
      0,
      strValue.split("").reduce((acc, char) => acc + (char.charCodeAt(0) + offset), 0)
    )
  },
}
