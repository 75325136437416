import colorHelper from "@digits-shared/helpers/colorHelper"

// Generate color names from http://chir.ag/projects/name-that-color/

const colors = {
  transparent: "rgba(255, 255, 255, 0)", // safari doesnt like "transparent"

  white: "#ffffff",
  white90: "#FAFAFA",
  offWhite: "#E3F2FF",
  lightestGray: "#f6f6f6",
  lightGray: "#e9e9e9",
  altoGray: "#cecece",
  regentGray: "#909293",
  subtitleGray: "#6e7075", // monetary amounts
  gray: "#606060",

  green: "#79c37e",
  neonGreen: "#00FCB8",
  subtleNeonGreen: "#71f5bb",

  poloBlue: "#81acd9",
  havelockBlue: "#56a0e1",

  // There should be no red in the product.
  yellow: "#dacd61",
  orange: "#fb902f",

  offBlack: "#182429",
  blackPearl: "#040911",
  nero: "#030400",
  black: "#000000",

  lightPurple: "#A17EE1",
  purple: "#B95DD9",
  pastelPurple: "#f9d8ff",
  accentGreen: "#00FFBE",
  accentGreen50: "#80FFDF",
  accentGreenDark: "#00F1B3",
  accentBlue: "#7597e8",
  accentTurquoise: "#00D3C7",
  teal: "#409AD0",
  accentPurple: "#7B82D0",
  accentPurple50: "#BDC1E7",
  accentPink: "#d382e0",
  aiBlue: "#205DFC",
  infoBlue: "#d0ebff",

  logoGreen: "#00c4b8",
  navActiveHighlight: "#50F2E8",

  // Design system button colors
  light: "#ffffff",
  lightDark: "#b2b2b2",
  error: "#fb902f",
  errorDark: "#dc812d",
  accentYellow: "#F0C556",
  errorText: "#B94E00",

  // Primary Shades
  primaryDark: "#00746d",
  primary: "#00A69C",
  primary90: "#1AAFA6",
  primary80: "#33B8B0",
  primary70: "#4DC1BA",
  primary60: "#66C9C4",
  primary50: "#80D2CD",
  primary40: "#99DBD7",
  primary30: "#B2E4E1",
  primary20: "#CCEDEB",
  primary10: "#E5F6F5",
  primary05: "#F2FBFA",

  // Secondary Shades
  secondaryDark: "#223358",
  secondary: "#32497f",
  secondary90: "#475B8C",
  secondary80: "#5B6D99",
  secondary70: "#7080A5",
  secondary60: "#8492B2",
  secondary50: "#98A4BF",
  secondary40: "#ADB6CC",
  secondary30: "#C1C8D9",
  secondary20: "#D6DBE5",
  secondary10: "#EAEDF2",
  secondary05: "#F5F6F9",

  // Glow Shades
  glowBackground: "#f4f7fd",
  glowDim: "#cae0ff",
  glowBright: "#5fa1ff",
  glowHighlight: "#f1f5fd",
  glowSoft: "#e3f1fe",
}

export default Object.assign(colors, {
  translucentPrimary90: colorHelper.hexToRgba(colors.primary, 0.9),
  translucentPrimary80: colorHelper.hexToRgba(colors.primary, 0.8),
  translucentPrimary70: colorHelper.hexToRgba(colors.primary, 0.7),
  translucentPrimary60: colorHelper.hexToRgba(colors.primary, 0.6),
  translucentPrimary50: colorHelper.hexToRgba(colors.primary, 0.5),
  translucentPrimary40: colorHelper.hexToRgba(colors.primary, 0.4),
  translucentPrimary30: colorHelper.hexToRgba(colors.primary, 0.3),
  translucentPrimary20: colorHelper.hexToRgba(colors.primary, 0.2),
  translucentPrimary10: colorHelper.hexToRgba(colors.primary, 0.1),

  translucentSecondary90: colorHelper.hexToRgba(colors.secondary, 0.9),
  translucentSecondary80: colorHelper.hexToRgba(colors.secondary, 0.8),
  translucentSecondary70: colorHelper.hexToRgba(colors.secondary, 0.7),
  translucentSecondary60: colorHelper.hexToRgba(colors.secondary, 0.6),
  translucentSecondary50: colorHelper.hexToRgba(colors.secondary, 0.5),
  translucentSecondary40: colorHelper.hexToRgba(colors.secondary, 0.4),
  translucentSecondary30: colorHelper.hexToRgba(colors.secondary, 0.3),
  translucentSecondary20: colorHelper.hexToRgba(colors.secondary, 0.2),
  translucentSecondary10: colorHelper.hexToRgba(colors.secondary, 0.1),
  translucentSecondary05: colorHelper.hexToRgba(colors.secondary, 0.05),

  payWhite40: colorHelper.hexToRgba(colors.offWhite, 0.4),
  payWhite30: colorHelper.hexToRgba(colors.offWhite, 0.3),

  translucentNeonGreen80: colorHelper.hexToRgba(colors.neonGreen, 0.8),
  translucentNeonGreen70: colorHelper.hexToRgba(colors.neonGreen, 0.7),
  translucentNeonGreen60: colorHelper.hexToRgba(colors.neonGreen, 0.6),
  translucentNeonGreen30: colorHelper.hexToRgba(colors.neonGreen, 0.3),
  translucentNeonGreen15: colorHelper.hexToRgba(colors.neonGreen, 0.15),
  translucentNeonGreen5: colorHelper.hexToRgba(colors.neonGreen, 0.05),
  translucentMalibuBlue40: colorHelper.hexToRgba("#4cd3ff", 0.4),
  translucentOrange70: colorHelper.hexToRgba(colors.orange, 0.7),
  translucentOrange10: colorHelper.hexToRgba(colors.orange, 0.1),
  translucentOrange05: colorHelper.hexToRgba(colors.orange, 0.05),
  translucentGray70: colorHelper.hexToRgba(colors.lightGray, 0.7),
  translucentGray20: colorHelper.hexToRgba(colors.gray, 0.2),
  translucentWhite90: colorHelper.hexToRgba(colors.white, 0.9),
  translucentWhite80: colorHelper.hexToRgba(colors.white, 0.8),
  translucentWhite70: colorHelper.hexToRgba(colors.white, 0.7),
  translucentWhite60: colorHelper.hexToRgba(colors.white, 0.6),
  translucentWhite50: colorHelper.hexToRgba(colors.white, 0.5),
  translucentWhite40: colorHelper.hexToRgba(colors.white, 0.4),
  translucentWhite30: colorHelper.hexToRgba(colors.white, 0.3),
  translucentWhite20: colorHelper.hexToRgba(colors.white, 0.2),
  translucentWhite10: colorHelper.hexToRgba(colors.white, 0.1),
  translucentWhite04: colorHelper.hexToRgba(colors.white, 0.04),
  translucentLightBlack50: colorHelper.hexToRgba("#182732", 0.5),
  translucentBlack90: colorHelper.hexToRgba(colors.black, 0.9),
  translucentBlack80: colorHelper.hexToRgba(colors.black, 0.8),
  translucentBlack70: colorHelper.hexToRgba(colors.black, 0.7),
  translucentBlack60: colorHelper.hexToRgba(colors.black, 0.6),
  translucentBlack50: colorHelper.hexToRgba(colors.black, 0.5),
  translucentBlack40: colorHelper.hexToRgba(colors.black, 0.4),
  translucentBlack30: colorHelper.hexToRgba(colors.black, 0.3),
  translucentBlack20: colorHelper.hexToRgba(colors.black, 0.2),
  translucentBlack15: colorHelper.hexToRgba(colors.black, 0.15),
  translucentBlack10: colorHelper.hexToRgba(colors.black, 0.1),
  translucentBlack06: colorHelper.hexToRgba(colors.black, 0.06),
  deltaBad: colors.orange,
  deltaGood: colors.green,
  deltaNew: "#5c61ff",

  rowDescription: colorHelper.hexToRgba(colors.secondary, 0.8),
  rowHoverBackground: colorHelper.hexToRgba("#4cd3ff", 0.12),
  highlightBackground: colorHelper.hexToRgba("#00E2A9", 0.3),
  optionPopupBackground: "#10212b",
  osRowBackground: "rgba(37, 109, 136, 0.08)",

  gradients: {
    ai: "linear-gradient(84deg, #399CD4 -4.07%, #5E5BE9 95.1%)",
    aiLight:
      "linear-gradient(84deg, rgba(57, 156, 212, 0.20) -4.07%, rgba(94, 91, 233, 0.20) 95.1%), #fff",
    ai60: "linear-gradient(84deg, rgba(57, 156, 212, 0.6) -4.07%, rgba(94, 91, 233, 0.6) 95.1%)",
    ai30: "linear-gradient(84deg, rgba(57, 156, 212, 0.3) -4.07%, rgba(94, 91, 233, 0.3) 95.1%)",
    ai10: "linear-gradient(84deg, rgba(57, 156, 212, 0.1) -4.07%, rgba(94, 91, 233, 0.1) 95.1%)",
    alert: "linear-gradient(92deg, #FFBF60 -13.22%, #FFDA84 100%)",
    tealPurple:
      "linear-gradient(84deg, rgba(44, 146, 190, 0.80) -4.07%, rgba(61, 58, 194, 0.54) 95.1%)",
  },

  // When showing monetary amounts,
  // by default we want money coming in to be white@0.8 and money leaving the business to be orange.
  // In contexts where values are inverted (expenses), money leaving the business is orange, money coming in (refunds)
  // will be green (positive sentiment)
  money: {
    inbound: colorHelper.hexToRgba(colors.white, 0.8),
    outbound: colors.orange,

    inverted: {
      outbound: colorHelper.hexToRgba(colors.white, 0.8),
      inbound: colors.neonGreen,
    },
  },

  theme: {
    light: {
      text: colors.offBlack,
      secondaryText: colorHelper.hexToRgba(colors.offBlack, 0.6),
      invertedText: colors.white,
      highlightedText: colors.black,
      secondaryButtonText: "#4c85b8",
      border: colors.altoGray,
      boxShadow: "rgba(0,0,0,0.19)",
      link: "#4c85b8",
      genericIcon: "rgba(39,55,98,0.6)",
    },
    dark: {
      graph: {
        value1: "#009ff9",
        value2: "#43a9ff",
        value3: "#15d1f8",
        value4: "#33e772",
        value5: "#25dea7",
        value6: "#71F5BB",
      },
      genericIcon: "#425664",
      background: colors.blackPearl,
      secondaryBackground: "#060D1B",
      text: colors.white,
      secondaryText: colorHelper.hexToRgba(colors.white, 0.6),
      invertedText: colors.blackPearl,
      border: "rgba(37, 109, 136, 0.3)",
      dropdownBorder: "#3c3c3c",
      popupBorder: "rgb(31, 71, 81)",
      interactedContainerGlow: "rgba(6, 253, 184, 0.1)",
      interactedContainerBorder: "rgba(6, 253, 184, 0.5)",
      selectedContainerBorder: "rgba(6, 253, 184, 0.7)",
      highlightedText: colors.neonGreen,
      secondaryButtonText: "#4490B0",
      invertedGradient: {
        top: "#091120",
        bottom: "#081130",
      },
      boxShadow: "#113b65",
      link: "#4490B0",
    },
  },
})
