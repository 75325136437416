import { ApolloError, ApolloLink, Observable, type Operation } from "@apollo/client"
import { getOperationDefinition } from "@apollo/client/utilities"
import {
  type GraphQLTrace,
  GraphQLTraceKind,
} from "@digits-shared/components/Contexts/GraphQLTracerContext"
import { GRPCErrorCode } from "@digits-shared/grpc/codes"
import type Session from "@digits-shared/session/Session"
import { v4 as generateUUID } from "uuid"
import { type TraceCallback } from "@digits-shared/initializers/apollo/middleware/http/graphQLTrace"

export const DG_MUTATION_ERROR = "doppelgangers cannot perform mutations"

type BlockResult =
  | {
      blocked: false
    }
  | { blocked: true; code: GRPCErrorCode; reason: string; trace?: GraphQLTrace }

/**
 * Creates a middleware that conditionally blocks mutations
 * @returns An Apollo Link that conditionally blocks mutations
 */
export default (session: Session, onTrace?: TraceCallback) =>
  new ApolloLink((operation, forward) => {
    const operationDefinition = getOperationDefinition(operation.query)

    // Only check mutations
    if (operationDefinition?.operation === "mutation") {
      const blockResult = shouldBlockMutation(session, operation)

      if (blockResult.blocked) {
        const { code, reason, trace } = blockResult
        // Return an Observable that immediately errors
        return new Observable((observer) => {
          const error = new ApolloError({
            errorMessage: reason,
            graphQLErrors: [
              {
                message: reason,
                extensions: { code },
              },
            ],
          })

          TrackJS?.console.debug("Mutation blocked", {
            operation: operation.operationName,
            variables: operation.variables,
            reason,
          })

          if (trace) {
            onTrace?.(trace)
          }

          observer.error(error)
          observer.complete()
        })
      }
    }

    // If not blocked, continue with the operation
    return forward(operation)
  })

const shouldBlockMutation = (session: Session, operation: Operation): BlockResult => {
  // public mutations (login, logout, token refresh, etc should never be blocked)
  if (operation.getContext().publicAPI) {
    return { blocked: false }
  }

  if (session.isDoppelganger) {
    return {
      blocked: true,
      code: GRPCErrorCode.PermissionDenied,
      reason: DG_MUTATION_ERROR,

      trace: {
        id: generateUUID(),
        kind: GraphQLTraceKind.DG_MUTATION,
        operation,
        durationMS: 0,
        recordedAt: Date.now(),
        errorCode: GRPCErrorCode.PermissionDenied,
        errorMessage: DG_MUTATION_ERROR,
        errorPath: [operation.operationName],
      },
    }
  }

  return { blocked: false }
}
