export enum AspectCode {
  AIBookkeeper = "aib",
  AdvancedLedger = "adv",
  AskDigits = "ask",
  Audit = "adt",
  Branding = "bnd",
  CategoryManager = "cmg",
  ClosedPeriod = "clp",
  Connections = "cnx",
  GetPaid = "gpd",
  Inbox = "ibx",
  Invoicing = "inv",
  PayBills = "pbl",
  QuarterlyDashboard = "qtr",
  RestrictLedgerEdits = "rle",
  StatementSummary = "sts",
  TransactionActionItems = "tai",
  Vault = "vlt",
  VendorDirectory = "vdr",
}
