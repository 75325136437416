/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: number; output: number; }
  Long: { input: number; output: number; }
  Void: { input: any; output: any; }
};

export type AccessConstraint = {
  partyRole?: InputMaybe<PartyRole>;
};

/** Report Options */
export enum AccountNumbersDisplay {
  Auto = 'Auto',
  Hidden = 'Hidden'
}

export type AccountReconciliation = {
  category: EntityCategory;
  categoryId: Scalars['String']['output'];
  chartOfAccountsId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** creator and updater are nullable when system opened the reconciliation */
  creator?: Maybe<EntityUser>;
  endDate: Date;
  reconciliationId: Scalars['String']['output'];
  startDate: Date;
  status: AccountReconciliationStatus;
  updatedAt: Scalars['DateTime']['output'];
  updater?: Maybe<EntityUser>;
};

export enum AccountReconciliationStatus {
  Draft = 'Draft',
  Finalized = 'Finalized',
  ManualReopened = 'ManualReopened',
  SystemReopened = 'SystemReopened',
  Unknown = 'Unknown'
}

export type AccountReconciliations = {
  account: EntityCategory;
  reconciliations: Array<AccountReconciliation>;
};

export type AccountantVanityStats = {
  totalCalculations: Scalars['Long']['output'];
  totalClients: Scalars['Long']['output'];
  totalTransactions: Scalars['Long']['output'];
  totalTransactionsEnhanced: Scalars['Long']['output'];
  totalTransactionsValue: MonetaryValue;
};

export enum AccountingBasis {
  AccrualBasis = 'AccrualBasis',
  CashBasis = 'CashBasis',
  UnknownAccountingBasis = 'UnknownAccountingBasis'
}

export type ActionItem = {
  author?: Maybe<EntityUser>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: ActionItemValue;
};

export enum ActionItemActionType {
  Approve = 'Approve',
  Pay = 'Pay',
  Review = 'Review',
  Submit = 'Submit',
  UnknownActionType = 'UnknownActionType'
}

export type ActionItemBillApproval = {
  bill: EntityBill;
};

export type ActionItemFilter = {
  assignedToOrganizationId?: InputMaybe<Scalars['String']['input']>;
  assignedToUserId?: InputMaybe<Scalars['String']['input']>;
  excludeFanouts?: InputMaybe<Scalars['Boolean']['input']>;
  excludeObjectKinds?: InputMaybe<Array<ObjectKind>>;
  includeMode?: InputMaybe<Array<ActionItemTargetMode>>;
  includeSuperseded?: InputMaybe<Scalars['Boolean']['input']>;
  status: Array<ActionItemStatus>;
};

export enum ActionItemKind {
  Bill = 'Bill',
  Comment = 'Comment',
  Onboarding = 'Onboarding',
  Report = 'Report',
  TransactionReview = 'TransactionReview',
  UnknownActionItemKind = 'UnknownActionItemKind',
  WorkItem = 'WorkItem'
}

export type ActionItemPublishedReport = {
  package: EntityReportPackage;
};

export enum ActionItemStatus {
  Closed = 'Closed',
  Completed = 'Completed',
  Created = 'Created',
  Unknown = 'Unknown'
}

export type ActionItemStatusChange = {
  actionItemId: Scalars['String']['input'];
  status: ActionItemStatus;
};

export type ActionItemSummary = {
  count: Scalars['Long']['output'];
  period: Period;
};

export enum ActionItemTargetMode {
  Assign = 'Assign',
  UnknownTargetMode = 'UnknownTargetMode',
  Watch = 'Watch'
}

export type ActionItemThread = {
  targetEntity: ObjectEntity;
  threadId: Scalars['String']['output'];
};

export type ActionItemTransaction = {
  categorySuggestions?: Maybe<Array<CategorySuggestion>>;
  transaction: EntityTransaction;
};

export type ActionItemValue = ActionItemBillApproval | ActionItemPublishedReport | ActionItemThread | ActionItemTransaction;

export type ActionItemsObjectKindSummary = {
  count: Scalars['Long']['output'];
  objectKind: ObjectKind;
};

export type ActivePartyRoles = {
  partyId: Scalars['String']['output'];
  roles: Array<PartyRole>;
};

export type ActiveViewVersion = {
  legalEntityId: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/**
 * ActivityEvent represents one event in an ActivityFeed.
 * Note: The objects represented by subject, target and actor_user_id
 *       will be available externally to this message type.
 */
export type ActivityEvent = {
  computedEvent?: Maybe<ComputedEvent>;
  discreteEvent?: Maybe<DiscreteEvent>;
  id: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  occurredAt: Scalars['DateTime']['output'];
};

export enum ActivityEventInterval {
  AllTime = 'AllTime',
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  Month = 'Month',
  None = 'None',
  Week = 'Week',
  Year = 'Year'
}

/**
 * Activity Feed
 * ActivityFeed contains a sequence of ActivityEvents
 * and a separate structure describing the hydrated form
 * of the object entities referenced by the author/subject/target
 * of the feed's events.
 */
export type ActivityFeed = {
  entities: ObjectEntities;
  events: Array<ActivityEvent>;
};

export type ActivityObjectIdentifier = {
  context?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  kind: ObjectKind;
};

export type AddedFeature = {
  featureId: Scalars['String']['output'];
  invalid: Scalars['Boolean']['output'];
};

export type Address = {
  /** city is used for the city/town/village of the address */
  city?: Maybe<Scalars['String']['output']>;
  /** ISO country code */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** Latitude */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Longitude */
  long?: Maybe<Scalars['Float']['output']>;
  /** postal identifier for the address */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** region is the state, region, or any other subdivision of the country */
  region?: Maybe<Scalars['String']['output']>;
  /** street contains the housing number and street name */
  street?: Maybe<Scalars['String']['output']>;
  /** street_additional is used for any other additional street identifying information */
  streetAdditional?: Maybe<Scalars['String']['output']>;
};

export type AddressFeatureValue = {
  addressValue: Address;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetAdditional?: InputMaybe<Scalars['String']['input']>;
};

export type AdjacentObjectPermission = {
  legalEntity: EntityLegalEntity;
  object: ObjectIdentifier;
  organizationPreview: OrganizationPreview;
  viewType: ViewType;
};

export type AdminCategory = {
  category: Category;
  chartOfAccountsDisplayNumber?: Maybe<Scalars['String']['output']>;
  externalAccountSubtype?: Maybe<Scalars['String']['output']>;
  externalAccountType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<CategorySubtype>;
  synthesized: Scalars['Boolean']['output'];
};

export type AdminChartOfAccountsFilter = {
  accountingBasis?: InputMaybe<AccountingBasis>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminReportPackageDocumentMetadata = {
  documentObjectId: Scalars['String']['output'];
  documentObjectVersionId: Scalars['String']['output'];
  generationHistory?: Maybe<ReportGenerationHistory>;
  id: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  reportFile?: Maybe<ReportFileMetadata>;
  status: ReportPackageDocumentStatus;
  title: Scalars['String']['output'];
  type: ReportPackageDocumentType;
  validationHistories: Array<ReportValidationHistory>;
};

export type AdminReportPackageMetadata = {
  author?: Maybe<Contact>;
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  documents: Array<AdminReportPackageDocumentMetadata>;
  endedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  interval: Interval;
  publishedAsDraft: Scalars['Boolean']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: ReportPackageStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionId: Scalars['String']['output'];
  viewKey: ViewIdentifier;
};

export type AffiliateOrganization = {
  affiliatedUsers: Array<AffiliateUser>;
  organization: Organization;
  type: AffiliateType;
};

export type AffiliateOrganizations = {
  inviteProposals: Array<InvitationProposal>;
  organizations: Array<AffiliateOrganization>;
};

export enum AffiliateType {
  Accounting = 'Accounting',
  Investment = 'Investment'
}

export type AffiliateUser = {
  role: Role;
  user: User;
};

export type Affiliation = {
  legalEntity: EntityLegalEntity;
  organization: EntityOrganization;
  role: Role;
};

export type AmountRuleFilter = {
  amount: MonetaryValue;
  amountRuleFilterId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  operator: ComparisonOperator;
};

export type ApprovalEligibility = {
  legalEntityId: Scalars['String']['output'];
  status: ApprovalEligibilityStatus;
};

export enum ApprovalEligibilityStatus {
  BelowRequiredTransactionCount = 'BelowRequiredTransactionCount',
  DataValidationHealthNotComputed = 'DataValidationHealthNotComputed',
  DataValidationUnhealthy = 'DataValidationUnhealthy',
  /** Error statuses */
  Error = 'Error',
  InvalidQBOLink = 'InvalidQBOLink',
  LedgerViewError = 'LedgerViewError',
  /** Pending i.e. still computing */
  LedgerViewNotComputed = 'LedgerViewNotComputed',
  NoTransactionCount = 'NoTransactionCount',
  Ready = 'Ready',
  ReportValidationNotComputed = 'ReportValidationNotComputed',
  ReportValidationUnhealthy = 'ReportValidationUnhealthy',
  Unknown = 'Unknown'
}

/**
 * TYPES
 *
 */
export type ArchiveIntervalOrigin = {
  index: Scalars['Long']['output'];
  interval: Interval;
  intervalCount?: Maybe<Scalars['Long']['output']>;
  year: Scalars['Long']['output'];
};

export type ArchivedComponent = {
  data: ComponentData;
  viewId: ViewIdentifier;
};

export enum ArrivalContext {
  ReportsBuilder = 'ReportsBuilder',
  TransactionReview = 'TransactionReview'
}

export type AspectRequestMeta = {
  createdAt: Scalars['DateTime']['output'];
  requestor: EntityUser;
};

export type Assignee = {
  organization?: InputMaybe<OrganizationAssignee>;
  user?: InputMaybe<UserAssignee>;
};

export type AssistantEvent = {
  comment: Comment;
  kind: AssistantEventKind;
  layout?: Maybe<Layout>;
  suggestions?: Maybe<Array<Scalars['String']['output']>>;
  threadId?: Maybe<Scalars['String']['output']>;
};

export enum AssistantEventKind {
  Complete = 'Complete',
  Error = 'Error',
  Unknown = 'Unknown',
  Update = 'Update'
}

export enum AssociationSource {
  Onboarding = 'Onboarding',
  UnknownSource = 'UnknownSource'
}

export enum AsyncProcessingStatus {
  AsyncProcessingDone = 'AsyncProcessingDone',
  AsyncProcessingError = 'AsyncProcessingError',
  AsyncProcessingStarted = 'AsyncProcessingStarted',
  UnknownAsyncProcessingStatus = 'UnknownAsyncProcessingStatus'
}

export type AuditRule = {
  action: AuditRuleAction;
  amountFilters?: Maybe<Array<AmountRuleFilter>>;
  auditRuleId: Scalars['String']['output'];
  categoryFilters?: Maybe<Array<CategoryRuleFilter>>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partyFilters?: Maybe<Array<PartyRuleFilter>>;
  ruleState: AuditRuleState;
  type: AuditRuleType;
};

export enum AuditRuleAction {
  CreateBoost = 'CreateBoost',
  SendClientEmail = 'SendClientEmail',
  SendSelfEmail = 'SendSelfEmail',
  UnknownAction = 'UnknownAction'
}

export enum AuditRuleState {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  UnknownState = 'UnknownState'
}

export type AuditRuleStatus = {
  recentlyUpdated: Scalars['Boolean']['output'];
  type: AuditRuleType;
  updaterType: AuditRuleUpdaterType;
};

export enum AuditRuleType {
  CategorySuggestion = 'CategorySuggestion',
  InParentCategory = 'InParentCategory',
  NewTransaction = 'NewTransaction',
  SensitiveDataSuggestion = 'SensitiveDataSuggestion',
  UnassignedCategorySuggestion = 'UnassignedCategorySuggestion',
  UnassignedVendorSuggestion = 'UnassignedVendorSuggestion',
  UnknownType = 'UnknownType',
  VendorSuggestion = 'VendorSuggestion'
}

export enum AuditRuleUpdaterType {
  System = 'System',
  UnknownUpdaterType = 'UnknownUpdaterType',
  User = 'User'
}

export type BadgeCount = {
  count: Scalars['Long']['output'];
};

export type BalanceSummary = {
  period: Period;
  total: PeriodMonetaryValue;
};

export type BankAccount = {
  accountIdMask: Scalars['String']['output'];
  iconObjectName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  institutionName: Scalars['String']['output'];
  isAutoPayout: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nickname: Scalars['String']['output'];
};

export type BankFeedEntry = {
  amount: InputMonetaryValue;
  memo: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type BillAccess = {
  objectId: Scalars['String']['output'];
  objectKind: BillAccessKind;
  role: BillAccessRole;
};

export enum BillAccessKind {
  UnknownBillAccessKind = 'UnknownBillAccessKind',
  User = 'User'
}

export enum BillAccessRole {
  Approver = 'Approver',
  Payer = 'Payer',
  UnknownBillAccessRole = 'UnknownBillAccessRole',
  Uploader = 'Uploader'
}

export enum BillStatus {
  Approved = 'Approved',
  Assigned = 'Assigned',
  New = 'New',
  PaymentCreated = 'PaymentCreated',
  Rejected = 'Rejected',
  UnknownBillStatus = 'UnknownBillStatus'
}

export type BillingCardDetails = {
  brand: Scalars['String']['output'];
  country: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BillingCustomerProfile = {
  cardDetails: BillingCardDetails;
  subscription: BillingSubscription;
};

export type BillingLegalEntityLimit = {
  id: Scalars['String']['output'];
  maxLegalEntityLimit?: Maybe<Scalars['Int']['output']>;
  minLegalEntityLimit: Scalars['Int']['output'];
};

export enum BillingPaymentIntentStatus {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Paid = 'Paid',
  UnknownStatus = 'UnknownStatus',
  Upcoming = 'Upcoming'
}

export type BillingPlan = {
  legalEntityLimit?: Maybe<BillingLegalEntityLimit>;
  price: BillingPrice;
  product: BillingProduct;
};

export type BillingPrice = {
  amount: MonetaryValue;
  id: Scalars['String']['output'];
  interval: Interval;
  intervalCount?: Maybe<Scalars['Int']['output']>;
};

export type BillingProduct = {
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: BillingProductName;
  trialPeriodDays?: Maybe<Scalars['Int']['output']>;
};

export enum BillingProductName {
  AIAccounting = 'AIAccounting',
  Free = 'Free',
  FreeForever = 'FreeForever',
  Growth = 'Growth',
  Premium = 'Premium',
  UnknownName = 'UnknownName'
}

export type BillingSubscription = {
  id: Scalars['String']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
};

export type BillingSubscriptionHistory = {
  amount: MonetaryValue;
  createdAt: Scalars['DateTime']['output'];
  interval: Scalars['String']['output'];
  paymentIntentStatus: BillingPaymentIntentStatus;
  productDisplayName: Scalars['String']['output'];
  productName: BillingProductName;
};

export enum BillingSubscriptionStatus {
  Active = 'Active',
  All = 'All',
  Canceled = 'Canceled',
  Incomplete = 'Incomplete',
  IncompleteExpired = 'IncompleteExpired',
  PastDue = 'PastDue',
  Trialing = 'Trialing',
  Unknown = 'Unknown',
  Unpaid = 'Unpaid'
}

export type BillingSubscriptionWithoutStatus = {
  id: Scalars['String']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
};

export type BillingTrial = {
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type BooksClosedDate = {
  latestBooksClosedDateFact?: Maybe<Scalars['DateTime']['output']>;
  legalEntityId: Scalars['String']['output'];
  user?: Maybe<EntityUser>;
  viewBooksClosedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type BooksClosedFact = {
  booksClosedDate: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataSourceId?: Maybe<Scalars['String']['output']>;
  legalEntityId: Scalars['String']['output'];
  superUserId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum CanonicalCategoryType {
  Cash = 'Cash',
  CashNoClearing = 'CashNoClearing',
  Unknown = 'Unknown'
}

export type CashSummary = {
  cashChange: DimensionSummary;
  cashTotal: DimensionSummary;
};

export type CashSummaryChartConfig = {
  origin: ArchiveIntervalOrigin;
};

export type CashSummaryChartConfigInput = {
  origin: IntervalOrigin;
};

/** Categories */
export type Category = {
  active: Scalars['Boolean']['output'];
  ancestors?: Maybe<Array<EntityCategory>>;
  categoryState: CategoryState;
  description: Scalars['String']['output'];
  displayKey: Scalars['String']['output'];
  displayNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentCategoryId?: Maybe<Scalars['String']['output']>;
  productArea?: Maybe<ProductArea>;
  subtype: CategorySubtype;
  synthesized?: Maybe<Scalars['Boolean']['output']>;
  type: CategoryType;
};

/** Balances */
export type CategoryBalanceFilter = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  categoryType?: InputMaybe<CategoryType>;
  includeCategoryChildren?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityId: Scalars['String']['input'];
  productArea?: InputMaybe<ProductArea>;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryChartConfig = {
  asPermanent?: Maybe<Scalars['Boolean']['output']>;
  categoryId: Scalars['String']['output'];
  origin: ArchiveIntervalOrigin;
  withoutCategoryAncestors?: Maybe<Scalars['Boolean']['output']>;
};

export type CategoryChartConfigInput = {
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId: Scalars['String']['input'];
  origin: IntervalOrigin;
  withoutCategoryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryDimension = {
  by: DimensionSummary;
  categoryId: Scalars['String']['output'];
  categoryObject: EntityCategory;
  summary: TransactionSummary;
};

export type CategoryHover = {
  balance?: Maybe<Scalars['Boolean']['output']>;
  context?: Maybe<DimensionSummary>;
  entity: EntityCategory;
  history: DimensionSummary;
  topTransactions?: Maybe<Array<EntityTransaction>>;
};

export type CategoryIntegrations = {
  categoryId: Scalars['String']['output'];
  connect: Array<ConnectionCategoryIntegrations>;
  pay?: Maybe<PayCategoryIntegrations>;
};

/** Inputs */
export type CategoryLabelInput = {
  categoryId: Scalars['String']['input'];
  label: ConnectLabel;
};

/** Category Mappings */
export type CategoryMapping = {
  category: EntityCategory;
  mappingType: CategoryToLabelMappingType;
  mappingValue: Scalars['String']['output'];
  product: CategoryToLabelMappingProduct;
};

export type CategoryParams = {
  categoryIcon?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['String']['input'];
  categoryName: Scalars['String']['input'];
};

export type CategoryRef = {
  id: Scalars['String']['output'];
};

export type CategoryRuleFilter = {
  categories: Array<CategoryRuleFilterCategory>;
  categoryRuleFilterId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  operation: Operation;
};

export type CategoryRuleFilterCategory = {
  categoryId: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
};

export enum CategoryState {
  CategoryActive = 'CategoryActive',
  CategoryDisabled = 'CategoryDisabled',
  CategoryInactive = 'CategoryInactive',
  UnknownCategoryState = 'UnknownCategoryState'
}

export enum CategorySubtype {
  AccountsPayable = 'AccountsPayable',
  AccountsReceivable = 'AccountsReceivable',
  AccruedExpenses = 'AccruedExpenses',
  AccruedRevenue = 'AccruedRevenue',
  AdditionalPaidInCapital = 'AdditionalPaidInCapital',
  AssetClearing = 'AssetClearing',
  BadDebtExpense = 'BadDebtExpense',
  BankAccounts = 'BankAccounts',
  BusinessApplicationsAndSoftware = 'BusinessApplicationsAndSoftware',
  CommonStock = 'CommonStock',
  ConvertibleNotes = 'ConvertibleNotes',
  CostOfGoods = 'CostOfGoods',
  CostOfLabor = 'CostOfLabor',
  CreditCardRewards = 'CreditCardRewards',
  CreditCards = 'CreditCards',
  DeferredRevenue = 'DeferredRevenue',
  DepreciationAndAmortization = 'DepreciationAndAmortization',
  Facilities = 'Facilities',
  FixedAssets = 'FixedAssets',
  GeneralOperations = 'GeneralOperations',
  Goodwill = 'Goodwill',
  HostingFees = 'HostingFees',
  IntangibleAssets = 'IntangibleAssets',
  InterestAndDividends = 'InterestAndDividends',
  InterestExpense = 'InterestExpense',
  Inventory = 'Inventory',
  Investments = 'Investments',
  LiabilityClearing = 'LiabilityClearing',
  LongTermDebt = 'LongTermDebt',
  None = 'None',
  Other = 'Other',
  OtherComprehensiveIncome = 'OtherComprehensiveIncome',
  OtherCostOfRevenue = 'OtherCostOfRevenue',
  OtherCurrentAssets = 'OtherCurrentAssets',
  OtherCurrentLiabilities = 'OtherCurrentLiabilities',
  OtherLongTermAssets = 'OtherLongTermAssets',
  OtherLongTermLiabilities = 'OtherLongTermLiabilities',
  OtherMiscellaneousExpenses = 'OtherMiscellaneousExpenses',
  OtherMiscellaneousIncome = 'OtherMiscellaneousIncome',
  OwnersEquity = 'OwnersEquity',
  PartnerRevenue = 'PartnerRevenue',
  PaymentProcessingFees = 'PaymentProcessingFees',
  Payroll = 'Payroll',
  PayrollExpenses = 'PayrollExpenses',
  Pending = 'Pending',
  PreferredStock = 'PreferredStock',
  PrepaidExpenses = 'PrepaidExpenses',
  ProfessionalFees = 'ProfessionalFees',
  RefundsAndDiscounts = 'RefundsAndDiscounts',
  RetainedEarnings = 'RetainedEarnings',
  Safes = 'Safes',
  SalesAndMarketing = 'SalesAndMarketing',
  SalesRevenue = 'SalesRevenue',
  ShareholderLoans = 'ShareholderLoans',
  ShortTermDebt = 'ShortTermDebt',
  SubscriptionRevenue = 'SubscriptionRevenue',
  SuppliesAndMaterials = 'SuppliesAndMaterials',
  Taxes = 'Taxes',
  TransactionRevenue = 'TransactionRevenue',
  TravelAndEntertainment = 'TravelAndEntertainment',
  TreasuryStock = 'TreasuryStock',
  UncategorizedAssets = 'UncategorizedAssets',
  UncategorizedEquity = 'UncategorizedEquity',
  UncategorizedExpense = 'UncategorizedExpense',
  UncategorizedIncome = 'UncategorizedIncome',
  /** Current values */
  UnknownSubtype = 'UnknownSubtype'
}

/** Category Suggestions */
export type CategorySuggestion = {
  categoryId: Scalars['String']['output'];
  classificationVersionId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataSourceId: Scalars['String']['output'];
  modelConfidence?: Maybe<Scalars['Long']['output']>;
  modelVersionId?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Long']['output'];
  transactionFactId: Scalars['String']['output'];
  type: EntryType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum CategoryToLabelMappingLabel {
  AccountsPayable = 'AccountsPayable',
  AccountsReceivable = 'AccountsReceivable',
  CashAccount = 'CashAccount',
  ClearingAccount = 'ClearingAccount',
  ContraRevenue = 'ContraRevenue',
  Fees = 'Fees',
  SalesRevenue = 'SalesRevenue',
  UncategorizedExpenses = 'UncategorizedExpenses',
  UnknownLabel = 'UnknownLabel'
}

export enum CategoryToLabelMappingProduct {
  DigitsPayAP = 'DigitsPayAP',
  DigitsPayAR = 'DigitsPayAR',
  UnknownProduct = 'UnknownProduct'
}

export enum CategoryToLabelMappingType {
  CategoryLabel = 'CategoryLabel',
  PaymentInstrument = 'PaymentInstrument',
  UnknownType = 'UnknownType'
}

export enum CategoryType {
  Assets = 'Assets',
  CostOfGoodsSold = 'CostOfGoodsSold',
  Equity = 'Equity',
  Expenses = 'Expenses',
  Income = 'Income',
  Liabilities = 'Liabilities',
  OtherExpenses = 'OtherExpenses',
  OtherIncome = 'OtherIncome',
  UnknownType = 'UnknownType'
}

export type CategoryTypeDimension = {
  by: DimensionSummary;
  summary: TransactionSummary;
};

export type Chart = {
  entity: ObjectEntity;
  summary: DimensionSummary;
};

/** Chart of Accounts */
export type ChartOfAccounts = {
  accountingBasis: AccountingBasis;
  categories: Array<Category>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ChartOfAccountsListEntry = {
  accountingBasis: AccountingBasis;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalEntityId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CheckBillingPlan = {
  billingPlan?: Maybe<BillingPlan>;
  exists: Scalars['Boolean']['output'];
  subscriptionDetails?: Maybe<SubscriptionDetails>;
  trial?: Maybe<BillingTrial>;
};

export enum ClassificationSource {
  Digits = 'Digits',
  Manual = 'Manual',
  None = 'None',
  QuickBooks = 'QuickBooks'
}

export type ClosedWorkItem = {
  ignoredFields: Array<WorkItemPushFieldType>;
  workItemId: Scalars['String']['input'];
};

export type CollapsedSections = {
  sections: Array<Scalars['String']['output']>;
};

export type CollapsedSectionsInput = {
  sections: Array<Scalars['String']['input']>;
};

/**
 * Comment represents a single thread comment, with additional
 * properties resolved by the backend.
 */
export type Comment = {
  authorId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  editedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  layoutAttachment?: Maybe<LayoutAttachment>;
  reactions?: Maybe<Array<CommentReaction>>;
  text: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export enum CommentReaction {
  Dislike = 'Dislike',
  Like = 'Like',
  UnknownReaction = 'UnknownReaction'
}

export type CommentWithEntities = {
  comment: Comment;
  entities?: Maybe<ObjectEntities>;
};

export type CompanyDetails = {
  address?: Maybe<Address>;
  categories?: Maybe<Array<Scalars['String']['output']>>;
  companyName: Scalars['String']['output'];
  companySize?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  foreignSubsidiaryCount?: Maybe<Scalars['String']['output']>;
  incorporationState?: Maybe<Scalars['String']['output']>;
  incorporationYear?: Maybe<Scalars['String']['output']>;
  lastTaxFiling?: Maybe<Scalars['String']['output']>;
  subsidiaryCount?: Maybe<Scalars['String']['output']>;
  websiteURL?: Maybe<Scalars['String']['output']>;
};

export type CompanyDetailsInput = {
  address?: InputMaybe<AddressInput>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  companyName: Scalars['String']['input'];
  companySize?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<Scalars['String']['input']>;
  foreignSubsidiaryCount?: InputMaybe<Scalars['String']['input']>;
  incorporationState?: InputMaybe<Scalars['String']['input']>;
  incorporationYear?: InputMaybe<Scalars['String']['input']>;
  lastTaxFiling?: InputMaybe<Scalars['String']['input']>;
  subsidiaryCount?: InputMaybe<Scalars['String']['input']>;
  websiteURL?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyFinancialValue = {
  institutionId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CompanyFinancialValueInput = {
  institutionId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CompanyFinancials = {
  product: InstitutionProduct;
  values: Array<CompanyFinancialValue>;
};

export type CompanyFinancialsInput = {
  product: InstitutionProduct;
  values: Array<CompanyFinancialValueInput>;
};

export enum ComparisonOperator {
  EqualTo = 'EqualTo',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  UnknownComparisonOperator = 'UnknownComparisonOperator'
}

export enum CompileSource {
  SpeedLayer = 'SpeedLayer',
  UnknownCompileSource = 'UnknownCompileSource',
  ViewGeneration = 'ViewGeneration',
  ViewGenerationIncomplete = 'ViewGenerationIncomplete'
}

export type ComponentData = CashSummary | Chart | DimensionSummary | EntitiesList | EntityHovers | InsightComponent | LocationSummary | Runway | Statement | TopEntities | TopTransactions;

/**
 * ComputedEvent represents batches of activity feed events
 * that are counted over a given interval
 */
export type ComputedEvent = {
  eventInterval: ActivityEventInterval;
  kind: ComputedEventKind;
  priority?: Maybe<Scalars['Float']['output']>;
  value: Scalars['Float']['output'];
};

export enum ComputedEventKind {
  AssetInsights = 'AssetInsights',
  ClassifiedCategory = 'ClassifiedCategory',
  ClassifiedCustomer = 'ClassifiedCustomer',
  ClassifiedSupplier = 'ClassifiedSupplier',
  ClassifiedVendor = 'ClassifiedVendor',
  EquityInsights = 'EquityInsights',
  ExpenseInsights = 'ExpenseInsights',
  LiabilityInsights = 'LiabilityInsights',
  MatchedTransactions = 'MatchedTransactions',
  NewRecurringTransactions = 'NewRecurringTransactions',
  NewTransactions = 'NewTransactions',
  NewVendors = 'NewVendors',
  PerformedCalculations = 'PerformedCalculations',
  RevenueInsights = 'RevenueInsights',
  UpdatedVendors = 'UpdatedVendors',
  VendorTransactions = 'VendorTransactions'
}

export enum ConnectLabel {
  Adjustment = 'Adjustment',
  /** Account types */
  Bank = 'Bank',
  BenefitsExpense = 'BenefitsExpense',
  BenefitsPayable = 'BenefitsPayable',
  Clearing = 'Clearing',
  CreditCard = 'CreditCard',
  Deduction = 'Deduction',
  DigitsAI = 'DigitsAI',
  Fees = 'Fees',
  Investment = 'Investment',
  Loan = 'Loan',
  Reimbursement = 'Reimbursement',
  Sales = 'Sales',
  Settlement = 'Settlement',
  TaxExpense = 'TaxExpense',
  UncategorizedExpense = 'UncategorizedExpense',
  Unspecified = 'Unspecified',
  Wages = 'Wages'
}

export type Connection = {
  accounts?: Maybe<Array<ConnectionAccount>>;
  connector?: Maybe<Connector>;
  connectorClassName?: Maybe<Scalars['String']['output']>;
  dataSourceId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  sourceIconURL?: Maybe<Scalars['String']['output']>;
  sourceName: Scalars['String']['output'];
  /** stripe, plaid */
  status: ConnectionStatus;
  sync?: Maybe<ConnectionSyncState>;
};

export type ConnectionAccount = {
  connectionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<ConnectionAccountSettings>;
  sourceAccountId: Scalars['String']['output'];
};

export type ConnectionAccountInput = {
  connectionAccountId: Scalars['String']['input'];
  settings: ConnectionAccountSettingsInput;
};

export type ConnectionAccountSettings = {
  cutoverDate?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  labelCategories?: Maybe<Array<LabelCategory>>;
};

export type ConnectionAccountSettingsInput = {
  categoryLabels?: InputMaybe<Array<CategoryLabelInput>>;
  cutoverDate?: InputMaybe<Scalars['DateTime']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConnectionAccountSummary = {
  connectionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sourceAccountId: Scalars['String']['output'];
};

export type ConnectionBadgeCountFilter = {
  connectionStatuses?: InputMaybe<Array<ConnectionStatus>>;
  legalEntityIds: Array<Scalars['String']['input']>;
  syncStatuses?: InputMaybe<Array<ConnectionSyncStatus>>;
};

export type ConnectionCategoryIntegrations = {
  connectionAccountSummaries: Array<ConnectionAccountSummary>;
  party?: Maybe<EntityParty>;
};

export type ConnectionSettingsInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ConnectionStatus {
  ActivationPending = 'ActivationPending',
  Archived = 'Archived',
  ConfigurationPending = 'ConfigurationPending',
  DelayedActivation = 'DelayedActivation',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  SystemDisabled = 'SystemDisabled',
  Unknown = 'Unknown'
}

export type ConnectionSummary = {
  accounts?: Maybe<Array<ConnectionAccountSummary>>;
  connector?: Maybe<Connector>;
  connectorClassName?: Maybe<Scalars['String']['output']>;
  dataSourceId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  sourceIconURL?: Maybe<Scalars['String']['output']>;
  sourceName: Scalars['String']['output'];
  /** stripe, plaid */
  status: ConnectionStatus;
  sync?: Maybe<ConnectionSyncState>;
};

export type ConnectionSyncState = {
  lastEventAt?: Maybe<Scalars['DateTime']['output']>;
  lastSuccessAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<ConnectionSyncStatus>;
};

export enum ConnectionSyncStatus {
  Configured = 'Configured',
  Delayed = 'Delayed',
  Failed = 'Failed',
  LoginRequired = 'LoginRequired',
  Success = 'Success'
}

export type ConnectionTemplate = {
  connector: Connector;
  institution: ProductInstitution;
};

export type Connector = {
  category: ConnectorCategory;
  className?: Maybe<Scalars['String']['output']>;
  connectorProperties?: Maybe<ConnectorProperties>;
  description?: Maybe<Scalars['String']['output']>;
  iconURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ConnectorCategory {
  BanksAndCards = 'BanksAndCards',
  CategoryUnspecified = 'CategoryUnspecified',
  Ecommerce = 'Ecommerce',
  ExpenseManagement = 'ExpenseManagement',
  Payment = 'Payment',
  Payroll = 'Payroll'
}

export type ConnectorProperties = EmptyConnectorProperties | GustoConnectorProperties | PlaidConnectorProperties | RampConnectorProperties | StripeConnectorProperties;

export type Contact = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  emailAddress: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
};

export type ContractLineItemInput = {
  amount: InputMonetaryValue;
  recurrence: ProductRecurrence;
  stripeProductId: Scalars['String']['input'];
};

export enum CoverLogoSource {
  AccountingFirm = 'AccountingFirm',
  Client = 'Client'
}

export type CreateCategoryInput = {
  chartOfAccountsDisplayNumber: Scalars['String']['input'];
  description: Scalars['String']['input'];
  displayKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentCategoryId: Scalars['String']['input'];
  subtype: CategorySubtype;
  type: CategoryType;
};

export type CreateCategoryResponse = {
  categoryId: Scalars['String']['output'];
};

export type CreateConnectionResponse = {
  connection: Connection;
};

export type CreateTransactionResponse = {
  transactionFactIds: Array<Scalars['String']['output']>;
};

export type CreatedLegalEntity = {
  legalEntity?: Maybe<LegalEntity>;
  quickBooksCompany?: Maybe<QuickBooksCompany>;
};

export type CreatedQuickBooksDataSource = {
  dataSource?: Maybe<EnrichedDataSource>;
  quickBooksCompany?: Maybe<QuickBooksCompany>;
};

export enum CreationMethod {
  Auto = 'Auto',
  Manual = 'Manual',
  Unknown = 'Unknown',
  Validation = 'Validation'
}

export type CreditCard = {
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  nickname: Scalars['String']['output'];
};

export type CsvExportField = {
  fieldName: CsvExportFieldName;
  label?: InputMaybe<Scalars['String']['input']>;
};

export enum CsvExportFieldName {
  CategoryName = 'CategoryName',
  CategoryNumber = 'CategoryNumber',
  Memo = 'Memo',
  Party1099Status = 'Party1099Status',
  PartyEntityType = 'PartyEntityType',
  PartyName = 'PartyName',
  PartyW9Status = 'PartyW9Status',
  SplitCategoryName = 'SplitCategoryName',
  SplitCategoryNumber = 'SplitCategoryNumber',
  TaxStatus = 'TaxStatus',
  TransactionAmount = 'TransactionAmount',
  TransactionCreditAmount = 'TransactionCreditAmount',
  TransactionDate = 'TransactionDate',
  TransactionDebitAmount = 'TransactionDebitAmount'
}

export type CsvImport = {
  createdAt: Scalars['DateTime']['output'];
  csvImportId: Scalars['String']['output'];
  dataSourceId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileName: Scalars['String']['output'];
  source: Scalars['String']['output'];
  transactionCount: Scalars['Long']['output'];
};

export type CsvImportSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<CsvImportSortOrderBy>;
};

export enum CsvImportSortOrderBy {
  CreatedAt = 'CreatedAt',
  FileName = 'FileName',
  TransactionCount = 'TransactionCount'
}

export type Customer = InvoiceCustomerInterface & {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type CustomerAddress = {
  address: Address;
  id: Scalars['String']['output'];
};

/** Customer Events (Logs) */
export type CustomerEvent = {
  eventId: Scalars['String']['input'];
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  occurredAt: Scalars['DateTime']['input'];
  payload: CustomerEventPayload;
};

export type CustomerEventPayload = {
  marketingEvent?: InputMaybe<MarketingEvent>;
  pageView?: InputMaybe<PageView>;
  reportAction?: InputMaybe<ReportActionEvent>;
  signUpEvent?: InputMaybe<SignUpEvent>;
};

export type CustomerSummary = InvoiceCustomerSummaryInterface & {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DataSource = {
  accountAlias?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  institution: Institution;
  legalEntityId: Scalars['String']['output'];
  providerType: ProviderType;
  status: DataSourceStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DataSourcePreference = {
  accountingBasis: AccountingBasis;
  dataSourceId: Scalars['String']['output'];
  departmentTerminology?: Maybe<Scalars['String']['output']>;
  providerType: ProviderType;
  taxForm: TaxForm;
};

export enum DataSourceStatus {
  Archived = 'Archived',
  Deleted = 'Deleted',
  EngineeringRequired = 'EngineeringRequired',
  Linked = 'Linked',
  LogInRequired = 'LogInRequired',
  Unknown = 'Unknown',
  Unsupported = 'Unsupported'
}

export type DataSources = {
  legalEntityId: Scalars['String']['output'];
  sources: Array<EnrichedDataSource>;
};

export type Date = {
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type DateFeatureValue = {
  dateValue: Date;
};

export type DateInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** DayOfMonth */
export enum DayOfMonth {
  Fifteenth = 'Fifteenth',
  First = 'First',
  Last = 'Last'
}

/** DayOfWeek */
export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type DeletedAmountFilter = {
  amountRuleFilterId: Scalars['String']['input'];
  operator: ComparisonOperator;
};

export type DeletedCategory = {
  categoryId: Scalars['String']['input'];
  operation: Operation;
};

export type DeletedCategoryFilter = {
  categoryRuleFilterId: Scalars['String']['input'];
  operation: Operation;
};

export type DenormalizedViewStatus = {
  activeViewCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  legalEntityId: Scalars['String']['output'];
  ongoingViewStartedAt?: Maybe<Scalars['DateTime']['output']>;
  viewType: ViewType;
};

export type DenormalizedViewStatusSummary = {
  entries: Array<DenormalizedViewStatus>;
};

export type Department = {
  active: Scalars['Boolean']['output'];
  ancestors: Array<EntityDepartment>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use parentId instead */
  parentDepartmentId?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type DepartmentChartConfig = {
  departmentId: Scalars['String']['output'];
  origin: ArchiveIntervalOrigin;
};

export type DepartmentChartConfigInput = {
  departmentId: Scalars['String']['input'];
  origin: IntervalOrigin;
};

export type DepartmentDimension = {
  by: DimensionSummary;
  departmentId: Scalars['String']['output'];
  departmentObject: EntityDepartment;
  summary: TransactionSummary;
};

export type DigitsWorkItemPushParams = {
  categoryParams?: InputMaybe<CategoryParams>;
  partyParams?: InputMaybe<PartyParams>;
  skipClose?: InputMaybe<Scalars['Boolean']['input']>;
  workItemId: Scalars['String']['input'];
};

export enum DimensionSortMode {
  Active = 'Active',
  Growing = 'Growing',
  Shrinking = 'Shrinking',
  Total = 'Total'
}

export type DimensionSummary = {
  category: Array<CategoryDimension>;
  categoryType: Array<CategoryTypeDimension>;
  department: Array<DepartmentDimension>;
  location: Array<LocationDimension>;
  partialData: Scalars['Boolean']['output'];
  party: Array<PartyDimension>;
  /**
   * Summary implements dimensionless top-level summaries.
   * All other summaries are in the dimension fields above.
   */
  summary: TransactionSummary;
  /**
   * Each below Dimension field allows queries to organize their output
   * by specifying each field in the structural order desired.
   */
  time: Array<TimeDimension>;
};


export type DimensionSummaryCategoryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryCategoryTypeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryDepartmentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryLocationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryPartyArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryTimeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};

export enum DirectionFromOrigin {
  Future = 'Future',
  Past = 'Past'
}

export type Discount = {
  discountId: Scalars['String']['output'];
  fixedDiscountAmount?: Maybe<MonetaryValue>;
  name: Scalars['String']['output'];
  providerType: ProviderType;
  variablePercentage?: Maybe<Scalars['Float']['output']>;
};

/**
 * DiscreteEvent represents one-time activity feed events
 * that are created via our go-services APIs
 */
export type DiscreteEvent = {
  actorUserId: Scalars['String']['output'];
  kind: DiscreteEventKind;
  subject: ActivityObjectIdentifier;
  target: ActivityObjectIdentifier;
  targetEmailAddress?: Maybe<Scalars['String']['output']>;
};

export enum DiscreteEventKind {
  CreatedGrant = 'CreatedGrant',
  CreatedIntent = 'CreatedIntent',
  /**
   * DisabledLink
   * RejectedRequest
   */
  CreatedReportPackage = 'CreatedReportPackage',
  /** RegeneratedReportPackage */
  DeletedReportPackage = 'DeletedReportPackage',
  IntegrationFixed = 'IntegrationFixed',
  NewComment = 'NewComment',
  NewIntegration = 'NewIntegration',
  NewReport = 'NewReport',
  NewUser = 'NewUser',
  /**
   * CreatedReport
   * ViewedReport
   * UpdatedReport
   * RegeneratedReport
   */
  PublishedDraftReportPackage = 'PublishedDraftReportPackage',
  PublishedFinalReportPackage = 'PublishedFinalReportPackage',
  /**
   * FixedIntegration
   * CreatedIntegration
   * CreatedUser
   * CreatedThread
   * CreatedComment
   */
  ResolvedThread = 'ResolvedThread',
  RevokedGrant = 'RevokedGrant',
  /**
   * UpdatedLink
   * AcceptedRequest
   */
  RevokedIntent = 'RevokedIntent',
  UpdatedGrant = 'UpdatedGrant',
  /**
   * CreatedLink
   * CreatedRequest
   */
  UpdatedIntent = 'UpdatedIntent',
  UpdatedReportPackage = 'UpdatedReportPackage',
  VendorEdited = 'VendorEdited',
  ViewedReportPackage = 'ViewedReportPackage'
}

export enum DisplayEntry {
  Credit = 'Credit',
  Debit = 'Debit',
  TransferCredit = 'TransferCredit',
  TransferDebit = 'TransferDebit',
  Unknown = 'Unknown'
}

export type DocumentCollectionConfig = {
  collectionId: Scalars['String']['output'];
};

export type DocumentCollectionConfigInput = {
  collectionId: Scalars['String']['input'];
};

export type DocumentDownload = {
  url: Scalars['String']['output'];
};

export type DocumentFilter = {
  creatorId?: InputMaybe<Scalars['String']['input']>;
  documentCollectionId?: InputMaybe<Scalars['String']['input']>;
  documentTypes?: InputMaybe<Array<DocumentType>>;
  featureKinds?: InputMaybe<Array<FeatureKind>>;
  mimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DocumentInitialSegment = {
  collectionId: Scalars['String']['output'];
  documentId: Scalars['String']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
};

export type DocumentLineItem = {
  amount: MonetaryValue;
  categoryId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  position: Scalars['Long']['output'];
  quantity: Scalars['Long']['output'];
  rate: MonetaryValue;
};

export type DocumentSort = {
  direction?: InputMaybe<SortDirection>;
  field: DocumentSortBy;
};

export enum DocumentSortBy {
  CreatedAt = 'CreatedAt',
  DocumentType = 'DocumentType',
  FileName = 'FileName',
  FileSize = 'FileSize',
  Title = 'Title'
}

export enum DocumentStatus {
  Done = 'Done',
  New = 'New',
  Processing = 'Processing',
  UnknownStatus = 'UnknownStatus'
}

export enum DocumentType {
  Bill = 'Bill',
  CheckingAccount = 'CheckingAccount',
  Compliance = 'Compliance',
  Contract = 'Contract',
  CreditCard = 'CreditCard',
  Equity = 'Equity',
  Form1099 = 'Form1099',
  FormW9 = 'FormW9',
  Invoice = 'Invoice',
  K1 = 'K1',
  LoanAgreement = 'LoanAgreement',
  Payroll = 'Payroll',
  Receipt = 'Receipt',
  SavingsAccount = 'SavingsAccount',
  Schedule = 'Schedule',
  TaxID = 'TaxID',
  TaxReturn = 'TaxReturn',
  UnknownType = 'UnknownType',
  Valuation = 'Valuation'
}

export type DocumentsSummary = {
  count: Scalars['Long']['output'];
};

export type DoppelgangerPermit = {
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: User;
};

export type DoubleEntry = {
  firstEntryCategoryId: Scalars['String']['input'];
  secondEntryCategoryId: Scalars['String']['input'];
  withoutFirstEntryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutSecondEntryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DraftCustomer = InvoiceCustomerInterface & {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type DraftCustomerSummary = InvoiceCustomerSummaryInterface & {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DraftInvoice = {
  customer?: Maybe<DraftCustomer>;
  discounts?: Maybe<Array<InvoiceDiscount>>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<DraftInvoicingProfile>;
  lineItems?: Maybe<Array<DraftInvoiceLineItem>>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
  validationErrors?: Maybe<Array<DraftInvoiceValidationError>>;
};

export type DraftInvoiceLineItem = {
  description?: Maybe<Scalars['String']['output']>;
  discounts?: Maybe<Array<InvoiceDiscount>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Long']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Long']['output'];
  taxable: Scalars['Boolean']['output'];
  unitPrice: MonetaryValue;
};

export type DraftInvoiceSummary = InvoiceSummaryInterface & {
  amount: EntityMonetaryValue;
  createdAt: Scalars['DateTime']['output'];
  customer: DraftCustomerSummary;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  status: InvoiceStatus;
};

export enum DraftInvoiceValidationError {
  DuplicateInvoiceNumber = 'DuplicateInvoiceNumber',
  InvoiceNumberMissingDigit = 'InvoiceNumberMissingDigit',
  InvoiceNumberTooLong = 'InvoiceNumberTooLong',
  InvoiceNumberTooShort = 'InvoiceNumberTooShort',
  InvoiceNumberWhitespace = 'InvoiceNumberWhitespace',
  Unknown = 'Unknown'
}

export type DraftInvoicingProfile = InvoicingProfileInterface & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceNumberFormat?: Maybe<Scalars['String']['output']>;
  invoiceNumberFormatDefault: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type EmailStatus = {
  rawStatus: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Employee = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  hiddenAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invitation?: Maybe<Invitation>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  role?: Maybe<Role>;
  status: EmployeeStatus;
  user?: Maybe<User>;
};

export type EmployeeAffiliations = {
  affiliations: Array<Affiliation>;
  employee: Employee;
};

export type EmployeeFilter = {
  employeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<EmployeeStatus>>;
  visibility?: InputMaybe<EmployeeVisibility>;
};

export type EmployeeIdentifier = {
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export enum EmployeeOrigin {
  Bootstrapped = 'Bootstrapped',
  External = 'External',
  Invitation = 'Invitation',
  Manual = 'Manual',
  Synthesized = 'Synthesized',
  UnknownOrigin = 'UnknownOrigin'
}

export type EmployeeSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<EmployeeSortOrderBy>;
};

export enum EmployeeSortOrderBy {
  Name = 'Name',
  Status = 'Status'
}

export enum EmployeeStatus {
  Activated = 'Activated',
  Deactivated = 'Deactivated',
  Initialized = 'Initialized',
  UnknownStatus = 'UnknownStatus'
}

export enum EmployeeVisibility {
  Active = 'Active',
  All = 'All',
  Hidden = 'Hidden'
}

export type EmptyConnectorProperties = {
  empty?: Maybe<Scalars['Boolean']['output']>;
};

export type EnrichedDataSource = {
  accountAlias?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  hasTransactions: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  institution: Party;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  legalEntityId: Scalars['String']['output'];
  plaidConfig?: Maybe<PlaidConfig>;
  providerType: ProviderType;
  quickBooksConfig?: Maybe<QuickBooksConfig>;
  sourceAccounts?: Maybe<Array<SourceAccount>>;
  status: DataSourceStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type EntitiesList = {
  entities: ObjectEntities;
};

export type EntitiesListConfig = {
  entities: ObjectEntities;
  origin: ArchiveIntervalOrigin;
};

export type EntityBill = {
  approvalAccess?: Maybe<BillAccess>;
  billDescription: Scalars['String']['output'];
  billStatus: BillStatus;
  dueDate?: Maybe<Date>;
  format: Format;
  id: Scalars['String']['output'];
  totalAmount: EntityMonetaryValue;
  vendorName: Scalars['String']['output'];
};

export type EntityCategory = {
  active: Scalars['Boolean']['output'];
  chartOfAccountsDisplayNumber?: Maybe<Scalars['String']['output']>;
  displayKey: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentCategoryId?: Maybe<Scalars['String']['output']>;
  productArea?: Maybe<ProductArea>;
  subtype: CategorySubtype;
  type: CategoryType;
};

/** Comments */
export type EntityComment = {
  authorId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type EntityDataSource = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type EntityDepartment = {
  active: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use parentId */
  parentDepartmentId?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

/** Employees */
export type EntityEmployee = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type EntityHovers = {
  hovers: Array<Hover>;
};

export type EntityIdentityVerification = {
  legalEntityId: Scalars['String']['output'];
  status: IdentityVerificationStatus;
};

/** Invoices */
export type EntityInvoice = {
  amount: EntityMonetaryValue;
  createdAt: Scalars['DateTime']['output'];
  customer: EntityInvoiceCustomer;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  externalDisplayId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type EntityInvoiceCustomer = {
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  party: EntityParty;
  phone?: Maybe<Scalars['String']['output']>;
};

export type EntityLedgerTransaction = {
  factIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type EntityLegalEntity = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type EntityLocation = {
  active: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
};

export type EntityMonetaryValue = {
  amount: Scalars['Long']['output'];
  currencyMultiplier: Scalars['Long']['output'];
  iso4217CurrencyCode: Scalars['String']['output'];
};

export type EntityOrganization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** Parties */
export type EntityParty = {
  designation: PartyDesignation;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  roles?: Maybe<Array<PartyRole>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
};

/** Reports */
export type EntityReport = {
  authorId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  reportFor: Date;
  title: Scalars['String']['output'];
};

export type EntityReportPackage = {
  authorId: Scalars['String']['output'];
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  documents: Array<SharedReportDocumentMetadata>;
  id: Scalars['String']['output'];
  interval: Interval;
  periodEndedAt: Scalars['DateTime']['output'];
  periodStartedAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EntityThread = {
  id: Scalars['String']['output'];
  resolvedAt: Scalars['DateTime']['output'];
};

/**
 * ObjectEntities
 * Note: some definitions exist in shared/common/
 * Transactions
 */
export type EntityTransaction = {
  description?: Maybe<Scalars['String']['output']>;
  displayCategory: EntityCategory;
  displayEntry: DisplayEntry;
  /** @deprecated Use moneyFlow */
  displayValue?: Maybe<EntityMonetaryValue>;
  externalId?: Maybe<Scalars['String']['output']>;
  factId?: Maybe<Scalars['String']['output']>;
  moneyFlow: MoneyFlow;
  name?: Maybe<Scalars['String']['output']>;
  party?: Maybe<EntityParty>;
  splitCategory?: Maybe<EntityCategory>;
  timestamp: Scalars['DateTime']['output'];
  viewId: Scalars['String']['output'];
};

export type EntityUser = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** Work Items */
export type EntityWorkItem = {
  id: Scalars['String']['output'];
  ledgerType: LedgerType;
  objectTransaction: EntityTransaction;
  reviewCreatedById: Scalars['String']['output'];
  reviewState?: Maybe<WorkItemReviewState>;
  time: Scalars['DateTime']['output'];
  workItemState: WorkItemState;
  workItemType: WorkItemType;
};

export enum EntryType {
  Credit = 'Credit',
  Debit = 'Debit',
  Unknown = 'Unknown'
}

export enum EventType {
  /** Invitations */
  AffiliateLegalEntityInvitation = 'AffiliateLegalEntityInvitation',
  AffiliationsCreated = 'AffiliationsCreated',
  AffiliationsInvitationCreated = 'AffiliationsInvitationCreated',
  ApplicationApproved = 'ApplicationApproved',
  ApplicationSubmitted = 'ApplicationSubmitted',
  /** Signups */
  ApplicationSubmittedByInvitee = 'ApplicationSubmittedByInvitee',
  BoostSummary = 'BoostSummary',
  CashBalanceAlert = 'CashBalanceAlert',
  ConfirmEmail = 'ConfirmEmail',
  ConfirmPasswordReset = 'ConfirmPasswordReset',
  /** Password Management */
  CreatePasswordReset = 'CreatePasswordReset',
  InitialInvitation = 'InitialInvitation',
  InstructionsAffiliateInvitation = 'InstructionsAffiliateInvitation',
  InstructionsInvitation = 'InstructionsInvitation',
  LedgerTransactionComment = 'LedgerTransactionComment',
  LedgerTransactionMention = 'LedgerTransactionMention',
  ObjectAccessAccepted = 'ObjectAccessAccepted',
  ObjectAccessApproved = 'ObjectAccessApproved',
  ObjectAccessRequested = 'ObjectAccessRequested',
  ObjectComment = 'ObjectComment',
  /** Sharing */
  ObjectGrantCreated = 'ObjectGrantCreated',
  ObjectMention = 'ObjectMention',
  PlanInvitation = 'PlanInvitation',
  ReportComment = 'ReportComment',
  ReportCommentThreadResolved = 'ReportCommentThreadResolved',
  ReportMention = 'ReportMention',
  ReportsCreated = 'ReportsCreated',
  SystemReportCreated = 'SystemReportCreated',
  TransactionMention = 'TransactionMention',
  TransactionQuestionsAsked = 'TransactionQuestionsAsked',
  TransactionQuestionsReminder = 'TransactionQuestionsReminder',
  UnknownEvent = 'UnknownEvent',
  UserInvitation = 'UserInvitation',
  UserLockedOut = 'UserLockedOut',
  WorkItemComment = 'WorkItemComment'
}

export type ExistingReportAttachment = {
  position: Scalars['Int']['input'];
  reportId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export type Feature = {
  featurePage: Scalars['Long']['output'];
  height: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  invalid: Scalars['Boolean']['output'];
  kind: FeatureKind;
  originX: Scalars['Float']['output'];
  originY: Scalars['Float']['output'];
  value: FeatureValue;
  width: Scalars['Float']['output'];
};

export enum FeatureKind {
  AccountNumber = 'AccountNumber',
  BillCategory = 'BillCategory',
  BusinessAddress = 'BusinessAddress',
  /** W9 feature kinds */
  BusinessName = 'BusinessName',
  CheckInfo = 'CheckInfo',
  DocumentCustomer = 'DocumentCustomer',
  DocumentDate = 'DocumentDate',
  DocumentKeyword = 'DocumentKeyword',
  DocumentParty = 'DocumentParty',
  /** generic document feature kinds */
  DocumentRawText = 'DocumentRawText',
  DocumentReference = 'DocumentReference',
  DocumentSummary = 'DocumentSummary',
  DocumentType = 'DocumentType',
  DocumentVendor = 'DocumentVendor',
  DueDate = 'DueDate',
  EndingBalance = 'EndingBalance',
  EndingDate = 'EndingDate',
  InstitutionAccountNumber = 'InstitutionAccountNumber',
  /** bank statement feature kinds */
  InstitutionName = 'InstitutionName',
  InvoiceDate = 'InvoiceDate',
  InvoiceId = 'InvoiceId',
  LineItem = 'LineItem',
  Memo = 'Memo',
  Other = 'Other',
  OtherPaymentInfo = 'OtherPaymentInfo',
  PayerAddress = 'PayerAddress',
  PayerCompanyName = 'PayerCompanyName',
  PayerEmail = 'PayerEmail',
  PayerName = 'PayerName',
  PayerPhoneNumber = 'PayerPhoneNumber',
  PaymentInstructions = 'PaymentInstructions',
  RemittanceAddress = 'RemittanceAddress',
  RemittanceInformation = 'RemittanceInformation',
  /** contract feature kinds */
  Schedule = 'Schedule',
  StartingBalance = 'StartingBalance',
  StartingDate = 'StartingDate',
  TaxNumber = 'TaxNumber',
  Terms = 'Terms',
  Title = 'Title',
  TotalAmount = 'TotalAmount',
  TotalCredits = 'TotalCredits',
  TotalDebits = 'TotalDebits',
  TotalDeposits = 'TotalDeposits',
  TotalFees = 'TotalFees',
  TotalWithdrawals = 'TotalWithdrawals',
  UnknownKind = 'UnknownKind',
  VendorAddress = 'VendorAddress',
  VendorEmail = 'VendorEmail',
  VendorName = 'VendorName',
  VendorPhoneNumber = 'VendorPhoneNumber',
  VendorWebsite = 'VendorWebsite',
  WireInfo = 'WireInfo'
}

export type FeatureValue = AddressFeatureValue | DateFeatureValue | LineItemFeatureValue | MonetaryFeatureValue | NumericFeatureValue | RemittanceFeatureValue | TextFeatureValue;

export enum FetchType {
  Backfill = 'Backfill',
  ChangeDataCapture = 'ChangeDataCapture',
  Onboard = 'Onboard',
  Refresh = 'Refresh'
}

export type FirstTransactionOccurrence = {
  firstTransactionOccurredAt: Scalars['DateTime']['output'];
};

export enum Flow {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  UnknownFlow = 'UnknownFlow'
}

export type Format = {
  formatClass?: Maybe<FormatClass>;
  id: Scalars['String']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  segments: Array<Segment>;
};

export enum FormatClass {
  AnnotatedTableJson = 'AnnotatedTableJson',
  CSV = 'CSV',
  Email = 'Email',
  ImageDocument = 'ImageDocument',
  ImageLarge = 'ImageLarge',
  ImageSmall = 'ImageSmall',
  ImageThumbnail = 'ImageThumbnail',
  LabelJson = 'LabelJson',
  OcrJson = 'OcrJson',
  OcrProto = 'OcrProto',
  PDF = 'PDF',
  RawMIME = 'RawMIME'
}

export enum GenerationStatus {
  Failed = 'Failed',
  InProgress = 'InProgress',
  Success = 'Success',
  UnknownStatus = 'UnknownStatus'
}

export enum GrantPolicy {
  Allow = 'Allow',
  Deny = 'Deny'
}

export type GustoConnectorProperties = {
  authorizationURL: Scalars['String']['output'];
};

export enum HandleNone {
  Both = 'Both',
  Either = 'Either',
  Ignore = 'Ignore'
}

export type HighlightedToken = {
  score: Scalars['Long']['output'];
  token: Scalars['String']['output'];
};

export type HighlightedTokens = {
  description?: Maybe<Array<HighlightedToken>>;
  name?: Maybe<Array<HighlightedToken>>;
};

/**
 * Highlight describes the positional offsets of a search term
 * within the result document, which will allow clients to highlight
 * that term during presentation of the result.
 */
export type HitHighlight = {
  end: Scalars['Long']['output'];
  field: Scalars['String']['output'];
  start: Scalars['Long']['output'];
  term: Scalars['String']['output'];
};

export type Hover = CategoryHover | PartyHover | SummaryLineItemHover;

/** IdentifiersByKind represents a kind, legalEntityId and list of object ids */
export type IdentifiersByKind = {
  ids: Array<Scalars['String']['input']>;
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};

export type Identity = {
  domain?: Maybe<Scalars['String']['output']>;
  identifier: Scalars['String']['output'];
  provider: OAuthService;
};

export enum IdentityVerificationStatus {
  ApplicationReview = 'ApplicationReview',
  Approved = 'Approved',
  Denied = 'Denied',
  IdentityVerificationStatusUnknown = 'IdentityVerificationStatusUnknown',
  Ineligible = 'Ineligible',
  Pending = 'Pending'
}

export type IdentityVerificationStatusResult = {
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  status: IdentityVerificationStatus;
};

export type ImportedEmployees = {
  employees: Array<Employee>;
  newEmployeeCount: Scalars['Int']['output'];
};

export enum IngestSource {
  BankStatementUpload = 'BankStatementUpload',
  BillPayEmail = 'BillPayEmail',
  BillPayUpload = 'BillPayUpload',
  Report = 'Report',
  Vault = 'Vault'
}

export type InputAccountReconciliation = {
  abnormalTotal?: InputMaybe<InputMonetaryValue>;
  categoryId: Scalars['String']['input'];
  closingBalance: InputMonetaryValue;
  endDate: DateInput;
  normalTotal?: InputMaybe<InputMonetaryValue>;
};

export type InputAccountReconciliationUpdate = {
  abnormalTotal?: InputMaybe<InputReconciliationAmount>;
  closingBalance?: InputMaybe<InputMonetaryValue>;
  /** fields used for reconfiguring a reconciliation */
  endDate?: InputMaybe<DateInput>;
  /** fields used for reconfiguring or updating a reconciliation */
  normalTotal?: InputMaybe<InputReconciliationAmount>;
};

export type InputAccountReconciliationsFilter = {
  direction: DirectionFromOrigin;
  hasActivity: Scalars['Boolean']['input'];
  origin: IntervalOrigin;
};

export type InputAmountRuleFilter = {
  amount: InputMonetaryValue;
  amountRuleFilterId?: InputMaybe<Scalars['String']['input']>;
  operator: ComparisonOperator;
};

export type InputCategoryRuleFilter = {
  categories: Array<InputCategoryRuleFilterCategory>;
  categoryRuleFilterId?: InputMaybe<Scalars['String']['input']>;
  operation: Operation;
};

export type InputCategoryRuleFilterCategory = {
  categoryId: Scalars['String']['input'];
  categoryName: Scalars['String']['input'];
};

/** # TODO - should be unified with Party/NewParty once addresses land */
export type InputCustomer = {
  address: InputCustomerAddress;
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  providerType: ProviderType;
};

export type InputCustomerAddress = {
  address: AddressInput;
  id: Scalars['String']['input'];
};

export type InputDataSourceDiscount = {
  discountId: Scalars['String']['input'];
  fixedDiscountAmount?: InputMaybe<InputMonetaryValue>;
  name: Scalars['String']['input'];
  providerType: ProviderType;
  variablePercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type InputInvoice = {
  customerAddressId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  discounts?: InputMaybe<Array<InputInvoiceDiscount>>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  invoiceDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceNumber: Scalars['String']['input'];
  lineItems: Array<InputInvoiceLineItem>;
  memo?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['String']['input'];
  status: InvoiceStatus;
  taxPercent?: InputMaybe<Scalars['Float']['input']>;
};

export type InputInvoiceDiscount = {
  /** frontend generated stableId */
  discountId: Scalars['String']['input'];
  fixedDiscountAmount?: InputMaybe<InputMonetaryValue>;
  invoiceDiscountId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  providerType: ProviderType;
  variablePercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type InputInvoiceLineItem = {
  description?: InputMaybe<Scalars['String']['input']>;
  discounts?: InputMaybe<Array<InputInvoiceDiscount>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['Long']['input'];
  productId?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  taxable: Scalars['Boolean']['input'];
  unitPrice: InputMonetaryValue;
};

export type InputInvoiceProduct = {
  id: Scalars['String']['input'];
  incomeCategoryId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  providerType: ProviderType;
  unitPrice?: InputMaybe<InputMonetaryValue>;
};

export type InputInvoicingAddress = {
  address: AddressInput;
  id: Scalars['String']['input'];
};

export type InputInvoicingProfile = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  companyAddress: InputInvoicingAddress;
  companyEmail: Scalars['String']['input'];
  companyIcon?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  coverURL?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  invoiceNumberFormat?: InputMaybe<Scalars['String']['input']>;
  payoutInstrumentID: Scalars['String']['input'];
};

export type InputMonetaryValue = {
  amount: Scalars['Long']['input'];
  currencyMultiplier: Scalars['Long']['input'];
  iso4217CurrencyCode: Scalars['String']['input'];
};

/**
 * ObjectIdentifier is a generic identifier with an enumerated kind.
 * It can be used to identify different Digits' core objects.
 */
export type InputObjectIdentifier = {
  id: Scalars['String']['input'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};

export type InputPartyRuleFilter = {
  operation: Operation;
  parties: Array<InputPartyRuleFilterParty>;
  partyRuleFilterId?: InputMaybe<Scalars['String']['input']>;
};

export type InputPartyRuleFilterParty = {
  partyId: Scalars['String']['input'];
  partyName: Scalars['String']['input'];
};

export type InputReconciliationAmount = {
  amount?: InputMaybe<InputMonetaryValue>;
  delete: Scalars['Boolean']['input'];
};

export type InputStatusUpdate = {
  categoryId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
  status: TransactionReconciliationStatus;
  transactionFactId: Scalars['String']['input'];
};

/** # input types */
export type InputTransaction = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  lines: Array<InputTransactionLine>;
  memo: Scalars['String']['input'];
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['DateTime']['input'];
  type: TransactionType;
};

export type InputTransactionLine = {
  amount: InputMonetaryValue;
  categoryId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  entryType: EntryType;
  id: Scalars['String']['input'];
  lineItemId: Scalars['String']['input'];
  /**
   * newParty is optional
   * if provided (and partyId is blank) a new party will be created and associated with the transaction line
   */
  newParty?: InputMaybe<Scalars['String']['input']>;
  /** party classification is optional */
  partyId?: InputMaybe<Scalars['String']['input']>;
};

export type InputUpdateFields = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  digitsTransactionType: TransactionType;
  entryType: EntryType;
  /** optional fields to update */
  partyId?: InputMaybe<Scalars['String']['input']>;
  splitCategoryId?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<TaxStatus>;
  transactionFactId: Scalars['String']['input'];
};

export type InputUpdatePartyFields = {
  entityType?: InputMaybe<PartyEntityType>;
  partyId: Scalars['String']['input'];
  /** optional fields to update */
  taxpayerIdentification?: InputMaybe<TaxpayerIdentification>;
};

export type Insight = {
  absoluteDelta?: Maybe<MonetaryValue>;
  comparisonIntervalOffset: Scalars['Long']['output'];
  comparisonPeriodValue?: Maybe<MonetaryValue>;
  designation?: Maybe<PartyDesignation>;
  globalScore?: Maybe<Scalars['Float']['output']>;
  periodValue?: Maybe<MonetaryValue>;
  productArea?: Maybe<ProductArea>;
  ruleType: InsightRuleType;
  score: Scalars['Long']['output'];
  sentence?: Maybe<Scalars['String']['output']>;
  subjectDisplayKey: Scalars['String']['output'];
  subjectIconUrl?: Maybe<Scalars['String']['output']>;
  subjectId: Scalars['String']['output'];
  subjectType: InsightSubjectType;
  type: InsightType;
};

export type InsightComponent = {
  hovers: Array<Hover>;
  sentence: Scalars['String']['output'];
};

export type InsightComponentConfig = {
  lookback: ArchiveIntervalOrigin;
  objectId: ObjectIdentifier;
  options?: Maybe<InsightComponentConfigOptions>;
  origin: ArchiveIntervalOrigin;
};

export type InsightComponentConfigOptions = {
  partyRole?: Maybe<PartyRole>;
};

export type InsightConfigInput = {
  lookback: IntervalOrigin;
  objectId: InputObjectIdentifier;
  origin: IntervalOrigin;
  partyRole?: InputMaybe<PartyRole>;
};

export type InsightFilter = {
  categoryTypes?: InputMaybe<Array<CategoryType>>;
  insightTypes?: InputMaybe<Array<InsightType>>;
  insightVisibility?: InputMaybe<InsightVisibility>;
  objectId?: InputMaybe<InputObjectIdentifier>;
  partyRole?: InputMaybe<PartyRole>;
  productArea?: InputMaybe<ProductArea>;
  scoreType?: InputMaybe<InsightScoreType>;
  subjectId?: InputMaybe<Scalars['String']['input']>;
  subjectTypes?: InputMaybe<Array<InsightSubjectType>>;
  topLevelOnly?: InputMaybe<Scalars['Boolean']['input']>;
  viewKey: ViewKey;
};

export enum InsightRuleType {
  Delta = 'Delta',
  Growth = 'Growth',
  Inconsistent = 'Inconsistent',
  New = 'New',
  Outlier = 'Outlier',
  Regression = 'Regression',
  UnspecifiedRuleType = 'UnspecifiedRuleType'
}

export enum InsightScoreType {
  Global = 'Global',
  Local = 'Local',
  UnknownScoreType = 'UnknownScoreType'
}

export enum InsightSubjectType {
  Category = 'Category',
  Department = 'Department',
  LegalEntity = 'LegalEntity',
  Location = 'Location',
  Party = 'Party',
  Recurring = 'Recurring',
  TransactionFact = 'TransactionFact',
  UnspecifiedSubjectType = 'UnspecifiedSubjectType'
}

export enum InsightType {
  AssetsPrepaid = 'AssetsPrepaid',
  AssetsReceivable = 'AssetsReceivable',
  Cash = 'Cash',
  CostOfGoodsSold = 'CostOfGoodsSold',
  Equity = 'Equity',
  Expenses = 'Expenses',
  Income = 'Income',
  LiabilitiesLongTerm = 'LiabilitiesLongTerm',
  LiabilitiesPayable = 'LiabilitiesPayable',
  UnspecifiedInsightType = 'UnspecifiedInsightType'
}

export enum InsightVisibility {
  DuplicateVisibility = 'DuplicateVisibility',
  FullVisibility = 'FullVisibility',
  UnspecifiedInsightVisibility = 'UnspecifiedInsightVisibility'
}

export type Institution = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partyId?: Maybe<Scalars['String']['output']>;
  routingNumber?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export enum InstitutionKind {
  Bank = 'Bank',
  Expense = 'Expense',
  Ledger = 'Ledger',
  Payment = 'Payment',
  Payroll = 'Payroll'
}

export enum InstitutionProduct {
  BankAccount = 'BankAccount',
  BillPay = 'BillPay',
  CapTable = 'CapTable',
  CreditDebitCard = 'CreditDebitCard',
  Invoicing = 'Invoicing',
  PayrollProvider = 'PayrollProvider',
  Revenue = 'Revenue',
  UnknownProduct = 'UnknownProduct'
}

export enum Interval {
  Biweek = 'Biweek',
  Day = 'Day',
  Hour = 'Hour',
  IntervalNone = 'IntervalNone',
  Minute = 'Minute',
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
  Year = 'Year'
}

/** Intervals */
export type IntervalOrigin = {
  index: Scalars['Int']['input'];
  interval: Interval;
  intervalCount?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};

export type IntervalPartition = {
  origin: IntervalPartitionOrigin;
  step: IntervalPartitionStep;
};

export type IntervalPartitionOrigin = {
  index: Scalars['Int']['input'];
  interval: Interval;
  intervalCount: Scalars['Int']['input'];
};

export type IntervalPartitionStep = {
  interval: Interval;
};

export type Invitation = {
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invitationType: InvitationType;
  notifiedAt?: Maybe<Scalars['DateTime']['output']>;
  organization?: Maybe<Organization>;
  organizationTemplateId?: Maybe<Scalars['String']['output']>;
  recipient: Contact;
  role?: Maybe<Role>;
  sender?: Maybe<Contact>;
  service?: Maybe<OAuthService>;
  signupId?: Maybe<Scalars['String']['output']>;
  status: InvitationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationProposal = {
  createdAt: Scalars['DateTime']['output'];
  invitationId: Scalars['String']['output'];
  legalEntity?: Maybe<EntityLegalEntity>;
  organization?: Maybe<EntityOrganization>;
  organizationTemplateId?: Maybe<Scalars['String']['output']>;
  proposalId: Scalars['String']['output'];
  proposalType: ProposalType;
  recipient?: Maybe<Contact>;
  sender?: Maybe<EntityUser>;
  status: InvitationProposalStatus;
};

export enum InvitationProposalStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Sent = 'Sent',
  Unknown = 'Unknown'
}

export enum InvitationStatus {
  Accepted = 'Accepted',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Unknown = 'Unknown'
}

export type InvitationToken = {
  token: Scalars['String']['output'];
};

export enum InvitationType {
  Accountant = 'Accountant',
  Operator = 'Operator',
  Passwordless = 'Passwordless',
  UnknownType = 'UnknownType'
}

export type Invoice = {
  customer?: Maybe<Customer>;
  discounts?: Maybe<Array<InvoiceDiscount>>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<InvoiceFile>>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<InvoicingProfile>;
  legalEntityId: Scalars['String']['output'];
  lineItems: Array<InvoiceLineItem>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  statusSummary?: Maybe<InvoiceStatusSummary>;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
};

export type InvoiceCustomerInterface = {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type InvoiceCustomerSummaryInterface = {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InvoiceDefaults = {
  dueDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invoiceDate: Scalars['DateTime']['output'];
  invoiceNumber: Scalars['String']['output'];
};

export type InvoiceDiscount = {
  discount: Discount;
  discountAmount?: Maybe<MonetaryValue>;
  invoiceDiscountId: Scalars['String']['output'];
};

export type InvoiceFile = {
  documentId: Scalars['String']['output'];
  fileType?: Maybe<InvoiceFileType>;
};

export enum InvoiceFileType {
  InvoicePDF = 'InvoicePDF',
  Unknown = 'Unknown'
}

export type InvoiceLineItem = {
  description?: Maybe<Scalars['String']['output']>;
  discounts?: Maybe<Array<InvoiceDiscount>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Long']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Long']['output'];
  taxable: Scalars['Boolean']['output'];
  unitPrice: MonetaryValue;
};

export type InvoiceNumber = {
  invoiceNumber: Scalars['String']['output'];
};

export type InvoicePaymentDetails = {
  paymentAmount: MonetaryValue;
  paymentConfirmationId: Scalars['String']['output'];
  paymentDate: Scalars['DateTime']['output'];
  paymentInstrument: PaymentInstrument;
};

export type InvoiceProduct = {
  id: Scalars['String']['output'];
  incomeCategoryId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  providerType: ProviderType;
  unitPrice?: Maybe<MonetaryValue>;
};

export enum InvoiceStatus {
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Failed = 'Failed',
  ManuallySent = 'ManuallySent',
  MarkedAsPaid = 'MarkedAsPaid',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PaymentCanceled = 'PaymentCanceled',
  PaymentProcessing = 'PaymentProcessing',
  PaymentRejected = 'PaymentRejected',
  PaymentRequiresAttention = 'PaymentRequiresAttention',
  PaymentReturned = 'PaymentReturned',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Sent = 'Sent',
  Settled = 'Settled',
  Submitted = 'Submitted',
  Unknown = 'Unknown',
  Voided = 'Voided'
}

export type InvoiceStatusSummary = {
  internalNoteForStatus?: Maybe<Scalars['String']['output']>;
  invoiceSentDate: Scalars['DateTime']['output'];
  status: InvoiceStatus;
  statusAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type InvoiceSummary = InvoiceSummaryInterface & {
  amount: EntityMonetaryValue;
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerSummary;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  status: InvoiceStatus;
};

export type InvoiceSummaryInterface = {
  amount: EntityMonetaryValue;
  createdAt: Scalars['DateTime']['output'];
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceNumber: Scalars['String']['output'];
  status: InvoiceStatus;
};

export type InvoicingAddress = {
  address: Address;
  id: Scalars['String']['output'];
};

export type InvoicingProfile = InvoicingProfileInterface & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceNumberFormat?: Maybe<Scalars['String']['output']>;
  invoiceNumberFormatDefault: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type InvoicingProfileInterface = {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceNumberFormat?: Maybe<Scalars['String']['output']>;
  invoiceNumberFormatDefault: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type LabelCategory = {
  category?: Maybe<EntityCategory>;
  /**
   * The default category type for this label
   * This can be used to find the uncategorized category for the given
   * accounting category root type
   */
  defaultCategoryType: CategoryType;
  displayName: Scalars['String']['output'];
  label: ConnectLabel;
  required: Scalars['Boolean']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
  /**
   * This contains a exhaustive list of all of the valid category types
   * that can be assigned to this label for the given connector. This will
   * include the default category type as well as any other category types
   * that are valid
   */
  validCategoryTypes: Array<CategoryType>;
};

export type Layout = {
  layoutId: Scalars['String']['output'];
  layoutVersionId?: Maybe<Scalars['String']['output']>;
  sections?: Maybe<Array<LayoutSection>>;
  viewKey: ViewIdentifier;
};

export type LayoutAttachment = {
  layoutId: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
};

export type LayoutComponent = {
  componentId: Scalars['String']['output'];
  config: LayoutComponentConfig;
  dataId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LayoutComponentConfig = {
  cashSummaryChart?: Maybe<CashSummaryChartConfig>;
  categoryChart?: Maybe<CategoryChartConfig>;
  departmentChart?: Maybe<DepartmentChartConfig>;
  documentCollection?: Maybe<DocumentCollectionConfig>;
  entitiesList?: Maybe<EntitiesListConfig>;
  insight?: Maybe<InsightComponentConfig>;
  locationChart?: Maybe<LocationChartConfig>;
  metricChart?: Maybe<MetricChartConfig>;
  partyChart?: Maybe<PartyChartConfig>;
  portalMetric?: Maybe<PortalMetricConfig>;
  runwayChart?: Maybe<RunwayChartConfig>;
  statement?: Maybe<StatementConfig>;
  staticMetric?: Maybe<StaticMetricConfig>;
  summaryLineItem?: Maybe<SummaryLineItemConfig>;
  text?: Maybe<TextComponentConfig>;
  topEntities?: Maybe<TopEntitiesConfig>;
  topTransactions?: Maybe<TopTransactionsConfig>;
  type: LayoutComponentType;
};

export type LayoutComponentConfigInput = {
  cashSummaryChart?: InputMaybe<CashSummaryChartConfigInput>;
  categoryChart?: InputMaybe<CategoryChartConfigInput>;
  departmentChart?: InputMaybe<DepartmentChartConfigInput>;
  documentCollection?: InputMaybe<DocumentCollectionConfigInput>;
  insight?: InputMaybe<InsightConfigInput>;
  locationChart?: InputMaybe<LocationChartConfigInput>;
  metricChart?: InputMaybe<MetricChartConfigInput>;
  partyChart?: InputMaybe<PartyChartConfigInput>;
  portalMetric?: InputMaybe<PortalMetricConfigInput>;
  runwayChart?: InputMaybe<RunwayChartConfigInput>;
  statement?: InputMaybe<StatementConfigInput>;
  staticMetric?: InputMaybe<StaticMetricConfigInput>;
  summaryLineItem?: InputMaybe<SummaryLineItemConfigInput>;
  text?: InputMaybe<TextComponentConfigInput>;
  topEntities?: InputMaybe<TopEntitiesConfigInput>;
  topTransactions?: InputMaybe<TopTransactionsConfigInput>;
  type: LayoutComponentType;
};

export type LayoutComponentInput = {
  componentId: Scalars['String']['input'];
  config: LayoutComponentConfigInput;
  dataId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum LayoutComponentType {
  CashSummaryChart = 'CashSummaryChart',
  CategoryBalanceSummaryChart = 'CategoryBalanceSummaryChart',
  CategoryTransactionSummaryChart = 'CategoryTransactionSummaryChart',
  DepartmentChart = 'DepartmentChart',
  DocumentCollection = 'DocumentCollection',
  EntitiesList = 'EntitiesList',
  Insight = 'Insight',
  LocationChart = 'LocationChart',
  MetricSummaryChart = 'MetricSummaryChart',
  PartyRoleBalanceSummaryChart = 'PartyRoleBalanceSummaryChart',
  PartyTransactionSummaryChart = 'PartyTransactionSummaryChart',
  PortalMetric = 'PortalMetric',
  RunwayChart = 'RunwayChart',
  Statement = 'Statement',
  StaticMetric = 'StaticMetric',
  SummaryLineItemChart = 'SummaryLineItemChart',
  Text = 'Text',
  TopEntities = 'TopEntities',
  TopTransactions = 'TopTransactions'
}

export type LayoutDocument = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  layoutId: Scalars['String']['output'];
  status: ReportPackageDocumentStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LayoutInput = {
  layoutId: Scalars['String']['input'];
  sections?: InputMaybe<Array<LayoutSectionInput>>;
};

export type LayoutRow = {
  components?: Maybe<Array<LayoutComponent>>;
  rowId: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LayoutRowInput = {
  components?: InputMaybe<Array<LayoutComponentInput>>;
  rowId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type LayoutSection = {
  rows?: Maybe<Array<LayoutRow>>;
  sectionId: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LayoutSectionInput = {
  rows?: InputMaybe<Array<LayoutRowInput>>;
  sectionId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type LedgerAccountDifferences = {
  creditDifference?: Maybe<MoneyFlow>;
  debitDifference?: Maybe<MoneyFlow>;
  unreconciledDifference: MoneyFlow;
};

export type LedgerBalances = {
  closingBalance: MoneyFlow;
  creditTotal: MoneyFlow;
  debitTotal: MoneyFlow;
  netTotal: MoneyFlow;
  unsettledTotal: MoneyFlow;
};

/**
 * # output types
 * LedgerTransaction is a transaction with multiple lines that are in balance
 */
export type LedgerTransaction = {
  compileSource?: Maybe<CompileSource>;
  externalId: Scalars['String']['output'];
  isMetadataEditable: Scalars['Boolean']['output'];
  ledgerTransactionId: Scalars['String']['output'];
  lines: Array<TransactionLine>;
  memo: Scalars['String']['output'];
  referenceNumber?: Maybe<Scalars['String']['output']>;
  sources: Array<TransactionSource>;
  timestamp: Scalars['DateTime']['output'];
  type: TransactionType;
};

export type LedgerTransactionStale = {
  newViewAvailable: Scalars['Boolean']['output'];
  stale: Scalars['Boolean']['output'];
};

export enum LedgerType {
  Digits = 'Digits',
  QuickBooks = 'QuickBooks',
  Unspecified = 'Unspecified'
}

export type LegalEntity = {
  address: Address;
  fiscalYearNumberOffset: Scalars['Long']['output'];
  fiscalYearStartMonth: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  incorporatedOn?: Maybe<Date>;
  industry?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  sensitive: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  status: LegalEntityStatus;
  timezone?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type LegalEntityBadgeCount = {
  count: Scalars['Long']['output'];
  legalEntityId: Scalars['String']['output'];
};

export type LegalEntityPermission = {
  canManageTransactions: Scalars['Boolean']['input'];
  legalEntityId: Scalars['String']['input'];
};

export type LegalEntityPreferences = {
  bookkeeperAiCutoverAt?: Maybe<Scalars['DateTime']['output']>;
  isCogsInExpenses: Scalars['Boolean']['output'];
  legalEntityId: Scalars['String']['output'];
  lineClassDisplayName: Scalars['String']['output'];
  lineClassPluralDisplayName: Scalars['String']['output'];
};

export type LegalEntityRole = {
  legalEntityId: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export enum LegalEntityStatus {
  Active = 'Active',
  Approved = 'Approved',
  New = 'New',
  Pending = 'Pending',
  PendingHold = 'PendingHold',
  Unknown = 'Unknown'
}

export type LegalEntityUserInfo = {
  legalEntity: LegalEntity;
  userInfo: UserInfo;
};

export type LineItemFeatureValue = {
  lineItemValue: DocumentLineItem;
};

export type ListBulkCategorySuggestionsResponse = {
  transactionCategorySuggestions: Array<TransactionCategorySuggestions>;
};

export type ListCount = {
  count: Scalars['Long']['output'];
};

export enum ListSignupRequestStatus {
  AllSignups = 'AllSignups',
  Applied = 'Applied',
  Ignored = 'Ignored',
  InviteAccepted = 'InviteAccepted',
  Invited = 'Invited',
  PrimerSent = 'PrimerSent',
  Qualified = 'Qualified',
  Unknown = 'Unknown',
  Unqualified = 'Unqualified',
  Waitlisted = 'Waitlisted'
}

/** TODO: deprecate */
export type ListSimilarTransactionsItem = {
  id: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type Location = {
  active: Scalars['Boolean']['output'];
  ancestors: Array<EntityLocation>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use parentId instead */
  parentLocationId?: Maybe<Scalars['String']['output']>;
};

export type LocationChartConfig = {
  locationId: Scalars['String']['output'];
  origin: ArchiveIntervalOrigin;
};

export type LocationChartConfigInput = {
  locationId: Scalars['String']['input'];
  origin: IntervalOrigin;
};

export type LocationDimension = {
  by: DimensionSummary;
  locationId: Scalars['String']['output'];
  locationObject: EntityLocation;
  summary: TransactionSummary;
};

export type LocationSummary = {
  cogsChange: DimensionSummary;
  expensesChange: DimensionSummary;
  incomeChange: DimensionSummary;
  location: EntityLocation;
  otherExpensesChange: DimensionSummary;
  otherIncomeChange: DimensionSummary;
  total: DimensionSummary;
};

export enum MappingValidity {
  Invalid = 'Invalid',
  PartiallyValid = 'PartiallyValid',
  Valid = 'Valid'
}

export type MarketingEvent = {
  attributes?: InputMaybe<MarketingEventAttributes>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  type: MarketingEventType;
};

export type MarketingEventAttributes = {
  accountState?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createAt?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  formerProvider?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ledger?: InputMaybe<Scalars['String']['input']>;
  planName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export enum MarketingEventType {
  OnboardingAccountDetailsComplete = 'OnboardingAccountDetailsComplete',
  OnboardingAccountSetupComplete = 'OnboardingAccountSetupComplete',
  OnboardingAddCC = 'OnboardingAddCC',
  OnboardingConnectQBO = 'OnboardingConnectQBO',
  OnboardingConnectionAdded = 'OnboardingConnectionAdded',
  OnboardingFormerProvider = 'OnboardingFormerProvider',
  OnboardingSelectBank = 'OnboardingSelectBank',
  OnboardingSelectFinanceTools = 'OnboardingSelectFinanceTools',
  OnboardingSignIn = 'OnboardingSignIn',
  OnboardingSignUp = 'OnboardingSignUp',
  PlanManagementAddUser = 'PlanManagementAddUser',
  PlanManagementPaymentComplete = 'PlanManagementPaymentComplete',
  PlanManagementPlanCancelled = 'PlanManagementPlanCancelled',
  PlanManagementPlanDowngrade = 'PlanManagementPlanDowngrade',
  PlanManagementPlanUpgrade = 'PlanManagementPlanUpgrade',
  PlanManagementTrialCancel = 'PlanManagementTrialCancel',
  TrialEnd = 'TrialEnd',
  TrialStart = 'TrialStart',
  UnknownType = 'UnknownType',
  UsageActivateLegalEntity = 'UsageActivateLegalEntity',
  UsageAddUser = 'UsageAddUser',
  UsageBillPayed = 'UsageBillPayed',
  UsageCreateReport = 'UsageCreateReport',
  UsageRecategorizeTransaction = 'UsageRecategorizeTransaction',
  UsageSendInvoice = 'UsageSendInvoice',
  UsageShareReport = 'UsageShareReport',
  UsageSignIn = 'UsageSignIn'
}

export type MatchSetEntry = {
  createdAt: Scalars['DateTime']['output'];
  dataSourceId: Scalars['String']['output'];
  entryType: MatchedSetRowType;
  id: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  matchSetId: Scalars['String']['output'];
  transactionFactId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum MatchedSetRowType {
  JournalEntry = 'JournalEntry',
  Recorded = 'Recorded',
  SourceCredit = 'SourceCredit',
  SourceDebit = 'SourceDebit',
  Unknown = 'Unknown'
}

export enum Matcher {
  Category = 'Category',
  CategoryType = 'CategoryType',
  PartyRole = 'PartyRole',
  ProductArea = 'ProductArea'
}

export enum MergeableStatus {
  Behind = 'Behind',
  Blocked = 'Blocked',
  Clean = 'Clean',
  Dirty = 'Dirty',
  Draft = 'Draft',
  HasHooks = 'HasHooks',
  UnknownMergeableStatus = 'UnknownMergeableStatus',
  Unstable = 'Unstable'
}

export type MetricCategoriesOperand = {
  categories: Array<EntityCategory>;
  defaultCategoryType: CategoryType;
  isBalance: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  naturalFlow: Flow;
  period?: Maybe<Period>;
};

export type MetricChartConfig = {
  item: MetricSummaryItem;
  origin: ArchiveIntervalOrigin;
};

export type MetricChartConfigInput = {
  item: MetricSummaryItem;
  origin: IntervalOrigin;
};

export type MetricEquation = {
  terms: Array<MetricTerm>;
};

export type MetricOperation = {
  symbol: MetricSymbol;
};

export enum MetricSummaryItem {
  GrossBurn = 'GrossBurn',
  GrossIncome = 'GrossIncome',
  NetBurn = 'NetBurn',
  UnknownMetricType = 'UnknownMetricType'
}

export type MetricSummaryTypeOperand = {
  defaultCategoryType: CategoryType;
  isBalance: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  naturalFlow: Flow;
  period?: Maybe<Period>;
  type: MetricSummaryItem;
};

export enum MetricSymbol {
  Add = 'Add',
  Divide = 'Divide',
  Subtract = 'Subtract'
}

export type MetricTerm = MetricCategoriesOperand | MetricOperation | MetricSummaryTypeOperand;

export enum MetricType {
  COGS = 'COGS',
  Expenses = 'Expenses',
  GrossProfit = 'GrossProfit',
  Income = 'Income',
  NetIncome = 'NetIncome',
  NetOperatingIncome = 'NetOperatingIncome',
  ProfitPercentage = 'ProfitPercentage'
}

export type MigrationFileSummary = {
  file: PullRequestFile;
  hasRun: Scalars['Boolean']['output'];
};

export type MigrationPullRequest = {
  fileSummaries: Array<MigrationFileSummary>;
  pullRequestSummary: PullRequestSummary;
};

export type MinMaxAmount = {
  maxAmount: MonetaryValue;
  minAmount: MonetaryValue;
};

export enum MissingParty {
  Exclude = 'Exclude',
  Include = 'Include',
  Unknown = 'Unknown'
}

export type MonetaryFeatureValue = {
  monetaryValue: MonetaryValue;
};

/** MonetaryValue */
export type MonetaryValue = {
  amount: Scalars['Long']['output'];
  currencyMultiplier: Scalars['Long']['output'];
  iso4217CurrencyCode: Scalars['String']['output'];
};

export type MoneyFlow = {
  businessFlow: Flow;
  isNormal: Scalars['Boolean']['output'];
  value: MonetaryValue;
};

export type MoneyFlowInput = {
  businessFlow: Flow;
  isNormal: Scalars['Boolean']['input'];
  value: InputMonetaryValue;
};

export type Mutation = {
  acceptAffiliation?: Maybe<Scalars['Void']['output']>;
  /** TODO: This call is a dead end. We are leaving it in the schema to handle old clients that may still be using it. */
  acceptAllTerms?: Maybe<Scalars['Void']['output']>;
  acceptTerms?: Maybe<Scalars['Void']['output']>;
  activateCurrentUserEmployment?: Maybe<Scalars['Void']['output']>;
  activateLegalEntity?: Maybe<Scalars['Void']['output']>;
  activateRampConnection?: Maybe<Scalars['Void']['output']>;
  addDocumentFeature: AddedFeature;
  addTeamMembers?: Maybe<Scalars['Void']['output']>;
  approveObjectAccessRequest?: Maybe<Scalars['Void']['output']>;
  /** Component Data */
  archiveComponentData: Scalars['String']['output'];
  archiveConnection?: Maybe<Scalars['Void']['output']>;
  associateInstitutions: Scalars['Void']['output'];
  attachReportPackageDocument: Scalars['Void']['output'];
  autoConfigureConnection?: Maybe<Scalars['Void']['output']>;
  autoConfigureProduct?: Maybe<Scalars['Void']['output']>;
  cancelBillingSubscription?: Maybe<Scalars['Void']['output']>;
  cancelInvitation?: Maybe<Scalars['Void']['output']>;
  closeWorkItems?: Maybe<Scalars['Void']['output']>;
  confirmBillingSubscription?: Maybe<Scalars['Void']['output']>;
  confirmOperatorTrial?: Maybe<Scalars['Void']['output']>;
  copyReportPackage: ReportPackageMetadata;
  /** Reconciliations */
  createAccountReconciliation: Scalars['String']['output'];
  createAffiliation: LegalEntity;
  /** Affiliations (Affiliation mutating an associate to a legal entity or the legal entity directly) */
  createAffiliationFromOAuthIdentity: CreatedLegalEntity;
  /** Affiliations (Affiliation mutating an associate to a legal entity or the legal entity directly) */
  createAffiliationFromOAuthService: CreatedLegalEntity;
  /** Aspects */
  createAspectRequest?: Maybe<Scalars['Void']['output']>;
  createBillingOnboardingSubscription: BillingSubscriptionWithoutStatus;
  /** Billing */
  createBillingSubscription: NewBillingSubscription;
  createBooksClosedDate?: Maybe<Scalars['Void']['output']>;
  /** Chart of Accounts */
  createCategory: CreateCategoryResponse;
  /** Category Mapping */
  createCategoryMapping: Scalars['Void']['output'];
  createConnectPlaidLinkToken: PlaidLinkToken;
  /** Employees */
  createEmployee: Employee;
  createEmployeeAffiliations?: Maybe<Scalars['Void']['output']>;
  createGustoConnection: CreateConnectionResponse;
  createHiddenPredictedDataSource?: Maybe<Scalars['Void']['output']>;
  createIntent?: Maybe<Scalars['Void']['output']>;
  /** Legal Entities */
  createLegalEntityFromOAuthIdentity: LegalEntity;
  createLegalEntityFromOAuthService: LegalEntityUserInfo;
  /**
   * Proposals
   * Organizations
   */
  createOrganization: Organization;
  /** Parties */
  createParties: Array<Party>;
  /** Users */
  createPasswordReset?: Maybe<Scalars['Void']['output']>;
  createPlaidConnection: CreateConnectionResponse;
  createPortal: Portal;
  /** Data Sources */
  createQuickBooksDataSource: CreatedQuickBooksDataSource;
  /** Customer Quotes */
  createQuotePackage: QuotePackageId;
  createRampConnection: CreateConnectionResponse;
  createReaction?: Maybe<Scalars['Void']['output']>;
  createReportFromUpload: Array<Report>;
  /** Report Packages */
  createReportPackage: ReportPackageMetadata;
  createStripeConnection: CreateConnectionResponse;
  /** Teams management */
  createTeam: Team;
  /** Comments */
  createThread: ThreadWithEntities;
  /** Transaction Editing */
  createTransaction?: Maybe<CreateTransactionResponse>;
  createTrialBillingSubscription: NewTrialBillingSubscription;
  deactivateEmployee?: Maybe<Scalars['Void']['output']>;
  deleteAccountReconciliation?: Maybe<Scalars['Void']['output']>;
  deleteComment?: Maybe<Scalars['Void']['output']>;
  deleteDataSource?: Maybe<Scalars['Void']['output']>;
  /** Vault */
  deleteDocument: Scalars['Void']['output'];
  deleteDraftInvoice: Scalars['Void']['output'];
  deleteEmployeeAffiliations?: Maybe<Scalars['Void']['output']>;
  deleteReaction?: Maybe<Scalars['Void']['output']>;
  deleteReport?: Maybe<Scalars['Void']['output']>;
  deleteReportPackage?: Maybe<Scalars['Void']['output']>;
  deleteSourceAccountOwner?: Maybe<Scalars['Void']['output']>;
  deleteTeam?: Maybe<Scalars['Void']['output']>;
  deleteTransactionReviewQuestion?: Maybe<Scalars['Void']['output']>;
  deleteTransactions?: Maybe<Scalars['Void']['output']>;
  destroyConnection?: Maybe<Scalars['Void']['output']>;
  disableObjectLink?: Maybe<Scalars['Void']['output']>;
  editAffiliationBusiness?: Maybe<Scalars['Void']['output']>;
  editComment?: Maybe<Scalars['Void']['output']>;
  editReportPackage: ReportPackageMetadata;
  enableObjectLink?: Maybe<Scalars['Void']['output']>;
  finalizeAccountReconciliation?: Maybe<Scalars['Void']['output']>;
  grantFromShare?: Maybe<Scalars['Void']['output']>;
  grantTeam?: Maybe<Scalars['Void']['output']>;
  importOAuthEmployees?: Maybe<ImportedEmployees>;
  inviteTeamMembers?: Maybe<Scalars['Void']['output']>;
  /** Customer Events */
  logCustomerEvents?: Maybe<Scalars['Void']['output']>;
  markInvoiceAsPaid: Scalars['Void']['output'];
  onboardLegalEntity: NewAiBookkeeperLegalEntity;
  onboardLegalEntityFromOAuthService: NewAiBookkeeperLegalEntity;
  payInvoice: PayInvoiceSummary;
  provisionTrialBillingSubscription: NewTrialBillingSubscription;
  publishReportPackage?: Maybe<Scalars['Void']['output']>;
  pushDigitsWorkItems?: Maybe<Scalars['Void']['output']>;
  pushWorkItems?: Maybe<Scalars['Void']['output']>;
  rearchiveLayoutData: Layout;
  refreshReportPackage: ReportRefreshRequest;
  rejectAffiliation?: Maybe<Scalars['Void']['output']>;
  rejectObjectAccessRequest?: Maybe<Scalars['Void']['output']>;
  removeTeamMember?: Maybe<Scalars['Void']['output']>;
  reopenAccountReconciliation?: Maybe<Scalars['Void']['output']>;
  reopenWorkItem?: Maybe<Scalars['Void']['output']>;
  replyThread: CommentWithEntities;
  requestAccess?: Maybe<Scalars['Void']['output']>;
  requestObjectAccess?: Maybe<Scalars['Void']['output']>;
  resendInvitation?: Maybe<Scalars['Void']['output']>;
  resendManagedOperatorInvitation?: Maybe<Scalars['Void']['output']>;
  resolveThread?: Maybe<Scalars['Void']['output']>;
  /** Sharing and Access Control */
  revokeGrant?: Maybe<Scalars['Void']['output']>;
  revokeIntent?: Maybe<Scalars['Void']['output']>;
  revokeTeamGrant?: Maybe<Scalars['Void']['output']>;
  saveDraftInvoice: Scalars['Void']['output'];
  saveInvoiceCustomer: Customer;
  saveInvoiceDiscount: Discount;
  saveInvoiceProduct: InvoiceProduct;
  /** Invoicing */
  saveInvoicingProfile: InvoicingProfile;
  sendAffiliate: Scalars['Void']['output'];
  sendInstructions: Scalars['Void']['output'];
  sendInstructionsAffiliate: Scalars['Void']['output'];
  /** Invitations */
  sendInvitation?: Maybe<Scalars['Void']['output']>;
  sendInvoice: Scalars['String']['output'];
  sendInvoiceManually: Scalars['String']['output'];
  sendManagedOperatorInvitation?: Maybe<Scalars['Void']['output']>;
  sendQuotePackage: Scalars['Void']['output'];
  sendTransactionReviewReminder?: Maybe<Scalars['Void']['output']>;
  setDefaultApprover?: Maybe<Scalars['Void']['output']>;
  submitAccountantOnboarding?: Maybe<Scalars['Void']['output']>;
  /** Onboarding */
  submitOperatorOnboarding?: Maybe<Scalars['Void']['output']>;
  syncConnection?: Maybe<Scalars['Void']['output']>;
  triggerQuickBooksManualRefresh?: Maybe<Scalars['Void']['output']>;
  updateAIBookkeeperCutoverDate?: Maybe<Scalars['Void']['output']>;
  updateAccountReconciliation?: Maybe<Scalars['Void']['output']>;
  /** Work Items */
  updateAccountantReviewStates?: Maybe<Scalars['Void']['output']>;
  updateActionItemAssignmentStatus: Scalars['Void']['output'];
  updateActionItemStatus: Scalars['Void']['output'];
  updateActionItemWatchStatus: Scalars['Void']['output'];
  updateAuditRules?: Maybe<Scalars['Void']['output']>;
  updateBillingPaymentMethod?: Maybe<Scalars['Void']['output']>;
  updateBillingSubscription?: Maybe<Scalars['Void']['output']>;
  updateCategory: Scalars['Void']['output'];
  updateCategoryStatus: Scalars['Void']['output'];
  updateClientReviewStates?: Maybe<Scalars['Void']['output']>;
  /** Legal Entity Preferences */
  updateCogsInExpenses?: Maybe<Scalars['Void']['output']>;
  updateCompanyFinancials: Scalars['Void']['output'];
  updateConnectPlaidLinkToken: PlaidLinkToken;
  updateConnection?: Maybe<Scalars['Void']['output']>;
  /** Connect */
  updateConnectionAccounts?: Maybe<Scalars['Void']['output']>;
  updateContract: Scalars['Void']['output'];
  updateDataSourceStatus?: Maybe<Scalars['Void']['output']>;
  updateEmployee?: Maybe<Scalars['Void']['output']>;
  updateEmployeeAffiliations?: Maybe<Scalars['Void']['output']>;
  updateExecutiveSummary?: Maybe<Scalars['Void']['output']>;
  updateGrant?: Maybe<Scalars['Void']['output']>;
  updateIntent?: Maybe<Scalars['Void']['output']>;
  /** Layouts */
  updateLayout: Layout;
  updateLegalEntity?: Maybe<Scalars['Void']['output']>;
  updateLineClassDisplayName?: Maybe<Scalars['Void']['output']>;
  updateNotificationSetting?: Maybe<Scalars['Void']['output']>;
  /** Notifications */
  updateNotificationStatus?: Maybe<Scalars['Void']['output']>;
  updateOAuthCredential?: Maybe<Scalars['Void']['output']>;
  updateObjectLink?: Maybe<Scalars['Void']['output']>;
  /** Object Mapping */
  updateObjectMappings: ObjectMappingHierarchies;
  updateOrganization?: Maybe<Scalars['Void']['output']>;
  updateOrganizationPreferences?: Maybe<Scalars['Void']['output']>;
  updatePartyFields?: Maybe<Scalars['Void']['output']>;
  updatePlaidConnection?: Maybe<Scalars['Void']['output']>;
  updatePortal?: Maybe<Scalars['Void']['output']>;
  updateQuickBooksCredentials?: Maybe<Scalars['Void']['output']>;
  updateQuotePackage: Scalars['Void']['output'];
  updateReport?: Maybe<Scalars['Void']['output']>;
  /** Report automation settings */
  updateReportAutomationSettings: Scalars['Void']['output'];
  updateReportLayout: Layout;
  updateReportOptions?: Maybe<Scalars['Void']['output']>;
  updateReportPackage?: Maybe<Scalars['Void']['output']>;
  updateSourceAccountCategory?: Maybe<Scalars['Void']['output']>;
  updateSourceAccountOwner?: Maybe<Scalars['Void']['output']>;
  updateTeam?: Maybe<Scalars['Void']['output']>;
  updateTransaction?: Maybe<Scalars['Void']['output']>;
  updateTransactionFields?: Maybe<Scalars['Void']['output']>;
  updateTransactionReconciliationStatuses?: Maybe<Scalars['Void']['output']>;
  updateTransactionReviewQuestions?: Maybe<Scalars['Void']['output']>;
  updateTransactionReviewReminderMessage?: Maybe<Scalars['Void']['output']>;
  updateTransactionReviewSchedule?: Maybe<Scalars['Void']['output']>;
  updateUser?: Maybe<Scalars['Void']['output']>;
  uploadBankFeedEntries?: Maybe<UploadBankFeedResponse>;
  uploadJournalEntries?: Maybe<UploadJournalEntriesResponse>;
  uploadOrganizationIcon?: Maybe<Scalars['Void']['output']>;
  voidInvoice: Scalars['Void']['output'];
};


export type MutationAcceptAffiliationArgs = {
  organizationId: Scalars['String']['input'];
  proposalId: Scalars['String']['input'];
};


export type MutationAcceptAllTermsArgs = {
  legalEntityIds: Array<Scalars['String']['input']>;
  version: Scalars['String']['input'];
};


export type MutationAcceptTermsArgs = {
  version: Scalars['String']['input'];
};


export type MutationActivateCurrentUserEmploymentArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationActivateLegalEntityArgs = {
  id: Scalars['String']['input'];
};


export type MutationActivateRampConnectionArgs = {
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationAddDocumentFeatureArgs = {
  correctedFeatureId?: InputMaybe<Scalars['String']['input']>;
  documentCollectionId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  feature: NewDocumentFeature;
};


export type MutationAddTeamMembersArgs = {
  legalEntityId: Scalars['String']['input'];
  role: TeamRole;
  teamId: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};


export type MutationApproveObjectAccessRequestArgs = {
  constraint?: InputMaybe<AccessConstraint>;
  permission: Permission;
  requestId: Scalars['String']['input'];
};


export type MutationArchiveComponentDataArgs = {
  config: LayoutComponentConfigInput;
  layoutId: Scalars['String']['input'];
  viewId: ViewIdentifierInput;
};


export type MutationArchiveConnectionArgs = {
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationAssociateInstitutionsArgs = {
  institutionIds: Array<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
  source: AssociationSource;
};


export type MutationAttachReportPackageDocumentArgs = {
  attachments: NewReportAttachments;
  origin: IntervalOrigin;
  packageId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationAutoConfigureConnectionArgs = {
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationAutoConfigureProductArgs = {
  legalEntityId: Scalars['String']['input'];
  product: CategoryToLabelMappingProduct;
};


export type MutationCancelBillingSubscriptionArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type MutationCancelInvitationArgs = {
  token: Scalars['String']['input'];
};


export type MutationCloseWorkItemsArgs = {
  closedWorkItems: Array<ClosedWorkItem>;
  legalEntityId: Scalars['String']['input'];
  viewKey?: InputMaybe<ViewKey>;
};


export type MutationConfirmBillingSubscriptionArgs = {
  customerId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};


export type MutationConfirmOperatorTrialArgs = {
  legalEntityId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationCopyReportPackageArgs = {
  attachments: NewReportAttachments;
  interval: Interval;
  packageId: Scalars['String']['input'];
  periodEndAt: DateInput;
  periodStartAt: DateInput;
  title: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationCreateAccountReconciliationArgs = {
  legalEntityId: Scalars['String']['input'];
  reconciliation: InputAccountReconciliation;
  viewKey: ViewKey;
};


export type MutationCreateAffiliationArgs = {
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  recipient: ContactInput;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAffiliationFromOAuthIdentityArgs = {
  organizationId: Scalars['String']['input'];
  proposalOrganizationId?: InputMaybe<Scalars['String']['input']>;
  realmId: Scalars['String']['input'];
  service: OAuthService;
};


export type MutationCreateAffiliationFromOAuthServiceArgs = {
  code: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  proposalOrganizationId?: InputMaybe<Scalars['String']['input']>;
  service: OAuthService;
};


export type MutationCreateAspectRequestArgs = {
  code: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationCreateBillingOnboardingSubscriptionArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationCreateBillingSubscriptionArgs = {
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateBooksClosedDateArgs = {
  booksClosedDate?: InputMaybe<Scalars['Float']['input']>;
  legalEntityId: Scalars['String']['input'];
};


export type MutationCreateCategoryArgs = {
  category: CreateCategoryInput;
  legalEntityId: Scalars['String']['input'];
};


export type MutationCreateCategoryMappingArgs = {
  categoryId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  mappingType: CategoryToLabelMappingType;
  mappingValue: Scalars['String']['input'];
  product: CategoryToLabelMappingProduct;
};


export type MutationCreateConnectPlaidLinkTokenArgs = {
  routingNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateEmployeeArgs = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  role: RoleIdentifier;
};


export type MutationCreateEmployeeAffiliationsArgs = {
  affiliations: Array<LegalEntityRole>;
  emailAddress: Scalars['String']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  organizationRole: Scalars['String']['input'];
};


export type MutationCreateGustoConnectionArgs = {
  authorizationCode: Scalars['String']['input'];
  connectorId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationCreateHiddenPredictedDataSourceArgs = {
  institutionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  predictedDataSourceType: PredictedDataSourceType;
};


export type MutationCreateIntentArgs = {
  constraint?: InputMaybe<AccessConstraint>;
  message: Scalars['String']['input'];
  object: InputObjectIdentifier;
  permissions: Array<NewUserGrant>;
  sendNotification: Scalars['Boolean']['input'];
  viewKey?: InputMaybe<ViewKey>;
};


export type MutationCreateLegalEntityFromOAuthIdentityArgs = {
  realmId: Scalars['String']['input'];
  service: OAuthService;
};


export type MutationCreateLegalEntityFromOAuthServiceArgs = {
  code: Scalars['String']['input'];
  service: OAuthService;
};


export type MutationCreateOrganizationArgs = {
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePartiesArgs = {
  legalEntityId: Scalars['String']['input'];
  partyNames: Array<Scalars['String']['input']>;
};


export type MutationCreatePasswordResetArgs = {
  emailAddress: Scalars['String']['input'];
};


export type MutationCreatePlaidConnectionArgs = {
  accounts: Array<PlaidAccount>;
  connectorId: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
  institutionName: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
};


export type MutationCreatePortalArgs = {
  viewId: ViewIdentifierInput;
};


export type MutationCreateQuickBooksDataSourceArgs = {
  credential: OAuthCredentialFields;
  legalEntityId: Scalars['String']['input'];
};


export type MutationCreateQuotePackageArgs = {
  companyDetails: CompanyDetailsInput;
  legalEntityId: Scalars['String']['input'];
  recipient?: InputMaybe<ContactInput>;
};


export type MutationCreateRampConnectionArgs = {
  authorizationCode: Scalars['String']['input'];
  connectorId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationCreateReactionArgs = {
  commentId: Scalars['String']['input'];
  reaction: CommentReaction;
  threadId: Scalars['String']['input'];
};


export type MutationCreateReportFromUploadArgs = {
  legalEntityId: Scalars['String']['input'];
  reports: Array<NewReportUpload>;
};


export type MutationCreateReportPackageArgs = {
  attachments: NewReportAttachments;
  coverUrl?: InputMaybe<Scalars['String']['input']>;
  interval: Interval;
  periodEndAt: DateInput;
  periodStartAt: DateInput;
  title: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationCreateStripeConnectionArgs = {
  connectorId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationCreateTeamArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateThreadArgs = {
  assignee?: InputMaybe<Assignee>;
  comment: NewComment;
  context?: InputMaybe<Scalars['String']['input']>;
  targetObject: InputObjectIdentifier;
  viewKey?: InputMaybe<ViewKey>;
};


export type MutationCreateTransactionArgs = {
  legalEntityId: Scalars['String']['input'];
  transaction: InputTransaction;
};


export type MutationCreateTrialBillingSubscriptionArgs = {
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};


export type MutationDeactivateEmployeeArgs = {
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteAccountReconciliationArgs = {
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};


export type MutationDeleteDataSourceArgs = {
  dataSourceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  providerType: ProviderType;
};


export type MutationDeleteDocumentArgs = {
  documentCollectionId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationDeleteDraftInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationDeleteEmployeeAffiliationsArgs = {
  employeeIdentifier: EmployeeIdentifier;
  legalEntityIds: Array<Scalars['String']['input']>;
};


export type MutationDeleteReactionArgs = {
  commentId: Scalars['String']['input'];
  reaction: CommentReaction;
  threadId: Scalars['String']['input'];
};


export type MutationDeleteReportArgs = {
  legalEntityId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
};


export type MutationDeleteReportPackageArgs = {
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
};


export type MutationDeleteSourceAccountOwnerArgs = {
  dataSourceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  sourceAccountId: Scalars['String']['input'];
};


export type MutationDeleteTeamArgs = {
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};


export type MutationDeleteTransactionReviewQuestionArgs = {
  organizationId: Scalars['String']['input'];
  question: Scalars['String']['input'];
};


export type MutationDeleteTransactionsArgs = {
  factIds: Array<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
};


export type MutationDestroyConnectionArgs = {
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationDisableObjectLinkArgs = {
  object: InputObjectIdentifier;
};


export type MutationEditAffiliationBusinessArgs = {
  legalEntityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditCommentArgs = {
  comment: NewComment;
  commentId: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};


export type MutationEditReportPackageArgs = {
  packageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationEnableObjectLinkArgs = {
  object: InputObjectIdentifier;
  permission?: InputMaybe<Permission>;
  viewType?: InputMaybe<ViewType>;
};


export type MutationFinalizeAccountReconciliationArgs = {
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationGrantFromShareArgs = {
  constraint?: InputMaybe<AccessConstraint>;
  intentId?: InputMaybe<Scalars['String']['input']>;
  object: InputObjectIdentifier;
  viewKey?: InputMaybe<ViewKey>;
};


export type MutationGrantTeamArgs = {
  grants: Array<NewObjectGrant>;
  teamId: Scalars['String']['input'];
};


export type MutationImportOAuthEmployeesArgs = {
  organizationId: Scalars['String']['input'];
  service: Scalars['String']['input'];
};


export type MutationInviteTeamMembersArgs = {
  emailAddresses: Array<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
  role: TeamRole;
  teamId: Scalars['String']['input'];
};


export type MutationLogCustomerEventsArgs = {
  events: Array<CustomerEvent>;
};


export type MutationMarkInvoiceAsPaidArgs = {
  datePaid: Scalars['DateTime']['input'];
  internalNote?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationOnboardLegalEntityArgs = {
  contact: ContactInput;
  name: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOnboardLegalEntityFromOAuthServiceArgs = {
  code: Scalars['String']['input'];
  service: OAuthService;
};


export type MutationPayInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  paymentInstrumentId: Scalars['String']['input'];
};


export type MutationProvisionTrialBillingSubscriptionArgs = {
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};


export type MutationPublishReportPackageArgs = {
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  publishAsDraft?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPushDigitsWorkItemsArgs = {
  legalEntityId: Scalars['String']['input'];
  workItems: Array<DigitsWorkItemPushParams>;
};


export type MutationPushWorkItemsArgs = {
  legalEntityId: Scalars['String']['input'];
  workItems: Array<WorkItemPushParams>;
};


export type MutationRearchiveLayoutDataArgs = {
  layoutId: Scalars['String']['input'];
  viewId: ViewIdentifierInput;
};


export type MutationRefreshReportPackageArgs = {
  packageId: Scalars['String']['input'];
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
  viewKey: ViewKey;
};


export type MutationRejectAffiliationArgs = {
  proposalId: Scalars['String']['input'];
};


export type MutationRejectObjectAccessRequestArgs = {
  requestId: Scalars['String']['input'];
};


export type MutationRemoveTeamMemberArgs = {
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  teamMemberId: Scalars['String']['input'];
};


export type MutationReopenAccountReconciliationArgs = {
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
};


export type MutationReopenWorkItemArgs = {
  legalEntityId: Scalars['String']['input'];
  workItemId: Scalars['String']['input'];
};


export type MutationReplyThreadArgs = {
  assignee?: InputMaybe<Assignee>;
  comment: NewComment;
  threadId: Scalars['String']['input'];
  viewKey?: InputMaybe<ViewKey>;
};


export type MutationRequestAccessArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type MutationRequestObjectAccessArgs = {
  constraint?: InputMaybe<AccessConstraint>;
  intentId?: InputMaybe<Scalars['String']['input']>;
  object: InputObjectIdentifier;
};


export type MutationResendInvitationArgs = {
  token: Scalars['String']['input'];
};


export type MutationResendManagedOperatorInvitationArgs = {
  emailAddress: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  message: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationResolveThreadArgs = {
  threadId: Scalars['String']['input'];
  viewKey?: InputMaybe<ViewKey>;
};


export type MutationRevokeGrantArgs = {
  constraint?: InputMaybe<AccessConstraint>;
  grantId: Scalars['String']['input'];
  object: InputObjectIdentifier;
};


export type MutationRevokeIntentArgs = {
  constraint?: InputMaybe<AccessConstraint>;
  intentId: Scalars['String']['input'];
  object: InputObjectIdentifier;
};


export type MutationRevokeTeamGrantArgs = {
  teamGrantId: Scalars['String']['input'];
};


export type MutationSaveDraftInvoiceArgs = {
  invoice: InputInvoice;
  legalEntityId: Scalars['String']['input'];
};


export type MutationSaveInvoiceCustomerArgs = {
  customer: InputCustomer;
  legalEntityId: Scalars['String']['input'];
};


export type MutationSaveInvoiceDiscountArgs = {
  discount: InputDataSourceDiscount;
  legalEntityId: Scalars['String']['input'];
};


export type MutationSaveInvoiceProductArgs = {
  legalEntityId: Scalars['String']['input'];
  product: InputInvoiceProduct;
};


export type MutationSaveInvoicingProfileArgs = {
  legalEntityId: Scalars['String']['input'];
  profile: InputInvoicingProfile;
};


export type MutationSendAffiliateArgs = {
  invitationType: Scalars['String']['input'];
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  proposalType?: InputMaybe<Scalars['String']['input']>;
  recipient: ContactInput;
};


export type MutationSendInstructionsArgs = {
  destinationOrganizationId?: InputMaybe<Scalars['String']['input']>;
  invitationType: Scalars['String']['input'];
  proposalType?: InputMaybe<Scalars['String']['input']>;
  recipient: ContactInput;
};


export type MutationSendInstructionsAffiliateArgs = {
  destinationOrganizationId?: InputMaybe<Scalars['String']['input']>;
  invitationType: Scalars['String']['input'];
  proposalType?: InputMaybe<Scalars['String']['input']>;
  recipient: ContactInput;
};


export type MutationSendInvitationArgs = {
  destinationOrganizationId?: InputMaybe<Scalars['String']['input']>;
  destinationOrganizationRole?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  invitationType: Scalars['String']['input'];
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  proposalType?: InputMaybe<Scalars['String']['input']>;
  recipient: ContactInput;
};


export type MutationSendInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationSendInvoiceManuallyArgs = {
  invoiceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationSendManagedOperatorInvitationArgs = {
  context?: InputMaybe<ArrivalContext>;
  contextObject?: InputMaybe<InputObjectIdentifier>;
  legalEntityId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  recipient: ContactInput;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendQuotePackageArgs = {
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationSendTransactionReviewReminderArgs = {
  legalEntityId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};


export type MutationSetDefaultApproverArgs = {
  legalEntityId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSubmitAccountantOnboardingArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type MutationSubmitOperatorOnboardingArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type MutationSyncConnectionArgs = {
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationTriggerQuickBooksManualRefreshArgs = {
  dataSourceId: Scalars['String']['input'];
};


export type MutationUpdateAiBookkeeperCutoverDateArgs = {
  cutoverDate: Scalars['DateTime']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateAccountReconciliationArgs = {
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
  update: InputAccountReconciliationUpdate;
  viewKey: ViewKey;
};


export type MutationUpdateAccountantReviewStatesArgs = {
  legalEntityId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  period: PeriodInput;
  workItemSubjects: Array<WorkItemSubject>;
};


export type MutationUpdateActionItemAssignmentStatusArgs = {
  actionItemId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  organizationAssignees: Array<OrganizationAssignee>;
  userAssignees: Array<UserAssignee>;
};


export type MutationUpdateActionItemStatusArgs = {
  actionItems?: InputMaybe<Array<ActionItemStatusChange>>;
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateActionItemWatchStatusArgs = {
  actionItemId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  watch: Scalars['Boolean']['input'];
};


export type MutationUpdateAuditRulesArgs = {
  isFTE?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityId: Scalars['String']['input'];
  rules: Array<UpdateAuditRule>;
  usedSuggestedCategories?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateBillingPaymentMethodArgs = {
  organizationId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};


export type MutationUpdateBillingSubscriptionArgs = {
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
};


export type MutationUpdateCategoryArgs = {
  category: UpdateCategoryInput;
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationUpdateCategoryStatusArgs = {
  categoryId: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationUpdateClientReviewStatesArgs = {
  legalEntityId: Scalars['String']['input'];
  responses: Array<WorkItemResponse>;
};


export type MutationUpdateCogsInExpensesArgs = {
  isCogsInExpenses: Scalars['Boolean']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateCompanyFinancialsArgs = {
  financials?: InputMaybe<Array<CompanyFinancialsInput>>;
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateConnectPlaidLinkTokenArgs = {
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateConnectionArgs = {
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  settings: ConnectionSettingsInput;
};


export type MutationUpdateConnectionAccountsArgs = {
  accounts: Array<ConnectionAccountInput>;
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateContractArgs = {
  id: Scalars['String']['input'];
  items: Array<ContractLineItemInput>;
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateDataSourceStatusArgs = {
  dataSourceId: Scalars['String']['input'];
  status: DataSourceStatus;
};


export type MutationUpdateEmployeeArgs = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  employeeId: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  fieldToUpdate: UpdatableEmployeeField;
  givenName?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  role?: InputMaybe<RoleIdentifier>;
};


export type MutationUpdateEmployeeAffiliationsArgs = {
  affiliations: Array<LegalEntityRole>;
  employeeIdentifier: EmployeeIdentifier;
};


export type MutationUpdateExecutiveSummaryArgs = {
  documentId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  summary?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateGrantArgs = {
  constraint?: InputMaybe<AccessConstraint>;
  grantId: Scalars['String']['input'];
  object: InputObjectIdentifier;
  permission?: InputMaybe<Permission>;
  policy?: InputMaybe<GrantPolicy>;
};


export type MutationUpdateIntentArgs = {
  constraint?: InputMaybe<AccessConstraint>;
  intentId: Scalars['String']['input'];
  object: InputObjectIdentifier;
  permission?: InputMaybe<Permission>;
};


export type MutationUpdateLayoutArgs = {
  layout: LayoutInput;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  viewKey?: InputMaybe<ViewKey>;
};


export type MutationUpdateLegalEntityArgs = {
  address?: InputMaybe<AddressInput>;
  fiscalYearNumberOffset?: InputMaybe<Scalars['Int']['input']>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  incorporatedOn?: InputMaybe<DateInput>;
  industry?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateLineClassDisplayNameArgs = {
  legalEntityId: Scalars['String']['input'];
  lineClassDisplayName: Scalars['String']['input'];
};


export type MutationUpdateNotificationSettingArgs = {
  enabled: Scalars['Boolean']['input'];
  eventType: EventType;
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateNotificationStatusArgs = {
  id: Scalars['String']['input'];
  status: WebNotificationStatus;
};


export type MutationUpdateOAuthCredentialArgs = {
  code: Scalars['String']['input'];
  service: OAuthService;
};


export type MutationUpdateObjectLinkArgs = {
  object: InputObjectIdentifier;
  permission?: InputMaybe<Permission>;
};


export type MutationUpdateObjectMappingsArgs = {
  departmentIds: Array<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
  lineClassIds: Array<Scalars['String']['input']>;
  mappedTo: ObjectKind;
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOrganizationPreferencesArgs = {
  anonymizeEmployees: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};


export type MutationUpdatePartyFieldsArgs = {
  legalEntityId: Scalars['String']['input'];
  updates: Array<InputUpdatePartyFields>;
};


export type MutationUpdatePlaidConnectionArgs = {
  accounts: Array<PlaidAccount>;
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdatePortalArgs = {
  backgroundUrl?: InputMaybe<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
  portalId: Scalars['String']['input'];
};


export type MutationUpdateQuickBooksCredentialsArgs = {
  code: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  service: OAuthService;
};


export type MutationUpdateQuotePackageArgs = {
  companyDetails: CompanyDetailsInput;
  contractDocumentId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  recipient?: InputMaybe<ContactInput>;
};


export type MutationUpdateReportArgs = {
  interval?: InputMaybe<Interval>;
  legalEntityId: Scalars['String']['input'];
  reportFor?: InputMaybe<DateInput>;
  reportId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateReportAutomationSettingsArgs = {
  autoCreateDraft: Scalars['Boolean']['input'];
  autoPublishDraft: Scalars['Boolean']['input'];
  autoPublishDraftDay: Scalars['Int']['input'];
  autoPublishFinal: Scalars['Boolean']['input'];
  autoPublishFinalDay: Scalars['Int']['input'];
  interval: Interval;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type MutationUpdateReportLayoutArgs = {
  documentId: Scalars['String']['input'];
  layout: LayoutInput;
  packageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationUpdateReportOptionsArgs = {
  collapsedSections?: InputMaybe<CollapsedSectionsInput>;
  comparedToIncome?: InputMaybe<ReportOptionComparison>;
  comparedToTotal?: InputMaybe<ReportOptionComparison>;
  deltaMonthOverMonth?: InputMaybe<ReportOptionComparison>;
  deltaYearOverYear?: InputMaybe<ReportOptionComparison>;
  legalEntityId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  reportPackageId: Scalars['String']['input'];
  reportVersionId: Scalars['String']['input'];
  sparklineLookbackPeriods?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateReportPackageArgs = {
  accountNumbersDisplay?: InputMaybe<AccountNumbersDisplay>;
  coverLogoSource?: InputMaybe<CoverLogoSource>;
  coverUrl?: InputMaybe<Scalars['String']['input']>;
  customFooter?: InputMaybe<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  preparedBy?: InputMaybe<Scalars['String']['input']>;
  publishedAsDraft?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSourceAccountCategoryArgs = {
  categoryId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  sourceAccountId: Scalars['String']['input'];
};


export type MutationUpdateSourceAccountOwnerArgs = {
  dataSourceId: Scalars['String']['input'];
  employeeFullName: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  sourceAccountId: Scalars['String']['input'];
};


export type MutationUpdateTeamArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};


export type MutationUpdateTransactionArgs = {
  filter: TransactionFilter;
  legalEntityId: Scalars['String']['input'];
  transaction: InputTransaction;
};


export type MutationUpdateTransactionFieldsArgs = {
  legalEntityId: Scalars['String']['input'];
  updates: Array<InputUpdateFields>;
  viewKey: ViewKey;
};


export type MutationUpdateTransactionReconciliationStatusesArgs = {
  statusUpdates: Array<InputStatusUpdate>;
  viewKey: ViewKey;
};


export type MutationUpdateTransactionReviewQuestionsArgs = {
  deletedQuestions?: InputMaybe<Array<Scalars['String']['input']>>;
  newQuestions?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationId: Scalars['String']['input'];
};


export type MutationUpdateTransactionReviewReminderMessageArgs = {
  legalEntityId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};


export type MutationUpdateTransactionReviewScheduleArgs = {
  daysOfMonth?: InputMaybe<Array<DayOfMonth>>;
  daysOfWeek?: InputMaybe<Array<DayOfWeek>>;
  enabled: Scalars['Boolean']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUploadBankFeedEntriesArgs = {
  bankFeedEntries: Array<BankFeedEntry>;
  categoryId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationUploadJournalEntriesArgs = {
  filename: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  transactions: Array<InputTransaction>;
};


export type MutationUploadOrganizationIconArgs = {
  iconBase64: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationVoidInvoiceArgs = {
  internalNote?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};

export type NewAiBookkeeperLegalEntity = {
  legalEntity: LegalEntity;
  quotePackage: QuotePackage;
  userInfo: UserInfo;
};

export type NewBillingSubscription = {
  customerId: Scalars['String']['output'];
  stripeClientSecret: Scalars['String']['output'];
};

/** NewComment represents an input comment. */
export type NewComment = {
  text: Scalars['String']['input'];
};

export type NewDocumentFeature = {
  kind: FeatureKind;
  value: NewDocumentFeatureValue;
};

export type NewDocumentFeatureValue = {
  textValue?: InputMaybe<Scalars['String']['input']>;
};

export type NewLayoutDocument = {
  initialText?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  tags?: InputMaybe<Array<TextComponentConfigTagInput>>;
  title: Scalars['String']['input'];
};

export type NewObjectGrant = {
  object: InputObjectIdentifier;
  permission?: InputMaybe<Permission>;
  policy?: InputMaybe<GrantPolicy>;
};

export type NewParty = {
  designation?: InputMaybe<PartyDesignation>;
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  iconUrl: Scalars['String']['input'];
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  shortDescription: Scalars['String']['input'];
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportPhone?: InputMaybe<Scalars['String']['input']>;
  supportUrl?: InputMaybe<Scalars['String']['input']>;
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  yelpUrl?: InputMaybe<Scalars['String']['input']>;
};

export type NewReportAttachments = {
  existing: Array<ExistingReportAttachment>;
  insightObjectIds?: InputMaybe<Array<InputObjectIdentifier>>;
  layout: Array<NewLayoutDocument>;
  statementKinds: Array<ReportKind>;
  text: Array<NewTextDocument>;
};

export type NewReportUpload = {
  fileId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  interval: Interval;
  mimeType: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  reportFor: DateInput;
  title: Scalars['String']['input'];
};

export type NewTextDocument = {
  position: Scalars['Int']['input'];
  summary: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type NewTrialBillingSubscription = {
  customerId: Scalars['String']['output'];
  productId: Scalars['String']['output'];
};

export type NewUserGrant = {
  emailAddress: Scalars['String']['input'];
  permission: Permission;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationSetting = {
  displayKey: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  eventType: EventType;
  legalEntityId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type NumericFeatureValue = {
  numericValue: Scalars['Long']['output'];
};

export type OAuthCredentialFields = {
  code: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  service: OAuthService;
};

export enum OAuthService {
  Google = 'Google',
  LinkedIn = 'LinkedIn',
  QuickBooks = 'QuickBooks',
  Unknown = 'Unknown'
}

export type ObjectAcl = {
  entities: ObjectEntities;
  grants: Array<UserGrant>;
  intents: Array<UserIntent>;
  link?: Maybe<Permission>;
  object: ObjectIdentifier;
  requests: Array<UserRequest>;
};

/**
 * ObjectEntities is a structure to thread through populated objects
 * indicated by ObjectIdentifiers to clients.
 */
export type ObjectEntities = {
  bills?: Maybe<Array<EntityBill>>;
  categories?: Maybe<Array<EntityCategory>>;
  comments?: Maybe<Array<EntityComment>>;
  datasources?: Maybe<Array<EntityDataSource>>;
  departments?: Maybe<Array<EntityDepartment>>;
  identityVerifications?: Maybe<Array<EntityIdentityVerification>>;
  invoices?: Maybe<Array<EntityInvoice>>;
  legalEntities?: Maybe<Array<EntityLegalEntity>>;
  locations?: Maybe<Array<EntityLocation>>;
  parties?: Maybe<Array<EntityParty>>;
  reportPackages?: Maybe<Array<EntityReportPackage>>;
  reports?: Maybe<Array<EntityReport>>;
  threads?: Maybe<Array<EntityThread>>;
  transactions?: Maybe<Array<EntityTransaction>>;
  users?: Maybe<Array<EntityUser>>;
  workItems?: Maybe<Array<EntityWorkItem>>;
};

export type ObjectEntity = EntityBill | EntityCategory | EntityComment | EntityDataSource | EntityDepartment | EntityIdentityVerification | EntityInvoice | EntityLedgerTransaction | EntityLegalEntity | EntityLocation | EntityParty | EntityReport | EntityReportPackage | EntityThread | EntityTransaction | EntityUser | EntityWorkItem;

export type ObjectGrant = {
  createdAt: Scalars['DateTime']['output'];
  grantId: Scalars['String']['output'];
  object: ObjectIdentifier;
  permission: Permission;
  viewType?: Maybe<ViewType>;
};

/**
 * ObjectIdentifier is a generic identifier with an enumerated kind.
 * It can be used to identify different Digits' core objects.
 */
export type ObjectIdentifier = {
  id: Scalars['String']['output'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['output'];
};

export type ObjectIntent = {
  creatorUserId: Scalars['String']['output'];
  intentId: Scalars['String']['output'];
  object: ObjectIdentifier;
  permission: Permission;
};

export enum ObjectKind {
  Bill = 'Bill',
  Category = 'Category',
  Comment = 'Comment',
  DataSource = 'DataSource',
  Department = 'Department',
  Invoice = 'Invoice',
  LedgerTransaction = 'LedgerTransaction',
  LegalEntity = 'LegalEntity',
  Location = 'Location',
  Party = 'Party',
  Portal = 'Portal',
  Report = 'Report',
  ReportPackage = 'ReportPackage',
  Thread = 'Thread',
  Transaction = 'Transaction',
  User = 'User',
  WorkItem = 'WorkItem'
}

export type ObjectKindFacet = {
  count: Scalars['Long']['output'];
  kind: ObjectKind;
};

export type ObjectMappingHierarchies = {
  departmentHierarchy: Array<ObjectMappingHierarchyNode>;
  lineClassHierarchy: Array<ObjectMappingHierarchyNode>;
};

export type ObjectMappingHierarchyNode = {
  active: Scalars['Boolean']['output'];
  depth: Scalars['Long']['output'];
  factId: Scalars['String']['output'];
  fullyQualifiedName: Scalars['String']['output'];
  mappedTo?: Maybe<ObjectKind>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  transactionCount: Scalars['Long']['output'];
};

export type ObjectPermission = {
  action: ShareAction;
  legalEntity: EntityLegalEntity;
  object: ObjectIdentifier;
  organizationPreview: OrganizationPreview;
  permission?: Maybe<Permission>;
  viewType: ViewType;
};

export type ObjectRequest = {
  object: ObjectIdentifier;
  requestId: Scalars['String']['output'];
};

export enum Operation {
  Contains = 'Contains',
  Excludes = 'Excludes',
  UnknownOperation = 'UnknownOperation'
}

export type Organization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type OrganizationAffiliation = {
  legalEntity: EntityLegalEntity;
  organization: EntityOrganization;
};

export type OrganizationAssignee = {
  organizationId: Scalars['String']['input'];
};

export type OrganizationBillingPlan = {
  plan: BillingPlan;
  trial?: Maybe<BillingTrial>;
};

export type OrganizationEmployment = {
  employeeId: Scalars['String']['output'];
  organization: Organization;
  role: Role;
};

export type OrganizationPreferences = {
  anonymizeEmployees: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type OrganizationPreview = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type OrganizationTemplate = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type OwnedDataSource = {
  dataSource: EnrichedDataSource;
  dataSourcePreference?: Maybe<DataSourcePreference>;
  ownedSourceAccounts?: Maybe<Array<OwnedSourceAccount>>;
};

export type OwnedSourceAccount = {
  owner?: Maybe<Employee>;
  sourceAccount: SourceAccount;
};

export type PackageCount = {
  interval: Interval;
  total: Scalars['Long']['output'];
};

export type PackageCounts = {
  counts: Array<PackageCount>;
  total: Scalars['Long']['output'];
};

export type PackagesFilter = {
  hideDrafts?: InputMaybe<Scalars['Boolean']['input']>;
  interval?: InputMaybe<Interval>;
};

export type PackagesSort = {
  direction?: InputMaybe<SortDirection>;
  orderBy?: InputMaybe<PackagesSortOrderBy>;
};

export enum PackagesSortOrderBy {
  UpdatedAt = 'UpdatedAt'
}

export type PageView = {
  url: Scalars['String']['input'];
};

export type Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** Parties */
export type Party = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  designation: PartyDesignation;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  legalEntityId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportPhone?: Maybe<Scalars['String']['output']>;
  supportUrl?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  yelpUrl?: Maybe<Scalars['String']['output']>;
};

export type PartyAndRoleBalanceFilter = {
  legalEntityId: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
  partyRole: PartyRole;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};

export type PartyChartConfig = {
  asPermanent?: Maybe<Scalars['Boolean']['output']>;
  origin: ArchiveIntervalOrigin;
  partyId: Scalars['String']['output'];
  partyRole?: Maybe<PartyRole>;
};

export type PartyChartConfigInput = {
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  origin: IntervalOrigin;
  partyId: Scalars['String']['input'];
  partyRole?: InputMaybe<PartyRole>;
};

export enum PartyDesignation {
  BusinessDesignation = 'BusinessDesignation',
  IgnoredDesignation = 'IgnoredDesignation',
  InvalidDesignation = 'InvalidDesignation',
  PeopleDesignation = 'PeopleDesignation',
  SMBMealsDesignation = 'SMBMealsDesignation',
  SMBOtherDesignation = 'SMBOtherDesignation',
  UnknownDesignation = 'UnknownDesignation'
}

export type PartyDimension = {
  by: DimensionSummary;
  partyId: Scalars['String']['output'];
  partyObject: EntityParty;
  summary: TransactionSummary;
};

export enum PartyEntityType {
  Corporation = 'Corporation',
  ForeignEntities = 'ForeignEntities',
  Individual = 'Individual',
  LimitedLiabilityCompany = 'LimitedLiabilityCompany',
  Other = 'Other',
  Partnerships = 'Partnerships',
  SoleProprietorship = 'SoleProprietorship',
  TrustsAndEstates = 'TrustsAndEstates',
  Undetermined = 'Undetermined',
  UnknownEntityType = 'UnknownEntityType'
}

export type PartyHistory = {
  entityTypeClassification: PartyHistoryEntry;
  partyId: Scalars['String']['output'];
  taxpayerIdentificationClassification: PartyHistoryEntry;
};

export type PartyHistoryEntry = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PartyHover = {
  context?: Maybe<DimensionSummary>;
  entity: EntityParty;
  history: DimensionSummary;
  topTransactions?: Maybe<Array<EntityTransaction>>;
};

export type PartyIconMetadata = {
  iconUrl: Scalars['String']['output'];
};

export type PartyParams = {
  action: PushAction;
  partyID?: InputMaybe<Scalars['String']['input']>;
  partyIconUrl?: InputMaybe<Scalars['String']['input']>;
  partyName: Scalars['String']['input'];
};

export type PartyRef = {
  id: Scalars['String']['output'];
};

export enum PartyRole {
  EntityAssetRole = 'EntityAssetRole',
  EntityAssetSupplierRole = 'EntityAssetSupplierRole',
  EntityAssetVendorRole = 'EntityAssetVendorRole',
  EntityBanksRole = 'EntityBanksRole',
  EntityCreditCardsRole = 'EntityCreditCardsRole',
  EntityCustomerRole = 'EntityCustomerRole',
  EntityDebtorRole = 'EntityDebtorRole',
  EntityInvesteeRole = 'EntityInvesteeRole',
  EntityLenderRole = 'EntityLenderRole',
  EntityLiabilitiesRole = 'EntityLiabilitiesRole',
  EntityOtherExpensesRole = 'EntityOtherExpensesRole',
  EntityOtherIncomeRole = 'EntityOtherIncomeRole',
  EntityOtherLiabilitiesRole = 'EntityOtherLiabilitiesRole',
  EntityOwedVendorRole = 'EntityOwedVendorRole',
  EntityPrepaidVendorRole = 'EntityPrepaidVendorRole',
  EntityProcessorRole = 'EntityProcessorRole',
  EntityRevenuePartnerRole = 'EntityRevenuePartnerRole',
  EntityShareholderRole = 'EntityShareholderRole',
  EntitySupplierRole = 'EntitySupplierRole',
  EntityUnpaidCustomerRole = 'EntityUnpaidCustomerRole',
  EntityUnspecifiedRole = 'EntityUnspecifiedRole',
  EntityVendorRole = 'EntityVendorRole',
  FacilitatorRole = 'FacilitatorRole',
  InstitutionCreditRole = 'InstitutionCreditRole',
  InstitutionDebitRole = 'InstitutionDebitRole',
  OwnerRole = 'OwnerRole',
  PaymentProcessorRole = 'PaymentProcessorRole',
  UnknownRole = 'UnknownRole'
}

export type PartyRuleFilter = {
  createdAt: Scalars['DateTime']['output'];
  operation: Operation;
  parties: Array<PartyRuleFilterParty>;
  partyRuleFilterId: Scalars['String']['output'];
};

export type PartyRuleFilterParty = {
  partyId: Scalars['String']['output'];
  partyName: Scalars['String']['output'];
};

export type PartyTaxInfo = {
  entityType: PartyEntityType;
  partyId: Scalars['String']['output'];
  taxpayerIdentification: TaxpayerIdentification;
};

export type PartyTaxInfoStats = {
  taxpayerIdentificationCounts: Array<TaxpayerIdentificationCount>;
  totalPartyCount: Scalars['Int']['output'];
};

export enum PartyType {
  Global = 'Global',
  Implied = 'Implied',
  Imported = 'Imported',
  Synthesized = 'Synthesized',
  Unknown = 'Unknown',
  UserCreated = 'UserCreated',
  WireImplied = 'WireImplied'
}

export type PayCategoryIntegrations = {
  party?: Maybe<EntityParty>;
  products: Array<CategoryToLabelMappingProduct>;
};

export type PayInvoiceSummary = {
  invoicePaymentDetails: InvoicePaymentDetails;
  status: InvoiceStatus;
};

export type PaymentInstrument = BankAccount | CreditCard;

export enum PaymentStatus {
  Blocked = 'Blocked',
  Canceled = 'Canceled',
  Created = 'Created',
  Deposited = 'Deposited',
  Mailed = 'Mailed',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PendingIncreaseReview = 'PendingIncreaseReview',
  PendingMailing = 'PendingMailing',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
  RequiresAttention = 'RequiresAttention',
  Returned = 'Returned',
  Settled = 'Settled',
  Stopped = 'Stopped',
  Submitted = 'Submitted',
  Unknown = 'Unknown'
}

export type PayrollProvider = {
  legalEntityId: Scalars['String']['output'];
  partyId: Scalars['String']['output'];
  partyName: Scalars['String']['output'];
  payrollProviderType: PayrollProviderType;
  rangeEndedAt?: Maybe<Scalars['DateTime']['output']>;
  rangeStartedAt: Scalars['DateTime']['output'];
};

export enum PayrollProviderType {
  Primary = 'Primary',
  UnknownPayrollProvider = 'UnknownPayrollProvider'
}

export type Period = {
  endedAt: Scalars['DateTime']['output'];
  interval: Interval;
  name: Scalars['String']['output'];
  startedAt: Scalars['DateTime']['output'];
};

export type PeriodInput = {
  endedAt: Scalars['DateTime']['input'];
  interval: Interval;
  name: Scalars['String']['input'];
  startedAt: Scalars['DateTime']['input'];
};

/** Insights */
export type PeriodInsights = {
  entities?: Maybe<ObjectEntities>;
  insights: Array<Insight>;
  period: Period;
};

/** Periods */
export type PeriodMonetaryValue = {
  current?: Maybe<SummaryTotal>;
  deltaPrevious?: Maybe<Scalars['Float']['output']>;
  /** @deprecated unpopulated */
  deltaYearAgo?: Maybe<Scalars['Float']['output']>;
  /** @deprecated unpopulated */
  isFirstOccurrencePeriod?: Maybe<Scalars['Boolean']['output']>;
  moneyFlow?: Maybe<MoneyFlow>;
  prior?: Maybe<SummaryTotal>;
  transactionsCount: Scalars['Long']['output'];
  value: MonetaryValue;
};

export type PeriodReport = {
  author?: Maybe<Contact>;
  createdAt: Scalars['DateTime']['output'];
  generationHistory?: Maybe<ReportGenerationHistory>;
  id: Scalars['String']['output'];
  interval: Interval;
  reportFile: ReportFileMetadata;
  reportFor?: Maybe<Date>;
  status: ReportPackageDocumentStatus;
  threadDetails?: Maybe<Array<ThreadDetails>>;
  title: Scalars['String']['output'];
};

export enum Permission {
  CommentRead = 'CommentRead',
  CommentShare = 'CommentShare',
  FullAccess = 'FullAccess',
  None = 'None',
  ReadOnly = 'ReadOnly',
  SensitiveFullAccess = 'SensitiveFullAccess'
}

export enum Persona {
  AccountingLarge = 'AccountingLarge',
  AccountingMedium = 'AccountingMedium',
  AccountingSmall = 'AccountingSmall',
  Business = 'Business',
  BusinessAndSubsidiaries = 'BusinessAndSubsidiaries',
  InvestmentMedium = 'InvestmentMedium',
  InvestmentSmall = 'InvestmentSmall',
  MailingList = 'MailingList',
  UnknownPersona = 'UnknownPersona'
}

export type PlaidAccount = {
  id: Scalars['String']['input'];
  mask?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subtype?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type PlaidConfig = {
  createdAt: Scalars['DateTime']['output'];
  externalInstitutionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PlaidConnectorProperties = {
  /**
   * For the initial implementation, we wont have anything special here
   * however in the future we may use this to add things like filters
   * for specific account types, icons for different banks, etc
   */
  empty?: Maybe<Scalars['Boolean']['output']>;
};

export type PlaidLinkToken = {
  token: Scalars['String']['output'];
};

export type Portal = {
  backgroundUrl?: Maybe<Scalars['String']['output']>;
  layoutId: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  portalId: Scalars['String']['output'];
};

export type PortalLayout = {
  hasPortal: Scalars['Boolean']['output'];
  layout?: Maybe<Layout>;
  portalId?: Maybe<Scalars['String']['output']>;
};

export type PortalMetricConfig = {
  metricType: MetricType;
  origin: ArchiveIntervalOrigin;
};

export type PortalMetricConfigInput = {
  metricType: MetricType;
  origin: IntervalOrigin;
};

export type PredictedDataSource = {
  institution: Party;
  providerType: ProviderType;
  type: PredictedDataSourceType;
};

export enum PredictedDataSourceType {
  BankAccount = 'BankAccount',
  BankAccountAndCreditCard = 'BankAccountAndCreditCard',
  CreditCard = 'CreditCard',
  UnknownType = 'UnknownType'
}

export type Product = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ProductArea {
  All = 'All',
  Cash = 'Cash',
  Expenses = 'Expenses',
  None = 'None',
  Revenue = 'Revenue'
}

export type ProductInstitution = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  party?: Maybe<Party>;
  products?: Maybe<Array<InstitutionProduct>>;
  routingNumber?: Maybe<Scalars['String']['output']>;
};

export enum ProductRecurrence {
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  UnknownRecurrence = 'UnknownRecurrence',
  Yearly = 'Yearly'
}

export enum ProposalType {
  AffiliateInviteManagedOperator = 'AffiliateInviteManagedOperator',
  AffiliationAssistClient = 'AffiliationAssistClient',
  AffiliationCreateClient = 'AffiliationCreateClient',
  AffiliationJoinClient = 'AffiliationJoinClient',
  CreateOrganization = 'CreateOrganization',
  EmploymentAssistOperator = 'EmploymentAssistOperator',
  EmploymentJoinOrg = 'EmploymentJoinOrg',
  OperatorAssistAffiliate = 'OperatorAssistAffiliate',
  UnknownProposalType = 'UnknownProposalType'
}

export type ProposedGlobalParty = {
  designation: PartyDesignation;
  iconUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Provider = {
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum ProviderType {
  CSVImport = 'CSVImport',
  Connect = 'Connect',
  DigitsInvoicing = 'DigitsInvoicing',
  DigitsPay = 'DigitsPay',
  DigitsPaymentsLedger = 'DigitsPaymentsLedger',
  Manual = 'Manual',
  Plaid = 'Plaid',
  QuickBooks = 'QuickBooks',
  UnknownProvider = 'UnknownProvider',
  Xero = 'Xero'
}

export type PublicInvoice = {
  customer?: Maybe<Customer>;
  discounts?: Maybe<Array<InvoiceDiscount>>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<Array<InvoiceFile>>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<InvoicingProfile>;
  legalEntityId: Scalars['String']['output'];
  lineItems: Array<InvoiceLineItem>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
};

export type PullRequest = {
  author: PullRequestAuthor;
  branch: Scalars['String']['output'];
  labels?: Maybe<Array<PullRequestLabel>>;
  number: Scalars['Int']['output'];
  prState: PullRequestState;
  repo: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PullRequestAuthor = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  login: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PullRequestFile = {
  contents: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum PullRequestFilterState {
  All = 'All',
  Closed = 'Closed',
  Open = 'Open'
}

export type PullRequestLabel = {
  hexColor: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum PullRequestState {
  Closed = 'Closed',
  Open = 'Open'
}

export type PullRequestSummary = {
  mergeableStatus: MergeableStatus;
  merged: Scalars['Boolean']['output'];
  pullRequest: PullRequest;
  reviewStatus: ReviewStatus;
};

export enum PushAction {
  Reassign = 'Reassign',
  Rename = 'Rename',
  UnknownPushAction = 'UnknownPushAction'
}

export type Query = {
  checkBillingOrganizationPlan: CheckBillingPlan;
  checkLedgerTransactionStale: LedgerTransactionStale;
  countEmployees: ListCount;
  /**
   * TODO: this reuses TransactionSource but this is more of a TransactionEditHistory so we may want to create a new type
   * dimensions are derivable from the query but that requires
   * requires a patch to our fork, so just passing them in for
   * now.
   */
  dimensionalTransactionSummary: DimensionSummary;
  documentDownloadUrl: DocumentDownload;
  emailOAuthProvider: OAuthService;
  exportTransactions: DocumentInitialSegment;
  factIdForLedgerTransactionId: Scalars['String']['output'];
  fetchSimilarTransactions: Array<Transaction>;
  findConnectionsByInstitution: Array<ConnectionSummary>;
  findThreadByComment?: Maybe<ThreadDetails>;
  generateExecutiveSummary: Scalars['String']['output'];
  generateStatementSummary: StatementSummary;
  /** Invoicing */
  getDraftInvoiceDefaults: InvoiceDefaults;
  getNextInvoiceNumber: InvoiceNumber;
  /** @deprecated use readPortalLayout instead */
  hasPortal: Scalars['Boolean']['output'];
  highlightWorkItem: HighlightedTokens;
  listAccountReconciliations: Array<AccountReconciliations>;
  listAccountantSuggestedUsers: Array<User>;
  listActionItems: Array<ActionItem>;
  listActionItemsBadgeCounts: Array<LegalEntityBadgeCount>;
  listActivePartyRoles: Array<PartyRole>;
  listActivePartyRolesBatch: Array<ActivePartyRoles>;
  /** Affiliates of a Legal Entity */
  listAffiliates: AffiliateOrganizations;
  listAllWorkItems: Array<WorkItem>;
  listAuditRuleStatuses: Array<AuditRuleStatus>;
  listAuditRules: Array<AuditRule>;
  listAuthConnections: Array<ConnectionSummary>;
  listBoostBadgeCounts: Array<LegalEntityBadgeCount>;
  listBulkCategorySuggestions: ListBulkCategorySuggestionsResponse;
  /** Chart of Accounts */
  listCategories: Array<Category>;
  listCategoryIntegrations: Array<CategoryIntegrations>;
  /** Category Mappings */
  listCategoryMappings: Array<CategoryMapping>;
  listConnectionBadgeCounts: Array<LegalEntityBadgeCount>;
  /** Connect */
  listConnections: Array<ConnectionSummary>;
  listConnectors: Array<Connector>;
  /** Data Sources */
  listDataSources: Array<DataSource>;
  listDataSourcesBadgeCounts: Array<LegalEntityBadgeCount>;
  listDataSourcesByIds: Array<DataSources>;
  listDeletedDataSources: Array<DataSource>;
  listDepartmentFactHierarchy: Array<ObjectMappingHierarchyNode>;
  listDepartments: Array<Department>;
  listDocuments: Array<RawDocument>;
  /** Doppelganger */
  listDoppelgangerPermits: Array<Maybe<DoppelgangerPermit>>;
  listDraftInvoices: Array<DraftInvoiceSummary>;
  listEmployees: Array<Employee>;
  listEmployeesAffiliations: Array<EmployeeAffiliations>;
  listEnrichedDataSources: Array<EnrichedDataSource>;
  listGrantees: Array<User>;
  /** Insights */
  listInsights: Array<PeriodInsights>;
  listInstitutions: Array<ProductInstitution>;
  /** Proposals */
  listInvitationProposals: Array<InvitationProposal>;
  listInvoicingProfiles: Array<InvoicingProfile>;
  listLatestPartyHistories: Array<PartyHistory>;
  listLimitedSuggestedUsers: Array<User>;
  /** Object Mapping */
  listLineClassFactHierarchy: Array<ObjectMappingHierarchyNode>;
  listLocations: Array<Location>;
  listMetricTransactions: Array<Transaction>;
  listNotificationSettings: Array<NotificationSetting>;
  listNotifications: Array<WebNotification>;
  /** Sharing and Access Control */
  listObjectACLs: Array<ObjectAcl>;
  listOrganizationAffiliations: Array<OrganizationAffiliation>;
  listOrganizationInvitations: Array<Invitation>;
  listOrganizationUsers: Array<User>;
  listOwnedDataSources: Array<OwnedDataSource>;
  listPartyTaxInfo: Array<PartyTaxInfo>;
  listPartyTaxInfoStats: PartyTaxInfoStats;
  listPredictedDataSources: Array<PredictedDataSource>;
  listQuotePackagesByLegalEntity?: Maybe<Array<QuotePackage>>;
  listReportPackages: Array<ReportPackageMetadata>;
  listReportTemplates: Array<ReportTemplate>;
  listReportsBadgeCounts: Array<LegalEntityBadgeCount>;
  listSentInvoices: Array<InvoiceSummary>;
  listShareSuggestedUsers: Array<User>;
  listSimilarActionItems: Array<Maybe<Array<Scalars['String']['output']>>>;
  /** TODO: delete when API below is renamed to listSimilarTransactions */
  listSimilarTransactions: Array<Maybe<Array<Scalars['String']['output']>>>;
  /** Autocomplete */
  listSuggestedUsers: Array<User>;
  /** Teams management */
  listTeams: Array<Team>;
  /** Comments */
  listThreads: Threads;
  listTransactionEditingHistory: Array<TransactionSource>;
  listTransactionReviewQuestions?: Maybe<Array<TransactionReviewQuestion>>;
  /** Transactions */
  listTransactions: Array<Transaction>;
  listTransactionsByFactIds: Array<Transaction>;
  listWorkItems: Array<WorkItem>;
  /** Work Items */
  listWorkItemsByIds: Array<WorkItem>;
  /** Component Data */
  liveComponentData: ComponentData;
  metricTransactionSummary: DimensionSummary;
  minMaxAmount: MinMaxAmount;
  onboardConnections: Array<SuggestedConnection>;
  previewBillingSubscription: MonetaryValue;
  previewInvoiceEmail: Scalars['String']['output'];
  /** Reconciliations */
  readAccountReconciliation?: Maybe<ReconciliationDetails>;
  /** Activity Feed */
  readActivityFeed: ActivityFeed;
  /** Aspects */
  readAspectRequestMeta?: Maybe<AspectRequestMeta>;
  readAssociatedThreads: ThreadListWithEntities;
  readBillingCustomerProfile: BillingCustomerProfile;
  /**
   * Billing
   * deprecated: remove after 10/3/2024
   */
  readBillingOrganizationPlan: BillingPlan;
  readBillingPlans: Array<BillingPlan>;
  readBillingSubscriptionHistory: Array<BillingSubscriptionHistory>;
  readBillingSubscriptionStatus: BillingSubscription;
  readBooksClosedDate: BooksClosedDate;
  readBoostBadgeCount: BadgeCount;
  readCategory: Category;
  readCategorySuggestions: Array<CategorySuggestion>;
  readComponentData: ArchivedComponent;
  readConnection?: Maybe<Connection>;
  readDataSourcePreferences: Array<DataSourcePreference>;
  readDefaultApprover?: Maybe<Scalars['String']['output']>;
  /** Departments */
  readDepartment: Department;
  /** Documents */
  readDocument: RawDocument;
  readDraftInvoice: DraftInvoice;
  /** Employees */
  readEmployee: Employee;
  readFactId: Scalars['String']['output'];
  readInvitationProposal: InvitationProposal;
  /** Layouts */
  readLayout: Layout;
  /** Transaction Editing */
  readLedgerTransaction: ReadLedgerTransaction;
  readLedgerTransactionById: ReadLedgerTransaction;
  /** Legal Entity */
  readLegalEntity: LegalEntity;
  /** Metadata */
  readLegalEntityMetadata: ViewMetadata;
  /** Legal Entity Preferences */
  readLegalEntityPreferences: LegalEntityPreferences;
  /** Locations */
  readLocation: Location;
  readMetricEquation: MetricEquation;
  /** Notifications */
  readNotification: WebNotification;
  readNotificationSettings: Array<NotificationSetting>;
  /** Organization */
  readOrganization: Organization;
  readOrganizationBillingPlan: OrganizationBillingPlan;
  readOrganizationPreferences: OrganizationPreferences;
  readOrganizationTemplate: OrganizationTemplate;
  /** Parties */
  readParty: Party;
  /** @deprecated use readPortalLayout instead */
  readPortal: Portal;
  readPortalLayout: PortalLayout;
  /** Quotes */
  readQuotePackage: QuotePackage;
  readRealtimeHealthStatus: RealtimeHealthStatus;
  readReconciliationPeriodStats: ReconciliationPeriodStats;
  readRefreshProgress: RefreshProgress;
  readReportArchive: Statement;
  /** Report automation settings */
  readReportAutomationSettings: ReportAutomationSettings;
  readReportPackage: ReportPackage;
  readReportPackageCount: PackageCounts;
  readSentInvoice: SentInvoiceUsers;
  readSentInvoiceFiles: Array<InvoiceFile>;
  readShare: ObjectPermission;
  readShareAdjacency: AdjacentObjectPermission;
  /** Source Accounts */
  readSourceAccountCategory?: Maybe<Category>;
  readTeam: Team;
  readTeamACL: TeamAcl;
  readThread: ThreadWithEntities;
  readTransactionDetails: TransactionWithDetails;
  readTransactionReviewNextReminder?: Maybe<Scalars['DateTime']['output']>;
  readTransactionReviewReminderMessage: TransactionReviewReminderMessage;
  readTransactionReviewSchedule: TransactionReviewSchedule;
  readTransactionWorkItemId: Scalars['String']['output'];
  /** User */
  readUser: User;
  readUserACL: UserAcl;
  /** Invitations */
  readUserInvitation?: Maybe<Invitation>;
  /** Vanity */
  readVanityStats: VanityStats;
  refreshReportPackageStatus: Array<ReportGenerationStatus>;
  reportDownloadUrl: ReportDownload;
  /** Search */
  searchEntities: SearchResult;
  searchGlobalParties: SearchResult;
  /** TODO: Handle search as part of normal entity search */
  searchInvoiceCustomers: SearchInvoiceCustomersResult;
  searchInvoiceDiscounts: Array<Discount>;
  /** TODO: Handle search as part of normal entity search */
  searchInvoiceProducts: Array<InvoiceProduct>;
  searchTerms: SearchResult;
  suggestedConnections: Array<SuggestedConnection>;
  summarizeActionItemsByObjectKind: Array<ActionItemsObjectKindSummary>;
  summarizeActionItemsByTime: Array<ActionItemSummary>;
  summarizeDocuments: DocumentsSummary;
  /** Report Packages */
  summarizeReportPackages: Array<ReportPackageSummary>;
  /** Trial Balance */
  summarizeTrialBalance: TrialBalance;
  summarizeWorkItems: Array<WorkItemSummary>;
  tokenIdentityProvider: Scalars['String']['output'];
  validateCategoryMappings: MappingValidity;
};


export type QueryCheckBillingOrganizationPlanArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryCheckLedgerTransactionStaleArgs = {
  factId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type QueryCountEmployeesArgs = {
  filter: EmployeeFilter;
};


export type QueryDimensionalTransactionSummaryArgs = {
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  defaultCategoryType?: InputMaybe<CategoryType>;
  dimensions: Array<SummaryDimension>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  direction: DirectionFromOrigin;
  filter: SummaryFilterInput;
  intervalPartition?: InputMaybe<IntervalPartition>;
  masks?: InputMaybe<Array<Array<SummaryDimension>>>;
  matchBy?: InputMaybe<Matcher>;
  naturalFlow?: InputMaybe<Flow>;
  origin: IntervalOrigin;
};


export type QueryDocumentDownloadUrlArgs = {
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryExportTransactionsArgs = {
  direction: DirectionFromOrigin;
  fields?: InputMaybe<Array<CsvExportField>>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  filter: TransactionFilter;
  order?: InputMaybe<TransactionOrder>;
  origin: IntervalOrigin;
  singleColumnAmount?: InputMaybe<Scalars['Boolean']['input']>;
  temporary?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFactIdForLedgerTransactionIdArgs = {
  ledgerTransactionId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type QueryFetchSimilarTransactionsArgs = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  entryType: EntryType;
  factId: Scalars['String']['input'];
  numNeighbors?: InputMaybe<Scalars['Int']['input']>;
  viewKey: ViewKey;
};


export type QueryFindConnectionsByInstitutionArgs = {
  institutionName: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryFindThreadByCommentArgs = {
  commentId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryGenerateExecutiveSummaryArgs = {
  legalEntityId: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  tone?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGenerateStatementSummaryArgs = {
  input: Scalars['String']['input'];
  reportType: Scalars['String']['input'];
};


export type QueryGetDraftInvoiceDefaultsArgs = {
  invoiceProfileId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryGetNextInvoiceNumberArgs = {
  invoiceProfileId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryHasPortalArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryHighlightWorkItemArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  similarityType: SimilarityType;
};


export type QueryListAccountReconciliationsArgs = {
  direction: DirectionFromOrigin;
  filter?: InputMaybe<InputAccountReconciliationsFilter>;
  legalEntityId: Scalars['String']['input'];
  origin: IntervalOrigin;
  viewKey: ViewKey;
};


export type QueryListAccountantSuggestedUsersArgs = {
  object: InputObjectIdentifier;
  organizationId: Scalars['String']['input'];
};


export type QueryListActionItemsArgs = {
  filter: ActionItemFilter;
  origin?: InputMaybe<IntervalOrigin>;
  pagination: Pagination;
  viewKey: ViewKey;
};


export type QueryListActionItemsBadgeCountsArgs = {
  direction?: InputMaybe<DirectionFromOrigin>;
  filter: ActionItemFilter;
  legalEntities?: InputMaybe<Array<InputMaybe<LegalEntityPermission>>>;
  legalEntityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  origin: IntervalOrigin;
};


export type QueryListActivePartyRolesArgs = {
  direction?: InputMaybe<DirectionFromOrigin>;
  filter: SummaryFilterInput;
  origin?: InputMaybe<IntervalOrigin>;
};


export type QueryListActivePartyRolesBatchArgs = {
  inputs: Array<SummarizeInput>;
};


export type QueryListAffiliatesArgs = {
  affiliateType: AffiliateType;
  legalEntityId: Scalars['String']['input'];
};


export type QueryListAllWorkItemsArgs = {
  filter: WorkItemFilter;
  pagination: Pagination;
};


export type QueryListAuditRuleStatusesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListAuditRulesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListAuthConnectionsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListBoostBadgeCountsArgs = {
  direction: DirectionFromOrigin;
  filter: WorkItemBadgeCountFilter;
  origin: IntervalOrigin;
};


export type QueryListBulkCategorySuggestionsArgs = {
  legalEntityId: Scalars['String']['input'];
  transactionFacts: Array<TransactionFactEntryType>;
};


export type QueryListCategoriesArgs = {
  categoryType?: InputMaybe<CategoryType>;
  viewKey: ViewKey;
};


export type QueryListCategoryIntegrationsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListCategoryMappingsArgs = {
  legalEntityId: Scalars['String']['input'];
  product: CategoryToLabelMappingProduct;
};


export type QueryListConnectionBadgeCountsArgs = {
  filter: ConnectionBadgeCountFilter;
};


export type QueryListConnectionsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListConnectorsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListDataSourcesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListDataSourcesBadgeCountsArgs = {
  legalEntityIds: Array<Scalars['String']['input']>;
};


export type QueryListDataSourcesByIdsArgs = {
  legalEntityIds: Array<Scalars['String']['input']>;
};


export type QueryListDeletedDataSourcesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListDepartmentFactHierarchyArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListDepartmentsArgs = {
  viewKey: ViewKey;
};


export type QueryListDocumentsArgs = {
  filter?: InputMaybe<DocumentFilter>;
  legalEntityId: Scalars['String']['input'];
  order?: InputMaybe<DocumentSort>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryListDraftInvoicesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListEmployeesArgs = {
  filter: EmployeeFilter;
  pagination: Pagination;
  sort?: InputMaybe<EmployeeSort>;
};


export type QueryListEmployeesAffiliationsArgs = {
  filter: EmployeeFilter;
  pagination: Pagination;
  sort?: InputMaybe<EmployeeSort>;
};


export type QueryListEnrichedDataSourcesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListGranteesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListInsightsArgs = {
  direction: DirectionFromOrigin;
  filter: InsightFilter;
  origin: IntervalOrigin;
  pagination: Pagination;
};


export type QueryListInstitutionsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryListInvitationProposalsArgs = {
  proposalType: ProposalType;
};


export type QueryListInvoicingProfilesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListLatestPartyHistoriesArgs = {
  legalEntityId: Scalars['String']['input'];
  partyIds: Array<Scalars['String']['input']>;
};


export type QueryListLimitedSuggestedUsersArgs = {
  object: InputObjectIdentifier;
};


export type QueryListLineClassFactHierarchyArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListLocationsArgs = {
  viewKey: ViewKey;
};


export type QueryListMetricTransactionsArgs = {
  metricSummaryType?: InputMaybe<MetricSummaryItem>;
  order?: InputMaybe<TransactionOrder>;
  origin: IntervalOrigin;
  pagination: Pagination;
  summaryLineType?: InputMaybe<SummaryLineItem>;
  viewKey: ViewKey;
};


export type QueryListObjectAcLsArgs = {
  ids: Array<Scalars['String']['input']>;
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};


export type QueryListOrganizationAffiliationsArgs = {
  id: Scalars['String']['input'];
};


export type QueryListOrganizationInvitationsArgs = {
  id: Scalars['String']['input'];
};


export type QueryListOrganizationUsersArgs = {
  id: Scalars['String']['input'];
};


export type QueryListOwnedDataSourcesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListPartyTaxInfoArgs = {
  direction: DirectionFromOrigin;
  filter: TransactionFilter;
  origin: IntervalOrigin;
};


export type QueryListPartyTaxInfoStatsArgs = {
  direction: DirectionFromOrigin;
  filter: TransactionFilter;
  origin: IntervalOrigin;
};


export type QueryListPredictedDataSourcesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListQuotePackagesByLegalEntityArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListReportPackagesArgs = {
  direction: DirectionFromOrigin;
  filter?: InputMaybe<PackagesFilter>;
  legalEntityId: Scalars['String']['input'];
  origin: IntervalOrigin;
  pagination: Pagination;
  sort?: InputMaybe<PackagesSort>;
};


export type QueryListReportTemplatesArgs = {
  interval: Interval;
  legalEntityId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
};


export type QueryListReportsBadgeCountsArgs = {
  direction: DirectionFromOrigin;
  legalEntityIds: Array<Scalars['String']['input']>;
  origin: IntervalOrigin;
};


export type QueryListSentInvoicesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListShareSuggestedUsersArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListSimilarActionItemsArgs = {
  items: Array<SimilarActionItem>;
  legalEntityId: Scalars['String']['input'];
};


export type QueryListSimilarTransactionsArgs = {
  items: Array<ListSimilarTransactionsItem>;
  legalEntityId: Scalars['String']['input'];
};


export type QueryListSuggestedUsersArgs = {
  object: InputObjectIdentifier;
  organizationId: Scalars['String']['input'];
};


export type QueryListTeamsArgs = {
  legalEntityId: Scalars['String']['input'];
  memberOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryListThreadsArgs = {
  idsByKind: IdentifiersByKind;
  viewType: ViewType;
};


export type QueryListTransactionEditingHistoryArgs = {
  factId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryListTransactionReviewQuestionsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryListTransactionsArgs = {
  direction: DirectionFromOrigin;
  filter: TransactionFilter;
  naturalFlow?: InputMaybe<Flow>;
  order?: InputMaybe<TransactionOrder>;
  origin: IntervalOrigin;
  pagination: Pagination;
};


export type QueryListTransactionsByFactIdsArgs = {
  factIds: Array<Scalars['String']['input']>;
  viewKey: ViewKey;
};


export type QueryListWorkItemsArgs = {
  direction: DirectionFromOrigin;
  filter: WorkItemFilter;
  origin: IntervalOrigin;
};


export type QueryListWorkItemsByIdsArgs = {
  legalEntityId: Scalars['String']['input'];
  workItemIds: Array<Scalars['String']['input']>;
};


export type QueryLiveComponentDataArgs = {
  config: LayoutComponentConfigInput;
  viewId: ViewIdentifierInput;
};


export type QueryMetricTransactionSummaryArgs = {
  metricSummaryType?: InputMaybe<MetricSummaryItem>;
  origin: IntervalOrigin;
  summaryLineType?: InputMaybe<SummaryLineItem>;
  viewKey: ViewKey;
};


export type QueryMinMaxAmountArgs = {
  direction: DirectionFromOrigin;
  filter: TransactionFilter;
  origin: IntervalOrigin;
};


export type QueryOnboardConnectionsArgs = {
  institutionProducts: Array<InstitutionProduct>;
  legalEntityId: Scalars['String']['input'];
};


export type QueryPreviewBillingSubscriptionArgs = {
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
};


export type QueryPreviewInvoiceEmailArgs = {
  invoiceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadAccountReconciliationArgs = {
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type QueryReadActivityFeedArgs = {
  identifier?: InputMaybe<InputObjectIdentifier>;
  legalEntityId: Scalars['String']['input'];
  page: Pagination;
};


export type QueryReadAspectRequestMetaArgs = {
  code: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadAssociatedThreadsArgs = {
  allowResolved?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadBillingCustomerProfileArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryReadBillingOrganizationPlanArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryReadBillingPlansArgs = {
  organizationId: Scalars['String']['input'];
  productNames: Array<Scalars['String']['input']>;
};


export type QueryReadBillingSubscriptionHistoryArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryReadBillingSubscriptionStatusArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryReadBooksClosedDateArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadBoostBadgeCountArgs = {
  direction: DirectionFromOrigin;
  filter: WorkItemFilter;
  origin: IntervalOrigin;
};


export type QueryReadCategoryArgs = {
  categoryId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type QueryReadCategorySuggestionsArgs = {
  entryType: EntryType;
  legalEntityId: Scalars['String']['input'];
  transactionFactId: Scalars['String']['input'];
};


export type QueryReadComponentDataArgs = {
  dataId: Scalars['String']['input'];
  layoutId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadConnectionArgs = {
  connectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadDataSourcePreferencesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadDefaultApproverArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadDepartmentArgs = {
  departmentId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type QueryReadDocumentArgs = {
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadDraftInvoiceArgs = {
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadEmployeeArgs = {
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryReadFactIdArgs = {
  dataSourceId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  externalLineItemId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadInvitationProposalArgs = {
  proposalId: Scalars['String']['input'];
};


export type QueryReadLayoutArgs = {
  layoutId: Scalars['String']['input'];
  layoutVersionId?: InputMaybe<Scalars['String']['input']>;
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadLedgerTransactionArgs = {
  filter: TransactionFilter;
  transactionFactId: Scalars['String']['input'];
};


export type QueryReadLedgerTransactionByIdArgs = {
  ledgerTransactionId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type QueryReadLegalEntityArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadLegalEntityMetadataArgs = {
  viewKey: ViewKey;
};


export type QueryReadLegalEntityPreferencesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadLocationArgs = {
  locationId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type QueryReadMetricEquationArgs = {
  layoutComponentConfig?: InputMaybe<LayoutComponentConfigInput>;
  layoutComponentType?: InputMaybe<LayoutComponentType>;
  metricSummaryType?: InputMaybe<MetricSummaryItem>;
  origin?: InputMaybe<IntervalOrigin>;
  summaryLineType?: InputMaybe<SummaryLineItem>;
  viewKey: ViewKey;
};


export type QueryReadNotificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadNotificationSettingsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadOrganizationBillingPlanArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryReadOrganizationPreferencesArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadOrganizationTemplateArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadPartyArgs = {
  partyId: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type QueryReadPortalArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadPortalLayoutArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadQuotePackageArgs = {
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadRealtimeHealthStatusArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadReconciliationPeriodStatsArgs = {
  direction: DirectionFromOrigin;
  legalEntityId: Scalars['String']['input'];
  origin: IntervalOrigin;
  viewKey: ViewKey;
};


export type QueryReadRefreshProgressArgs = {
  dataSourceId: Scalars['String']['input'];
};


export type QueryReadReportArchiveArgs = {
  legalEntityId: Scalars['String']['input'];
  reportFileSource?: InputMaybe<ReportFileSource>;
  reportId: Scalars['String']['input'];
  reportPackageId: Scalars['String']['input'];
  reportVersionId: Scalars['String']['input'];
};


export type QueryReadReportAutomationSettingsArgs = {
  interval: Interval;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryReadReportPackageArgs = {
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReadReportPackageCountArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadSentInvoiceArgs = {
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadSentInvoiceFilesArgs = {
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadShareArgs = {
  id: Scalars['String']['input'];
  intentId?: InputMaybe<Scalars['String']['input']>;
  kind: ObjectKind;
};


export type QueryReadShareAdjacencyArgs = {
  id: Scalars['String']['input'];
  kind: ObjectKind;
  legalEntitySlug: Scalars['String']['input'];
};


export type QueryReadSourceAccountCategoryArgs = {
  sourceAccountId: Scalars['String']['input'];
};


export type QueryReadTeamArgs = {
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};


export type QueryReadTeamAclArgs = {
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};


export type QueryReadThreadArgs = {
  allowResolved?: InputMaybe<Scalars['Boolean']['input']>;
  threadId: Scalars['String']['input'];
};


export type QueryReadTransactionDetailsArgs = {
  filter: TransactionFilter;
  transactionFactId: Scalars['String']['input'];
};


export type QueryReadTransactionReviewNextReminderArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadTransactionReviewReminderMessageArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadTransactionReviewScheduleArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadTransactionWorkItemIdArgs = {
  factId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadVanityStatsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryRefreshReportPackageStatusArgs = {
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
};


export type QueryReportDownloadUrlArgs = {
  legalEntityId: Scalars['String']['input'];
  reportFileSource?: InputMaybe<ReportFileSource>;
  reportId: Scalars['String']['input'];
  reportPackageId: Scalars['String']['input'];
  reportVersionId: Scalars['String']['input'];
};


export type QuerySearchEntitiesArgs = {
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
  occurredAfter?: InputMaybe<Scalars['Float']['input']>;
  occurredBefore?: InputMaybe<Scalars['Float']['input']>;
  page: Pagination;
  sort?: InputMaybe<Array<SearchEntitiesSort>>;
  sortHitsLeftmost?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  viewType?: InputMaybe<ViewType>;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchGlobalPartiesArgs = {
  page: Pagination;
  sort?: InputMaybe<Array<SearchEntitiesSort>>;
  sortHitsLeftmost?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
  viewType?: InputMaybe<ViewType>;
};


export type QuerySearchInvoiceCustomersArgs = {
  legalEntityId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};


export type QuerySearchInvoiceDiscountsArgs = {
  legalEntityId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};


export type QuerySearchInvoiceProductsArgs = {
  legalEntityId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};


export type QuerySearchTermsArgs = {
  kinds?: InputMaybe<Array<ObjectKind>>;
  legalEntityId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  viewType?: InputMaybe<ViewType>;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySuggestedConnectionsArgs = {
  filter: SuggestedConnectionsFilter;
  legalEntityId: Scalars['String']['input'];
};


export type QuerySummarizeActionItemsByObjectKindArgs = {
  filter: ActionItemFilter;
  legalEntityId: Scalars['String']['input'];
};


export type QuerySummarizeActionItemsByTimeArgs = {
  filter: ActionItemFilter;
  origin: IntervalOrigin;
  viewKey: ViewKey;
};


export type QuerySummarizeDocumentsArgs = {
  filter?: InputMaybe<DocumentFilter>;
  legalEntityId: Scalars['String']['input'];
};


export type QuerySummarizeReportPackagesArgs = {
  direction: DirectionFromOrigin;
  legalEntityId: Scalars['String']['input'];
  origin: IntervalOrigin;
};


export type QuerySummarizeTrialBalanceArgs = {
  origin: IntervalOrigin;
  viewKey: ViewKey;
};


export type QuerySummarizeWorkItemsArgs = {
  direction: DirectionFromOrigin;
  filter: WorkItemFilter;
  origin: IntervalOrigin;
};


export type QueryTokenIdentityProviderArgs = {
  service: TokenIdentityService;
};


export type QueryValidateCategoryMappingsArgs = {
  legalEntityId: Scalars['String']['input'];
  product: CategoryToLabelMappingProduct;
};

export type QuickBooksAccountingInfoPreferences = {
  bookCloseDate?: Maybe<Date>;
};

export type QuickBooksCompany = {
  preferences?: Maybe<QuickBooksPreferences>;
};

export type QuickBooksConfig = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  realmId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type QuickBooksPreferences = {
  accountingInfo?: Maybe<QuickBooksAccountingInfoPreferences>;
};

export type QuotePackage = {
  companyDetails: CompanyDetails;
  contractDocumentId?: Maybe<Scalars['String']['output']>;
  financials?: Maybe<Array<CompanyFinancials>>;
  id: Scalars['String']['output'];
  recipient?: Maybe<Contact>;
  status: QuoteStatus;
};

export type QuotePackageId = {
  id: Scalars['String']['output'];
};

export enum QuoteStatus {
  Accepted = 'Accepted',
  Created = 'Created',
  CustomerReview = 'CustomerReview',
  Deferred = 'Deferred',
  New = 'New',
  UnknownQuoteStatus = 'UnknownQuoteStatus'
}

export type RampConnectorProperties = {
  authorizationURL: Scalars['String']['output'];
};

export type RawDocument = {
  asyncProcessingStatus: AsyncProcessingStatus;
  collectionId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<EntityUser>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  features: Array<Feature>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Float']['output']>;
  formats: Array<Format>;
  id: Scalars['String']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  sourceFormat?: Maybe<Format>;
  sourceFormatId?: Maybe<Scalars['String']['output']>;
  status: DocumentStatus;
  thumbnail?: Maybe<Segment>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** ReadLedgerTransaction is the output type of readLedgerTransaction GraphQL query */
export type ReadLedgerTransaction = {
  transaction: LedgerTransaction;
};

export enum RealtimeHealthStatus {
  HasLoginRequired = 'HasLoginRequired',
  HasPredictions = 'HasPredictions',
  Healthy = 'Healthy',
  UnknownHealthStatus = 'UnknownHealthStatus'
}

export enum Reconciliation {
  Reconciled = 'Reconciled',
  Unreconciled = 'Unreconciled'
}

export type ReconciliationBalances = {
  ledgerAccountDifferences: LedgerAccountDifferences;
  ledgerBalances: LedgerBalances;
  statementBalances: StatementBalances;
};

export type ReconciliationDetails = {
  balances: ReconciliationBalances;
  normalEntryType: EntryType;
  reconciliation: AccountReconciliation;
  transactionCounts: TransactionCounts;
};

export enum ReconciliationFilter {
  FindAll = 'FindAll',
  Reconciled = 'Reconciled',
  Unreconciled = 'Unreconciled',
  Unspecified = 'Unspecified'
}

export type ReconciliationPeriodStats = {
  drafts: Scalars['Int']['output'];
  finalized: Scalars['Int']['output'];
  noActivity?: Maybe<Scalars['Int']['output']>;
  notStarted: Scalars['Int']['output'];
  totalCategories: Scalars['Int']['output'];
};

export type RefreshProgress = {
  mostRecentFetchStartTime?: Maybe<Scalars['DateTime']['output']>;
  progressPercentage?: Maybe<Scalars['Float']['output']>;
};

export type RemittanceFeatureValue = {
  remittanceValue: RemittanceItem;
};

export type RemittanceItem = {
  BankAccountName?: Maybe<Scalars['String']['output']>;
  BankAccountNumber: Scalars['String']['output'];
  BankName: Scalars['String']['output'];
  BankRoutingNumber: Scalars['String']['output'];
  RemittanceType: RemittanceType;
};

export enum RemittanceType {
  ACH = 'ACH',
  Unknown = 'Unknown',
  Wire = 'Wire'
}

export type Report = {
  author?: Maybe<Contact>;
  createdAt: Scalars['DateTime']['output'];
  generationHistory?: Maybe<ReportGenerationHistory>;
  id: Scalars['String']['output'];
  interval: Interval;
  options?: Maybe<ReportDocumentOptions>;
  reportFile: ReportFileMetadata;
  reportFor?: Maybe<Date>;
  status: ReportPackageDocumentStatus;
  title: Scalars['String']['output'];
  versionId: Scalars['String']['output'];
};

export enum ReportAction {
  Print = 'Print'
}

export type ReportActionEvent = {
  action: ReportAction;
  packageId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Report automation settings */
export type ReportAutomationSettings = {
  autoCreateDraft: Scalars['Boolean']['output'];
  autoPublishDraft: Scalars['Boolean']['output'];
  autoPublishDraftDay: Scalars['Long']['output'];
  autoPublishFinal: Scalars['Boolean']['output'];
  autoPublishFinalDay: Scalars['Long']['output'];
  interval: Interval;
  legalEntityId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
};

export type ReportDocumentOptions = {
  amountPrecision?: Maybe<ReportNumberPrecision>;
  collapsedSections?: Maybe<CollapsedSections>;
  comparedToIncome?: Maybe<ReportOptionComparison>;
  comparedToTotal?: Maybe<ReportOptionComparison>;
  deltaMonthOverMonth?: Maybe<ReportOptionComparison>;
  deltaMonthOverMonthPeriods?: Maybe<Scalars['Int']['output']>;
  deltaYearOverYear?: Maybe<ReportOptionComparison>;
  deltaYearToDate?: Maybe<ReportOptionComparison>;
  sparklineLookbackPeriods?: Maybe<Scalars['Int']['output']>;
  yearToDate?: Maybe<ReportOptionComparison>;
};

export type ReportDocumentOptionsInput = {
  amountPrecision?: InputMaybe<ReportNumberPrecision>;
  collapsedSections?: InputMaybe<CollapsedSectionsInput>;
  comparedToIncome?: InputMaybe<ReportOptionComparison>;
  comparedToTotal?: InputMaybe<ReportOptionComparison>;
  deltaMonthOverMonth?: InputMaybe<ReportOptionComparison>;
  deltaMonthOverMonthPeriods?: InputMaybe<Scalars['Int']['input']>;
  deltaYearOverYear?: InputMaybe<ReportOptionComparison>;
  deltaYearToDate?: InputMaybe<ReportOptionComparison>;
  sparklineLookbackPeriods?: InputMaybe<Scalars['Int']['input']>;
  yearToDate?: InputMaybe<ReportOptionComparison>;
};

export type ReportDownload = {
  url: Scalars['String']['output'];
};

export type ReportFileMetadata = {
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  source: ReportFileSource;
};

export enum ReportFileSource {
  AVS = 'AVS',
  DataIngestion = 'DataIngestion',
  UnknownFileSource = 'UnknownFileSource',
  UserUploaded = 'UserUploaded'
}

export type ReportGenerationHistory = {
  authorId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<Scalars['String']['output']>;
  generationEndedAt?: Maybe<Scalars['DateTime']['output']>;
  generationStartedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  kind: ReportKind;
  legalEntityId: Scalars['String']['output'];
  periodEndedAt: Scalars['DateTime']['output'];
  periodStartedAt: Scalars['DateTime']['output'];
  reportId: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  versionId: Scalars['String']['output'];
  viewVersion: Scalars['Float']['output'];
};

export type ReportGenerationStatus = {
  reportId: Scalars['String']['output'];
  status: GenerationStatus;
  versionId: Scalars['String']['output'];
};

export enum ReportKind {
  APAging = 'APAging',
  ARAging = 'ARAging',
  BalanceSheet = 'BalanceSheet',
  CashFlow = 'CashFlow',
  ExpenseSummary = 'ExpenseSummary',
  ProfitAndLoss = 'ProfitAndLoss',
  Unknown = 'Unknown'
}

export enum ReportNumberPrecision {
  Decimal = 'Decimal',
  InvalidNumberPrecision = 'InvalidNumberPrecision',
  Rounded = 'Rounded'
}

export enum ReportOptionComparison {
  Amount = 'Amount',
  DeltaPercent = 'DeltaPercent',
  InvalidComparison = 'InvalidComparison',
  Percent = 'Percent',
  Total = 'Total',
  TriPeriodDeltaPercent = 'TriPeriodDeltaPercent'
}

export enum ReportOptionTimelineInterval {
  InvalidInterval = 'InvalidInterval',
  Last3Months = 'Last3Months',
  Last6Months = 'Last6Months',
  Last12Months = 'Last12Months'
}

export type ReportPackage = {
  author?: Maybe<Contact>;
  authorOrganization?: Maybe<OrganizationPreview>;
  createdAt: Scalars['DateTime']['output'];
  documents: Array<ReportPackageDocumentDetails>;
  endedAt: Scalars['DateTime']['output'];
  entities: ObjectEntities;
  id: Scalars['String']['output'];
  interval: Interval;
  legalEntityOrganization?: Maybe<OrganizationPreview>;
  packageOptions?: Maybe<ReportPackageOptions>;
  startedAt: Scalars['DateTime']['output'];
  status: ReportPackageStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  version: ReportPackageVersionMetadata;
  versionId: Scalars['String']['output'];
  viewKey: ViewIdentifier;
};

export type ReportPackageDocument = LayoutDocument | Report | TextDocument;

export type ReportPackageDocumentDetails = {
  document?: Maybe<ReportPackageDocument>;
  metadata: ReportPackageDocumentMetadata;
};

export type ReportPackageDocumentMetadata = {
  documentObjectId: Scalars['String']['output'];
  documentObjectVersionId: Scalars['String']['output'];
  generationHistory?: Maybe<ReportGenerationHistory>;
  id: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  reportFile?: Maybe<ReportFileMetadata>;
  status: ReportPackageDocumentStatus;
  title: Scalars['String']['output'];
  type: ReportPackageDocumentType;
};

export enum ReportPackageDocumentStatus {
  Complete = 'Complete',
  Failed = 'Failed',
  Pending = 'Pending',
  UnknownStatus = 'UnknownStatus'
}

export enum ReportPackageDocumentType {
  Layout = 'Layout',
  Report = 'Report',
  Text = 'Text'
}

export type ReportPackageMetadata = {
  author?: Maybe<Contact>;
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creationMethod: CreationMethod;
  documents: Array<ReportPackageDocumentMetadata>;
  endedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  interval: Interval;
  publishedAsDraft: Scalars['Boolean']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: ReportPackageStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionId: Scalars['String']['output'];
  versions: Array<ReportPackageVersionMetadata>;
  viewKey: ViewIdentifier;
};


export type ReportPackageMetadataVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ReportPackageStatus>;
};

export type ReportPackageOptions = {
  accountNumbersDisplay?: Maybe<AccountNumbersDisplay>;
  coverLogoSource?: Maybe<CoverLogoSource>;
  coverUrl?: Maybe<Scalars['String']['output']>;
  customFooter?: Maybe<Scalars['String']['output']>;
  preparedBy?: Maybe<Scalars['String']['output']>;
  publishedAsDraft?: Maybe<Scalars['Boolean']['output']>;
};

export enum ReportPackageStatus {
  Draft = 'Draft',
  Published = 'Published',
  Transient = 'Transient',
  Unknown = 'Unknown'
}

/** Reports */
export type ReportPackageSummary = {
  draftCount: Scalars['Long']['output'];
  packagesCount: Scalars['Long']['output'];
  period: Period;
  publishedCount: Scalars['Long']['output'];
};

export type ReportPackageVersionMetadata = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  status: ReportPackageStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReportRefreshRequest = {
  requestId: Scalars['String']['output'];
};

export enum ReportSource {
  Digits = 'Digits',
  None = 'None',
  QuickBooks = 'QuickBooks'
}

export type ReportTemplate = {
  coverUrl: Scalars['String']['output'];
  hasBalanceSheet: Scalars['Boolean']['output'];
  hasCashFlow: Scalars['Boolean']['output'];
  hasExecutiveSummary: Scalars['Boolean']['output'];
  hasProfitAndLoss: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ReportValidationArchive = {
  avs?: Maybe<Scalars['String']['output']>;
  digits?: Maybe<Scalars['String']['output']>;
  quickbooks: Scalars['String']['output'];
};

export type ReportValidationHistory = {
  errorMessage?: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
  legalEntityId: Scalars['String']['output'];
  reportId: Scalars['String']['output'];
  source: ReportFileSource;
  validatedAt: Scalars['DateTime']['output'];
  versionId: Scalars['String']['output'];
};

export enum ReviewStatus {
  Approved = 'Approved',
  ChangesRequested = 'ChangesRequested',
  Commented = 'Commented',
  Dismissed = 'Dismissed',
  Pending = 'Pending'
}

export type RichComment = {
  comment: Comment;
  entities?: Maybe<ObjectEntities>;
  layout: Layout;
};

export type Role = {
  id: Scalars['String']['output'];
};

export type RoleIdentifier = {
  id: Scalars['String']['input'];
};

export type Runway = {
  cashOutDate?: Maybe<Scalars['DateTime']['output']>;
  current: DimensionSummary;
  future: DimensionSummary;
  previousCashOutDate?: Maybe<Scalars['DateTime']['output']>;
};

export type RunwayChartConfig = {
  netBurnLookback?: Maybe<ArchiveIntervalOrigin>;
  origin: ArchiveIntervalOrigin;
};

export type RunwayChartConfigInput = {
  netBurnLookback?: InputMaybe<IntervalOrigin>;
  origin: IntervalOrigin;
};

export enum SearchEntitiesField {
  Date = 'Date',
  DisplayNumber = 'DisplayNumber',
  Name = 'Name',
  Score = 'Score',
  Type = 'Type',
  Unspecified = 'Unspecified'
}

export type SearchEntitiesSort = {
  ascending: Scalars['Boolean']['input'];
  field: SearchEntitiesField;
};

/** Hit describes a single search result within a result set */
export type SearchHit = {
  /** The search-term highlight offsets within the result contents */
  highlights: Array<HitHighlight>;
  /** The object this search hit references */
  objectId: ObjectIdentifier;
  /** The bleve-assigned score of this result. Uses TDF-IF */
  score: Scalars['Float']['output'];
};

export type SearchInvoiceCustomersResult = {
  customers: Array<Customer>;
};

/**
 * Search
 * Result represents a customer-facing set of search results.
 */
export type SearchResult = {
  entities: ObjectEntities;
  kindCounts: Array<ObjectKindFacet>;
  results: Array<SearchHit>;
  tookSecs: Scalars['Float']['output'];
  total: Scalars['Long']['output'];
};

export type Segment = {
  collectionId: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Long']['output']>;
  sequence: Scalars['Long']['output'];
  token: Scalars['String']['output'];
};

export type SentInvoiceUsers = {
  invoice: Invoice;
  users?: Maybe<Array<User>>;
};

export enum ShareAction {
  CreateGrant = 'CreateGrant',
  IntentRevoked = 'IntentRevoked',
  Proceed = 'Proceed',
  RequestAccess = 'RequestAccess',
  RequestPending = 'RequestPending',
  Unknown = 'Unknown'
}

export type SharedReportDocumentMetadata = {
  documentObjectId: Scalars['String']['output'];
  documentObjectVersionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SignUpEvent = {
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
};

export type Signup = {
  companyCurrency?: Maybe<Scalars['String']['output']>;
  companyLedger?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  oAuthEmailAddress?: Maybe<Scalars['String']['output']>;
  oAuthService?: Maybe<Scalars['String']['output']>;
  persona: Persona;
  positionTitle?: Maybe<Scalars['String']['output']>;
  profileURL?: Maybe<Scalars['String']['output']>;
  status: SignupStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
};

export enum SignupStatus {
  Applied = 'Applied',
  Approved = 'Approved',
  Ignored = 'Ignored',
  InviteAccepted = 'InviteAccepted',
  Invited = 'Invited',
  PrimerSent = 'PrimerSent',
  Qualified = 'Qualified',
  Unknown = 'Unknown',
  Unqualified = 'Unqualified',
  Waitlisted = 'Waitlisted'
}

export type SimilarActionItem = {
  id: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export enum SimilarityType {
  Category = 'Category',
  Party = 'Party',
  Unknown = 'Unknown'
}

export enum SortDirection {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export type SourceAccount = {
  dataSource?: Maybe<EnrichedDataSource>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  externalOfficialName?: Maybe<Scalars['String']['output']>;
  externalOwnerName?: Maybe<Scalars['String']['output']>;
  externalSubtype?: Maybe<Scalars['String']['output']>;
  externalType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mask?: Maybe<Scalars['String']['output']>;
  ownerEmployeeId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SourceAccountStatus>;
};

export enum SourceAccountStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  UnknownStatus = 'UnknownStatus'
}

export type Statement = {
  kind: ReportKind;
  rows: Array<StatementRow>;
};

export type StatementBalances = {
  closingBalance: MoneyFlow;
  creditTotal?: Maybe<MoneyFlow>;
  debitTotal?: Maybe<MoneyFlow>;
  netTotal: MoneyFlow;
  openingBalance: MoneyFlow;
};

export type StatementConfig = {
  kind: ReportKind;
  options?: Maybe<ReportDocumentOptions>;
  origin: ArchiveIntervalOrigin;
};

export type StatementConfigInput = {
  kind: ReportKind;
  options?: InputMaybe<ReportDocumentOptionsInput>;
  origin: IntervalOrigin;
};

export type StatementDeltaValue = {
  moneyFlow: MoneyFlow;
  percentageOfAmount: Scalars['Float']['output'];
};

export type StatementDeltas = {
  percentageIncome?: Maybe<StatementDeltaValue>;
  percentageTotal?: Maybe<StatementDeltaValue>;
  previousPeriod?: Maybe<StatementDeltaValue>;
  previousYear?: Maybe<StatementDeltaValue>;
};

export type StatementDetails = {
  hover: Hover;
  insight?: Maybe<Insight>;
};

export type StatementRow = {
  deltas?: Maybe<StatementDeltas>;
  depth: Scalars['Int']['output'];
  details?: Maybe<StatementDetails>;
  leafCategorySummary?: Maybe<StatementRowSummary>;
  parentCategorySummary?: Maybe<StatementRowSummary>;
  parentCategoryTitle?: Maybe<StatementRowTitle>;
  rowId: Scalars['String']['output'];
  sectionSummary?: Maybe<StatementRowSummary>;
  sectionTitle?: Maybe<StatementRowTitle>;
  ytdDetails?: Maybe<StatementDetails>;
};

export type StatementRowSummary = {
  displayNumber?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  moneyFlow: MoneyFlow;
};

export type StatementRowTitle = {
  displayNumber?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type StatementSummary = {
  summaries: Array<Scalars['String']['output']>;
};

export type StaticMetricConfig = {
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type StaticMetricConfigInput = {
  name: Scalars['String']['input'];
  updatedAt: Scalars['DateTime']['input'];
  value: Scalars['String']['input'];
};

export type StripeConnectorProperties = {
  appLinkURL: Scalars['String']['output'];
};

export type Subscription = {
  askAssistant: AssistantEvent;
  notificationCreated: WebNotification;
};


export type SubscriptionAskAssistantArgs = {
  messages: Array<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['String']['input']>;
  viewKey: ViewKey;
};

export type SubscriptionDetails = {
  billingCycleEnd: Scalars['DateTime']['output'];
  billingCycleStart: Scalars['DateTime']['output'];
  cancelAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: BillingSubscriptionStatus;
};

export type SuggestedConnection = {
  template: ConnectionTemplate;
};

export type SuggestedConnectionsFilter = {
  institutionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SummarizeInput = {
  direction?: InputMaybe<DirectionFromOrigin>;
  filter: SummaryFilterInput;
  origin?: InputMaybe<IntervalOrigin>;
};

export enum SummaryDimension {
  Category = 'Category',
  CategoryType = 'CategoryType',
  Department = 'Department',
  Location = 'Location',
  Party = 'Party',
  Time = 'Time'
}

export type SummaryFilterInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categorySubtypes?: InputMaybe<Array<CategorySubtype>>;
  categoryType?: InputMaybe<CategoryType>;
  categoryTypes?: InputMaybe<Array<CategoryType>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  institutionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  maxValue?: InputMaybe<InputMonetaryValue>;
  minValue?: InputMaybe<InputMonetaryValue>;
  missingParty?: InputMaybe<MissingParty>;
  ownerEmployeeId?: InputMaybe<Scalars['String']['input']>;
  partyEntityTypes?: InputMaybe<Array<PartyEntityType>>;
  partyId?: InputMaybe<Scalars['String']['input']>;
  partyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  partyName?: InputMaybe<Scalars['String']['input']>;
  partyRole?: InputMaybe<PartyRole>;
  partyRoles?: InputMaybe<Array<PartyRole>>;
  productArea?: InputMaybe<ProductArea>;
  reconciliationFilter?: InputMaybe<ReconciliationFilter>;
  taxStatuses?: InputMaybe<Array<TaxStatus>>;
  taxpayerIdentifications?: InputMaybe<Array<TaxpayerIdentification>>;
  useAbsAmount?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  viewKey: ViewKey;
  withoutCategoryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutDepartmentAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutLocationAncestors?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SummaryLineItem {
  GrossProfit = 'GrossProfit',
  NetIncome = 'NetIncome',
  NetOperatingIncome = 'NetOperatingIncome',
  NetOtherIncome = 'NetOtherIncome',
  TotalCash = 'TotalCash'
}

export type SummaryLineItemConfig = {
  item: SummaryLineItem;
  origin: ArchiveIntervalOrigin;
};

export type SummaryLineItemConfigInput = {
  item: SummaryLineItem;
  origin: IntervalOrigin;
};

export type SummaryLineItemHover = {
  history: DimensionSummary;
  lineType: SummaryLineItem;
};

export type SummaryTotal = {
  count: Scalars['Long']['output'];
  total: MoneyFlow;
};

export enum TaxForm {
  Corporation = 'Corporation',
  LimitedLiability = 'LimitedLiability',
  NonprofitOrganization = 'NonprofitOrganization',
  Other = 'Other',
  PartnershipOrLimitedLiability = 'PartnershipOrLimitedLiability',
  SmallBusinessCorporation = 'SmallBusinessCorporation',
  SoleProprietor = 'SoleProprietor',
  UnknownTaxForm = 'UnknownTaxForm'
}

export enum TaxStatus {
  NonTaxable = 'NonTaxable',
  Taxable = 'Taxable',
  Undetermined = 'Undetermined',
  UnspecifiedTaxStatus = 'UnspecifiedTaxStatus'
}

export enum TaxpayerIdentification {
  Available = 'Available',
  NotRequired = 'NotRequired',
  Required = 'Required',
  Undetermined = 'Undetermined',
  UnknownTaxpayerIdentification = 'UnknownTaxpayerIdentification'
}

export type TaxpayerIdentificationCount = {
  partyCount: Scalars['Int']['output'];
  taxpayerIdentification: TaxpayerIdentification;
};

export type TaxpayerIdentificationInput = {
  partyId: Scalars['String']['input'];
  taxpayerIdentification: TaxpayerIdentification;
};

export type Team = {
  description?: Maybe<Scalars['String']['output']>;
  iconObjectName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invites: Array<TeamMemberInvite>;
  members: Array<TeamMember>;
  name: Scalars['String']['output'];
};

export type TeamAcl = {
  entities: ObjectEntities;
  grants: Array<ObjectGrant>;
};

export type TeamMember = {
  role: TeamRole;
  user: EntityUser;
};

export type TeamMemberInvite = {
  emailAddress: Scalars['String']['output'];
  role: TeamRole;
};

export enum TeamRole {
  Manager = 'Manager',
  Member = 'Member'
}

export type TextComponentConfig = {
  body: Scalars['String']['output'];
  current?: Maybe<ArchiveIntervalOrigin>;
  lookback?: Maybe<ArchiveIntervalOrigin>;
  tags?: Maybe<Array<TextComponentConfigTag>>;
};

export type TextComponentConfigInput = {
  body: Scalars['String']['input'];
  current?: InputMaybe<IntervalOrigin>;
  lookback?: InputMaybe<IntervalOrigin>;
  tags?: InputMaybe<Array<TextComponentConfigTagInput>>;
};

export type TextComponentConfigTag = {
  displayText: Scalars['String']['output'];
  objectId: ObjectIdentifier;
  options?: Maybe<TextTagOptions>;
};

export type TextComponentConfigTagInput = {
  displayText: Scalars['String']['input'];
  objectId: InputObjectIdentifier;
  options?: InputMaybe<TextTagOptionsInput>;
};

export type TextDocument = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  status: ReportPackageDocumentStatus;
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TextFeatureValue = {
  textValue: Scalars['String']['output'];
};

export type TextTagOptions = {
  partyRole?: Maybe<PartyRole>;
};

export type TextTagOptionsInput = {
  partyRole?: InputMaybe<Scalars['String']['input']>;
};

/** Thread contains the ThreadDetails and associated Comments. */
export type Thread = {
  comments: Array<Comment>;
  details: ThreadDetails;
  id: Scalars['String']['output'];
};

/**
 * ThreadDetails represents metadata about a thread.
 * This type is intended to be read-only and resolved
 * by the backend.
 */
export type ThreadDetails = {
  authorId: Scalars['String']['output'];
  commentCount: Scalars['Long']['output'];
  commenterIds: Array<Scalars['String']['output']>;
  context?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  tags: Array<ObjectIdentifier>;
  targetObject: ObjectIdentifier;
};

export type ThreadList = {
  targetObject: ObjectIdentifier;
  threads: Array<ThreadDetails>;
};

export type ThreadListWithEntities = {
  entities?: Maybe<ObjectEntities>;
  threads: Array<Thread>;
};

export type ThreadWithEntities = {
  entities?: Maybe<ObjectEntities>;
  thread: Thread;
};

export type Threads = {
  entities?: Maybe<ObjectEntities>;
  threads: Array<ThreadList>;
};

export type TimeDimension = {
  by: DimensionSummary;
  summary: TransactionSummary;
  timestamp: Scalars['Float']['output'];
};

export enum TokenIdentityService {
  InSided = 'InSided'
}

export type TopEntities = {
  entities: DimensionSummary;
};

export type TopEntitiesConfig = {
  categoryType?: Maybe<CategoryType>;
  filteredBy?: Maybe<ObjectIdentifier>;
  kind: ObjectKind;
  limit: Scalars['Int']['output'];
  origin: ArchiveIntervalOrigin;
  partyRole?: Maybe<PartyRole>;
  sort: DimensionSortMode;
};

export type TopEntitiesConfigInput = {
  categoryType?: InputMaybe<CategoryType>;
  filteredBy?: InputMaybe<InputObjectIdentifier>;
  kind: ObjectKind;
  limit: Scalars['Int']['input'];
  origin: IntervalOrigin;
  partyRole?: InputMaybe<PartyRole>;
  sort: DimensionSortMode;
};

export type TopTransactions = {
  partialData: Scalars['Boolean']['output'];
  transactions: Array<EntityTransaction>;
};

export type TopTransactionsConfig = {
  categoryType?: Maybe<CategoryType>;
  filteredBy?: Maybe<ObjectIdentifier>;
  limit: Scalars['Int']['output'];
  origin: ArchiveIntervalOrigin;
  partyRole?: Maybe<PartyRole>;
};

/**
 * INPUTS
 *
 */
export type TopTransactionsConfigInput = {
  categoryType?: InputMaybe<CategoryType>;
  filteredBy?: InputMaybe<InputObjectIdentifier>;
  limit: Scalars['Int']['input'];
  origin: IntervalOrigin;
  partyRole?: InputMaybe<PartyRole>;
};

export type Transaction = {
  compileSource?: Maybe<CompileSource>;
  creditSources: Array<TransactionSource>;
  debitSources: Array<TransactionSource>;
  department?: Maybe<EntityDepartment>;
  description?: Maybe<Scalars['String']['output']>;
  digitsTransactionType?: Maybe<TransactionType>;
  displayCanonicalCategoryName?: Maybe<Scalars['String']['output']>;
  displayCategory: Category;
  displayCategorySource: ClassificationSource;
  /** display* fields for use when a single field is preferred over displaying both debit and credit. */
  displayEntry: DisplayEntry;
  displayInstitution?: Maybe<TransactionParty>;
  displayProductArea?: Maybe<ProductArea>;
  displayUncategorizedReason: UncategorizedReason;
  /** @deprecated Use moneyFlow */
  displayValue?: Maybe<MonetaryValue>;
  facilitatorParty?: Maybe<TransactionParty>;
  factId?: Maybe<Scalars['String']['output']>;
  location?: Maybe<EntityLocation>;
  moneyFlow: MoneyFlow;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<EntityEmployee>;
  ownerSource: ClassificationSource;
  party?: Maybe<TransactionParty>;
  partySource: ClassificationSource;
  product?: Maybe<Product>;
  providers: Array<Provider>;
  reconciliationStatus: TransactionReconciliationStatus;
  recordType: TransactionRecordType;
  recurrence?: Maybe<TransactionRecurrence>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  runningTotal?: Maybe<MoneyFlow>;
  sources: Array<TransactionSource>;
  splitCategory?: Maybe<Category>;
  splitInstitution?: Maybe<TransactionParty>;
  supersededFactIds: Array<Scalars['String']['output']>;
  taxStatus: TaxStatus;
  /** @deprecated unpopulated */
  threadDetails?: Maybe<Array<ThreadDetails>>;
  timestamp: Scalars['DateTime']['output'];
  transactionClassificationType?: Maybe<TransactionClassificationType>;
  viewId: Scalars['String']['output'];
};

export type TransactionCategorySuggestions = {
  categorySuggestions: Array<CategorySuggestion>;
  transactionFactId: Scalars['String']['output'];
};

export enum TransactionClassification {
  Credit = 'Credit',
  Debit = 'Debit'
}

export enum TransactionClassificationType {
  ACH = 'ACH',
  ATM = 'ATM',
  AccountsPayable = 'AccountsPayable',
  Charge = 'Charge',
  Check = 'Check',
  DebitCard = 'DebitCard',
  DirectDeposit = 'DirectDeposit',
  ExpenseReport = 'ExpenseReport',
  OnlineTransfer = 'OnlineTransfer',
  Transfer = 'Transfer',
  UnspecifiedTransactionClassificationType = 'UnspecifiedTransactionClassificationType',
  Wire = 'Wire'
}

export enum TransactionColumnName {
  AbsoluteAmount = 'AbsoluteAmount',
  Amount = 'Amount',
  Category = 'Category',
  Description = 'Description',
  FirstRecurredAt = 'FirstRecurredAt',
  Name = 'Name',
  OccurredAt = 'OccurredAt',
  Party = 'Party',
  ReconciliationStatus = 'ReconciliationStatus',
  RecurrenceInterval = 'RecurrenceInterval'
}

export type TransactionCounts = {
  reconciled: Scalars['Int']['output'];
  unsettled: Scalars['Int']['output'];
};

export type TransactionEntry = {
  amount: MonetaryValue;
  description: Scalars['String']['output'];
  externalLineId: Scalars['String']['output'];
  factId: Scalars['String']['output'];
};

export type TransactionEntryLine = {
  category: Category;
  description?: Maybe<Scalars['String']['output']>;
  highlighted: Scalars['Boolean']['output'];
  isCredit: Scalars['Boolean']['output'];
  lineId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  value: MonetaryValue;
};

export type TransactionFactEntryType = {
  entryType: EntryType;
  transactionFactId: Scalars['String']['input'];
};

export type TransactionFilter = {
  canonicalCategoryType?: InputMaybe<CanonicalCategoryType>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryName?: InputMaybe<Scalars['String']['input']>;
  categoryType?: InputMaybe<CategoryType>;
  categoryTypes?: InputMaybe<Array<CategoryType>>;
  classification?: InputMaybe<TransactionClassification>;
  dataSourceId?: InputMaybe<Scalars['String']['input']>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  doubleEntry?: InputMaybe<DoubleEntry>;
  filterTerm?: InputMaybe<Scalars['String']['input']>;
  handleNone?: InputMaybe<HandleNone>;
  institutionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceId?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<TransactionKind>;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  maxValue?: InputMaybe<InputMonetaryValue>;
  minValue?: InputMaybe<InputMonetaryValue>;
  missingParty?: InputMaybe<MissingParty>;
  occurredAfter?: InputMaybe<Scalars['Float']['input']>;
  occurredBefore?: InputMaybe<Scalars['Float']['input']>;
  ownerEmployeeId?: InputMaybe<Scalars['String']['input']>;
  partyEntityTypes?: InputMaybe<Array<PartyEntityType>>;
  partyId?: InputMaybe<Scalars['String']['input']>;
  partyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  partyName?: InputMaybe<Scalars['String']['input']>;
  partyRole?: InputMaybe<PartyRole>;
  partyRoles?: InputMaybe<Array<PartyRole>>;
  productArea?: InputMaybe<ProductArea>;
  reconciliation?: InputMaybe<Reconciliation>;
  reconciliationFilter?: InputMaybe<ReconciliationFilter>;
  recurrenceInterval?: InputMaybe<Interval>;
  recurrenceStatus?: InputMaybe<TransactionFilterRecurrenceStatus>;
  recurringThreadId?: InputMaybe<Scalars['String']['input']>;
  referenceTransactionId?: InputMaybe<Scalars['String']['input']>;
  sourceAccountId?: InputMaybe<Scalars['String']['input']>;
  taxStatuses?: InputMaybe<Array<TaxStatus>>;
  taxpayerIdentifications?: InputMaybe<Array<TaxpayerIdentification>>;
  useAbsAmount?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  viewKey: ViewKey;
  withoutCategoryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutDepartmentAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutLocationAncestors?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TransactionFilterRecurrenceStatus {
  AllRecurring = 'AllRecurring',
  Occurred = 'Occurred'
}

export type TransactionGroup = {
  category: Category;
  description?: Maybe<Scalars['String']['output']>;
  groupId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  party?: Maybe<TransactionParty>;
  timestamp: Scalars['DateTime']['output'];
  totalAmount: MoneyFlow;
  transactions: Array<Transaction>;
};

export enum TransactionKind {
  Deposit = 'Deposit',
  Expense = 'Expense',
  Invoice = 'Invoice',
  Journal = 'Journal',
  Payment = 'Payment',
  Transfer = 'Transfer'
}

export type TransactionLine = {
  category: EntityCategory;
  entry: TransactionEntry;
  entryType: EntryType;
  party?: Maybe<EntityParty>;
};

/** Transactions */
export type TransactionOrder = {
  transactionOrderPairs: Array<TransactionOrderPair>;
};

export type TransactionOrderPair = {
  columnName: TransactionColumnName;
  direction: SortDirection;
};

export type TransactionParty = {
  designation: PartyDesignation;
  iconObjectName?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  roles?: Maybe<Array<PartyRole>>;
};

export type TransactionReconciliation = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** creator/created and updater/updatedAt are null when it was auto-reconciled/auto-unsettled */
  creator?: Maybe<EntityUser>;
  status: TransactionReconciliationStatus;
  transaction: Transaction;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updater?: Maybe<EntityUser>;
};

export enum TransactionReconciliationStatus {
  Reconciled = 'Reconciled',
  Unknown = 'Unknown',
  Unsettled = 'Unsettled'
}

export enum TransactionRecordType {
  ExpectedTransaction = 'ExpectedTransaction',
  Journal = 'Journal',
  MissedTransaction = 'MissedTransaction',
  Transaction = 'Transaction',
  UnknownRecordType = 'UnknownRecordType',
  UnreconciledSourceTransaction = 'UnreconciledSourceTransaction'
}

export type TransactionRecurrence = {
  firstOccurredAt: Scalars['DateTime']['output'];
  interval: Interval;
  intervalMultiplier: Scalars['Long']['output'];
  isHidden: Scalars['Boolean']['output'];
  lastOccurredAt: Scalars['DateTime']['output'];
  recurrenceThreadId: Scalars['String']['output'];
  threadIndex: Scalars['Long']['output'];
  totalThreadCount: Scalars['Long']['output'];
};

export type TransactionReviewQuestion = {
  createdAt: Scalars['DateTime']['output'];
  question: Scalars['String']['output'];
};

export type TransactionReviewReminderMessage = {
  legalEntityId: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type TransactionReviewSchedule = {
  daysOfMonth?: Maybe<Array<DayOfMonth>>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
  enabled: Scalars['Boolean']['output'];
  legalEntityId: Scalars['String']['output'];
};

export type TransactionSource = {
  account?: Maybe<TransactionSourceAccount>;
  externalDisplayId?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  type: TransactionSourceType;
};

export type TransactionSourceAccount = {
  externalName?: Maybe<Scalars['String']['output']>;
  externalOfficialName?: Maybe<Scalars['String']['output']>;
  externalOwnerName?: Maybe<Scalars['String']['output']>;
  mask?: Maybe<Scalars['String']['output']>;
};

export enum TransactionSourceType {
  CSVImport = 'CSVImport',
  Institution = 'Institution',
  Unknown = 'Unknown',
  User = 'User'
}

export type TransactionSummary = {
  isPeriodBookClosed: Scalars['Boolean']['output'];
  period: Period;
  total: PeriodMonetaryValue;
};

/** Transaction Editing */
export enum TransactionType {
  BankTransfer = 'BankTransfer',
  JournalEntry = 'JournalEntry',
  PayIn = 'PayIn',
  PayOut = 'PayOut',
  Unknown = 'Unknown'
}

export type TransactionViewDetail = {
  creditAmount?: Maybe<MonetaryValue>;
  creditCategoryHierarchy: Array<Category>;
  debitAmount?: Maybe<MonetaryValue>;
  /** First node is the top most parent of the hierarchy */
  debitCategoryHierarchy: Array<Category>;
  description?: Maybe<Scalars['String']['output']>;
  /** First node is the top most parent of the hierarchy */
  entryLines: Array<TransactionEntryLine>;
  externalCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  externalDisplayId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  factId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  institution: Institution;
  name?: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['DateTime']['output'];
  party?: Maybe<TransactionParty>;
  quickbooksAppName?: Maybe<Scalars['String']['output']>;
  recordType: TransactionRecordType;
  sourceAccount?: Maybe<SourceAccount>;
};

export type TransactionViewDetails = {
  details: Array<TransactionViewDetail>;
};

/** Transaction Details */
export type TransactionWithDetails = {
  details: Array<TransactionViewDetail>;
  isInBooksClosedPeriod?: Maybe<Scalars['Boolean']['output']>;
  transaction: Transaction;
};

export type TrialBalance = {
  rows: Array<TrialBalanceRow>;
};

export type TrialBalanceRow = {
  credits: MonetaryValue;
  debits: MonetaryValue;
  name: Scalars['String']['output'];
  rowId: Scalars['String']['output'];
};

export enum UncategorizedReason {
  New = 'New',
  None = 'None',
  Split = 'Split'
}

export enum UpdatableEmployeeField {
  AvatarUrl = 'AvatarUrl',
  EmailAddress = 'EmailAddress',
  FamilyName = 'FamilyName',
  GivenName = 'GivenName',
  Hidden = 'Hidden',
  JobTitle = 'JobTitle',
  Role = 'Role'
}

export type UpdateAuditRule = {
  amountFilters?: InputMaybe<Array<InputAmountRuleFilter>>;
  auditRuleId: Scalars['String']['input'];
  auditRuleName: Scalars['String']['input'];
  categoryFilters?: InputMaybe<Array<InputCategoryRuleFilter>>;
  deletedAmountFilters?: InputMaybe<Array<DeletedAmountFilter>>;
  deletedCategories?: InputMaybe<Array<DeletedCategory>>;
  deletedCategoryFilters?: InputMaybe<Array<DeletedCategoryFilter>>;
  deletedPartyFilterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedPartyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  partyFilters?: InputMaybe<Array<InputPartyRuleFilter>>;
  ruleState: AuditRuleState;
};

export type UpdateCategoryInput = {
  categoryId: Scalars['String']['input'];
  chartOfAccountsDisplayNumber: Scalars['String']['input'];
  description: Scalars['String']['input'];
  displayKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentCategoryId: Scalars['String']['input'];
  subtype: CategorySubtype;
  type: CategoryType;
};

export type UploadBankFeedResponse = {
  transactionFactIds: Array<Scalars['String']['output']>;
};

export type UploadJournalEntriesResponse = {
  transactionFactIds: Array<Scalars['String']['output']>;
};

export type User = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  employments?: Maybe<Array<OrganizationEmployment>>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  identities?: Maybe<Array<Identity>>;
  lastActivityAt: Scalars['DateTime']['output'];
  passwordSet: Scalars['Boolean']['output'];
  primaryEmailAddress: Scalars['String']['output'];
};

export type UserAcl = {
  entities: ObjectEntities;
  grants: Array<ObjectGrant>;
  intents: Array<ObjectIntent>;
  requests: Array<ObjectRequest>;
};

export type UserAssignee = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserContact = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserGrant = {
  grantId: Scalars['String']['output'];
  permission: Permission;
  userId: Scalars['String']['output'];
};

export type UserInfo = {
  emailAddress: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
};

export type UserIntent = {
  emailAddress: Scalars['String']['output'];
  intentId: Scalars['String']['output'];
  permission: Permission;
};

export type UserRequest = {
  requestId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** Vanity Stats */
export type VanityStats = {
  totalCalculations: Scalars['Long']['output'];
  totalParties: Scalars['Long']['output'];
  totalPartiesIdentified: Scalars['Long']['output'];
  totalTransactions: Scalars['Long']['output'];
  totalTransactionsEnhanced: Scalars['Long']['output'];
  totalTransactionsValue: MonetaryValue;
};

export enum VerificationRequestDestination {
  AIAccounting = 'AIAccounting',
  Bill = 'Bill',
  InSidedSSO = 'InSidedSSO',
  Share = 'Share',
  Unspecified = 'Unspecified'
}

export type ViewIdentifier = {
  legalEntityId: Scalars['String']['output'];
  mutationVersion?: Maybe<Scalars['String']['output']>;
  viewType: ViewType;
  viewVersion: Scalars['String']['output'];
};

export type ViewIdentifierInput = {
  legalEntityId: Scalars['String']['input'];
  mutationVersion?: InputMaybe<Scalars['String']['input']>;
  viewType: ViewType;
  viewVersion: Scalars['String']['input'];
};

export type ViewInvoiceSummary = {
  invoice: PublicInvoice;
  invoicePaymentDetails?: Maybe<InvoicePaymentDetails>;
};

export type ViewKey = {
  legalEntityId: Scalars['String']['input'];
  mutationVersion?: InputMaybe<Scalars['String']['input']>;
  viewType?: InputMaybe<ViewType>;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};

export type ViewMetadata = {
  firstTransactionTimestamp: Scalars['DateTime']['output'];
  legalEntityId: Scalars['String']['output'];
  viewType?: Maybe<ViewType>;
  viewVersion?: Maybe<Scalars['String']['output']>;
};

export enum ViewType {
  AIBookkeeper = 'AIBookkeeper',
  Ledger = 'Ledger',
  Unknown = 'Unknown'
}

/** Notifications */
export type WebNotification = {
  assetLink?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayKey: Scalars['String']['output'];
  eventType: EventType;
  id: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: WebNotificationStatus;
  targetAction: Scalars['String']['output'];
};

export enum WebNotificationStatus {
  DelayedDelivered = 'DelayedDelivered',
  Dismissed = 'Dismissed',
  Enqueued = 'Enqueued',
  Read = 'Read',
  Received = 'Received',
  UnknownStatus = 'UnknownStatus'
}

export type WorkItem = {
  actionable: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  generatedQuestions: Array<WorkItemGeneratedQuestion>;
  itemState: WorkItemState;
  itemType: WorkItemType;
  ledgerType: LedgerType;
  legalEntityId: Scalars['String']['output'];
  pushes: Array<WorkItemPush>;
  review?: Maybe<WorkItemReview>;
  reviewState?: Maybe<WorkItemReviewState>;
  time: Scalars['DateTime']['output'];
  transactions: Array<WorkItemTransaction>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** TODO: Hydrate user. */
  updatedBy?: Maybe<Scalars['String']['output']>;
  workItemId: Scalars['String']['output'];
};

export type WorkItemBadgeCountFilter = {
  itemStates?: InputMaybe<Array<WorkItemState>>;
  itemTypes?: InputMaybe<Array<WorkItemType>>;
  ledgerType?: InputMaybe<LedgerType>;
  legalEntityIds: Array<Scalars['String']['input']>;
  reviewStatesFilter?: InputMaybe<WorkItemReviewStatesFilter>;
};

export enum WorkItemErrorType {
  DuplicateVendor = 'DuplicateVendor',
  NoError = 'NoError',
  NotSupported = 'NotSupported',
  Other = 'Other',
  PeriodClosed = 'PeriodClosed'
}

export type WorkItemFilter = {
  excludedReviewStates?: InputMaybe<Array<WorkItemReviewState>>;
  itemStates?: InputMaybe<Array<WorkItemState>>;
  ledgerType?: InputMaybe<LedgerType>;
  legalEntityId: Scalars['String']['input'];
  reviewStates?: InputMaybe<Array<WorkItemReviewState>>;
  types?: InputMaybe<Array<WorkItemType>>;
};

export type WorkItemGeneratedQuestion = {
  active: Scalars['Boolean']['output'];
  contextType: WorkItemGeneratedQuestionContextType;
  question: Scalars['String']['output'];
};

export enum WorkItemGeneratedQuestionContextType {
  AccountantSpecific = 'AccountantSpecific',
  Direct = 'Direct',
  Friendly = 'Friendly'
}

export type WorkItemPush = {
  errorType?: Maybe<WorkItemErrorType>;
  field: WorkItemPushFieldType;
  pushState: WorkItemPushState;
  updatedAt: Scalars['DateTime']['output'];
};

export enum WorkItemPushFieldType {
  Category = 'Category',
  LineDescription = 'LineDescription',
  Memo = 'Memo',
  Party = 'Party',
  UnknownFieldType = 'UnknownFieldType'
}

export type WorkItemPushParams = {
  appendCommentsToQBO?: InputMaybe<Scalars['Boolean']['input']>;
  categoryParams?: InputMaybe<CategoryParams>;
  partyParams?: InputMaybe<PartyParams>;
  skipClose?: InputMaybe<Scalars['Boolean']['input']>;
  workItemId: Scalars['String']['input'];
};

export enum WorkItemPushState {
  Error = 'Error',
  Ignored = 'Ignored',
  Pushed = 'Pushed',
  UnknownState = 'UnknownState'
}

export type WorkItemResponse = {
  responseText?: InputMaybe<Scalars['String']['input']>;
  workItemId: Scalars['String']['input'];
};

export type WorkItemReview = {
  createdAt: Scalars['DateTime']['output'];
  creator: EntityUser;
  subject: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum WorkItemReviewState {
  ClientResponded = 'ClientResponded',
  PendingClientResponse = 'PendingClientResponse'
}

export type WorkItemReviewStatesFilter = {
  includeNullReviewState?: InputMaybe<Scalars['Boolean']['input']>;
  reviewStates?: InputMaybe<Array<WorkItemReviewState>>;
};

export enum WorkItemState {
  Accepted = 'Accepted',
  Active = 'Active',
  Completed = 'Completed',
  Invalidated = 'Invalidated',
  UnknownState = 'UnknownState'
}

export type WorkItemSubject = {
  saveAsTemplate: Scalars['Boolean']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  workItemId: Scalars['String']['input'];
};

export type WorkItemSummary = {
  count: Scalars['Long']['output'];
  period: Period;
};

export enum WorkItemSupportType {
  Context = 'Context',
  From = 'From',
  Object = 'Object',
  ObjectHighlight = 'ObjectHighlight',
  ResponseBasedPrediction = 'ResponseBasedPrediction',
  To = 'To',
  UnknownSupportType = 'UnknownSupportType',
  UserProvided = 'UserProvided'
}

export type WorkItemTransaction = {
  creditValue?: Maybe<MonetaryValue>;
  dataSourceId?: Maybe<Scalars['String']['output']>;
  debitValue?: Maybe<MonetaryValue>;
  description?: Maybe<Scalars['String']['output']>;
  displayCategory?: Maybe<EntityCategory>;
  displayEntry: DisplayEntry;
  displayPartyRole: PartyRole;
  /** @deprecated Use moneyFlow instead. */
  displayValue?: Maybe<MonetaryValue>;
  externalTransactionId: Scalars['String']['output'];
  externalTransactionLineId?: Maybe<Scalars['String']['output']>;
  ledgerTransactionId?: Maybe<Scalars['String']['output']>;
  moneyFlow?: Maybe<MoneyFlow>;
  name?: Maybe<Scalars['String']['output']>;
  occurredAt?: Maybe<Scalars['DateTime']['output']>;
  party?: Maybe<EntityParty>;
  quickbooksAppName?: Maybe<Scalars['String']['output']>;
  splitCategory?: Maybe<EntityCategory>;
  supportType: WorkItemSupportType;
};

export enum WorkItemType {
  HighConfidenceCategory = 'HighConfidenceCategory',
  HighConfidenceVendor = 'HighConfidenceVendor',
  InParentCategory = 'InParentCategory',
  PII = 'PII',
  UnassignedCategory = 'UnassignedCategory',
  UnassignedVendor = 'UnassignedVendor',
  UnknownType = 'UnknownType'
}

export type UpdateSourceAccountCategoryMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
  sourceAccountId: Scalars['String']['input'];
  categoryId: Scalars['String']['input'];
}>;


export type UpdateSourceAccountCategoryMutation = Pick<Mutation, 'updateSourceAccountCategory'>;

export type TriggerQuickBooksManualRefreshMutationVariables = Exact<{
  dataSourceId: Scalars['String']['input'];
}>;


export type TriggerQuickBooksManualRefreshMutation = Pick<Mutation, 'triggerQuickBooksManualRefresh'>;

export type CreateHiddenPredictedDataSourceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
  predictedDataSourceType: PredictedDataSourceType;
}>;


export type CreateHiddenPredictedDataSourceMutation = Pick<Mutation, 'createHiddenPredictedDataSource'>;

export type UpdateSourceAccountOwnerMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
  sourceAccountId: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  employeeFullName: Scalars['String']['input'];
}>;


export type UpdateSourceAccountOwnerMutation = Pick<Mutation, 'updateSourceAccountOwner'>;

export type DeleteSourceAccountOwnerMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
  sourceAccountId: Scalars['String']['input'];
}>;


export type DeleteSourceAccountOwnerMutation = Pick<Mutation, 'deleteSourceAccountOwner'>;

export type DeleteDataSourceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
  providerType: ProviderType;
}>;


export type DeleteDataSourceMutation = Pick<Mutation, 'deleteDataSource'>;

export type CreateBooksClosedDateMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  booksClosedDate?: InputMaybe<Scalars['Float']['input']>;
}>;


export type CreateBooksClosedDateMutation = Pick<Mutation, 'createBooksClosedDate'>;

export type CreateQuickBooksDataSourceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  credential: OAuthCredentialFields;
}>;


export type CreateQuickBooksDataSourceMutation = { createQuickBooksDataSource: { dataSource?: Maybe<(
      Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
      & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
    )>, quickBooksCompany?: Maybe<{ preferences?: Maybe<{ accountingInfo?: Maybe<{ bookCloseDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }> }> }> } };

export type UpdateDataSourceStatusMutationVariables = Exact<{
  dataSourceId: Scalars['String']['input'];
  status: DataSourceStatus;
}>;


export type UpdateDataSourceStatusMutation = Pick<Mutation, 'updateDataSourceStatus'>;

export type QuickBooksPreferencesFieldsFragment = { accountingInfo?: Maybe<{ bookCloseDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }> };

export type QuickBooksCompanyFieldsFragment = { preferences?: Maybe<{ accountingInfo?: Maybe<{ bookCloseDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }> }> };

export type PlaidLinkTokenFieldsFragment = Pick<PlaidLinkToken, 'token'>;

export type QuickBooksAccountingInfoPreferencesFieldsFragment = { bookCloseDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> };

export type ReadBooksClosedDateQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadBooksClosedDateQuery = { readBooksClosedDate: (
    Pick<BooksClosedDate, 'legalEntityId' | 'latestBooksClosedDateFact' | 'viewBooksClosedDate'>
    & { user?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
  ) };

export type ListEnrichedDataSourcesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListEnrichedDataSourcesQuery = { listEnrichedDataSources: Array<(
    Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
    & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
  )>, listPredictedDataSources: Array<(
    Pick<PredictedDataSource, 'providerType' | 'type'>
    & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  )> };

export type ReadSourceAccountCategoryQueryVariables = Exact<{
  sourceAccountId: Scalars['String']['input'];
}>;


export type ReadSourceAccountCategoryQuery = { readSourceAccountCategory?: Maybe<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )> };

export type ReadRealtimeHealthStatusQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadRealtimeHealthStatusQuery = Pick<Query, 'readRealtimeHealthStatus'>;

export type ListPredictedDataSourcesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListPredictedDataSourcesQuery = { listPredictedDataSources: Array<(
    Pick<PredictedDataSource, 'providerType' | 'type'>
    & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  )> };

export type ReadDataSourcePreferencesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadDataSourcePreferencesQuery = { readDataSourcePreferences: Array<Pick<DataSourcePreference, 'dataSourceId' | 'accountingBasis' | 'taxForm' | 'providerType' | 'departmentTerminology'>> };

export type ReadDataSourceRefreshProgressQueryVariables = Exact<{
  dataSourceId: Scalars['String']['input'];
}>;


export type ReadDataSourceRefreshProgressQuery = { readRefreshProgress: Pick<RefreshProgress, 'mostRecentFetchStartTime' | 'progressPercentage'> };

export type ListDeletedDataSourcesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListDeletedDataSourcesQuery = { listDeletedDataSources: Array<(
    Pick<DataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt'>
    & { institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'> }
  )> };

export type ListOwnedDataSourcesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListOwnedDataSourcesQuery = { listOwnedDataSources: Array<{ dataSource: (
      Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
      & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
    ), ownedSourceAccounts?: Maybe<Array<{ sourceAccount: Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>, owner?: Maybe<(
        Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
        & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
          Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
          & { employments?: Maybe<Array<(
            Pick<OrganizationEmployment, 'employeeId'>
            & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
          )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
        )>, invitation?: Maybe<(
          Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
          & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
        )> }
      )> }>> }>, listPredictedDataSources: Array<(
    Pick<PredictedDataSource, 'providerType' | 'type'>
    & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  )> };

export type ListDataSourcesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListDataSourcesQuery = { listDataSources: Array<(
    Pick<DataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt'>
    & { institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'> }
  )> };

export type AssociateInstitutionsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  institutionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  source: AssociationSource;
}>;


export type AssociateInstitutionsMutation = Pick<Mutation, 'associateInstitutions'>;

export type ListInstitutionsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type ListInstitutionsQuery = { listInstitutions: Array<(
    Pick<ProductInstitution, 'id' | 'name' | 'products' | 'routingNumber'>
    & { party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
  )> };

export type AskAssistantSubscriptionVariables = Exact<{
  viewKey: ViewKey;
  threadId?: InputMaybe<Scalars['String']['input']>;
  messages: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AskAssistantSubscription = { askAssistant: (
    Pick<AssistantEvent, 'kind' | 'threadId' | 'suggestions'>
    & { comment: (
      { __typename: 'Comment' }
      & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
      & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
    ), layout?: Maybe<(
      Pick<Layout, 'layoutId' | 'layoutVersionId'>
      & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, sections?: Maybe<Array<(
        Pick<LayoutSection, 'sectionId' | 'title'>
        & { rows?: Maybe<Array<(
          Pick<LayoutRow, 'rowId' | 'title'>
          & { components?: Maybe<Array<(
            Pick<LayoutComponent, 'componentId' | 'title' | 'dataId'>
            & { config: (
              Pick<LayoutComponentConfig, 'type'>
              & { text?: Maybe<(
                Pick<TextComponentConfig, 'body'>
                & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
                  Pick<TextComponentConfigTag, 'displayText'>
                  & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
                )>> }
              )>, categoryChart?: Maybe<(
                Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, partyChart?: Maybe<(
                Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, topEntities?: Maybe<(
                Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
              )>, topTransactions?: Maybe<(
                Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
              )>, summaryLineItem?: Maybe<(
                Pick<SummaryLineItemConfig, 'item'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, portalMetric?: Maybe<(
                Pick<PortalMetricConfig, 'metricType'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, entitiesList?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
                  { __typename: 'ObjectEntities' }
                  & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
                    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                    & { moneyFlow: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                  )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
                    Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
                    & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
                  )>>, reportPackages?: Maybe<Array<(
                    Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
                    & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
                  )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
                    Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
                    & { objectTransaction: (
                      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                      & { moneyFlow: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                    ) }
                  )>>, invoices?: Maybe<Array<(
                    Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
                    & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
                      Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
                      & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
                    ) }
                  )>>, bills?: Maybe<Array<(
                    Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
                    & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
                      Pick<Format, 'id' | 'formatClass'>
                      & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
                    ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
                  )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
                    { __typename: 'EntityDepartment' }
                    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
                  )>>, locations?: Maybe<Array<(
                    { __typename: 'EntityLocation' }
                    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
                  )>> }
                ) }>, statement?: Maybe<(
                Pick<StatementConfig, 'kind'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
                  { __typename: 'ReportDocumentOptions' }
                  & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
                  & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
                )> }
              )>, metricChart?: Maybe<(
                Pick<MetricChartConfig, 'item'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, runwayChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, cashSummaryChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, staticMetric?: Maybe<Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>>, documentCollection?: Maybe<Pick<DocumentCollectionConfig, 'collectionId'>>, insight?: Maybe<{ objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, departmentChart?: Maybe<(
                Pick<DepartmentChartConfig, 'departmentId'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, locationChart?: Maybe<(
                Pick<LocationChartConfig, 'locationId'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )> }
            ) }
          )>> }
        )>> }
      )>> }
    )> }
  ) };

export type GenerateExecutiveSummaryQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
}>;


export type GenerateExecutiveSummaryQuery = Pick<Query, 'generateExecutiveSummary'>;

export type UpdateOrganizationPreferencesMutationVariables = Exact<{
  id: Scalars['String']['input'];
  anonymizeEmployees: Scalars['Boolean']['input'];
}>;


export type UpdateOrganizationPreferencesMutation = Pick<Mutation, 'updateOrganizationPreferences'>;

export type ImportOAuthEmployeesMutationVariables = Exact<{
  service: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type ImportOAuthEmployeesMutation = { importOAuthEmployees?: Maybe<Pick<ImportedEmployees, 'newEmployeeCount'>> };

export type UploadOrganizationIconMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  iconBase64: Scalars['String']['input'];
}>;


export type UploadOrganizationIconMutation = Pick<Mutation, 'uploadOrganizationIcon'>;

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateOrganizationMutation = Pick<Mutation, 'updateOrganization'>;

export type OrganizationAffiliationFieldsFragment = { legalEntity: Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>, organization: Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'> };

export type ReadOrganizationTemplateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadOrganizationTemplateQuery = { readOrganizationTemplate: Pick<OrganizationTemplate, 'id' | 'name' | 'iconUrl' | 'websiteUrl'> };

export type ListOrganizationAffiliationsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ListOrganizationAffiliationsQuery = { listOrganizationAffiliations: Array<{ legalEntity: Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>, organization: Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'> }> };

export type ReadOrganizationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadOrganizationQuery = { readOrganization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> };

export type ListOrganizationUsersQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ListOrganizationUsersQuery = { listOrganizationUsers: Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )> };

export type ListAllOrganizationUsersQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ListAllOrganizationUsersQuery = { listOrganizationUsers: Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )>, listOrganizationInvitations: Array<(
    Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
    & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
  )> };

export type ReadOrganizationPreferencesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadOrganizationPreferencesQuery = { readOrganizationPreferences: Pick<OrganizationPreferences, 'id' | 'anonymizeEmployees'> };

export type FactIdForLedgerTransactionIdQueryVariables = Exact<{
  ledgerTransactionId: Scalars['String']['input'];
  viewKey: ViewKey;
}>;


export type FactIdForLedgerTransactionIdQuery = Pick<Query, 'factIdForLedgerTransactionId'>;

export type ListInsightsQueryVariables = Exact<{
  interval: Interval;
  intervalCount?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
  index: Scalars['Int']['input'];
  paginationDirection: DirectionFromOrigin;
  paginationLimit: Scalars['Int']['input'];
  paginationOffset: Scalars['Int']['input'];
  filter: InsightFilter;
}>;


export type ListInsightsQuery = { listInsights: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, insights: Array<(
      Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
      & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    )>, entities?: Maybe<(
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    )> }> };

export type ListDoppelgangerPermitsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListDoppelgangerPermitsQuery = { listDoppelgangerPermits: Array<Maybe<(
    Pick<DoppelgangerPermit, 'id' | 'expiresAt' | 'createdAt'>
    & { user: (
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    ) }
  )>> };

export type CreateThreadMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  kind: ObjectKind;
  id: Scalars['String']['input'];
  comment: NewComment;
  context?: InputMaybe<Scalars['String']['input']>;
  viewKey?: InputMaybe<ViewKey>;
  assignee?: InputMaybe<Assignee>;
}>;


export type CreateThreadMutation = { thread: (
    { __typename: 'ThreadWithEntities' }
    & { thread: (
      { __typename: 'Thread' }
      & Pick<Thread, 'id'>
      & { details: (
        Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
        & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
      ), comments: Array<(
        { __typename: 'Comment' }
        & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
        & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
      )> }
    ), entities?: Maybe<(
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    )> }
  ) };

export type ResolveThreadMutationVariables = Exact<{
  threadId: Scalars['String']['input'];
  viewKey?: InputMaybe<ViewKey>;
}>;


export type ResolveThreadMutation = Pick<Mutation, 'resolveThread'>;

export type DeleteReactionMutationVariables = Exact<{
  threadId: Scalars['String']['input'];
  commentId: Scalars['String']['input'];
  reaction: CommentReaction;
}>;


export type DeleteReactionMutation = Pick<Mutation, 'deleteReaction'>;

export type CreateReactionMutationVariables = Exact<{
  threadId: Scalars['String']['input'];
  commentId: Scalars['String']['input'];
  reaction: CommentReaction;
}>;


export type CreateReactionMutation = Pick<Mutation, 'createReaction'>;

export type ReplyThreadMutationVariables = Exact<{
  threadId: Scalars['String']['input'];
  comment: NewComment;
  assignee?: InputMaybe<Assignee>;
  viewKey?: InputMaybe<ViewKey>;
}>;


export type ReplyThreadMutation = { comment: { comment: (
      { __typename: 'Comment' }
      & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
      & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
    ), entities?: Maybe<(
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    )> } };

export type ListThreadsQueryVariables = Exact<{
  idsByKind: IdentifiersByKind;
  viewType: ViewType;
}>;


export type ListThreadsQuery = { listThreads: { threads: Array<{ targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, threads: Array<(
        Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
        & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
      )> }>, entities?: Maybe<(
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    )> } };

export type FindThreadByCommentQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  commentId: Scalars['String']['input'];
}>;


export type FindThreadByCommentQuery = { thread?: Maybe<(
    Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
    & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
  )> };

export type ReadAssociatedThreadsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  kind: ObjectKind;
  allowResolved?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ReadAssociatedThreadsQuery = { response: { threads: Array<(
      { __typename: 'Thread' }
      & Pick<Thread, 'id'>
      & { details: (
        Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
        & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
      ), comments: Array<(
        { __typename: 'Comment' }
        & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
        & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
      )> }
    )>, entities?: Maybe<(
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    )> } };

export type ReadThreadQueryVariables = Exact<{
  id: Scalars['String']['input'];
  allowResolved?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ReadThreadQuery = { thread: (
    { __typename: 'ThreadWithEntities' }
    & { thread: (
      { __typename: 'Thread' }
      & Pick<Thread, 'id'>
      & { details: (
        Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
        & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
      ), comments: Array<(
        { __typename: 'Comment' }
        & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
        & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
      )> }
    ), entities?: Maybe<(
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    )> }
  ) };

export type UpdateActionItemAssignmentStatusMutationVariables = Exact<{
  actionItemId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  userAssignees: Array<UserAssignee> | UserAssignee;
  organizationAssignees: Array<OrganizationAssignee> | OrganizationAssignee;
}>;


export type UpdateActionItemAssignmentStatusMutation = Pick<Mutation, 'updateActionItemAssignmentStatus'>;

export type UpdateActionItemWatchStatusMutationVariables = Exact<{
  actionItemId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  watch: Scalars['Boolean']['input'];
}>;


export type UpdateActionItemWatchStatusMutation = Pick<Mutation, 'updateActionItemWatchStatus'>;

export type UpdateActionItemStatusMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  actionItems: Array<ActionItemStatusChange> | ActionItemStatusChange;
}>;


export type UpdateActionItemStatusMutation = Pick<Mutation, 'updateActionItemStatus'>;

export type ActionItemBillApprovalFieldsFragment = { bill: (
    Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
    & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
      Pick<Format, 'id' | 'formatClass'>
      & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
    ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
  ) };

export type ActionItemTransactionFieldsFragment = { transaction: (
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  ), categorySuggestions?: Maybe<Array<Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>>> };

export type ActionItemPublishedReportFieldsFragment = { package: (
    Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
    & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
  ) };

export type ActionItemsObjectKindSummaryFieldsFragment = Pick<ActionItemsObjectKindSummary, 'objectKind' | 'count'>;

export type ActionItemThreadFieldsFragment = (
  Pick<ActionItemThread, 'threadId'>
  & { targetEntity: (
    { __typename: 'EntityBill' }
    & Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
    & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
      Pick<Format, 'id' | 'formatClass'>
      & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
    ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
  ) | (
    { __typename: 'EntityCategory' }
    & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
  ) | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | (
    { __typename: 'EntityDepartment' }
    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
  ) | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | (
    { __typename: 'EntityLedgerTransaction' }
    & Pick<EntityLedgerTransaction, 'id' | 'timestamp' | 'factIds'>
  ) | { __typename: 'EntityLegalEntity' } | (
    { __typename: 'EntityLocation' }
    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
  ) | (
    { __typename: 'EntityParty' }
    & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
  ) | { __typename: 'EntityReport' } | (
    { __typename: 'EntityReportPackage' }
    & Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
    & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
  ) | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | (
    { __typename: 'EntityWorkItem' }
    & Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
    & { objectTransaction: (
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    ) }
  ) }
);

export type ActionItemSummaryFieldsFragment = (
  Pick<ActionItemSummary, 'count'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
);

export type ActionItemFieldsFragment = (
  Pick<ActionItem, 'id' | 'title' | 'createdAt' | 'updatedAt'>
  & { author?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, value: (
    { __typename: 'ActionItemBillApproval' }
    & { bill: (
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    ) }
  ) | (
    { __typename: 'ActionItemPublishedReport' }
    & { package: (
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    ) }
  ) | (
    { __typename: 'ActionItemThread' }
    & Pick<ActionItemThread, 'threadId'>
    & { targetEntity: (
      { __typename: 'EntityBill' }
      & Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    ) | (
      { __typename: 'EntityCategory' }
      & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
    ) | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | (
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    ) | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | (
      { __typename: 'EntityLedgerTransaction' }
      & Pick<EntityLedgerTransaction, 'id' | 'timestamp' | 'factIds'>
    ) | { __typename: 'EntityLegalEntity' } | (
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    ) | (
      { __typename: 'EntityParty' }
      & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
    ) | { __typename: 'EntityReport' } | (
      { __typename: 'EntityReportPackage' }
      & Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    ) | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | (
      { __typename: 'EntityWorkItem' }
      & Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    ) }
  ) | (
    { __typename: 'ActionItemTransaction' }
    & { transaction: (
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    ), categorySuggestions?: Maybe<Array<Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>>> }
  ) }
);

export type ListActionItemsQueryVariables = Exact<{
  filter: ActionItemFilter;
  pagination: Pagination;
  viewKey: ViewKey;
  origin?: InputMaybe<IntervalOrigin>;
}>;


export type ListActionItemsQuery = { listActionItems: Array<(
    Pick<ActionItem, 'id' | 'title' | 'createdAt' | 'updatedAt'>
    & { author?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, value: (
      { __typename: 'ActionItemBillApproval' }
      & { bill: (
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      ) }
    ) | (
      { __typename: 'ActionItemPublishedReport' }
      & { package: (
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      ) }
    ) | (
      { __typename: 'ActionItemThread' }
      & Pick<ActionItemThread, 'threadId'>
      & { targetEntity: (
        { __typename: 'EntityBill' }
        & Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      ) | (
        { __typename: 'EntityCategory' }
        & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
      ) | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | (
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      ) | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | (
        { __typename: 'EntityLedgerTransaction' }
        & Pick<EntityLedgerTransaction, 'id' | 'timestamp' | 'factIds'>
      ) | { __typename: 'EntityLegalEntity' } | (
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      ) | (
        { __typename: 'EntityParty' }
        & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
      ) | { __typename: 'EntityReport' } | (
        { __typename: 'EntityReportPackage' }
        & Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      ) | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | (
        { __typename: 'EntityWorkItem' }
        & Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      ) }
    ) | (
      { __typename: 'ActionItemTransaction' }
      & { transaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ), categorySuggestions?: Maybe<Array<Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>>> }
    ) }
  )> };

export type SummarizeActionItemsByTimeQueryVariables = Exact<{
  filter: ActionItemFilter;
  viewKey: ViewKey;
  origin: IntervalOrigin;
}>;


export type SummarizeActionItemsByTimeQuery = { summarizeActionItemsByTime: Array<(
    Pick<ActionItemSummary, 'count'>
    & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
  )> };

export type ListSimilarActionItemsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  items: Array<SimilarActionItem> | SimilarActionItem;
}>;


export type ListSimilarActionItemsQuery = Pick<Query, 'listSimilarActionItems'>;

export type SummarizeActionItemsByObjectKindQueryVariables = Exact<{
  filter: ActionItemFilter;
  legalEntityId: Scalars['String']['input'];
}>;


export type SummarizeActionItemsByObjectKindQuery = { summarizeActionItemsByObjectKind: Array<Pick<ActionItemsObjectKindSummary, 'objectKind' | 'count'>> };

export type ListActionItemsAndBadgeCountQueryVariables = Exact<{
  listFilter: ActionItemFilter;
  pagination: Pagination;
  viewKey: ViewKey;
  direction?: InputMaybe<DirectionFromOrigin>;
  badgeCountFilter: ActionItemFilter;
  legalEntityIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  legalEntities?: InputMaybe<Array<InputMaybe<LegalEntityPermission>> | InputMaybe<LegalEntityPermission>>;
  origin: IntervalOrigin;
}>;


export type ListActionItemsAndBadgeCountQuery = { listActionItems: Array<(
    Pick<ActionItem, 'id' | 'title' | 'createdAt' | 'updatedAt'>
    & { author?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, value: (
      { __typename: 'ActionItemBillApproval' }
      & { bill: (
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      ) }
    ) | (
      { __typename: 'ActionItemPublishedReport' }
      & { package: (
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      ) }
    ) | (
      { __typename: 'ActionItemThread' }
      & Pick<ActionItemThread, 'threadId'>
      & { targetEntity: (
        { __typename: 'EntityBill' }
        & Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      ) | (
        { __typename: 'EntityCategory' }
        & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
      ) | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | (
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      ) | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | (
        { __typename: 'EntityLedgerTransaction' }
        & Pick<EntityLedgerTransaction, 'id' | 'timestamp' | 'factIds'>
      ) | { __typename: 'EntityLegalEntity' } | (
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      ) | (
        { __typename: 'EntityParty' }
        & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
      ) | { __typename: 'EntityReport' } | (
        { __typename: 'EntityReportPackage' }
        & Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      ) | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | (
        { __typename: 'EntityWorkItem' }
        & Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      ) }
    ) | (
      { __typename: 'ActionItemTransaction' }
      & { transaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ), categorySuggestions?: Maybe<Array<Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>>> }
    ) }
  )>, listActionItemsBadgeCounts: Array<Pick<LegalEntityBadgeCount, 'legalEntityId' | 'count'>> };

export type DeleteDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
  documentCollectionId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type DeleteDocumentMutation = Pick<Mutation, 'deleteDocument'>;

export type UpdatePortalMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  portalId: Scalars['String']['input'];
  backgroundUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdatePortalMutation = Pick<Mutation, 'updatePortal'>;

export type CreatePortalMutationVariables = Exact<{
  viewId: ViewIdentifierInput;
}>;


export type CreatePortalMutation = { createPortal: Pick<Portal, 'portalId'> };

export type ReadPortalLayoutQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadPortalLayoutQuery = { readPortalLayout: (
    Pick<PortalLayout, 'hasPortal' | 'portalId'>
    & { layout?: Maybe<(
      Pick<Layout, 'layoutId' | 'layoutVersionId'>
      & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, sections?: Maybe<Array<(
        Pick<LayoutSection, 'sectionId' | 'title'>
        & { rows?: Maybe<Array<(
          Pick<LayoutRow, 'rowId' | 'title'>
          & { components?: Maybe<Array<(
            Pick<LayoutComponent, 'componentId' | 'title' | 'dataId'>
            & { config: (
              Pick<LayoutComponentConfig, 'type'>
              & { text?: Maybe<(
                Pick<TextComponentConfig, 'body'>
                & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
                  Pick<TextComponentConfigTag, 'displayText'>
                  & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
                )>> }
              )>, categoryChart?: Maybe<(
                Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, partyChart?: Maybe<(
                Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, topEntities?: Maybe<(
                Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
              )>, topTransactions?: Maybe<(
                Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
              )>, summaryLineItem?: Maybe<(
                Pick<SummaryLineItemConfig, 'item'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, portalMetric?: Maybe<(
                Pick<PortalMetricConfig, 'metricType'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, entitiesList?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
                  { __typename: 'ObjectEntities' }
                  & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
                    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                    & { moneyFlow: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                  )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
                    Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
                    & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
                  )>>, reportPackages?: Maybe<Array<(
                    Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
                    & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
                  )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
                    Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
                    & { objectTransaction: (
                      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                      & { moneyFlow: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                    ) }
                  )>>, invoices?: Maybe<Array<(
                    Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
                    & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
                      Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
                      & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
                    ) }
                  )>>, bills?: Maybe<Array<(
                    Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
                    & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
                      Pick<Format, 'id' | 'formatClass'>
                      & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
                    ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
                  )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
                    { __typename: 'EntityDepartment' }
                    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
                  )>>, locations?: Maybe<Array<(
                    { __typename: 'EntityLocation' }
                    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
                  )>> }
                ) }>, statement?: Maybe<(
                Pick<StatementConfig, 'kind'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
                  { __typename: 'ReportDocumentOptions' }
                  & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
                  & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
                )> }
              )>, metricChart?: Maybe<(
                Pick<MetricChartConfig, 'item'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, runwayChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, cashSummaryChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, staticMetric?: Maybe<Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>>, documentCollection?: Maybe<Pick<DocumentCollectionConfig, 'collectionId'>>, insight?: Maybe<{ objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, departmentChart?: Maybe<(
                Pick<DepartmentChartConfig, 'departmentId'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )>, locationChart?: Maybe<(
                Pick<LocationChartConfig, 'locationId'>
                & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
              )> }
            ) }
          )>> }
        )>> }
      )>> }
    )> }
  ) };

export type VanityStatsFieldsFragment = (
  Pick<VanityStats, 'totalParties' | 'totalPartiesIdentified' | 'totalCalculations' | 'totalTransactions' | 'totalTransactionsEnhanced'>
  & { totalTransactionsValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type ReadVanityStatsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadVanityStatsQuery = { readVanityStats: (
    Pick<VanityStats, 'totalParties' | 'totalPartiesIdentified' | 'totalCalculations' | 'totalTransactions' | 'totalTransactionsEnhanced'>
    & { totalTransactionsValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) };

export type UpdateTransactionReconciliationStatusesMutationVariables = Exact<{
  viewKey: ViewKey;
  statusUpdates: Array<InputStatusUpdate> | InputStatusUpdate;
}>;


export type UpdateTransactionReconciliationStatusesMutation = Pick<Mutation, 'updateTransactionReconciliationStatuses'>;

export type CreateAccountReconciliationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
  reconciliation: InputAccountReconciliation;
}>;


export type CreateAccountReconciliationMutation = Pick<Mutation, 'createAccountReconciliation'>;

export type FinalizeAccountReconciliationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
  viewKey: ViewKey;
}>;


export type FinalizeAccountReconciliationMutation = Pick<Mutation, 'finalizeAccountReconciliation'>;

export type ReopenAccountReconciliationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
}>;


export type ReopenAccountReconciliationMutation = Pick<Mutation, 'reopenAccountReconciliation'>;

export type DeleteAccountReconciliationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
}>;


export type DeleteAccountReconciliationMutation = Pick<Mutation, 'deleteAccountReconciliation'>;

export type UpdateAccountReconciliationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
  viewKey: ViewKey;
  update: InputAccountReconciliationUpdate;
}>;


export type UpdateAccountReconciliationMutation = Pick<Mutation, 'updateAccountReconciliation'>;

export type StatementBalancesFieldsFragment = { debitTotal?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, creditTotal?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, netTotal: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), openingBalance: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), closingBalance: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) };

export type ReconciliationPeriodStatsFieldsFragment = Pick<ReconciliationPeriodStats, 'notStarted' | 'noActivity' | 'drafts' | 'finalized' | 'totalCategories'>;

export type LedgerBalancesFieldsFragment = { debitTotal: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), creditTotal: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), netTotal: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), unsettledTotal: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), closingBalance: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) };

export type TransactionCountsFieldsFragment = Pick<TransactionCounts, 'reconciled' | 'unsettled'>;

export type LedgerAccountDifferencesFieldsFragment = { debitDifference?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, creditDifference?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, unreconciledDifference: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) };

export type ReconciliationBalancesFieldsFragment = { ledgerBalances: { debitTotal: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), creditTotal: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), netTotal: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), unsettledTotal: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), closingBalance: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }, statementBalances: { debitTotal?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, creditTotal?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, netTotal: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), openingBalance: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), closingBalance: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }, ledgerAccountDifferences: { debitDifference?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, creditDifference?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, unreconciledDifference: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) } };

export type AccountReconciliationFieldsFragment = (
  Pick<AccountReconciliation, 'chartOfAccountsId' | 'categoryId' | 'reconciliationId' | 'status' | 'createdAt' | 'updatedAt'>
  & { category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, startDate: Pick<Date, 'year' | 'month' | 'day'>, endDate: Pick<Date, 'year' | 'month' | 'day'>, updater?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
);

export type ListAccountReconciliationsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
  origin: IntervalOrigin;
  direction: DirectionFromOrigin;
  filter?: InputMaybe<InputAccountReconciliationsFilter>;
}>;


export type ListAccountReconciliationsQuery = { listAccountReconciliations: Array<{ account: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, reconciliations: Array<(
      Pick<AccountReconciliation, 'chartOfAccountsId' | 'categoryId' | 'reconciliationId' | 'status' | 'createdAt' | 'updatedAt'>
      & { category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, startDate: Pick<Date, 'year' | 'month' | 'day'>, endDate: Pick<Date, 'year' | 'month' | 'day'>, updater?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
    )> }> };

export type ReadReconciliationPeriodStatsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
  origin: IntervalOrigin;
  direction: DirectionFromOrigin;
}>;


export type ReadReconciliationPeriodStatsQuery = { readReconciliationPeriodStats: Pick<ReconciliationPeriodStats, 'notStarted' | 'noActivity' | 'drafts' | 'finalized' | 'totalCategories'> };

export type ReadAccountReconciliationQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
  viewKey: ViewKey;
}>;


export type ReadAccountReconciliationQuery = { readAccountReconciliation?: Maybe<(
    Pick<ReconciliationDetails, 'normalEntryType'>
    & { reconciliation: (
      Pick<AccountReconciliation, 'chartOfAccountsId' | 'categoryId' | 'reconciliationId' | 'status' | 'createdAt' | 'updatedAt'>
      & { category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, startDate: Pick<Date, 'year' | 'month' | 'day'>, endDate: Pick<Date, 'year' | 'month' | 'day'>, updater?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
    ), balances: { ledgerBalances: { debitTotal: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), creditTotal: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), netTotal: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), unsettledTotal: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), closingBalance: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }, statementBalances: { debitTotal?: Maybe<(
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        )>, creditTotal?: Maybe<(
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        )>, netTotal: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), openingBalance: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), closingBalance: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }, ledgerAccountDifferences: { debitDifference?: Maybe<(
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        )>, creditDifference?: Maybe<(
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        )>, unreconciledDifference: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) } }, transactionCounts: Pick<TransactionCounts, 'reconciled' | 'unsettled'> }
  )> };

export type UpdateCompanyFinancialsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  financials?: InputMaybe<Array<CompanyFinancialsInput> | CompanyFinancialsInput>;
}>;


export type UpdateCompanyFinancialsMutation = Pick<Mutation, 'updateCompanyFinancials'>;

export type SendQuotePackageMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type SendQuotePackageMutation = Pick<Mutation, 'sendQuotePackage'>;

export type CreateQuotePackageMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  recipient?: InputMaybe<ContactInput>;
  companyDetails: CompanyDetailsInput;
}>;


export type CreateQuotePackageMutation = { createQuotePackage: Pick<QuotePackageId, 'id'> };

export type UpdateQuotePackageMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  recipient?: InputMaybe<ContactInput>;
  contractDocumentId?: InputMaybe<Scalars['String']['input']>;
  companyDetails: CompanyDetailsInput;
}>;


export type UpdateQuotePackageMutation = Pick<Mutation, 'updateQuotePackage'>;

export type CompanyFinancialValueFieldsFragment = Pick<CompanyFinancialValue, 'name' | 'institutionId'>;

export type QuotePackageFieldsFragment = (
  Pick<QuotePackage, 'id' | 'contractDocumentId' | 'status'>
  & { recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, companyDetails: (
    Pick<CompanyDetails, 'categories' | 'companyName' | 'websiteURL' | 'companySize' | 'incorporationState' | 'entityType' | 'subsidiaryCount' | 'foreignSubsidiaryCount' | 'incorporationYear' | 'lastTaxFiling'>
    & { address?: Maybe<Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>> }
  ), financials?: Maybe<Array<(
    Pick<CompanyFinancials, 'product'>
    & { values: Array<Pick<CompanyFinancialValue, 'name' | 'institutionId'>> }
  )>> }
);

export type CompanyDetailsFieldsFragment = (
  Pick<CompanyDetails, 'categories' | 'companyName' | 'websiteURL' | 'companySize' | 'incorporationState' | 'entityType' | 'subsidiaryCount' | 'foreignSubsidiaryCount' | 'incorporationYear' | 'lastTaxFiling'>
  & { address?: Maybe<Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>> }
);

export type CompanyFinancialsFieldsFragment = (
  Pick<CompanyFinancials, 'product'>
  & { values: Array<Pick<CompanyFinancialValue, 'name' | 'institutionId'>> }
);

export type QuotePackageIdFieldsFragment = Pick<QuotePackageId, 'id'>;

export type ListQuotePackagesByLegalEntityQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListQuotePackagesByLegalEntityQuery = { listQuotePackagesByLegalEntity?: Maybe<Array<(
    Pick<QuotePackage, 'id' | 'contractDocumentId' | 'status'>
    & { recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, companyDetails: (
      Pick<CompanyDetails, 'categories' | 'companyName' | 'websiteURL' | 'companySize' | 'incorporationState' | 'entityType' | 'subsidiaryCount' | 'foreignSubsidiaryCount' | 'incorporationYear' | 'lastTaxFiling'>
      & { address?: Maybe<Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>> }
    ), financials?: Maybe<Array<(
      Pick<CompanyFinancials, 'product'>
      & { values: Array<Pick<CompanyFinancialValue, 'name' | 'institutionId'>> }
    )>> }
  )>> };

export type ReadQuotePackageQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type ReadQuotePackageQuery = { readQuotePackage: (
    Pick<QuotePackage, 'id' | 'contractDocumentId' | 'status'>
    & { recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, companyDetails: (
      Pick<CompanyDetails, 'categories' | 'companyName' | 'websiteURL' | 'companySize' | 'incorporationState' | 'entityType' | 'subsidiaryCount' | 'foreignSubsidiaryCount' | 'incorporationYear' | 'lastTaxFiling'>
      & { address?: Maybe<Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>> }
    ), financials?: Maybe<Array<(
      Pick<CompanyFinancials, 'product'>
      & { values: Array<Pick<CompanyFinancialValue, 'name' | 'institutionId'>> }
    )>> }
  ) };

export type RequestObjectAccessMutationVariables = Exact<{
  object: InputObjectIdentifier;
  intentId?: InputMaybe<Scalars['String']['input']>;
  constraint?: InputMaybe<AccessConstraint>;
}>;


export type RequestObjectAccessMutation = Pick<Mutation, 'requestObjectAccess'>;

export type UpdateObjectLinkMutationVariables = Exact<{
  identifier: InputObjectIdentifier;
  permission: Permission;
}>;


export type UpdateObjectLinkMutation = Pick<Mutation, 'updateObjectLink'>;

export type DisableObjectLinkMutationVariables = Exact<{
  identifier: InputObjectIdentifier;
}>;


export type DisableObjectLinkMutation = Pick<Mutation, 'disableObjectLink'>;

export type RejectObjectAccessRequestMutationVariables = Exact<{
  requestId: Scalars['String']['input'];
}>;


export type RejectObjectAccessRequestMutation = Pick<Mutation, 'rejectObjectAccessRequest'>;

export type GrantFromShareMutationVariables = Exact<{
  object: InputObjectIdentifier;
  intentId?: InputMaybe<Scalars['String']['input']>;
  constraint?: InputMaybe<AccessConstraint>;
}>;


export type GrantFromShareMutation = Pick<Mutation, 'grantFromShare'>;

export type CreateIntentMutationVariables = Exact<{
  identifier: InputObjectIdentifier;
  permissions: Array<NewUserGrant> | NewUserGrant;
  message: Scalars['String']['input'];
  sendNotification: Scalars['Boolean']['input'];
  constraint?: InputMaybe<AccessConstraint>;
  viewKey?: InputMaybe<ViewKey>;
}>;


export type CreateIntentMutation = Pick<Mutation, 'createIntent'>;

export type UpdateIntentMutationVariables = Exact<{
  intentId: Scalars['String']['input'];
  identifier: InputObjectIdentifier;
  permission: Permission;
  constraint?: InputMaybe<AccessConstraint>;
}>;


export type UpdateIntentMutation = Pick<Mutation, 'updateIntent'>;

export type EnableObjectLinkMutationVariables = Exact<{
  identifier: InputObjectIdentifier;
  permission: Permission;
  viewType?: InputMaybe<ViewType>;
}>;


export type EnableObjectLinkMutation = Pick<Mutation, 'enableObjectLink'>;

export type ApproveObjectAccessRequestMutationVariables = Exact<{
  requestId: Scalars['String']['input'];
  permission: Permission;
  constraint?: InputMaybe<AccessConstraint>;
}>;


export type ApproveObjectAccessRequestMutation = Pick<Mutation, 'approveObjectAccessRequest'>;

export type RevokeGrantMutationVariables = Exact<{
  grantId: Scalars['String']['input'];
  identifier: InputObjectIdentifier;
  constraint?: InputMaybe<AccessConstraint>;
}>;


export type RevokeGrantMutation = Pick<Mutation, 'revokeGrant'>;

export type RevokeIntentMutationVariables = Exact<{
  intentId: Scalars['String']['input'];
  identifier: InputObjectIdentifier;
  constraint?: InputMaybe<AccessConstraint>;
}>;


export type RevokeIntentMutation = Pick<Mutation, 'revokeIntent'>;

export type UpdateGrantMutationVariables = Exact<{
  grantId: Scalars['String']['input'];
  identifier: InputObjectIdentifier;
  permission: Permission;
  constraint?: InputMaybe<AccessConstraint>;
}>;


export type UpdateGrantMutation = Pick<Mutation, 'updateGrant'>;

export type ObjectAclFieldsFragment = (
  Pick<ObjectAcl, 'link'>
  & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, grants: Array<Pick<UserGrant, 'grantId' | 'userId' | 'permission'>>, intents: Array<Pick<UserIntent, 'intentId' | 'emailAddress' | 'permission'>>, requests: Array<Pick<UserRequest, 'requestId' | 'userId'>>, entities: (
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  ) }
);

export type UserGrantFieldsFragment = Pick<UserGrant, 'grantId' | 'userId' | 'permission'>;

export type UserIntentFieldsFragment = Pick<UserIntent, 'intentId' | 'emailAddress' | 'permission'>;

export type UserRequestFieldsFragment = Pick<UserRequest, 'requestId' | 'userId'>;

export type ObjectGrantFieldsFragment = (
  Pick<ObjectGrant, 'grantId' | 'permission' | 'createdAt' | 'viewType'>
  & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
);

export type UserAclFieldsFragment = { grants: Array<(
    Pick<ObjectGrant, 'grantId' | 'permission' | 'createdAt' | 'viewType'>
    & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
  )>, entities: (
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  ) };

export type ListGranteesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListGranteesQuery = { listGrantees: Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )> };

export type ReadTeamAclQueryVariables = Exact<{
  teamId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadTeamAclQuery = { readTeamACL: { grants: Array<(
      Pick<ObjectGrant, 'grantId' | 'permission' | 'createdAt' | 'viewType'>
      & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
    )>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ) } };

export type TeamAclFieldsFragment = { grants: Array<(
    Pick<ObjectGrant, 'grantId' | 'permission' | 'createdAt' | 'viewType'>
    & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
  )>, entities: (
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  ) };

export type ListObjectAcLsQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
}>;


export type ListObjectAcLsQuery = { listObjectACLs: Array<(
    Pick<ObjectAcl, 'link'>
    & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, grants: Array<Pick<UserGrant, 'grantId' | 'userId' | 'permission'>>, intents: Array<Pick<UserIntent, 'intentId' | 'emailAddress' | 'permission'>>, requests: Array<Pick<UserRequest, 'requestId' | 'userId'>>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ) }
  )> };

export type ReadUserAclQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadUserAclQuery = { readUserACL: { grants: Array<(
      Pick<ObjectGrant, 'grantId' | 'permission' | 'createdAt' | 'viewType'>
      & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
    )>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ) } };

export type ReadShareAdjacencyQueryVariables = Exact<{
  id: Scalars['String']['input'];
  kind: ObjectKind;
  legalEntitySlug: Scalars['String']['input'];
}>;


export type ReadShareAdjacencyQuery = { readShareAdjacency: (
    Pick<AdjacentObjectPermission, 'viewType'>
    & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, legalEntity: Pick<EntityLegalEntity, 'id' | 'slug' | 'name'>, organizationPreview: Pick<OrganizationPreview, 'id' | 'slug' | 'name'> }
  ) };

export type ReadShareQueryVariables = Exact<{
  id: Scalars['String']['input'];
  kind: ObjectKind;
  intentId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReadShareQuery = { readShare: (
    Pick<ObjectPermission, 'permission' | 'action' | 'viewType'>
    & { object: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, legalEntity: Pick<EntityLegalEntity, 'id' | 'slug' | 'name'>, organizationPreview: Pick<OrganizationPreview, 'id' | 'slug' | 'name'> }
  ) };

export type ReadLocationQueryVariables = Exact<{
  viewKey: ViewKey;
  locationId: Scalars['String']['input'];
}>;


export type ReadLocationQuery = { readLocation: (
    Pick<Location, 'id' | 'name' | 'parentId' | 'active'>
    & { ancestors: Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )> }
  ) };

export type ListLocationsQueryVariables = Exact<{
  viewKey: ViewKey;
}>;


export type ListLocationsQuery = { listLocations: Array<(
    Pick<Location, 'id' | 'name' | 'parentId' | 'active'>
    & { ancestors: Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )> }
  )> };

export type ArchiveComponentDataMutationVariables = Exact<{
  layoutId: Scalars['String']['input'];
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ArchiveComponentDataMutation = Pick<Mutation, 'archiveComponentData'>;

export type RearchiveLayoutDataMutationVariables = Exact<{
  layoutId: Scalars['String']['input'];
  viewId: ViewIdentifierInput;
}>;


export type RearchiveLayoutDataMutation = { rearchiveLayoutData: (
    Pick<Layout, 'layoutId' | 'layoutVersionId'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, sections?: Maybe<Array<(
      Pick<LayoutSection, 'sectionId' | 'title'>
      & { rows?: Maybe<Array<(
        Pick<LayoutRow, 'rowId' | 'title'>
        & { components?: Maybe<Array<(
          Pick<LayoutComponent, 'componentId' | 'title' | 'dataId'>
          & { config: (
            Pick<LayoutComponentConfig, 'type'>
            & { text?: Maybe<(
              Pick<TextComponentConfig, 'body'>
              & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
                Pick<TextComponentConfigTag, 'displayText'>
                & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
              )>> }
            )>, categoryChart?: Maybe<(
              Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, partyChart?: Maybe<(
              Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, topEntities?: Maybe<(
              Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
            )>, topTransactions?: Maybe<(
              Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
            )>, summaryLineItem?: Maybe<(
              Pick<SummaryLineItemConfig, 'item'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, portalMetric?: Maybe<(
              Pick<PortalMetricConfig, 'metricType'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, entitiesList?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
                { __typename: 'ObjectEntities' }
                & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
                  Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                  & { moneyFlow: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
                  Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
                  & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
                )>>, reportPackages?: Maybe<Array<(
                  Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
                  & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
                )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
                  Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
                  & { objectTransaction: (
                    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                    & { moneyFlow: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                  ) }
                )>>, invoices?: Maybe<Array<(
                  Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
                  & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
                    Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
                    & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
                  ) }
                )>>, bills?: Maybe<Array<(
                  Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
                  & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
                    Pick<Format, 'id' | 'formatClass'>
                    & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
                  ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
                )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
                  { __typename: 'EntityDepartment' }
                  & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
                )>>, locations?: Maybe<Array<(
                  { __typename: 'EntityLocation' }
                  & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
                )>> }
              ) }>, statement?: Maybe<(
              Pick<StatementConfig, 'kind'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
                { __typename: 'ReportDocumentOptions' }
                & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
                & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
              )> }
            )>, metricChart?: Maybe<(
              Pick<MetricChartConfig, 'item'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, runwayChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, cashSummaryChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, staticMetric?: Maybe<Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>>, documentCollection?: Maybe<Pick<DocumentCollectionConfig, 'collectionId'>>, insight?: Maybe<{ objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, departmentChart?: Maybe<(
              Pick<DepartmentChartConfig, 'departmentId'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, locationChart?: Maybe<(
              Pick<LocationChartConfig, 'locationId'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )> }
          ) }
        )>> }
      )>> }
    )>> }
  ) };

export type UpdateLayoutMutationVariables = Exact<{
  viewKey?: InputMaybe<ViewKey>;
  layout: LayoutInput;
}>;


export type UpdateLayoutMutation = { updateLayout: (
    { __typename: 'Layout' }
    & Pick<Layout, 'layoutId' | 'layoutVersionId'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, sections?: Maybe<Array<(
      Pick<LayoutSection, 'sectionId' | 'title'>
      & { rows?: Maybe<Array<(
        Pick<LayoutRow, 'rowId' | 'title'>
        & { components?: Maybe<Array<(
          Pick<LayoutComponent, 'componentId' | 'title' | 'dataId'>
          & { config: (
            Pick<LayoutComponentConfig, 'type'>
            & { text?: Maybe<(
              Pick<TextComponentConfig, 'body'>
              & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
                Pick<TextComponentConfigTag, 'displayText'>
                & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
              )>> }
            )>, categoryChart?: Maybe<(
              Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, partyChart?: Maybe<(
              Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, topEntities?: Maybe<(
              Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
            )>, topTransactions?: Maybe<(
              Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
            )>, summaryLineItem?: Maybe<(
              Pick<SummaryLineItemConfig, 'item'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, portalMetric?: Maybe<(
              Pick<PortalMetricConfig, 'metricType'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, entitiesList?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
                { __typename: 'ObjectEntities' }
                & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
                  Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                  & { moneyFlow: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
                  Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
                  & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
                )>>, reportPackages?: Maybe<Array<(
                  Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
                  & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
                )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
                  Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
                  & { objectTransaction: (
                    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                    & { moneyFlow: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                  ) }
                )>>, invoices?: Maybe<Array<(
                  Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
                  & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
                    Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
                    & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
                  ) }
                )>>, bills?: Maybe<Array<(
                  Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
                  & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
                    Pick<Format, 'id' | 'formatClass'>
                    & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
                  ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
                )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
                  { __typename: 'EntityDepartment' }
                  & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
                )>>, locations?: Maybe<Array<(
                  { __typename: 'EntityLocation' }
                  & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
                )>> }
              ) }>, statement?: Maybe<(
              Pick<StatementConfig, 'kind'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
                { __typename: 'ReportDocumentOptions' }
                & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
                & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
              )> }
            )>, metricChart?: Maybe<(
              Pick<MetricChartConfig, 'item'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, runwayChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, cashSummaryChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, staticMetric?: Maybe<Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>>, documentCollection?: Maybe<Pick<DocumentCollectionConfig, 'collectionId'>>, insight?: Maybe<{ objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, departmentChart?: Maybe<(
              Pick<DepartmentChartConfig, 'departmentId'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, locationChart?: Maybe<(
              Pick<LocationChartConfig, 'locationId'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )> }
          ) }
        )>> }
      )>> }
    )>> }
  ) };

export type UpdateReportLayoutMutationVariables = Exact<{
  viewKey: ViewKey;
  packageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  layout: LayoutInput;
}>;


export type UpdateReportLayoutMutation = { updateReportLayout: (
    { __typename: 'Layout' }
    & Pick<Layout, 'layoutId' | 'layoutVersionId'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, sections?: Maybe<Array<(
      Pick<LayoutSection, 'sectionId' | 'title'>
      & { rows?: Maybe<Array<(
        Pick<LayoutRow, 'rowId' | 'title'>
        & { components?: Maybe<Array<(
          Pick<LayoutComponent, 'componentId' | 'title' | 'dataId'>
          & { config: (
            Pick<LayoutComponentConfig, 'type'>
            & { text?: Maybe<(
              Pick<TextComponentConfig, 'body'>
              & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
                Pick<TextComponentConfigTag, 'displayText'>
                & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
              )>> }
            )>, categoryChart?: Maybe<(
              Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, partyChart?: Maybe<(
              Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, topEntities?: Maybe<(
              Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
            )>, topTransactions?: Maybe<(
              Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
            )>, summaryLineItem?: Maybe<(
              Pick<SummaryLineItemConfig, 'item'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, portalMetric?: Maybe<(
              Pick<PortalMetricConfig, 'metricType'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, entitiesList?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
                { __typename: 'ObjectEntities' }
                & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
                  Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                  & { moneyFlow: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
                  Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
                  & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
                )>>, reportPackages?: Maybe<Array<(
                  Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
                  & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
                )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
                  Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
                  & { objectTransaction: (
                    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                    & { moneyFlow: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                  ) }
                )>>, invoices?: Maybe<Array<(
                  Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
                  & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
                    Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
                    & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
                  ) }
                )>>, bills?: Maybe<Array<(
                  Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
                  & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
                    Pick<Format, 'id' | 'formatClass'>
                    & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
                  ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
                )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
                  { __typename: 'EntityDepartment' }
                  & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
                )>>, locations?: Maybe<Array<(
                  { __typename: 'EntityLocation' }
                  & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
                )>> }
              ) }>, statement?: Maybe<(
              Pick<StatementConfig, 'kind'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
                { __typename: 'ReportDocumentOptions' }
                & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
                & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
              )> }
            )>, metricChart?: Maybe<(
              Pick<MetricChartConfig, 'item'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, runwayChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, cashSummaryChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, staticMetric?: Maybe<Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>>, documentCollection?: Maybe<Pick<DocumentCollectionConfig, 'collectionId'>>, insight?: Maybe<{ objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, departmentChart?: Maybe<(
              Pick<DepartmentChartConfig, 'departmentId'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, locationChart?: Maybe<(
              Pick<LocationChartConfig, 'locationId'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )> }
          ) }
        )>> }
      )>> }
    )>> }
  ) };

export type PortalMetricConfigFieldsFragment = (
  Pick<PortalMetricConfig, 'metricType'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
);

export type TextTagOptionsFieldsFragment = Pick<TextTagOptions, 'partyRole'>;

export type CashSummaryFieldsFragment = { cashTotal: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ), cashChange: (
    Pick<DimensionSummary, 'partialData'>
    & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }
  ) };

export type RunwayChartConfigFieldsFragment = { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> };

export type SummaryLineItemHoverFieldsFragment = (
  { __typename: 'SummaryLineItemHover' }
  & Pick<SummaryLineItemHover, 'lineType'>
  & { history: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) }
);

export type SummaryLineItemConfigFieldsFragment = (
  Pick<SummaryLineItemConfig, 'item'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
);

export type PartyHoverFieldsFragment = (
  { __typename: 'PartyHover' }
  & { entity: (
    { __typename: 'EntityParty' }
    & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
  ), history: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ), context?: Maybe<(
    Pick<DimensionSummary, 'partialData'>
    & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  )>, topTransactions?: Maybe<Array<(
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  )>> }
);

export type CashSummaryChartConfigFieldsFragment = { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> };

export type TextComponentConfigFieldsFragment = (
  Pick<TextComponentConfig, 'body'>
  & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
    Pick<TextComponentConfigTag, 'displayText'>
    & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
  )>> }
);

export type LocationSummaryFieldsFragment = { location: (
    { __typename: 'EntityLocation' }
    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
  ), total: { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }, expensesChange: { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }, otherExpensesChange: { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }, incomeChange: { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }, otherIncomeChange: { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }, cogsChange: { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> } };

export type PartyChartConfigFieldsFragment = (
  Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
);

export type RunwayFieldsFragment = (
  Pick<Runway, 'cashOutDate' | 'previousCashOutDate'>
  & { current: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ), future: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) }
);

export type DepartmentChartConfigFieldsFragment = (
  Pick<DepartmentChartConfig, 'departmentId'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
);

export type LayoutDocumentFieldsFragment = Pick<LayoutDocument, 'id' | 'title' | 'layoutId' | 'status' | 'createdAt' | 'updatedAt'>;

export type TopTransactionsConfigFieldsFragment = (
  Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
);

export type EntitiesListConfigFieldsFragment = { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  ) };

export type CategoryChartConfigFieldsFragment = (
  Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
);

export type LocationChartConfigFieldsFragment = (
  Pick<LocationChartConfig, 'locationId'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
);

export type MetricChartConfigFieldsFragment = (
  Pick<MetricChartConfig, 'item'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
);

export type StaticMetricConfigFieldsFragment = Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>;

export type InsightComponentConfigFieldsFragment = { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> };

export type TopEntitiesConfigFieldsFragment = (
  Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
);

export type StatementConfigFieldsFragment = (
  Pick<StatementConfig, 'kind'>
  & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
    { __typename: 'ReportDocumentOptions' }
    & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
    & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
  )> }
);

export type CategoryHoverFieldsFragment = (
  { __typename: 'CategoryHover' }
  & Pick<CategoryHover, 'balance'>
  & { entity: (
    { __typename: 'EntityCategory' }
    & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
  ), history: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ), context?: Maybe<(
    Pick<DimensionSummary, 'partialData'>
    & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  )>, topTransactions?: Maybe<Array<(
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  )>> }
);

export type LayoutFieldsFragment = (
  Pick<Layout, 'layoutId' | 'layoutVersionId'>
  & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, sections?: Maybe<Array<(
    Pick<LayoutSection, 'sectionId' | 'title'>
    & { rows?: Maybe<Array<(
      Pick<LayoutRow, 'rowId' | 'title'>
      & { components?: Maybe<Array<(
        Pick<LayoutComponent, 'componentId' | 'title' | 'dataId'>
        & { config: (
          Pick<LayoutComponentConfig, 'type'>
          & { text?: Maybe<(
            Pick<TextComponentConfig, 'body'>
            & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
              Pick<TextComponentConfigTag, 'displayText'>
              & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
            )>> }
          )>, categoryChart?: Maybe<(
            Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
          )>, partyChart?: Maybe<(
            Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
          )>, topEntities?: Maybe<(
            Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
          )>, topTransactions?: Maybe<(
            Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
          )>, summaryLineItem?: Maybe<(
            Pick<SummaryLineItemConfig, 'item'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
          )>, portalMetric?: Maybe<(
            Pick<PortalMetricConfig, 'metricType'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
          )>, entitiesList?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
              { __typename: 'ObjectEntities' }
              & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
                Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                & { moneyFlow: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
              )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
                Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
                & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
              )>>, reportPackages?: Maybe<Array<(
                Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
                & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
              )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
                Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
                & { objectTransaction: (
                  Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                  & { moneyFlow: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                ) }
              )>>, invoices?: Maybe<Array<(
                Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
                & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
                  Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
                  & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
                ) }
              )>>, bills?: Maybe<Array<(
                Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
                & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
                  Pick<Format, 'id' | 'formatClass'>
                  & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
                ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
              )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
                { __typename: 'EntityDepartment' }
                & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
              )>>, locations?: Maybe<Array<(
                { __typename: 'EntityLocation' }
                & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
              )>> }
            ) }>, statement?: Maybe<(
            Pick<StatementConfig, 'kind'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
              { __typename: 'ReportDocumentOptions' }
              & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
              & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
            )> }
          )>, metricChart?: Maybe<(
            Pick<MetricChartConfig, 'item'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
          )>, runwayChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, cashSummaryChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, staticMetric?: Maybe<Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>>, documentCollection?: Maybe<Pick<DocumentCollectionConfig, 'collectionId'>>, insight?: Maybe<{ objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, departmentChart?: Maybe<(
            Pick<DepartmentChartConfig, 'departmentId'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
          )>, locationChart?: Maybe<(
            Pick<LocationChartConfig, 'locationId'>
            & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
          )> }
        ) }
      )>> }
    )>> }
  )>> }
);

export type LayoutComponentConfigFieldsFragment = (
  Pick<LayoutComponentConfig, 'type'>
  & { text?: Maybe<(
    Pick<TextComponentConfig, 'body'>
    & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
      Pick<TextComponentConfigTag, 'displayText'>
      & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
    )>> }
  )>, categoryChart?: Maybe<(
    Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
  )>, partyChart?: Maybe<(
    Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
  )>, topEntities?: Maybe<(
    Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
  )>, topTransactions?: Maybe<(
    Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
  )>, summaryLineItem?: Maybe<(
    Pick<SummaryLineItemConfig, 'item'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
  )>, portalMetric?: Maybe<(
    Pick<PortalMetricConfig, 'metricType'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
  )>, entitiesList?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ) }>, statement?: Maybe<(
    Pick<StatementConfig, 'kind'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
      { __typename: 'ReportDocumentOptions' }
      & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
      & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
    )> }
  )>, metricChart?: Maybe<(
    Pick<MetricChartConfig, 'item'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
  )>, runwayChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, cashSummaryChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, staticMetric?: Maybe<Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>>, documentCollection?: Maybe<Pick<DocumentCollectionConfig, 'collectionId'>>, insight?: Maybe<{ objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, departmentChart?: Maybe<(
    Pick<DepartmentChartConfig, 'departmentId'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
  )>, locationChart?: Maybe<(
    Pick<LocationChartConfig, 'locationId'>
    & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
  )> }
);

export type ReadPartySummaryByTimeLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadPartySummaryByTimeLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | (
    { __typename: 'Chart' }
    & { entity: { __typename: 'EntityBill' } | { __typename: 'EntityCategory' } | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | { __typename: 'EntityDepartment' } | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | { __typename: 'EntityLedgerTransaction' } | { __typename: 'EntityLegalEntity' } | { __typename: 'EntityLocation' } | (
      { __typename: 'EntityParty' }
      & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
    ) | { __typename: 'EntityReport' } | { __typename: 'EntityReportPackage' } | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | { __typename: 'EntityWorkItem' }, summary: (
      Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ) }
  ) | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadPortalMetricItemByTimeLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadPortalMetricItemByTimeLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | (
    { __typename: 'DimensionSummary' }
    & Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadCashSummaryChartLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadCashSummaryChartLiveDataQuery = { liveComponentData: (
    { __typename: 'CashSummary' }
    & { cashTotal: (
      Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ), cashChange: (
      Pick<DimensionSummary, 'partialData'>
      & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> } }> }
    ) }
  ) | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadLocationChartComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadLocationChartComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | (
      { __typename: 'LocationSummary' }
      & { location: (
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      ), total: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }, expensesChange: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }, otherExpensesChange: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }, incomeChange: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }, otherIncomeChange: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }, cogsChange: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }
    ) | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadLocationChartLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadLocationChartLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | (
    { __typename: 'LocationSummary' }
    & { location: (
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    ), total: { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }, expensesChange: { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }, otherExpensesChange: { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }, incomeChange: { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }, otherIncomeChange: { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }, cogsChange: { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> } }
  ) | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadRunwayLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadRunwayLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | (
    { __typename: 'Runway' }
    & Pick<Runway, 'cashOutDate' | 'previousCashOutDate'>
    & { current: (
      Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ), future: (
      Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ) }
  ) | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadInsightComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadInsightComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | (
      { __typename: 'InsightComponent' }
      & Pick<InsightComponent, 'sentence'>
      & { hovers: Array<(
        { __typename: 'CategoryHover' }
        & Pick<CategoryHover, 'balance'>
        & { entity: (
          { __typename: 'EntityCategory' }
          & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
        ), history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ), context?: Maybe<(
          Pick<DimensionSummary, 'partialData'>
          & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        )>, topTransactions?: Maybe<Array<(
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      ) | (
        { __typename: 'PartyHover' }
        & { entity: (
          { __typename: 'EntityParty' }
          & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
        ), history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ), context?: Maybe<(
          Pick<DimensionSummary, 'partialData'>
          & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        )>, topTransactions?: Maybe<Array<(
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      ) | (
        { __typename: 'SummaryLineItemHover' }
        & Pick<SummaryLineItemHover, 'lineType'>
        & { history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ) }
      )> }
    ) | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadCategorySummaryByTimeComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadCategorySummaryByTimeComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | (
      { __typename: 'Chart' }
      & { entity: { __typename: 'EntityBill' } | (
        { __typename: 'EntityCategory' }
        & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
      ) | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | { __typename: 'EntityDepartment' } | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | { __typename: 'EntityLedgerTransaction' } | { __typename: 'EntityLegalEntity' } | { __typename: 'EntityLocation' } | { __typename: 'EntityParty' } | { __typename: 'EntityReport' } | { __typename: 'EntityReportPackage' } | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | { __typename: 'EntityWorkItem' }, summary: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ) }
    ) | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadTopPartiesComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadTopPartiesComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | (
      { __typename: 'TopEntities' }
      & { entities: (
        Pick<DimensionSummary, 'partialData'>
        & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ), by: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'> }> } }> }
      ) }
    ) | { __typename: 'TopTransactions' } } };

export type ReadTextComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadTextComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | (
      { __typename: 'EntityHovers' }
      & { hovers: Array<(
        { __typename: 'CategoryHover' }
        & Pick<CategoryHover, 'balance'>
        & { entity: (
          { __typename: 'EntityCategory' }
          & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
        ), history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ), context?: Maybe<(
          Pick<DimensionSummary, 'partialData'>
          & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        )>, topTransactions?: Maybe<Array<(
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      ) | (
        { __typename: 'PartyHover' }
        & { entity: (
          { __typename: 'EntityParty' }
          & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
        ), history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ), context?: Maybe<(
          Pick<DimensionSummary, 'partialData'>
          & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        )>, topTransactions?: Maybe<Array<(
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      ) | (
        { __typename: 'SummaryLineItemHover' }
        & Pick<SummaryLineItemHover, 'lineType'>
        & { history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ) }
      )> }
    ) | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type EntityHoverFields_CategoryHover_Fragment = (
  { __typename: 'CategoryHover' }
  & Pick<CategoryHover, 'balance'>
  & { entity: (
    { __typename: 'EntityCategory' }
    & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
  ), history: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ), context?: Maybe<(
    Pick<DimensionSummary, 'partialData'>
    & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  )>, topTransactions?: Maybe<Array<(
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  )>> }
);

export type EntityHoverFields_PartyHover_Fragment = (
  { __typename: 'PartyHover' }
  & { entity: (
    { __typename: 'EntityParty' }
    & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
  ), history: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ), context?: Maybe<(
    Pick<DimensionSummary, 'partialData'>
    & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  )>, topTransactions?: Maybe<Array<(
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  )>> }
);

export type EntityHoverFields_SummaryLineItemHover_Fragment = (
  { __typename: 'SummaryLineItemHover' }
  & Pick<SummaryLineItemHover, 'lineType'>
  & { history: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) }
);

export type EntityHoverFieldsFragment = EntityHoverFields_CategoryHover_Fragment | EntityHoverFields_PartyHover_Fragment | EntityHoverFields_SummaryLineItemHover_Fragment;

export type ReadDepartmentSummaryByTimeComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadDepartmentSummaryByTimeComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | (
      { __typename: 'Chart' }
      & { entity: { __typename: 'EntityBill' } | { __typename: 'EntityCategory' } | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | (
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      ) | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | { __typename: 'EntityLedgerTransaction' } | { __typename: 'EntityLegalEntity' } | { __typename: 'EntityLocation' } | { __typename: 'EntityParty' } | { __typename: 'EntityReport' } | { __typename: 'EntityReportPackage' } | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | { __typename: 'EntityWorkItem' }, summary: (
        Pick<DimensionSummary, 'partialData'>
        & { department: Array<(
          Pick<DepartmentDimension, 'departmentId'>
          & { departmentObject: (
            { __typename: 'EntityDepartment' }
            & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
          ), by: { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> } }
        )> }
      ) }
    ) | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadLocationSummaryByTimeComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadLocationSummaryByTimeComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | (
      { __typename: 'Chart' }
      & { entity: { __typename: 'EntityBill' } | { __typename: 'EntityCategory' } | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | (
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      ) | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | { __typename: 'EntityLedgerTransaction' } | { __typename: 'EntityLegalEntity' } | { __typename: 'EntityLocation' } | { __typename: 'EntityParty' } | { __typename: 'EntityReport' } | { __typename: 'EntityReportPackage' } | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | { __typename: 'EntityWorkItem' }, summary: (
        Pick<DimensionSummary, 'partialData'>
        & { department: Array<(
          Pick<DepartmentDimension, 'departmentId'>
          & { departmentObject: (
            { __typename: 'EntityDepartment' }
            & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
          ), by: { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> } }
        )> }
      ) }
    ) | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadTopTransactionsComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadTopTransactionsComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | (
      { __typename: 'TopTransactions' }
      & Pick<TopTransactions, 'partialData'>
      & { transactions: Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )> }
    ) } };

export type ReadDepartmentSummaryByTimeLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadDepartmentSummaryByTimeLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | (
    { __typename: 'Chart' }
    & { entity: { __typename: 'EntityBill' } | { __typename: 'EntityCategory' } | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | (
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    ) | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | { __typename: 'EntityLedgerTransaction' } | { __typename: 'EntityLegalEntity' } | { __typename: 'EntityLocation' } | { __typename: 'EntityParty' } | { __typename: 'EntityReport' } | { __typename: 'EntityReportPackage' } | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | { __typename: 'EntityWorkItem' }, summary: (
      Pick<DimensionSummary, 'partialData'>
      & { department: Array<(
        Pick<DepartmentDimension, 'departmentId'>
        & { departmentObject: (
          { __typename: 'EntityDepartment' }
          & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
        ), by: { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> } }
      )> }
    ) }
  ) | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadTopCategoriesComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadTopCategoriesComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | (
      { __typename: 'TopEntities' }
      & { entities: (
        Pick<DimensionSummary, 'partialData'>
        & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ) }
    ) | { __typename: 'TopTransactions' } } };

export type ReadPartySummaryByTimeComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadPartySummaryByTimeComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | (
      { __typename: 'Chart' }
      & { entity: { __typename: 'EntityBill' } | { __typename: 'EntityCategory' } | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | { __typename: 'EntityDepartment' } | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | { __typename: 'EntityLedgerTransaction' } | { __typename: 'EntityLegalEntity' } | { __typename: 'EntityLocation' } | (
        { __typename: 'EntityParty' }
        & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
      ) | { __typename: 'EntityReport' } | { __typename: 'EntityReportPackage' } | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | { __typename: 'EntityWorkItem' }, summary: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ) }
    ) | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadTopPartiesLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadTopPartiesLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | (
    { __typename: 'TopEntities' }
    & { entities: (
      Pick<DimensionSummary, 'partialData'>
      & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ), by: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'> }> } }> }
    ) }
  ) | { __typename: 'TopTransactions' } };

export type ReadCategorySummaryByTimeLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadCategorySummaryByTimeLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | (
    { __typename: 'Chart' }
    & { entity: { __typename: 'EntityBill' } | (
      { __typename: 'EntityCategory' }
      & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
    ) | { __typename: 'EntityComment' } | { __typename: 'EntityDataSource' } | { __typename: 'EntityDepartment' } | { __typename: 'EntityIdentityVerification' } | { __typename: 'EntityInvoice' } | { __typename: 'EntityLedgerTransaction' } | { __typename: 'EntityLegalEntity' } | { __typename: 'EntityLocation' } | { __typename: 'EntityParty' } | { __typename: 'EntityReport' } | { __typename: 'EntityReportPackage' } | { __typename: 'EntityThread' } | { __typename: 'EntityTransaction' } | { __typename: 'EntityUser' } | { __typename: 'EntityWorkItem' }, summary: (
      Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ) }
  ) | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadSummaryLineItemByTimeCategoryLiveDataQuery = { total: { __typename: 'CashSummary' } | { __typename: 'Chart' } | (
    { __typename: 'DimensionSummary' }
    & Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' }, breakdown: { __typename: 'CashSummary' } | { __typename: 'Chart' } | (
    { __typename: 'DimensionSummary' }
    & Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ by: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }
  ) | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadLayoutQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  layoutId: Scalars['String']['input'];
  layoutVersionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReadLayoutQuery = { readLayout: (
    Pick<Layout, 'layoutId' | 'layoutVersionId'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, sections?: Maybe<Array<(
      Pick<LayoutSection, 'sectionId' | 'title'>
      & { rows?: Maybe<Array<(
        Pick<LayoutRow, 'rowId' | 'title'>
        & { components?: Maybe<Array<(
          Pick<LayoutComponent, 'componentId' | 'title' | 'dataId'>
          & { config: (
            Pick<LayoutComponentConfig, 'type'>
            & { text?: Maybe<(
              Pick<TextComponentConfig, 'body'>
              & { current?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, lookback?: Maybe<Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>>, tags?: Maybe<Array<(
                Pick<TextComponentConfigTag, 'displayText'>
                & { objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<TextTagOptions, 'partyRole'>> }
              )>> }
            )>, categoryChart?: Maybe<(
              Pick<CategoryChartConfig, 'categoryId' | 'withoutCategoryAncestors' | 'asPermanent'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, partyChart?: Maybe<(
              Pick<PartyChartConfig, 'partyId' | 'partyRole' | 'asPermanent'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, topEntities?: Maybe<(
              Pick<TopEntitiesConfig, 'limit' | 'kind' | 'partyRole' | 'categoryType' | 'sort'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
            )>, topTransactions?: Maybe<(
              Pick<TopTransactionsConfig, 'limit' | 'partyRole' | 'categoryType'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, filteredBy?: Maybe<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
            )>, summaryLineItem?: Maybe<(
              Pick<SummaryLineItemConfig, 'item'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, portalMetric?: Maybe<(
              Pick<PortalMetricConfig, 'metricType'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, entitiesList?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, entities: (
                { __typename: 'ObjectEntities' }
                & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
                  Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                  & { moneyFlow: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
                  Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
                  & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
                )>>, reportPackages?: Maybe<Array<(
                  Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
                  & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
                )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
                  Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
                  & { objectTransaction: (
                    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
                    & { moneyFlow: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
                  ) }
                )>>, invoices?: Maybe<Array<(
                  Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
                  & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
                    Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
                    & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
                  ) }
                )>>, bills?: Maybe<Array<(
                  Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
                  & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
                    Pick<Format, 'id' | 'formatClass'>
                    & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
                  ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
                )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
                  { __typename: 'EntityDepartment' }
                  & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
                )>>, locations?: Maybe<Array<(
                  { __typename: 'EntityLocation' }
                  & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
                )>> }
              ) }>, statement?: Maybe<(
              Pick<StatementConfig, 'kind'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, options?: Maybe<(
                { __typename: 'ReportDocumentOptions' }
                & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
                & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
              )> }
            )>, metricChart?: Maybe<(
              Pick<MetricChartConfig, 'item'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, runwayChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, cashSummaryChart?: Maybe<{ origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, staticMetric?: Maybe<Pick<StaticMetricConfig, 'name' | 'value' | 'updatedAt'>>, documentCollection?: Maybe<Pick<DocumentCollectionConfig, 'collectionId'>>, insight?: Maybe<{ objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, options?: Maybe<Pick<InsightComponentConfigOptions, 'partyRole'>>, origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>, lookback: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }>, departmentChart?: Maybe<(
              Pick<DepartmentChartConfig, 'departmentId'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )>, locationChart?: Maybe<(
              Pick<LocationChartConfig, 'locationId'>
              & { origin: Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'> }
            )> }
          ) }
        )>> }
      )>> }
    )>> }
  ) };

export type ReadReportStatementComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadReportStatementComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | (
      { __typename: 'Statement' }
      & Pick<Statement, 'kind'>
      & { rows: Array<(
        Pick<StatementRow, 'rowId' | 'depth'>
        & { sectionTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, sectionSummary?: Maybe<(
          Pick<StatementRowSummary, 'displayNumber' | 'label'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, parentCategoryTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, parentCategorySummary?: Maybe<(
          Pick<StatementRowSummary, 'displayNumber' | 'label'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, leafCategorySummary?: Maybe<(
          Pick<StatementRowSummary, 'displayNumber' | 'label'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, details?: Maybe<{ hover: (
            { __typename: 'CategoryHover' }
            & Pick<CategoryHover, 'balance'>
            & { entity: (
              { __typename: 'EntityCategory' }
              & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
            ), history: (
              Pick<DimensionSummary, 'partialData'>
              & { time: Array<{ summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            ), context?: Maybe<(
              Pick<DimensionSummary, 'partialData'>
              & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            )>, topTransactions?: Maybe<Array<(
              Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
              & { moneyFlow: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
            )>> }
          ) | (
            { __typename: 'PartyHover' }
            & { entity: (
              { __typename: 'EntityParty' }
              & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
            ), history: (
              Pick<DimensionSummary, 'partialData'>
              & { time: Array<{ summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            ), context?: Maybe<(
              Pick<DimensionSummary, 'partialData'>
              & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            )>, topTransactions?: Maybe<Array<(
              Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
              & { moneyFlow: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
            )>> }
          ) | (
            { __typename: 'SummaryLineItemHover' }
            & Pick<SummaryLineItemHover, 'lineType'>
            & { history: (
              Pick<DimensionSummary, 'partialData'>
              & { time: Array<{ summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            ) }
          ), insight?: Maybe<(
            Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
            & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
          )> }>, ytdDetails?: Maybe<{ hover: (
            { __typename: 'CategoryHover' }
            & Pick<CategoryHover, 'balance'>
            & { entity: (
              { __typename: 'EntityCategory' }
              & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
            ), history: (
              Pick<DimensionSummary, 'partialData'>
              & { time: Array<{ summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            ), context?: Maybe<(
              Pick<DimensionSummary, 'partialData'>
              & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            )>, topTransactions?: Maybe<Array<(
              Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
              & { moneyFlow: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
            )>> }
          ) | (
            { __typename: 'PartyHover' }
            & { entity: (
              { __typename: 'EntityParty' }
              & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
            ), history: (
              Pick<DimensionSummary, 'partialData'>
              & { time: Array<{ summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            ), context?: Maybe<(
              Pick<DimensionSummary, 'partialData'>
              & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            )>, topTransactions?: Maybe<Array<(
              Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
              & { moneyFlow: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
            )>> }
          ) | (
            { __typename: 'SummaryLineItemHover' }
            & Pick<SummaryLineItemHover, 'lineType'>
            & { history: (
              Pick<DimensionSummary, 'partialData'>
              & { time: Array<{ summary: (
                  Pick<TransactionSummary, 'isPeriodBookClosed'>
                  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    )>, current?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )>, prior?: Maybe<(
                      Pick<SummaryTotal, 'count'>
                      & { total: (
                        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                      ) }
                    )> }
                  ) }
                ) }> }
            ) }
          ), insight?: Maybe<(
            Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
            & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
          )> }>, deltas?: Maybe<{ percentageTotal?: Maybe<(
            Pick<StatementDeltaValue, 'percentageOfAmount'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, percentageIncome?: Maybe<(
            Pick<StatementDeltaValue, 'percentageOfAmount'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, previousPeriod?: Maybe<(
            Pick<StatementDeltaValue, 'percentageOfAmount'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, previousYear?: Maybe<(
            Pick<StatementDeltaValue, 'percentageOfAmount'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }> }
      )> }
    ) | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadRunwayComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadRunwayComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | (
      { __typename: 'Runway' }
      & Pick<Runway, 'cashOutDate' | 'previousCashOutDate'>
      & { current: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ), future: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ) }
    ) | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadReportStatementLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadReportStatementLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | (
    { __typename: 'Statement' }
    & Pick<Statement, 'kind'>
    & { rows: Array<(
      Pick<StatementRow, 'rowId' | 'depth'>
      & { sectionTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, sectionSummary?: Maybe<(
        Pick<StatementRowSummary, 'displayNumber' | 'label'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, parentCategoryTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, parentCategorySummary?: Maybe<(
        Pick<StatementRowSummary, 'displayNumber' | 'label'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, leafCategorySummary?: Maybe<(
        Pick<StatementRowSummary, 'displayNumber' | 'label'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, details?: Maybe<{ hover: (
          { __typename: 'CategoryHover' }
          & Pick<CategoryHover, 'balance'>
          & { entity: (
            { __typename: 'EntityCategory' }
            & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
          ), history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ), context?: Maybe<(
            Pick<DimensionSummary, 'partialData'>
            & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          )>, topTransactions?: Maybe<Array<(
            Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        ) | (
          { __typename: 'PartyHover' }
          & { entity: (
            { __typename: 'EntityParty' }
            & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
          ), history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ), context?: Maybe<(
            Pick<DimensionSummary, 'partialData'>
            & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          )>, topTransactions?: Maybe<Array<(
            Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        ) | (
          { __typename: 'SummaryLineItemHover' }
          & Pick<SummaryLineItemHover, 'lineType'>
          & { history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ) }
        ), insight?: Maybe<(
          Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
          & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
        )> }>, ytdDetails?: Maybe<{ hover: (
          { __typename: 'CategoryHover' }
          & Pick<CategoryHover, 'balance'>
          & { entity: (
            { __typename: 'EntityCategory' }
            & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
          ), history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ), context?: Maybe<(
            Pick<DimensionSummary, 'partialData'>
            & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          )>, topTransactions?: Maybe<Array<(
            Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        ) | (
          { __typename: 'PartyHover' }
          & { entity: (
            { __typename: 'EntityParty' }
            & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
          ), history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ), context?: Maybe<(
            Pick<DimensionSummary, 'partialData'>
            & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          )>, topTransactions?: Maybe<Array<(
            Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        ) | (
          { __typename: 'SummaryLineItemHover' }
          & Pick<SummaryLineItemHover, 'lineType'>
          & { history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ) }
        ), insight?: Maybe<(
          Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
          & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
        )> }>, deltas?: Maybe<{ percentageTotal?: Maybe<(
          Pick<StatementDeltaValue, 'percentageOfAmount'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, percentageIncome?: Maybe<(
          Pick<StatementDeltaValue, 'percentageOfAmount'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, previousPeriod?: Maybe<(
          Pick<StatementDeltaValue, 'percentageOfAmount'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, previousYear?: Maybe<(
          Pick<StatementDeltaValue, 'percentageOfAmount'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )> }> }
    )> }
  ) | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadSummaryLineItemByTimeComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadSummaryLineItemByTimeComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | (
      { __typename: 'DimensionSummary' }
      & Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ) | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadTopTransactionsLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadTopTransactionsLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | (
    { __typename: 'TopTransactions' }
    & Pick<TopTransactions, 'partialData'>
    & { transactions: Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )> }
  ) };

export type ReadTopCategoriesLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadTopCategoriesLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | (
    { __typename: 'TopEntities' }
    & { entities: (
      Pick<DimensionSummary, 'partialData'>
      & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ) }
  ) | { __typename: 'TopTransactions' } };

export type ReadMetricByTimeLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadMetricByTimeLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | (
    { __typename: 'DimensionSummary' }
    & Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadMetricByTimeComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadMetricByTimeComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: { __typename: 'CashSummary' } | { __typename: 'Chart' } | (
      { __typename: 'DimensionSummary' }
      & Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ) | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadInsightLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadInsightLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | (
    { __typename: 'InsightComponent' }
    & Pick<InsightComponent, 'sentence'>
    & { hovers: Array<(
      { __typename: 'CategoryHover' }
      & Pick<CategoryHover, 'balance'>
      & { entity: (
        { __typename: 'EntityCategory' }
        & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
      ), history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ), context?: Maybe<(
        Pick<DimensionSummary, 'partialData'>
        & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      )>, topTransactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>> }
    ) | (
      { __typename: 'PartyHover' }
      & { entity: (
        { __typename: 'EntityParty' }
        & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
      ), history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ), context?: Maybe<(
        Pick<DimensionSummary, 'partialData'>
        & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      )>, topTransactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>> }
    ) | (
      { __typename: 'SummaryLineItemHover' }
      & Pick<SummaryLineItemHover, 'lineType'>
      & { history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ) }
    )> }
  ) | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ReadCashSummaryChartComponentDataQueryVariables = Exact<{
  layoutId: Scalars['String']['input'];
  dataId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadCashSummaryChartComponentDataQuery = { readComponentData: { viewId: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, data: (
      { __typename: 'CashSummary' }
      & { cashTotal: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ), cashChange: (
        Pick<DimensionSummary, 'partialData'>
        & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> } }> }
      ) }
    ) | { __typename: 'Chart' } | { __typename: 'DimensionSummary' } | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } } };

export type ReadSummaryLineItemByTimeLiveDataQueryVariables = Exact<{
  viewId: ViewIdentifierInput;
  config: LayoutComponentConfigInput;
}>;


export type ReadSummaryLineItemByTimeLiveDataQuery = { liveComponentData: { __typename: 'CashSummary' } | { __typename: 'Chart' } | (
    { __typename: 'DimensionSummary' }
    & Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) | { __typename: 'EntitiesList' } | { __typename: 'EntityHovers' } | { __typename: 'InsightComponent' } | { __typename: 'LocationSummary' } | { __typename: 'Runway' } | { __typename: 'Statement' } | { __typename: 'TopEntities' } | { __typename: 'TopTransactions' } };

export type ActivityEventFieldsFragment = (
  Pick<ActivityEvent, 'id' | 'legalEntityId' | 'occurredAt'>
  & { discreteEvent?: Maybe<(
    Pick<DiscreteEvent, 'kind' | 'actorUserId' | 'targetEmailAddress'>
    & { target: Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'>, subject: Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'> }
  )> }
);

export type DiscreteEventFieldsFragment = (
  Pick<DiscreteEvent, 'kind' | 'actorUserId' | 'targetEmailAddress'>
  & { target: Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'>, subject: Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'> }
);

export type ActivityFeedFieldsFragment = { events: Array<(
    Pick<ActivityEvent, 'id' | 'legalEntityId' | 'occurredAt'>
    & { discreteEvent?: Maybe<(
      Pick<DiscreteEvent, 'kind' | 'actorUserId' | 'targetEmailAddress'>
      & { target: Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'>, subject: Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'> }
    )> }
  )>, entities: (
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  ) };

export type ReadActivityFeedQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  identifier?: InputMaybe<InputObjectIdentifier>;
  pagination: Pagination;
}>;


export type ReadActivityFeedQuery = { activityFeed: { events: Array<(
      Pick<ActivityEvent, 'id' | 'legalEntityId' | 'occurredAt'>
      & { discreteEvent?: Maybe<(
        Pick<DiscreteEvent, 'kind' | 'actorUserId' | 'targetEmailAddress'>
        & { target: Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'>, subject: Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'> }
      )> }
    )>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ) } };

export type TransactionReviewQuestionFieldsFragment = Pick<TransactionReviewQuestion, 'question' | 'createdAt'>;

export type CloseWorkItemsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  closedWorkItems: Array<ClosedWorkItem> | ClosedWorkItem;
  viewKey?: InputMaybe<ViewKey>;
}>;


export type CloseWorkItemsMutation = Pick<Mutation, 'closeWorkItems'>;

export type PushWorkItemsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  workItems: Array<WorkItemPushParams> | WorkItemPushParams;
}>;


export type PushWorkItemsMutation = Pick<Mutation, 'pushWorkItems'>;

export type UpdateClientReviewStatesMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  responses: Array<WorkItemResponse> | WorkItemResponse;
}>;


export type UpdateClientReviewStatesMutation = Pick<Mutation, 'updateClientReviewStates'>;

export type DeleteTransactionReviewQuestionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  question: Scalars['String']['input'];
}>;


export type DeleteTransactionReviewQuestionMutation = Pick<Mutation, 'deleteTransactionReviewQuestion'>;

export type UpdateTransactionReviewQuestionsMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  deletedQuestions?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  newQuestions?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type UpdateTransactionReviewQuestionsMutation = Pick<Mutation, 'updateTransactionReviewQuestions'>;

export type ReopenWorkItemMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  workItemId: Scalars['String']['input'];
}>;


export type ReopenWorkItemMutation = Pick<Mutation, 'reopenWorkItem'>;

export type PushDigitsWorkItemsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  workItems: Array<DigitsWorkItemPushParams> | DigitsWorkItemPushParams;
}>;


export type PushDigitsWorkItemsMutation = Pick<Mutation, 'pushDigitsWorkItems'>;

export type UpdateAccountantReviewStatesMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  period: PeriodInput;
  workItemSubjects: Array<WorkItemSubject> | WorkItemSubject;
  message?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateAccountantReviewStatesMutation = Pick<Mutation, 'updateAccountantReviewStates'>;

export type HighlightWorkItemQueryVariables = Exact<{
  similarityType: SimilarityType;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type HighlightWorkItemQuery = { highlightWorkItem: { description?: Maybe<Array<Pick<HighlightedToken, 'token' | 'score'>>>, name?: Maybe<Array<Pick<HighlightedToken, 'token' | 'score'>>> } };

export type ListTransactionReviewItemsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  filter: WorkItemFilter;
  intervalOrigin: IntervalOrigin;
  direction: DirectionFromOrigin;
}>;


export type ListTransactionReviewItemsQuery = { workItems: Array<(
    Pick<WorkItem, 'legalEntityId' | 'workItemId' | 'time' | 'itemType' | 'itemState' | 'ledgerType' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'actionable' | 'reviewState'>
    & { transactions: Array<(
      Pick<WorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'displayPartyRole' | 'displayEntry' | 'description' | 'name' | 'occurredAt' | 'dataSourceId' | 'quickbooksAppName' | 'ledgerTransactionId'>
      & { displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, creditValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, displayValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, moneyFlow?: Maybe<(
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      )> }
    )>, review?: Maybe<(
      Pick<WorkItemReview, 'createdAt' | 'updatedAt' | 'subject'>
      & { creator: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    )>, generatedQuestions: Array<Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>>, pushes: Array<Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>> }
  )>, rules: Array<(
    Pick<AuditRule, 'auditRuleId' | 'name' | 'description' | 'type' | 'action' | 'ruleState'>
    & { amountFilters?: Maybe<Array<(
      Pick<AmountRuleFilter, 'amountRuleFilterId' | 'operator' | 'createdAt'>
      & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>>, partyFilters?: Maybe<Array<(
      Pick<PartyRuleFilter, 'partyRuleFilterId' | 'operation' | 'createdAt'>
      & { parties: Array<Pick<PartyRuleFilterParty, 'partyId' | 'partyName'>> }
    )>>, categoryFilters?: Maybe<Array<(
      Pick<CategoryRuleFilter, 'categoryRuleFilterId' | 'operation' | 'createdAt'>
      & { categories: Array<Pick<CategoryRuleFilterCategory, 'categoryId' | 'categoryName'>> }
    )>> }
  )>, ruleStatuses: Array<Pick<AuditRuleStatus, 'type' | 'recentlyUpdated' | 'updaterType'>> };

export type ListWorkItemsQueryVariables = Exact<{
  filter: WorkItemFilter;
  intervalOrigin: IntervalOrigin;
  direction: DirectionFromOrigin;
}>;


export type ListWorkItemsQuery = { listWorkItems: Array<(
    Pick<WorkItem, 'legalEntityId' | 'workItemId' | 'time' | 'itemType' | 'itemState' | 'ledgerType' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'actionable' | 'reviewState'>
    & { transactions: Array<(
      Pick<WorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'displayPartyRole' | 'displayEntry' | 'description' | 'name' | 'occurredAt' | 'dataSourceId' | 'quickbooksAppName' | 'ledgerTransactionId'>
      & { displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, creditValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, displayValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, moneyFlow?: Maybe<(
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      )> }
    )>, review?: Maybe<(
      Pick<WorkItemReview, 'createdAt' | 'updatedAt' | 'subject'>
      & { creator: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    )>, generatedQuestions: Array<Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>>, pushes: Array<Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>> }
  )> };

export type ListWorkItemsByIdsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  workItemIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ListWorkItemsByIdsQuery = { listWorkItemsByIds: Array<(
    Pick<WorkItem, 'legalEntityId' | 'workItemId' | 'time' | 'itemType' | 'itemState' | 'ledgerType' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'actionable' | 'reviewState'>
    & { transactions: Array<(
      Pick<WorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'displayPartyRole' | 'displayEntry' | 'description' | 'name' | 'occurredAt' | 'dataSourceId' | 'quickbooksAppName' | 'ledgerTransactionId'>
      & { displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, creditValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, displayValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, moneyFlow?: Maybe<(
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      )> }
    )>, review?: Maybe<(
      Pick<WorkItemReview, 'createdAt' | 'updatedAt' | 'subject'>
      & { creator: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    )>, generatedQuestions: Array<Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>>, pushes: Array<Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>> }
  )> };

export type SummarizeWorkItemsQueryVariables = Exact<{
  filter: WorkItemFilter;
  intervalOrigin: IntervalOrigin;
  direction: DirectionFromOrigin;
}>;


export type SummarizeWorkItemsQuery = { summarizeWorkItems: Array<(
    Pick<WorkItemSummary, 'count'>
    & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
  )> };

export type ListTransactionReviewQuestionsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type ListTransactionReviewQuestionsQuery = { listTransactionReviewQuestions?: Maybe<Array<Pick<TransactionReviewQuestion, 'question' | 'createdAt'>>> };

export type UpdateAuditRulesMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  rules: Array<UpdateAuditRule> | UpdateAuditRule;
  isFTE?: InputMaybe<Scalars['Boolean']['input']>;
  usedSuggestedCategories?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateAuditRulesMutation = Pick<Mutation, 'updateAuditRules'>;

export type AuditRuleFieldsFragment = (
  Pick<AuditRule, 'auditRuleId' | 'name' | 'description' | 'type' | 'action' | 'ruleState'>
  & { amountFilters?: Maybe<Array<(
    Pick<AmountRuleFilter, 'amountRuleFilterId' | 'operator' | 'createdAt'>
    & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>>, partyFilters?: Maybe<Array<(
    Pick<PartyRuleFilter, 'partyRuleFilterId' | 'operation' | 'createdAt'>
    & { parties: Array<Pick<PartyRuleFilterParty, 'partyId' | 'partyName'>> }
  )>>, categoryFilters?: Maybe<Array<(
    Pick<CategoryRuleFilter, 'categoryRuleFilterId' | 'operation' | 'createdAt'>
    & { categories: Array<Pick<CategoryRuleFilterCategory, 'categoryId' | 'categoryName'>> }
  )>> }
);

export type AmountRuleFilterFieldsFragment = (
  Pick<AmountRuleFilter, 'amountRuleFilterId' | 'operator' | 'createdAt'>
  & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type PartyRuleFilterFieldsFragment = (
  Pick<PartyRuleFilter, 'partyRuleFilterId' | 'operation' | 'createdAt'>
  & { parties: Array<Pick<PartyRuleFilterParty, 'partyId' | 'partyName'>> }
);

export type PartyRuleFilterPartyFieldsFragment = Pick<PartyRuleFilterParty, 'partyId' | 'partyName'>;

export type CategoryRuleFilterFieldsFragment = (
  Pick<CategoryRuleFilter, 'categoryRuleFilterId' | 'operation' | 'createdAt'>
  & { categories: Array<Pick<CategoryRuleFilterCategory, 'categoryId' | 'categoryName'>> }
);

export type CategoryRuleFilterCategoryFieldsFragment = Pick<CategoryRuleFilterCategory, 'categoryId' | 'categoryName'>;

export type AuditRuleStatusFieldsFragment = Pick<AuditRuleStatus, 'type' | 'recentlyUpdated' | 'updaterType'>;

export type ListAuditRulesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListAuditRulesQuery = { listAuditRules: Array<(
    Pick<AuditRule, 'auditRuleId' | 'name' | 'description' | 'type' | 'action' | 'ruleState'>
    & { amountFilters?: Maybe<Array<(
      Pick<AmountRuleFilter, 'amountRuleFilterId' | 'operator' | 'createdAt'>
      & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>>, partyFilters?: Maybe<Array<(
      Pick<PartyRuleFilter, 'partyRuleFilterId' | 'operation' | 'createdAt'>
      & { parties: Array<Pick<PartyRuleFilterParty, 'partyId' | 'partyName'>> }
    )>>, categoryFilters?: Maybe<Array<(
      Pick<CategoryRuleFilter, 'categoryRuleFilterId' | 'operation' | 'createdAt'>
      & { categories: Array<Pick<CategoryRuleFilterCategory, 'categoryId' | 'categoryName'>> }
    )>> }
  )> };

export type ListShareSuggestedUsersQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListShareSuggestedUsersQuery = { listShareSuggestedUsers: Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )> };

export type ListLimitedSuggestedUsersQueryVariables = Exact<{
  identifier: InputObjectIdentifier;
}>;


export type ListLimitedSuggestedUsersQuery = { listLimitedSuggestedUsers: Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )> };

export type ListSuggestedUsersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  identifier: InputObjectIdentifier;
}>;


export type ListSuggestedUsersQuery = { listSuggestedUsers: Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )> };

export type ListAccountantSuggestedUsersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  identifier: InputObjectIdentifier;
}>;


export type ListAccountantSuggestedUsersQuery = { listAccountantSuggestedUsers: Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )> };

export type ListInvitationProposalsQueryVariables = Exact<{
  type: ProposalType;
}>;


export type ListInvitationProposalsQuery = { listInvitationProposals: Array<(
    Pick<InvitationProposal, 'proposalId' | 'proposalType' | 'status' | 'invitationId' | 'organizationTemplateId' | 'createdAt'>
    & { legalEntity?: Maybe<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>, organization?: Maybe<Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>>, sender?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
  )> };

export type ReadInvitationProposalQueryVariables = Exact<{
  proposalId: Scalars['String']['input'];
}>;


export type ReadInvitationProposalQuery = { readInvitationProposal: (
    Pick<InvitationProposal, 'proposalId' | 'proposalType' | 'status' | 'invitationId' | 'organizationTemplateId' | 'createdAt'>
    & { legalEntity?: Maybe<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>, organization?: Maybe<Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>>, sender?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
  ) };

export type ObjectMappingHierarchyNodeFieldsFragment = Pick<ObjectMappingHierarchyNode, 'factId' | 'name' | 'parentId' | 'active' | 'transactionCount' | 'mappedTo' | 'depth' | 'fullyQualifiedName'>;

export type UpdateObjectMappingsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  mappedTo: ObjectKind;
  lineClassIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  departmentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type UpdateObjectMappingsMutation = { updateObjectMappings: { lineClassHierarchy: Array<Pick<ObjectMappingHierarchyNode, 'factId' | 'name' | 'parentId' | 'active' | 'transactionCount' | 'mappedTo' | 'depth' | 'fullyQualifiedName'>>, departmentHierarchy: Array<Pick<ObjectMappingHierarchyNode, 'factId' | 'name' | 'parentId' | 'active' | 'transactionCount' | 'mappedTo' | 'depth' | 'fullyQualifiedName'>> } };

export type ListLineClassFactHierarchyQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListLineClassFactHierarchyQuery = { listLineClassFactHierarchy: Array<Pick<ObjectMappingHierarchyNode, 'factId' | 'name' | 'parentId' | 'active' | 'transactionCount' | 'mappedTo' | 'depth' | 'fullyQualifiedName'>> };

export type ListDepartmentFactHierarchyQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListDepartmentFactHierarchyQuery = { listDepartmentFactHierarchy: Array<Pick<ObjectMappingHierarchyNode, 'factId' | 'name' | 'parentId' | 'active' | 'transactionCount' | 'mappedTo' | 'depth' | 'fullyQualifiedName'>> };

export type UpdateOAuthCredentialMutationVariables = Exact<{
  code: Scalars['String']['input'];
  service: OAuthService;
}>;


export type UpdateOAuthCredentialMutation = Pick<Mutation, 'updateOAuthCredential'>;

export type UpdateUserMutationVariables = Exact<{
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserMutation = Pick<Mutation, 'updateUser'>;

export type AcceptTermsMutationVariables = Exact<{
  version: Scalars['String']['input'];
}>;


export type AcceptTermsMutation = Pick<Mutation, 'acceptTerms'>;

export type CreatePasswordResetMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
}>;


export type CreatePasswordResetMutation = Pick<Mutation, 'createPasswordReset'>;

export type SetDefaultApproverMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type SetDefaultApproverMutation = Pick<Mutation, 'setDefaultApprover'>;

export type EmailOAuthProviderQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailOAuthProviderQuery = Pick<Query, 'emailOAuthProvider'>;

export type ReadUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadUserQuery = { readUser: (
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  ) };

export type ReadDefaultApproverQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadDefaultApproverQuery = Pick<Query, 'readDefaultApprover'>;

export type ReadIdentityTokenQueryVariables = Exact<{
  service: TokenIdentityService;
}>;


export type ReadIdentityTokenQuery = Pick<Query, 'tokenIdentityProvider'>;

export type ReadDepartmentQueryVariables = Exact<{
  viewKey: ViewKey;
  departmentId: Scalars['String']['input'];
}>;


export type ReadDepartmentQuery = { readDepartment: (
    Pick<Department, 'id' | 'name' | 'parentId' | 'active'>
    & { ancestors: Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )> }
  ) };

export type ListDepartmentsQueryVariables = Exact<{
  viewKey: ViewKey;
}>;


export type ListDepartmentsQuery = { listDepartments: Array<(
    Pick<Department, 'id' | 'name' | 'parentId' | 'active'>
    & { ancestors: Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )> }
  )> };

export type UpdateTransactionReviewReminderMessageMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  message: Scalars['String']['input'];
}>;


export type UpdateTransactionReviewReminderMessageMutation = Pick<Mutation, 'updateTransactionReviewReminderMessage'>;

export type SendTransactionReviewReminderMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  message: Scalars['String']['input'];
}>;


export type SendTransactionReviewReminderMutation = Pick<Mutation, 'sendTransactionReviewReminder'>;

export type UpdateTransactionReviewScheduleMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  daysOfWeek?: InputMaybe<Array<DayOfWeek> | DayOfWeek>;
  daysOfMonth?: InputMaybe<Array<DayOfMonth> | DayOfMonth>;
  enabled: Scalars['Boolean']['input'];
}>;


export type UpdateTransactionReviewScheduleMutation = Pick<Mutation, 'updateTransactionReviewSchedule'>;

export type TransactionReviewScheduleFieldsFragment = Pick<TransactionReviewSchedule, 'daysOfMonth' | 'daysOfWeek' | 'enabled' | 'legalEntityId'>;

export type TransactionReviewReminderMessageFieldsFragment = Pick<TransactionReviewReminderMessage, 'legalEntityId' | 'message'>;

export type ReadTransactionReviewScheduleQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadTransactionReviewScheduleQuery = { readTransactionReviewSchedule: Pick<TransactionReviewSchedule, 'daysOfMonth' | 'daysOfWeek' | 'enabled' | 'legalEntityId'> };

export type ReadTransactionReviewNextReminderQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadTransactionReviewNextReminderQuery = Pick<Query, 'readTransactionReviewNextReminder'>;

export type ReadTransactionReviewReminderMessageQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadTransactionReviewReminderMessageQuery = { readTransactionReviewReminderMessage: Pick<TransactionReviewReminderMessage, 'legalEntityId' | 'message'> };

export type CreateAffiliationFromOAuthServiceMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  service: OAuthService;
  proposalOrganizationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateAffiliationFromOAuthServiceMutation = { createAffiliationFromOAuthService: { legalEntity?: Maybe<(
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    )>, quickBooksCompany?: Maybe<{ preferences?: Maybe<{ accountingInfo?: Maybe<{ bookCloseDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }> }> }> } };

export type SubmitAccountantOnboardingMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type SubmitAccountantOnboardingMutation = Pick<Mutation, 'submitAccountantOnboarding'>;

export type ConfirmOperatorTrialMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type ConfirmOperatorTrialMutation = Pick<Mutation, 'confirmOperatorTrial'>;

export type SubmitOperatorOnboardingMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type SubmitOperatorOnboardingMutation = Pick<Mutation, 'submitOperatorOnboarding'>;

export type UpdateQuickBooksCredentialsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  service: OAuthService;
}>;


export type UpdateQuickBooksCredentialsMutation = Pick<Mutation, 'updateQuickBooksCredentials'>;

export type CreateLegalEntityFromOAuthServiceMutationVariables = Exact<{
  code: Scalars['String']['input'];
  service: OAuthService;
}>;


export type CreateLegalEntityFromOAuthServiceMutation = { createLegalEntityFromOAuthService: { legalEntity: (
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    ), userInfo: Pick<UserInfo, 'givenName' | 'familyName' | 'emailAddress'> } };

export type CreateAffiliationFromOAuthIdentityMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  realmId: Scalars['String']['input'];
  service: OAuthService;
  proposalOrganizationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateAffiliationFromOAuthIdentityMutation = { createAffiliationFromOAuthIdentity: { legalEntity?: Maybe<(
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    )>, quickBooksCompany?: Maybe<{ preferences?: Maybe<{ accountingInfo?: Maybe<{ bookCloseDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }> }> }> } };

export type SendAffiliateMutationVariables = Exact<{
  givenName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  invitationType: Scalars['String']['input'];
  proposalType?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendAffiliateMutation = Pick<Mutation, 'sendAffiliate'>;

export type AcceptAffiliationMutationVariables = Exact<{
  proposalId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type AcceptAffiliationMutation = Pick<Mutation, 'acceptAffiliation'>;

export type OnboardLegalEntityFromOAuthServiceMutationVariables = Exact<{
  code: Scalars['String']['input'];
  service: OAuthService;
}>;


export type OnboardLegalEntityFromOAuthServiceMutation = { onboardLegalEntityFromOAuthService: { legalEntity: (
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    ), userInfo: Pick<UserInfo, 'givenName' | 'familyName' | 'emailAddress'>, quotePackage: (
      Pick<QuotePackage, 'id' | 'contractDocumentId' | 'status'>
      & { recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, companyDetails: (
        Pick<CompanyDetails, 'categories' | 'companyName' | 'websiteURL' | 'companySize' | 'incorporationState' | 'entityType' | 'subsidiaryCount' | 'foreignSubsidiaryCount' | 'incorporationYear' | 'lastTaxFiling'>
        & { address?: Maybe<Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>> }
      ), financials?: Maybe<Array<(
        Pick<CompanyFinancials, 'product'>
        & { values: Array<Pick<CompanyFinancialValue, 'name' | 'institutionId'>> }
      )>> }
    ) } };

export type OnboardLegalEntityMutationVariables = Exact<{
  name: Scalars['String']['input'];
  contact: ContactInput;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type OnboardLegalEntityMutation = { onboardLegalEntity: { legalEntity: (
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    ), userInfo: Pick<UserInfo, 'givenName' | 'familyName' | 'emailAddress'>, quotePackage: (
      Pick<QuotePackage, 'id' | 'contractDocumentId' | 'status'>
      & { recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, companyDetails: (
        Pick<CompanyDetails, 'categories' | 'companyName' | 'websiteURL' | 'companySize' | 'incorporationState' | 'entityType' | 'subsidiaryCount' | 'foreignSubsidiaryCount' | 'incorporationYear' | 'lastTaxFiling'>
        & { address?: Maybe<Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>> }
      ), financials?: Maybe<Array<(
        Pick<CompanyFinancials, 'product'>
        & { values: Array<Pick<CompanyFinancialValue, 'name' | 'institutionId'>> }
      )>> }
    ) } };

export type RejectAffiliationMutationVariables = Exact<{
  proposalId: Scalars['String']['input'];
}>;


export type RejectAffiliationMutation = Pick<Mutation, 'rejectAffiliation'>;

export type CreateLegalEntityFromOAuthIdentityMutationVariables = Exact<{
  realmId: Scalars['String']['input'];
  service: OAuthService;
}>;


export type CreateLegalEntityFromOAuthIdentityMutation = { createLegalEntityFromOAuthIdentity: (
    Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
  ) };

export type CreateOrganizationMutationVariables = Exact<{
  companyName: Scalars['String']['input'];
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateOrganizationMutation = { createOrganization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> };

export type SearchResultFieldsFragment = (
  Pick<SearchResult, 'total' | 'tookSecs'>
  & { results: Array<(
    Pick<SearchHit, 'score'>
    & { highlights: Array<Pick<HitHighlight, 'field' | 'term' | 'start' | 'end'>>, objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
  )>, entities: (
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  ), kindCounts: Array<Pick<ObjectKindFacet, 'kind' | 'count'>> }
);

export type SearchGlobalPartiesQueryVariables = Exact<{
  viewType?: InputMaybe<ViewType>;
  text: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<Array<SearchEntitiesSort> | SearchEntitiesSort>;
  sortHitsLeftmost?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SearchGlobalPartiesQuery = { search: (
    Pick<SearchResult, 'total' | 'tookSecs'>
    & { results: Array<(
      Pick<SearchHit, 'score'>
      & { highlights: Array<Pick<HitHighlight, 'field' | 'term' | 'start' | 'end'>>, objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
    )>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ), kindCounts: Array<Pick<ObjectKindFacet, 'kind' | 'count'>> }
  ) };

export type SearchEntitiesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  viewVersion?: InputMaybe<Scalars['String']['input']>;
  viewType?: InputMaybe<ViewType>;
  text: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  kind: ObjectKind;
  sort?: InputMaybe<Array<SearchEntitiesSort> | SearchEntitiesSort>;
  occurredBefore?: InputMaybe<Scalars['Float']['input']>;
  occurredAfter?: InputMaybe<Scalars['Float']['input']>;
  sortHitsLeftmost?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SearchEntitiesQuery = { search: (
    Pick<SearchResult, 'total' | 'tookSecs'>
    & { results: Array<(
      Pick<SearchHit, 'score'>
      & { highlights: Array<Pick<HitHighlight, 'field' | 'term' | 'start' | 'end'>>, objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
    )>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ), kindCounts: Array<Pick<ObjectKindFacet, 'kind' | 'count'>> }
  ) };

export type SearchTermsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  viewVersion?: InputMaybe<Scalars['String']['input']>;
  viewType?: InputMaybe<ViewType>;
  text: Scalars['String']['input'];
  kinds?: InputMaybe<Array<ObjectKind> | ObjectKind>;
}>;


export type SearchTermsQuery = { searchTerms: (
    Pick<SearchResult, 'total' | 'tookSecs'>
    & { results: Array<(
      Pick<SearchHit, 'score'>
      & { highlights: Array<Pick<HitHighlight, 'field' | 'term' | 'start' | 'end'>>, objectId: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'> }
    )>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ), kindCounts: Array<Pick<ObjectKindFacet, 'kind' | 'count'>> }
  ) };

export type AutoConfigureProductMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  product: CategoryToLabelMappingProduct;
}>;


export type AutoConfigureProductMutation = Pick<Mutation, 'autoConfigureProduct'>;

export type CreateCategoryMappingMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  mappingValue: Scalars['String']['input'];
  mappingType: CategoryToLabelMappingType;
  product: CategoryToLabelMappingProduct;
  categoryId: Scalars['String']['input'];
}>;


export type CreateCategoryMappingMutation = Pick<Mutation, 'createCategoryMapping'>;

export type ValidateCategoryMappingsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  product: CategoryToLabelMappingProduct;
}>;


export type ValidateCategoryMappingsQuery = Pick<Query, 'validateCategoryMappings'>;

export type ListCategoryMappingsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  product: CategoryToLabelMappingProduct;
}>;


export type ListCategoryMappingsQuery = { listCategoryMappings: Array<(
    Pick<CategoryMapping, 'mappingValue' | 'mappingType' | 'product'>
    & { category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'> }
  )> };

export type CreateGustoConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectorId: Scalars['String']['input'];
  authorizationCode: Scalars['String']['input'];
}>;


export type CreateGustoConnectionMutation = { createGustoConnection: { connection: (
      Pick<Connection, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
      & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<(
        Pick<ConnectionAccount, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>
        & { settings?: Maybe<(
          Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
          & { labelCategories?: Maybe<Array<(
            Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
            & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        )> }
      )>>, connector?: Maybe<(
        Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
        & { connectorProperties?: Maybe<(
          { __typename: 'EmptyConnectorProperties' }
          & Pick<EmptyConnectorProperties, 'empty'>
        ) | (
          { __typename: 'GustoConnectorProperties' }
          & Pick<GustoConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'PlaidConnectorProperties' }
          & Pick<PlaidConnectorProperties, 'empty'>
        ) | (
          { __typename: 'RampConnectorProperties' }
          & Pick<RampConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'StripeConnectorProperties' }
          & Pick<StripeConnectorProperties, 'appLinkURL'>
        )> }
      )> }
    ) } };

export type SyncConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
}>;


export type SyncConnectionMutation = Pick<Mutation, 'syncConnection'>;

export type UpdateConnectPlaidLinkTokenMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
}>;


export type UpdateConnectPlaidLinkTokenMutation = { updateConnectPlaidLinkToken: Pick<PlaidLinkToken, 'token'> };

export type ActivateRampConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
}>;


export type ActivateRampConnectionMutation = Pick<Mutation, 'activateRampConnection'>;

export type ArchiveConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
}>;


export type ArchiveConnectionMutation = Pick<Mutation, 'archiveConnection'>;

export type DestroyConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
}>;


export type DestroyConnectionMutation = Pick<Mutation, 'destroyConnection'>;

export type AutoConfigureConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
}>;


export type AutoConfigureConnectionMutation = Pick<Mutation, 'autoConfigureConnection'>;

export type UpdateConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
  settings: ConnectionSettingsInput;
}>;


export type UpdateConnectionMutation = Pick<Mutation, 'updateConnection'>;

export type UpdatePlaidConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
  accounts: Array<PlaidAccount> | PlaidAccount;
}>;


export type UpdatePlaidConnectionMutation = Pick<Mutation, 'updatePlaidConnection'>;

export type CreateStripeConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectorId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
}>;


export type CreateStripeConnectionMutation = { createStripeConnection: { connection: (
      Pick<Connection, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
      & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<(
        Pick<ConnectionAccount, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>
        & { settings?: Maybe<(
          Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
          & { labelCategories?: Maybe<Array<(
            Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
            & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        )> }
      )>>, connector?: Maybe<(
        Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
        & { connectorProperties?: Maybe<(
          { __typename: 'EmptyConnectorProperties' }
          & Pick<EmptyConnectorProperties, 'empty'>
        ) | (
          { __typename: 'GustoConnectorProperties' }
          & Pick<GustoConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'PlaidConnectorProperties' }
          & Pick<PlaidConnectorProperties, 'empty'>
        ) | (
          { __typename: 'RampConnectorProperties' }
          & Pick<RampConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'StripeConnectorProperties' }
          & Pick<StripeConnectorProperties, 'appLinkURL'>
        )> }
      )> }
    ) } };

export type CreatePlaidConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectorId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
  institutionName: Scalars['String']['input'];
  accounts: Array<PlaidAccount> | PlaidAccount;
}>;


export type CreatePlaidConnectionMutation = { createPlaidConnection: { connection: (
      Pick<Connection, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
      & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<(
        Pick<ConnectionAccount, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>
        & { settings?: Maybe<(
          Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
          & { labelCategories?: Maybe<Array<(
            Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
            & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        )> }
      )>>, connector?: Maybe<(
        Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
        & { connectorProperties?: Maybe<(
          { __typename: 'EmptyConnectorProperties' }
          & Pick<EmptyConnectorProperties, 'empty'>
        ) | (
          { __typename: 'GustoConnectorProperties' }
          & Pick<GustoConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'PlaidConnectorProperties' }
          & Pick<PlaidConnectorProperties, 'empty'>
        ) | (
          { __typename: 'RampConnectorProperties' }
          & Pick<RampConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'StripeConnectorProperties' }
          & Pick<StripeConnectorProperties, 'appLinkURL'>
        )> }
      )> }
    ) } };

export type CreateRampConnectionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectorId: Scalars['String']['input'];
  authorizationCode: Scalars['String']['input'];
}>;


export type CreateRampConnectionMutation = { createRampConnection: { connection: (
      Pick<Connection, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
      & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<(
        Pick<ConnectionAccount, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>
        & { settings?: Maybe<(
          Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
          & { labelCategories?: Maybe<Array<(
            Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
            & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        )> }
      )>>, connector?: Maybe<(
        Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
        & { connectorProperties?: Maybe<(
          { __typename: 'EmptyConnectorProperties' }
          & Pick<EmptyConnectorProperties, 'empty'>
        ) | (
          { __typename: 'GustoConnectorProperties' }
          & Pick<GustoConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'PlaidConnectorProperties' }
          & Pick<PlaidConnectorProperties, 'empty'>
        ) | (
          { __typename: 'RampConnectorProperties' }
          & Pick<RampConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'StripeConnectorProperties' }
          & Pick<StripeConnectorProperties, 'appLinkURL'>
        )> }
      )> }
    ) } };

export type CreateConnectPlaidLinkTokenMutationVariables = Exact<{
  routingNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateConnectPlaidLinkTokenMutation = { createConnectPlaidLinkToken: Pick<PlaidLinkToken, 'token'> };

export type UpdateConnectionAccountsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
  accounts: Array<ConnectionAccountInput> | ConnectionAccountInput;
}>;


export type UpdateConnectionAccountsMutation = Pick<Mutation, 'updateConnectionAccounts'>;

export type ConnectionFieldsFragment = (
  Pick<Connection, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
  & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<(
    Pick<ConnectionAccount, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>
    & { settings?: Maybe<(
      Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
      & { labelCategories?: Maybe<Array<(
        Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
        & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>> }
    )> }
  )>>, connector?: Maybe<(
    Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
    & { connectorProperties?: Maybe<(
      { __typename: 'EmptyConnectorProperties' }
      & Pick<EmptyConnectorProperties, 'empty'>
    ) | (
      { __typename: 'GustoConnectorProperties' }
      & Pick<GustoConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'PlaidConnectorProperties' }
      & Pick<PlaidConnectorProperties, 'empty'>
    ) | (
      { __typename: 'RampConnectorProperties' }
      & Pick<RampConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'StripeConnectorProperties' }
      & Pick<StripeConnectorProperties, 'appLinkURL'>
    )> }
  )> }
);

export type ConnectionAccountFieldsFragment = (
  Pick<ConnectionAccount, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>
  & { settings?: Maybe<(
    Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
    & { labelCategories?: Maybe<Array<(
      Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
      & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>> }
  )> }
);

export type ConnectionAccountSettingsFieldsFragment = (
  Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
  & { labelCategories?: Maybe<Array<(
    Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
    & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  )>> }
);

export type LabelCategoryFieldsFragment = (
  Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
  & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
);

export type CreateConnectionResponseFieldsFragment = { connection: (
    Pick<Connection, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
    & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<(
      Pick<ConnectionAccount, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>
      & { settings?: Maybe<(
        Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
        & { labelCategories?: Maybe<Array<(
          Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
          & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      )> }
    )>>, connector?: Maybe<(
      Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
      & { connectorProperties?: Maybe<(
        { __typename: 'EmptyConnectorProperties' }
        & Pick<EmptyConnectorProperties, 'empty'>
      ) | (
        { __typename: 'GustoConnectorProperties' }
        & Pick<GustoConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'PlaidConnectorProperties' }
        & Pick<PlaidConnectorProperties, 'empty'>
      ) | (
        { __typename: 'RampConnectorProperties' }
        & Pick<RampConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'StripeConnectorProperties' }
        & Pick<StripeConnectorProperties, 'appLinkURL'>
      )> }
    )> }
  ) };

export type ConnectionSummaryFieldsFragment = (
  Pick<ConnectionSummary, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
  & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<Pick<ConnectionAccountSummary, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>>>, connector?: Maybe<(
    Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
    & { connectorProperties?: Maybe<(
      { __typename: 'EmptyConnectorProperties' }
      & Pick<EmptyConnectorProperties, 'empty'>
    ) | (
      { __typename: 'GustoConnectorProperties' }
      & Pick<GustoConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'PlaidConnectorProperties' }
      & Pick<PlaidConnectorProperties, 'empty'>
    ) | (
      { __typename: 'RampConnectorProperties' }
      & Pick<RampConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'StripeConnectorProperties' }
      & Pick<StripeConnectorProperties, 'appLinkURL'>
    )> }
  )> }
);

export type ConnectionAccountSummaryFieldsFragment = Pick<ConnectionAccountSummary, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>;

export type ConnectionTemplateFieldsFragment = { connector: (
    Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
    & { connectorProperties?: Maybe<(
      { __typename: 'EmptyConnectorProperties' }
      & Pick<EmptyConnectorProperties, 'empty'>
    ) | (
      { __typename: 'GustoConnectorProperties' }
      & Pick<GustoConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'PlaidConnectorProperties' }
      & Pick<PlaidConnectorProperties, 'empty'>
    ) | (
      { __typename: 'RampConnectorProperties' }
      & Pick<RampConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'StripeConnectorProperties' }
      & Pick<StripeConnectorProperties, 'appLinkURL'>
    )> }
  ), institution: (
    Pick<ProductInstitution, 'id' | 'name' | 'products' | 'routingNumber'>
    & { party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
  ) };

export type SuggestedConnectionFieldsFragment = { template: { connector: (
      Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
      & { connectorProperties?: Maybe<(
        { __typename: 'EmptyConnectorProperties' }
        & Pick<EmptyConnectorProperties, 'empty'>
      ) | (
        { __typename: 'GustoConnectorProperties' }
        & Pick<GustoConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'PlaidConnectorProperties' }
        & Pick<PlaidConnectorProperties, 'empty'>
      ) | (
        { __typename: 'RampConnectorProperties' }
        & Pick<RampConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'StripeConnectorProperties' }
        & Pick<StripeConnectorProperties, 'appLinkURL'>
      )> }
    ), institution: (
      Pick<ProductInstitution, 'id' | 'name' | 'products' | 'routingNumber'>
      & { party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
    ) } };

export type ConnectionSyncStateFieldsFragment = Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>;

export type SuggestedConnectionsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  filter: SuggestedConnectionsFilter;
}>;


export type SuggestedConnectionsQuery = { suggestedConnections: Array<{ template: { connector: (
        Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
        & { connectorProperties?: Maybe<(
          { __typename: 'EmptyConnectorProperties' }
          & Pick<EmptyConnectorProperties, 'empty'>
        ) | (
          { __typename: 'GustoConnectorProperties' }
          & Pick<GustoConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'PlaidConnectorProperties' }
          & Pick<PlaidConnectorProperties, 'empty'>
        ) | (
          { __typename: 'RampConnectorProperties' }
          & Pick<RampConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'StripeConnectorProperties' }
          & Pick<StripeConnectorProperties, 'appLinkURL'>
        )> }
      ), institution: (
        Pick<ProductInstitution, 'id' | 'name' | 'products' | 'routingNumber'>
        & { party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
      ) } }> };

export type OnboardConnectionsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  institutionProducts: Array<InstitutionProduct> | InstitutionProduct;
}>;


export type OnboardConnectionsQuery = { onboardConnections: Array<{ template: { connector: (
        Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
        & { connectorProperties?: Maybe<(
          { __typename: 'EmptyConnectorProperties' }
          & Pick<EmptyConnectorProperties, 'empty'>
        ) | (
          { __typename: 'GustoConnectorProperties' }
          & Pick<GustoConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'PlaidConnectorProperties' }
          & Pick<PlaidConnectorProperties, 'empty'>
        ) | (
          { __typename: 'RampConnectorProperties' }
          & Pick<RampConnectorProperties, 'authorizationURL'>
        ) | (
          { __typename: 'StripeConnectorProperties' }
          & Pick<StripeConnectorProperties, 'appLinkURL'>
        )> }
      ), institution: (
        Pick<ProductInstitution, 'id' | 'name' | 'products' | 'routingNumber'>
        & { party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
      ) } }> };

export type ListConnectionsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListConnectionsQuery = { listConnections: Array<(
    Pick<ConnectionSummary, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
    & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<Pick<ConnectionAccountSummary, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>>>, connector?: Maybe<(
      Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
      & { connectorProperties?: Maybe<(
        { __typename: 'EmptyConnectorProperties' }
        & Pick<EmptyConnectorProperties, 'empty'>
      ) | (
        { __typename: 'GustoConnectorProperties' }
        & Pick<GustoConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'PlaidConnectorProperties' }
        & Pick<PlaidConnectorProperties, 'empty'>
      ) | (
        { __typename: 'RampConnectorProperties' }
        & Pick<RampConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'StripeConnectorProperties' }
        & Pick<StripeConnectorProperties, 'appLinkURL'>
      )> }
    )> }
  )> };

export type FindConnectionsByInstitutionQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  institutionName: Scalars['String']['input'];
}>;


export type FindConnectionsByInstitutionQuery = { findConnectionsByInstitution: Array<(
    Pick<ConnectionSummary, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
    & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<Pick<ConnectionAccountSummary, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>>>, connector?: Maybe<(
      Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
      & { connectorProperties?: Maybe<(
        { __typename: 'EmptyConnectorProperties' }
        & Pick<EmptyConnectorProperties, 'empty'>
      ) | (
        { __typename: 'GustoConnectorProperties' }
        & Pick<GustoConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'PlaidConnectorProperties' }
        & Pick<PlaidConnectorProperties, 'empty'>
      ) | (
        { __typename: 'RampConnectorProperties' }
        & Pick<RampConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'StripeConnectorProperties' }
        & Pick<StripeConnectorProperties, 'appLinkURL'>
      )> }
    )> }
  )> };

export type ListConnectorsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListConnectorsQuery = { listConnectors: Array<(
    Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
    & { connectorProperties?: Maybe<(
      { __typename: 'EmptyConnectorProperties' }
      & Pick<EmptyConnectorProperties, 'empty'>
    ) | (
      { __typename: 'GustoConnectorProperties' }
      & Pick<GustoConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'PlaidConnectorProperties' }
      & Pick<PlaidConnectorProperties, 'empty'>
    ) | (
      { __typename: 'RampConnectorProperties' }
      & Pick<RampConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'StripeConnectorProperties' }
      & Pick<StripeConnectorProperties, 'appLinkURL'>
    )> }
  )> };

export type ListPaymentConnectorsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListPaymentConnectorsQuery = { listConnectors: Array<(
    Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
    & { connectorProperties?: Maybe<(
      { __typename: 'EmptyConnectorProperties' }
      & Pick<EmptyConnectorProperties, 'empty'>
    ) | (
      { __typename: 'GustoConnectorProperties' }
      & Pick<GustoConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'PlaidConnectorProperties' }
      & Pick<PlaidConnectorProperties, 'empty'>
    ) | (
      { __typename: 'RampConnectorProperties' }
      & Pick<RampConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'StripeConnectorProperties' }
      & Pick<StripeConnectorProperties, 'appLinkURL'>
    )> }
  )> };

export type ListAuthConnectionsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListAuthConnectionsQuery = { listAuthConnections: Array<(
    Pick<ConnectionSummary, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
    & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<Pick<ConnectionAccountSummary, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>>>, connector?: Maybe<(
      Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
      & { connectorProperties?: Maybe<(
        { __typename: 'EmptyConnectorProperties' }
        & Pick<EmptyConnectorProperties, 'empty'>
      ) | (
        { __typename: 'GustoConnectorProperties' }
        & Pick<GustoConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'PlaidConnectorProperties' }
        & Pick<PlaidConnectorProperties, 'empty'>
      ) | (
        { __typename: 'RampConnectorProperties' }
        & Pick<RampConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'StripeConnectorProperties' }
        & Pick<StripeConnectorProperties, 'appLinkURL'>
      )> }
    )> }
  )> };

export type ReadConnectionQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
}>;


export type ReadConnectionQuery = { readConnection?: Maybe<(
    Pick<Connection, 'id' | 'dataSourceId' | 'sourceName' | 'sourceIconURL' | 'description' | 'status'>
    & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>>, accounts?: Maybe<Array<(
      Pick<ConnectionAccount, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>
      & { settings?: Maybe<(
        Pick<ConnectionAccountSettings, 'enabled' | 'cutoverDate'>
        & { labelCategories?: Maybe<Array<(
          Pick<LabelCategory, 'label' | 'defaultCategoryType' | 'validCategoryTypes' | 'displayName' | 'required' | 'tooltip'>
          & { category?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      )> }
    )>>, connector?: Maybe<(
      Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
      & { connectorProperties?: Maybe<(
        { __typename: 'EmptyConnectorProperties' }
        & Pick<EmptyConnectorProperties, 'empty'>
      ) | (
        { __typename: 'GustoConnectorProperties' }
        & Pick<GustoConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'PlaidConnectorProperties' }
        & Pick<PlaidConnectorProperties, 'empty'>
      ) | (
        { __typename: 'RampConnectorProperties' }
        & Pick<RampConnectorProperties, 'authorizationURL'>
      ) | (
        { __typename: 'StripeConnectorProperties' }
        & Pick<StripeConnectorProperties, 'appLinkURL'>
      )> }
    )> }
  )> };

export type CategorySuggestionFieldsFragment = Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>;

export type MinMaxAmountFieldsFragment = { minAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, maxAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> };

export type ListBulkCategorySuggestionsResponseFieldsFragment = { transactionCategorySuggestions: Array<(
    Pick<TransactionCategorySuggestions, 'transactionFactId'>
    & { categorySuggestions: Array<Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>> }
  )> };

export type TransactionCategorySuggestionsFieldsFragment = (
  Pick<TransactionCategorySuggestions, 'transactionFactId'>
  & { categorySuggestions: Array<Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>> }
);

export type LedgerTransactionStaleFieldsFragment = Pick<LedgerTransactionStale, 'stale' | 'newViewAvailable'>;

export type LedgerTransactionFieldsFragment = (
  Pick<LedgerTransaction, 'timestamp' | 'externalId' | 'type' | 'memo' | 'referenceNumber' | 'isMetadataEditable' | 'ledgerTransactionId' | 'compileSource'>
  & { lines: Array<(
    Pick<TransactionLine, 'entryType'>
    & { entry: (
      Pick<TransactionEntry, 'factId' | 'externalLineId' | 'description'>
      & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }
  )>, sources: Array<(
    Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
    & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
  )> }
);

export type MetricEquationFieldsFragment = { terms: Array<(
    { __typename: 'MetricCategoriesOperand' }
    & Pick<MetricCategoriesOperand, 'name' | 'isBalance' | 'naturalFlow' | 'defaultCategoryType'>
    & { categories: Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, period?: Maybe<Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>> }
  ) | (
    { __typename: 'MetricOperation' }
    & Pick<MetricOperation, 'symbol'>
  ) | (
    { __typename: 'MetricSummaryTypeOperand' }
    & Pick<MetricSummaryTypeOperand, 'name' | 'type' | 'naturalFlow' | 'defaultCategoryType' | 'isBalance'>
    & { period?: Maybe<Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>> }
  )> };

export type MetricTermFields_MetricCategoriesOperand_Fragment = (
  { __typename: 'MetricCategoriesOperand' }
  & Pick<MetricCategoriesOperand, 'name' | 'isBalance' | 'naturalFlow' | 'defaultCategoryType'>
  & { categories: Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, period?: Maybe<Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>> }
);

export type MetricTermFields_MetricOperation_Fragment = (
  { __typename: 'MetricOperation' }
  & Pick<MetricOperation, 'symbol'>
);

export type MetricTermFields_MetricSummaryTypeOperand_Fragment = (
  { __typename: 'MetricSummaryTypeOperand' }
  & Pick<MetricSummaryTypeOperand, 'name' | 'type' | 'naturalFlow' | 'defaultCategoryType' | 'isBalance'>
  & { period?: Maybe<Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>> }
);

export type MetricTermFieldsFragment = MetricTermFields_MetricCategoriesOperand_Fragment | MetricTermFields_MetricOperation_Fragment | MetricTermFields_MetricSummaryTypeOperand_Fragment;

export type MetricCategoriesOperandFieldsFragment = (
  Pick<MetricCategoriesOperand, 'name' | 'isBalance' | 'naturalFlow' | 'defaultCategoryType'>
  & { categories: Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, period?: Maybe<Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>> }
);

export type MetricSummaryTypeOperandFieldsFragment = (
  Pick<MetricSummaryTypeOperand, 'name' | 'type' | 'naturalFlow' | 'defaultCategoryType' | 'isBalance'>
  & { period?: Maybe<Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>> }
);

export type MetricOperationFieldsFragment = Pick<MetricOperation, 'symbol'>;

export type UpdateTransactionFieldsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
  updates: Array<InputUpdateFields> | InputUpdateFields;
}>;


export type UpdateTransactionFieldsMutation = Pick<Mutation, 'updateTransactionFields'>;

export type CreateTransactionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  transaction: InputTransaction;
}>;


export type CreateTransactionMutation = { createTransaction?: Maybe<Pick<CreateTransactionResponse, 'transactionFactIds'>> };

export type DeleteTransactionsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  factIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteTransactionsMutation = Pick<Mutation, 'deleteTransactions'>;

export type UploadBankFeedEntriesMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  categoryId: Scalars['String']['input'];
  bankFeedEntries: Array<BankFeedEntry> | BankFeedEntry;
}>;


export type UploadBankFeedEntriesMutation = { uploadBankFeedEntries?: Maybe<Pick<UploadBankFeedResponse, 'transactionFactIds'>> };

export type UploadJournalEntriesMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  transactions: Array<InputTransaction> | InputTransaction;
}>;


export type UploadJournalEntriesMutation = { uploadJournalEntries?: Maybe<Pick<UploadJournalEntriesResponse, 'transactionFactIds'>> };

export type UpdateTransactionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  transaction: InputTransaction;
  filter: TransactionFilter;
}>;


export type UpdateTransactionMutation = Pick<Mutation, 'updateTransaction'>;

export type ListTransactionsQueryVariables = Exact<{
  pagination: Pagination;
  paginationDirection: DirectionFromOrigin;
  sortColumnName: TransactionColumnName;
  sortDirection: SortDirection;
  filter: TransactionFilter;
  intervalOrigin: IntervalOrigin;
  naturalFlow?: InputMaybe<Flow>;
}>;


export type ListTransactionsQuery = { listTransactions: Array<(
    Pick<Transaction, 'viewId' | 'factId' | 'supersededFactIds' | 'timestamp' | 'recordType' | 'name' | 'description' | 'digitsTransactionType' | 'partySource' | 'displayEntry' | 'displayCategorySource' | 'displayCanonicalCategoryName' | 'displayUncategorizedReason' | 'displayProductArea' | 'ownerSource' | 'transactionClassificationType' | 'compileSource' | 'reconciliationStatus' | 'referenceNumber' | 'taxStatus'>
    & { party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, facilitatorParty?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, product?: Maybe<Pick<Product, 'id' | 'name'>>, department?: Maybe<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>, location?: Maybe<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>, providers: Array<Pick<Provider, 'name'>>, recurrence?: Maybe<Pick<TransactionRecurrence, 'recurrenceThreadId' | 'interval' | 'intervalMultiplier' | 'threadIndex' | 'totalThreadCount' | 'isHidden' | 'firstOccurredAt' | 'lastOccurredAt'>>, displayInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, displayCategory: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ), splitCategory?: Maybe<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )>, splitInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), runningTotal?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, owner?: Maybe<Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>>, sources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )>, creditSources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )>, debitSources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )> }
  )> };

export type SummarizeTransactionsByCategoryByTimeQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  sort?: InputMaybe<DimensionSortMode>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  masks?: InputMaybe<Array<Array<SummaryDimension> | SummaryDimension> | Array<SummaryDimension> | SummaryDimension>;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SummarizeTransactionsByCategoryByTimeQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }
  ) };

export type ReadTransactionFactIdQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  externalLineItemId: Scalars['String']['input'];
}>;


export type ReadTransactionFactIdQuery = Pick<Query, 'readFactId'>;

export type SummarizeTransactionsByTimeByPartyQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  sort?: InputMaybe<DimensionSortMode>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  masks?: InputMaybe<Array<Array<SummaryDimension> | SummaryDimension> | Array<SummaryDimension> | SummaryDimension>;
  matchBy?: InputMaybe<Matcher>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeTransactionsByTimeByPartyQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ), by: { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }
  ) };

export type SummarizeTransactionsByCategoryQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  sort?: InputMaybe<DimensionSortMode>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  naturalFlow?: InputMaybe<Flow>;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeTransactionsByCategoryQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) };

export type SummarizeTransactionsByPartyQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  matchBy?: InputMaybe<Matcher>;
  masks?: InputMaybe<Array<Array<SummaryDimension> | SummaryDimension> | Array<SummaryDimension> | SummaryDimension>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  naturalFlow?: InputMaybe<Flow>;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeTransactionsByPartyQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) };

export type ReadCategorySuggestionsQueryVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  entryType: EntryType;
}>;


export type ReadCategorySuggestionsQuery = { readCategorySuggestions: Array<Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>> };

export type SummarizeTransactionsByPartyByTimeQueryVariables = Exact<{
  filter: SummaryFilterInput;
  paginationDirection: DirectionFromOrigin;
  intervalOrigin: IntervalOrigin;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  masks?: InputMaybe<Array<Array<SummaryDimension> | SummaryDimension> | Array<SummaryDimension> | SummaryDimension>;
  matchBy?: InputMaybe<Matcher>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  naturalFlow?: InputMaybe<Flow>;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeTransactionsByPartyByTimeQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }
  ) };

export type SummarizeTransactionsByDepartmentByTimeQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SummarizeTransactionsByDepartmentByTimeQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { department: Array<(
      Pick<DepartmentDimension, 'departmentId'>
      & { departmentObject: (
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      ), by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }
    )> }
  ) };

export type ListSimilarTransactionsQueryVariables = Exact<{
  viewKey: ViewKey;
  factId: Scalars['String']['input'];
  entryType: EntryType;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  numNeighbors?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListSimilarTransactionsQuery = { listSimilarTransactions: Array<(
    Pick<Transaction, 'viewId' | 'factId' | 'supersededFactIds' | 'timestamp' | 'recordType' | 'name' | 'description' | 'digitsTransactionType' | 'partySource' | 'displayEntry' | 'displayCategorySource' | 'displayCanonicalCategoryName' | 'displayUncategorizedReason' | 'displayProductArea' | 'ownerSource' | 'transactionClassificationType' | 'compileSource' | 'reconciliationStatus' | 'referenceNumber' | 'taxStatus'>
    & { party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, facilitatorParty?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, product?: Maybe<Pick<Product, 'id' | 'name'>>, department?: Maybe<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>, location?: Maybe<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>, providers: Array<Pick<Provider, 'name'>>, recurrence?: Maybe<Pick<TransactionRecurrence, 'recurrenceThreadId' | 'interval' | 'intervalMultiplier' | 'threadIndex' | 'totalThreadCount' | 'isHidden' | 'firstOccurredAt' | 'lastOccurredAt'>>, displayInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, displayCategory: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ), splitCategory?: Maybe<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )>, splitInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), runningTotal?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, owner?: Maybe<Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>>, sources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )>, creditSources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )>, debitSources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )> }
  )> };

export type SummarizeTransactionsByTimeByCategoryQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  sort: DimensionSortMode;
  limit: Scalars['Int']['input'];
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SummarizeTransactionsByTimeByCategoryQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ by: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }
  ) };

export type ExportTransactionsQueryVariables = Exact<{
  filter: TransactionFilter;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  fileName?: InputMaybe<Scalars['String']['input']>;
  temporary?: InputMaybe<Scalars['Boolean']['input']>;
  singleColumnAmount?: InputMaybe<Scalars['Boolean']['input']>;
  fields?: InputMaybe<Array<CsvExportField> | CsvExportField>;
  orderPairs?: InputMaybe<Array<TransactionOrderPair> | TransactionOrderPair>;
}>;


export type ExportTransactionsQuery = { exportTransactions: Pick<DocumentInitialSegment, 'collectionId' | 'documentId' | 'downloadUrl'> };

export type ListTransactionsByFactIdsQueryVariables = Exact<{
  factIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  viewKey: ViewKey;
}>;


export type ListTransactionsByFactIdsQuery = { listTransactionsByFactIds: Array<(
    Pick<Transaction, 'viewId' | 'factId' | 'supersededFactIds' | 'timestamp' | 'recordType' | 'name' | 'description' | 'digitsTransactionType' | 'partySource' | 'displayEntry' | 'displayCategorySource' | 'displayCanonicalCategoryName' | 'displayUncategorizedReason' | 'displayProductArea' | 'ownerSource' | 'transactionClassificationType' | 'compileSource' | 'reconciliationStatus' | 'referenceNumber' | 'taxStatus'>
    & { party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, facilitatorParty?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, product?: Maybe<Pick<Product, 'id' | 'name'>>, department?: Maybe<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>, location?: Maybe<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>, providers: Array<Pick<Provider, 'name'>>, recurrence?: Maybe<Pick<TransactionRecurrence, 'recurrenceThreadId' | 'interval' | 'intervalMultiplier' | 'threadIndex' | 'totalThreadCount' | 'isHidden' | 'firstOccurredAt' | 'lastOccurredAt'>>, displayInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, displayCategory: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ), splitCategory?: Maybe<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )>, splitInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), runningTotal?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, owner?: Maybe<Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>>, sources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )>, creditSources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )>, debitSources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )> }
  )> };

export type ReadTransactionDetailsQueryVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
  filter: TransactionFilter;
}>;


export type ReadTransactionDetailsQuery = { readTransactionDetails: (
    Pick<TransactionWithDetails, 'isInBooksClosedPeriod'>
    & { transaction: (
      Pick<Transaction, 'viewId' | 'factId' | 'supersededFactIds' | 'timestamp' | 'recordType' | 'name' | 'description' | 'digitsTransactionType' | 'partySource' | 'displayEntry' | 'displayCategorySource' | 'displayCanonicalCategoryName' | 'displayUncategorizedReason' | 'displayProductArea' | 'ownerSource' | 'transactionClassificationType' | 'compileSource' | 'reconciliationStatus' | 'referenceNumber' | 'taxStatus'>
      & { party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, facilitatorParty?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, product?: Maybe<Pick<Product, 'id' | 'name'>>, department?: Maybe<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>, location?: Maybe<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>, providers: Array<Pick<Provider, 'name'>>, recurrence?: Maybe<Pick<TransactionRecurrence, 'recurrenceThreadId' | 'interval' | 'intervalMultiplier' | 'threadIndex' | 'totalThreadCount' | 'isHidden' | 'firstOccurredAt' | 'lastOccurredAt'>>, displayInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, displayCategory: (
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      ), splitCategory?: Maybe<(
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      )>, splitInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), runningTotal?: Maybe<(
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      )>, owner?: Maybe<Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>>, sources: Array<(
        Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
        & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
      )>, creditSources: Array<(
        Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
        & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
      )>, debitSources: Array<(
        Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
        & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
      )> }
    ), details: Array<(
      Pick<TransactionViewDetail, 'id' | 'factId' | 'occurredAt' | 'externalCreatedAt' | 'externalId' | 'externalDisplayId' | 'quickbooksAppName' | 'name' | 'description' | 'recordType'>
      & { creditAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, creditCategoryHierarchy: Array<(
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      )>, debitCategoryHierarchy: Array<(
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      )>, entryLines: Array<(
        Pick<TransactionEntryLine, 'lineId' | 'name' | 'description' | 'isCredit' | 'highlighted'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, category: (
          Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
          & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
        ) }
      )>, party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
    )> }
  ) };

export type SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SummarizeTransactionsByCategoryHierarchyByTimeQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }
  ) };

export type SummarizeCategoryTransactionsByTimeQueryVariables = Exact<{
  filter: SummaryFilterInput;
  origin: IntervalOrigin;
  direction: DirectionFromOrigin;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  naturalFlow?: InputMaybe<Flow>;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeCategoryTransactionsByTimeQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) };

export type ListMetricTransactionsQueryVariables = Exact<{
  viewKey: ViewKey;
  metricSummaryType?: InputMaybe<MetricSummaryItem>;
  summaryLineType?: InputMaybe<SummaryLineItem>;
  pagination: Pagination;
  sortColumnName: TransactionColumnName;
  sortDirection: SortDirection;
  intervalOrigin: IntervalOrigin;
}>;


export type ListMetricTransactionsQuery = { listTransactions: Array<(
    Pick<Transaction, 'viewId' | 'factId' | 'supersededFactIds' | 'timestamp' | 'recordType' | 'name' | 'description' | 'digitsTransactionType' | 'partySource' | 'displayEntry' | 'displayCategorySource' | 'displayCanonicalCategoryName' | 'displayUncategorizedReason' | 'displayProductArea' | 'ownerSource' | 'transactionClassificationType' | 'compileSource' | 'reconciliationStatus' | 'referenceNumber' | 'taxStatus'>
    & { party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, facilitatorParty?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, product?: Maybe<Pick<Product, 'id' | 'name'>>, department?: Maybe<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>, location?: Maybe<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>, providers: Array<Pick<Provider, 'name'>>, recurrence?: Maybe<Pick<TransactionRecurrence, 'recurrenceThreadId' | 'interval' | 'intervalMultiplier' | 'threadIndex' | 'totalThreadCount' | 'isHidden' | 'firstOccurredAt' | 'lastOccurredAt'>>, displayInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, displayCategory: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ), splitCategory?: Maybe<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )>, splitInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), runningTotal?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, owner?: Maybe<Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>>, sources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )>, creditSources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )>, debitSources: Array<(
      Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
      & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
    )> }
  )> };

export type ReadLedgerTransactionByIdQueryVariables = Exact<{
  ledgerTransactionId: Scalars['String']['input'];
  viewKey: ViewKey;
}>;


export type ReadLedgerTransactionByIdQuery = { readLedgerTransactionById: { transaction: (
      Pick<LedgerTransaction, 'timestamp' | 'externalId' | 'type' | 'memo' | 'referenceNumber' | 'isMetadataEditable' | 'ledgerTransactionId' | 'compileSource'>
      & { lines: Array<(
        Pick<TransactionLine, 'entryType'>
        & { entry: (
          Pick<TransactionEntry, 'factId' | 'externalLineId' | 'description'>
          & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }
      )>, sources: Array<(
        Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
        & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
      )> }
    ) } };

export type SummarizeMetricTransactionsByTimeQueryVariables = Exact<{
  viewKey: ViewKey;
  origin: IntervalOrigin;
  metricSummaryType?: InputMaybe<MetricSummaryItem>;
  summaryLineType?: InputMaybe<SummaryLineItem>;
}>;


export type SummarizeMetricTransactionsByTimeQuery = { metricTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) };

export type MinMaxAmountQueryVariables = Exact<{
  filter: TransactionFilter;
  origin: IntervalOrigin;
  direction: DirectionFromOrigin;
}>;


export type MinMaxAmountQuery = { minMaxAmount: { minAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, maxAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> } };

export type ListBulkCategorySuggestionsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  transactionFacts: Array<TransactionFactEntryType> | TransactionFactEntryType;
}>;


export type ListBulkCategorySuggestionsQuery = { listBulkCategorySuggestions: { transactionCategorySuggestions: Array<(
      Pick<TransactionCategorySuggestions, 'transactionFactId'>
      & { categorySuggestions: Array<Pick<CategorySuggestion, 'dataSourceId' | 'transactionFactId' | 'classificationVersionId' | 'categoryId' | 'type' | 'modelConfidence' | 'modelVersionId' | 'priority' | 'createdAt' | 'updatedAt'>> }
    )> } };

export type ReadLedgerTransactionQueryVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
  filter: TransactionFilter;
}>;


export type ReadLedgerTransactionQuery = { readLedgerTransaction: { transaction: (
      Pick<LedgerTransaction, 'timestamp' | 'externalId' | 'type' | 'memo' | 'referenceNumber' | 'isMetadataEditable' | 'ledgerTransactionId' | 'compileSource'>
      & { lines: Array<(
        Pick<TransactionLine, 'entryType'>
        & { entry: (
          Pick<TransactionEntry, 'factId' | 'externalLineId' | 'description'>
          & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }
      )>, sources: Array<(
        Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
        & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
      )> }
    ) } };

export type SummarizeTransactionsByTimeByPartyByCategoryQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  sort: DimensionSortMode;
  limit: Scalars['Int']['input'];
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  masks?: InputMaybe<Array<Array<SummaryDimension> | SummaryDimension> | Array<SummaryDimension> | SummaryDimension>;
  matchBy?: InputMaybe<Matcher>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SummarizeTransactionsByTimeByPartyByCategoryQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ by: { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ), by: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'> }> } }> } }> }
  ) };

export type SummarizeTransactionsByPartyByCategoryQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  matchBy?: InputMaybe<Matcher>;
  masks?: InputMaybe<Array<Array<SummaryDimension> | SummaryDimension> | Array<SummaryDimension> | SummaryDimension>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeTransactionsByPartyByCategoryQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ), by: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'> }> } }> }
  ) };

export type ListTransactionEditingHistoryQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  factId: Scalars['String']['input'];
}>;


export type ListTransactionEditingHistoryQuery = { listTransactionEditingHistory: Array<(
    Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
    & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
  )> };

export type CheckLedgerTransactionStaleQueryVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
}>;


export type CheckLedgerTransactionStaleQuery = { checkLedgerTransactionStale: Pick<LedgerTransactionStale, 'stale' | 'newViewAvailable'> };

export type TransactionDashboardDimensionalSummaryQueryVariables = Exact<{
  viewKey: ViewKey;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
}>;


export type TransactionDashboardDimensionalSummaryQuery = { assetsSummary: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }, liabilitiesSummary: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }, equitySummary: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }, incomeSummary: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }, costOfGoodsSoldSummary: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }, expensesSummary: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }, otherExpensesSummary: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> }, otherIncomeSummary: { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }> } };

export type ReadTransactionWorkItemIdQueryVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadTransactionWorkItemIdQuery = Pick<Query, 'readTransactionWorkItemId'>;

export type SummarizeTransactionsByTimeQueryVariables = Exact<{
  filter: SummaryFilterInput;
  origin: IntervalOrigin;
  direction: DirectionFromOrigin;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  naturalFlow?: InputMaybe<Flow>;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeTransactionsByTimeQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { time: Array<{ summary: (
        Pick<TransactionSummary, 'isPeriodBookClosed'>
        & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
          Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          )>, current?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )>, prior?: Maybe<(
            Pick<SummaryTotal, 'count'>
            & { total: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ) }
          )> }
        ) }
      ) }> }
  ) };

export type ReadMetricEquationQueryVariables = Exact<{
  viewKey: ViewKey;
  summaryLineType?: InputMaybe<SummaryLineItem>;
  metricSummaryType?: InputMaybe<MetricSummaryItem>;
  layoutComponentConfig?: InputMaybe<LayoutComponentConfigInput>;
  origin?: InputMaybe<IntervalOrigin>;
}>;


export type ReadMetricEquationQuery = { readMetricEquation: { terms: Array<(
      { __typename: 'MetricCategoriesOperand' }
      & Pick<MetricCategoriesOperand, 'name' | 'isBalance' | 'naturalFlow' | 'defaultCategoryType'>
      & { categories: Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, period?: Maybe<Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>> }
    ) | (
      { __typename: 'MetricOperation' }
      & Pick<MetricOperation, 'symbol'>
    ) | (
      { __typename: 'MetricSummaryTypeOperand' }
      & Pick<MetricSummaryTypeOperand, 'name' | 'type' | 'naturalFlow' | 'defaultCategoryType' | 'isBalance'>
      & { period?: Maybe<Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>> }
    )> } };

export type SummarizeTransactionsWithoutDimensionQueryVariables = Exact<{
  filter: SummaryFilterInput;
  origin: IntervalOrigin;
  direction: DirectionFromOrigin;
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  naturalFlow?: InputMaybe<Flow>;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeTransactionsWithoutDimensionQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { summary: (
      Pick<TransactionSummary, 'isPeriodBookClosed'>
      & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
        Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        )>, current?: Maybe<(
          Pick<SummaryTotal, 'count'>
          & { total: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, prior?: Maybe<(
          Pick<SummaryTotal, 'count'>
          & { total: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )> }
      ) }
    ) }
  ) };

export type SummarizeTransactionsByLocationByTimeQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction: DirectionFromOrigin;
  origin: IntervalOrigin;
  directBookings?: InputMaybe<Scalars['Boolean']['input']>;
  naturalFlow: Flow;
  defaultCategoryType?: InputMaybe<CategoryType>;
}>;


export type SummarizeTransactionsByLocationByTimeQuery = { dimensionalTransactionSummary: (
    Pick<DimensionSummary, 'partialData'>
    & { location: Array<(
      Pick<LocationDimension, 'locationId'>
      & { locationObject: (
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      ), by: { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> } }
    )> }
  ) };

export type LegalEntityBadgeCountFieldsFragment = Pick<LegalEntityBadgeCount, 'legalEntityId' | 'count'>;

export type ListActionItemsBadgeCountsQueryVariables = Exact<{
  direction?: InputMaybe<DirectionFromOrigin>;
  filter: ActionItemFilter;
  legalEntityIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  legalEntities?: InputMaybe<Array<InputMaybe<LegalEntityPermission>> | InputMaybe<LegalEntityPermission>>;
  origin: IntervalOrigin;
}>;


export type ListActionItemsBadgeCountsQuery = { listActionItemsBadgeCounts: Array<Pick<LegalEntityBadgeCount, 'legalEntityId' | 'count'>> };

export type ListReportsBadgeCountsQueryVariables = Exact<{
  direction: DirectionFromOrigin;
  legalEntityIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  origin: IntervalOrigin;
}>;


export type ListReportsBadgeCountsQuery = { listReportsBadgeCounts: Array<Pick<LegalEntityBadgeCount, 'legalEntityId' | 'count'>> };

export type ListDataSourcesBadgeCountsQueryVariables = Exact<{
  legalEntityIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ListDataSourcesBadgeCountsQuery = { listDataSourcesBadgeCounts: Array<Pick<LegalEntityBadgeCount, 'legalEntityId' | 'count'>> };

export type ListConnectionBadgeCountsQueryVariables = Exact<{
  filter: ConnectionBadgeCountFilter;
}>;


export type ListConnectionBadgeCountsQuery = { listConnectionBadgeCounts: Array<Pick<LegalEntityBadgeCount, 'legalEntityId' | 'count'>> };

export type ListBoostBadgeCountsQueryVariables = Exact<{
  direction: DirectionFromOrigin;
  filter: WorkItemBadgeCountFilter;
  origin: IntervalOrigin;
}>;


export type ListBoostBadgeCountsQuery = { listBoostBadgeCounts: Array<Pick<LegalEntityBadgeCount, 'legalEntityId' | 'count'>> };

export type UpdateCategoryMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
  category: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = Pick<Mutation, 'updateCategory'>;

export type UpdateCategoryStatusMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  viewKey: ViewKey;
  categoryId: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
}>;


export type UpdateCategoryStatusMutation = Pick<Mutation, 'updateCategoryStatus'>;

export type CreateCategoryMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  category: CreateCategoryInput;
}>;


export type CreateCategoryMutation = { createCategory: Pick<CreateCategoryResponse, 'categoryId'> };

export type CategoryIntegrationsFieldsFragment = (
  Pick<CategoryIntegrations, 'categoryId'>
  & { connect: Array<{ connectionAccountSummaries: Array<Pick<ConnectionAccountSummary, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }>, pay?: Maybe<(
    Pick<PayCategoryIntegrations, 'products'>
    & { party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }
  )> }
);

export type ConnectionCategoryIntegrationsFieldsFragment = { connectionAccountSummaries: Array<Pick<ConnectionAccountSummary, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> };

export type PayCategoryIntegrationsFieldsFragment = (
  Pick<PayCategoryIntegrations, 'products'>
  & { party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }
);

export type ListCategoriesQueryVariables = Exact<{
  viewKey: ViewKey;
  categoryType?: InputMaybe<CategoryType>;
}>;


export type ListCategoriesQuery = { listCategories: Array<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )> };

export type ListCategoryIntegrationsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListCategoryIntegrationsQuery = { listCategoryIntegrations: Array<(
    Pick<CategoryIntegrations, 'categoryId'>
    & { connect: Array<{ connectionAccountSummaries: Array<Pick<ConnectionAccountSummary, 'id' | 'connectionId' | 'sourceAccountId' | 'name' | 'mask'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }>, pay?: Maybe<(
      Pick<PayCategoryIntegrations, 'products'>
      & { party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }
    )> }
  )> };

export type GrantTeamMutationVariables = Exact<{
  grants: Array<NewObjectGrant> | NewObjectGrant;
  teamId: Scalars['String']['input'];
}>;


export type GrantTeamMutation = Pick<Mutation, 'grantTeam'>;

export type InviteTeamMembersMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  emailAddresses: Array<Scalars['String']['input']> | Scalars['String']['input'];
  role: TeamRole;
}>;


export type InviteTeamMembersMutation = Pick<Mutation, 'inviteTeamMembers'>;

export type AddTeamMembersMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  role: TeamRole;
}>;


export type AddTeamMembersMutation = Pick<Mutation, 'addTeamMembers'>;

export type DeleteTeamMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
}>;


export type DeleteTeamMutation = Pick<Mutation, 'deleteTeam'>;

export type CreateTeamMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateTeamMutation = { createTeam: (
    Pick<Team, 'id' | 'name' | 'description' | 'iconObjectName'>
    & { members: Array<(
      Pick<TeamMember, 'role'>
      & { user: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    )>, invites: Array<Pick<TeamMemberInvite, 'emailAddress' | 'role'>> }
  ) };

export type RevokeTeamGrantMutationVariables = Exact<{
  teamGrantId: Scalars['String']['input'];
}>;


export type RevokeTeamGrantMutation = Pick<Mutation, 'revokeTeamGrant'>;

export type RemoveTeamMemberMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type RemoveTeamMemberMutation = Pick<Mutation, 'removeTeamMember'>;

export type UpdateTeamMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  iconBase64?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateTeamMutation = Pick<Mutation, 'updateTeam'>;

export type TeamFieldsFragment = (
  Pick<Team, 'id' | 'name' | 'description' | 'iconObjectName'>
  & { members: Array<(
    Pick<TeamMember, 'role'>
    & { user: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
  )>, invites: Array<Pick<TeamMemberInvite, 'emailAddress' | 'role'>> }
);

export type TeamMemberFieldsFragment = (
  Pick<TeamMember, 'role'>
  & { user: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
);

export type TeamMemberInviteFieldsFragment = Pick<TeamMemberInvite, 'emailAddress' | 'role'>;

export type ListTeamsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  memberOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ListTeamsQuery = { listTeams: Array<(
    Pick<Team, 'id' | 'name' | 'description' | 'iconObjectName'>
    & { members: Array<(
      Pick<TeamMember, 'role'>
      & { user: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    )>, invites: Array<Pick<TeamMemberInvite, 'emailAddress' | 'role'>> }
  )> };

export type ReadTeamQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
}>;


export type ReadTeamQuery = { readTeam: (
    Pick<Team, 'id' | 'name' | 'description' | 'iconObjectName'>
    & { members: Array<(
      Pick<TeamMember, 'role'>
      & { user: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    )>, invites: Array<Pick<TeamMemberInvite, 'emailAddress' | 'role'>> }
  ) };

export type AffiliateUserFieldsFragment = { user: (
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  ), role: Pick<Role, 'id'> };

export type AffiliateOrganizationFieldsFragment = (
  Pick<AffiliateOrganization, 'type'>
  & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, affiliatedUsers: Array<{ user: (
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    ), role: Pick<Role, 'id'> }> }
);

export type AffiliateOrganizationsFieldsFragment = { organizations: Array<(
    Pick<AffiliateOrganization, 'type'>
    & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, affiliatedUsers: Array<{ user: (
        Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
        & { employments?: Maybe<Array<(
          Pick<OrganizationEmployment, 'employeeId'>
          & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
        )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
      ), role: Pick<Role, 'id'> }> }
  )>, inviteProposals: Array<(
    Pick<InvitationProposal, 'proposalId' | 'proposalType' | 'status' | 'invitationId' | 'organizationTemplateId' | 'createdAt'>
    & { legalEntity?: Maybe<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>, organization?: Maybe<Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>>, sender?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
  )> };

export type ListAffiliatesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  affiliateType: AffiliateType;
}>;


export type ListAffiliatesQuery = { listAffiliates: { organizations: Array<(
      Pick<AffiliateOrganization, 'type'>
      & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, affiliatedUsers: Array<{ user: (
          Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
          & { employments?: Maybe<Array<(
            Pick<OrganizationEmployment, 'employeeId'>
            & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
          )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
        ), role: Pick<Role, 'id'> }> }
    )>, inviteProposals: Array<(
      Pick<InvitationProposal, 'proposalId' | 'proposalType' | 'status' | 'invitationId' | 'organizationTemplateId' | 'createdAt'>
      & { legalEntity?: Maybe<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>, organization?: Maybe<Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>>, sender?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
    )> } };

export type ViewMetadataFieldsFragment = Pick<ViewMetadata, 'firstTransactionTimestamp' | 'legalEntityId' | 'viewType' | 'viewVersion'>;

export type AiBookkeeperLegalEntityFieldsFragment = { legalEntity: (
    Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
  ), userInfo: Pick<UserInfo, 'givenName' | 'familyName' | 'emailAddress'>, quotePackage: (
    Pick<QuotePackage, 'id' | 'contractDocumentId' | 'status'>
    & { recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, companyDetails: (
      Pick<CompanyDetails, 'categories' | 'companyName' | 'websiteURL' | 'companySize' | 'incorporationState' | 'entityType' | 'subsidiaryCount' | 'foreignSubsidiaryCount' | 'incorporationYear' | 'lastTaxFiling'>
      & { address?: Maybe<Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>> }
    ), financials?: Maybe<Array<(
      Pick<CompanyFinancials, 'product'>
      & { values: Array<Pick<CompanyFinancialValue, 'name' | 'institutionId'>> }
    )>> }
  ) };

export type ActivateLegalEntityMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ActivateLegalEntityMutation = Pick<Mutation, 'activateLegalEntity'>;

export type UpdateCogsInExpensesMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  isCogsInExpenses: Scalars['Boolean']['input'];
}>;


export type UpdateCogsInExpensesMutation = Pick<Mutation, 'updateCogsInExpenses'>;

export type RequestAccessMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type RequestAccessMutation = Pick<Mutation, 'requestAccess'>;

export type UpdateAiBookkeeperCutoverDateMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  cutoverDate: Scalars['DateTime']['input'];
}>;


export type UpdateAiBookkeeperCutoverDateMutation = Pick<Mutation, 'updateAIBookkeeperCutoverDate'>;

export type UpdateLegalEntityMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetAdditional?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  incorporatedOn?: InputMaybe<DateInput>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  fiscalYearNumberOffset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateLegalEntityMutation = Pick<Mutation, 'updateLegalEntity'>;

export type ReadLegalEntityMetadataQueryVariables = Exact<{
  viewKey: ViewKey;
}>;


export type ReadLegalEntityMetadataQuery = { readLegalEntityMetadata: Pick<ViewMetadata, 'firstTransactionTimestamp' | 'legalEntityId' | 'viewType' | 'viewVersion'> };

export type ReadLegalEntityQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadLegalEntityQuery = { readLegalEntity: (
    Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
  ) };

export type ReadLegalEntityPreferencesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadLegalEntityPreferencesQuery = { readLegalEntityPreferences: Pick<LegalEntityPreferences, 'legalEntityId' | 'isCogsInExpenses' | 'lineClassDisplayName' | 'lineClassPluralDisplayName' | 'bookkeeperAiCutoverAt'> };

export type UpdatePartyFieldsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  updates: Array<InputUpdatePartyFields> | InputUpdatePartyFields;
}>;


export type UpdatePartyFieldsMutation = Pick<Mutation, 'updatePartyFields'>;

export type CreatePartiesMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  partyNames: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type CreatePartiesMutation = { createParties: Array<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> };

export type TaxpayerIdentificationCountFieldsFragment = Pick<TaxpayerIdentificationCount, 'partyCount' | 'taxpayerIdentification'>;

export type PartyHistoryEntryFieldsFragment = Pick<PartyHistoryEntry, 'userId' | 'name' | 'iconUrl'>;

export type ReadPartyQueryVariables = Exact<{
  viewKey: ViewKey;
  partyId: Scalars['String']['input'];
}>;


export type ReadPartyQuery = { readParty: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> };

export type ListActivePartyRolesBatchQueryVariables = Exact<{
  inputs: Array<SummarizeInput> | SummarizeInput;
}>;


export type ListActivePartyRolesBatchQuery = { listActivePartyRolesBatch: Array<Pick<ActivePartyRoles, 'partyId' | 'roles'>> };

export type ListPartyTaxInfoStatsQueryVariables = Exact<{
  filter: TransactionFilter;
  origin: IntervalOrigin;
  direction: DirectionFromOrigin;
}>;


export type ListPartyTaxInfoStatsQuery = { listPartyTaxInfoStats: (
    Pick<PartyTaxInfoStats, 'totalPartyCount'>
    & { taxpayerIdentificationCounts: Array<Pick<TaxpayerIdentificationCount, 'partyCount' | 'taxpayerIdentification'>> }
  ) };

export type ListLatestPartyHistoriesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  partyIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ListLatestPartyHistoriesQuery = { listLatestPartyHistories: Array<(
    Pick<PartyHistory, 'partyId'>
    & { taxpayerIdentificationClassification: Pick<PartyHistoryEntry, 'userId' | 'name' | 'iconUrl'>, entityTypeClassification: Pick<PartyHistoryEntry, 'userId' | 'name' | 'iconUrl'> }
  )> };

export type ListPartyTaxInfoQueryVariables = Exact<{
  filter: TransactionFilter;
  origin: IntervalOrigin;
  direction: DirectionFromOrigin;
}>;


export type ListPartyTaxInfoQuery = { listPartyTaxInfo: Array<Pick<PartyTaxInfo, 'partyId' | 'taxpayerIdentification' | 'entityType'>> };

export type ListActivePartyRolesQueryVariables = Exact<{
  filter: SummaryFilterInput;
  direction?: InputMaybe<DirectionFromOrigin>;
  intervalOrigin?: InputMaybe<IntervalOrigin>;
}>;


export type ListActivePartyRolesQuery = Pick<Query, 'listActivePartyRoles'>;

export type CreateAspectRequestMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type CreateAspectRequestMutation = Pick<Mutation, 'createAspectRequest'>;

export type ReadAspectRequestMetaQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type ReadAspectRequestMetaQuery = { readAspectRequestMeta?: Maybe<(
    Pick<AspectRequestMeta, 'createdAt'>
    & { requestor: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
  )> };

export type InvoiceSummaryFieldsFragment = (
  Pick<InvoiceSummary, 'id' | 'createdAt' | 'dueAt' | 'invoiceNumber' | 'status'>
  & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: Pick<CustomerSummary, 'id' | 'name' | 'email' | 'icon'> }
);

export type BankAccountFieldsFragment = Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'>;

export type DraftInvoiceLineItemFieldsFragment = (
  Pick<DraftInvoiceLineItem, 'id' | 'name' | 'description' | 'quantity' | 'taxable' | 'productId' | 'position'>
  & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, discounts?: Maybe<Array<(
    Pick<InvoiceDiscount, 'invoiceDiscountId'>
    & { discount: (
      Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
      & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  )>> }
);

export type DiscountFieldsFragment = (
  Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
  & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
);

export type InvoicingAddressFieldsFragment = (
  Pick<InvoicingAddress, 'id'>
  & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
);

export type DraftCustomerSummaryFieldsFragment = Pick<DraftCustomerSummary, 'id' | 'name' | 'email' | 'icon'>;

export type InvoiceFileFieldsFragment = Pick<InvoiceFile, 'documentId' | 'fileType'>;

export type InvoiceStatusSummaryFieldsFragment = Pick<InvoiceStatusSummary, 'status' | 'statusAt' | 'invoiceSentDate' | 'userId' | 'internalNoteForStatus'>;

export type DraftCustomerFieldsFragment = (
  Pick<DraftCustomer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
  & { address: (
    Pick<CustomerAddress, 'id'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
  ) }
);

export type DraftInvoicingProfileFieldsFragment = (
  Pick<DraftInvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
  & { companyAddress: (
    Pick<InvoicingAddress, 'id'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
  ) }
);

export type SentInvoiceUsersFieldsFragment = { invoice: (
    Pick<Invoice, 'id' | 'legalEntityId' | 'status' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'taxPercent' | 'memo'>
    & { invoicingProfile?: Maybe<(
      Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
      & { companyAddress: (
        Pick<InvoicingAddress, 'id'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) }
    )>, customer?: Maybe<(
      Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
      & { address: (
        Pick<CustomerAddress, 'id'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) }
    )>, lineItems: Array<(
      Pick<InvoiceLineItem, 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
      & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, discounts?: Maybe<Array<(
        Pick<InvoiceDiscount, 'invoiceDiscountId'>
        & { discount: (
          Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
          & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
        ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      )>> }
    )>, totalTax?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, subtotal?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, totalAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, statusSummary?: Maybe<Pick<InvoiceStatusSummary, 'status' | 'statusAt' | 'invoiceSentDate' | 'userId' | 'internalNoteForStatus'>>, discounts?: Maybe<Array<(
      Pick<InvoiceDiscount, 'invoiceDiscountId'>
      & { discount: (
        Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
        & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    )>>, files?: Maybe<Array<Pick<InvoiceFile, 'documentId' | 'fileType'>>> }
  ), users?: Maybe<Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )>> };

export type InvoicingProfileFieldsFragment = (
  Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
  & { companyAddress: (
    Pick<InvoicingAddress, 'id'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
  ) }
);

export type InvoiceDefaultsFieldsFragment = Pick<InvoiceDefaults, 'id' | 'invoiceDate' | 'dueDate' | 'invoiceNumber'>;

export type CustomerFieldsFragment = (
  Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
  & { address: (
    Pick<CustomerAddress, 'id'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
  ) }
);

export type PayInvoiceSummaryFieldsFragment = (
  Pick<PayInvoiceSummary, 'status'>
  & { invoicePaymentDetails: (
    Pick<InvoicePaymentDetails, 'paymentConfirmationId' | 'paymentDate'>
    & { paymentAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, paymentInstrument: Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'> | {} }
  ) }
);

export type SentInvoiceFieldsFragment = (
  Pick<Invoice, 'id' | 'legalEntityId' | 'status' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'taxPercent' | 'memo'>
  & { invoicingProfile?: Maybe<(
    Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
    & { companyAddress: (
      Pick<InvoicingAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  )>, customer?: Maybe<(
    Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
    & { address: (
      Pick<CustomerAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  )>, lineItems: Array<(
    Pick<InvoiceLineItem, 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
    & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, discounts?: Maybe<Array<(
      Pick<InvoiceDiscount, 'invoiceDiscountId'>
      & { discount: (
        Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
        & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    )>> }
  )>, totalTax?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, subtotal?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, totalAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, statusSummary?: Maybe<Pick<InvoiceStatusSummary, 'status' | 'statusAt' | 'invoiceSentDate' | 'userId' | 'internalNoteForStatus'>>, discounts?: Maybe<Array<(
    Pick<InvoiceDiscount, 'invoiceDiscountId'>
    & { discount: (
      Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
      & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  )>>, files?: Maybe<Array<Pick<InvoiceFile, 'documentId' | 'fileType'>>> }
);

export type InvoiceDiscountFieldsFragment = (
  Pick<InvoiceDiscount, 'invoiceDiscountId'>
  & { discount: (
    Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
    & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
);

export type CustomerAddressFieldsFragment = (
  Pick<CustomerAddress, 'id'>
  & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
);

export type UserFieldsFragment = (
  Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
  & { employments?: Maybe<Array<(
    Pick<OrganizationEmployment, 'employeeId'>
    & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
  )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
);

export type InvoiceLineItemFieldsFragment = (
  Pick<InvoiceLineItem, 'description' | 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
  & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type SentInvoiceLineItemFieldsFragment = (
  Pick<InvoiceLineItem, 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
  & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, discounts?: Maybe<Array<(
    Pick<InvoiceDiscount, 'invoiceDiscountId'>
    & { discount: (
      Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
      & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  )>> }
);

export type InvoiceNumberFieldsFragment = Pick<InvoiceNumber, 'invoiceNumber'>;

export type DraftInvoiceSummaryFieldsFragment = (
  Pick<DraftInvoiceSummary, 'id' | 'createdAt' | 'dueAt' | 'invoiceNumber' | 'status'>
  & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: Pick<DraftCustomerSummary, 'id' | 'name' | 'email' | 'icon'> }
);

export type InvoicePaymentDetailsFieldsFragment = (
  Pick<InvoicePaymentDetails, 'paymentConfirmationId' | 'paymentDate'>
  & { paymentAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, paymentInstrument: Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'> | {} }
);

export type InvoiceProductFieldsFragment = (
  Pick<InvoiceProduct, 'id' | 'name' | 'providerType' | 'incomeCategoryId'>
  & { unitPrice?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
);

export type CustomerSummaryFieldsFragment = Pick<CustomerSummary, 'id' | 'name' | 'email' | 'icon'>;

export type DraftInvoiceFieldsFragment = (
  Pick<DraftInvoice, 'id' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'taxPercent' | 'memo' | 'status' | 'validationErrors'>
  & { invoicingProfile?: Maybe<(
    Pick<DraftInvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
    & { companyAddress: (
      Pick<InvoicingAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  )>, customer?: Maybe<(
    Pick<DraftCustomer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
    & { address: (
      Pick<CustomerAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  )>, lineItems?: Maybe<Array<(
    Pick<DraftInvoiceLineItem, 'id' | 'name' | 'description' | 'quantity' | 'taxable' | 'productId' | 'position'>
    & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, discounts?: Maybe<Array<(
      Pick<InvoiceDiscount, 'invoiceDiscountId'>
      & { discount: (
        Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
        & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    )>> }
  )>>, totalTax?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, subtotal?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, totalAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, discounts?: Maybe<Array<(
    Pick<InvoiceDiscount, 'invoiceDiscountId'>
    & { discount: (
      Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
      & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  )>> }
);

export type SendInvoiceManuallyMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
}>;


export type SendInvoiceManuallyMutation = Pick<Mutation, 'sendInvoiceManually'>;

export type DeleteDraftInvoiceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
}>;


export type DeleteDraftInvoiceMutation = Pick<Mutation, 'deleteDraftInvoice'>;

export type SaveInvoiceDiscountMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  discount: InputDataSourceDiscount;
}>;


export type SaveInvoiceDiscountMutation = { saveInvoiceDiscount: (
    Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
    & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  ) };

export type PayInvoiceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  paymentInstrumentId: Scalars['String']['input'];
}>;


export type PayInvoiceMutation = { payInvoice: (
    Pick<PayInvoiceSummary, 'status'>
    & { invoicePaymentDetails: (
      Pick<InvoicePaymentDetails, 'paymentConfirmationId' | 'paymentDate'>
      & { paymentAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, paymentInstrument: Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'> | {} }
    ) }
  ) };

export type SaveInvoiceCustomerMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  customer: InputCustomer;
}>;


export type SaveInvoiceCustomerMutation = { saveInvoiceCustomer: (
    Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
    & { address: (
      Pick<CustomerAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  ) };

export type SaveInvoicingProfileMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  profile: InputInvoicingProfile;
}>;


export type SaveInvoicingProfileMutation = { saveInvoicingProfile: (
    Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
    & { companyAddress: (
      Pick<InvoicingAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  ) };

export type SaveInvoiceProductMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  product: InputInvoiceProduct;
}>;


export type SaveInvoiceProductMutation = { saveInvoiceProduct: (
    Pick<InvoiceProduct, 'id' | 'name' | 'providerType' | 'incomeCategoryId'>
    & { unitPrice?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  ) };

export type SaveDraftInvoiceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoice: InputInvoice;
}>;


export type SaveDraftInvoiceMutation = Pick<Mutation, 'saveDraftInvoice'>;

export type VoidInvoiceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  internalNote?: InputMaybe<Scalars['String']['input']>;
}>;


export type VoidInvoiceMutation = Pick<Mutation, 'voidInvoice'>;

export type SendInvoiceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
}>;


export type SendInvoiceMutation = Pick<Mutation, 'sendInvoice'>;

export type MarkInvoiceAsPaidMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  internalNote?: InputMaybe<Scalars['String']['input']>;
  datePaid: Scalars['DateTime']['input'];
}>;


export type MarkInvoiceAsPaidMutation = Pick<Mutation, 'markInvoiceAsPaid'>;

export type SearchInvoiceProductsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  text: Scalars['String']['input'];
}>;


export type SearchInvoiceProductsQuery = { searchInvoiceProducts: Array<(
    Pick<InvoiceProduct, 'id' | 'name' | 'providerType' | 'incomeCategoryId'>
    & { unitPrice?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  )> };

export type ReadSentInvoiceQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type ReadSentInvoiceQuery = { readSentInvoice: { invoice: (
      Pick<Invoice, 'id' | 'legalEntityId' | 'status' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'taxPercent' | 'memo'>
      & { invoicingProfile?: Maybe<(
        Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
        & { companyAddress: (
          Pick<InvoicingAddress, 'id'>
          & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
        ) }
      )>, customer?: Maybe<(
        Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
        & { address: (
          Pick<CustomerAddress, 'id'>
          & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
        ) }
      )>, lineItems: Array<(
        Pick<InvoiceLineItem, 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
        & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, discounts?: Maybe<Array<(
          Pick<InvoiceDiscount, 'invoiceDiscountId'>
          & { discount: (
            Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
            & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
          ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
        )>> }
      )>, totalTax?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, subtotal?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, totalAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, statusSummary?: Maybe<Pick<InvoiceStatusSummary, 'status' | 'statusAt' | 'invoiceSentDate' | 'userId' | 'internalNoteForStatus'>>, discounts?: Maybe<Array<(
        Pick<InvoiceDiscount, 'invoiceDiscountId'>
        & { discount: (
          Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
          & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
        ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      )>>, files?: Maybe<Array<Pick<InvoiceFile, 'documentId' | 'fileType'>>> }
    ), users?: Maybe<Array<(
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    )>> } };

export type ReadDraftInvoiceQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type ReadDraftInvoiceQuery = { readDraftInvoice: (
    Pick<DraftInvoice, 'id' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'taxPercent' | 'memo' | 'status' | 'validationErrors'>
    & { invoicingProfile?: Maybe<(
      Pick<DraftInvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
      & { companyAddress: (
        Pick<InvoicingAddress, 'id'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) }
    )>, customer?: Maybe<(
      Pick<DraftCustomer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
      & { address: (
        Pick<CustomerAddress, 'id'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) }
    )>, lineItems?: Maybe<Array<(
      Pick<DraftInvoiceLineItem, 'id' | 'name' | 'description' | 'quantity' | 'taxable' | 'productId' | 'position'>
      & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, discounts?: Maybe<Array<(
        Pick<InvoiceDiscount, 'invoiceDiscountId'>
        & { discount: (
          Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
          & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
        ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      )>> }
    )>>, totalTax?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, subtotal?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, totalAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, discounts?: Maybe<Array<(
      Pick<InvoiceDiscount, 'invoiceDiscountId'>
      & { discount: (
        Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
        & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      ), discountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    )>> }
  ) };

export type SearchInvoiceDiscountsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  text: Scalars['String']['input'];
}>;


export type SearchInvoiceDiscountsQuery = { searchInvoiceDiscounts: Array<(
    Pick<Discount, 'discountId' | 'name' | 'variablePercentage' | 'providerType'>
    & { fixedDiscountAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  )> };

export type ListDraftInvoicesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListDraftInvoicesQuery = { listDraftInvoices: Array<(
    Pick<DraftInvoiceSummary, 'id' | 'createdAt' | 'dueAt' | 'invoiceNumber' | 'status'>
    & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: Pick<DraftCustomerSummary, 'id' | 'name' | 'email' | 'icon'> }
  )> };

export type ListInvoicingProfilesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListInvoicingProfilesQuery = { listInvoicingProfiles: Array<(
    Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID' | 'invoiceNumberFormatDefault' | 'invoiceNumberFormat'>
    & { companyAddress: (
      Pick<InvoicingAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  )> };

export type GetNextInvoiceNumberQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceProfileId: Scalars['String']['input'];
}>;


export type GetNextInvoiceNumberQuery = { getNextInvoiceNumber: Pick<InvoiceNumber, 'invoiceNumber'> };

export type ReadSentInvoiceFilesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type ReadSentInvoiceFilesQuery = { readSentInvoiceFiles: Array<Pick<InvoiceFile, 'documentId' | 'fileType'>> };

export type SearchInvoiceCustomersQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  text: Scalars['String']['input'];
}>;


export type SearchInvoiceCustomersQuery = { searchInvoiceCustomers: { customers: Array<(
      Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
      & { address: (
        Pick<CustomerAddress, 'id'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) }
    )> } };

export type ListSentInvoicesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListSentInvoicesQuery = { listSentInvoices: Array<(
    Pick<InvoiceSummary, 'id' | 'createdAt' | 'dueAt' | 'invoiceNumber' | 'status'>
    & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: Pick<CustomerSummary, 'id' | 'name' | 'email' | 'icon'> }
  )> };

export type PreviewInvoiceEmailQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
}>;


export type PreviewInvoiceEmailQuery = Pick<Query, 'previewInvoiceEmail'>;

export type GetDraftInvoiceDefaultsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  invoiceProfileId: Scalars['String']['input'];
}>;


export type GetDraftInvoiceDefaultsQuery = { getDraftInvoiceDefaults: Pick<InvoiceDefaults, 'id' | 'invoiceDate' | 'dueDate' | 'invoiceNumber'> };

export type ReadCategoryQueryVariables = Exact<{
  viewKey: ViewKey;
  categoryId: Scalars['String']['input'];
}>;


export type ReadCategoryQuery = { readCategory: (
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  ) };

export type UpdateNotificationStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
  status: WebNotificationStatus;
}>;


export type UpdateNotificationStatusMutation = Pick<Mutation, 'updateNotificationStatus'>;

export type UpdateNotificationSettingMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  eventType: EventType;
  enabled: Scalars['Boolean']['input'];
}>;


export type UpdateNotificationSettingMutation = Pick<Mutation, 'updateNotificationSetting'>;

export type NotificationCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationCreatedSubscription = { notificationCreated: Pick<WebNotification, 'id' | 'legalEntityId' | 'eventType' | 'name' | 'displayKey' | 'targetAction' | 'assetLink' | 'status' | 'message' | 'createdAt'> };

export type WebNotificationFieldsFragment = Pick<WebNotification, 'id' | 'legalEntityId' | 'eventType' | 'name' | 'displayKey' | 'targetAction' | 'assetLink' | 'status' | 'message' | 'createdAt'>;

export type NotificationSettingFieldsFragment = Pick<NotificationSetting, 'legalEntityId' | 'eventType' | 'name' | 'displayKey' | 'enabled'>;

export type ListNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListNotificationsQuery = { listNotifications: Array<Pick<WebNotification, 'id' | 'legalEntityId' | 'eventType' | 'name' | 'displayKey' | 'targetAction' | 'assetLink' | 'status' | 'message' | 'createdAt'>> };

export type LogCustomerEventsMutationVariables = Exact<{
  events: Array<CustomerEvent> | CustomerEvent;
}>;


export type LogCustomerEventsMutation = Pick<Mutation, 'logCustomerEvents'>;

export type ProvisionTrialBillingSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
}>;


export type ProvisionTrialBillingSubscriptionMutation = { provisionTrialBillingSubscription: Pick<NewTrialBillingSubscription, 'customerId'> };

export type CreateBillingSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateBillingSubscriptionMutation = { createBillingSubscription: Pick<NewBillingSubscription, 'customerId' | 'stripeClientSecret'> };

export type UpdateBillingPaymentMethodMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
}>;


export type UpdateBillingPaymentMethodMutation = Pick<Mutation, 'updateBillingPaymentMethod'>;

export type CreateTrialBillingSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
}>;


export type CreateTrialBillingSubscriptionMutation = { createTrialBillingSubscription: Pick<NewTrialBillingSubscription, 'customerId'> };

export type CreateBillingOnboardingSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type CreateBillingOnboardingSubscriptionMutation = { createBillingOnboardingSubscription: Pick<BillingSubscriptionWithoutStatus, 'id' | 'periodEnd' | 'periodStart'> };

export type UpdateBillingSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
}>;


export type UpdateBillingSubscriptionMutation = Pick<Mutation, 'updateBillingSubscription'>;

export type CancelBillingSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
}>;


export type CancelBillingSubscriptionMutation = Pick<Mutation, 'cancelBillingSubscription'>;

export type ConfirmBillingSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
}>;


export type ConfirmBillingSubscriptionMutation = Pick<Mutation, 'confirmBillingSubscription'>;

export type BillingPlanFieldsFragment = { product: Pick<BillingProduct, 'id' | 'name' | 'displayName' | 'description' | 'trialPeriodDays'>, price: (
    Pick<BillingPrice, 'id' | 'interval' | 'intervalCount'>
    & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), legalEntityLimit?: Maybe<Pick<BillingLegalEntityLimit, 'id' | 'minLegalEntityLimit' | 'maxLegalEntityLimit'>> };

export type NewTrialBillingSubscriptionFieldsFragment = Pick<NewTrialBillingSubscription, 'customerId'>;

export type CreateBillingOnboardingSubscriptionFieldsFragment = Pick<BillingSubscriptionWithoutStatus, 'id' | 'periodEnd' | 'periodStart'>;

export type BillingTrialFieldsFragment = Pick<BillingTrial, 'start' | 'end'>;

export type SubscriptionDetailsFieldsFragment = Pick<SubscriptionDetails, 'startedAt' | 'billingCycleStart' | 'billingCycleEnd' | 'status' | 'cancelAt'>;

export type BillingProductFieldsFragment = Pick<BillingProduct, 'id' | 'name' | 'displayName' | 'description' | 'trialPeriodDays'>;

export type BillingPriceFieldsFragment = (
  Pick<BillingPrice, 'id' | 'interval' | 'intervalCount'>
  & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type BillingLegalEntityLimitFieldsFragment = Pick<BillingLegalEntityLimit, 'id' | 'minLegalEntityLimit' | 'maxLegalEntityLimit'>;

export type NewBillingSubscriptionFieldsFragment = Pick<NewBillingSubscription, 'customerId' | 'stripeClientSecret'>;

export type ReadBillingPlansQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  productNames: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ReadBillingPlansQuery = { readBillingPlans: Array<{ product: Pick<BillingProduct, 'id' | 'name' | 'displayName' | 'description' | 'trialPeriodDays'>, price: (
      Pick<BillingPrice, 'id' | 'interval' | 'intervalCount'>
      & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }> };

export type ReadBillingSubscriptionHistoryQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type ReadBillingSubscriptionHistoryQuery = { readBillingSubscriptionHistory: Array<(
    Pick<BillingSubscriptionHistory, 'createdAt' | 'paymentIntentStatus' | 'interval' | 'productName' | 'productDisplayName'>
    & { amount: Pick<MonetaryValue, 'amount' | 'currencyMultiplier' | 'iso4217CurrencyCode'> }
  )> };

export type PreviewBillingSubscriptionQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  priceId: Scalars['String']['input'];
}>;


export type PreviewBillingSubscriptionQuery = { previewBillingSubscription: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> };

export type ReadBillingCustomerProfileQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type ReadBillingCustomerProfileQuery = { readBillingCustomerProfile: { cardDetails: Pick<BillingCardDetails, 'name' | 'last4' | 'brand'> } };

export type CheckBillingOrganizationPlanQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type CheckBillingOrganizationPlanQuery = { checkBillingOrganizationPlan: (
    Pick<CheckBillingPlan, 'exists'>
    & { billingPlan?: Maybe<{ product: Pick<BillingProduct, 'id' | 'name' | 'displayName' | 'description' | 'trialPeriodDays'>, price: (
        Pick<BillingPrice, 'id' | 'interval' | 'intervalCount'>
        & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), legalEntityLimit?: Maybe<Pick<BillingLegalEntityLimit, 'id' | 'minLegalEntityLimit' | 'maxLegalEntityLimit'>> }>, trial?: Maybe<Pick<BillingTrial, 'start' | 'end'>>, subscriptionDetails?: Maybe<Pick<SubscriptionDetails, 'startedAt' | 'billingCycleStart' | 'billingCycleEnd' | 'status' | 'cancelAt'>> }
  ) };

export type ReadOrganizationBillingPlanQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type ReadOrganizationBillingPlanQuery = { readOrganizationBillingPlan: { plan: { product: Pick<BillingProduct, 'id' | 'name' | 'displayName' | 'description' | 'trialPeriodDays'>, price: (
        Pick<BillingPrice, 'id' | 'interval' | 'intervalCount'>
        & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), legalEntityLimit?: Maybe<Pick<BillingLegalEntityLimit, 'id' | 'minLegalEntityLimit' | 'maxLegalEntityLimit'>> }, trial?: Maybe<Pick<BillingTrial, 'start' | 'end'>> } };

export type AddDocumentFeatureMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
  documentCollectionId: Scalars['String']['input'];
  feature: NewDocumentFeature;
  correctedFeatureId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddDocumentFeatureMutation = { addDocumentFeature: Pick<AddedFeature, 'featureId' | 'invalid'> };

export type VaultFeatureFieldsFragment = (
  { __typename: 'Feature' }
  & Pick<Feature, 'id' | 'kind'>
  & { value: (
    { __typename: 'AddressFeatureValue' }
    & { addressValue: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
  ) | (
    { __typename: 'DateFeatureValue' }
    & { dateValue: Pick<Date, 'year' | 'month' | 'day'> }
  ) | (
    { __typename: 'LineItemFeatureValue' }
    & { lineItemValue: (
      Pick<DocumentLineItem, 'description' | 'quantity' | 'position' | 'categoryId'>
      & { rate: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  ) | (
    { __typename: 'MonetaryFeatureValue' }
    & { monetaryValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) | (
    { __typename: 'NumericFeatureValue' }
    & Pick<NumericFeatureValue, 'numericValue'>
  ) | (
    { __typename: 'RemittanceFeatureValue' }
    & { remittanceValue: Pick<RemittanceItem, 'BankName' | 'BankAccountNumber' | 'BankAccountName' | 'BankRoutingNumber' | 'RemittanceType'> }
  ) | (
    { __typename: 'TextFeatureValue' }
    & Pick<TextFeatureValue, 'textValue'>
  ) }
);

export type TextFeatureValueFieldsFragment = (
  { __typename: 'TextFeatureValue' }
  & Pick<TextFeatureValue, 'textValue'>
);

export type NumericFeatureValueFieldsFragment = (
  { __typename: 'NumericFeatureValue' }
  & Pick<NumericFeatureValue, 'numericValue'>
);

export type DateFeatureValueFieldsFragment = (
  { __typename: 'DateFeatureValue' }
  & { dateValue: Pick<Date, 'year' | 'month' | 'day'> }
);

export type MonetaryFeatureValueFieldsFragment = (
  { __typename: 'MonetaryFeatureValue' }
  & { monetaryValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type AddressFeatureValueFieldsFragment = (
  { __typename: 'AddressFeatureValue' }
  & { addressValue: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
);

export type LineItemFeatureValueFieldsFragment = (
  { __typename: 'LineItemFeatureValue' }
  & { lineItemValue: (
    Pick<DocumentLineItem, 'description' | 'quantity' | 'position' | 'categoryId'>
    & { rate: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) }
);

export type RemittanceFeatureValueFieldsFragment = (
  { __typename: 'RemittanceFeatureValue' }
  & { remittanceValue: Pick<RemittanceItem, 'BankName' | 'BankAccountNumber' | 'BankAccountName' | 'BankRoutingNumber' | 'RemittanceType'> }
);

export type VaultFileFieldsFragment = (
  { __typename: 'RawDocument' }
  & Pick<RawDocument, 'id' | 'collectionId' | 'sourceFormatId' | 'createdAt' | 'updatedAt' | 'status' | 'asyncProcessingStatus' | 'title' | 'fileName' | 'fileSize' | 'mimeType'>
  & { formats: Array<(
    { __typename: 'Format' }
    & Pick<Format, 'id' | 'formatClass' | 'mimeType'>
    & { segments: Array<(
      { __typename: 'Segment' }
      & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
    )> }
  )>, features: Array<(
    { __typename: 'Feature' }
    & Pick<Feature, 'id' | 'kind'>
    & { value: (
      { __typename: 'AddressFeatureValue' }
      & { addressValue: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) | (
      { __typename: 'DateFeatureValue' }
      & { dateValue: Pick<Date, 'year' | 'month' | 'day'> }
    ) | (
      { __typename: 'LineItemFeatureValue' }
      & { lineItemValue: (
        Pick<DocumentLineItem, 'description' | 'quantity' | 'position' | 'categoryId'>
        & { rate: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    ) | (
      { __typename: 'MonetaryFeatureValue' }
      & { monetaryValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) | (
      { __typename: 'NumericFeatureValue' }
      & Pick<NumericFeatureValue, 'numericValue'>
    ) | (
      { __typename: 'RemittanceFeatureValue' }
      & { remittanceValue: Pick<RemittanceItem, 'BankName' | 'BankAccountNumber' | 'BankAccountName' | 'BankRoutingNumber' | 'RemittanceType'> }
    ) | (
      { __typename: 'TextFeatureValue' }
      & Pick<TextFeatureValue, 'textValue'>
    ) }
  )>, creator?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, thumbnail?: Maybe<(
    { __typename: 'Segment' }
    & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
  )>, sourceFormat?: Maybe<(
    { __typename: 'Format' }
    & Pick<Format, 'id' | 'formatClass' | 'mimeType'>
    & { segments: Array<(
      { __typename: 'Segment' }
      & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
    )> }
  )> }
);

export type VaultFormatFieldsFragment = (
  { __typename: 'Format' }
  & Pick<Format, 'id' | 'formatClass' | 'mimeType'>
  & { segments: Array<(
    { __typename: 'Segment' }
    & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
  )> }
);

export type VaultSegmentFieldsFragment = (
  { __typename: 'Segment' }
  & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
);

export type DocumentDownloadUrlQueryVariables = Exact<{
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type DocumentDownloadUrlQuery = { documentDownloadUrl: Pick<DocumentDownload, 'url'> };

export type ListVaultFilesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
  filter: DocumentFilter;
  order?: InputMaybe<DocumentSort>;
}>;


export type ListVaultFilesQuery = { listDocuments: Array<(
    { __typename: 'RawDocument' }
    & Pick<RawDocument, 'id' | 'collectionId' | 'sourceFormatId' | 'createdAt' | 'updatedAt' | 'status' | 'asyncProcessingStatus' | 'title' | 'fileName' | 'fileSize' | 'mimeType'>
    & { formats: Array<(
      { __typename: 'Format' }
      & Pick<Format, 'id' | 'formatClass' | 'mimeType'>
      & { segments: Array<(
        { __typename: 'Segment' }
        & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
      )> }
    )>, features: Array<(
      { __typename: 'Feature' }
      & Pick<Feature, 'id' | 'kind'>
      & { value: (
        { __typename: 'AddressFeatureValue' }
        & { addressValue: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) | (
        { __typename: 'DateFeatureValue' }
        & { dateValue: Pick<Date, 'year' | 'month' | 'day'> }
      ) | (
        { __typename: 'LineItemFeatureValue' }
        & { lineItemValue: (
          Pick<DocumentLineItem, 'description' | 'quantity' | 'position' | 'categoryId'>
          & { rate: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      ) | (
        { __typename: 'MonetaryFeatureValue' }
        & { monetaryValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) | (
        { __typename: 'NumericFeatureValue' }
        & Pick<NumericFeatureValue, 'numericValue'>
      ) | (
        { __typename: 'RemittanceFeatureValue' }
        & { remittanceValue: Pick<RemittanceItem, 'BankName' | 'BankAccountNumber' | 'BankAccountName' | 'BankRoutingNumber' | 'RemittanceType'> }
      ) | (
        { __typename: 'TextFeatureValue' }
        & Pick<TextFeatureValue, 'textValue'>
      ) }
    )>, creator?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, thumbnail?: Maybe<(
      { __typename: 'Segment' }
      & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
    )>, sourceFormat?: Maybe<(
      { __typename: 'Format' }
      & Pick<Format, 'id' | 'formatClass' | 'mimeType'>
      & { segments: Array<(
        { __typename: 'Segment' }
        & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
      )> }
    )> }
  )>, summarizeDocuments: Pick<DocumentsSummary, 'count'> };

export type ReadVaultFileQueryVariables = Exact<{
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadVaultFileQuery = { readVaultFile: (
    { __typename: 'RawDocument' }
    & Pick<RawDocument, 'id' | 'collectionId' | 'sourceFormatId' | 'createdAt' | 'updatedAt' | 'status' | 'asyncProcessingStatus' | 'title' | 'fileName' | 'fileSize' | 'mimeType'>
    & { formats: Array<(
      { __typename: 'Format' }
      & Pick<Format, 'id' | 'formatClass' | 'mimeType'>
      & { segments: Array<(
        { __typename: 'Segment' }
        & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
      )> }
    )>, features: Array<(
      { __typename: 'Feature' }
      & Pick<Feature, 'id' | 'kind'>
      & { value: (
        { __typename: 'AddressFeatureValue' }
        & { addressValue: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) | (
        { __typename: 'DateFeatureValue' }
        & { dateValue: Pick<Date, 'year' | 'month' | 'day'> }
      ) | (
        { __typename: 'LineItemFeatureValue' }
        & { lineItemValue: (
          Pick<DocumentLineItem, 'description' | 'quantity' | 'position' | 'categoryId'>
          & { rate: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      ) | (
        { __typename: 'MonetaryFeatureValue' }
        & { monetaryValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) | (
        { __typename: 'NumericFeatureValue' }
        & Pick<NumericFeatureValue, 'numericValue'>
      ) | (
        { __typename: 'RemittanceFeatureValue' }
        & { remittanceValue: Pick<RemittanceItem, 'BankName' | 'BankAccountNumber' | 'BankAccountName' | 'BankRoutingNumber' | 'RemittanceType'> }
      ) | (
        { __typename: 'TextFeatureValue' }
        & Pick<TextFeatureValue, 'textValue'>
      ) }
    )>, creator?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, thumbnail?: Maybe<(
      { __typename: 'Segment' }
      & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
    )>, sourceFormat?: Maybe<(
      { __typename: 'Format' }
      & Pick<Format, 'id' | 'formatClass' | 'mimeType'>
      & { segments: Array<(
        { __typename: 'Segment' }
        & Pick<Segment, 'fileId' | 'fileSize' | 'fileName' | 'sequence' | 'collectionId' | 'token'>
      )> }
    )> }
  ) };

export type UpdateExecutiveSummaryMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  documentId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateExecutiveSummaryMutation = Pick<Mutation, 'updateExecutiveSummary'>;

export type UpdateReportAutomationSettingsMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  interval: Interval;
  autoCreateDraft: Scalars['Boolean']['input'];
  autoPublishDraft: Scalars['Boolean']['input'];
  autoPublishDraftDay: Scalars['Int']['input'];
  autoPublishFinal: Scalars['Boolean']['input'];
  autoPublishFinalDay: Scalars['Int']['input'];
}>;


export type UpdateReportAutomationSettingsMutation = Pick<Mutation, 'updateReportAutomationSettings'>;

export type DeleteReportMutationVariables = Exact<{
  reportId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type DeleteReportMutation = Pick<Mutation, 'deleteReport'>;

export type PublishReportPackageMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  publishAsDraft: Scalars['Boolean']['input'];
}>;


export type PublishReportPackageMutation = Pick<Mutation, 'publishReportPackage'>;

export type DeleteReportPackageMutationVariables = Exact<{
  packageId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type DeleteReportPackageMutation = Pick<Mutation, 'deleteReportPackage'>;

export type RefreshReportPackageMutationVariables = Exact<{
  viewKey: ViewKey;
  packageId: Scalars['String']['input'];
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type RefreshReportPackageMutation = { refreshReportPackage: Pick<ReportRefreshRequest, 'requestId'> };

export type CopyReportPackageMutationVariables = Exact<{
  viewKey: ViewKey;
  packageId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  periodStartAt: DateInput;
  periodEndAt: DateInput;
  interval: Interval;
  attachments: NewReportAttachments;
}>;


export type CopyReportPackageMutation = { copyReportPackage: (
    Pick<ReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'coverUrl' | 'publishedAsDraft' | 'createdAt' | 'updatedAt' | 'creationMethod'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
      Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
      & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
    )>, versions: Array<Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>> }
  ) };

export type EditReportPackageMutationVariables = Exact<{
  viewKey: ViewKey;
  packageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
}>;


export type EditReportPackageMutation = { editReportPackage: (
    Pick<ReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'coverUrl' | 'publishedAsDraft' | 'createdAt' | 'updatedAt' | 'creationMethod'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
      Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
      & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
    )>, versions: Array<Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>> }
  ) };

export type AttachReportPackageDocumentMutationVariables = Exact<{
  viewKey: ViewKey;
  origin: IntervalOrigin;
  packageId: Scalars['String']['input'];
  attachments: NewReportAttachments;
}>;


export type AttachReportPackageDocumentMutation = Pick<Mutation, 'attachReportPackageDocument'>;

export type UpdateReportMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  interval?: InputMaybe<Interval>;
  reportFor?: InputMaybe<DateInput>;
}>;


export type UpdateReportMutation = Pick<Mutation, 'updateReport'>;

export type CreateReportPackageMutationVariables = Exact<{
  viewKey: ViewKey;
  title: Scalars['String']['input'];
  periodStartAt: DateInput;
  periodEndAt: DateInput;
  interval: Interval;
  attachments: NewReportAttachments;
  coverUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateReportPackageMutation = { createReportPackage: (
    Pick<ReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'coverUrl' | 'publishedAsDraft' | 'createdAt' | 'updatedAt' | 'creationMethod'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
      Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
      & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
    )>, versions: Array<Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>> }
  ) };

export type UpdateReportPackageMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  customFooter?: InputMaybe<Scalars['String']['input']>;
  preparedBy?: InputMaybe<Scalars['String']['input']>;
  accountNumbersDisplay?: InputMaybe<AccountNumbersDisplay>;
  coverUrl?: InputMaybe<Scalars['String']['input']>;
  coverLogoSource?: InputMaybe<CoverLogoSource>;
  publishedAsDraft?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateReportPackageMutation = Pick<Mutation, 'updateReportPackage'>;

export type UpdateReportOptionsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reportPackageId: Scalars['String']['input'];
  packageVersionId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  reportVersionId: Scalars['String']['input'];
  sparklineLookbackPeriods?: InputMaybe<Scalars['Int']['input']>;
  deltaMonthOverMonth?: InputMaybe<ReportOptionComparison>;
  deltaYearOverYear?: InputMaybe<ReportOptionComparison>;
  comparedToTotal?: InputMaybe<ReportOptionComparison>;
  comparedToIncome?: InputMaybe<ReportOptionComparison>;
  collapsedSections?: InputMaybe<CollapsedSectionsInput>;
}>;


export type UpdateReportOptionsMutation = Pick<Mutation, 'updateReportOptions'>;

export type CreateReportFromUploadMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reports: Array<NewReportUpload> | NewReportUpload;
}>;


export type CreateReportFromUploadMutation = { createReportFromUpload: Array<(
    Pick<Report, 'id' | 'versionId' | 'title' | 'status' | 'interval' | 'createdAt'>
    & { author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, reportFile: Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>, reportFor?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>>, options?: Maybe<(
      { __typename: 'ReportDocumentOptions' }
      & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
      & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
    )> }
  )> };

export type StatementDetailsFieldsFragment = { hover: (
    { __typename: 'CategoryHover' }
    & Pick<CategoryHover, 'balance'>
    & { entity: (
      { __typename: 'EntityCategory' }
      & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
    ), history: (
      Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ), context?: Maybe<(
      Pick<DimensionSummary, 'partialData'>
      & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    )>, topTransactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>> }
  ) | (
    { __typename: 'PartyHover' }
    & { entity: (
      { __typename: 'EntityParty' }
      & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
    ), history: (
      Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ), context?: Maybe<(
      Pick<DimensionSummary, 'partialData'>
      & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    )>, topTransactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>> }
  ) | (
    { __typename: 'SummaryLineItemHover' }
    & Pick<SummaryLineItemHover, 'lineType'>
    & { history: (
      Pick<DimensionSummary, 'partialData'>
      & { time: Array<{ summary: (
          Pick<TransactionSummary, 'isPeriodBookClosed'>
          & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
            Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            )>, current?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )>, prior?: Maybe<(
              Pick<SummaryTotal, 'count'>
              & { total: (
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              ) }
            )> }
          ) }
        ) }> }
    ) }
  ), insight?: Maybe<(
    Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
    & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  )> };

export type StatementRowSummaryFieldsFragment = (
  Pick<StatementRowSummary, 'displayNumber' | 'label'>
  & { moneyFlow: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) }
);

export type StatementRowFieldsFragment = (
  Pick<StatementRow, 'rowId' | 'depth'>
  & { sectionTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, sectionSummary?: Maybe<(
    Pick<StatementRowSummary, 'displayNumber' | 'label'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )>, parentCategoryTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, parentCategorySummary?: Maybe<(
    Pick<StatementRowSummary, 'displayNumber' | 'label'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )>, leafCategorySummary?: Maybe<(
    Pick<StatementRowSummary, 'displayNumber' | 'label'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )>, details?: Maybe<{ hover: (
      { __typename: 'CategoryHover' }
      & Pick<CategoryHover, 'balance'>
      & { entity: (
        { __typename: 'EntityCategory' }
        & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
      ), history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ), context?: Maybe<(
        Pick<DimensionSummary, 'partialData'>
        & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      )>, topTransactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>> }
    ) | (
      { __typename: 'PartyHover' }
      & { entity: (
        { __typename: 'EntityParty' }
        & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
      ), history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ), context?: Maybe<(
        Pick<DimensionSummary, 'partialData'>
        & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      )>, topTransactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>> }
    ) | (
      { __typename: 'SummaryLineItemHover' }
      & Pick<SummaryLineItemHover, 'lineType'>
      & { history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ) }
    ), insight?: Maybe<(
      Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
      & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    )> }>, ytdDetails?: Maybe<{ hover: (
      { __typename: 'CategoryHover' }
      & Pick<CategoryHover, 'balance'>
      & { entity: (
        { __typename: 'EntityCategory' }
        & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
      ), history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ), context?: Maybe<(
        Pick<DimensionSummary, 'partialData'>
        & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      )>, topTransactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>> }
    ) | (
      { __typename: 'PartyHover' }
      & { entity: (
        { __typename: 'EntityParty' }
        & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
      ), history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ), context?: Maybe<(
        Pick<DimensionSummary, 'partialData'>
        & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      )>, topTransactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>> }
    ) | (
      { __typename: 'SummaryLineItemHover' }
      & Pick<SummaryLineItemHover, 'lineType'>
      & { history: (
        Pick<DimensionSummary, 'partialData'>
        & { time: Array<{ summary: (
            Pick<TransactionSummary, 'isPeriodBookClosed'>
            & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
              Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
              )>, current?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )>, prior?: Maybe<(
                Pick<SummaryTotal, 'count'>
                & { total: (
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                ) }
              )> }
            ) }
          ) }> }
      ) }
    ), insight?: Maybe<(
      Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
      & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    )> }>, deltas?: Maybe<{ percentageTotal?: Maybe<(
      Pick<StatementDeltaValue, 'percentageOfAmount'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )>, percentageIncome?: Maybe<(
      Pick<StatementDeltaValue, 'percentageOfAmount'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )>, previousPeriod?: Maybe<(
      Pick<StatementDeltaValue, 'percentageOfAmount'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )>, previousYear?: Maybe<(
      Pick<StatementDeltaValue, 'percentageOfAmount'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )> }> }
);

export type ReportAutomationSettingsFieldsFragment = Pick<ReportAutomationSettings, 'organizationId' | 'legalEntityId' | 'interval' | 'autoCreateDraft' | 'autoPublishDraft' | 'autoPublishDraftDay' | 'autoPublishFinal' | 'autoPublishFinalDay'>;

export type TrialBalanceFieldsFragment = { rows: Array<(
    Pick<TrialBalanceRow, 'rowId' | 'name'>
    & { debits: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, credits: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )> };

export type TrialBalanceRowFieldsFragment = (
  Pick<TrialBalanceRow, 'rowId' | 'name'>
  & { debits: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, credits: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type PeriodReportFieldsFragment = (
  Pick<PeriodReport, 'id' | 'title' | 'interval' | 'createdAt' | 'status'>
  & { generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, threadDetails?: Maybe<Array<(
    Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
    & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
  )>>, reportFile: Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>, reportFor?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
);

export type TextDocumentFieldsFragment = Pick<TextDocument, 'id' | 'title' | 'summary' | 'status' | 'createdAt' | 'updatedAt'>;

export type StatementRowTitleFieldsFragment = Pick<StatementRowTitle, 'displayNumber' | 'title'>;

export type StatementDeltasFieldsFragment = { percentageTotal?: Maybe<(
    Pick<StatementDeltaValue, 'percentageOfAmount'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )>, percentageIncome?: Maybe<(
    Pick<StatementDeltaValue, 'percentageOfAmount'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )>, previousPeriod?: Maybe<(
    Pick<StatementDeltaValue, 'percentageOfAmount'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )>, previousYear?: Maybe<(
    Pick<StatementDeltaValue, 'percentageOfAmount'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )> };

export type StatementDeltaValueFieldsFragment = (
  Pick<StatementDeltaValue, 'percentageOfAmount'>
  & { moneyFlow: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) }
);

export type StatementFieldsFragment = (
  Pick<Statement, 'kind'>
  & { rows: Array<(
    Pick<StatementRow, 'rowId' | 'depth'>
    & { sectionTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, sectionSummary?: Maybe<(
      Pick<StatementRowSummary, 'displayNumber' | 'label'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )>, parentCategoryTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, parentCategorySummary?: Maybe<(
      Pick<StatementRowSummary, 'displayNumber' | 'label'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )>, leafCategorySummary?: Maybe<(
      Pick<StatementRowSummary, 'displayNumber' | 'label'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )>, details?: Maybe<{ hover: (
        { __typename: 'CategoryHover' }
        & Pick<CategoryHover, 'balance'>
        & { entity: (
          { __typename: 'EntityCategory' }
          & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
        ), history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ), context?: Maybe<(
          Pick<DimensionSummary, 'partialData'>
          & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        )>, topTransactions?: Maybe<Array<(
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      ) | (
        { __typename: 'PartyHover' }
        & { entity: (
          { __typename: 'EntityParty' }
          & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
        ), history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ), context?: Maybe<(
          Pick<DimensionSummary, 'partialData'>
          & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        )>, topTransactions?: Maybe<Array<(
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      ) | (
        { __typename: 'SummaryLineItemHover' }
        & Pick<SummaryLineItemHover, 'lineType'>
        & { history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ) }
      ), insight?: Maybe<(
        Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
        & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      )> }>, ytdDetails?: Maybe<{ hover: (
        { __typename: 'CategoryHover' }
        & Pick<CategoryHover, 'balance'>
        & { entity: (
          { __typename: 'EntityCategory' }
          & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
        ), history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ), context?: Maybe<(
          Pick<DimensionSummary, 'partialData'>
          & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        )>, topTransactions?: Maybe<Array<(
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      ) | (
        { __typename: 'PartyHover' }
        & { entity: (
          { __typename: 'EntityParty' }
          & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
        ), history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ), context?: Maybe<(
          Pick<DimensionSummary, 'partialData'>
          & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        )>, topTransactions?: Maybe<Array<(
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        )>> }
      ) | (
        { __typename: 'SummaryLineItemHover' }
        & Pick<SummaryLineItemHover, 'lineType'>
        & { history: (
          Pick<DimensionSummary, 'partialData'>
          & { time: Array<{ summary: (
              Pick<TransactionSummary, 'isPeriodBookClosed'>
              & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                )>, current?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )>, prior?: Maybe<(
                  Pick<SummaryTotal, 'count'>
                  & { total: (
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  ) }
                )> }
              ) }
            ) }> }
        ) }
      ), insight?: Maybe<(
        Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
        & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
      )> }>, deltas?: Maybe<{ percentageTotal?: Maybe<(
        Pick<StatementDeltaValue, 'percentageOfAmount'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, percentageIncome?: Maybe<(
        Pick<StatementDeltaValue, 'percentageOfAmount'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, previousPeriod?: Maybe<(
        Pick<StatementDeltaValue, 'percentageOfAmount'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, previousYear?: Maybe<(
        Pick<StatementDeltaValue, 'percentageOfAmount'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )> }> }
  )> }
);

export type StatementSummaryFieldsFragment = Pick<StatementSummary, 'summaries'>;

export type ReportPackageFieldsFragment = (
  Pick<ReportPackage, 'id' | 'versionId' | 'title' | 'status' | 'createdAt' | 'updatedAt' | 'startedAt' | 'endedAt' | 'interval'>
  & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, authorOrganization?: Maybe<Pick<OrganizationPreview, 'id' | 'slug' | 'name' | 'iconUrl'>>, legalEntityOrganization?: Maybe<Pick<OrganizationPreview, 'id' | 'slug' | 'name' | 'iconUrl'>>, packageOptions?: Maybe<Pick<ReportPackageOptions, 'customFooter' | 'preparedBy' | 'accountNumbersDisplay' | 'coverUrl' | 'coverLogoSource' | 'publishedAsDraft'>>, documents: Array<{ metadata: (
      Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
      & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
    ), document?: Maybe<Pick<LayoutDocument, 'id' | 'title' | 'layoutId' | 'status' | 'createdAt' | 'updatedAt'> | (
      Pick<Report, 'id' | 'versionId' | 'title' | 'status' | 'interval' | 'createdAt'>
      & { author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, reportFile: Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>, reportFor?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>>, options?: Maybe<(
        { __typename: 'ReportDocumentOptions' }
        & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
        & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
      )> }
    ) | Pick<TextDocument, 'id' | 'title' | 'summary' | 'status' | 'createdAt' | 'updatedAt'>> }>, entities: (
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  ), version: Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'> }
);

export type ReportFieldsFragment = (
  Pick<Report, 'id' | 'versionId' | 'title' | 'status' | 'interval' | 'createdAt'>
  & { author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, reportFile: Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>, reportFor?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>>, options?: Maybe<(
    { __typename: 'ReportDocumentOptions' }
    & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
    & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
  )> }
);

export type ReportOptionsFieldsFragment = (
  { __typename: 'ReportDocumentOptions' }
  & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
  & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
);

export type ListReportTemplatesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  interval: Interval;
  limit: Scalars['Int']['input'];
}>;


export type ListReportTemplatesQuery = { listReportTemplates: Array<Pick<ReportTemplate, 'id' | 'name' | 'hasExecutiveSummary' | 'hasProfitAndLoss' | 'hasBalanceSheet' | 'hasCashFlow'>> };

export type RefreshReportPackageStatusQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
}>;


export type RefreshReportPackageStatusQuery = { refreshReportPackageStatus: Array<Pick<ReportGenerationStatus, 'reportId' | 'versionId' | 'status'>> };

export type ReadReportPackageQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
  packageVersionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ReadReportPackageQuery = { readReportPackage: (
    Pick<ReportPackage, 'id' | 'versionId' | 'title' | 'status' | 'createdAt' | 'updatedAt' | 'startedAt' | 'endedAt' | 'interval'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, authorOrganization?: Maybe<Pick<OrganizationPreview, 'id' | 'slug' | 'name' | 'iconUrl'>>, legalEntityOrganization?: Maybe<Pick<OrganizationPreview, 'id' | 'slug' | 'name' | 'iconUrl'>>, packageOptions?: Maybe<Pick<ReportPackageOptions, 'customFooter' | 'preparedBy' | 'accountNumbersDisplay' | 'coverUrl' | 'coverLogoSource' | 'publishedAsDraft'>>, documents: Array<{ metadata: (
        Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
        & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
      ), document?: Maybe<Pick<LayoutDocument, 'id' | 'title' | 'layoutId' | 'status' | 'createdAt' | 'updatedAt'> | (
        Pick<Report, 'id' | 'versionId' | 'title' | 'status' | 'interval' | 'createdAt'>
        & { author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, reportFile: Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>, reportFor?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>>, options?: Maybe<(
          { __typename: 'ReportDocumentOptions' }
          & Pick<ReportDocumentOptions, 'sparklineLookbackPeriods' | 'deltaMonthOverMonth' | 'deltaMonthOverMonthPeriods' | 'deltaYearOverYear' | 'comparedToTotal' | 'comparedToIncome' | 'amountPrecision' | 'yearToDate' | 'deltaYearToDate'>
          & { collapsedSections?: Maybe<Pick<CollapsedSections, 'sections'>> }
        )> }
      ) | Pick<TextDocument, 'id' | 'title' | 'summary' | 'status' | 'createdAt' | 'updatedAt'>> }>, entities: (
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    ), version: Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'> }
  ) };

export type ReadReportPackageCountQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadReportPackageCountQuery = { readReportPackageCount: (
    Pick<PackageCounts, 'total'>
    & { counts: Array<Pick<PackageCount, 'interval' | 'total'>> }
  ) };

export type GenerateStatementSummaryQueryVariables = Exact<{
  input: Scalars['String']['input'];
  reportType: Scalars['String']['input'];
}>;


export type GenerateStatementSummaryQuery = { generateStatementSummary: Pick<StatementSummary, 'summaries'> };

export type SummarizeTrialBalanceQueryVariables = Exact<{
  viewKey: ViewKey;
  origin: IntervalOrigin;
}>;


export type SummarizeTrialBalanceQuery = { summarizeTrialBalance: { rows: Array<(
      Pick<TrialBalanceRow, 'rowId' | 'name'>
      & { debits: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, credits: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )> } };

export type ReadReportAutomationSettingsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  interval: Interval;
}>;


export type ReadReportAutomationSettingsQuery = { readReportAutomationSettings: Pick<ReportAutomationSettings, 'organizationId' | 'legalEntityId' | 'interval' | 'autoCreateDraft' | 'autoPublishDraft' | 'autoPublishDraftDay' | 'autoPublishFinal' | 'autoPublishFinalDay'> };

export type ListReportPackagesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  interval: Interval;
  intervalCount?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
  index: Scalars['Int']['input'];
  paginationLimit: Scalars['Int']['input'];
  paginationOffset: Scalars['Int']['input'];
  direction: DirectionFromOrigin;
  sort?: InputMaybe<PackagesSort>;
}>;


export type ListReportPackagesQuery = { listReportPackages: Array<(
    Pick<ReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'coverUrl' | 'publishedAsDraft' | 'createdAt' | 'updatedAt' | 'creationMethod'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
      Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
      & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
    )>, versions: Array<Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>> }
  )> };

export type ReportDownloadUrlQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  reportVersionId: Scalars['String']['input'];
  reportPackageId: Scalars['String']['input'];
  reportFileSource?: InputMaybe<ReportFileSource>;
}>;


export type ReportDownloadUrlQuery = { reportDownloadUrl: Pick<ReportDownload, 'url'> };

export type SummarizeReportPackagesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  intervalOrigin: IntervalOrigin;
  direction: DirectionFromOrigin;
}>;


export type SummarizeReportPackagesQuery = { summarizeReportPackages: Array<(
    Pick<ReportPackageSummary, 'packagesCount' | 'publishedCount' | 'draftCount'>
    & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
  )> };

export type ReadReportArchiveQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  reportVersionId: Scalars['String']['input'];
  reportPackageId: Scalars['String']['input'];
  reportFileSource?: InputMaybe<ReportFileSource>;
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<DimensionSortMode>;
}>;


export type ReadReportArchiveQuery = { readReportArchive: (
    Pick<Statement, 'kind'>
    & { rows: Array<(
      Pick<StatementRow, 'rowId' | 'depth'>
      & { sectionTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, sectionSummary?: Maybe<(
        Pick<StatementRowSummary, 'displayNumber' | 'label'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, parentCategoryTitle?: Maybe<Pick<StatementRowTitle, 'displayNumber' | 'title'>>, parentCategorySummary?: Maybe<(
        Pick<StatementRowSummary, 'displayNumber' | 'label'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, leafCategorySummary?: Maybe<(
        Pick<StatementRowSummary, 'displayNumber' | 'label'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ) }
      )>, details?: Maybe<{ hover: (
          { __typename: 'CategoryHover' }
          & Pick<CategoryHover, 'balance'>
          & { entity: (
            { __typename: 'EntityCategory' }
            & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
          ), history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ), context?: Maybe<(
            Pick<DimensionSummary, 'partialData'>
            & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          )>, topTransactions?: Maybe<Array<(
            Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        ) | (
          { __typename: 'PartyHover' }
          & { entity: (
            { __typename: 'EntityParty' }
            & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
          ), history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ), context?: Maybe<(
            Pick<DimensionSummary, 'partialData'>
            & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          )>, topTransactions?: Maybe<Array<(
            Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        ) | (
          { __typename: 'SummaryLineItemHover' }
          & Pick<SummaryLineItemHover, 'lineType'>
          & { history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ) }
        ), insight?: Maybe<(
          Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
          & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
        )> }>, ytdDetails?: Maybe<{ hover: (
          { __typename: 'CategoryHover' }
          & Pick<CategoryHover, 'balance'>
          & { entity: (
            { __typename: 'EntityCategory' }
            & Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>
          ), history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ), context?: Maybe<(
            Pick<DimensionSummary, 'partialData'>
            & { party: Array<{ partyObject: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>, summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          )>, topTransactions?: Maybe<Array<(
            Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        ) | (
          { __typename: 'PartyHover' }
          & { entity: (
            { __typename: 'EntityParty' }
            & Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>
          ), history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ), context?: Maybe<(
            Pick<DimensionSummary, 'partialData'>
            & { category: Array<{ categoryObject: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          )>, topTransactions?: Maybe<Array<(
            Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
            & { moneyFlow: (
              Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
              & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
            ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
          )>> }
        ) | (
          { __typename: 'SummaryLineItemHover' }
          & Pick<SummaryLineItemHover, 'lineType'>
          & { history: (
            Pick<DimensionSummary, 'partialData'>
            & { time: Array<{ summary: (
                Pick<TransactionSummary, 'isPeriodBookClosed'>
                & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
                  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
                  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
                    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                  )>, current?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )>, prior?: Maybe<(
                    Pick<SummaryTotal, 'count'>
                    & { total: (
                      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
                      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
                    ) }
                  )> }
                ) }
              ) }> }
          ) }
        ), insight?: Maybe<(
          Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
          & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
        )> }>, deltas?: Maybe<{ percentageTotal?: Maybe<(
          Pick<StatementDeltaValue, 'percentageOfAmount'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, percentageIncome?: Maybe<(
          Pick<StatementDeltaValue, 'percentageOfAmount'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, previousPeriod?: Maybe<(
          Pick<StatementDeltaValue, 'percentageOfAmount'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )>, previousYear?: Maybe<(
          Pick<StatementDeltaValue, 'percentageOfAmount'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ) }
        )> }> }
    )> }
  ) };

export type ActivateCurrentUserEmploymentMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type ActivateCurrentUserEmploymentMutation = Pick<Mutation, 'activateCurrentUserEmployment'>;

export type CreateEmployeeAffiliationsMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  organizationRole: Scalars['String']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  givenName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  affiliations: Array<LegalEntityRole> | LegalEntityRole;
}>;


export type CreateEmployeeAffiliationsMutation = Pick<Mutation, 'createEmployeeAffiliations'>;

export type DeactivateEmployeeMutationVariables = Exact<{
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type DeactivateEmployeeMutation = Pick<Mutation, 'deactivateEmployee'>;

export type UpdateEmployeeAffiliationsMutationVariables = Exact<{
  employeeIdentifier: EmployeeIdentifier;
  affiliations: Array<LegalEntityRole> | LegalEntityRole;
}>;


export type UpdateEmployeeAffiliationsMutation = Pick<Mutation, 'updateEmployeeAffiliations'>;

export type UpdateEmployeeMutationVariables = Exact<{
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<RoleIdentifier>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  fieldToUpdate: UpdatableEmployeeField;
}>;


export type UpdateEmployeeMutation = Pick<Mutation, 'updateEmployee'>;

export type DeleteEmployeeAffiliationsMutationVariables = Exact<{
  employeeIdentifier: EmployeeIdentifier;
  legalEntityIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteEmployeeAffiliationsMutation = Pick<Mutation, 'deleteEmployeeAffiliations'>;

export type CreateEmployeeMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  role: RoleIdentifier;
}>;


export type CreateEmployeeMutation = { createEmployee: (
    Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
    & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    )>, invitation?: Maybe<(
      Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    )> }
  ) };

export type CountEmployeesQueryVariables = Exact<{
  filter: EmployeeFilter;
}>;


export type CountEmployeesQuery = { countEmployees: Pick<ListCount, 'count'> };

export type ListEmployeesAffiliationsQueryVariables = Exact<{
  filter: EmployeeFilter;
  pagination: Pagination;
  sort?: InputMaybe<EmployeeSort>;
}>;


export type ListEmployeesAffiliationsQuery = { listEmployeesAffiliations: Array<{ employee: (
      Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
      & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
        Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
        & { employments?: Maybe<Array<(
          Pick<OrganizationEmployment, 'employeeId'>
          & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
        )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
      )>, invitation?: Maybe<(
        Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
        & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
      )> }
    ), affiliations: Array<{ organization: Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>, legalEntity: Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>, role: Pick<Role, 'id'> }> }> };

export type ListEmployeesQueryVariables = Exact<{
  filter: EmployeeFilter;
  pagination: Pagination;
  sort?: InputMaybe<EmployeeSort>;
}>;


export type ListEmployeesQuery = { listEmployees: Array<(
    Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
    & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    )>, invitation?: Maybe<(
      Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    )> }
  )> };

export type SendManagedOperatorInvitationMutationVariables = Exact<{
  recipient: ContactInput;
  legalEntityId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  contextObject?: InputMaybe<InputObjectIdentifier>;
}>;


export type SendManagedOperatorInvitationMutation = Pick<Mutation, 'sendManagedOperatorInvitation'>;

export type ResendInvitationMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ResendInvitationMutation = Pick<Mutation, 'resendInvitation'>;

export type ResendManagedOperatorInvitationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  message: Scalars['String']['input'];
}>;


export type ResendManagedOperatorInvitationMutation = Pick<Mutation, 'resendManagedOperatorInvitation'>;

export type SendInstructionsAffiliateMutationVariables = Exact<{
  givenName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  destinationOrganizationId: Scalars['String']['input'];
  invitationType: Scalars['String']['input'];
  proposalType: Scalars['String']['input'];
}>;


export type SendInstructionsAffiliateMutation = Pick<Mutation, 'sendInstructionsAffiliate'>;

export type CreateAffiliationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  iconBase64?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  givenName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateAffiliationMutation = { createAffiliation: (
    Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
  ) };

export type CancelInvitationMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type CancelInvitationMutation = Pick<Mutation, 'cancelInvitation'>;

export type SendInstructionsMutationVariables = Exact<{
  givenName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  destinationOrganizationId: Scalars['String']['input'];
  invitationType: Scalars['String']['input'];
  proposalType?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendInstructionsMutation = Pick<Mutation, 'sendInstructions'>;

export type SendInvitationMutationVariables = Exact<{
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  destinationOrganizationId?: InputMaybe<Scalars['String']['input']>;
  destinationOrganizationRole?: InputMaybe<Scalars['String']['input']>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  invitationType: Scalars['String']['input'];
  proposalType?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendInvitationMutation = Pick<Mutation, 'sendInvitation'>;

export type ReadUserInvitationQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadUserInvitationQuery = { readUserInvitation?: Maybe<(
    Pick<Invitation, 'service' | 'invitationType' | 'organizationTemplateId'>
    & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress'>, sender?: Maybe<Pick<Contact, 'givenName' | 'avatarUrl' | 'emailAddress'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name'>> }
  )> };

export type TransactionFieldsFragment = (
  Pick<Transaction, 'viewId' | 'factId' | 'supersededFactIds' | 'timestamp' | 'recordType' | 'name' | 'description' | 'digitsTransactionType' | 'partySource' | 'displayEntry' | 'displayCategorySource' | 'displayCanonicalCategoryName' | 'displayUncategorizedReason' | 'displayProductArea' | 'ownerSource' | 'transactionClassificationType' | 'compileSource' | 'reconciliationStatus' | 'referenceNumber' | 'taxStatus'>
  & { party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, facilitatorParty?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, product?: Maybe<Pick<Product, 'id' | 'name'>>, department?: Maybe<(
    { __typename: 'EntityDepartment' }
    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
  )>, location?: Maybe<(
    { __typename: 'EntityLocation' }
    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
  )>, providers: Array<Pick<Provider, 'name'>>, recurrence?: Maybe<Pick<TransactionRecurrence, 'recurrenceThreadId' | 'interval' | 'intervalMultiplier' | 'threadIndex' | 'totalThreadCount' | 'isHidden' | 'firstOccurredAt' | 'lastOccurredAt'>>, displayInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, displayCategory: (
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  ), splitCategory?: Maybe<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )>, splitInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, moneyFlow: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), runningTotal?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, owner?: Maybe<Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>>, sources: Array<(
    Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
    & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
  )>, creditSources: Array<(
    Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
    & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
  )>, debitSources: Array<(
    Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
    & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
  )> }
);

export type IdentityFieldsFragment = Pick<Identity, 'identifier' | 'provider' | 'domain'>;

export type InstitutionFieldsFragment = Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>;

export type ReportGenerationHistoryFieldsFragment = Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>;

export type TransactionLineFieldsFragment = (
  Pick<TransactionLine, 'entryType'>
  & { entry: (
    Pick<TransactionEntry, 'factId' | 'externalLineId' | 'description'>
    & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }
);

export type TransactionEntryFieldsFragment = (
  Pick<TransactionEntry, 'factId' | 'externalLineId' | 'description'>
  & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type MoneyFlowFieldsFragment = (
  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type ConnectorFieldsFragment = (
  Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
  & { connectorProperties?: Maybe<(
    { __typename: 'EmptyConnectorProperties' }
    & Pick<EmptyConnectorProperties, 'empty'>
  ) | (
    { __typename: 'GustoConnectorProperties' }
    & Pick<GustoConnectorProperties, 'authorizationURL'>
  ) | (
    { __typename: 'PlaidConnectorProperties' }
    & Pick<PlaidConnectorProperties, 'empty'>
  ) | (
    { __typename: 'RampConnectorProperties' }
    & Pick<RampConnectorProperties, 'authorizationURL'>
  ) | (
    { __typename: 'StripeConnectorProperties' }
    & Pick<StripeConnectorProperties, 'appLinkURL'>
  )> }
);

export type ReportPackageMetadataFieldsFragment = (
  Pick<ReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'coverUrl' | 'publishedAsDraft' | 'createdAt' | 'updatedAt' | 'creationMethod'>
  & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
    Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
    & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
  )>, versions: Array<Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>> }
);

export type ReportPackageVersionMetadataFieldsFragment = Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>;

export type WorkItemPushFieldsFragment = Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>;

export type PredictedDataSourceFieldsFragment = (
  Pick<PredictedDataSource, 'providerType' | 'type'>
  & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
);

export type TransactionViewDetailFieldsFragment = (
  Pick<TransactionViewDetail, 'id' | 'factId' | 'occurredAt' | 'externalCreatedAt' | 'externalId' | 'externalDisplayId' | 'quickbooksAppName' | 'name' | 'description' | 'recordType'>
  & { creditAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, creditCategoryHierarchy: Array<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )>, debitCategoryHierarchy: Array<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )>, entryLines: Array<(
    Pick<TransactionEntryLine, 'lineId' | 'name' | 'description' | 'isCredit' | 'highlighted'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, category: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ) }
  )>, party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
);

export type WorkItemGeneratedQuestionFieldsFragment = Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>;

export type ThreadFieldsFragment = (
  { __typename: 'Thread' }
  & Pick<Thread, 'id'>
  & { details: (
    Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
    & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
  ), comments: Array<(
    { __typename: 'Comment' }
    & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
    & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
  )> }
);

export type LegalEntityFieldsFragment = (
  Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
  & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
);

export type ProductInstitutionFieldsFragment = (
  Pick<ProductInstitution, 'id' | 'name' | 'products' | 'routingNumber'>
  & { party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
);

export type DepartmentFieldsFragment = (
  Pick<Department, 'id' | 'name' | 'parentId' | 'active'>
  & { ancestors: Array<(
    { __typename: 'EntityDepartment' }
    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
  )> }
);

export type WorkItemSummaryFieldsFragment = (
  Pick<WorkItemSummary, 'count'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
);

export type InvitationProposalFieldsFragment = (
  Pick<InvitationProposal, 'proposalId' | 'proposalType' | 'status' | 'invitationId' | 'organizationTemplateId' | 'createdAt'>
  & { legalEntity?: Maybe<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>, organization?: Maybe<Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>>, sender?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
);

export type EmployeeFieldsFragment = (
  Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
  & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )>, invitation?: Maybe<(
    Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
    & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
  )> }
);

export type SourceAccountFieldsFragment = Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>;

export type DocumentInitialSegmentFieldsFragment = Pick<DocumentInitialSegment, 'collectionId' | 'documentId' | 'downloadUrl'>;

export type ThreadsFieldsFragment = { threads: Array<{ targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, threads: Array<(
      Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
      & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
    )> }>, entities?: Maybe<(
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  )> };

export type SignupFieldsFragment = (
  Pick<Signup, 'id' | 'status' | 'statusReason' | 'location' | 'industry' | 'companyName' | 'companyUrl' | 'companyLedger' | 'companyCurrency' | 'positionTitle' | 'profileURL' | 'oAuthEmailAddress' | 'oAuthService' | 'persona'>
  & { contact: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
);

export type ThreadWithEntitiesFieldsFragment = (
  { __typename: 'ThreadWithEntities' }
  & { thread: (
    { __typename: 'Thread' }
    & Pick<Thread, 'id'>
    & { details: (
      Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
      & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
    ), comments: Array<(
      { __typename: 'Comment' }
      & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
      & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
    )> }
  ), entities?: Maybe<(
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  )> }
);

export type InvitationFieldsFragment = (
  Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
  & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
);

export type BooksClosedDateFieldsFragment = (
  Pick<BooksClosedDate, 'legalEntityId' | 'latestBooksClosedDateFact' | 'viewBooksClosedDate'>
  & { user?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
);

export type LegalEntityUserInfoFieldsFragment = { legalEntity: (
    Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
  ), userInfo: Pick<UserInfo, 'givenName' | 'familyName' | 'emailAddress'> };

export type CategoryFieldsFragment = (
  Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
  & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
);

export type ChartOfAccountsFieldsFragment = (
  Pick<ChartOfAccounts, 'id' | 'name' | 'description' | 'accountingBasis'>
  & { categories: Array<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )> }
);

export type OwnedSourceAccountFieldsFragment = { sourceAccount: Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>, owner?: Maybe<(
    Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
    & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    )>, invitation?: Maybe<(
      Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    )> }
  )> };

export type HighlightedTokenFieldsFragment = Pick<HighlightedToken, 'token' | 'score'>;

export type BooksClosedFactFieldsFragment = Pick<BooksClosedFact, 'legalEntityId' | 'dataSourceId' | 'userId' | 'booksClosedDate' | 'createdAt'>;

export type OrganizationPreviewFieldsFragment = Pick<OrganizationPreview, 'id' | 'slug' | 'name' | 'iconUrl'>;

export type TransactionSummaryFieldsFragment = (
  Pick<TransactionSummary, 'isPeriodBookClosed'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, current?: Maybe<(
      Pick<SummaryTotal, 'count'>
      & { total: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )>, prior?: Maybe<(
      Pick<SummaryTotal, 'count'>
      & { total: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )> }
  ) }
);

export type DataSourceFieldsFragment = (
  Pick<DataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt'>
  & { institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'> }
);

export type EnrichedDataSourceFieldsFragment = (
  Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
  & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
);

export type QuickBooksConfigFieldsFragment = Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>;

export type PlaidConfigFieldsFragment = Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>;

export type RefreshProgressFieldsFragment = Pick<RefreshProgress, 'mostRecentFetchStartTime' | 'progressPercentage'>;

export type EmployeeAffiliationsFieldsFragment = { employee: (
    Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
    & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    )>, invitation?: Maybe<(
      Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    )> }
  ), affiliations: Array<{ organization: Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>, legalEntity: Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>, role: Pick<Role, 'id'> }> };

export type ViewIdentifierFieldsFragment = Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>;

export type MonetaryValueFieldsFragment = Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>;

export type ProductFieldsFragment = Pick<Product, 'id' | 'name'>;

export type OrganizationFieldsFragment = Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>;

export type LocationFieldsFragment = (
  Pick<Location, 'id' | 'name' | 'parentId' | 'active'>
  & { ancestors: Array<(
    { __typename: 'EntityLocation' }
    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
  )> }
);

export type TransactionPartyFieldsFragment = Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>;

export type UserInfoFieldsFragment = Pick<UserInfo, 'givenName' | 'familyName' | 'emailAddress'>;

export type WorkItemFieldsFragment = (
  Pick<WorkItem, 'legalEntityId' | 'workItemId' | 'time' | 'itemType' | 'itemState' | 'ledgerType' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'actionable' | 'reviewState'>
  & { transactions: Array<(
    Pick<WorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'displayPartyRole' | 'displayEntry' | 'description' | 'name' | 'occurredAt' | 'dataSourceId' | 'quickbooksAppName' | 'ledgerTransactionId'>
    & { displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, creditValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, displayValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, moneyFlow?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )> }
  )>, review?: Maybe<(
    Pick<WorkItemReview, 'createdAt' | 'updatedAt' | 'subject'>
    & { creator: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
  )>, generatedQuestions: Array<Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>>, pushes: Array<Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>> }
);

export type AddressFieldsFragment = Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>;

export type InsightFieldsFragment = (
  Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
  & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
);

export type WorkItemReviewFieldsFragment = (
  Pick<WorkItemReview, 'createdAt' | 'updatedAt' | 'subject'>
  & { creator: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
);

export type ThreadDetailsFieldsFragment = (
  Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
  & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
);

export type PartyFieldsFragment = Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>;

export type DateFieldsFragment = Pick<Date, 'year' | 'month' | 'day'>;

export type ArchiveIntervalOriginFieldsFragment = Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>;

export type ThreadListFieldsFragment = { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, threads: Array<(
    Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
    & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
  )> };

export type PeriodFieldsFragment = Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>;

export type WorkItemTransactionFieldsFragment = (
  Pick<WorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'displayPartyRole' | 'displayEntry' | 'description' | 'name' | 'occurredAt' | 'dataSourceId' | 'quickbooksAppName' | 'ledgerTransactionId'>
  & { displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, creditValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, displayValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, moneyFlow?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )> }
);

export type ReportPackageDocumentMetadataFieldsFragment = (
  Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
  & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
);

export type PeriodMonetaryValueFieldsFragment = (
  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, current?: Maybe<(
    Pick<SummaryTotal, 'count'>
    & { total: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )>, prior?: Maybe<(
    Pick<SummaryTotal, 'count'>
    & { total: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )> }
);

export type CommentFieldsFragment = (
  { __typename: 'Comment' }
  & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
  & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
);

export type TransactionViewDetailsFieldsFragment = { details: Array<(
    Pick<TransactionViewDetail, 'id' | 'factId' | 'occurredAt' | 'externalCreatedAt' | 'externalId' | 'externalDisplayId' | 'quickbooksAppName' | 'name' | 'description' | 'recordType'>
    & { creditAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, creditCategoryHierarchy: Array<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )>, debitCategoryHierarchy: Array<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )>, entryLines: Array<(
      Pick<TransactionEntryLine, 'lineId' | 'name' | 'description' | 'isCredit' | 'highlighted'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, category: (
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      ) }
    )>, party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
  )> };

export type SummaryTotalFieldsFragment = (
  Pick<SummaryTotal, 'count'>
  & { total: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) }
);

export type HighlightedTokensFieldsFragment = { description?: Maybe<Array<Pick<HighlightedToken, 'token' | 'score'>>>, name?: Maybe<Array<Pick<HighlightedToken, 'token' | 'score'>>> };

export type ContactFieldsFragment = Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>;

export type TransactionEntryLineFieldsFragment = (
  Pick<TransactionEntryLine, 'lineId' | 'name' | 'description' | 'isCredit' | 'highlighted'>
  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, category: (
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  ) }
);

export type EntityLedgerTransactionFieldsFragment = Pick<EntityLedgerTransaction, 'id' | 'timestamp' | 'factIds'>;

export type EntityOrganizationFieldsFragment = Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>;

export type EntityReportFieldsFragment = (
  Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
  & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
);

export type EntityTransactionFieldsFragment = (
  Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
  & { moneyFlow: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
);

export type EntityLocationFieldsFragment = (
  { __typename: 'EntityLocation' }
  & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
);

export type EntityPartyFieldsFragment = Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>;

export type EntityInvoiceFieldsFragment = (
  Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
  & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
    Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
    & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
  ) }
);

export type EntityWorkItemFieldsFragment = (
  Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
  & { objectTransaction: (
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  ) }
);

export type EntityMonetaryValueFieldsFragment = Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>;

export type EntityLegalEntityFieldsFragment = Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>;

export type BillAccessFieldsFragment = Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>;

export type EntityDepartmentFieldsFragment = (
  { __typename: 'EntityDepartment' }
  & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
);

export type EntityReportPackageFieldsFragment = (
  Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
  & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
);

export type SharedReportDocumentMetadataFieldsFragment = Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>;

export type EntityUserFieldsFragment = Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>;

export type ObjectIdentifierFieldsFragment = Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>;

export type ActivityObjectIdentifierFieldsFragment = Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'>;

export type EntityInvoiceCustomerFieldsFragment = (
  Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
  & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
);

export type EntityEmployeeFieldsFragment = Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>;

export type EntityCommentFieldsFragment = Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>;

export type EntityIdentityVerificationFieldsFragment = Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>;

export type SegmentFieldsFragment = Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>;

export type EntityThreadFieldsFragment = Pick<EntityThread, 'id' | 'resolvedAt'>;

export type EntityCategoryFieldsFragment = Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>;

export type EntityBillFieldsFragment = (
  Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
  & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
    Pick<Format, 'id' | 'formatClass'>
    & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
  ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
);

export type EntityDataSourceFieldsFragment = Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>;

export type ObjectEntitiesFieldsFragment = (
  { __typename: 'ObjectEntities' }
  & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
    Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
    & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
  )>>, reportPackages?: Maybe<Array<(
    Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
    & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
  )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
    Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
    & { objectTransaction: (
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    ) }
  )>>, invoices?: Maybe<Array<(
    Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
    & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
      Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
      & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
    ) }
  )>>, bills?: Maybe<Array<(
    Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
    & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
      Pick<Format, 'id' | 'formatClass'>
      & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
    ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
  )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
    { __typename: 'EntityDepartment' }
    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
  )>>, locations?: Maybe<Array<(
    { __typename: 'EntityLocation' }
    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
  )>> }
);

export type RoleFieldsFragment = Pick<Role, 'id'>;

export type ReportFileMetadataFieldsFragment = Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>;

export type DataSourcePreferenceFieldsFragment = Pick<DataSourcePreference, 'dataSourceId' | 'accountingBasis' | 'taxForm' | 'providerType' | 'departmentTerminology'>;

export type TransactionSourceFieldsFragment = (
  Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
  & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
);

export type DoppelgangerPermitFieldsFragment = (
  Pick<DoppelgangerPermit, 'id' | 'expiresAt' | 'createdAt'>
  & { user: (
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  ) }
);

export type CategoryMappingFieldsFragment = (
  Pick<CategoryMapping, 'mappingValue' | 'mappingType' | 'product'>
  & { category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'> }
);

export const DateFieldsFragmentDoc = gql`
    fragment DateFields on Date {
  year
  month
  day
}
    `;
export const QuickBooksAccountingInfoPreferencesFieldsFragmentDoc = gql`
    fragment QuickBooksAccountingInfoPreferencesFields on QuickBooksAccountingInfoPreferences {
  bookCloseDate {
    ...DateFields
  }
}
    ${DateFieldsFragmentDoc}`;
export const QuickBooksPreferencesFieldsFragmentDoc = gql`
    fragment QuickBooksPreferencesFields on QuickBooksPreferences {
  accountingInfo {
    ...QuickBooksAccountingInfoPreferencesFields
  }
}
    ${QuickBooksAccountingInfoPreferencesFieldsFragmentDoc}`;
export const QuickBooksCompanyFieldsFragmentDoc = gql`
    fragment QuickBooksCompanyFields on QuickBooksCompany {
  preferences {
    ...QuickBooksPreferencesFields
  }
}
    ${QuickBooksPreferencesFieldsFragmentDoc}`;
export const PlaidLinkTokenFieldsFragmentDoc = gql`
    fragment PlaidLinkTokenFields on PlaidLinkToken {
  token
}
    `;
export const EntityLegalEntityFieldsFragmentDoc = gql`
    fragment EntityLegalEntityFields on EntityLegalEntity {
  id
  name
  slug
}
    `;
export const EntityOrganizationFieldsFragmentDoc = gql`
    fragment EntityOrganizationFields on EntityOrganization {
  id
  name
  slug
  iconUrl
}
    `;
export const OrganizationAffiliationFieldsFragmentDoc = gql`
    fragment OrganizationAffiliationFields on OrganizationAffiliation {
  legalEntity {
    ...EntityLegalEntityFields
  }
  organization {
    ...EntityOrganizationFields
  }
}
    ${EntityLegalEntityFieldsFragmentDoc}
${EntityOrganizationFieldsFragmentDoc}`;
export const ActionItemsObjectKindSummaryFieldsFragmentDoc = gql`
    fragment ActionItemsObjectKindSummaryFields on ActionItemsObjectKindSummary {
  objectKind
  count
}
    `;
export const PeriodFieldsFragmentDoc = gql`
    fragment PeriodFields on Period {
  name
  interval
  startedAt
  endedAt
}
    `;
export const ActionItemSummaryFieldsFragmentDoc = gql`
    fragment ActionItemSummaryFields on ActionItemSummary {
  period {
    ...PeriodFields
  }
  count
}
    ${PeriodFieldsFragmentDoc}`;
export const EntityUserFieldsFragmentDoc = gql`
    fragment EntityUserFields on EntityUser {
  id
  givenName
  familyName
  emailAddress
  avatarUrl
}
    `;
export const EntityCategoryFieldsFragmentDoc = gql`
    fragment EntityCategoryFields on EntityCategory {
  id
  name
  displayKey
  chartOfAccountsDisplayNumber
  type
  subtype
  parentCategoryId
  productArea
  active
}
    `;
export const EntityPartyFieldsFragmentDoc = gql`
    fragment EntityPartyFields on EntityParty {
  id
  name
  iconUrl
  shortDescription
  roles
  designation
}
    `;
export const SharedReportDocumentMetadataFieldsFragmentDoc = gql`
    fragment SharedReportDocumentMetadataFields on SharedReportDocumentMetadata {
  id
  documentObjectId
  documentObjectVersionId
  title
  mimeType
}
    `;
export const EntityReportPackageFieldsFragmentDoc = gql`
    fragment EntityReportPackageFields on EntityReportPackage {
  id
  title
  authorId
  interval
  periodStartedAt
  periodEndedAt
  createdAt
  updatedAt
  documents {
    ...SharedReportDocumentMetadataFields
  }
  coverUrl
}
    ${SharedReportDocumentMetadataFieldsFragmentDoc}`;
export const MonetaryValueFieldsFragmentDoc = gql`
    fragment MonetaryValueFields on MonetaryValue {
  iso4217CurrencyCode
  currencyMultiplier
  amount
}
    `;
export const MoneyFlowFieldsFragmentDoc = gql`
    fragment MoneyFlowFields on MoneyFlow {
  value {
    ...MonetaryValueFields
  }
  isNormal
  businessFlow
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const EntityTransactionFieldsFragmentDoc = gql`
    fragment EntityTransactionFields on EntityTransaction {
  viewId
  factId
  timestamp
  description
  externalId
  moneyFlow {
    ...MoneyFlowFields
  }
  party {
    ...EntityPartyFields
  }
  displayCategory {
    ...EntityCategoryFields
  }
  splitCategory {
    ...EntityCategoryFields
  }
  displayEntry
}
    ${MoneyFlowFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}`;
export const EntityWorkItemFieldsFragmentDoc = gql`
    fragment EntityWorkItemFields on EntityWorkItem {
  id
  time
  workItemState
  workItemType
  reviewCreatedById
  objectTransaction {
    ...EntityTransactionFields
  }
  reviewState
  ledgerType
}
    ${EntityTransactionFieldsFragmentDoc}`;
export const EntityLedgerTransactionFieldsFragmentDoc = gql`
    fragment EntityLedgerTransactionFields on EntityLedgerTransaction {
  id
  timestamp
  factIds
}
    `;
export const EntityMonetaryValueFieldsFragmentDoc = gql`
    fragment EntityMonetaryValueFields on EntityMonetaryValue {
  iso4217CurrencyCode
  currencyMultiplier
  amount
}
    `;
export const SegmentFieldsFragmentDoc = gql`
    fragment SegmentFields on Segment {
  collectionId
  fileId
  sequence
  token
}
    `;
export const BillAccessFieldsFragmentDoc = gql`
    fragment BillAccessFields on BillAccess {
  objectId
  objectKind
  role
}
    `;
export const EntityBillFieldsFragmentDoc = gql`
    fragment EntityBillFields on EntityBill {
  id
  billStatus
  vendorName
  totalAmount {
    ...EntityMonetaryValueFields
  }
  dueDate {
    ...DateFields
  }
  billDescription
  format {
    id
    formatClass
    segments {
      ...SegmentFields
    }
  }
  approvalAccess {
    ...BillAccessFields
  }
}
    ${EntityMonetaryValueFieldsFragmentDoc}
${DateFieldsFragmentDoc}
${SegmentFieldsFragmentDoc}
${BillAccessFieldsFragmentDoc}`;
export const EntityDepartmentFieldsFragmentDoc = gql`
    fragment EntityDepartmentFields on EntityDepartment {
  __typename
  id
  name
  parentId
  active
}
    `;
export const EntityLocationFieldsFragmentDoc = gql`
    fragment EntityLocationFields on EntityLocation {
  __typename
  id
  name
  parentId
  active
}
    `;
export const ActionItemThreadFieldsFragmentDoc = gql`
    fragment ActionItemThreadFields on ActionItemThread {
  threadId
  targetEntity {
    __typename
    ... on EntityCategory {
      ...EntityCategoryFields
    }
    ... on EntityParty {
      ...EntityPartyFields
    }
    ... on EntityReportPackage {
      ...EntityReportPackageFields
    }
    ... on EntityWorkItem {
      ...EntityWorkItemFields
    }
    ... on EntityLedgerTransaction {
      ...EntityLedgerTransactionFields
    }
    ... on EntityBill {
      ...EntityBillFields
    }
    ... on EntityDepartment {
      ...EntityDepartmentFields
    }
    ... on EntityLocation {
      ...EntityLocationFields
    }
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${EntityReportPackageFieldsFragmentDoc}
${EntityWorkItemFieldsFragmentDoc}
${EntityLedgerTransactionFieldsFragmentDoc}
${EntityBillFieldsFragmentDoc}
${EntityDepartmentFieldsFragmentDoc}
${EntityLocationFieldsFragmentDoc}`;
export const ActionItemBillApprovalFieldsFragmentDoc = gql`
    fragment ActionItemBillApprovalFields on ActionItemBillApproval {
  bill {
    ...EntityBillFields
  }
}
    ${EntityBillFieldsFragmentDoc}`;
export const ActionItemPublishedReportFieldsFragmentDoc = gql`
    fragment ActionItemPublishedReportFields on ActionItemPublishedReport {
  package {
    ...EntityReportPackageFields
  }
}
    ${EntityReportPackageFieldsFragmentDoc}`;
export const CategorySuggestionFieldsFragmentDoc = gql`
    fragment CategorySuggestionFields on CategorySuggestion {
  dataSourceId
  transactionFactId
  classificationVersionId
  categoryId
  type
  modelConfidence
  modelVersionId
  priority
  createdAt
  updatedAt
}
    `;
export const ActionItemTransactionFieldsFragmentDoc = gql`
    fragment ActionItemTransactionFields on ActionItemTransaction {
  transaction {
    ...EntityTransactionFields
  }
  categorySuggestions {
    ...CategorySuggestionFields
  }
}
    ${EntityTransactionFieldsFragmentDoc}
${CategorySuggestionFieldsFragmentDoc}`;
export const ActionItemFieldsFragmentDoc = gql`
    fragment ActionItemFields on ActionItem {
  id
  title
  author {
    ...EntityUserFields
  }
  createdAt
  updatedAt
  value {
    __typename
    ... on ActionItemThread {
      ...ActionItemThreadFields
    }
    ... on ActionItemBillApproval {
      ...ActionItemBillApprovalFields
    }
    ... on ActionItemPublishedReport {
      ...ActionItemPublishedReportFields
    }
    ... on ActionItemTransaction {
      ...ActionItemTransactionFields
    }
  }
}
    ${EntityUserFieldsFragmentDoc}
${ActionItemThreadFieldsFragmentDoc}
${ActionItemBillApprovalFieldsFragmentDoc}
${ActionItemPublishedReportFieldsFragmentDoc}
${ActionItemTransactionFieldsFragmentDoc}`;
export const VanityStatsFieldsFragmentDoc = gql`
    fragment VanityStatsFields on VanityStats {
  totalTransactionsValue {
    ...MonetaryValueFields
  }
  totalParties
  totalPartiesIdentified
  totalCalculations
  totalTransactions
  totalTransactionsEnhanced
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const ReconciliationPeriodStatsFieldsFragmentDoc = gql`
    fragment ReconciliationPeriodStatsFields on ReconciliationPeriodStats {
  notStarted
  noActivity
  drafts
  finalized
  totalCategories
}
    `;
export const TransactionCountsFieldsFragmentDoc = gql`
    fragment TransactionCountsFields on TransactionCounts {
  reconciled
  unsettled
}
    `;
export const LedgerBalancesFieldsFragmentDoc = gql`
    fragment LedgerBalancesFields on LedgerBalances {
  debitTotal {
    ...MoneyFlowFields
  }
  creditTotal {
    ...MoneyFlowFields
  }
  netTotal {
    ...MoneyFlowFields
  }
  unsettledTotal {
    ...MoneyFlowFields
  }
  closingBalance {
    ...MoneyFlowFields
  }
}
    ${MoneyFlowFieldsFragmentDoc}`;
export const StatementBalancesFieldsFragmentDoc = gql`
    fragment StatementBalancesFields on StatementBalances {
  debitTotal {
    ...MoneyFlowFields
  }
  creditTotal {
    ...MoneyFlowFields
  }
  netTotal {
    ...MoneyFlowFields
  }
  openingBalance {
    ...MoneyFlowFields
  }
  closingBalance {
    ...MoneyFlowFields
  }
}
    ${MoneyFlowFieldsFragmentDoc}`;
export const LedgerAccountDifferencesFieldsFragmentDoc = gql`
    fragment LedgerAccountDifferencesFields on LedgerAccountDifferences {
  debitDifference {
    ...MoneyFlowFields
  }
  creditDifference {
    ...MoneyFlowFields
  }
  unreconciledDifference {
    ...MoneyFlowFields
  }
}
    ${MoneyFlowFieldsFragmentDoc}`;
export const ReconciliationBalancesFieldsFragmentDoc = gql`
    fragment ReconciliationBalancesFields on ReconciliationBalances {
  ledgerBalances {
    ...LedgerBalancesFields
  }
  statementBalances {
    ...StatementBalancesFields
  }
  ledgerAccountDifferences {
    ...LedgerAccountDifferencesFields
  }
}
    ${LedgerBalancesFieldsFragmentDoc}
${StatementBalancesFieldsFragmentDoc}
${LedgerAccountDifferencesFieldsFragmentDoc}`;
export const AccountReconciliationFieldsFragmentDoc = gql`
    fragment AccountReconciliationFields on AccountReconciliation {
  chartOfAccountsId
  categoryId
  category {
    ...EntityCategoryFields
  }
  reconciliationId
  startDate {
    ...DateFields
  }
  endDate {
    ...DateFields
  }
  status
  updater {
    ...EntityUserFields
  }
  createdAt
  updatedAt
}
    ${EntityCategoryFieldsFragmentDoc}
${DateFieldsFragmentDoc}
${EntityUserFieldsFragmentDoc}`;
export const QuotePackageIdFieldsFragmentDoc = gql`
    fragment QuotePackageIdFields on QuotePackageId {
  id
}
    `;
export const ObjectIdentifierFieldsFragmentDoc = gql`
    fragment ObjectIdentifierFields on ObjectIdentifier {
  legalEntityId
  id
  kind
}
    `;
export const UserGrantFieldsFragmentDoc = gql`
    fragment UserGrantFields on UserGrant {
  grantId
  userId
  permission
}
    `;
export const UserIntentFieldsFragmentDoc = gql`
    fragment UserIntentFields on UserIntent {
  intentId
  emailAddress
  permission
}
    `;
export const UserRequestFieldsFragmentDoc = gql`
    fragment UserRequestFields on UserRequest {
  requestId
  userId
}
    `;
export const EntityCommentFieldsFragmentDoc = gql`
    fragment EntityCommentFields on EntityComment {
  id
  authorId
  text
  timestamp
}
    `;
export const EntityReportFieldsFragmentDoc = gql`
    fragment EntityReportFields on EntityReport {
  id
  title
  authorId
  mimeType
  reportFor {
    ...DateFields
  }
  createdAt
}
    ${DateFieldsFragmentDoc}`;
export const EntityDataSourceFieldsFragmentDoc = gql`
    fragment EntityDataSourceFields on EntityDataSource {
  id
  name
  url
  iconUrl
}
    `;
export const EntityThreadFieldsFragmentDoc = gql`
    fragment EntityThreadFields on EntityThread {
  id
  resolvedAt
}
    `;
export const EntityInvoiceCustomerFieldsFragmentDoc = gql`
    fragment EntityInvoiceCustomerFields on EntityInvoiceCustomer {
  name
  phone
  email
  party {
    ...EntityPartyFields
  }
}
    ${EntityPartyFieldsFragmentDoc}`;
export const EntityInvoiceFieldsFragmentDoc = gql`
    fragment EntityInvoiceFields on EntityInvoice {
  id
  externalDisplayId
  amount {
    ...EntityMonetaryValueFields
  }
  customer {
    ...EntityInvoiceCustomerFields
  }
  createdAt
  dueAt
}
    ${EntityMonetaryValueFieldsFragmentDoc}
${EntityInvoiceCustomerFieldsFragmentDoc}`;
export const EntityIdentityVerificationFieldsFragmentDoc = gql`
    fragment EntityIdentityVerificationFields on EntityIdentityVerification {
  legalEntityId
  status
}
    `;
export const ObjectEntitiesFieldsFragmentDoc = gql`
    fragment ObjectEntitiesFields on ObjectEntities {
  __typename
  users {
    ...EntityUserFields
  }
  transactions {
    ...EntityTransactionFields
  }
  comments {
    ...EntityCommentFields
  }
  reports {
    ...EntityReportFields
  }
  reportPackages {
    ...EntityReportPackageFields
  }
  parties {
    ...EntityPartyFields
  }
  datasources {
    ...EntityDataSourceFields
  }
  categories {
    ...EntityCategoryFields
  }
  threads {
    ...EntityThreadFields
  }
  legalEntities {
    ...EntityLegalEntityFields
  }
  workItems {
    ...EntityWorkItemFields
  }
  invoices {
    ...EntityInvoiceFields
  }
  bills {
    ...EntityBillFields
  }
  identityVerifications {
    ...EntityIdentityVerificationFields
  }
  departments {
    ...EntityDepartmentFields
  }
  locations {
    ...EntityLocationFields
  }
}
    ${EntityUserFieldsFragmentDoc}
${EntityTransactionFieldsFragmentDoc}
${EntityCommentFieldsFragmentDoc}
${EntityReportFieldsFragmentDoc}
${EntityReportPackageFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${EntityDataSourceFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}
${EntityThreadFieldsFragmentDoc}
${EntityLegalEntityFieldsFragmentDoc}
${EntityWorkItemFieldsFragmentDoc}
${EntityInvoiceFieldsFragmentDoc}
${EntityBillFieldsFragmentDoc}
${EntityIdentityVerificationFieldsFragmentDoc}
${EntityDepartmentFieldsFragmentDoc}
${EntityLocationFieldsFragmentDoc}`;
export const ObjectAclFieldsFragmentDoc = gql`
    fragment ObjectACLFields on ObjectACL {
  object {
    ...ObjectIdentifierFields
  }
  grants {
    ...UserGrantFields
  }
  intents {
    ...UserIntentFields
  }
  requests {
    ...UserRequestFields
  }
  link
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ObjectIdentifierFieldsFragmentDoc}
${UserGrantFieldsFragmentDoc}
${UserIntentFieldsFragmentDoc}
${UserRequestFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const ObjectGrantFieldsFragmentDoc = gql`
    fragment ObjectGrantFields on ObjectGrant {
  grantId
  object {
    ...ObjectIdentifierFields
  }
  permission
  createdAt
  viewType
}
    ${ObjectIdentifierFieldsFragmentDoc}`;
export const UserAclFieldsFragmentDoc = gql`
    fragment UserACLFields on UserACL {
  grants {
    ...ObjectGrantFields
  }
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ObjectGrantFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const TeamAclFieldsFragmentDoc = gql`
    fragment TeamACLFields on TeamACL {
  grants {
    ...ObjectGrantFields
  }
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ObjectGrantFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const SummaryTotalFieldsFragmentDoc = gql`
    fragment SummaryTotalFields on SummaryTotal {
  total {
    ...MoneyFlowFields
  }
  count
}
    ${MoneyFlowFieldsFragmentDoc}`;
export const PeriodMonetaryValueFieldsFragmentDoc = gql`
    fragment PeriodMonetaryValueFields on PeriodMonetaryValue {
  value {
    ...MonetaryValueFields
  }
  moneyFlow {
    ...MoneyFlowFields
  }
  current {
    ...SummaryTotalFields
  }
  prior {
    ...SummaryTotalFields
  }
  transactionsCount
  deltaPrevious
  deltaYearAgo
  isFirstOccurrencePeriod
}
    ${MonetaryValueFieldsFragmentDoc}
${MoneyFlowFieldsFragmentDoc}
${SummaryTotalFieldsFragmentDoc}`;
export const TransactionSummaryFieldsFragmentDoc = gql`
    fragment TransactionSummaryFields on TransactionSummary {
  period {
    ...PeriodFields
  }
  total {
    ...PeriodMonetaryValueFields
  }
  isPeriodBookClosed
}
    ${PeriodFieldsFragmentDoc}
${PeriodMonetaryValueFieldsFragmentDoc}`;
export const CashSummaryFieldsFragmentDoc = gql`
    fragment CashSummaryFields on CashSummary {
  cashTotal {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
  cashChange {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
    partialData
  }
}
    ${TransactionSummaryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}`;
export const LocationSummaryFieldsFragmentDoc = gql`
    fragment LocationSummaryFields on LocationSummary {
  location {
    ...EntityLocationFields
  }
  total {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
  }
  expensesChange {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
  }
  otherExpensesChange {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
  }
  incomeChange {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
  }
  otherIncomeChange {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
  }
  cogsChange {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
  }
}
    ${EntityLocationFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;
export const RunwayFieldsFragmentDoc = gql`
    fragment RunwayFields on Runway {
  current {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
  future {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
  cashOutDate
  previousCashOutDate
}
    ${TransactionSummaryFieldsFragmentDoc}`;
export const ViewIdentifierFieldsFragmentDoc = gql`
    fragment ViewIdentifierFields on ViewIdentifier {
  legalEntityId
  viewVersion
  mutationVersion
  viewType
}
    `;
export const ArchiveIntervalOriginFieldsFragmentDoc = gql`
    fragment ArchiveIntervalOriginFields on ArchiveIntervalOrigin {
  interval
  year
  index
  intervalCount
}
    `;
export const TextTagOptionsFieldsFragmentDoc = gql`
    fragment TextTagOptionsFields on TextTagOptions {
  partyRole
}
    `;
export const TextComponentConfigFieldsFragmentDoc = gql`
    fragment TextComponentConfigFields on TextComponentConfig {
  body
  current {
    ...ArchiveIntervalOriginFields
  }
  lookback {
    ...ArchiveIntervalOriginFields
  }
  tags {
    displayText
    objectId {
      ...ObjectIdentifierFields
    }
    options {
      ...TextTagOptionsFields
    }
  }
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}
${ObjectIdentifierFieldsFragmentDoc}
${TextTagOptionsFieldsFragmentDoc}`;
export const CategoryChartConfigFieldsFragmentDoc = gql`
    fragment CategoryChartConfigFields on CategoryChartConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
  categoryId
  withoutCategoryAncestors
  asPermanent
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const PartyChartConfigFieldsFragmentDoc = gql`
    fragment PartyChartConfigFields on PartyChartConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
  partyId
  partyRole
  asPermanent
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const TopEntitiesConfigFieldsFragmentDoc = gql`
    fragment TopEntitiesConfigFields on TopEntitiesConfig {
  limit
  origin {
    ...ArchiveIntervalOriginFields
  }
  kind
  partyRole
  categoryType
  filteredBy {
    ...ObjectIdentifierFields
  }
  sort
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}
${ObjectIdentifierFieldsFragmentDoc}`;
export const TopTransactionsConfigFieldsFragmentDoc = gql`
    fragment TopTransactionsConfigFields on TopTransactionsConfig {
  limit
  origin {
    ...ArchiveIntervalOriginFields
  }
  partyRole
  categoryType
  filteredBy {
    ...ObjectIdentifierFields
  }
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}
${ObjectIdentifierFieldsFragmentDoc}`;
export const SummaryLineItemConfigFieldsFragmentDoc = gql`
    fragment SummaryLineItemConfigFields on SummaryLineItemConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
  item
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const PortalMetricConfigFieldsFragmentDoc = gql`
    fragment PortalMetricConfigFields on PortalMetricConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
  metricType
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const EntitiesListConfigFieldsFragmentDoc = gql`
    fragment EntitiesListConfigFields on EntitiesListConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const ReportOptionsFieldsFragmentDoc = gql`
    fragment ReportOptionsFields on ReportDocumentOptions {
  __typename
  sparklineLookbackPeriods
  deltaMonthOverMonth
  deltaMonthOverMonthPeriods
  deltaYearOverYear
  comparedToTotal
  comparedToIncome
  collapsedSections {
    sections
  }
  amountPrecision
  yearToDate
  deltaYearToDate
}
    `;
export const StatementConfigFieldsFragmentDoc = gql`
    fragment StatementConfigFields on StatementConfig {
  kind
  origin {
    ...ArchiveIntervalOriginFields
  }
  options {
    ...ReportOptionsFields
  }
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}
${ReportOptionsFieldsFragmentDoc}`;
export const MetricChartConfigFieldsFragmentDoc = gql`
    fragment MetricChartConfigFields on MetricChartConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
  item
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const RunwayChartConfigFieldsFragmentDoc = gql`
    fragment RunwayChartConfigFields on RunwayChartConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const CashSummaryChartConfigFieldsFragmentDoc = gql`
    fragment CashSummaryChartConfigFields on CashSummaryChartConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const StaticMetricConfigFieldsFragmentDoc = gql`
    fragment StaticMetricConfigFields on StaticMetricConfig {
  name
  value
  updatedAt
}
    `;
export const InsightComponentConfigFieldsFragmentDoc = gql`
    fragment InsightComponentConfigFields on InsightComponentConfig {
  objectId {
    ...ObjectIdentifierFields
  }
  options {
    partyRole
  }
  origin {
    ...ArchiveIntervalOriginFields
  }
  lookback {
    ...ArchiveIntervalOriginFields
  }
}
    ${ObjectIdentifierFieldsFragmentDoc}
${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const DepartmentChartConfigFieldsFragmentDoc = gql`
    fragment DepartmentChartConfigFields on DepartmentChartConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
  departmentId
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const LocationChartConfigFieldsFragmentDoc = gql`
    fragment LocationChartConfigFields on LocationChartConfig {
  origin {
    ...ArchiveIntervalOriginFields
  }
  locationId
}
    ${ArchiveIntervalOriginFieldsFragmentDoc}`;
export const LayoutComponentConfigFieldsFragmentDoc = gql`
    fragment LayoutComponentConfigFields on LayoutComponentConfig {
  type
  text {
    ...TextComponentConfigFields
  }
  categoryChart {
    ...CategoryChartConfigFields
  }
  partyChart {
    ...PartyChartConfigFields
  }
  topEntities {
    ...TopEntitiesConfigFields
  }
  topTransactions {
    ...TopTransactionsConfigFields
  }
  summaryLineItem {
    ...SummaryLineItemConfigFields
  }
  portalMetric {
    ...PortalMetricConfigFields
  }
  entitiesList {
    ...EntitiesListConfigFields
  }
  statement {
    ...StatementConfigFields
  }
  metricChart {
    ...MetricChartConfigFields
  }
  runwayChart {
    ...RunwayChartConfigFields
  }
  cashSummaryChart {
    ...CashSummaryChartConfigFields
  }
  staticMetric {
    ...StaticMetricConfigFields
  }
  documentCollection {
    collectionId
  }
  insight {
    ...InsightComponentConfigFields
  }
  departmentChart {
    ...DepartmentChartConfigFields
  }
  locationChart {
    ...LocationChartConfigFields
  }
}
    ${TextComponentConfigFieldsFragmentDoc}
${CategoryChartConfigFieldsFragmentDoc}
${PartyChartConfigFieldsFragmentDoc}
${TopEntitiesConfigFieldsFragmentDoc}
${TopTransactionsConfigFieldsFragmentDoc}
${SummaryLineItemConfigFieldsFragmentDoc}
${PortalMetricConfigFieldsFragmentDoc}
${EntitiesListConfigFieldsFragmentDoc}
${StatementConfigFieldsFragmentDoc}
${MetricChartConfigFieldsFragmentDoc}
${RunwayChartConfigFieldsFragmentDoc}
${CashSummaryChartConfigFieldsFragmentDoc}
${StaticMetricConfigFieldsFragmentDoc}
${InsightComponentConfigFieldsFragmentDoc}
${DepartmentChartConfigFieldsFragmentDoc}
${LocationChartConfigFieldsFragmentDoc}`;
export const LayoutFieldsFragmentDoc = gql`
    fragment LayoutFields on Layout {
  viewKey {
    ...ViewIdentifierFields
  }
  layoutId
  layoutVersionId
  sections {
    sectionId
    title
    rows {
      rowId
      title
      components {
        componentId
        title
        config {
          ...LayoutComponentConfigFields
        }
        dataId
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${LayoutComponentConfigFieldsFragmentDoc}`;
export const CategoryHoverFieldsFragmentDoc = gql`
    fragment CategoryHoverFields on CategoryHover {
  __typename
  entity {
    __typename
    ... on EntityCategory {
      ...EntityCategoryFields
    }
  }
  history {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
  context {
    party(limit: $limit, sort: $sort) {
      partyObject {
        ...EntityPartyFields
      }
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
  topTransactions {
    ...EntityTransactionFields
  }
  balance
}
    ${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${EntityTransactionFieldsFragmentDoc}`;
export const PartyHoverFieldsFragmentDoc = gql`
    fragment PartyHoverFields on PartyHover {
  __typename
  entity {
    __typename
    ... on EntityParty {
      ...EntityPartyFields
    }
  }
  history {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
  context {
    category(limit: $limit, sort: $sort) {
      categoryObject {
        ...EntityCategoryFields
      }
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
  topTransactions {
    ...EntityTransactionFields
  }
}
    ${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}
${EntityTransactionFieldsFragmentDoc}`;
export const SummaryLineItemHoverFieldsFragmentDoc = gql`
    fragment SummaryLineItemHoverFields on SummaryLineItemHover {
  __typename
  lineType
  history {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
}
    ${TransactionSummaryFieldsFragmentDoc}`;
export const EntityHoverFieldsFragmentDoc = gql`
    fragment EntityHoverFields on Hover {
  __typename
  ... on CategoryHover {
    ...CategoryHoverFields
  }
  ... on PartyHover {
    ...PartyHoverFields
  }
  ... on SummaryLineItemHover {
    ...SummaryLineItemHoverFields
  }
}
    ${CategoryHoverFieldsFragmentDoc}
${PartyHoverFieldsFragmentDoc}
${SummaryLineItemHoverFieldsFragmentDoc}`;
export const ActivityObjectIdentifierFieldsFragmentDoc = gql`
    fragment ActivityObjectIdentifierFields on ActivityObjectIdentifier {
  kind
  id
  context
}
    `;
export const DiscreteEventFieldsFragmentDoc = gql`
    fragment DiscreteEventFields on DiscreteEvent {
  kind
  actorUserId
  target {
    ...ActivityObjectIdentifierFields
  }
  subject {
    ...ActivityObjectIdentifierFields
  }
  targetEmailAddress
}
    ${ActivityObjectIdentifierFieldsFragmentDoc}`;
export const ActivityEventFieldsFragmentDoc = gql`
    fragment ActivityEventFields on ActivityEvent {
  id
  legalEntityId
  occurredAt
  discreteEvent {
    ...DiscreteEventFields
  }
}
    ${DiscreteEventFieldsFragmentDoc}`;
export const ActivityFeedFieldsFragmentDoc = gql`
    fragment ActivityFeedFields on ActivityFeed {
  events {
    ...ActivityEventFields
  }
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ActivityEventFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const TransactionReviewQuestionFieldsFragmentDoc = gql`
    fragment TransactionReviewQuestionFields on TransactionReviewQuestion {
  question
  createdAt
}
    `;
export const AmountRuleFilterFieldsFragmentDoc = gql`
    fragment AmountRuleFilterFields on AmountRuleFilter {
  amountRuleFilterId
  operator
  amount {
    ...MonetaryValueFields
  }
  createdAt
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const PartyRuleFilterPartyFieldsFragmentDoc = gql`
    fragment PartyRuleFilterPartyFields on PartyRuleFilterParty {
  partyId
  partyName
}
    `;
export const PartyRuleFilterFieldsFragmentDoc = gql`
    fragment PartyRuleFilterFields on PartyRuleFilter {
  partyRuleFilterId
  operation
  parties {
    ...PartyRuleFilterPartyFields
  }
  createdAt
}
    ${PartyRuleFilterPartyFieldsFragmentDoc}`;
export const CategoryRuleFilterCategoryFieldsFragmentDoc = gql`
    fragment CategoryRuleFilterCategoryFields on CategoryRuleFilterCategory {
  categoryId
  categoryName
}
    `;
export const CategoryRuleFilterFieldsFragmentDoc = gql`
    fragment CategoryRuleFilterFields on CategoryRuleFilter {
  categoryRuleFilterId
  operation
  categories {
    ...CategoryRuleFilterCategoryFields
  }
  createdAt
}
    ${CategoryRuleFilterCategoryFieldsFragmentDoc}`;
export const AuditRuleFieldsFragmentDoc = gql`
    fragment AuditRuleFields on AuditRule {
  auditRuleId
  name
  description
  type
  action
  ruleState
  amountFilters {
    ...AmountRuleFilterFields
  }
  partyFilters {
    ...PartyRuleFilterFields
  }
  categoryFilters {
    ...CategoryRuleFilterFields
  }
}
    ${AmountRuleFilterFieldsFragmentDoc}
${PartyRuleFilterFieldsFragmentDoc}
${CategoryRuleFilterFieldsFragmentDoc}`;
export const AuditRuleStatusFieldsFragmentDoc = gql`
    fragment AuditRuleStatusFields on AuditRuleStatus {
  type
  recentlyUpdated
  updaterType
}
    `;
export const ObjectMappingHierarchyNodeFieldsFragmentDoc = gql`
    fragment ObjectMappingHierarchyNodeFields on ObjectMappingHierarchyNode {
  factId
  name
  parentId
  active
  transactionCount
  mappedTo
  depth
  fullyQualifiedName
}
    `;
export const TransactionReviewScheduleFieldsFragmentDoc = gql`
    fragment TransactionReviewScheduleFields on TransactionReviewSchedule {
  daysOfMonth
  daysOfWeek
  enabled
  legalEntityId
}
    `;
export const TransactionReviewReminderMessageFieldsFragmentDoc = gql`
    fragment TransactionReviewReminderMessageFields on TransactionReviewReminderMessage {
  legalEntityId
  message
}
    `;
export const SearchResultFieldsFragmentDoc = gql`
    fragment SearchResultFields on SearchResult {
  total
  results {
    score
    highlights {
      field
      term
      start
      end
    }
    objectId {
      ...ObjectIdentifierFields
    }
  }
  entities {
    ...ObjectEntitiesFields
  }
  tookSecs
  kindCounts {
    kind
    count
  }
}
    ${ObjectIdentifierFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const ConnectionSyncStateFieldsFragmentDoc = gql`
    fragment ConnectionSyncStateFields on ConnectionSyncState {
  lastSuccessAt
  lastEventAt
  status
}
    `;
export const LabelCategoryFieldsFragmentDoc = gql`
    fragment LabelCategoryFields on LabelCategory {
  label
  category {
    ...EntityCategoryFields
  }
  defaultCategoryType
  validCategoryTypes
  displayName
  required
  tooltip
}
    ${EntityCategoryFieldsFragmentDoc}`;
export const ConnectionAccountSettingsFieldsFragmentDoc = gql`
    fragment ConnectionAccountSettingsFields on ConnectionAccountSettings {
  enabled
  cutoverDate
  labelCategories {
    ...LabelCategoryFields
  }
}
    ${LabelCategoryFieldsFragmentDoc}`;
export const ConnectionAccountFieldsFragmentDoc = gql`
    fragment ConnectionAccountFields on ConnectionAccount {
  id
  connectionId
  sourceAccountId
  name
  mask
  settings {
    ...ConnectionAccountSettingsFields
  }
}
    ${ConnectionAccountSettingsFieldsFragmentDoc}`;
export const ConnectorFieldsFragmentDoc = gql`
    fragment ConnectorFields on Connector {
  id
  name
  description
  category
  iconURL
  className
  connectorProperties {
    __typename
    ... on StripeConnectorProperties {
      __typename
      appLinkURL
    }
    ... on PlaidConnectorProperties {
      __typename
      empty
    }
    ... on RampConnectorProperties {
      __typename
      authorizationURL
    }
    ... on GustoConnectorProperties {
      __typename
      authorizationURL
    }
    ... on EmptyConnectorProperties {
      __typename
      empty
    }
  }
}
    `;
export const ConnectionFieldsFragmentDoc = gql`
    fragment ConnectionFields on Connection {
  id
  dataSourceId
  sourceName
  sourceIconURL
  description
  status
  sync {
    ...ConnectionSyncStateFields
  }
  accounts {
    ...ConnectionAccountFields
  }
  connector {
    ...ConnectorFields
  }
}
    ${ConnectionSyncStateFieldsFragmentDoc}
${ConnectionAccountFieldsFragmentDoc}
${ConnectorFieldsFragmentDoc}`;
export const CreateConnectionResponseFieldsFragmentDoc = gql`
    fragment CreateConnectionResponseFields on CreateConnectionResponse {
  connection {
    ...ConnectionFields
  }
}
    ${ConnectionFieldsFragmentDoc}`;
export const ConnectionAccountSummaryFieldsFragmentDoc = gql`
    fragment ConnectionAccountSummaryFields on ConnectionAccountSummary {
  id
  connectionId
  sourceAccountId
  name
  mask
}
    `;
export const ConnectionSummaryFieldsFragmentDoc = gql`
    fragment ConnectionSummaryFields on ConnectionSummary {
  id
  dataSourceId
  sourceName
  sourceIconURL
  description
  status
  sync {
    ...ConnectionSyncStateFields
  }
  accounts {
    ...ConnectionAccountSummaryFields
  }
  connector {
    ...ConnectorFields
  }
}
    ${ConnectionSyncStateFieldsFragmentDoc}
${ConnectionAccountSummaryFieldsFragmentDoc}
${ConnectorFieldsFragmentDoc}`;
export const PartyFieldsFragmentDoc = gql`
    fragment PartyFields on Party {
  id
  legalEntityId
  name
  url
  supportUrl
  supportEmail
  supportPhone
  twitterUrl
  facebookUrl
  instagramUrl
  yelpUrl
  iconUrl
  shortDescription
  designation
}
    `;
export const ProductInstitutionFieldsFragmentDoc = gql`
    fragment ProductInstitutionFields on ProductInstitution {
  id
  name
  party {
    ...PartyFields
  }
  products
  routingNumber
}
    ${PartyFieldsFragmentDoc}`;
export const ConnectionTemplateFieldsFragmentDoc = gql`
    fragment ConnectionTemplateFields on ConnectionTemplate {
  connector {
    ...ConnectorFields
  }
  institution {
    ...ProductInstitutionFields
  }
}
    ${ConnectorFieldsFragmentDoc}
${ProductInstitutionFieldsFragmentDoc}`;
export const SuggestedConnectionFieldsFragmentDoc = gql`
    fragment SuggestedConnectionFields on SuggestedConnection {
  template {
    ...ConnectionTemplateFields
  }
}
    ${ConnectionTemplateFieldsFragmentDoc}`;
export const MinMaxAmountFieldsFragmentDoc = gql`
    fragment MinMaxAmountFields on MinMaxAmount {
  minAmount {
    ...MonetaryValueFields
  }
  maxAmount {
    ...MonetaryValueFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const TransactionCategorySuggestionsFieldsFragmentDoc = gql`
    fragment TransactionCategorySuggestionsFields on TransactionCategorySuggestions {
  transactionFactId
  categorySuggestions {
    ...CategorySuggestionFields
  }
}
    ${CategorySuggestionFieldsFragmentDoc}`;
export const ListBulkCategorySuggestionsResponseFieldsFragmentDoc = gql`
    fragment ListBulkCategorySuggestionsResponseFields on ListBulkCategorySuggestionsResponse {
  transactionCategorySuggestions {
    ...TransactionCategorySuggestionsFields
  }
}
    ${TransactionCategorySuggestionsFieldsFragmentDoc}`;
export const LedgerTransactionStaleFieldsFragmentDoc = gql`
    fragment LedgerTransactionStaleFields on LedgerTransactionStale {
  stale
  newViewAvailable
}
    `;
export const TransactionEntryFieldsFragmentDoc = gql`
    fragment TransactionEntryFields on TransactionEntry {
  factId
  externalLineId
  description
  amount {
    ...MonetaryValueFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const TransactionLineFieldsFragmentDoc = gql`
    fragment TransactionLineFields on TransactionLine {
  entry {
    ...TransactionEntryFields
  }
  entryType
  category {
    ...EntityCategoryFields
  }
  party {
    ...EntityPartyFields
  }
}
    ${TransactionEntryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}`;
export const TransactionSourceFieldsFragmentDoc = gql`
    fragment TransactionSourceFields on TransactionSource {
  timestamp
  name
  iconUrl
  externalDisplayId
  type
  account {
    externalName
    mask
    externalOwnerName
    externalOfficialName
  }
}
    `;
export const LedgerTransactionFieldsFragmentDoc = gql`
    fragment LedgerTransactionFields on LedgerTransaction {
  timestamp
  externalId
  type
  memo
  referenceNumber
  lines {
    ...TransactionLineFields
  }
  sources {
    ...TransactionSourceFields
  }
  isMetadataEditable
  ledgerTransactionId
  compileSource
}
    ${TransactionLineFieldsFragmentDoc}
${TransactionSourceFieldsFragmentDoc}`;
export const MetricCategoriesOperandFieldsFragmentDoc = gql`
    fragment MetricCategoriesOperandFields on MetricCategoriesOperand {
  name
  categories {
    ...EntityCategoryFields
  }
  period {
    ...PeriodFields
  }
  isBalance
  naturalFlow
  defaultCategoryType
}
    ${EntityCategoryFieldsFragmentDoc}
${PeriodFieldsFragmentDoc}`;
export const MetricSummaryTypeOperandFieldsFragmentDoc = gql`
    fragment MetricSummaryTypeOperandFields on MetricSummaryTypeOperand {
  name
  type
  naturalFlow
  defaultCategoryType
  period {
    ...PeriodFields
  }
  isBalance
}
    ${PeriodFieldsFragmentDoc}`;
export const MetricOperationFieldsFragmentDoc = gql`
    fragment MetricOperationFields on MetricOperation {
  symbol
}
    `;
export const MetricTermFieldsFragmentDoc = gql`
    fragment MetricTermFields on MetricTerm {
  __typename
  ... on MetricCategoriesOperand {
    ...MetricCategoriesOperandFields
  }
  ... on MetricSummaryTypeOperand {
    ...MetricSummaryTypeOperandFields
  }
  ... on MetricOperation {
    ...MetricOperationFields
  }
}
    ${MetricCategoriesOperandFieldsFragmentDoc}
${MetricSummaryTypeOperandFieldsFragmentDoc}
${MetricOperationFieldsFragmentDoc}`;
export const MetricEquationFieldsFragmentDoc = gql`
    fragment MetricEquationFields on MetricEquation {
  terms {
    ...MetricTermFields
  }
}
    ${MetricTermFieldsFragmentDoc}`;
export const LegalEntityBadgeCountFieldsFragmentDoc = gql`
    fragment LegalEntityBadgeCountFields on LegalEntityBadgeCount {
  legalEntityId
  count
}
    `;
export const ConnectionCategoryIntegrationsFieldsFragmentDoc = gql`
    fragment ConnectionCategoryIntegrationsFields on ConnectionCategoryIntegrations {
  connectionAccountSummaries {
    ...ConnectionAccountSummaryFields
  }
  party {
    ...EntityPartyFields
  }
}
    ${ConnectionAccountSummaryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}`;
export const PayCategoryIntegrationsFieldsFragmentDoc = gql`
    fragment PayCategoryIntegrationsFields on PayCategoryIntegrations {
  products
  party {
    ...EntityPartyFields
  }
}
    ${EntityPartyFieldsFragmentDoc}`;
export const CategoryIntegrationsFieldsFragmentDoc = gql`
    fragment CategoryIntegrationsFields on CategoryIntegrations {
  categoryId
  connect {
    ...ConnectionCategoryIntegrationsFields
  }
  pay {
    ...PayCategoryIntegrationsFields
  }
}
    ${ConnectionCategoryIntegrationsFieldsFragmentDoc}
${PayCategoryIntegrationsFieldsFragmentDoc}`;
export const TeamMemberFieldsFragmentDoc = gql`
    fragment TeamMemberFields on TeamMember {
  user {
    ...EntityUserFields
  }
  role
}
    ${EntityUserFieldsFragmentDoc}`;
export const TeamMemberInviteFieldsFragmentDoc = gql`
    fragment TeamMemberInviteFields on TeamMemberInvite {
  emailAddress
  role
}
    `;
export const TeamFieldsFragmentDoc = gql`
    fragment TeamFields on Team {
  id
  name
  description
  iconObjectName
  members {
    ...TeamMemberFields
  }
  invites {
    ...TeamMemberInviteFields
  }
}
    ${TeamMemberFieldsFragmentDoc}
${TeamMemberInviteFieldsFragmentDoc}`;
export const OrganizationFieldsFragmentDoc = gql`
    fragment OrganizationFields on Organization {
  id
  slug
  name
  iconUrl
  websiteUrl
}
    `;
export const RoleFieldsFragmentDoc = gql`
    fragment RoleFields on Role {
  id
}
    `;
export const IdentityFieldsFragmentDoc = gql`
    fragment IdentityFields on Identity {
  identifier
  provider
  domain
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  givenName
  familyName
  primaryEmailAddress
  passwordSet
  avatarUrl
  createdAt
  lastActivityAt
  employments {
    employeeId
    role {
      ...RoleFields
    }
    organization {
      ...OrganizationFields
    }
  }
  identities {
    ...IdentityFields
  }
}
    ${RoleFieldsFragmentDoc}
${OrganizationFieldsFragmentDoc}
${IdentityFieldsFragmentDoc}`;
export const AffiliateUserFieldsFragmentDoc = gql`
    fragment AffiliateUserFields on AffiliateUser {
  user {
    ...UserFields
  }
  role {
    ...RoleFields
  }
}
    ${UserFieldsFragmentDoc}
${RoleFieldsFragmentDoc}`;
export const AffiliateOrganizationFieldsFragmentDoc = gql`
    fragment AffiliateOrganizationFields on AffiliateOrganization {
  organization {
    ...OrganizationFields
  }
  type
  affiliatedUsers {
    ...AffiliateUserFields
  }
}
    ${OrganizationFieldsFragmentDoc}
${AffiliateUserFieldsFragmentDoc}`;
export const ContactFieldsFragmentDoc = gql`
    fragment ContactFields on Contact {
  givenName
  familyName
  emailAddress
  avatarUrl
}
    `;
export const InvitationProposalFieldsFragmentDoc = gql`
    fragment InvitationProposalFields on InvitationProposal {
  proposalId
  proposalType
  status
  invitationId
  legalEntity {
    ...EntityLegalEntityFields
  }
  organization {
    ...EntityOrganizationFields
  }
  organizationTemplateId
  sender {
    ...EntityUserFields
  }
  createdAt
  recipient {
    ...ContactFields
  }
}
    ${EntityLegalEntityFieldsFragmentDoc}
${EntityOrganizationFieldsFragmentDoc}
${EntityUserFieldsFragmentDoc}
${ContactFieldsFragmentDoc}`;
export const AffiliateOrganizationsFieldsFragmentDoc = gql`
    fragment AffiliateOrganizationsFields on AffiliateOrganizations {
  organizations {
    ...AffiliateOrganizationFields
  }
  inviteProposals {
    ...InvitationProposalFields
  }
}
    ${AffiliateOrganizationFieldsFragmentDoc}
${InvitationProposalFieldsFragmentDoc}`;
export const ViewMetadataFieldsFragmentDoc = gql`
    fragment ViewMetadataFields on ViewMetadata {
  firstTransactionTimestamp
  legalEntityId
  viewType
  viewVersion
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  street
  streetAdditional
  city
  region
  postalCode
  countryCode
  lat
  long
}
    `;
export const LegalEntityFieldsFragmentDoc = gql`
    fragment LegalEntityFields on LegalEntity {
  id
  slug
  name
  websiteUrl
  industry
  timezone
  fiscalYearStartMonth
  fiscalYearNumberOffset
  status
  organizationId
  address {
    ...AddressFields
  }
  incorporatedOn {
    year
    month
    day
  }
  sensitive
}
    ${AddressFieldsFragmentDoc}`;
export const UserInfoFieldsFragmentDoc = gql`
    fragment UserInfoFields on UserInfo {
  givenName
  familyName
  emailAddress
}
    `;
export const CompanyDetailsFieldsFragmentDoc = gql`
    fragment CompanyDetailsFields on CompanyDetails {
  categories
  companyName
  websiteURL
  address {
    ...AddressFields
  }
  companySize
  incorporationState
  entityType
  subsidiaryCount
  foreignSubsidiaryCount
  incorporationYear
  lastTaxFiling
}
    ${AddressFieldsFragmentDoc}`;
export const CompanyFinancialValueFieldsFragmentDoc = gql`
    fragment CompanyFinancialValueFields on CompanyFinancialValue {
  name
  institutionId
}
    `;
export const CompanyFinancialsFieldsFragmentDoc = gql`
    fragment CompanyFinancialsFields on CompanyFinancials {
  product
  values {
    ...CompanyFinancialValueFields
  }
}
    ${CompanyFinancialValueFieldsFragmentDoc}`;
export const QuotePackageFieldsFragmentDoc = gql`
    fragment QuotePackageFields on QuotePackage {
  id
  recipient {
    ...ContactFields
  }
  companyDetails {
    ...CompanyDetailsFields
  }
  financials {
    ...CompanyFinancialsFields
  }
  contractDocumentId
  status
}
    ${ContactFieldsFragmentDoc}
${CompanyDetailsFieldsFragmentDoc}
${CompanyFinancialsFieldsFragmentDoc}`;
export const AiBookkeeperLegalEntityFieldsFragmentDoc = gql`
    fragment AIBookkeeperLegalEntityFields on NewAIBookkeeperLegalEntity {
  legalEntity {
    ...LegalEntityFields
  }
  userInfo {
    ...UserInfoFields
  }
  quotePackage {
    ...QuotePackageFields
  }
}
    ${LegalEntityFieldsFragmentDoc}
${UserInfoFieldsFragmentDoc}
${QuotePackageFieldsFragmentDoc}`;
export const TaxpayerIdentificationCountFieldsFragmentDoc = gql`
    fragment TaxpayerIdentificationCountFields on TaxpayerIdentificationCount {
  partyCount
  taxpayerIdentification
}
    `;
export const PartyHistoryEntryFieldsFragmentDoc = gql`
    fragment PartyHistoryEntryFields on PartyHistoryEntry {
  userId
  name
  iconUrl
}
    `;
export const CustomerSummaryFieldsFragmentDoc = gql`
    fragment CustomerSummaryFields on CustomerSummary {
  id
  name
  email
  icon
}
    `;
export const InvoiceSummaryFieldsFragmentDoc = gql`
    fragment InvoiceSummaryFields on InvoiceSummary {
  id
  amount {
    ...EntityMonetaryValueFields
  }
  customer {
    ...CustomerSummaryFields
  }
  createdAt
  dueAt
  invoiceNumber
  status
}
    ${EntityMonetaryValueFieldsFragmentDoc}
${CustomerSummaryFieldsFragmentDoc}`;
export const InvoicingAddressFieldsFragmentDoc = gql`
    fragment InvoicingAddressFields on InvoicingAddress {
  id
  address {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const InvoicingProfileFieldsFragmentDoc = gql`
    fragment InvoicingProfileFields on InvoicingProfile {
  id
  companyEmail
  companyName
  companyIcon
  companyAddress {
    ...InvoicingAddressFields
  }
  backgroundColor
  coverURL
  payoutInstrumentID
  invoiceNumberFormatDefault
  invoiceNumberFormat
}
    ${InvoicingAddressFieldsFragmentDoc}`;
export const CustomerAddressFieldsFragmentDoc = gql`
    fragment CustomerAddressFields on CustomerAddress {
  id
  address {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const CustomerFieldsFragmentDoc = gql`
    fragment CustomerFields on Customer {
  id
  name
  email
  icon
  address {
    ...CustomerAddressFields
  }
  providerType
}
    ${CustomerAddressFieldsFragmentDoc}`;
export const DiscountFieldsFragmentDoc = gql`
    fragment DiscountFields on Discount {
  discountId
  name
  fixedDiscountAmount {
    ...MonetaryValueFields
  }
  variablePercentage
  providerType
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const InvoiceDiscountFieldsFragmentDoc = gql`
    fragment InvoiceDiscountFields on InvoiceDiscount {
  invoiceDiscountId
  discount {
    ...DiscountFields
  }
  discountAmount {
    ...MonetaryValueFields
  }
}
    ${DiscountFieldsFragmentDoc}
${MonetaryValueFieldsFragmentDoc}`;
export const SentInvoiceLineItemFieldsFragmentDoc = gql`
    fragment SentInvoiceLineItemFields on InvoiceLineItem {
  id
  name
  unitPrice {
    ...MonetaryValueFields
  }
  quantity
  taxable
  productId
  position
  discounts {
    ...InvoiceDiscountFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}
${InvoiceDiscountFieldsFragmentDoc}`;
export const InvoiceStatusSummaryFieldsFragmentDoc = gql`
    fragment InvoiceStatusSummaryFields on InvoiceStatusSummary {
  status
  statusAt
  invoiceSentDate
  userId
  internalNoteForStatus
}
    `;
export const InvoiceFileFieldsFragmentDoc = gql`
    fragment InvoiceFileFields on InvoiceFile {
  documentId
  fileType
}
    `;
export const SentInvoiceFieldsFragmentDoc = gql`
    fragment SentInvoiceFields on Invoice {
  id
  legalEntityId
  status
  invoicingProfile {
    ...InvoicingProfileFields
  }
  customer {
    ...CustomerFields
  }
  invoiceNumber
  invoiceDate
  dueDate
  lineItems {
    ...SentInvoiceLineItemFields
  }
  taxPercent
  totalTax {
    ...MonetaryValueFields
  }
  subtotal {
    ...MonetaryValueFields
  }
  totalAmount {
    ...MonetaryValueFields
  }
  memo
  statusSummary {
    ...InvoiceStatusSummaryFields
  }
  discounts {
    ...InvoiceDiscountFields
  }
  files {
    ...InvoiceFileFields
  }
}
    ${InvoicingProfileFieldsFragmentDoc}
${CustomerFieldsFragmentDoc}
${SentInvoiceLineItemFieldsFragmentDoc}
${MonetaryValueFieldsFragmentDoc}
${InvoiceStatusSummaryFieldsFragmentDoc}
${InvoiceDiscountFieldsFragmentDoc}
${InvoiceFileFieldsFragmentDoc}`;
export const SentInvoiceUsersFieldsFragmentDoc = gql`
    fragment SentInvoiceUsersFields on SentInvoiceUsers {
  invoice {
    ...SentInvoiceFields
  }
  users {
    ...UserFields
  }
}
    ${SentInvoiceFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export const InvoiceDefaultsFieldsFragmentDoc = gql`
    fragment InvoiceDefaultsFields on InvoiceDefaults {
  id
  invoiceDate
  dueDate
  invoiceNumber
}
    `;
export const BankAccountFieldsFragmentDoc = gql`
    fragment BankAccountFields on BankAccount {
  id
  institutionName
  name
  accountIdMask
  isDefault
  nickname
  isAutoPayout
  iconObjectName
}
    `;
export const InvoicePaymentDetailsFieldsFragmentDoc = gql`
    fragment InvoicePaymentDetailsFields on InvoicePaymentDetails {
  paymentConfirmationId
  paymentDate
  paymentAmount {
    ...MonetaryValueFields
  }
  paymentInstrument {
    ...BankAccountFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}
${BankAccountFieldsFragmentDoc}`;
export const PayInvoiceSummaryFieldsFragmentDoc = gql`
    fragment PayInvoiceSummaryFields on PayInvoiceSummary {
  invoicePaymentDetails {
    ...InvoicePaymentDetailsFields
  }
  status
}
    ${InvoicePaymentDetailsFieldsFragmentDoc}`;
export const InvoiceLineItemFieldsFragmentDoc = gql`
    fragment InvoiceLineItemFields on InvoiceLineItem {
  description
  id
  name
  unitPrice {
    ...MonetaryValueFields
  }
  quantity
  taxable
  productId
  position
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const InvoiceNumberFieldsFragmentDoc = gql`
    fragment InvoiceNumberFields on InvoiceNumber {
  invoiceNumber
}
    `;
export const DraftCustomerSummaryFieldsFragmentDoc = gql`
    fragment DraftCustomerSummaryFields on DraftCustomerSummary {
  id
  name
  email
  icon
}
    `;
export const DraftInvoiceSummaryFieldsFragmentDoc = gql`
    fragment DraftInvoiceSummaryFields on DraftInvoiceSummary {
  id
  amount {
    ...EntityMonetaryValueFields
  }
  customer {
    ...DraftCustomerSummaryFields
  }
  createdAt
  dueAt
  invoiceNumber
  status
}
    ${EntityMonetaryValueFieldsFragmentDoc}
${DraftCustomerSummaryFieldsFragmentDoc}`;
export const InvoiceProductFieldsFragmentDoc = gql`
    fragment InvoiceProductFields on InvoiceProduct {
  id
  name
  unitPrice {
    ...MonetaryValueFields
  }
  providerType
  incomeCategoryId
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const DraftInvoicingProfileFieldsFragmentDoc = gql`
    fragment DraftInvoicingProfileFields on DraftInvoicingProfile {
  id
  companyEmail
  companyName
  companyIcon
  companyAddress {
    ...InvoicingAddressFields
  }
  backgroundColor
  coverURL
  payoutInstrumentID
  invoiceNumberFormatDefault
  invoiceNumberFormat
}
    ${InvoicingAddressFieldsFragmentDoc}`;
export const DraftCustomerFieldsFragmentDoc = gql`
    fragment DraftCustomerFields on DraftCustomer {
  id
  name
  email
  icon
  address {
    ...CustomerAddressFields
  }
  providerType
}
    ${CustomerAddressFieldsFragmentDoc}`;
export const DraftInvoiceLineItemFieldsFragmentDoc = gql`
    fragment DraftInvoiceLineItemFields on DraftInvoiceLineItem {
  id
  name
  unitPrice {
    ...MonetaryValueFields
  }
  description
  quantity
  taxable
  productId
  position
  discounts {
    ...InvoiceDiscountFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}
${InvoiceDiscountFieldsFragmentDoc}`;
export const DraftInvoiceFieldsFragmentDoc = gql`
    fragment DraftInvoiceFields on DraftInvoice {
  id
  invoicingProfile {
    ...DraftInvoicingProfileFields
  }
  customer {
    ...DraftCustomerFields
  }
  invoiceNumber
  invoiceDate
  dueDate
  lineItems {
    ...DraftInvoiceLineItemFields
  }
  taxPercent
  totalTax {
    ...MonetaryValueFields
  }
  subtotal {
    ...MonetaryValueFields
  }
  totalAmount {
    ...MonetaryValueFields
  }
  memo
  status
  discounts {
    ...InvoiceDiscountFields
  }
  validationErrors
}
    ${DraftInvoicingProfileFieldsFragmentDoc}
${DraftCustomerFieldsFragmentDoc}
${DraftInvoiceLineItemFieldsFragmentDoc}
${MonetaryValueFieldsFragmentDoc}
${InvoiceDiscountFieldsFragmentDoc}`;
export const WebNotificationFieldsFragmentDoc = gql`
    fragment WebNotificationFields on WebNotification {
  id
  legalEntityId
  eventType
  name
  displayKey
  targetAction
  assetLink
  status
  message
  createdAt
}
    `;
export const NotificationSettingFieldsFragmentDoc = gql`
    fragment NotificationSettingFields on NotificationSetting {
  legalEntityId
  eventType
  name
  displayKey
  enabled
}
    `;
export const BillingProductFieldsFragmentDoc = gql`
    fragment BillingProductFields on BillingProduct {
  id
  name
  displayName
  description
  trialPeriodDays
}
    `;
export const BillingPriceFieldsFragmentDoc = gql`
    fragment BillingPriceFields on BillingPrice {
  id
  amount {
    ...MonetaryValueFields
  }
  interval
  intervalCount
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const BillingLegalEntityLimitFieldsFragmentDoc = gql`
    fragment BillingLegalEntityLimitFields on BillingLegalEntityLimit {
  id
  minLegalEntityLimit
  maxLegalEntityLimit
}
    `;
export const BillingPlanFieldsFragmentDoc = gql`
    fragment BillingPlanFields on BillingPlan {
  product {
    ...BillingProductFields
  }
  price {
    ...BillingPriceFields
  }
  legalEntityLimit {
    ...BillingLegalEntityLimitFields
  }
}
    ${BillingProductFieldsFragmentDoc}
${BillingPriceFieldsFragmentDoc}
${BillingLegalEntityLimitFieldsFragmentDoc}`;
export const NewTrialBillingSubscriptionFieldsFragmentDoc = gql`
    fragment NewTrialBillingSubscriptionFields on NewTrialBillingSubscription {
  customerId
}
    `;
export const CreateBillingOnboardingSubscriptionFieldsFragmentDoc = gql`
    fragment CreateBillingOnboardingSubscriptionFields on BillingSubscriptionWithoutStatus {
  id
  periodEnd
  periodStart
}
    `;
export const BillingTrialFieldsFragmentDoc = gql`
    fragment BillingTrialFields on BillingTrial {
  start
  end
}
    `;
export const SubscriptionDetailsFieldsFragmentDoc = gql`
    fragment SubscriptionDetailsFields on SubscriptionDetails {
  startedAt
  billingCycleStart
  billingCycleEnd
  status
  cancelAt
}
    `;
export const NewBillingSubscriptionFieldsFragmentDoc = gql`
    fragment NewBillingSubscriptionFields on NewBillingSubscription {
  customerId
  stripeClientSecret
}
    `;
export const VaultSegmentFieldsFragmentDoc = gql`
    fragment VaultSegmentFields on Segment {
  __typename
  fileId
  fileSize
  fileName
  sequence
  collectionId
  token
}
    `;
export const VaultFormatFieldsFragmentDoc = gql`
    fragment VaultFormatFields on Format {
  __typename
  id
  formatClass
  mimeType
  segments {
    ...VaultSegmentFields
  }
}
    ${VaultSegmentFieldsFragmentDoc}`;
export const TextFeatureValueFieldsFragmentDoc = gql`
    fragment TextFeatureValueFields on TextFeatureValue {
  __typename
  textValue
}
    `;
export const NumericFeatureValueFieldsFragmentDoc = gql`
    fragment NumericFeatureValueFields on NumericFeatureValue {
  __typename
  numericValue
}
    `;
export const DateFeatureValueFieldsFragmentDoc = gql`
    fragment DateFeatureValueFields on DateFeatureValue {
  __typename
  dateValue {
    year
    month
    day
  }
}
    `;
export const MonetaryFeatureValueFieldsFragmentDoc = gql`
    fragment MonetaryFeatureValueFields on MonetaryFeatureValue {
  __typename
  monetaryValue {
    ...MonetaryValueFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const AddressFeatureValueFieldsFragmentDoc = gql`
    fragment AddressFeatureValueFields on AddressFeatureValue {
  __typename
  addressValue {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const LineItemFeatureValueFieldsFragmentDoc = gql`
    fragment LineItemFeatureValueFields on LineItemFeatureValue {
  __typename
  lineItemValue {
    description
    quantity
    rate {
      ...MonetaryValueFields
    }
    amount {
      ...MonetaryValueFields
    }
    position
    categoryId
  }
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const RemittanceFeatureValueFieldsFragmentDoc = gql`
    fragment RemittanceFeatureValueFields on RemittanceFeatureValue {
  __typename
  remittanceValue {
    BankName
    BankAccountNumber
    BankAccountName
    BankRoutingNumber
    RemittanceType
  }
}
    `;
export const VaultFeatureFieldsFragmentDoc = gql`
    fragment VaultFeatureFields on Feature {
  __typename
  id
  kind
  value {
    ... on TextFeatureValue {
      ...TextFeatureValueFields
    }
    ... on NumericFeatureValue {
      ...NumericFeatureValueFields
    }
    ... on DateFeatureValue {
      ...DateFeatureValueFields
    }
    ... on MonetaryFeatureValue {
      ...MonetaryFeatureValueFields
    }
    ... on AddressFeatureValue {
      ...AddressFeatureValueFields
    }
    ... on LineItemFeatureValue {
      ...LineItemFeatureValueFields
    }
    ... on RemittanceFeatureValue {
      ...RemittanceFeatureValueFields
    }
  }
}
    ${TextFeatureValueFieldsFragmentDoc}
${NumericFeatureValueFieldsFragmentDoc}
${DateFeatureValueFieldsFragmentDoc}
${MonetaryFeatureValueFieldsFragmentDoc}
${AddressFeatureValueFieldsFragmentDoc}
${LineItemFeatureValueFieldsFragmentDoc}
${RemittanceFeatureValueFieldsFragmentDoc}`;
export const VaultFileFieldsFragmentDoc = gql`
    fragment VaultFileFields on RawDocument {
  __typename
  id
  collectionId
  sourceFormatId
  formats {
    ...VaultFormatFields
  }
  features {
    ...VaultFeatureFields
  }
  creator {
    ...EntityUserFields
  }
  createdAt
  updatedAt
  status
  asyncProcessingStatus
  title @client
  fileName @client
  fileSize @client
  mimeType @client
  thumbnail @client {
    ...VaultSegmentFields
  }
  sourceFormat @client {
    ...VaultFormatFields
  }
}
    ${VaultFormatFieldsFragmentDoc}
${VaultFeatureFieldsFragmentDoc}
${EntityUserFieldsFragmentDoc}
${VaultSegmentFieldsFragmentDoc}`;
export const ReportAutomationSettingsFieldsFragmentDoc = gql`
    fragment ReportAutomationSettingsFields on ReportAutomationSettings {
  organizationId
  legalEntityId
  interval
  autoCreateDraft
  autoPublishDraft
  autoPublishDraftDay
  autoPublishFinal
  autoPublishFinalDay
}
    `;
export const TrialBalanceRowFieldsFragmentDoc = gql`
    fragment TrialBalanceRowFields on TrialBalanceRow {
  rowId
  name
  debits {
    ...MonetaryValueFields
  }
  credits {
    ...MonetaryValueFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const TrialBalanceFieldsFragmentDoc = gql`
    fragment TrialBalanceFields on TrialBalance {
  rows {
    ...TrialBalanceRowFields
  }
}
    ${TrialBalanceRowFieldsFragmentDoc}`;
export const ThreadDetailsFieldsFragmentDoc = gql`
    fragment ThreadDetailsFields on ThreadDetails {
  id
  commentCount
  resolvedAt
  targetObject {
    ...ObjectIdentifierFields
  }
  authorId
  commenterIds
  tags {
    ...ObjectIdentifierFields
  }
  context
  pending @client
}
    ${ObjectIdentifierFieldsFragmentDoc}`;
export const ReportFileMetadataFieldsFragmentDoc = gql`
    fragment ReportFileMetadataFields on ReportFileMetadata {
  id
  fileName
  mimeType
  fileSize
  source
}
    `;
export const PeriodReportFieldsFragmentDoc = gql`
    fragment PeriodReportFields on PeriodReport {
  id
  title
  generationHistory {
    id
    requestId
    reportId
    versionId
    legalEntityId
    authorId
    viewVersion
    kind
    timezone
    periodStartedAt
    periodEndedAt
    generationStartedAt
    generationEndedAt
    error
    createdAt
    updatedAt
  }
  author {
    ...ContactFields
  }
  threadDetails {
    ...ThreadDetailsFields
  }
  reportFile {
    ...ReportFileMetadataFields
  }
  reportFor {
    ...DateFields
  }
  interval
  createdAt
  status
}
    ${ContactFieldsFragmentDoc}
${ThreadDetailsFieldsFragmentDoc}
${ReportFileMetadataFieldsFragmentDoc}
${DateFieldsFragmentDoc}`;
export const StatementRowTitleFieldsFragmentDoc = gql`
    fragment StatementRowTitleFields on StatementRowTitle {
  displayNumber
  title
}
    `;
export const StatementRowSummaryFieldsFragmentDoc = gql`
    fragment StatementRowSummaryFields on StatementRowSummary {
  displayNumber
  label
  moneyFlow {
    ...MoneyFlowFields
  }
}
    ${MoneyFlowFieldsFragmentDoc}`;
export const InsightFieldsFragmentDoc = gql`
    fragment InsightFields on Insight {
  score
  globalScore
  subjectType
  subjectId
  subjectDisplayKey
  periodValue {
    ...MonetaryValueFields
  }
  absoluteDelta {
    ...MonetaryValueFields
  }
  comparisonPeriodValue {
    ...MonetaryValueFields
  }
  comparisonIntervalOffset
  ruleType
  sentence
  subjectIconUrl
  designation
  type
  productArea
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const StatementDetailsFieldsFragmentDoc = gql`
    fragment StatementDetailsFields on StatementDetails {
  hover {
    __typename
    ... on CategoryHover {
      ...CategoryHoverFields
    }
    ... on PartyHover {
      ...PartyHoverFields
    }
    ... on SummaryLineItemHover {
      ...SummaryLineItemHoverFields
    }
  }
  insight {
    ...InsightFields
  }
}
    ${CategoryHoverFieldsFragmentDoc}
${PartyHoverFieldsFragmentDoc}
${SummaryLineItemHoverFieldsFragmentDoc}
${InsightFieldsFragmentDoc}`;
export const StatementDeltaValueFieldsFragmentDoc = gql`
    fragment StatementDeltaValueFields on StatementDeltaValue {
  moneyFlow {
    ...MoneyFlowFields
  }
  percentageOfAmount
}
    ${MoneyFlowFieldsFragmentDoc}`;
export const StatementDeltasFieldsFragmentDoc = gql`
    fragment StatementDeltasFields on StatementDeltas {
  percentageTotal {
    ...StatementDeltaValueFields
  }
  percentageIncome {
    ...StatementDeltaValueFields
  }
  previousPeriod {
    ...StatementDeltaValueFields
  }
  previousYear {
    ...StatementDeltaValueFields
  }
}
    ${StatementDeltaValueFieldsFragmentDoc}`;
export const StatementRowFieldsFragmentDoc = gql`
    fragment StatementRowFields on StatementRow {
  rowId
  depth
  sectionTitle {
    ...StatementRowTitleFields
  }
  sectionSummary {
    ...StatementRowSummaryFields
  }
  parentCategoryTitle {
    ...StatementRowTitleFields
  }
  parentCategorySummary {
    ...StatementRowSummaryFields
  }
  leafCategorySummary {
    ...StatementRowSummaryFields
  }
  details {
    ...StatementDetailsFields
  }
  ytdDetails {
    ...StatementDetailsFields
  }
  deltas {
    ...StatementDeltasFields
  }
}
    ${StatementRowTitleFieldsFragmentDoc}
${StatementRowSummaryFieldsFragmentDoc}
${StatementDetailsFieldsFragmentDoc}
${StatementDeltasFieldsFragmentDoc}`;
export const StatementFieldsFragmentDoc = gql`
    fragment StatementFields on Statement {
  kind
  rows {
    ...StatementRowFields
  }
}
    ${StatementRowFieldsFragmentDoc}`;
export const StatementSummaryFieldsFragmentDoc = gql`
    fragment StatementSummaryFields on StatementSummary {
  summaries
}
    `;
export const OrganizationPreviewFieldsFragmentDoc = gql`
    fragment OrganizationPreviewFields on OrganizationPreview {
  id
  slug
  name
  iconUrl
}
    `;
export const ReportGenerationHistoryFieldsFragmentDoc = gql`
    fragment ReportGenerationHistoryFields on ReportGenerationHistory {
  id
  requestId
  reportId
  versionId
  legalEntityId
  authorId
  viewVersion
  kind
  timezone
  periodStartedAt
  periodEndedAt
  generationStartedAt
  generationEndedAt
  error
  createdAt
  updatedAt
}
    `;
export const ReportPackageDocumentMetadataFieldsFragmentDoc = gql`
    fragment ReportPackageDocumentMetadataFields on ReportPackageDocumentMetadata {
  id
  title
  documentObjectId
  documentObjectVersionId
  type
  reportFile {
    ...ReportFileMetadataFields
  }
  status
  generationHistory {
    ...ReportGenerationHistoryFields
  }
  position
}
    ${ReportFileMetadataFieldsFragmentDoc}
${ReportGenerationHistoryFieldsFragmentDoc}`;
export const ReportFieldsFragmentDoc = gql`
    fragment ReportFields on Report {
  id
  versionId
  title
  author {
    ...ContactFields
  }
  reportFile {
    ...ReportFileMetadataFields
  }
  reportFor {
    ...DateFields
  }
  status
  interval
  createdAt
  status
  generationHistory {
    ...ReportGenerationHistoryFields
  }
  options {
    ...ReportOptionsFields
  }
}
    ${ContactFieldsFragmentDoc}
${ReportFileMetadataFieldsFragmentDoc}
${DateFieldsFragmentDoc}
${ReportGenerationHistoryFieldsFragmentDoc}
${ReportOptionsFieldsFragmentDoc}`;
export const TextDocumentFieldsFragmentDoc = gql`
    fragment TextDocumentFields on TextDocument {
  id
  title
  summary
  status
  createdAt
  updatedAt
  status
}
    `;
export const LayoutDocumentFieldsFragmentDoc = gql`
    fragment LayoutDocumentFields on LayoutDocument {
  id
  title
  layoutId
  status
  createdAt
  updatedAt
  status
}
    `;
export const ReportPackageVersionMetadataFieldsFragmentDoc = gql`
    fragment ReportPackageVersionMetadataFields on ReportPackageVersionMetadata {
  id
  status
  createdAt
  updatedAt
}
    `;
export const ReportPackageFieldsFragmentDoc = gql`
    fragment ReportPackageFields on ReportPackage {
  id
  versionId
  viewKey {
    ...ViewIdentifierFields
  }
  title
  status
  createdAt
  updatedAt
  startedAt
  endedAt
  interval
  author {
    ...ContactFields
  }
  authorOrganization {
    ...OrganizationPreviewFields
  }
  legalEntityOrganization {
    ...OrganizationPreviewFields
  }
  packageOptions {
    customFooter
    preparedBy
    accountNumbersDisplay
    coverUrl
    coverLogoSource
    publishedAsDraft
  }
  documents {
    metadata {
      ...ReportPackageDocumentMetadataFields
    }
    document {
      ... on Report {
        ...ReportFields
      }
      ... on TextDocument {
        ...TextDocumentFields
      }
      ... on LayoutDocument {
        ...LayoutDocumentFields
      }
    }
  }
  entities {
    ...ObjectEntitiesFields
  }
  version {
    ...ReportPackageVersionMetadataFields
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${ContactFieldsFragmentDoc}
${OrganizationPreviewFieldsFragmentDoc}
${ReportPackageDocumentMetadataFieldsFragmentDoc}
${ReportFieldsFragmentDoc}
${TextDocumentFieldsFragmentDoc}
${LayoutDocumentFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}
${ReportPackageVersionMetadataFieldsFragmentDoc}`;
export const TransactionPartyFieldsFragmentDoc = gql`
    fragment TransactionPartyFields on TransactionParty {
  id
  name
  iconUrl
  iconObjectName
  designation
  roles
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on Product {
  id
  name
}
    `;
export const CategoryFieldsFragmentDoc = gql`
    fragment CategoryFields on Category {
  id
  name
  description
  displayKey
  type
  subtype
  parentCategoryId
  displayNumber
  active
  categoryState
  productArea
  synthesized
  ancestors {
    ...EntityCategoryFields
  }
}
    ${EntityCategoryFieldsFragmentDoc}`;
export const EntityEmployeeFieldsFragmentDoc = gql`
    fragment EntityEmployeeFields on EntityEmployee {
  id
  organizationId
  givenName
  familyName
  avatarUrl
}
    `;
export const TransactionFieldsFragmentDoc = gql`
    fragment TransactionFields on Transaction {
  viewId
  factId
  supersededFactIds
  timestamp
  recordType
  name
  description
  digitsTransactionType
  party {
    ...TransactionPartyFields
  }
  partySource
  facilitatorParty {
    ...TransactionPartyFields
  }
  product {
    ...ProductFields
  }
  department {
    ...EntityDepartmentFields
  }
  location {
    ...EntityLocationFields
  }
  providers {
    name
  }
  recurrence {
    recurrenceThreadId
    interval
    intervalMultiplier
    threadIndex
    totalThreadCount
    isHidden
    firstOccurredAt
    lastOccurredAt
  }
  displayEntry
  displayInstitution {
    ...TransactionPartyFields
  }
  displayCategory {
    ...CategoryFields
  }
  displayCategorySource
  displayCanonicalCategoryName
  displayUncategorizedReason
  displayProductArea
  splitCategory {
    ...CategoryFields
  }
  splitInstitution {
    ...TransactionPartyFields
  }
  moneyFlow {
    ...MoneyFlowFields
  }
  runningTotal {
    ...MoneyFlowFields
  }
  owner {
    ...EntityEmployeeFields
  }
  ownerSource
  transactionClassificationType
  sources {
    ...TransactionSourceFields
  }
  creditSources {
    ...TransactionSourceFields
  }
  debitSources {
    ...TransactionSourceFields
  }
  compileSource
  reconciliationStatus
  referenceNumber
  taxStatus
}
    ${TransactionPartyFieldsFragmentDoc}
${ProductFieldsFragmentDoc}
${EntityDepartmentFieldsFragmentDoc}
${EntityLocationFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}
${MoneyFlowFieldsFragmentDoc}
${EntityEmployeeFieldsFragmentDoc}
${TransactionSourceFieldsFragmentDoc}`;
export const ReportPackageMetadataFieldsFragmentDoc = gql`
    fragment ReportPackageMetadataFields on ReportPackageMetadata {
  id
  versionId
  viewKey {
    ...ViewIdentifierFields
  }
  title
  status
  author {
    ...ContactFields
  }
  documents {
    ...ReportPackageDocumentMetadataFields
  }
  startedAt
  endedAt
  interval
  coverUrl
  publishedAsDraft
  status
  createdAt
  updatedAt
  creationMethod
  versions {
    ...ReportPackageVersionMetadataFields
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${ContactFieldsFragmentDoc}
${ReportPackageDocumentMetadataFieldsFragmentDoc}
${ReportPackageVersionMetadataFieldsFragmentDoc}`;
export const PredictedDataSourceFieldsFragmentDoc = gql`
    fragment PredictedDataSourceFields on PredictedDataSource {
  institution {
    ...PartyFields
  }
  providerType
  type
}
    ${PartyFieldsFragmentDoc}`;
export const DepartmentFieldsFragmentDoc = gql`
    fragment DepartmentFields on Department {
  id
  name
  parentId
  active
  ancestors {
    ...EntityDepartmentFields
  }
}
    ${EntityDepartmentFieldsFragmentDoc}`;
export const WorkItemSummaryFieldsFragmentDoc = gql`
    fragment WorkItemSummaryFields on WorkItemSummary {
  period {
    ...PeriodFields
  }
  count
}
    ${PeriodFieldsFragmentDoc}`;
export const DocumentInitialSegmentFieldsFragmentDoc = gql`
    fragment DocumentInitialSegmentFields on DocumentInitialSegment {
  collectionId
  documentId
  downloadUrl
}
    `;
export const ThreadListFieldsFragmentDoc = gql`
    fragment ThreadListFields on ThreadList {
  targetObject {
    ...ObjectIdentifierFields
  }
  threads {
    ...ThreadDetailsFields
  }
}
    ${ObjectIdentifierFieldsFragmentDoc}
${ThreadDetailsFieldsFragmentDoc}`;
export const ThreadsFieldsFragmentDoc = gql`
    fragment ThreadsFields on Threads {
  threads {
    ...ThreadListFields
  }
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ThreadListFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const SignupFieldsFragmentDoc = gql`
    fragment SignupFields on Signup {
  id
  contact {
    ...ContactFields
  }
  status
  statusReason
  location
  industry
  companyName
  companyUrl
  companyLedger
  companyCurrency
  positionTitle
  profileURL
  oAuthEmailAddress
  oAuthService
  persona
}
    ${ContactFieldsFragmentDoc}`;
export const CommentFieldsFragmentDoc = gql`
    fragment CommentFields on Comment {
  __typename
  id
  authorId
  text
  timestamp
  editedAt
  deletedAt
  layoutAttachment {
    layoutId
    legalEntityId
  }
  reactions
}
    `;
export const ThreadFieldsFragmentDoc = gql`
    fragment ThreadFields on Thread {
  __typename
  id
  details {
    ...ThreadDetailsFields
  }
  comments {
    ...CommentFields
  }
}
    ${ThreadDetailsFieldsFragmentDoc}
${CommentFieldsFragmentDoc}`;
export const ThreadWithEntitiesFieldsFragmentDoc = gql`
    fragment ThreadWithEntitiesFields on ThreadWithEntities {
  __typename
  thread {
    ...ThreadFields
  }
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ThreadFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const BooksClosedDateFieldsFragmentDoc = gql`
    fragment BooksClosedDateFields on BooksClosedDate {
  legalEntityId
  latestBooksClosedDateFact
  viewBooksClosedDate
  user {
    ...EntityUserFields
  }
}
    ${EntityUserFieldsFragmentDoc}`;
export const LegalEntityUserInfoFieldsFragmentDoc = gql`
    fragment LegalEntityUserInfoFields on LegalEntityUserInfo {
  legalEntity {
    ...LegalEntityFields
  }
  userInfo {
    ...UserInfoFields
  }
}
    ${LegalEntityFieldsFragmentDoc}
${UserInfoFieldsFragmentDoc}`;
export const ChartOfAccountsFieldsFragmentDoc = gql`
    fragment ChartOfAccountsFields on ChartOfAccounts {
  id
  name
  description
  accountingBasis
  categories {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;
export const SourceAccountFieldsFragmentDoc = gql`
    fragment SourceAccountFields on SourceAccount {
  id
  externalId
  externalName
  externalOwnerName
  externalOfficialName
  externalType
  externalSubtype
  mask
  status
  ownerEmployeeId
}
    `;
export const InvitationFieldsFragmentDoc = gql`
    fragment InvitationFields on Invitation {
  id
  invitationType
  recipient {
    givenName
    familyName
    emailAddress
    avatarUrl
  }
  sender {
    givenName
    familyName
    emailAddress
    avatarUrl
  }
  organization {
    ...OrganizationFields
  }
  role {
    ...RoleFields
  }
  status
  service
  organizationTemplateId
  createdAt
  updatedAt
  notifiedAt
}
    ${OrganizationFieldsFragmentDoc}
${RoleFieldsFragmentDoc}`;
export const EmployeeFieldsFragmentDoc = gql`
    fragment EmployeeFields on Employee {
  id
  organizationId
  givenName
  familyName
  avatarUrl
  emailAddress
  jobTitle
  status
  role {
    ...RoleFields
  }
  user {
    ...UserFields
  }
  invitation {
    ...InvitationFields
  }
  hiddenAt
}
    ${RoleFieldsFragmentDoc}
${UserFieldsFragmentDoc}
${InvitationFieldsFragmentDoc}`;
export const OwnedSourceAccountFieldsFragmentDoc = gql`
    fragment OwnedSourceAccountFields on OwnedSourceAccount {
  sourceAccount {
    ...SourceAccountFields
  }
  owner {
    ...EmployeeFields
  }
}
    ${SourceAccountFieldsFragmentDoc}
${EmployeeFieldsFragmentDoc}`;
export const BooksClosedFactFieldsFragmentDoc = gql`
    fragment BooksClosedFactFields on BooksClosedFact {
  legalEntityId
  dataSourceId
  userId
  booksClosedDate
  createdAt
}
    `;
export const InstitutionFieldsFragmentDoc = gql`
    fragment InstitutionFields on Institution {
  id
  name
  url
  routingNumber
  iconUrl
  partyId
  createdAt
}
    `;
export const DataSourceFieldsFragmentDoc = gql`
    fragment DataSourceFields on DataSource {
  id
  legalEntityId
  status
  statusReason
  institution {
    ...InstitutionFields
  }
  providerType
  accountAlias
  createdAt
  updatedAt
}
    ${InstitutionFieldsFragmentDoc}`;
export const QuickBooksConfigFieldsFragmentDoc = gql`
    fragment QuickBooksConfigFields on QuickBooksConfig {
  id
  realmId
  createdAt
  updatedAt
}
    `;
export const PlaidConfigFieldsFragmentDoc = gql`
    fragment PlaidConfigFields on PlaidConfig {
  id
  itemId
  externalInstitutionId
  createdAt
  updatedAt
}
    `;
export const EnrichedDataSourceFieldsFragmentDoc = gql`
    fragment EnrichedDataSourceFields on EnrichedDataSource {
  id
  legalEntityId
  status
  statusReason
  institution {
    ...PartyFields
  }
  quickBooksConfig {
    ...QuickBooksConfigFields
  }
  plaidConfig {
    ...PlaidConfigFields
  }
  providerType
  accountAlias
  createdAt
  updatedAt
  lastActivityAt
  sourceAccounts {
    ...SourceAccountFields
  }
  hasTransactions
}
    ${PartyFieldsFragmentDoc}
${QuickBooksConfigFieldsFragmentDoc}
${PlaidConfigFieldsFragmentDoc}
${SourceAccountFieldsFragmentDoc}`;
export const RefreshProgressFieldsFragmentDoc = gql`
    fragment RefreshProgressFields on RefreshProgress {
  mostRecentFetchStartTime
  progressPercentage
}
    `;
export const EmployeeAffiliationsFieldsFragmentDoc = gql`
    fragment EmployeeAffiliationsFields on EmployeeAffiliations {
  employee {
    ...EmployeeFields
  }
  affiliations {
    organization {
      ...EntityOrganizationFields
    }
    legalEntity {
      ...EntityLegalEntityFields
    }
    role {
      id
    }
  }
}
    ${EmployeeFieldsFragmentDoc}
${EntityOrganizationFieldsFragmentDoc}
${EntityLegalEntityFieldsFragmentDoc}`;
export const LocationFieldsFragmentDoc = gql`
    fragment LocationFields on Location {
  id
  name
  parentId
  active
  ancestors {
    ...EntityLocationFields
  }
}
    ${EntityLocationFieldsFragmentDoc}`;
export const WorkItemTransactionFieldsFragmentDoc = gql`
    fragment WorkItemTransactionFields on WorkItemTransaction {
  externalTransactionId
  externalTransactionLineId
  supportType
  displayCategory {
    ...EntityCategoryFields
  }
  displayPartyRole
  displayEntry
  splitCategory {
    ...EntityCategoryFields
  }
  description
  name
  party {
    ...EntityPartyFields
  }
  creditValue {
    ...MonetaryValueFields
  }
  debitValue {
    ...MonetaryValueFields
  }
  displayValue {
    ...MonetaryValueFields
  }
  moneyFlow {
    ...MoneyFlowFields
  }
  occurredAt
  dataSourceId
  quickbooksAppName
  ledgerTransactionId
}
    ${EntityCategoryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${MonetaryValueFieldsFragmentDoc}
${MoneyFlowFieldsFragmentDoc}`;
export const WorkItemReviewFieldsFragmentDoc = gql`
    fragment WorkItemReviewFields on WorkItemReview {
  createdAt
  creator {
    ...EntityUserFields
  }
  updatedAt
  subject
}
    ${EntityUserFieldsFragmentDoc}`;
export const WorkItemGeneratedQuestionFieldsFragmentDoc = gql`
    fragment WorkItemGeneratedQuestionFields on WorkItemGeneratedQuestion {
  question
  contextType
  active
}
    `;
export const WorkItemPushFieldsFragmentDoc = gql`
    fragment WorkItemPushFields on WorkItemPush {
  field
  pushState
  errorType
  updatedAt
}
    `;
export const WorkItemFieldsFragmentDoc = gql`
    fragment WorkItemFields on WorkItem {
  legalEntityId
  workItemId
  time
  itemType
  itemState
  ledgerType
  createdAt
  updatedBy
  updatedAt
  actionable
  transactions {
    ...WorkItemTransactionFields
  }
  reviewState
  review {
    ...WorkItemReviewFields
  }
  generatedQuestions {
    ...WorkItemGeneratedQuestionFields
  }
  pushes {
    ...WorkItemPushFields
  }
}
    ${WorkItemTransactionFieldsFragmentDoc}
${WorkItemReviewFieldsFragmentDoc}
${WorkItemGeneratedQuestionFieldsFragmentDoc}
${WorkItemPushFieldsFragmentDoc}`;
export const TransactionEntryLineFieldsFragmentDoc = gql`
    fragment TransactionEntryLineFields on TransactionEntryLine {
  lineId
  value {
    ...MonetaryValueFields
  }
  category {
    ...CategoryFields
  }
  name
  description
  isCredit
  highlighted
}
    ${MonetaryValueFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}`;
export const TransactionViewDetailFieldsFragmentDoc = gql`
    fragment TransactionViewDetailFields on TransactionViewDetail {
  id
  factId
  occurredAt
  externalCreatedAt
  externalId
  externalDisplayId
  quickbooksAppName
  name
  description
  creditAmount {
    ...MonetaryValueFields
  }
  debitAmount {
    ...MonetaryValueFields
  }
  creditCategoryHierarchy {
    ...CategoryFields
  }
  debitCategoryHierarchy {
    ...CategoryFields
  }
  entryLines {
    ...TransactionEntryLineFields
  }
  party {
    ...TransactionPartyFields
  }
  institution {
    ...InstitutionFields
  }
  sourceAccount {
    ...SourceAccountFields
  }
  recordType
}
    ${MonetaryValueFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}
${TransactionEntryLineFieldsFragmentDoc}
${TransactionPartyFieldsFragmentDoc}
${InstitutionFieldsFragmentDoc}
${SourceAccountFieldsFragmentDoc}`;
export const TransactionViewDetailsFieldsFragmentDoc = gql`
    fragment TransactionViewDetailsFields on TransactionViewDetails {
  details {
    ...TransactionViewDetailFields
  }
}
    ${TransactionViewDetailFieldsFragmentDoc}`;
export const HighlightedTokenFieldsFragmentDoc = gql`
    fragment HighlightedTokenFields on HighlightedToken {
  token
  score
}
    `;
export const HighlightedTokensFieldsFragmentDoc = gql`
    fragment HighlightedTokensFields on HighlightedTokens {
  description {
    ...HighlightedTokenFields
  }
  name {
    ...HighlightedTokenFields
  }
}
    ${HighlightedTokenFieldsFragmentDoc}`;
export const DataSourcePreferenceFieldsFragmentDoc = gql`
    fragment DataSourcePreferenceFields on DataSourcePreference {
  dataSourceId
  accountingBasis
  taxForm
  providerType
  departmentTerminology
}
    `;
export const DoppelgangerPermitFieldsFragmentDoc = gql`
    fragment DoppelgangerPermitFields on DoppelgangerPermit {
  id
  user {
    ...UserFields
  }
  expiresAt
  createdAt
}
    ${UserFieldsFragmentDoc}`;
export const CategoryMappingFieldsFragmentDoc = gql`
    fragment CategoryMappingFields on CategoryMapping {
  mappingValue
  mappingType
  product
  category {
    ...EntityCategoryFields
  }
}
    ${EntityCategoryFieldsFragmentDoc}`;
export const UpdateSourceAccountCategoryDocument = gql`
    mutation UpdateSourceAccountCategory($legalEntityId: String!, $dataSourceId: String!, $sourceAccountId: String!, $categoryId: String!) {
  updateSourceAccountCategory(
    legalEntityId: $legalEntityId
    dataSourceId: $dataSourceId
    sourceAccountId: $sourceAccountId
    categoryId: $categoryId
  )
}
    `;
export type UpdateSourceAccountCategoryMutationFn = Apollo.MutationFunction<UpdateSourceAccountCategoryMutation, UpdateSourceAccountCategoryMutationVariables>;

/**
 * __useUpdateSourceAccountCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateSourceAccountCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourceAccountCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourceAccountCategoryMutation, { data, loading, error }] = useUpdateSourceAccountCategoryMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      dataSourceId: // value for 'dataSourceId'
 *      sourceAccountId: // value for 'sourceAccountId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useUpdateSourceAccountCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSourceAccountCategoryMutation, UpdateSourceAccountCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSourceAccountCategoryMutation, UpdateSourceAccountCategoryMutationVariables>(UpdateSourceAccountCategoryDocument, options);
      }
export type UpdateSourceAccountCategoryMutationHookResult = ReturnType<typeof useUpdateSourceAccountCategoryMutation>;
export type UpdateSourceAccountCategoryMutationResult = Apollo.MutationResult<UpdateSourceAccountCategoryMutation>;
export type UpdateSourceAccountCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateSourceAccountCategoryMutation, UpdateSourceAccountCategoryMutationVariables>;
export const TriggerQuickBooksManualRefreshDocument = gql`
    mutation TriggerQuickBooksManualRefresh($dataSourceId: String!) {
  triggerQuickBooksManualRefresh(dataSourceId: $dataSourceId)
}
    `;
export type TriggerQuickBooksManualRefreshMutationFn = Apollo.MutationFunction<TriggerQuickBooksManualRefreshMutation, TriggerQuickBooksManualRefreshMutationVariables>;

/**
 * __useTriggerQuickBooksManualRefreshMutation__
 *
 * To run a mutation, you first call `useTriggerQuickBooksManualRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerQuickBooksManualRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerQuickBooksManualRefreshMutation, { data, loading, error }] = useTriggerQuickBooksManualRefreshMutation({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *   },
 * });
 */
export function useTriggerQuickBooksManualRefreshMutation(baseOptions?: Apollo.MutationHookOptions<TriggerQuickBooksManualRefreshMutation, TriggerQuickBooksManualRefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerQuickBooksManualRefreshMutation, TriggerQuickBooksManualRefreshMutationVariables>(TriggerQuickBooksManualRefreshDocument, options);
      }
export type TriggerQuickBooksManualRefreshMutationHookResult = ReturnType<typeof useTriggerQuickBooksManualRefreshMutation>;
export type TriggerQuickBooksManualRefreshMutationResult = Apollo.MutationResult<TriggerQuickBooksManualRefreshMutation>;
export type TriggerQuickBooksManualRefreshMutationOptions = Apollo.BaseMutationOptions<TriggerQuickBooksManualRefreshMutation, TriggerQuickBooksManualRefreshMutationVariables>;
export const CreateHiddenPredictedDataSourceDocument = gql`
    mutation CreateHiddenPredictedDataSource($legalEntityId: String!, $institutionId: String!, $predictedDataSourceType: PredictedDataSourceType!) {
  createHiddenPredictedDataSource(
    legalEntityId: $legalEntityId
    institutionId: $institutionId
    predictedDataSourceType: $predictedDataSourceType
  )
}
    `;
export type CreateHiddenPredictedDataSourceMutationFn = Apollo.MutationFunction<CreateHiddenPredictedDataSourceMutation, CreateHiddenPredictedDataSourceMutationVariables>;

/**
 * __useCreateHiddenPredictedDataSourceMutation__
 *
 * To run a mutation, you first call `useCreateHiddenPredictedDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHiddenPredictedDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHiddenPredictedDataSourceMutation, { data, loading, error }] = useCreateHiddenPredictedDataSourceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      institutionId: // value for 'institutionId'
 *      predictedDataSourceType: // value for 'predictedDataSourceType'
 *   },
 * });
 */
export function useCreateHiddenPredictedDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateHiddenPredictedDataSourceMutation, CreateHiddenPredictedDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHiddenPredictedDataSourceMutation, CreateHiddenPredictedDataSourceMutationVariables>(CreateHiddenPredictedDataSourceDocument, options);
      }
export type CreateHiddenPredictedDataSourceMutationHookResult = ReturnType<typeof useCreateHiddenPredictedDataSourceMutation>;
export type CreateHiddenPredictedDataSourceMutationResult = Apollo.MutationResult<CreateHiddenPredictedDataSourceMutation>;
export type CreateHiddenPredictedDataSourceMutationOptions = Apollo.BaseMutationOptions<CreateHiddenPredictedDataSourceMutation, CreateHiddenPredictedDataSourceMutationVariables>;
export const UpdateSourceAccountOwnerDocument = gql`
    mutation UpdateSourceAccountOwner($organizationId: String!, $legalEntityId: String!, $dataSourceId: String!, $sourceAccountId: String!, $employeeId: String!, $employeeFullName: String!) {
  updateSourceAccountOwner(
    organizationId: $organizationId
    legalEntityId: $legalEntityId
    dataSourceId: $dataSourceId
    sourceAccountId: $sourceAccountId
    employeeId: $employeeId
    employeeFullName: $employeeFullName
  )
}
    `;
export type UpdateSourceAccountOwnerMutationFn = Apollo.MutationFunction<UpdateSourceAccountOwnerMutation, UpdateSourceAccountOwnerMutationVariables>;

/**
 * __useUpdateSourceAccountOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateSourceAccountOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourceAccountOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourceAccountOwnerMutation, { data, loading, error }] = useUpdateSourceAccountOwnerMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      legalEntityId: // value for 'legalEntityId'
 *      dataSourceId: // value for 'dataSourceId'
 *      sourceAccountId: // value for 'sourceAccountId'
 *      employeeId: // value for 'employeeId'
 *      employeeFullName: // value for 'employeeFullName'
 *   },
 * });
 */
export function useUpdateSourceAccountOwnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSourceAccountOwnerMutation, UpdateSourceAccountOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSourceAccountOwnerMutation, UpdateSourceAccountOwnerMutationVariables>(UpdateSourceAccountOwnerDocument, options);
      }
export type UpdateSourceAccountOwnerMutationHookResult = ReturnType<typeof useUpdateSourceAccountOwnerMutation>;
export type UpdateSourceAccountOwnerMutationResult = Apollo.MutationResult<UpdateSourceAccountOwnerMutation>;
export type UpdateSourceAccountOwnerMutationOptions = Apollo.BaseMutationOptions<UpdateSourceAccountOwnerMutation, UpdateSourceAccountOwnerMutationVariables>;
export const DeleteSourceAccountOwnerDocument = gql`
    mutation DeleteSourceAccountOwner($legalEntityId: String!, $dataSourceId: String!, $sourceAccountId: String!) {
  deleteSourceAccountOwner(
    legalEntityId: $legalEntityId
    dataSourceId: $dataSourceId
    sourceAccountId: $sourceAccountId
  )
}
    `;
export type DeleteSourceAccountOwnerMutationFn = Apollo.MutationFunction<DeleteSourceAccountOwnerMutation, DeleteSourceAccountOwnerMutationVariables>;

/**
 * __useDeleteSourceAccountOwnerMutation__
 *
 * To run a mutation, you first call `useDeleteSourceAccountOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSourceAccountOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSourceAccountOwnerMutation, { data, loading, error }] = useDeleteSourceAccountOwnerMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      dataSourceId: // value for 'dataSourceId'
 *      sourceAccountId: // value for 'sourceAccountId'
 *   },
 * });
 */
export function useDeleteSourceAccountOwnerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSourceAccountOwnerMutation, DeleteSourceAccountOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSourceAccountOwnerMutation, DeleteSourceAccountOwnerMutationVariables>(DeleteSourceAccountOwnerDocument, options);
      }
export type DeleteSourceAccountOwnerMutationHookResult = ReturnType<typeof useDeleteSourceAccountOwnerMutation>;
export type DeleteSourceAccountOwnerMutationResult = Apollo.MutationResult<DeleteSourceAccountOwnerMutation>;
export type DeleteSourceAccountOwnerMutationOptions = Apollo.BaseMutationOptions<DeleteSourceAccountOwnerMutation, DeleteSourceAccountOwnerMutationVariables>;
export const DeleteDataSourceDocument = gql`
    mutation DeleteDataSource($legalEntityId: String!, $dataSourceId: String!, $providerType: ProviderType!) {
  deleteDataSource(
    legalEntityId: $legalEntityId
    dataSourceId: $dataSourceId
    providerType: $providerType
  )
}
    `;
export type DeleteDataSourceMutationFn = Apollo.MutationFunction<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;

/**
 * __useDeleteDataSourceMutation__
 *
 * To run a mutation, you first call `useDeleteDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataSourceMutation, { data, loading, error }] = useDeleteDataSourceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      dataSourceId: // value for 'dataSourceId'
 *      providerType: // value for 'providerType'
 *   },
 * });
 */
export function useDeleteDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>(DeleteDataSourceDocument, options);
      }
export type DeleteDataSourceMutationHookResult = ReturnType<typeof useDeleteDataSourceMutation>;
export type DeleteDataSourceMutationResult = Apollo.MutationResult<DeleteDataSourceMutation>;
export type DeleteDataSourceMutationOptions = Apollo.BaseMutationOptions<DeleteDataSourceMutation, DeleteDataSourceMutationVariables>;
export const CreateBooksClosedDateDocument = gql`
    mutation CreateBooksClosedDate($legalEntityId: String!, $booksClosedDate: Float) {
  createBooksClosedDate(
    legalEntityId: $legalEntityId
    booksClosedDate: $booksClosedDate
  )
}
    `;
export type CreateBooksClosedDateMutationFn = Apollo.MutationFunction<CreateBooksClosedDateMutation, CreateBooksClosedDateMutationVariables>;

/**
 * __useCreateBooksClosedDateMutation__
 *
 * To run a mutation, you first call `useCreateBooksClosedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBooksClosedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBooksClosedDateMutation, { data, loading, error }] = useCreateBooksClosedDateMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      booksClosedDate: // value for 'booksClosedDate'
 *   },
 * });
 */
export function useCreateBooksClosedDateMutation(baseOptions?: Apollo.MutationHookOptions<CreateBooksClosedDateMutation, CreateBooksClosedDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBooksClosedDateMutation, CreateBooksClosedDateMutationVariables>(CreateBooksClosedDateDocument, options);
      }
export type CreateBooksClosedDateMutationHookResult = ReturnType<typeof useCreateBooksClosedDateMutation>;
export type CreateBooksClosedDateMutationResult = Apollo.MutationResult<CreateBooksClosedDateMutation>;
export type CreateBooksClosedDateMutationOptions = Apollo.BaseMutationOptions<CreateBooksClosedDateMutation, CreateBooksClosedDateMutationVariables>;
export const CreateQuickBooksDataSourceDocument = gql`
    mutation CreateQuickBooksDataSource($legalEntityId: String!, $credential: OAuthCredentialFields!) {
  createQuickBooksDataSource(
    legalEntityId: $legalEntityId
    credential: $credential
  ) {
    dataSource {
      ...EnrichedDataSourceFields
    }
    quickBooksCompany {
      ...QuickBooksCompanyFields
    }
  }
}
    ${EnrichedDataSourceFieldsFragmentDoc}
${QuickBooksCompanyFieldsFragmentDoc}`;
export type CreateQuickBooksDataSourceMutationFn = Apollo.MutationFunction<CreateQuickBooksDataSourceMutation, CreateQuickBooksDataSourceMutationVariables>;

/**
 * __useCreateQuickBooksDataSourceMutation__
 *
 * To run a mutation, you first call `useCreateQuickBooksDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuickBooksDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuickBooksDataSourceMutation, { data, loading, error }] = useCreateQuickBooksDataSourceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      credential: // value for 'credential'
 *   },
 * });
 */
export function useCreateQuickBooksDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuickBooksDataSourceMutation, CreateQuickBooksDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuickBooksDataSourceMutation, CreateQuickBooksDataSourceMutationVariables>(CreateQuickBooksDataSourceDocument, options);
      }
export type CreateQuickBooksDataSourceMutationHookResult = ReturnType<typeof useCreateQuickBooksDataSourceMutation>;
export type CreateQuickBooksDataSourceMutationResult = Apollo.MutationResult<CreateQuickBooksDataSourceMutation>;
export type CreateQuickBooksDataSourceMutationOptions = Apollo.BaseMutationOptions<CreateQuickBooksDataSourceMutation, CreateQuickBooksDataSourceMutationVariables>;
export const UpdateDataSourceStatusDocument = gql`
    mutation UpdateDataSourceStatus($dataSourceId: String!, $status: DataSourceStatus!) {
  updateDataSourceStatus(dataSourceId: $dataSourceId, status: $status)
}
    `;
export type UpdateDataSourceStatusMutationFn = Apollo.MutationFunction<UpdateDataSourceStatusMutation, UpdateDataSourceStatusMutationVariables>;

/**
 * __useUpdateDataSourceStatusMutation__
 *
 * To run a mutation, you first call `useUpdateDataSourceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataSourceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataSourceStatusMutation, { data, loading, error }] = useUpdateDataSourceStatusMutation({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateDataSourceStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataSourceStatusMutation, UpdateDataSourceStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataSourceStatusMutation, UpdateDataSourceStatusMutationVariables>(UpdateDataSourceStatusDocument, options);
      }
export type UpdateDataSourceStatusMutationHookResult = ReturnType<typeof useUpdateDataSourceStatusMutation>;
export type UpdateDataSourceStatusMutationResult = Apollo.MutationResult<UpdateDataSourceStatusMutation>;
export type UpdateDataSourceStatusMutationOptions = Apollo.BaseMutationOptions<UpdateDataSourceStatusMutation, UpdateDataSourceStatusMutationVariables>;
export const ReadBooksClosedDateDocument = gql`
    query ReadBooksClosedDate($legalEntityId: String!) {
  readBooksClosedDate(legalEntityId: $legalEntityId) {
    ...BooksClosedDateFields
  }
}
    ${BooksClosedDateFieldsFragmentDoc}`;

/**
 * __useReadBooksClosedDateQuery__
 *
 * To run a query within a React component, call `useReadBooksClosedDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBooksClosedDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBooksClosedDateQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadBooksClosedDateQuery(baseOptions: Apollo.QueryHookOptions<ReadBooksClosedDateQuery, ReadBooksClosedDateQueryVariables> & ({ variables: ReadBooksClosedDateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBooksClosedDateQuery, ReadBooksClosedDateQueryVariables>(ReadBooksClosedDateDocument, options);
      }
export function useReadBooksClosedDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBooksClosedDateQuery, ReadBooksClosedDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBooksClosedDateQuery, ReadBooksClosedDateQueryVariables>(ReadBooksClosedDateDocument, options);
        }
export function useReadBooksClosedDateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadBooksClosedDateQuery, ReadBooksClosedDateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadBooksClosedDateQuery, ReadBooksClosedDateQueryVariables>(ReadBooksClosedDateDocument, options);
        }
export type ReadBooksClosedDateQueryHookResult = ReturnType<typeof useReadBooksClosedDateQuery>;
export type ReadBooksClosedDateLazyQueryHookResult = ReturnType<typeof useReadBooksClosedDateLazyQuery>;
export type ReadBooksClosedDateSuspenseQueryHookResult = ReturnType<typeof useReadBooksClosedDateSuspenseQuery>;
export type ReadBooksClosedDateQueryResult = Apollo.QueryResult<ReadBooksClosedDateQuery, ReadBooksClosedDateQueryVariables>;
export const ListEnrichedDataSourcesDocument = gql`
    query ListEnrichedDataSources($legalEntityId: String!) {
  listEnrichedDataSources(legalEntityId: $legalEntityId) {
    ...EnrichedDataSourceFields
  }
  listPredictedDataSources(legalEntityId: $legalEntityId) {
    ...PredictedDataSourceFields
  }
}
    ${EnrichedDataSourceFieldsFragmentDoc}
${PredictedDataSourceFieldsFragmentDoc}`;

/**
 * __useListEnrichedDataSourcesQuery__
 *
 * To run a query within a React component, call `useListEnrichedDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEnrichedDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEnrichedDataSourcesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListEnrichedDataSourcesQuery(baseOptions: Apollo.QueryHookOptions<ListEnrichedDataSourcesQuery, ListEnrichedDataSourcesQueryVariables> & ({ variables: ListEnrichedDataSourcesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEnrichedDataSourcesQuery, ListEnrichedDataSourcesQueryVariables>(ListEnrichedDataSourcesDocument, options);
      }
export function useListEnrichedDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEnrichedDataSourcesQuery, ListEnrichedDataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEnrichedDataSourcesQuery, ListEnrichedDataSourcesQueryVariables>(ListEnrichedDataSourcesDocument, options);
        }
export function useListEnrichedDataSourcesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListEnrichedDataSourcesQuery, ListEnrichedDataSourcesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEnrichedDataSourcesQuery, ListEnrichedDataSourcesQueryVariables>(ListEnrichedDataSourcesDocument, options);
        }
export type ListEnrichedDataSourcesQueryHookResult = ReturnType<typeof useListEnrichedDataSourcesQuery>;
export type ListEnrichedDataSourcesLazyQueryHookResult = ReturnType<typeof useListEnrichedDataSourcesLazyQuery>;
export type ListEnrichedDataSourcesSuspenseQueryHookResult = ReturnType<typeof useListEnrichedDataSourcesSuspenseQuery>;
export type ListEnrichedDataSourcesQueryResult = Apollo.QueryResult<ListEnrichedDataSourcesQuery, ListEnrichedDataSourcesQueryVariables>;
export const ReadSourceAccountCategoryDocument = gql`
    query ReadSourceAccountCategory($sourceAccountId: String!) {
  readSourceAccountCategory(sourceAccountId: $sourceAccountId) {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;

/**
 * __useReadSourceAccountCategoryQuery__
 *
 * To run a query within a React component, call `useReadSourceAccountCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSourceAccountCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSourceAccountCategoryQuery({
 *   variables: {
 *      sourceAccountId: // value for 'sourceAccountId'
 *   },
 * });
 */
export function useReadSourceAccountCategoryQuery(baseOptions: Apollo.QueryHookOptions<ReadSourceAccountCategoryQuery, ReadSourceAccountCategoryQueryVariables> & ({ variables: ReadSourceAccountCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSourceAccountCategoryQuery, ReadSourceAccountCategoryQueryVariables>(ReadSourceAccountCategoryDocument, options);
      }
export function useReadSourceAccountCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSourceAccountCategoryQuery, ReadSourceAccountCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSourceAccountCategoryQuery, ReadSourceAccountCategoryQueryVariables>(ReadSourceAccountCategoryDocument, options);
        }
export function useReadSourceAccountCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSourceAccountCategoryQuery, ReadSourceAccountCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSourceAccountCategoryQuery, ReadSourceAccountCategoryQueryVariables>(ReadSourceAccountCategoryDocument, options);
        }
export type ReadSourceAccountCategoryQueryHookResult = ReturnType<typeof useReadSourceAccountCategoryQuery>;
export type ReadSourceAccountCategoryLazyQueryHookResult = ReturnType<typeof useReadSourceAccountCategoryLazyQuery>;
export type ReadSourceAccountCategorySuspenseQueryHookResult = ReturnType<typeof useReadSourceAccountCategorySuspenseQuery>;
export type ReadSourceAccountCategoryQueryResult = Apollo.QueryResult<ReadSourceAccountCategoryQuery, ReadSourceAccountCategoryQueryVariables>;
export const ReadRealtimeHealthStatusDocument = gql`
    query ReadRealtimeHealthStatus($legalEntityId: String!) {
  readRealtimeHealthStatus(legalEntityId: $legalEntityId)
}
    `;

/**
 * __useReadRealtimeHealthStatusQuery__
 *
 * To run a query within a React component, call `useReadRealtimeHealthStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadRealtimeHealthStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadRealtimeHealthStatusQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadRealtimeHealthStatusQuery(baseOptions: Apollo.QueryHookOptions<ReadRealtimeHealthStatusQuery, ReadRealtimeHealthStatusQueryVariables> & ({ variables: ReadRealtimeHealthStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadRealtimeHealthStatusQuery, ReadRealtimeHealthStatusQueryVariables>(ReadRealtimeHealthStatusDocument, options);
      }
export function useReadRealtimeHealthStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadRealtimeHealthStatusQuery, ReadRealtimeHealthStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadRealtimeHealthStatusQuery, ReadRealtimeHealthStatusQueryVariables>(ReadRealtimeHealthStatusDocument, options);
        }
export function useReadRealtimeHealthStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadRealtimeHealthStatusQuery, ReadRealtimeHealthStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadRealtimeHealthStatusQuery, ReadRealtimeHealthStatusQueryVariables>(ReadRealtimeHealthStatusDocument, options);
        }
export type ReadRealtimeHealthStatusQueryHookResult = ReturnType<typeof useReadRealtimeHealthStatusQuery>;
export type ReadRealtimeHealthStatusLazyQueryHookResult = ReturnType<typeof useReadRealtimeHealthStatusLazyQuery>;
export type ReadRealtimeHealthStatusSuspenseQueryHookResult = ReturnType<typeof useReadRealtimeHealthStatusSuspenseQuery>;
export type ReadRealtimeHealthStatusQueryResult = Apollo.QueryResult<ReadRealtimeHealthStatusQuery, ReadRealtimeHealthStatusQueryVariables>;
export const ListPredictedDataSourcesDocument = gql`
    query ListPredictedDataSources($legalEntityId: String!) {
  listPredictedDataSources(legalEntityId: $legalEntityId) {
    ...PredictedDataSourceFields
  }
}
    ${PredictedDataSourceFieldsFragmentDoc}`;

/**
 * __useListPredictedDataSourcesQuery__
 *
 * To run a query within a React component, call `useListPredictedDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPredictedDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPredictedDataSourcesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListPredictedDataSourcesQuery(baseOptions: Apollo.QueryHookOptions<ListPredictedDataSourcesQuery, ListPredictedDataSourcesQueryVariables> & ({ variables: ListPredictedDataSourcesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPredictedDataSourcesQuery, ListPredictedDataSourcesQueryVariables>(ListPredictedDataSourcesDocument, options);
      }
export function useListPredictedDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPredictedDataSourcesQuery, ListPredictedDataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPredictedDataSourcesQuery, ListPredictedDataSourcesQueryVariables>(ListPredictedDataSourcesDocument, options);
        }
export function useListPredictedDataSourcesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListPredictedDataSourcesQuery, ListPredictedDataSourcesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPredictedDataSourcesQuery, ListPredictedDataSourcesQueryVariables>(ListPredictedDataSourcesDocument, options);
        }
export type ListPredictedDataSourcesQueryHookResult = ReturnType<typeof useListPredictedDataSourcesQuery>;
export type ListPredictedDataSourcesLazyQueryHookResult = ReturnType<typeof useListPredictedDataSourcesLazyQuery>;
export type ListPredictedDataSourcesSuspenseQueryHookResult = ReturnType<typeof useListPredictedDataSourcesSuspenseQuery>;
export type ListPredictedDataSourcesQueryResult = Apollo.QueryResult<ListPredictedDataSourcesQuery, ListPredictedDataSourcesQueryVariables>;
export const ReadDataSourcePreferencesDocument = gql`
    query ReadDataSourcePreferences($legalEntityId: String!) {
  readDataSourcePreferences(legalEntityId: $legalEntityId) {
    ...DataSourcePreferenceFields
  }
}
    ${DataSourcePreferenceFieldsFragmentDoc}`;

/**
 * __useReadDataSourcePreferencesQuery__
 *
 * To run a query within a React component, call `useReadDataSourcePreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDataSourcePreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDataSourcePreferencesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadDataSourcePreferencesQuery(baseOptions: Apollo.QueryHookOptions<ReadDataSourcePreferencesQuery, ReadDataSourcePreferencesQueryVariables> & ({ variables: ReadDataSourcePreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDataSourcePreferencesQuery, ReadDataSourcePreferencesQueryVariables>(ReadDataSourcePreferencesDocument, options);
      }
export function useReadDataSourcePreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDataSourcePreferencesQuery, ReadDataSourcePreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDataSourcePreferencesQuery, ReadDataSourcePreferencesQueryVariables>(ReadDataSourcePreferencesDocument, options);
        }
export function useReadDataSourcePreferencesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadDataSourcePreferencesQuery, ReadDataSourcePreferencesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadDataSourcePreferencesQuery, ReadDataSourcePreferencesQueryVariables>(ReadDataSourcePreferencesDocument, options);
        }
export type ReadDataSourcePreferencesQueryHookResult = ReturnType<typeof useReadDataSourcePreferencesQuery>;
export type ReadDataSourcePreferencesLazyQueryHookResult = ReturnType<typeof useReadDataSourcePreferencesLazyQuery>;
export type ReadDataSourcePreferencesSuspenseQueryHookResult = ReturnType<typeof useReadDataSourcePreferencesSuspenseQuery>;
export type ReadDataSourcePreferencesQueryResult = Apollo.QueryResult<ReadDataSourcePreferencesQuery, ReadDataSourcePreferencesQueryVariables>;
export const ReadDataSourceRefreshProgressDocument = gql`
    query ReadDataSourceRefreshProgress($dataSourceId: String!) {
  readRefreshProgress(dataSourceId: $dataSourceId) {
    ...RefreshProgressFields
  }
}
    ${RefreshProgressFieldsFragmentDoc}`;

/**
 * __useReadDataSourceRefreshProgressQuery__
 *
 * To run a query within a React component, call `useReadDataSourceRefreshProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDataSourceRefreshProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDataSourceRefreshProgressQuery({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *   },
 * });
 */
export function useReadDataSourceRefreshProgressQuery(baseOptions: Apollo.QueryHookOptions<ReadDataSourceRefreshProgressQuery, ReadDataSourceRefreshProgressQueryVariables> & ({ variables: ReadDataSourceRefreshProgressQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDataSourceRefreshProgressQuery, ReadDataSourceRefreshProgressQueryVariables>(ReadDataSourceRefreshProgressDocument, options);
      }
export function useReadDataSourceRefreshProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDataSourceRefreshProgressQuery, ReadDataSourceRefreshProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDataSourceRefreshProgressQuery, ReadDataSourceRefreshProgressQueryVariables>(ReadDataSourceRefreshProgressDocument, options);
        }
export function useReadDataSourceRefreshProgressSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadDataSourceRefreshProgressQuery, ReadDataSourceRefreshProgressQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadDataSourceRefreshProgressQuery, ReadDataSourceRefreshProgressQueryVariables>(ReadDataSourceRefreshProgressDocument, options);
        }
export type ReadDataSourceRefreshProgressQueryHookResult = ReturnType<typeof useReadDataSourceRefreshProgressQuery>;
export type ReadDataSourceRefreshProgressLazyQueryHookResult = ReturnType<typeof useReadDataSourceRefreshProgressLazyQuery>;
export type ReadDataSourceRefreshProgressSuspenseQueryHookResult = ReturnType<typeof useReadDataSourceRefreshProgressSuspenseQuery>;
export type ReadDataSourceRefreshProgressQueryResult = Apollo.QueryResult<ReadDataSourceRefreshProgressQuery, ReadDataSourceRefreshProgressQueryVariables>;
export const ListDeletedDataSourcesDocument = gql`
    query ListDeletedDataSources($legalEntityId: String!) {
  listDeletedDataSources(legalEntityId: $legalEntityId) {
    ...DataSourceFields
  }
}
    ${DataSourceFieldsFragmentDoc}`;

/**
 * __useListDeletedDataSourcesQuery__
 *
 * To run a query within a React component, call `useListDeletedDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDeletedDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDeletedDataSourcesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListDeletedDataSourcesQuery(baseOptions: Apollo.QueryHookOptions<ListDeletedDataSourcesQuery, ListDeletedDataSourcesQueryVariables> & ({ variables: ListDeletedDataSourcesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDeletedDataSourcesQuery, ListDeletedDataSourcesQueryVariables>(ListDeletedDataSourcesDocument, options);
      }
export function useListDeletedDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDeletedDataSourcesQuery, ListDeletedDataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDeletedDataSourcesQuery, ListDeletedDataSourcesQueryVariables>(ListDeletedDataSourcesDocument, options);
        }
export function useListDeletedDataSourcesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDeletedDataSourcesQuery, ListDeletedDataSourcesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDeletedDataSourcesQuery, ListDeletedDataSourcesQueryVariables>(ListDeletedDataSourcesDocument, options);
        }
export type ListDeletedDataSourcesQueryHookResult = ReturnType<typeof useListDeletedDataSourcesQuery>;
export type ListDeletedDataSourcesLazyQueryHookResult = ReturnType<typeof useListDeletedDataSourcesLazyQuery>;
export type ListDeletedDataSourcesSuspenseQueryHookResult = ReturnType<typeof useListDeletedDataSourcesSuspenseQuery>;
export type ListDeletedDataSourcesQueryResult = Apollo.QueryResult<ListDeletedDataSourcesQuery, ListDeletedDataSourcesQueryVariables>;
export const ListOwnedDataSourcesDocument = gql`
    query ListOwnedDataSources($legalEntityId: String!) {
  listOwnedDataSources(legalEntityId: $legalEntityId) {
    dataSource {
      ...EnrichedDataSourceFields
    }
    ownedSourceAccounts {
      ...OwnedSourceAccountFields
    }
  }
  listPredictedDataSources(legalEntityId: $legalEntityId) {
    ...PredictedDataSourceFields
  }
}
    ${EnrichedDataSourceFieldsFragmentDoc}
${OwnedSourceAccountFieldsFragmentDoc}
${PredictedDataSourceFieldsFragmentDoc}`;

/**
 * __useListOwnedDataSourcesQuery__
 *
 * To run a query within a React component, call `useListOwnedDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOwnedDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOwnedDataSourcesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListOwnedDataSourcesQuery(baseOptions: Apollo.QueryHookOptions<ListOwnedDataSourcesQuery, ListOwnedDataSourcesQueryVariables> & ({ variables: ListOwnedDataSourcesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOwnedDataSourcesQuery, ListOwnedDataSourcesQueryVariables>(ListOwnedDataSourcesDocument, options);
      }
export function useListOwnedDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOwnedDataSourcesQuery, ListOwnedDataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOwnedDataSourcesQuery, ListOwnedDataSourcesQueryVariables>(ListOwnedDataSourcesDocument, options);
        }
export function useListOwnedDataSourcesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListOwnedDataSourcesQuery, ListOwnedDataSourcesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListOwnedDataSourcesQuery, ListOwnedDataSourcesQueryVariables>(ListOwnedDataSourcesDocument, options);
        }
export type ListOwnedDataSourcesQueryHookResult = ReturnType<typeof useListOwnedDataSourcesQuery>;
export type ListOwnedDataSourcesLazyQueryHookResult = ReturnType<typeof useListOwnedDataSourcesLazyQuery>;
export type ListOwnedDataSourcesSuspenseQueryHookResult = ReturnType<typeof useListOwnedDataSourcesSuspenseQuery>;
export type ListOwnedDataSourcesQueryResult = Apollo.QueryResult<ListOwnedDataSourcesQuery, ListOwnedDataSourcesQueryVariables>;
export const ListDataSourcesDocument = gql`
    query ListDataSources($legalEntityId: String!) {
  listDataSources(legalEntityId: $legalEntityId) {
    ...DataSourceFields
  }
}
    ${DataSourceFieldsFragmentDoc}`;

/**
 * __useListDataSourcesQuery__
 *
 * To run a query within a React component, call `useListDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDataSourcesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListDataSourcesQuery(baseOptions: Apollo.QueryHookOptions<ListDataSourcesQuery, ListDataSourcesQueryVariables> & ({ variables: ListDataSourcesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDataSourcesQuery, ListDataSourcesQueryVariables>(ListDataSourcesDocument, options);
      }
export function useListDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDataSourcesQuery, ListDataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDataSourcesQuery, ListDataSourcesQueryVariables>(ListDataSourcesDocument, options);
        }
export function useListDataSourcesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDataSourcesQuery, ListDataSourcesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDataSourcesQuery, ListDataSourcesQueryVariables>(ListDataSourcesDocument, options);
        }
export type ListDataSourcesQueryHookResult = ReturnType<typeof useListDataSourcesQuery>;
export type ListDataSourcesLazyQueryHookResult = ReturnType<typeof useListDataSourcesLazyQuery>;
export type ListDataSourcesSuspenseQueryHookResult = ReturnType<typeof useListDataSourcesSuspenseQuery>;
export type ListDataSourcesQueryResult = Apollo.QueryResult<ListDataSourcesQuery, ListDataSourcesQueryVariables>;
export const AssociateInstitutionsDocument = gql`
    mutation AssociateInstitutions($legalEntityId: String!, $institutionIds: [String!]!, $source: AssociationSource!) {
  associateInstitutions(
    legalEntityId: $legalEntityId
    institutionIds: $institutionIds
    source: $source
  )
}
    `;
export type AssociateInstitutionsMutationFn = Apollo.MutationFunction<AssociateInstitutionsMutation, AssociateInstitutionsMutationVariables>;

/**
 * __useAssociateInstitutionsMutation__
 *
 * To run a mutation, you first call `useAssociateInstitutionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateInstitutionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateInstitutionsMutation, { data, loading, error }] = useAssociateInstitutionsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      institutionIds: // value for 'institutionIds'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useAssociateInstitutionsMutation(baseOptions?: Apollo.MutationHookOptions<AssociateInstitutionsMutation, AssociateInstitutionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssociateInstitutionsMutation, AssociateInstitutionsMutationVariables>(AssociateInstitutionsDocument, options);
      }
export type AssociateInstitutionsMutationHookResult = ReturnType<typeof useAssociateInstitutionsMutation>;
export type AssociateInstitutionsMutationResult = Apollo.MutationResult<AssociateInstitutionsMutation>;
export type AssociateInstitutionsMutationOptions = Apollo.BaseMutationOptions<AssociateInstitutionsMutation, AssociateInstitutionsMutationVariables>;
export const ListInstitutionsDocument = gql`
    query ListInstitutions($pagination: Pagination) {
  listInstitutions(pagination: $pagination) {
    ...ProductInstitutionFields
  }
}
    ${ProductInstitutionFieldsFragmentDoc}`;

/**
 * __useListInstitutionsQuery__
 *
 * To run a query within a React component, call `useListInstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInstitutionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListInstitutionsQuery(baseOptions?: Apollo.QueryHookOptions<ListInstitutionsQuery, ListInstitutionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInstitutionsQuery, ListInstitutionsQueryVariables>(ListInstitutionsDocument, options);
      }
export function useListInstitutionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInstitutionsQuery, ListInstitutionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInstitutionsQuery, ListInstitutionsQueryVariables>(ListInstitutionsDocument, options);
        }
export function useListInstitutionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListInstitutionsQuery, ListInstitutionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListInstitutionsQuery, ListInstitutionsQueryVariables>(ListInstitutionsDocument, options);
        }
export type ListInstitutionsQueryHookResult = ReturnType<typeof useListInstitutionsQuery>;
export type ListInstitutionsLazyQueryHookResult = ReturnType<typeof useListInstitutionsLazyQuery>;
export type ListInstitutionsSuspenseQueryHookResult = ReturnType<typeof useListInstitutionsSuspenseQuery>;
export type ListInstitutionsQueryResult = Apollo.QueryResult<ListInstitutionsQuery, ListInstitutionsQueryVariables>;
export const AskAssistantDocument = gql`
    subscription AskAssistant($viewKey: ViewKey!, $threadId: String, $messages: [String!]!) {
  askAssistant(viewKey: $viewKey, threadId: $threadId, messages: $messages) {
    kind
    comment {
      ...CommentFields
    }
    threadId
    layout {
      ...LayoutFields
    }
    suggestions
  }
}
    ${CommentFieldsFragmentDoc}
${LayoutFieldsFragmentDoc}`;

/**
 * __useAskAssistantSubscription__
 *
 * To run a query within a React component, call `useAskAssistantSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAskAssistantSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAskAssistantSubscription({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      threadId: // value for 'threadId'
 *      messages: // value for 'messages'
 *   },
 * });
 */
export function useAskAssistantSubscription(baseOptions: Apollo.SubscriptionHookOptions<AskAssistantSubscription, AskAssistantSubscriptionVariables> & ({ variables: AskAssistantSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AskAssistantSubscription, AskAssistantSubscriptionVariables>(AskAssistantDocument, options);
      }
export type AskAssistantSubscriptionHookResult = ReturnType<typeof useAskAssistantSubscription>;
export type AskAssistantSubscriptionResult = Apollo.SubscriptionResult<AskAssistantSubscription>;
export const GenerateExecutiveSummaryDocument = gql`
    query GenerateExecutiveSummary($legalEntityId: String!, $prompt: String!) {
  generateExecutiveSummary(legalEntityId: $legalEntityId, prompt: $prompt)
}
    `;

/**
 * __useGenerateExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGenerateExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateExecutiveSummaryQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      prompt: // value for 'prompt'
 *   },
 * });
 */
export function useGenerateExecutiveSummaryQuery(baseOptions: Apollo.QueryHookOptions<GenerateExecutiveSummaryQuery, GenerateExecutiveSummaryQueryVariables> & ({ variables: GenerateExecutiveSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateExecutiveSummaryQuery, GenerateExecutiveSummaryQueryVariables>(GenerateExecutiveSummaryDocument, options);
      }
export function useGenerateExecutiveSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateExecutiveSummaryQuery, GenerateExecutiveSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateExecutiveSummaryQuery, GenerateExecutiveSummaryQueryVariables>(GenerateExecutiveSummaryDocument, options);
        }
export function useGenerateExecutiveSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerateExecutiveSummaryQuery, GenerateExecutiveSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateExecutiveSummaryQuery, GenerateExecutiveSummaryQueryVariables>(GenerateExecutiveSummaryDocument, options);
        }
export type GenerateExecutiveSummaryQueryHookResult = ReturnType<typeof useGenerateExecutiveSummaryQuery>;
export type GenerateExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGenerateExecutiveSummaryLazyQuery>;
export type GenerateExecutiveSummarySuspenseQueryHookResult = ReturnType<typeof useGenerateExecutiveSummarySuspenseQuery>;
export type GenerateExecutiveSummaryQueryResult = Apollo.QueryResult<GenerateExecutiveSummaryQuery, GenerateExecutiveSummaryQueryVariables>;
export const UpdateOrganizationPreferencesDocument = gql`
    mutation UpdateOrganizationPreferences($id: String!, $anonymizeEmployees: Boolean!) {
  updateOrganizationPreferences(id: $id, anonymizeEmployees: $anonymizeEmployees)
}
    `;
export type UpdateOrganizationPreferencesMutationFn = Apollo.MutationFunction<UpdateOrganizationPreferencesMutation, UpdateOrganizationPreferencesMutationVariables>;

/**
 * __useUpdateOrganizationPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationPreferencesMutation, { data, loading, error }] = useUpdateOrganizationPreferencesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      anonymizeEmployees: // value for 'anonymizeEmployees'
 *   },
 * });
 */
export function useUpdateOrganizationPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationPreferencesMutation, UpdateOrganizationPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationPreferencesMutation, UpdateOrganizationPreferencesMutationVariables>(UpdateOrganizationPreferencesDocument, options);
      }
export type UpdateOrganizationPreferencesMutationHookResult = ReturnType<typeof useUpdateOrganizationPreferencesMutation>;
export type UpdateOrganizationPreferencesMutationResult = Apollo.MutationResult<UpdateOrganizationPreferencesMutation>;
export type UpdateOrganizationPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationPreferencesMutation, UpdateOrganizationPreferencesMutationVariables>;
export const ImportOAuthEmployeesDocument = gql`
    mutation ImportOAuthEmployees($service: String!, $organizationId: String!) {
  importOAuthEmployees(service: $service, organizationId: $organizationId) {
    newEmployeeCount
  }
}
    `;
export type ImportOAuthEmployeesMutationFn = Apollo.MutationFunction<ImportOAuthEmployeesMutation, ImportOAuthEmployeesMutationVariables>;

/**
 * __useImportOAuthEmployeesMutation__
 *
 * To run a mutation, you first call `useImportOAuthEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportOAuthEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importOAuthEmployeesMutation, { data, loading, error }] = useImportOAuthEmployeesMutation({
 *   variables: {
 *      service: // value for 'service'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useImportOAuthEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<ImportOAuthEmployeesMutation, ImportOAuthEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportOAuthEmployeesMutation, ImportOAuthEmployeesMutationVariables>(ImportOAuthEmployeesDocument, options);
      }
export type ImportOAuthEmployeesMutationHookResult = ReturnType<typeof useImportOAuthEmployeesMutation>;
export type ImportOAuthEmployeesMutationResult = Apollo.MutationResult<ImportOAuthEmployeesMutation>;
export type ImportOAuthEmployeesMutationOptions = Apollo.BaseMutationOptions<ImportOAuthEmployeesMutation, ImportOAuthEmployeesMutationVariables>;
export const UploadOrganizationIconDocument = gql`
    mutation UploadOrganizationIcon($id: String!, $name: String!, $iconBase64: String!) {
  uploadOrganizationIcon(id: $id, name: $name, iconBase64: $iconBase64)
}
    `;
export type UploadOrganizationIconMutationFn = Apollo.MutationFunction<UploadOrganizationIconMutation, UploadOrganizationIconMutationVariables>;

/**
 * __useUploadOrganizationIconMutation__
 *
 * To run a mutation, you first call `useUploadOrganizationIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOrganizationIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOrganizationIconMutation, { data, loading, error }] = useUploadOrganizationIconMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      iconBase64: // value for 'iconBase64'
 *   },
 * });
 */
export function useUploadOrganizationIconMutation(baseOptions?: Apollo.MutationHookOptions<UploadOrganizationIconMutation, UploadOrganizationIconMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadOrganizationIconMutation, UploadOrganizationIconMutationVariables>(UploadOrganizationIconDocument, options);
      }
export type UploadOrganizationIconMutationHookResult = ReturnType<typeof useUploadOrganizationIconMutation>;
export type UploadOrganizationIconMutationResult = Apollo.MutationResult<UploadOrganizationIconMutation>;
export type UploadOrganizationIconMutationOptions = Apollo.BaseMutationOptions<UploadOrganizationIconMutation, UploadOrganizationIconMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($id: String!, $name: String!, $websiteUrl: String) {
  updateOrganization(id: $id, name: $name, websiteUrl: $websiteUrl)
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      websiteUrl: // value for 'websiteUrl'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const ReadOrganizationTemplateDocument = gql`
    query ReadOrganizationTemplate($id: String!) {
  readOrganizationTemplate(id: $id) {
    id
    name
    iconUrl
    websiteUrl
  }
}
    `;

/**
 * __useReadOrganizationTemplateQuery__
 *
 * To run a query within a React component, call `useReadOrganizationTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrganizationTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrganizationTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadOrganizationTemplateQuery(baseOptions: Apollo.QueryHookOptions<ReadOrganizationTemplateQuery, ReadOrganizationTemplateQueryVariables> & ({ variables: ReadOrganizationTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOrganizationTemplateQuery, ReadOrganizationTemplateQueryVariables>(ReadOrganizationTemplateDocument, options);
      }
export function useReadOrganizationTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOrganizationTemplateQuery, ReadOrganizationTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOrganizationTemplateQuery, ReadOrganizationTemplateQueryVariables>(ReadOrganizationTemplateDocument, options);
        }
export function useReadOrganizationTemplateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadOrganizationTemplateQuery, ReadOrganizationTemplateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadOrganizationTemplateQuery, ReadOrganizationTemplateQueryVariables>(ReadOrganizationTemplateDocument, options);
        }
export type ReadOrganizationTemplateQueryHookResult = ReturnType<typeof useReadOrganizationTemplateQuery>;
export type ReadOrganizationTemplateLazyQueryHookResult = ReturnType<typeof useReadOrganizationTemplateLazyQuery>;
export type ReadOrganizationTemplateSuspenseQueryHookResult = ReturnType<typeof useReadOrganizationTemplateSuspenseQuery>;
export type ReadOrganizationTemplateQueryResult = Apollo.QueryResult<ReadOrganizationTemplateQuery, ReadOrganizationTemplateQueryVariables>;
export const ListOrganizationAffiliationsDocument = gql`
    query ListOrganizationAffiliations($id: String!) {
  listOrganizationAffiliations(id: $id) {
    ...OrganizationAffiliationFields
  }
}
    ${OrganizationAffiliationFieldsFragmentDoc}`;

/**
 * __useListOrganizationAffiliationsQuery__
 *
 * To run a query within a React component, call `useListOrganizationAffiliationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationAffiliationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationAffiliationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListOrganizationAffiliationsQuery(baseOptions: Apollo.QueryHookOptions<ListOrganizationAffiliationsQuery, ListOrganizationAffiliationsQueryVariables> & ({ variables: ListOrganizationAffiliationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationAffiliationsQuery, ListOrganizationAffiliationsQueryVariables>(ListOrganizationAffiliationsDocument, options);
      }
export function useListOrganizationAffiliationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationAffiliationsQuery, ListOrganizationAffiliationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationAffiliationsQuery, ListOrganizationAffiliationsQueryVariables>(ListOrganizationAffiliationsDocument, options);
        }
export function useListOrganizationAffiliationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListOrganizationAffiliationsQuery, ListOrganizationAffiliationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListOrganizationAffiliationsQuery, ListOrganizationAffiliationsQueryVariables>(ListOrganizationAffiliationsDocument, options);
        }
export type ListOrganizationAffiliationsQueryHookResult = ReturnType<typeof useListOrganizationAffiliationsQuery>;
export type ListOrganizationAffiliationsLazyQueryHookResult = ReturnType<typeof useListOrganizationAffiliationsLazyQuery>;
export type ListOrganizationAffiliationsSuspenseQueryHookResult = ReturnType<typeof useListOrganizationAffiliationsSuspenseQuery>;
export type ListOrganizationAffiliationsQueryResult = Apollo.QueryResult<ListOrganizationAffiliationsQuery, ListOrganizationAffiliationsQueryVariables>;
export const ReadOrganizationDocument = gql`
    query ReadOrganization($id: String!) {
  readOrganization(id: $id) {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;

/**
 * __useReadOrganizationQuery__
 *
 * To run a query within a React component, call `useReadOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ReadOrganizationQuery, ReadOrganizationQueryVariables> & ({ variables: ReadOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOrganizationQuery, ReadOrganizationQueryVariables>(ReadOrganizationDocument, options);
      }
export function useReadOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOrganizationQuery, ReadOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOrganizationQuery, ReadOrganizationQueryVariables>(ReadOrganizationDocument, options);
        }
export function useReadOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadOrganizationQuery, ReadOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadOrganizationQuery, ReadOrganizationQueryVariables>(ReadOrganizationDocument, options);
        }
export type ReadOrganizationQueryHookResult = ReturnType<typeof useReadOrganizationQuery>;
export type ReadOrganizationLazyQueryHookResult = ReturnType<typeof useReadOrganizationLazyQuery>;
export type ReadOrganizationSuspenseQueryHookResult = ReturnType<typeof useReadOrganizationSuspenseQuery>;
export type ReadOrganizationQueryResult = Apollo.QueryResult<ReadOrganizationQuery, ReadOrganizationQueryVariables>;
export const ListOrganizationUsersDocument = gql`
    query ListOrganizationUsers($id: String!) {
  listOrganizationUsers(id: $id) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useListOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useListOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListOrganizationUsersQuery(baseOptions: Apollo.QueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables> & ({ variables: ListOrganizationUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(ListOrganizationUsersDocument, options);
      }
export function useListOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(ListOrganizationUsersDocument, options);
        }
export function useListOrganizationUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(ListOrganizationUsersDocument, options);
        }
export type ListOrganizationUsersQueryHookResult = ReturnType<typeof useListOrganizationUsersQuery>;
export type ListOrganizationUsersLazyQueryHookResult = ReturnType<typeof useListOrganizationUsersLazyQuery>;
export type ListOrganizationUsersSuspenseQueryHookResult = ReturnType<typeof useListOrganizationUsersSuspenseQuery>;
export type ListOrganizationUsersQueryResult = Apollo.QueryResult<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>;
export const ListAllOrganizationUsersDocument = gql`
    query ListAllOrganizationUsers($id: String!) {
  listOrganizationUsers(id: $id) {
    ...UserFields
  }
  listOrganizationInvitations(id: $id) {
    ...InvitationFields
  }
}
    ${UserFieldsFragmentDoc}
${InvitationFieldsFragmentDoc}`;

/**
 * __useListAllOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useListAllOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllOrganizationUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListAllOrganizationUsersQuery(baseOptions: Apollo.QueryHookOptions<ListAllOrganizationUsersQuery, ListAllOrganizationUsersQueryVariables> & ({ variables: ListAllOrganizationUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllOrganizationUsersQuery, ListAllOrganizationUsersQueryVariables>(ListAllOrganizationUsersDocument, options);
      }
export function useListAllOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllOrganizationUsersQuery, ListAllOrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllOrganizationUsersQuery, ListAllOrganizationUsersQueryVariables>(ListAllOrganizationUsersDocument, options);
        }
export function useListAllOrganizationUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAllOrganizationUsersQuery, ListAllOrganizationUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAllOrganizationUsersQuery, ListAllOrganizationUsersQueryVariables>(ListAllOrganizationUsersDocument, options);
        }
export type ListAllOrganizationUsersQueryHookResult = ReturnType<typeof useListAllOrganizationUsersQuery>;
export type ListAllOrganizationUsersLazyQueryHookResult = ReturnType<typeof useListAllOrganizationUsersLazyQuery>;
export type ListAllOrganizationUsersSuspenseQueryHookResult = ReturnType<typeof useListAllOrganizationUsersSuspenseQuery>;
export type ListAllOrganizationUsersQueryResult = Apollo.QueryResult<ListAllOrganizationUsersQuery, ListAllOrganizationUsersQueryVariables>;
export const ReadOrganizationPreferencesDocument = gql`
    query ReadOrganizationPreferences($id: String!) {
  readOrganizationPreferences(id: $id) {
    id
    anonymizeEmployees
  }
}
    `;

/**
 * __useReadOrganizationPreferencesQuery__
 *
 * To run a query within a React component, call `useReadOrganizationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrganizationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrganizationPreferencesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadOrganizationPreferencesQuery(baseOptions: Apollo.QueryHookOptions<ReadOrganizationPreferencesQuery, ReadOrganizationPreferencesQueryVariables> & ({ variables: ReadOrganizationPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOrganizationPreferencesQuery, ReadOrganizationPreferencesQueryVariables>(ReadOrganizationPreferencesDocument, options);
      }
export function useReadOrganizationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOrganizationPreferencesQuery, ReadOrganizationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOrganizationPreferencesQuery, ReadOrganizationPreferencesQueryVariables>(ReadOrganizationPreferencesDocument, options);
        }
export function useReadOrganizationPreferencesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadOrganizationPreferencesQuery, ReadOrganizationPreferencesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadOrganizationPreferencesQuery, ReadOrganizationPreferencesQueryVariables>(ReadOrganizationPreferencesDocument, options);
        }
export type ReadOrganizationPreferencesQueryHookResult = ReturnType<typeof useReadOrganizationPreferencesQuery>;
export type ReadOrganizationPreferencesLazyQueryHookResult = ReturnType<typeof useReadOrganizationPreferencesLazyQuery>;
export type ReadOrganizationPreferencesSuspenseQueryHookResult = ReturnType<typeof useReadOrganizationPreferencesSuspenseQuery>;
export type ReadOrganizationPreferencesQueryResult = Apollo.QueryResult<ReadOrganizationPreferencesQuery, ReadOrganizationPreferencesQueryVariables>;
export const FactIdForLedgerTransactionIdDocument = gql`
    query FactIdForLedgerTransactionId($ledgerTransactionId: String!, $viewKey: ViewKey!) {
  factIdForLedgerTransactionId(
    ledgerTransactionId: $ledgerTransactionId
    viewKey: $viewKey
  )
}
    `;

/**
 * __useFactIdForLedgerTransactionIdQuery__
 *
 * To run a query within a React component, call `useFactIdForLedgerTransactionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactIdForLedgerTransactionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactIdForLedgerTransactionIdQuery({
 *   variables: {
 *      ledgerTransactionId: // value for 'ledgerTransactionId'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useFactIdForLedgerTransactionIdQuery(baseOptions: Apollo.QueryHookOptions<FactIdForLedgerTransactionIdQuery, FactIdForLedgerTransactionIdQueryVariables> & ({ variables: FactIdForLedgerTransactionIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FactIdForLedgerTransactionIdQuery, FactIdForLedgerTransactionIdQueryVariables>(FactIdForLedgerTransactionIdDocument, options);
      }
export function useFactIdForLedgerTransactionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FactIdForLedgerTransactionIdQuery, FactIdForLedgerTransactionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FactIdForLedgerTransactionIdQuery, FactIdForLedgerTransactionIdQueryVariables>(FactIdForLedgerTransactionIdDocument, options);
        }
export function useFactIdForLedgerTransactionIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FactIdForLedgerTransactionIdQuery, FactIdForLedgerTransactionIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FactIdForLedgerTransactionIdQuery, FactIdForLedgerTransactionIdQueryVariables>(FactIdForLedgerTransactionIdDocument, options);
        }
export type FactIdForLedgerTransactionIdQueryHookResult = ReturnType<typeof useFactIdForLedgerTransactionIdQuery>;
export type FactIdForLedgerTransactionIdLazyQueryHookResult = ReturnType<typeof useFactIdForLedgerTransactionIdLazyQuery>;
export type FactIdForLedgerTransactionIdSuspenseQueryHookResult = ReturnType<typeof useFactIdForLedgerTransactionIdSuspenseQuery>;
export type FactIdForLedgerTransactionIdQueryResult = Apollo.QueryResult<FactIdForLedgerTransactionIdQuery, FactIdForLedgerTransactionIdQueryVariables>;
export const ListInsightsDocument = gql`
    query ListInsights($interval: Interval!, $intervalCount: Int, $year: Int!, $index: Int!, $paginationDirection: DirectionFromOrigin!, $paginationLimit: Int!, $paginationOffset: Int!, $filter: InsightFilter!) {
  listInsights(
    filter: $filter
    origin: {interval: $interval, year: $year, index: $index, intervalCount: $intervalCount}
    pagination: {limit: $paginationLimit, offset: $paginationOffset}
    direction: $paginationDirection
  ) {
    period {
      ...PeriodFields
    }
    insights {
      ...InsightFields
    }
    entities {
      ...ObjectEntitiesFields
    }
  }
}
    ${PeriodFieldsFragmentDoc}
${InsightFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;

/**
 * __useListInsightsQuery__
 *
 * To run a query within a React component, call `useListInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInsightsQuery({
 *   variables: {
 *      interval: // value for 'interval'
 *      intervalCount: // value for 'intervalCount'
 *      year: // value for 'year'
 *      index: // value for 'index'
 *      paginationDirection: // value for 'paginationDirection'
 *      paginationLimit: // value for 'paginationLimit'
 *      paginationOffset: // value for 'paginationOffset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListInsightsQuery(baseOptions: Apollo.QueryHookOptions<ListInsightsQuery, ListInsightsQueryVariables> & ({ variables: ListInsightsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInsightsQuery, ListInsightsQueryVariables>(ListInsightsDocument, options);
      }
export function useListInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInsightsQuery, ListInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInsightsQuery, ListInsightsQueryVariables>(ListInsightsDocument, options);
        }
export function useListInsightsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListInsightsQuery, ListInsightsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListInsightsQuery, ListInsightsQueryVariables>(ListInsightsDocument, options);
        }
export type ListInsightsQueryHookResult = ReturnType<typeof useListInsightsQuery>;
export type ListInsightsLazyQueryHookResult = ReturnType<typeof useListInsightsLazyQuery>;
export type ListInsightsSuspenseQueryHookResult = ReturnType<typeof useListInsightsSuspenseQuery>;
export type ListInsightsQueryResult = Apollo.QueryResult<ListInsightsQuery, ListInsightsQueryVariables>;
export const ListDoppelgangerPermitsDocument = gql`
    query ListDoppelgangerPermits {
  listDoppelgangerPermits {
    ...DoppelgangerPermitFields
  }
}
    ${DoppelgangerPermitFieldsFragmentDoc}`;

/**
 * __useListDoppelgangerPermitsQuery__
 *
 * To run a query within a React component, call `useListDoppelgangerPermitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDoppelgangerPermitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDoppelgangerPermitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListDoppelgangerPermitsQuery(baseOptions?: Apollo.QueryHookOptions<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>(ListDoppelgangerPermitsDocument, options);
      }
export function useListDoppelgangerPermitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>(ListDoppelgangerPermitsDocument, options);
        }
export function useListDoppelgangerPermitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>(ListDoppelgangerPermitsDocument, options);
        }
export type ListDoppelgangerPermitsQueryHookResult = ReturnType<typeof useListDoppelgangerPermitsQuery>;
export type ListDoppelgangerPermitsLazyQueryHookResult = ReturnType<typeof useListDoppelgangerPermitsLazyQuery>;
export type ListDoppelgangerPermitsSuspenseQueryHookResult = ReturnType<typeof useListDoppelgangerPermitsSuspenseQuery>;
export type ListDoppelgangerPermitsQueryResult = Apollo.QueryResult<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>;
export const CreateThreadDocument = gql`
    mutation CreateThread($legalEntityId: String!, $kind: ObjectKind!, $id: String!, $comment: NewComment!, $context: String, $viewKey: ViewKey, $assignee: Assignee) {
  thread: createThread(
    targetObject: {legalEntityId: $legalEntityId, kind: $kind, id: $id}
    comment: $comment
    context: $context
    viewKey: $viewKey
    assignee: $assignee
  ) {
    ...ThreadWithEntitiesFields
  }
}
    ${ThreadWithEntitiesFieldsFragmentDoc}`;
export type CreateThreadMutationFn = Apollo.MutationFunction<CreateThreadMutation, CreateThreadMutationVariables>;

/**
 * __useCreateThreadMutation__
 *
 * To run a mutation, you first call `useCreateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThreadMutation, { data, loading, error }] = useCreateThreadMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      kind: // value for 'kind'
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *      context: // value for 'context'
 *      viewKey: // value for 'viewKey'
 *      assignee: // value for 'assignee'
 *   },
 * });
 */
export function useCreateThreadMutation(baseOptions?: Apollo.MutationHookOptions<CreateThreadMutation, CreateThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateThreadMutation, CreateThreadMutationVariables>(CreateThreadDocument, options);
      }
export type CreateThreadMutationHookResult = ReturnType<typeof useCreateThreadMutation>;
export type CreateThreadMutationResult = Apollo.MutationResult<CreateThreadMutation>;
export type CreateThreadMutationOptions = Apollo.BaseMutationOptions<CreateThreadMutation, CreateThreadMutationVariables>;
export const ResolveThreadDocument = gql`
    mutation ResolveThread($threadId: String!, $viewKey: ViewKey) {
  resolveThread(threadId: $threadId, viewKey: $viewKey)
}
    `;
export type ResolveThreadMutationFn = Apollo.MutationFunction<ResolveThreadMutation, ResolveThreadMutationVariables>;

/**
 * __useResolveThreadMutation__
 *
 * To run a mutation, you first call `useResolveThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveThreadMutation, { data, loading, error }] = useResolveThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useResolveThreadMutation(baseOptions?: Apollo.MutationHookOptions<ResolveThreadMutation, ResolveThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveThreadMutation, ResolveThreadMutationVariables>(ResolveThreadDocument, options);
      }
export type ResolveThreadMutationHookResult = ReturnType<typeof useResolveThreadMutation>;
export type ResolveThreadMutationResult = Apollo.MutationResult<ResolveThreadMutation>;
export type ResolveThreadMutationOptions = Apollo.BaseMutationOptions<ResolveThreadMutation, ResolveThreadMutationVariables>;
export const DeleteReactionDocument = gql`
    mutation DeleteReaction($threadId: String!, $commentId: String!, $reaction: CommentReaction!) {
  deleteReaction(threadId: $threadId, commentId: $commentId, reaction: $reaction)
}
    `;
export type DeleteReactionMutationFn = Apollo.MutationFunction<DeleteReactionMutation, DeleteReactionMutationVariables>;

/**
 * __useDeleteReactionMutation__
 *
 * To run a mutation, you first call `useDeleteReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReactionMutation, { data, loading, error }] = useDeleteReactionMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      commentId: // value for 'commentId'
 *      reaction: // value for 'reaction'
 *   },
 * });
 */
export function useDeleteReactionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReactionMutation, DeleteReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReactionMutation, DeleteReactionMutationVariables>(DeleteReactionDocument, options);
      }
export type DeleteReactionMutationHookResult = ReturnType<typeof useDeleteReactionMutation>;
export type DeleteReactionMutationResult = Apollo.MutationResult<DeleteReactionMutation>;
export type DeleteReactionMutationOptions = Apollo.BaseMutationOptions<DeleteReactionMutation, DeleteReactionMutationVariables>;
export const CreateReactionDocument = gql`
    mutation CreateReaction($threadId: String!, $commentId: String!, $reaction: CommentReaction!) {
  createReaction(threadId: $threadId, commentId: $commentId, reaction: $reaction)
}
    `;
export type CreateReactionMutationFn = Apollo.MutationFunction<CreateReactionMutation, CreateReactionMutationVariables>;

/**
 * __useCreateReactionMutation__
 *
 * To run a mutation, you first call `useCreateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReactionMutation, { data, loading, error }] = useCreateReactionMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      commentId: // value for 'commentId'
 *      reaction: // value for 'reaction'
 *   },
 * });
 */
export function useCreateReactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateReactionMutation, CreateReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReactionMutation, CreateReactionMutationVariables>(CreateReactionDocument, options);
      }
export type CreateReactionMutationHookResult = ReturnType<typeof useCreateReactionMutation>;
export type CreateReactionMutationResult = Apollo.MutationResult<CreateReactionMutation>;
export type CreateReactionMutationOptions = Apollo.BaseMutationOptions<CreateReactionMutation, CreateReactionMutationVariables>;
export const ReplyThreadDocument = gql`
    mutation ReplyThread($threadId: String!, $comment: NewComment!, $assignee: Assignee, $viewKey: ViewKey) {
  comment: replyThread(
    threadId: $threadId
    comment: $comment
    viewKey: $viewKey
    assignee: $assignee
  ) {
    comment {
      ...CommentFields
    }
    entities {
      ...ObjectEntitiesFields
    }
  }
}
    ${CommentFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export type ReplyThreadMutationFn = Apollo.MutationFunction<ReplyThreadMutation, ReplyThreadMutationVariables>;

/**
 * __useReplyThreadMutation__
 *
 * To run a mutation, you first call `useReplyThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyThreadMutation, { data, loading, error }] = useReplyThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      comment: // value for 'comment'
 *      assignee: // value for 'assignee'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useReplyThreadMutation(baseOptions?: Apollo.MutationHookOptions<ReplyThreadMutation, ReplyThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplyThreadMutation, ReplyThreadMutationVariables>(ReplyThreadDocument, options);
      }
export type ReplyThreadMutationHookResult = ReturnType<typeof useReplyThreadMutation>;
export type ReplyThreadMutationResult = Apollo.MutationResult<ReplyThreadMutation>;
export type ReplyThreadMutationOptions = Apollo.BaseMutationOptions<ReplyThreadMutation, ReplyThreadMutationVariables>;
export const ListThreadsDocument = gql`
    query ListThreads($idsByKind: IdentifiersByKind!, $viewType: ViewType!) {
  listThreads(idsByKind: $idsByKind, viewType: $viewType) {
    ...ThreadsFields
  }
}
    ${ThreadsFieldsFragmentDoc}`;

/**
 * __useListThreadsQuery__
 *
 * To run a query within a React component, call `useListThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListThreadsQuery({
 *   variables: {
 *      idsByKind: // value for 'idsByKind'
 *      viewType: // value for 'viewType'
 *   },
 * });
 */
export function useListThreadsQuery(baseOptions: Apollo.QueryHookOptions<ListThreadsQuery, ListThreadsQueryVariables> & ({ variables: ListThreadsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListThreadsQuery, ListThreadsQueryVariables>(ListThreadsDocument, options);
      }
export function useListThreadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListThreadsQuery, ListThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListThreadsQuery, ListThreadsQueryVariables>(ListThreadsDocument, options);
        }
export function useListThreadsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListThreadsQuery, ListThreadsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListThreadsQuery, ListThreadsQueryVariables>(ListThreadsDocument, options);
        }
export type ListThreadsQueryHookResult = ReturnType<typeof useListThreadsQuery>;
export type ListThreadsLazyQueryHookResult = ReturnType<typeof useListThreadsLazyQuery>;
export type ListThreadsSuspenseQueryHookResult = ReturnType<typeof useListThreadsSuspenseQuery>;
export type ListThreadsQueryResult = Apollo.QueryResult<ListThreadsQuery, ListThreadsQueryVariables>;
export const FindThreadByCommentDocument = gql`
    query FindThreadByComment($legalEntityId: String!, $commentId: String!) {
  thread: findThreadByComment(
    legalEntityId: $legalEntityId
    commentId: $commentId
  ) {
    ...ThreadDetailsFields
  }
}
    ${ThreadDetailsFieldsFragmentDoc}`;

/**
 * __useFindThreadByCommentQuery__
 *
 * To run a query within a React component, call `useFindThreadByCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindThreadByCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindThreadByCommentQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useFindThreadByCommentQuery(baseOptions: Apollo.QueryHookOptions<FindThreadByCommentQuery, FindThreadByCommentQueryVariables> & ({ variables: FindThreadByCommentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindThreadByCommentQuery, FindThreadByCommentQueryVariables>(FindThreadByCommentDocument, options);
      }
export function useFindThreadByCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindThreadByCommentQuery, FindThreadByCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindThreadByCommentQuery, FindThreadByCommentQueryVariables>(FindThreadByCommentDocument, options);
        }
export function useFindThreadByCommentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindThreadByCommentQuery, FindThreadByCommentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindThreadByCommentQuery, FindThreadByCommentQueryVariables>(FindThreadByCommentDocument, options);
        }
export type FindThreadByCommentQueryHookResult = ReturnType<typeof useFindThreadByCommentQuery>;
export type FindThreadByCommentLazyQueryHookResult = ReturnType<typeof useFindThreadByCommentLazyQuery>;
export type FindThreadByCommentSuspenseQueryHookResult = ReturnType<typeof useFindThreadByCommentSuspenseQuery>;
export type FindThreadByCommentQueryResult = Apollo.QueryResult<FindThreadByCommentQuery, FindThreadByCommentQueryVariables>;
export const ReadAssociatedThreadsDocument = gql`
    query ReadAssociatedThreads($id: String!, $legalEntityId: String!, $kind: ObjectKind!, $allowResolved: Boolean) {
  response: readAssociatedThreads(
    id: $id
    legalEntityId: $legalEntityId
    kind: $kind
    allowResolved: $allowResolved
  ) {
    threads {
      ...ThreadFields
    }
    entities {
      ...ObjectEntitiesFields
    }
  }
}
    ${ThreadFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;

/**
 * __useReadAssociatedThreadsQuery__
 *
 * To run a query within a React component, call `useReadAssociatedThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAssociatedThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAssociatedThreadsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      legalEntityId: // value for 'legalEntityId'
 *      kind: // value for 'kind'
 *      allowResolved: // value for 'allowResolved'
 *   },
 * });
 */
export function useReadAssociatedThreadsQuery(baseOptions: Apollo.QueryHookOptions<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables> & ({ variables: ReadAssociatedThreadsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>(ReadAssociatedThreadsDocument, options);
      }
export function useReadAssociatedThreadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>(ReadAssociatedThreadsDocument, options);
        }
export function useReadAssociatedThreadsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>(ReadAssociatedThreadsDocument, options);
        }
export type ReadAssociatedThreadsQueryHookResult = ReturnType<typeof useReadAssociatedThreadsQuery>;
export type ReadAssociatedThreadsLazyQueryHookResult = ReturnType<typeof useReadAssociatedThreadsLazyQuery>;
export type ReadAssociatedThreadsSuspenseQueryHookResult = ReturnType<typeof useReadAssociatedThreadsSuspenseQuery>;
export type ReadAssociatedThreadsQueryResult = Apollo.QueryResult<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>;
export const ReadThreadDocument = gql`
    query ReadThread($id: String!, $allowResolved: Boolean) {
  thread: readThread(threadId: $id, allowResolved: $allowResolved) {
    ...ThreadWithEntitiesFields
  }
}
    ${ThreadWithEntitiesFieldsFragmentDoc}`;

/**
 * __useReadThreadQuery__
 *
 * To run a query within a React component, call `useReadThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *      allowResolved: // value for 'allowResolved'
 *   },
 * });
 */
export function useReadThreadQuery(baseOptions: Apollo.QueryHookOptions<ReadThreadQuery, ReadThreadQueryVariables> & ({ variables: ReadThreadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadThreadQuery, ReadThreadQueryVariables>(ReadThreadDocument, options);
      }
export function useReadThreadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadThreadQuery, ReadThreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadThreadQuery, ReadThreadQueryVariables>(ReadThreadDocument, options);
        }
export function useReadThreadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadThreadQuery, ReadThreadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadThreadQuery, ReadThreadQueryVariables>(ReadThreadDocument, options);
        }
export type ReadThreadQueryHookResult = ReturnType<typeof useReadThreadQuery>;
export type ReadThreadLazyQueryHookResult = ReturnType<typeof useReadThreadLazyQuery>;
export type ReadThreadSuspenseQueryHookResult = ReturnType<typeof useReadThreadSuspenseQuery>;
export type ReadThreadQueryResult = Apollo.QueryResult<ReadThreadQuery, ReadThreadQueryVariables>;
export const UpdateActionItemAssignmentStatusDocument = gql`
    mutation UpdateActionItemAssignmentStatus($actionItemId: String!, $legalEntityId: String!, $userAssignees: [UserAssignee!]!, $organizationAssignees: [OrganizationAssignee!]!) {
  updateActionItemAssignmentStatus(
    actionItemId: $actionItemId
    legalEntityId: $legalEntityId
    userAssignees: $userAssignees
    organizationAssignees: $organizationAssignees
  )
}
    `;
export type UpdateActionItemAssignmentStatusMutationFn = Apollo.MutationFunction<UpdateActionItemAssignmentStatusMutation, UpdateActionItemAssignmentStatusMutationVariables>;

/**
 * __useUpdateActionItemAssignmentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateActionItemAssignmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionItemAssignmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionItemAssignmentStatusMutation, { data, loading, error }] = useUpdateActionItemAssignmentStatusMutation({
 *   variables: {
 *      actionItemId: // value for 'actionItemId'
 *      legalEntityId: // value for 'legalEntityId'
 *      userAssignees: // value for 'userAssignees'
 *      organizationAssignees: // value for 'organizationAssignees'
 *   },
 * });
 */
export function useUpdateActionItemAssignmentStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActionItemAssignmentStatusMutation, UpdateActionItemAssignmentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActionItemAssignmentStatusMutation, UpdateActionItemAssignmentStatusMutationVariables>(UpdateActionItemAssignmentStatusDocument, options);
      }
export type UpdateActionItemAssignmentStatusMutationHookResult = ReturnType<typeof useUpdateActionItemAssignmentStatusMutation>;
export type UpdateActionItemAssignmentStatusMutationResult = Apollo.MutationResult<UpdateActionItemAssignmentStatusMutation>;
export type UpdateActionItemAssignmentStatusMutationOptions = Apollo.BaseMutationOptions<UpdateActionItemAssignmentStatusMutation, UpdateActionItemAssignmentStatusMutationVariables>;
export const UpdateActionItemWatchStatusDocument = gql`
    mutation UpdateActionItemWatchStatus($actionItemId: String!, $legalEntityId: String!, $watch: Boolean!) {
  updateActionItemWatchStatus(
    actionItemId: $actionItemId
    legalEntityId: $legalEntityId
    watch: $watch
  )
}
    `;
export type UpdateActionItemWatchStatusMutationFn = Apollo.MutationFunction<UpdateActionItemWatchStatusMutation, UpdateActionItemWatchStatusMutationVariables>;

/**
 * __useUpdateActionItemWatchStatusMutation__
 *
 * To run a mutation, you first call `useUpdateActionItemWatchStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionItemWatchStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionItemWatchStatusMutation, { data, loading, error }] = useUpdateActionItemWatchStatusMutation({
 *   variables: {
 *      actionItemId: // value for 'actionItemId'
 *      legalEntityId: // value for 'legalEntityId'
 *      watch: // value for 'watch'
 *   },
 * });
 */
export function useUpdateActionItemWatchStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActionItemWatchStatusMutation, UpdateActionItemWatchStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActionItemWatchStatusMutation, UpdateActionItemWatchStatusMutationVariables>(UpdateActionItemWatchStatusDocument, options);
      }
export type UpdateActionItemWatchStatusMutationHookResult = ReturnType<typeof useUpdateActionItemWatchStatusMutation>;
export type UpdateActionItemWatchStatusMutationResult = Apollo.MutationResult<UpdateActionItemWatchStatusMutation>;
export type UpdateActionItemWatchStatusMutationOptions = Apollo.BaseMutationOptions<UpdateActionItemWatchStatusMutation, UpdateActionItemWatchStatusMutationVariables>;
export const UpdateActionItemStatusDocument = gql`
    mutation UpdateActionItemStatus($legalEntityId: String!, $actionItems: [ActionItemStatusChange!]!) {
  updateActionItemStatus(legalEntityId: $legalEntityId, actionItems: $actionItems)
}
    `;
export type UpdateActionItemStatusMutationFn = Apollo.MutationFunction<UpdateActionItemStatusMutation, UpdateActionItemStatusMutationVariables>;

/**
 * __useUpdateActionItemStatusMutation__
 *
 * To run a mutation, you first call `useUpdateActionItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionItemStatusMutation, { data, loading, error }] = useUpdateActionItemStatusMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      actionItems: // value for 'actionItems'
 *   },
 * });
 */
export function useUpdateActionItemStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActionItemStatusMutation, UpdateActionItemStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActionItemStatusMutation, UpdateActionItemStatusMutationVariables>(UpdateActionItemStatusDocument, options);
      }
export type UpdateActionItemStatusMutationHookResult = ReturnType<typeof useUpdateActionItemStatusMutation>;
export type UpdateActionItemStatusMutationResult = Apollo.MutationResult<UpdateActionItemStatusMutation>;
export type UpdateActionItemStatusMutationOptions = Apollo.BaseMutationOptions<UpdateActionItemStatusMutation, UpdateActionItemStatusMutationVariables>;
export const ListActionItemsDocument = gql`
    query ListActionItems($filter: ActionItemFilter!, $pagination: Pagination!, $viewKey: ViewKey!, $origin: IntervalOrigin) {
  listActionItems(
    filter: $filter
    pagination: $pagination
    viewKey: $viewKey
    origin: $origin
  ) {
    ...ActionItemFields
  }
}
    ${ActionItemFieldsFragmentDoc}`;

/**
 * __useListActionItemsQuery__
 *
 * To run a query within a React component, call `useListActionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActionItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      viewKey: // value for 'viewKey'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useListActionItemsQuery(baseOptions: Apollo.QueryHookOptions<ListActionItemsQuery, ListActionItemsQueryVariables> & ({ variables: ListActionItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListActionItemsQuery, ListActionItemsQueryVariables>(ListActionItemsDocument, options);
      }
export function useListActionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActionItemsQuery, ListActionItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListActionItemsQuery, ListActionItemsQueryVariables>(ListActionItemsDocument, options);
        }
export function useListActionItemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListActionItemsQuery, ListActionItemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListActionItemsQuery, ListActionItemsQueryVariables>(ListActionItemsDocument, options);
        }
export type ListActionItemsQueryHookResult = ReturnType<typeof useListActionItemsQuery>;
export type ListActionItemsLazyQueryHookResult = ReturnType<typeof useListActionItemsLazyQuery>;
export type ListActionItemsSuspenseQueryHookResult = ReturnType<typeof useListActionItemsSuspenseQuery>;
export type ListActionItemsQueryResult = Apollo.QueryResult<ListActionItemsQuery, ListActionItemsQueryVariables>;
export const SummarizeActionItemsByTimeDocument = gql`
    query SummarizeActionItemsByTime($filter: ActionItemFilter!, $viewKey: ViewKey!, $origin: IntervalOrigin!) {
  summarizeActionItemsByTime(filter: $filter, viewKey: $viewKey, origin: $origin) {
    ...ActionItemSummaryFields
  }
}
    ${ActionItemSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeActionItemsByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeActionItemsByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeActionItemsByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeActionItemsByTimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      viewKey: // value for 'viewKey'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useSummarizeActionItemsByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeActionItemsByTimeQuery, SummarizeActionItemsByTimeQueryVariables> & ({ variables: SummarizeActionItemsByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeActionItemsByTimeQuery, SummarizeActionItemsByTimeQueryVariables>(SummarizeActionItemsByTimeDocument, options);
      }
export function useSummarizeActionItemsByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeActionItemsByTimeQuery, SummarizeActionItemsByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeActionItemsByTimeQuery, SummarizeActionItemsByTimeQueryVariables>(SummarizeActionItemsByTimeDocument, options);
        }
export function useSummarizeActionItemsByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeActionItemsByTimeQuery, SummarizeActionItemsByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeActionItemsByTimeQuery, SummarizeActionItemsByTimeQueryVariables>(SummarizeActionItemsByTimeDocument, options);
        }
export type SummarizeActionItemsByTimeQueryHookResult = ReturnType<typeof useSummarizeActionItemsByTimeQuery>;
export type SummarizeActionItemsByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeActionItemsByTimeLazyQuery>;
export type SummarizeActionItemsByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeActionItemsByTimeSuspenseQuery>;
export type SummarizeActionItemsByTimeQueryResult = Apollo.QueryResult<SummarizeActionItemsByTimeQuery, SummarizeActionItemsByTimeQueryVariables>;
export const ListSimilarActionItemsDocument = gql`
    query ListSimilarActionItems($legalEntityId: String!, $items: [SimilarActionItem!]!) {
  listSimilarActionItems(legalEntityId: $legalEntityId, items: $items)
}
    `;

/**
 * __useListSimilarActionItemsQuery__
 *
 * To run a query within a React component, call `useListSimilarActionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSimilarActionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSimilarActionItemsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useListSimilarActionItemsQuery(baseOptions: Apollo.QueryHookOptions<ListSimilarActionItemsQuery, ListSimilarActionItemsQueryVariables> & ({ variables: ListSimilarActionItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSimilarActionItemsQuery, ListSimilarActionItemsQueryVariables>(ListSimilarActionItemsDocument, options);
      }
export function useListSimilarActionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSimilarActionItemsQuery, ListSimilarActionItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSimilarActionItemsQuery, ListSimilarActionItemsQueryVariables>(ListSimilarActionItemsDocument, options);
        }
export function useListSimilarActionItemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListSimilarActionItemsQuery, ListSimilarActionItemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListSimilarActionItemsQuery, ListSimilarActionItemsQueryVariables>(ListSimilarActionItemsDocument, options);
        }
export type ListSimilarActionItemsQueryHookResult = ReturnType<typeof useListSimilarActionItemsQuery>;
export type ListSimilarActionItemsLazyQueryHookResult = ReturnType<typeof useListSimilarActionItemsLazyQuery>;
export type ListSimilarActionItemsSuspenseQueryHookResult = ReturnType<typeof useListSimilarActionItemsSuspenseQuery>;
export type ListSimilarActionItemsQueryResult = Apollo.QueryResult<ListSimilarActionItemsQuery, ListSimilarActionItemsQueryVariables>;
export const SummarizeActionItemsByObjectKindDocument = gql`
    query SummarizeActionItemsByObjectKind($filter: ActionItemFilter!, $legalEntityId: String!) {
  summarizeActionItemsByObjectKind(filter: $filter, legalEntityId: $legalEntityId) {
    ...ActionItemsObjectKindSummaryFields
  }
}
    ${ActionItemsObjectKindSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeActionItemsByObjectKindQuery__
 *
 * To run a query within a React component, call `useSummarizeActionItemsByObjectKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeActionItemsByObjectKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeActionItemsByObjectKindQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useSummarizeActionItemsByObjectKindQuery(baseOptions: Apollo.QueryHookOptions<SummarizeActionItemsByObjectKindQuery, SummarizeActionItemsByObjectKindQueryVariables> & ({ variables: SummarizeActionItemsByObjectKindQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeActionItemsByObjectKindQuery, SummarizeActionItemsByObjectKindQueryVariables>(SummarizeActionItemsByObjectKindDocument, options);
      }
export function useSummarizeActionItemsByObjectKindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeActionItemsByObjectKindQuery, SummarizeActionItemsByObjectKindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeActionItemsByObjectKindQuery, SummarizeActionItemsByObjectKindQueryVariables>(SummarizeActionItemsByObjectKindDocument, options);
        }
export function useSummarizeActionItemsByObjectKindSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeActionItemsByObjectKindQuery, SummarizeActionItemsByObjectKindQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeActionItemsByObjectKindQuery, SummarizeActionItemsByObjectKindQueryVariables>(SummarizeActionItemsByObjectKindDocument, options);
        }
export type SummarizeActionItemsByObjectKindQueryHookResult = ReturnType<typeof useSummarizeActionItemsByObjectKindQuery>;
export type SummarizeActionItemsByObjectKindLazyQueryHookResult = ReturnType<typeof useSummarizeActionItemsByObjectKindLazyQuery>;
export type SummarizeActionItemsByObjectKindSuspenseQueryHookResult = ReturnType<typeof useSummarizeActionItemsByObjectKindSuspenseQuery>;
export type SummarizeActionItemsByObjectKindQueryResult = Apollo.QueryResult<SummarizeActionItemsByObjectKindQuery, SummarizeActionItemsByObjectKindQueryVariables>;
export const ListActionItemsAndBadgeCountDocument = gql`
    query ListActionItemsAndBadgeCount($listFilter: ActionItemFilter!, $pagination: Pagination!, $viewKey: ViewKey!, $direction: DirectionFromOrigin, $badgeCountFilter: ActionItemFilter!, $legalEntityIds: [String!]!, $legalEntities: [LegalEntityPermission], $origin: IntervalOrigin!) {
  listActionItems(filter: $listFilter, pagination: $pagination, viewKey: $viewKey) {
    ...ActionItemFields
  }
  listActionItemsBadgeCounts(
    direction: $direction
    origin: $origin
    legalEntityIds: $legalEntityIds
    legalEntities: $legalEntities
    filter: $badgeCountFilter
  ) {
    ...LegalEntityBadgeCountFields
  }
}
    ${ActionItemFieldsFragmentDoc}
${LegalEntityBadgeCountFieldsFragmentDoc}`;

/**
 * __useListActionItemsAndBadgeCountQuery__
 *
 * To run a query within a React component, call `useListActionItemsAndBadgeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActionItemsAndBadgeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActionItemsAndBadgeCountQuery({
 *   variables: {
 *      listFilter: // value for 'listFilter'
 *      pagination: // value for 'pagination'
 *      viewKey: // value for 'viewKey'
 *      direction: // value for 'direction'
 *      badgeCountFilter: // value for 'badgeCountFilter'
 *      legalEntityIds: // value for 'legalEntityIds'
 *      legalEntities: // value for 'legalEntities'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useListActionItemsAndBadgeCountQuery(baseOptions: Apollo.QueryHookOptions<ListActionItemsAndBadgeCountQuery, ListActionItemsAndBadgeCountQueryVariables> & ({ variables: ListActionItemsAndBadgeCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListActionItemsAndBadgeCountQuery, ListActionItemsAndBadgeCountQueryVariables>(ListActionItemsAndBadgeCountDocument, options);
      }
export function useListActionItemsAndBadgeCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActionItemsAndBadgeCountQuery, ListActionItemsAndBadgeCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListActionItemsAndBadgeCountQuery, ListActionItemsAndBadgeCountQueryVariables>(ListActionItemsAndBadgeCountDocument, options);
        }
export function useListActionItemsAndBadgeCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListActionItemsAndBadgeCountQuery, ListActionItemsAndBadgeCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListActionItemsAndBadgeCountQuery, ListActionItemsAndBadgeCountQueryVariables>(ListActionItemsAndBadgeCountDocument, options);
        }
export type ListActionItemsAndBadgeCountQueryHookResult = ReturnType<typeof useListActionItemsAndBadgeCountQuery>;
export type ListActionItemsAndBadgeCountLazyQueryHookResult = ReturnType<typeof useListActionItemsAndBadgeCountLazyQuery>;
export type ListActionItemsAndBadgeCountSuspenseQueryHookResult = ReturnType<typeof useListActionItemsAndBadgeCountSuspenseQuery>;
export type ListActionItemsAndBadgeCountQueryResult = Apollo.QueryResult<ListActionItemsAndBadgeCountQuery, ListActionItemsAndBadgeCountQueryVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($documentId: String!, $documentCollectionId: String!, $legalEntityId: String!) {
  deleteDocument(
    documentId: $documentId
    documentCollectionId: $documentCollectionId
    legalEntityId: $legalEntityId
  )
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      documentCollectionId: // value for 'documentCollectionId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const UpdatePortalDocument = gql`
    mutation UpdatePortal($legalEntityId: String!, $portalId: String!, $backgroundUrl: String) {
  updatePortal(
    legalEntityId: $legalEntityId
    portalId: $portalId
    backgroundUrl: $backgroundUrl
  )
}
    `;
export type UpdatePortalMutationFn = Apollo.MutationFunction<UpdatePortalMutation, UpdatePortalMutationVariables>;

/**
 * __useUpdatePortalMutation__
 *
 * To run a mutation, you first call `useUpdatePortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortalMutation, { data, loading, error }] = useUpdatePortalMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      portalId: // value for 'portalId'
 *      backgroundUrl: // value for 'backgroundUrl'
 *   },
 * });
 */
export function useUpdatePortalMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePortalMutation, UpdatePortalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePortalMutation, UpdatePortalMutationVariables>(UpdatePortalDocument, options);
      }
export type UpdatePortalMutationHookResult = ReturnType<typeof useUpdatePortalMutation>;
export type UpdatePortalMutationResult = Apollo.MutationResult<UpdatePortalMutation>;
export type UpdatePortalMutationOptions = Apollo.BaseMutationOptions<UpdatePortalMutation, UpdatePortalMutationVariables>;
export const CreatePortalDocument = gql`
    mutation CreatePortal($viewId: ViewIdentifierInput!) {
  createPortal(viewId: $viewId) {
    portalId
  }
}
    `;
export type CreatePortalMutationFn = Apollo.MutationFunction<CreatePortalMutation, CreatePortalMutationVariables>;

/**
 * __useCreatePortalMutation__
 *
 * To run a mutation, you first call `useCreatePortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortalMutation, { data, loading, error }] = useCreatePortalMutation({
 *   variables: {
 *      viewId: // value for 'viewId'
 *   },
 * });
 */
export function useCreatePortalMutation(baseOptions?: Apollo.MutationHookOptions<CreatePortalMutation, CreatePortalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePortalMutation, CreatePortalMutationVariables>(CreatePortalDocument, options);
      }
export type CreatePortalMutationHookResult = ReturnType<typeof useCreatePortalMutation>;
export type CreatePortalMutationResult = Apollo.MutationResult<CreatePortalMutation>;
export type CreatePortalMutationOptions = Apollo.BaseMutationOptions<CreatePortalMutation, CreatePortalMutationVariables>;
export const ReadPortalLayoutDocument = gql`
    query ReadPortalLayout($legalEntityId: String!) {
  readPortalLayout(legalEntityId: $legalEntityId) {
    hasPortal
    portalId
    layout {
      ...LayoutFields
    }
  }
}
    ${LayoutFieldsFragmentDoc}`;

/**
 * __useReadPortalLayoutQuery__
 *
 * To run a query within a React component, call `useReadPortalLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPortalLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPortalLayoutQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadPortalLayoutQuery(baseOptions: Apollo.QueryHookOptions<ReadPortalLayoutQuery, ReadPortalLayoutQueryVariables> & ({ variables: ReadPortalLayoutQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPortalLayoutQuery, ReadPortalLayoutQueryVariables>(ReadPortalLayoutDocument, options);
      }
export function useReadPortalLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPortalLayoutQuery, ReadPortalLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPortalLayoutQuery, ReadPortalLayoutQueryVariables>(ReadPortalLayoutDocument, options);
        }
export function useReadPortalLayoutSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadPortalLayoutQuery, ReadPortalLayoutQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadPortalLayoutQuery, ReadPortalLayoutQueryVariables>(ReadPortalLayoutDocument, options);
        }
export type ReadPortalLayoutQueryHookResult = ReturnType<typeof useReadPortalLayoutQuery>;
export type ReadPortalLayoutLazyQueryHookResult = ReturnType<typeof useReadPortalLayoutLazyQuery>;
export type ReadPortalLayoutSuspenseQueryHookResult = ReturnType<typeof useReadPortalLayoutSuspenseQuery>;
export type ReadPortalLayoutQueryResult = Apollo.QueryResult<ReadPortalLayoutQuery, ReadPortalLayoutQueryVariables>;
export const ReadVanityStatsDocument = gql`
    query ReadVanityStats($legalEntityId: String!) {
  readVanityStats(legalEntityId: $legalEntityId) {
    ...VanityStatsFields
  }
}
    ${VanityStatsFieldsFragmentDoc}`;

/**
 * __useReadVanityStatsQuery__
 *
 * To run a query within a React component, call `useReadVanityStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadVanityStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadVanityStatsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadVanityStatsQuery(baseOptions: Apollo.QueryHookOptions<ReadVanityStatsQuery, ReadVanityStatsQueryVariables> & ({ variables: ReadVanityStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadVanityStatsQuery, ReadVanityStatsQueryVariables>(ReadVanityStatsDocument, options);
      }
export function useReadVanityStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadVanityStatsQuery, ReadVanityStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadVanityStatsQuery, ReadVanityStatsQueryVariables>(ReadVanityStatsDocument, options);
        }
export function useReadVanityStatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadVanityStatsQuery, ReadVanityStatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadVanityStatsQuery, ReadVanityStatsQueryVariables>(ReadVanityStatsDocument, options);
        }
export type ReadVanityStatsQueryHookResult = ReturnType<typeof useReadVanityStatsQuery>;
export type ReadVanityStatsLazyQueryHookResult = ReturnType<typeof useReadVanityStatsLazyQuery>;
export type ReadVanityStatsSuspenseQueryHookResult = ReturnType<typeof useReadVanityStatsSuspenseQuery>;
export type ReadVanityStatsQueryResult = Apollo.QueryResult<ReadVanityStatsQuery, ReadVanityStatsQueryVariables>;
export const UpdateTransactionReconciliationStatusesDocument = gql`
    mutation UpdateTransactionReconciliationStatuses($viewKey: ViewKey!, $statusUpdates: [InputStatusUpdate!]!) {
  updateTransactionReconciliationStatuses(
    viewKey: $viewKey
    statusUpdates: $statusUpdates
  )
}
    `;
export type UpdateTransactionReconciliationStatusesMutationFn = Apollo.MutationFunction<UpdateTransactionReconciliationStatusesMutation, UpdateTransactionReconciliationStatusesMutationVariables>;

/**
 * __useUpdateTransactionReconciliationStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionReconciliationStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionReconciliationStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionReconciliationStatusesMutation, { data, loading, error }] = useUpdateTransactionReconciliationStatusesMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      statusUpdates: // value for 'statusUpdates'
 *   },
 * });
 */
export function useUpdateTransactionReconciliationStatusesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionReconciliationStatusesMutation, UpdateTransactionReconciliationStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionReconciliationStatusesMutation, UpdateTransactionReconciliationStatusesMutationVariables>(UpdateTransactionReconciliationStatusesDocument, options);
      }
export type UpdateTransactionReconciliationStatusesMutationHookResult = ReturnType<typeof useUpdateTransactionReconciliationStatusesMutation>;
export type UpdateTransactionReconciliationStatusesMutationResult = Apollo.MutationResult<UpdateTransactionReconciliationStatusesMutation>;
export type UpdateTransactionReconciliationStatusesMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionReconciliationStatusesMutation, UpdateTransactionReconciliationStatusesMutationVariables>;
export const CreateAccountReconciliationDocument = gql`
    mutation CreateAccountReconciliation($legalEntityId: String!, $viewKey: ViewKey!, $reconciliation: InputAccountReconciliation!) {
  createAccountReconciliation(
    legalEntityId: $legalEntityId
    viewKey: $viewKey
    reconciliation: $reconciliation
  )
}
    `;
export type CreateAccountReconciliationMutationFn = Apollo.MutationFunction<CreateAccountReconciliationMutation, CreateAccountReconciliationMutationVariables>;

/**
 * __useCreateAccountReconciliationMutation__
 *
 * To run a mutation, you first call `useCreateAccountReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountReconciliationMutation, { data, loading, error }] = useCreateAccountReconciliationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      viewKey: // value for 'viewKey'
 *      reconciliation: // value for 'reconciliation'
 *   },
 * });
 */
export function useCreateAccountReconciliationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountReconciliationMutation, CreateAccountReconciliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountReconciliationMutation, CreateAccountReconciliationMutationVariables>(CreateAccountReconciliationDocument, options);
      }
export type CreateAccountReconciliationMutationHookResult = ReturnType<typeof useCreateAccountReconciliationMutation>;
export type CreateAccountReconciliationMutationResult = Apollo.MutationResult<CreateAccountReconciliationMutation>;
export type CreateAccountReconciliationMutationOptions = Apollo.BaseMutationOptions<CreateAccountReconciliationMutation, CreateAccountReconciliationMutationVariables>;
export const FinalizeAccountReconciliationDocument = gql`
    mutation FinalizeAccountReconciliation($legalEntityId: String!, $reconciliationId: String!, $viewKey: ViewKey!) {
  finalizeAccountReconciliation(
    legalEntityId: $legalEntityId
    reconciliationId: $reconciliationId
    viewKey: $viewKey
  )
}
    `;
export type FinalizeAccountReconciliationMutationFn = Apollo.MutationFunction<FinalizeAccountReconciliationMutation, FinalizeAccountReconciliationMutationVariables>;

/**
 * __useFinalizeAccountReconciliationMutation__
 *
 * To run a mutation, you first call `useFinalizeAccountReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeAccountReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeAccountReconciliationMutation, { data, loading, error }] = useFinalizeAccountReconciliationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reconciliationId: // value for 'reconciliationId'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useFinalizeAccountReconciliationMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeAccountReconciliationMutation, FinalizeAccountReconciliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeAccountReconciliationMutation, FinalizeAccountReconciliationMutationVariables>(FinalizeAccountReconciliationDocument, options);
      }
export type FinalizeAccountReconciliationMutationHookResult = ReturnType<typeof useFinalizeAccountReconciliationMutation>;
export type FinalizeAccountReconciliationMutationResult = Apollo.MutationResult<FinalizeAccountReconciliationMutation>;
export type FinalizeAccountReconciliationMutationOptions = Apollo.BaseMutationOptions<FinalizeAccountReconciliationMutation, FinalizeAccountReconciliationMutationVariables>;
export const ReopenAccountReconciliationDocument = gql`
    mutation ReopenAccountReconciliation($legalEntityId: String!, $reconciliationId: String!) {
  reopenAccountReconciliation(
    legalEntityId: $legalEntityId
    reconciliationId: $reconciliationId
  )
}
    `;
export type ReopenAccountReconciliationMutationFn = Apollo.MutationFunction<ReopenAccountReconciliationMutation, ReopenAccountReconciliationMutationVariables>;

/**
 * __useReopenAccountReconciliationMutation__
 *
 * To run a mutation, you first call `useReopenAccountReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenAccountReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenAccountReconciliationMutation, { data, loading, error }] = useReopenAccountReconciliationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reconciliationId: // value for 'reconciliationId'
 *   },
 * });
 */
export function useReopenAccountReconciliationMutation(baseOptions?: Apollo.MutationHookOptions<ReopenAccountReconciliationMutation, ReopenAccountReconciliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReopenAccountReconciliationMutation, ReopenAccountReconciliationMutationVariables>(ReopenAccountReconciliationDocument, options);
      }
export type ReopenAccountReconciliationMutationHookResult = ReturnType<typeof useReopenAccountReconciliationMutation>;
export type ReopenAccountReconciliationMutationResult = Apollo.MutationResult<ReopenAccountReconciliationMutation>;
export type ReopenAccountReconciliationMutationOptions = Apollo.BaseMutationOptions<ReopenAccountReconciliationMutation, ReopenAccountReconciliationMutationVariables>;
export const DeleteAccountReconciliationDocument = gql`
    mutation DeleteAccountReconciliation($legalEntityId: String!, $reconciliationId: String!) {
  deleteAccountReconciliation(
    legalEntityId: $legalEntityId
    reconciliationId: $reconciliationId
  )
}
    `;
export type DeleteAccountReconciliationMutationFn = Apollo.MutationFunction<DeleteAccountReconciliationMutation, DeleteAccountReconciliationMutationVariables>;

/**
 * __useDeleteAccountReconciliationMutation__
 *
 * To run a mutation, you first call `useDeleteAccountReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountReconciliationMutation, { data, loading, error }] = useDeleteAccountReconciliationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reconciliationId: // value for 'reconciliationId'
 *   },
 * });
 */
export function useDeleteAccountReconciliationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountReconciliationMutation, DeleteAccountReconciliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountReconciliationMutation, DeleteAccountReconciliationMutationVariables>(DeleteAccountReconciliationDocument, options);
      }
export type DeleteAccountReconciliationMutationHookResult = ReturnType<typeof useDeleteAccountReconciliationMutation>;
export type DeleteAccountReconciliationMutationResult = Apollo.MutationResult<DeleteAccountReconciliationMutation>;
export type DeleteAccountReconciliationMutationOptions = Apollo.BaseMutationOptions<DeleteAccountReconciliationMutation, DeleteAccountReconciliationMutationVariables>;
export const UpdateAccountReconciliationDocument = gql`
    mutation UpdateAccountReconciliation($legalEntityId: String!, $reconciliationId: String!, $viewKey: ViewKey!, $update: InputAccountReconciliationUpdate!) {
  updateAccountReconciliation(
    legalEntityId: $legalEntityId
    reconciliationId: $reconciliationId
    viewKey: $viewKey
    update: $update
  )
}
    `;
export type UpdateAccountReconciliationMutationFn = Apollo.MutationFunction<UpdateAccountReconciliationMutation, UpdateAccountReconciliationMutationVariables>;

/**
 * __useUpdateAccountReconciliationMutation__
 *
 * To run a mutation, you first call `useUpdateAccountReconciliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountReconciliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountReconciliationMutation, { data, loading, error }] = useUpdateAccountReconciliationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reconciliationId: // value for 'reconciliationId'
 *      viewKey: // value for 'viewKey'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateAccountReconciliationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountReconciliationMutation, UpdateAccountReconciliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountReconciliationMutation, UpdateAccountReconciliationMutationVariables>(UpdateAccountReconciliationDocument, options);
      }
export type UpdateAccountReconciliationMutationHookResult = ReturnType<typeof useUpdateAccountReconciliationMutation>;
export type UpdateAccountReconciliationMutationResult = Apollo.MutationResult<UpdateAccountReconciliationMutation>;
export type UpdateAccountReconciliationMutationOptions = Apollo.BaseMutationOptions<UpdateAccountReconciliationMutation, UpdateAccountReconciliationMutationVariables>;
export const ListAccountReconciliationsDocument = gql`
    query ListAccountReconciliations($legalEntityId: String!, $viewKey: ViewKey!, $origin: IntervalOrigin!, $direction: DirectionFromOrigin!, $filter: InputAccountReconciliationsFilter) {
  listAccountReconciliations(
    legalEntityId: $legalEntityId
    viewKey: $viewKey
    origin: $origin
    direction: $direction
    filter: $filter
  ) {
    account {
      ...EntityCategoryFields
    }
    reconciliations {
      ...AccountReconciliationFields
    }
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${AccountReconciliationFieldsFragmentDoc}`;

/**
 * __useListAccountReconciliationsQuery__
 *
 * To run a query within a React component, call `useListAccountReconciliationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountReconciliationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountReconciliationsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      viewKey: // value for 'viewKey'
 *      origin: // value for 'origin'
 *      direction: // value for 'direction'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAccountReconciliationsQuery(baseOptions: Apollo.QueryHookOptions<ListAccountReconciliationsQuery, ListAccountReconciliationsQueryVariables> & ({ variables: ListAccountReconciliationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAccountReconciliationsQuery, ListAccountReconciliationsQueryVariables>(ListAccountReconciliationsDocument, options);
      }
export function useListAccountReconciliationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAccountReconciliationsQuery, ListAccountReconciliationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAccountReconciliationsQuery, ListAccountReconciliationsQueryVariables>(ListAccountReconciliationsDocument, options);
        }
export function useListAccountReconciliationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAccountReconciliationsQuery, ListAccountReconciliationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAccountReconciliationsQuery, ListAccountReconciliationsQueryVariables>(ListAccountReconciliationsDocument, options);
        }
export type ListAccountReconciliationsQueryHookResult = ReturnType<typeof useListAccountReconciliationsQuery>;
export type ListAccountReconciliationsLazyQueryHookResult = ReturnType<typeof useListAccountReconciliationsLazyQuery>;
export type ListAccountReconciliationsSuspenseQueryHookResult = ReturnType<typeof useListAccountReconciliationsSuspenseQuery>;
export type ListAccountReconciliationsQueryResult = Apollo.QueryResult<ListAccountReconciliationsQuery, ListAccountReconciliationsQueryVariables>;
export const ReadReconciliationPeriodStatsDocument = gql`
    query ReadReconciliationPeriodStats($legalEntityId: String!, $viewKey: ViewKey!, $origin: IntervalOrigin!, $direction: DirectionFromOrigin!) {
  readReconciliationPeriodStats(
    legalEntityId: $legalEntityId
    viewKey: $viewKey
    origin: $origin
    direction: $direction
  ) {
    ...ReconciliationPeriodStatsFields
  }
}
    ${ReconciliationPeriodStatsFieldsFragmentDoc}`;

/**
 * __useReadReconciliationPeriodStatsQuery__
 *
 * To run a query within a React component, call `useReadReconciliationPeriodStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReconciliationPeriodStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReconciliationPeriodStatsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      viewKey: // value for 'viewKey'
 *      origin: // value for 'origin'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useReadReconciliationPeriodStatsQuery(baseOptions: Apollo.QueryHookOptions<ReadReconciliationPeriodStatsQuery, ReadReconciliationPeriodStatsQueryVariables> & ({ variables: ReadReconciliationPeriodStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReconciliationPeriodStatsQuery, ReadReconciliationPeriodStatsQueryVariables>(ReadReconciliationPeriodStatsDocument, options);
      }
export function useReadReconciliationPeriodStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReconciliationPeriodStatsQuery, ReadReconciliationPeriodStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReconciliationPeriodStatsQuery, ReadReconciliationPeriodStatsQueryVariables>(ReadReconciliationPeriodStatsDocument, options);
        }
export function useReadReconciliationPeriodStatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadReconciliationPeriodStatsQuery, ReadReconciliationPeriodStatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadReconciliationPeriodStatsQuery, ReadReconciliationPeriodStatsQueryVariables>(ReadReconciliationPeriodStatsDocument, options);
        }
export type ReadReconciliationPeriodStatsQueryHookResult = ReturnType<typeof useReadReconciliationPeriodStatsQuery>;
export type ReadReconciliationPeriodStatsLazyQueryHookResult = ReturnType<typeof useReadReconciliationPeriodStatsLazyQuery>;
export type ReadReconciliationPeriodStatsSuspenseQueryHookResult = ReturnType<typeof useReadReconciliationPeriodStatsSuspenseQuery>;
export type ReadReconciliationPeriodStatsQueryResult = Apollo.QueryResult<ReadReconciliationPeriodStatsQuery, ReadReconciliationPeriodStatsQueryVariables>;
export const ReadAccountReconciliationDocument = gql`
    query ReadAccountReconciliation($legalEntityId: String!, $reconciliationId: String!, $viewKey: ViewKey!) {
  readAccountReconciliation(
    legalEntityId: $legalEntityId
    reconciliationId: $reconciliationId
    viewKey: $viewKey
  ) {
    reconciliation {
      ...AccountReconciliationFields
    }
    balances {
      ...ReconciliationBalancesFields
    }
    normalEntryType
    transactionCounts {
      ...TransactionCountsFields
    }
  }
}
    ${AccountReconciliationFieldsFragmentDoc}
${ReconciliationBalancesFieldsFragmentDoc}
${TransactionCountsFieldsFragmentDoc}`;

/**
 * __useReadAccountReconciliationQuery__
 *
 * To run a query within a React component, call `useReadAccountReconciliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAccountReconciliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAccountReconciliationQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reconciliationId: // value for 'reconciliationId'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useReadAccountReconciliationQuery(baseOptions: Apollo.QueryHookOptions<ReadAccountReconciliationQuery, ReadAccountReconciliationQueryVariables> & ({ variables: ReadAccountReconciliationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAccountReconciliationQuery, ReadAccountReconciliationQueryVariables>(ReadAccountReconciliationDocument, options);
      }
export function useReadAccountReconciliationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAccountReconciliationQuery, ReadAccountReconciliationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAccountReconciliationQuery, ReadAccountReconciliationQueryVariables>(ReadAccountReconciliationDocument, options);
        }
export function useReadAccountReconciliationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAccountReconciliationQuery, ReadAccountReconciliationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAccountReconciliationQuery, ReadAccountReconciliationQueryVariables>(ReadAccountReconciliationDocument, options);
        }
export type ReadAccountReconciliationQueryHookResult = ReturnType<typeof useReadAccountReconciliationQuery>;
export type ReadAccountReconciliationLazyQueryHookResult = ReturnType<typeof useReadAccountReconciliationLazyQuery>;
export type ReadAccountReconciliationSuspenseQueryHookResult = ReturnType<typeof useReadAccountReconciliationSuspenseQuery>;
export type ReadAccountReconciliationQueryResult = Apollo.QueryResult<ReadAccountReconciliationQuery, ReadAccountReconciliationQueryVariables>;
export const UpdateCompanyFinancialsDocument = gql`
    mutation UpdateCompanyFinancials($legalEntityId: String!, $id: String!, $financials: [CompanyFinancialsInput!]) {
  updateCompanyFinancials(
    legalEntityId: $legalEntityId
    id: $id
    financials: $financials
  )
}
    `;
export type UpdateCompanyFinancialsMutationFn = Apollo.MutationFunction<UpdateCompanyFinancialsMutation, UpdateCompanyFinancialsMutationVariables>;

/**
 * __useUpdateCompanyFinancialsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyFinancialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyFinancialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyFinancialsMutation, { data, loading, error }] = useUpdateCompanyFinancialsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      id: // value for 'id'
 *      financials: // value for 'financials'
 *   },
 * });
 */
export function useUpdateCompanyFinancialsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyFinancialsMutation, UpdateCompanyFinancialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyFinancialsMutation, UpdateCompanyFinancialsMutationVariables>(UpdateCompanyFinancialsDocument, options);
      }
export type UpdateCompanyFinancialsMutationHookResult = ReturnType<typeof useUpdateCompanyFinancialsMutation>;
export type UpdateCompanyFinancialsMutationResult = Apollo.MutationResult<UpdateCompanyFinancialsMutation>;
export type UpdateCompanyFinancialsMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyFinancialsMutation, UpdateCompanyFinancialsMutationVariables>;
export const SendQuotePackageDocument = gql`
    mutation SendQuotePackage($legalEntityId: String!, $id: String!) {
  sendQuotePackage(legalEntityId: $legalEntityId, id: $id)
}
    `;
export type SendQuotePackageMutationFn = Apollo.MutationFunction<SendQuotePackageMutation, SendQuotePackageMutationVariables>;

/**
 * __useSendQuotePackageMutation__
 *
 * To run a mutation, you first call `useSendQuotePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendQuotePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendQuotePackageMutation, { data, loading, error }] = useSendQuotePackageMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendQuotePackageMutation(baseOptions?: Apollo.MutationHookOptions<SendQuotePackageMutation, SendQuotePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendQuotePackageMutation, SendQuotePackageMutationVariables>(SendQuotePackageDocument, options);
      }
export type SendQuotePackageMutationHookResult = ReturnType<typeof useSendQuotePackageMutation>;
export type SendQuotePackageMutationResult = Apollo.MutationResult<SendQuotePackageMutation>;
export type SendQuotePackageMutationOptions = Apollo.BaseMutationOptions<SendQuotePackageMutation, SendQuotePackageMutationVariables>;
export const CreateQuotePackageDocument = gql`
    mutation CreateQuotePackage($legalEntityId: String!, $recipient: ContactInput, $companyDetails: CompanyDetailsInput!) {
  createQuotePackage(
    legalEntityId: $legalEntityId
    recipient: $recipient
    companyDetails: $companyDetails
  ) {
    ...QuotePackageIdFields
  }
}
    ${QuotePackageIdFieldsFragmentDoc}`;
export type CreateQuotePackageMutationFn = Apollo.MutationFunction<CreateQuotePackageMutation, CreateQuotePackageMutationVariables>;

/**
 * __useCreateQuotePackageMutation__
 *
 * To run a mutation, you first call `useCreateQuotePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuotePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuotePackageMutation, { data, loading, error }] = useCreateQuotePackageMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      recipient: // value for 'recipient'
 *      companyDetails: // value for 'companyDetails'
 *   },
 * });
 */
export function useCreateQuotePackageMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuotePackageMutation, CreateQuotePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuotePackageMutation, CreateQuotePackageMutationVariables>(CreateQuotePackageDocument, options);
      }
export type CreateQuotePackageMutationHookResult = ReturnType<typeof useCreateQuotePackageMutation>;
export type CreateQuotePackageMutationResult = Apollo.MutationResult<CreateQuotePackageMutation>;
export type CreateQuotePackageMutationOptions = Apollo.BaseMutationOptions<CreateQuotePackageMutation, CreateQuotePackageMutationVariables>;
export const UpdateQuotePackageDocument = gql`
    mutation UpdateQuotePackage($legalEntityId: String!, $id: String!, $recipient: ContactInput, $contractDocumentId: String, $companyDetails: CompanyDetailsInput!) {
  updateQuotePackage(
    legalEntityId: $legalEntityId
    id: $id
    recipient: $recipient
    contractDocumentId: $contractDocumentId
    companyDetails: $companyDetails
  )
}
    `;
export type UpdateQuotePackageMutationFn = Apollo.MutationFunction<UpdateQuotePackageMutation, UpdateQuotePackageMutationVariables>;

/**
 * __useUpdateQuotePackageMutation__
 *
 * To run a mutation, you first call `useUpdateQuotePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuotePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuotePackageMutation, { data, loading, error }] = useUpdateQuotePackageMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      id: // value for 'id'
 *      recipient: // value for 'recipient'
 *      contractDocumentId: // value for 'contractDocumentId'
 *      companyDetails: // value for 'companyDetails'
 *   },
 * });
 */
export function useUpdateQuotePackageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuotePackageMutation, UpdateQuotePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuotePackageMutation, UpdateQuotePackageMutationVariables>(UpdateQuotePackageDocument, options);
      }
export type UpdateQuotePackageMutationHookResult = ReturnType<typeof useUpdateQuotePackageMutation>;
export type UpdateQuotePackageMutationResult = Apollo.MutationResult<UpdateQuotePackageMutation>;
export type UpdateQuotePackageMutationOptions = Apollo.BaseMutationOptions<UpdateQuotePackageMutation, UpdateQuotePackageMutationVariables>;
export const ListQuotePackagesByLegalEntityDocument = gql`
    query ListQuotePackagesByLegalEntity($legalEntityId: String!) {
  listQuotePackagesByLegalEntity(legalEntityId: $legalEntityId) {
    ...QuotePackageFields
  }
}
    ${QuotePackageFieldsFragmentDoc}`;

/**
 * __useListQuotePackagesByLegalEntityQuery__
 *
 * To run a query within a React component, call `useListQuotePackagesByLegalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuotePackagesByLegalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuotePackagesByLegalEntityQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListQuotePackagesByLegalEntityQuery(baseOptions: Apollo.QueryHookOptions<ListQuotePackagesByLegalEntityQuery, ListQuotePackagesByLegalEntityQueryVariables> & ({ variables: ListQuotePackagesByLegalEntityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListQuotePackagesByLegalEntityQuery, ListQuotePackagesByLegalEntityQueryVariables>(ListQuotePackagesByLegalEntityDocument, options);
      }
export function useListQuotePackagesByLegalEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListQuotePackagesByLegalEntityQuery, ListQuotePackagesByLegalEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListQuotePackagesByLegalEntityQuery, ListQuotePackagesByLegalEntityQueryVariables>(ListQuotePackagesByLegalEntityDocument, options);
        }
export function useListQuotePackagesByLegalEntitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListQuotePackagesByLegalEntityQuery, ListQuotePackagesByLegalEntityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListQuotePackagesByLegalEntityQuery, ListQuotePackagesByLegalEntityQueryVariables>(ListQuotePackagesByLegalEntityDocument, options);
        }
export type ListQuotePackagesByLegalEntityQueryHookResult = ReturnType<typeof useListQuotePackagesByLegalEntityQuery>;
export type ListQuotePackagesByLegalEntityLazyQueryHookResult = ReturnType<typeof useListQuotePackagesByLegalEntityLazyQuery>;
export type ListQuotePackagesByLegalEntitySuspenseQueryHookResult = ReturnType<typeof useListQuotePackagesByLegalEntitySuspenseQuery>;
export type ListQuotePackagesByLegalEntityQueryResult = Apollo.QueryResult<ListQuotePackagesByLegalEntityQuery, ListQuotePackagesByLegalEntityQueryVariables>;
export const ReadQuotePackageDocument = gql`
    query ReadQuotePackage($legalEntityId: String!, $id: String!) {
  readQuotePackage(legalEntityId: $legalEntityId, id: $id) {
    ...QuotePackageFields
  }
}
    ${QuotePackageFieldsFragmentDoc}`;

/**
 * __useReadQuotePackageQuery__
 *
 * To run a query within a React component, call `useReadQuotePackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadQuotePackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadQuotePackageQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadQuotePackageQuery(baseOptions: Apollo.QueryHookOptions<ReadQuotePackageQuery, ReadQuotePackageQueryVariables> & ({ variables: ReadQuotePackageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadQuotePackageQuery, ReadQuotePackageQueryVariables>(ReadQuotePackageDocument, options);
      }
export function useReadQuotePackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadQuotePackageQuery, ReadQuotePackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadQuotePackageQuery, ReadQuotePackageQueryVariables>(ReadQuotePackageDocument, options);
        }
export function useReadQuotePackageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadQuotePackageQuery, ReadQuotePackageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadQuotePackageQuery, ReadQuotePackageQueryVariables>(ReadQuotePackageDocument, options);
        }
export type ReadQuotePackageQueryHookResult = ReturnType<typeof useReadQuotePackageQuery>;
export type ReadQuotePackageLazyQueryHookResult = ReturnType<typeof useReadQuotePackageLazyQuery>;
export type ReadQuotePackageSuspenseQueryHookResult = ReturnType<typeof useReadQuotePackageSuspenseQuery>;
export type ReadQuotePackageQueryResult = Apollo.QueryResult<ReadQuotePackageQuery, ReadQuotePackageQueryVariables>;
export const RequestObjectAccessDocument = gql`
    mutation RequestObjectAccess($object: InputObjectIdentifier!, $intentId: String, $constraint: AccessConstraint) {
  requestObjectAccess(
    object: $object
    intentId: $intentId
    constraint: $constraint
  )
}
    `;
export type RequestObjectAccessMutationFn = Apollo.MutationFunction<RequestObjectAccessMutation, RequestObjectAccessMutationVariables>;

/**
 * __useRequestObjectAccessMutation__
 *
 * To run a mutation, you first call `useRequestObjectAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestObjectAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestObjectAccessMutation, { data, loading, error }] = useRequestObjectAccessMutation({
 *   variables: {
 *      object: // value for 'object'
 *      intentId: // value for 'intentId'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useRequestObjectAccessMutation(baseOptions?: Apollo.MutationHookOptions<RequestObjectAccessMutation, RequestObjectAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestObjectAccessMutation, RequestObjectAccessMutationVariables>(RequestObjectAccessDocument, options);
      }
export type RequestObjectAccessMutationHookResult = ReturnType<typeof useRequestObjectAccessMutation>;
export type RequestObjectAccessMutationResult = Apollo.MutationResult<RequestObjectAccessMutation>;
export type RequestObjectAccessMutationOptions = Apollo.BaseMutationOptions<RequestObjectAccessMutation, RequestObjectAccessMutationVariables>;
export const UpdateObjectLinkDocument = gql`
    mutation UpdateObjectLink($identifier: InputObjectIdentifier!, $permission: Permission!) {
  updateObjectLink(object: $identifier, permission: $permission)
}
    `;
export type UpdateObjectLinkMutationFn = Apollo.MutationFunction<UpdateObjectLinkMutation, UpdateObjectLinkMutationVariables>;

/**
 * __useUpdateObjectLinkMutation__
 *
 * To run a mutation, you first call `useUpdateObjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectLinkMutation, { data, loading, error }] = useUpdateObjectLinkMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useUpdateObjectLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateObjectLinkMutation, UpdateObjectLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateObjectLinkMutation, UpdateObjectLinkMutationVariables>(UpdateObjectLinkDocument, options);
      }
export type UpdateObjectLinkMutationHookResult = ReturnType<typeof useUpdateObjectLinkMutation>;
export type UpdateObjectLinkMutationResult = Apollo.MutationResult<UpdateObjectLinkMutation>;
export type UpdateObjectLinkMutationOptions = Apollo.BaseMutationOptions<UpdateObjectLinkMutation, UpdateObjectLinkMutationVariables>;
export const DisableObjectLinkDocument = gql`
    mutation DisableObjectLink($identifier: InputObjectIdentifier!) {
  disableObjectLink(object: $identifier)
}
    `;
export type DisableObjectLinkMutationFn = Apollo.MutationFunction<DisableObjectLinkMutation, DisableObjectLinkMutationVariables>;

/**
 * __useDisableObjectLinkMutation__
 *
 * To run a mutation, you first call `useDisableObjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableObjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableObjectLinkMutation, { data, loading, error }] = useDisableObjectLinkMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useDisableObjectLinkMutation(baseOptions?: Apollo.MutationHookOptions<DisableObjectLinkMutation, DisableObjectLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableObjectLinkMutation, DisableObjectLinkMutationVariables>(DisableObjectLinkDocument, options);
      }
export type DisableObjectLinkMutationHookResult = ReturnType<typeof useDisableObjectLinkMutation>;
export type DisableObjectLinkMutationResult = Apollo.MutationResult<DisableObjectLinkMutation>;
export type DisableObjectLinkMutationOptions = Apollo.BaseMutationOptions<DisableObjectLinkMutation, DisableObjectLinkMutationVariables>;
export const RejectObjectAccessRequestDocument = gql`
    mutation RejectObjectAccessRequest($requestId: String!) {
  rejectObjectAccessRequest(requestId: $requestId)
}
    `;
export type RejectObjectAccessRequestMutationFn = Apollo.MutationFunction<RejectObjectAccessRequestMutation, RejectObjectAccessRequestMutationVariables>;

/**
 * __useRejectObjectAccessRequestMutation__
 *
 * To run a mutation, you first call `useRejectObjectAccessRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectObjectAccessRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectObjectAccessRequestMutation, { data, loading, error }] = useRejectObjectAccessRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useRejectObjectAccessRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectObjectAccessRequestMutation, RejectObjectAccessRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectObjectAccessRequestMutation, RejectObjectAccessRequestMutationVariables>(RejectObjectAccessRequestDocument, options);
      }
export type RejectObjectAccessRequestMutationHookResult = ReturnType<typeof useRejectObjectAccessRequestMutation>;
export type RejectObjectAccessRequestMutationResult = Apollo.MutationResult<RejectObjectAccessRequestMutation>;
export type RejectObjectAccessRequestMutationOptions = Apollo.BaseMutationOptions<RejectObjectAccessRequestMutation, RejectObjectAccessRequestMutationVariables>;
export const GrantFromShareDocument = gql`
    mutation GrantFromShare($object: InputObjectIdentifier!, $intentId: String, $constraint: AccessConstraint) {
  grantFromShare(object: $object, intentId: $intentId, constraint: $constraint)
}
    `;
export type GrantFromShareMutationFn = Apollo.MutationFunction<GrantFromShareMutation, GrantFromShareMutationVariables>;

/**
 * __useGrantFromShareMutation__
 *
 * To run a mutation, you first call `useGrantFromShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantFromShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantFromShareMutation, { data, loading, error }] = useGrantFromShareMutation({
 *   variables: {
 *      object: // value for 'object'
 *      intentId: // value for 'intentId'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useGrantFromShareMutation(baseOptions?: Apollo.MutationHookOptions<GrantFromShareMutation, GrantFromShareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GrantFromShareMutation, GrantFromShareMutationVariables>(GrantFromShareDocument, options);
      }
export type GrantFromShareMutationHookResult = ReturnType<typeof useGrantFromShareMutation>;
export type GrantFromShareMutationResult = Apollo.MutationResult<GrantFromShareMutation>;
export type GrantFromShareMutationOptions = Apollo.BaseMutationOptions<GrantFromShareMutation, GrantFromShareMutationVariables>;
export const CreateIntentDocument = gql`
    mutation CreateIntent($identifier: InputObjectIdentifier!, $permissions: [NewUserGrant!]!, $message: String!, $sendNotification: Boolean!, $constraint: AccessConstraint, $viewKey: ViewKey) {
  createIntent(
    object: $identifier
    permissions: $permissions
    message: $message
    sendNotification: $sendNotification
    constraint: $constraint
    viewKey: $viewKey
  )
}
    `;
export type CreateIntentMutationFn = Apollo.MutationFunction<CreateIntentMutation, CreateIntentMutationVariables>;

/**
 * __useCreateIntentMutation__
 *
 * To run a mutation, you first call `useCreateIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntentMutation, { data, loading, error }] = useCreateIntentMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      permissions: // value for 'permissions'
 *      message: // value for 'message'
 *      sendNotification: // value for 'sendNotification'
 *      constraint: // value for 'constraint'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useCreateIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateIntentMutation, CreateIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIntentMutation, CreateIntentMutationVariables>(CreateIntentDocument, options);
      }
export type CreateIntentMutationHookResult = ReturnType<typeof useCreateIntentMutation>;
export type CreateIntentMutationResult = Apollo.MutationResult<CreateIntentMutation>;
export type CreateIntentMutationOptions = Apollo.BaseMutationOptions<CreateIntentMutation, CreateIntentMutationVariables>;
export const UpdateIntentDocument = gql`
    mutation UpdateIntent($intentId: String!, $identifier: InputObjectIdentifier!, $permission: Permission!, $constraint: AccessConstraint) {
  updateIntent(
    intentId: $intentId
    object: $identifier
    permission: $permission
    constraint: $constraint
  )
}
    `;
export type UpdateIntentMutationFn = Apollo.MutationFunction<UpdateIntentMutation, UpdateIntentMutationVariables>;

/**
 * __useUpdateIntentMutation__
 *
 * To run a mutation, you first call `useUpdateIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntentMutation, { data, loading, error }] = useUpdateIntentMutation({
 *   variables: {
 *      intentId: // value for 'intentId'
 *      identifier: // value for 'identifier'
 *      permission: // value for 'permission'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useUpdateIntentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntentMutation, UpdateIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIntentMutation, UpdateIntentMutationVariables>(UpdateIntentDocument, options);
      }
export type UpdateIntentMutationHookResult = ReturnType<typeof useUpdateIntentMutation>;
export type UpdateIntentMutationResult = Apollo.MutationResult<UpdateIntentMutation>;
export type UpdateIntentMutationOptions = Apollo.BaseMutationOptions<UpdateIntentMutation, UpdateIntentMutationVariables>;
export const EnableObjectLinkDocument = gql`
    mutation EnableObjectLink($identifier: InputObjectIdentifier!, $permission: Permission!, $viewType: ViewType) {
  enableObjectLink(
    object: $identifier
    permission: $permission
    viewType: $viewType
  )
}
    `;
export type EnableObjectLinkMutationFn = Apollo.MutationFunction<EnableObjectLinkMutation, EnableObjectLinkMutationVariables>;

/**
 * __useEnableObjectLinkMutation__
 *
 * To run a mutation, you first call `useEnableObjectLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableObjectLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableObjectLinkMutation, { data, loading, error }] = useEnableObjectLinkMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      permission: // value for 'permission'
 *      viewType: // value for 'viewType'
 *   },
 * });
 */
export function useEnableObjectLinkMutation(baseOptions?: Apollo.MutationHookOptions<EnableObjectLinkMutation, EnableObjectLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableObjectLinkMutation, EnableObjectLinkMutationVariables>(EnableObjectLinkDocument, options);
      }
export type EnableObjectLinkMutationHookResult = ReturnType<typeof useEnableObjectLinkMutation>;
export type EnableObjectLinkMutationResult = Apollo.MutationResult<EnableObjectLinkMutation>;
export type EnableObjectLinkMutationOptions = Apollo.BaseMutationOptions<EnableObjectLinkMutation, EnableObjectLinkMutationVariables>;
export const ApproveObjectAccessRequestDocument = gql`
    mutation ApproveObjectAccessRequest($requestId: String!, $permission: Permission!, $constraint: AccessConstraint) {
  approveObjectAccessRequest(
    requestId: $requestId
    permission: $permission
    constraint: $constraint
  )
}
    `;
export type ApproveObjectAccessRequestMutationFn = Apollo.MutationFunction<ApproveObjectAccessRequestMutation, ApproveObjectAccessRequestMutationVariables>;

/**
 * __useApproveObjectAccessRequestMutation__
 *
 * To run a mutation, you first call `useApproveObjectAccessRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveObjectAccessRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveObjectAccessRequestMutation, { data, loading, error }] = useApproveObjectAccessRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      permission: // value for 'permission'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useApproveObjectAccessRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveObjectAccessRequestMutation, ApproveObjectAccessRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveObjectAccessRequestMutation, ApproveObjectAccessRequestMutationVariables>(ApproveObjectAccessRequestDocument, options);
      }
export type ApproveObjectAccessRequestMutationHookResult = ReturnType<typeof useApproveObjectAccessRequestMutation>;
export type ApproveObjectAccessRequestMutationResult = Apollo.MutationResult<ApproveObjectAccessRequestMutation>;
export type ApproveObjectAccessRequestMutationOptions = Apollo.BaseMutationOptions<ApproveObjectAccessRequestMutation, ApproveObjectAccessRequestMutationVariables>;
export const RevokeGrantDocument = gql`
    mutation RevokeGrant($grantId: String!, $identifier: InputObjectIdentifier!, $constraint: AccessConstraint) {
  revokeGrant(grantId: $grantId, object: $identifier, constraint: $constraint)
}
    `;
export type RevokeGrantMutationFn = Apollo.MutationFunction<RevokeGrantMutation, RevokeGrantMutationVariables>;

/**
 * __useRevokeGrantMutation__
 *
 * To run a mutation, you first call `useRevokeGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeGrantMutation, { data, loading, error }] = useRevokeGrantMutation({
 *   variables: {
 *      grantId: // value for 'grantId'
 *      identifier: // value for 'identifier'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useRevokeGrantMutation(baseOptions?: Apollo.MutationHookOptions<RevokeGrantMutation, RevokeGrantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeGrantMutation, RevokeGrantMutationVariables>(RevokeGrantDocument, options);
      }
export type RevokeGrantMutationHookResult = ReturnType<typeof useRevokeGrantMutation>;
export type RevokeGrantMutationResult = Apollo.MutationResult<RevokeGrantMutation>;
export type RevokeGrantMutationOptions = Apollo.BaseMutationOptions<RevokeGrantMutation, RevokeGrantMutationVariables>;
export const RevokeIntentDocument = gql`
    mutation RevokeIntent($intentId: String!, $identifier: InputObjectIdentifier!, $constraint: AccessConstraint) {
  revokeIntent(intentId: $intentId, object: $identifier, constraint: $constraint)
}
    `;
export type RevokeIntentMutationFn = Apollo.MutationFunction<RevokeIntentMutation, RevokeIntentMutationVariables>;

/**
 * __useRevokeIntentMutation__
 *
 * To run a mutation, you first call `useRevokeIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeIntentMutation, { data, loading, error }] = useRevokeIntentMutation({
 *   variables: {
 *      intentId: // value for 'intentId'
 *      identifier: // value for 'identifier'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useRevokeIntentMutation(baseOptions?: Apollo.MutationHookOptions<RevokeIntentMutation, RevokeIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeIntentMutation, RevokeIntentMutationVariables>(RevokeIntentDocument, options);
      }
export type RevokeIntentMutationHookResult = ReturnType<typeof useRevokeIntentMutation>;
export type RevokeIntentMutationResult = Apollo.MutationResult<RevokeIntentMutation>;
export type RevokeIntentMutationOptions = Apollo.BaseMutationOptions<RevokeIntentMutation, RevokeIntentMutationVariables>;
export const UpdateGrantDocument = gql`
    mutation UpdateGrant($grantId: String!, $identifier: InputObjectIdentifier!, $permission: Permission!, $constraint: AccessConstraint) {
  updateGrant(
    grantId: $grantId
    object: $identifier
    permission: $permission
    constraint: $constraint
  )
}
    `;
export type UpdateGrantMutationFn = Apollo.MutationFunction<UpdateGrantMutation, UpdateGrantMutationVariables>;

/**
 * __useUpdateGrantMutation__
 *
 * To run a mutation, you first call `useUpdateGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrantMutation, { data, loading, error }] = useUpdateGrantMutation({
 *   variables: {
 *      grantId: // value for 'grantId'
 *      identifier: // value for 'identifier'
 *      permission: // value for 'permission'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useUpdateGrantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGrantMutation, UpdateGrantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGrantMutation, UpdateGrantMutationVariables>(UpdateGrantDocument, options);
      }
export type UpdateGrantMutationHookResult = ReturnType<typeof useUpdateGrantMutation>;
export type UpdateGrantMutationResult = Apollo.MutationResult<UpdateGrantMutation>;
export type UpdateGrantMutationOptions = Apollo.BaseMutationOptions<UpdateGrantMutation, UpdateGrantMutationVariables>;
export const ListGranteesDocument = gql`
    query ListGrantees($legalEntityId: String!) {
  listGrantees(legalEntityId: $legalEntityId) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useListGranteesQuery__
 *
 * To run a query within a React component, call `useListGranteesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGranteesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGranteesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListGranteesQuery(baseOptions: Apollo.QueryHookOptions<ListGranteesQuery, ListGranteesQueryVariables> & ({ variables: ListGranteesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGranteesQuery, ListGranteesQueryVariables>(ListGranteesDocument, options);
      }
export function useListGranteesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGranteesQuery, ListGranteesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGranteesQuery, ListGranteesQueryVariables>(ListGranteesDocument, options);
        }
export function useListGranteesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListGranteesQuery, ListGranteesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListGranteesQuery, ListGranteesQueryVariables>(ListGranteesDocument, options);
        }
export type ListGranteesQueryHookResult = ReturnType<typeof useListGranteesQuery>;
export type ListGranteesLazyQueryHookResult = ReturnType<typeof useListGranteesLazyQuery>;
export type ListGranteesSuspenseQueryHookResult = ReturnType<typeof useListGranteesSuspenseQuery>;
export type ListGranteesQueryResult = Apollo.QueryResult<ListGranteesQuery, ListGranteesQueryVariables>;
export const ReadTeamAclDocument = gql`
    query ReadTeamACL($teamId: String!, $legalEntityId: String!) {
  readTeamACL(teamId: $teamId, legalEntityId: $legalEntityId) {
    ...TeamACLFields
  }
}
    ${TeamAclFieldsFragmentDoc}`;

/**
 * __useReadTeamAclQuery__
 *
 * To run a query within a React component, call `useReadTeamAclQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTeamAclQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTeamAclQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadTeamAclQuery(baseOptions: Apollo.QueryHookOptions<ReadTeamAclQuery, ReadTeamAclQueryVariables> & ({ variables: ReadTeamAclQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTeamAclQuery, ReadTeamAclQueryVariables>(ReadTeamAclDocument, options);
      }
export function useReadTeamAclLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTeamAclQuery, ReadTeamAclQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTeamAclQuery, ReadTeamAclQueryVariables>(ReadTeamAclDocument, options);
        }
export function useReadTeamAclSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTeamAclQuery, ReadTeamAclQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTeamAclQuery, ReadTeamAclQueryVariables>(ReadTeamAclDocument, options);
        }
export type ReadTeamAclQueryHookResult = ReturnType<typeof useReadTeamAclQuery>;
export type ReadTeamAclLazyQueryHookResult = ReturnType<typeof useReadTeamAclLazyQuery>;
export type ReadTeamAclSuspenseQueryHookResult = ReturnType<typeof useReadTeamAclSuspenseQuery>;
export type ReadTeamAclQueryResult = Apollo.QueryResult<ReadTeamAclQuery, ReadTeamAclQueryVariables>;
export const ListObjectAcLsDocument = gql`
    query ListObjectACLs($ids: [String!]!, $kind: ObjectKind!, $legalEntityId: String!) {
  listObjectACLs(ids: $ids, kind: $kind, legalEntityId: $legalEntityId) {
    ...ObjectACLFields
  }
}
    ${ObjectAclFieldsFragmentDoc}`;

/**
 * __useListObjectAcLsQuery__
 *
 * To run a query within a React component, call `useListObjectAcLsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListObjectAcLsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListObjectAcLsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      kind: // value for 'kind'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListObjectAcLsQuery(baseOptions: Apollo.QueryHookOptions<ListObjectAcLsQuery, ListObjectAcLsQueryVariables> & ({ variables: ListObjectAcLsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListObjectAcLsQuery, ListObjectAcLsQueryVariables>(ListObjectAcLsDocument, options);
      }
export function useListObjectAcLsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListObjectAcLsQuery, ListObjectAcLsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListObjectAcLsQuery, ListObjectAcLsQueryVariables>(ListObjectAcLsDocument, options);
        }
export function useListObjectAcLsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListObjectAcLsQuery, ListObjectAcLsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListObjectAcLsQuery, ListObjectAcLsQueryVariables>(ListObjectAcLsDocument, options);
        }
export type ListObjectAcLsQueryHookResult = ReturnType<typeof useListObjectAcLsQuery>;
export type ListObjectAcLsLazyQueryHookResult = ReturnType<typeof useListObjectAcLsLazyQuery>;
export type ListObjectAcLsSuspenseQueryHookResult = ReturnType<typeof useListObjectAcLsSuspenseQuery>;
export type ListObjectAcLsQueryResult = Apollo.QueryResult<ListObjectAcLsQuery, ListObjectAcLsQueryVariables>;
export const ReadUserAclDocument = gql`
    query ReadUserACL {
  readUserACL {
    ...UserACLFields
  }
}
    ${UserAclFieldsFragmentDoc}`;

/**
 * __useReadUserAclQuery__
 *
 * To run a query within a React component, call `useReadUserAclQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserAclQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserAclQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadUserAclQuery(baseOptions?: Apollo.QueryHookOptions<ReadUserAclQuery, ReadUserAclQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserAclQuery, ReadUserAclQueryVariables>(ReadUserAclDocument, options);
      }
export function useReadUserAclLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserAclQuery, ReadUserAclQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserAclQuery, ReadUserAclQueryVariables>(ReadUserAclDocument, options);
        }
export function useReadUserAclSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadUserAclQuery, ReadUserAclQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadUserAclQuery, ReadUserAclQueryVariables>(ReadUserAclDocument, options);
        }
export type ReadUserAclQueryHookResult = ReturnType<typeof useReadUserAclQuery>;
export type ReadUserAclLazyQueryHookResult = ReturnType<typeof useReadUserAclLazyQuery>;
export type ReadUserAclSuspenseQueryHookResult = ReturnType<typeof useReadUserAclSuspenseQuery>;
export type ReadUserAclQueryResult = Apollo.QueryResult<ReadUserAclQuery, ReadUserAclQueryVariables>;
export const ReadShareAdjacencyDocument = gql`
    query ReadShareAdjacency($id: String!, $kind: ObjectKind!, $legalEntitySlug: String!) {
  readShareAdjacency(id: $id, kind: $kind, legalEntitySlug: $legalEntitySlug) {
    object {
      ...ObjectIdentifierFields
    }
    legalEntity {
      id
      slug
      name
    }
    organizationPreview {
      id
      slug
      name
    }
    viewType
  }
}
    ${ObjectIdentifierFieldsFragmentDoc}`;

/**
 * __useReadShareAdjacencyQuery__
 *
 * To run a query within a React component, call `useReadShareAdjacencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadShareAdjacencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadShareAdjacencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      kind: // value for 'kind'
 *      legalEntitySlug: // value for 'legalEntitySlug'
 *   },
 * });
 */
export function useReadShareAdjacencyQuery(baseOptions: Apollo.QueryHookOptions<ReadShareAdjacencyQuery, ReadShareAdjacencyQueryVariables> & ({ variables: ReadShareAdjacencyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadShareAdjacencyQuery, ReadShareAdjacencyQueryVariables>(ReadShareAdjacencyDocument, options);
      }
export function useReadShareAdjacencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadShareAdjacencyQuery, ReadShareAdjacencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadShareAdjacencyQuery, ReadShareAdjacencyQueryVariables>(ReadShareAdjacencyDocument, options);
        }
export function useReadShareAdjacencySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadShareAdjacencyQuery, ReadShareAdjacencyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadShareAdjacencyQuery, ReadShareAdjacencyQueryVariables>(ReadShareAdjacencyDocument, options);
        }
export type ReadShareAdjacencyQueryHookResult = ReturnType<typeof useReadShareAdjacencyQuery>;
export type ReadShareAdjacencyLazyQueryHookResult = ReturnType<typeof useReadShareAdjacencyLazyQuery>;
export type ReadShareAdjacencySuspenseQueryHookResult = ReturnType<typeof useReadShareAdjacencySuspenseQuery>;
export type ReadShareAdjacencyQueryResult = Apollo.QueryResult<ReadShareAdjacencyQuery, ReadShareAdjacencyQueryVariables>;
export const ReadShareDocument = gql`
    query ReadShare($id: String!, $kind: ObjectKind!, $intentId: String) {
  readShare(id: $id, kind: $kind, intentId: $intentId) {
    object {
      ...ObjectIdentifierFields
    }
    permission
    action
    legalEntity {
      id
      slug
      name
    }
    organizationPreview {
      id
      slug
      name
    }
    viewType
  }
}
    ${ObjectIdentifierFieldsFragmentDoc}`;

/**
 * __useReadShareQuery__
 *
 * To run a query within a React component, call `useReadShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadShareQuery({
 *   variables: {
 *      id: // value for 'id'
 *      kind: // value for 'kind'
 *      intentId: // value for 'intentId'
 *   },
 * });
 */
export function useReadShareQuery(baseOptions: Apollo.QueryHookOptions<ReadShareQuery, ReadShareQueryVariables> & ({ variables: ReadShareQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadShareQuery, ReadShareQueryVariables>(ReadShareDocument, options);
      }
export function useReadShareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadShareQuery, ReadShareQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadShareQuery, ReadShareQueryVariables>(ReadShareDocument, options);
        }
export function useReadShareSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadShareQuery, ReadShareQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadShareQuery, ReadShareQueryVariables>(ReadShareDocument, options);
        }
export type ReadShareQueryHookResult = ReturnType<typeof useReadShareQuery>;
export type ReadShareLazyQueryHookResult = ReturnType<typeof useReadShareLazyQuery>;
export type ReadShareSuspenseQueryHookResult = ReturnType<typeof useReadShareSuspenseQuery>;
export type ReadShareQueryResult = Apollo.QueryResult<ReadShareQuery, ReadShareQueryVariables>;
export const ReadLocationDocument = gql`
    query ReadLocation($viewKey: ViewKey!, $locationId: String!) {
  readLocation(viewKey: $viewKey, locationId: $locationId) {
    ...LocationFields
  }
}
    ${LocationFieldsFragmentDoc}`;

/**
 * __useReadLocationQuery__
 *
 * To run a query within a React component, call `useReadLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLocationQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useReadLocationQuery(baseOptions: Apollo.QueryHookOptions<ReadLocationQuery, ReadLocationQueryVariables> & ({ variables: ReadLocationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLocationQuery, ReadLocationQueryVariables>(ReadLocationDocument, options);
      }
export function useReadLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLocationQuery, ReadLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLocationQuery, ReadLocationQueryVariables>(ReadLocationDocument, options);
        }
export function useReadLocationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLocationQuery, ReadLocationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLocationQuery, ReadLocationQueryVariables>(ReadLocationDocument, options);
        }
export type ReadLocationQueryHookResult = ReturnType<typeof useReadLocationQuery>;
export type ReadLocationLazyQueryHookResult = ReturnType<typeof useReadLocationLazyQuery>;
export type ReadLocationSuspenseQueryHookResult = ReturnType<typeof useReadLocationSuspenseQuery>;
export type ReadLocationQueryResult = Apollo.QueryResult<ReadLocationQuery, ReadLocationQueryVariables>;
export const ListLocationsDocument = gql`
    query ListLocations($viewKey: ViewKey!) {
  listLocations(viewKey: $viewKey) {
    ...LocationFields
  }
}
    ${LocationFieldsFragmentDoc}`;

/**
 * __useListLocationsQuery__
 *
 * To run a query within a React component, call `useListLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationsQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useListLocationsQuery(baseOptions: Apollo.QueryHookOptions<ListLocationsQuery, ListLocationsQueryVariables> & ({ variables: ListLocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLocationsQuery, ListLocationsQueryVariables>(ListLocationsDocument, options);
      }
export function useListLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLocationsQuery, ListLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLocationsQuery, ListLocationsQueryVariables>(ListLocationsDocument, options);
        }
export function useListLocationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListLocationsQuery, ListLocationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListLocationsQuery, ListLocationsQueryVariables>(ListLocationsDocument, options);
        }
export type ListLocationsQueryHookResult = ReturnType<typeof useListLocationsQuery>;
export type ListLocationsLazyQueryHookResult = ReturnType<typeof useListLocationsLazyQuery>;
export type ListLocationsSuspenseQueryHookResult = ReturnType<typeof useListLocationsSuspenseQuery>;
export type ListLocationsQueryResult = Apollo.QueryResult<ListLocationsQuery, ListLocationsQueryVariables>;
export const ArchiveComponentDataDocument = gql`
    mutation ArchiveComponentData($layoutId: String!, $viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  archiveComponentData(layoutId: $layoutId, viewId: $viewId, config: $config)
}
    `;
export type ArchiveComponentDataMutationFn = Apollo.MutationFunction<ArchiveComponentDataMutation, ArchiveComponentDataMutationVariables>;

/**
 * __useArchiveComponentDataMutation__
 *
 * To run a mutation, you first call `useArchiveComponentDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveComponentDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveComponentDataMutation, { data, loading, error }] = useArchiveComponentDataMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useArchiveComponentDataMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveComponentDataMutation, ArchiveComponentDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveComponentDataMutation, ArchiveComponentDataMutationVariables>(ArchiveComponentDataDocument, options);
      }
export type ArchiveComponentDataMutationHookResult = ReturnType<typeof useArchiveComponentDataMutation>;
export type ArchiveComponentDataMutationResult = Apollo.MutationResult<ArchiveComponentDataMutation>;
export type ArchiveComponentDataMutationOptions = Apollo.BaseMutationOptions<ArchiveComponentDataMutation, ArchiveComponentDataMutationVariables>;
export const RearchiveLayoutDataDocument = gql`
    mutation RearchiveLayoutData($layoutId: String!, $viewId: ViewIdentifierInput!) {
  rearchiveLayoutData(layoutId: $layoutId, viewId: $viewId) {
    ...LayoutFields
  }
}
    ${LayoutFieldsFragmentDoc}`;
export type RearchiveLayoutDataMutationFn = Apollo.MutationFunction<RearchiveLayoutDataMutation, RearchiveLayoutDataMutationVariables>;

/**
 * __useRearchiveLayoutDataMutation__
 *
 * To run a mutation, you first call `useRearchiveLayoutDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRearchiveLayoutDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rearchiveLayoutDataMutation, { data, loading, error }] = useRearchiveLayoutDataMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      viewId: // value for 'viewId'
 *   },
 * });
 */
export function useRearchiveLayoutDataMutation(baseOptions?: Apollo.MutationHookOptions<RearchiveLayoutDataMutation, RearchiveLayoutDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RearchiveLayoutDataMutation, RearchiveLayoutDataMutationVariables>(RearchiveLayoutDataDocument, options);
      }
export type RearchiveLayoutDataMutationHookResult = ReturnType<typeof useRearchiveLayoutDataMutation>;
export type RearchiveLayoutDataMutationResult = Apollo.MutationResult<RearchiveLayoutDataMutation>;
export type RearchiveLayoutDataMutationOptions = Apollo.BaseMutationOptions<RearchiveLayoutDataMutation, RearchiveLayoutDataMutationVariables>;
export const UpdateLayoutDocument = gql`
    mutation UpdateLayout($viewKey: ViewKey, $layout: LayoutInput!) {
  updateLayout(viewKey: $viewKey, layout: $layout) {
    __typename
    ...LayoutFields
  }
}
    ${LayoutFieldsFragmentDoc}`;
export type UpdateLayoutMutationFn = Apollo.MutationFunction<UpdateLayoutMutation, UpdateLayoutMutationVariables>;

/**
 * __useUpdateLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayoutMutation, { data, loading, error }] = useUpdateLayoutMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useUpdateLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLayoutMutation, UpdateLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLayoutMutation, UpdateLayoutMutationVariables>(UpdateLayoutDocument, options);
      }
export type UpdateLayoutMutationHookResult = ReturnType<typeof useUpdateLayoutMutation>;
export type UpdateLayoutMutationResult = Apollo.MutationResult<UpdateLayoutMutation>;
export type UpdateLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateLayoutMutation, UpdateLayoutMutationVariables>;
export const UpdateReportLayoutDocument = gql`
    mutation UpdateReportLayout($viewKey: ViewKey!, $packageId: String!, $packageVersionId: String!, $documentId: String!, $layout: LayoutInput!) {
  updateReportLayout(
    viewKey: $viewKey
    packageId: $packageId
    packageVersionId: $packageVersionId
    documentId: $documentId
    layout: $layout
  ) {
    __typename
    ...LayoutFields
  }
}
    ${LayoutFieldsFragmentDoc}`;
export type UpdateReportLayoutMutationFn = Apollo.MutationFunction<UpdateReportLayoutMutation, UpdateReportLayoutMutationVariables>;

/**
 * __useUpdateReportLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateReportLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportLayoutMutation, { data, loading, error }] = useUpdateReportLayoutMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      packageId: // value for 'packageId'
 *      packageVersionId: // value for 'packageVersionId'
 *      documentId: // value for 'documentId'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useUpdateReportLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportLayoutMutation, UpdateReportLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportLayoutMutation, UpdateReportLayoutMutationVariables>(UpdateReportLayoutDocument, options);
      }
export type UpdateReportLayoutMutationHookResult = ReturnType<typeof useUpdateReportLayoutMutation>;
export type UpdateReportLayoutMutationResult = Apollo.MutationResult<UpdateReportLayoutMutation>;
export type UpdateReportLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateReportLayoutMutation, UpdateReportLayoutMutationVariables>;
export const ReadPartySummaryByTimeLiveDataDocument = gql`
    query ReadPartySummaryByTimeLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on Chart {
      entity {
        __typename
        ... on EntityParty {
          ...EntityPartyFields
        }
      }
      summary {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
        partialData
      }
    }
  }
}
    ${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadPartySummaryByTimeLiveDataQuery__
 *
 * To run a query within a React component, call `useReadPartySummaryByTimeLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPartySummaryByTimeLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPartySummaryByTimeLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadPartySummaryByTimeLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadPartySummaryByTimeLiveDataQuery, ReadPartySummaryByTimeLiveDataQueryVariables> & ({ variables: ReadPartySummaryByTimeLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPartySummaryByTimeLiveDataQuery, ReadPartySummaryByTimeLiveDataQueryVariables>(ReadPartySummaryByTimeLiveDataDocument, options);
      }
export function useReadPartySummaryByTimeLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPartySummaryByTimeLiveDataQuery, ReadPartySummaryByTimeLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPartySummaryByTimeLiveDataQuery, ReadPartySummaryByTimeLiveDataQueryVariables>(ReadPartySummaryByTimeLiveDataDocument, options);
        }
export function useReadPartySummaryByTimeLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadPartySummaryByTimeLiveDataQuery, ReadPartySummaryByTimeLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadPartySummaryByTimeLiveDataQuery, ReadPartySummaryByTimeLiveDataQueryVariables>(ReadPartySummaryByTimeLiveDataDocument, options);
        }
export type ReadPartySummaryByTimeLiveDataQueryHookResult = ReturnType<typeof useReadPartySummaryByTimeLiveDataQuery>;
export type ReadPartySummaryByTimeLiveDataLazyQueryHookResult = ReturnType<typeof useReadPartySummaryByTimeLiveDataLazyQuery>;
export type ReadPartySummaryByTimeLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadPartySummaryByTimeLiveDataSuspenseQuery>;
export type ReadPartySummaryByTimeLiveDataQueryResult = Apollo.QueryResult<ReadPartySummaryByTimeLiveDataQuery, ReadPartySummaryByTimeLiveDataQueryVariables>;
export const ReadPortalMetricItemByTimeLiveDataDocument = gql`
    query ReadPortalMetricItemByTimeLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on DimensionSummary {
      time {
        summary {
          ...TransactionSummaryFields
        }
      }
      partialData
    }
  }
}
    ${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadPortalMetricItemByTimeLiveDataQuery__
 *
 * To run a query within a React component, call `useReadPortalMetricItemByTimeLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPortalMetricItemByTimeLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPortalMetricItemByTimeLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadPortalMetricItemByTimeLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadPortalMetricItemByTimeLiveDataQuery, ReadPortalMetricItemByTimeLiveDataQueryVariables> & ({ variables: ReadPortalMetricItemByTimeLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPortalMetricItemByTimeLiveDataQuery, ReadPortalMetricItemByTimeLiveDataQueryVariables>(ReadPortalMetricItemByTimeLiveDataDocument, options);
      }
export function useReadPortalMetricItemByTimeLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPortalMetricItemByTimeLiveDataQuery, ReadPortalMetricItemByTimeLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPortalMetricItemByTimeLiveDataQuery, ReadPortalMetricItemByTimeLiveDataQueryVariables>(ReadPortalMetricItemByTimeLiveDataDocument, options);
        }
export function useReadPortalMetricItemByTimeLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadPortalMetricItemByTimeLiveDataQuery, ReadPortalMetricItemByTimeLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadPortalMetricItemByTimeLiveDataQuery, ReadPortalMetricItemByTimeLiveDataQueryVariables>(ReadPortalMetricItemByTimeLiveDataDocument, options);
        }
export type ReadPortalMetricItemByTimeLiveDataQueryHookResult = ReturnType<typeof useReadPortalMetricItemByTimeLiveDataQuery>;
export type ReadPortalMetricItemByTimeLiveDataLazyQueryHookResult = ReturnType<typeof useReadPortalMetricItemByTimeLiveDataLazyQuery>;
export type ReadPortalMetricItemByTimeLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadPortalMetricItemByTimeLiveDataSuspenseQuery>;
export type ReadPortalMetricItemByTimeLiveDataQueryResult = Apollo.QueryResult<ReadPortalMetricItemByTimeLiveDataQuery, ReadPortalMetricItemByTimeLiveDataQueryVariables>;
export const ReadCashSummaryChartLiveDataDocument = gql`
    query ReadCashSummaryChartLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on CashSummary {
      ...CashSummaryFields
    }
  }
}
    ${CashSummaryFieldsFragmentDoc}`;

/**
 * __useReadCashSummaryChartLiveDataQuery__
 *
 * To run a query within a React component, call `useReadCashSummaryChartLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCashSummaryChartLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCashSummaryChartLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadCashSummaryChartLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadCashSummaryChartLiveDataQuery, ReadCashSummaryChartLiveDataQueryVariables> & ({ variables: ReadCashSummaryChartLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCashSummaryChartLiveDataQuery, ReadCashSummaryChartLiveDataQueryVariables>(ReadCashSummaryChartLiveDataDocument, options);
      }
export function useReadCashSummaryChartLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCashSummaryChartLiveDataQuery, ReadCashSummaryChartLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCashSummaryChartLiveDataQuery, ReadCashSummaryChartLiveDataQueryVariables>(ReadCashSummaryChartLiveDataDocument, options);
        }
export function useReadCashSummaryChartLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadCashSummaryChartLiveDataQuery, ReadCashSummaryChartLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadCashSummaryChartLiveDataQuery, ReadCashSummaryChartLiveDataQueryVariables>(ReadCashSummaryChartLiveDataDocument, options);
        }
export type ReadCashSummaryChartLiveDataQueryHookResult = ReturnType<typeof useReadCashSummaryChartLiveDataQuery>;
export type ReadCashSummaryChartLiveDataLazyQueryHookResult = ReturnType<typeof useReadCashSummaryChartLiveDataLazyQuery>;
export type ReadCashSummaryChartLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadCashSummaryChartLiveDataSuspenseQuery>;
export type ReadCashSummaryChartLiveDataQueryResult = Apollo.QueryResult<ReadCashSummaryChartLiveDataQuery, ReadCashSummaryChartLiveDataQueryVariables>;
export const ReadLocationChartComponentDataDocument = gql`
    query ReadLocationChartComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on LocationSummary {
        ...LocationSummaryFields
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${LocationSummaryFieldsFragmentDoc}`;

/**
 * __useReadLocationChartComponentDataQuery__
 *
 * To run a query within a React component, call `useReadLocationChartComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLocationChartComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLocationChartComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadLocationChartComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadLocationChartComponentDataQuery, ReadLocationChartComponentDataQueryVariables> & ({ variables: ReadLocationChartComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLocationChartComponentDataQuery, ReadLocationChartComponentDataQueryVariables>(ReadLocationChartComponentDataDocument, options);
      }
export function useReadLocationChartComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLocationChartComponentDataQuery, ReadLocationChartComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLocationChartComponentDataQuery, ReadLocationChartComponentDataQueryVariables>(ReadLocationChartComponentDataDocument, options);
        }
export function useReadLocationChartComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLocationChartComponentDataQuery, ReadLocationChartComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLocationChartComponentDataQuery, ReadLocationChartComponentDataQueryVariables>(ReadLocationChartComponentDataDocument, options);
        }
export type ReadLocationChartComponentDataQueryHookResult = ReturnType<typeof useReadLocationChartComponentDataQuery>;
export type ReadLocationChartComponentDataLazyQueryHookResult = ReturnType<typeof useReadLocationChartComponentDataLazyQuery>;
export type ReadLocationChartComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadLocationChartComponentDataSuspenseQuery>;
export type ReadLocationChartComponentDataQueryResult = Apollo.QueryResult<ReadLocationChartComponentDataQuery, ReadLocationChartComponentDataQueryVariables>;
export const ReadLocationChartLiveDataDocument = gql`
    query ReadLocationChartLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on LocationSummary {
      ...LocationSummaryFields
    }
  }
}
    ${LocationSummaryFieldsFragmentDoc}`;

/**
 * __useReadLocationChartLiveDataQuery__
 *
 * To run a query within a React component, call `useReadLocationChartLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLocationChartLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLocationChartLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadLocationChartLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadLocationChartLiveDataQuery, ReadLocationChartLiveDataQueryVariables> & ({ variables: ReadLocationChartLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLocationChartLiveDataQuery, ReadLocationChartLiveDataQueryVariables>(ReadLocationChartLiveDataDocument, options);
      }
export function useReadLocationChartLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLocationChartLiveDataQuery, ReadLocationChartLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLocationChartLiveDataQuery, ReadLocationChartLiveDataQueryVariables>(ReadLocationChartLiveDataDocument, options);
        }
export function useReadLocationChartLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLocationChartLiveDataQuery, ReadLocationChartLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLocationChartLiveDataQuery, ReadLocationChartLiveDataQueryVariables>(ReadLocationChartLiveDataDocument, options);
        }
export type ReadLocationChartLiveDataQueryHookResult = ReturnType<typeof useReadLocationChartLiveDataQuery>;
export type ReadLocationChartLiveDataLazyQueryHookResult = ReturnType<typeof useReadLocationChartLiveDataLazyQuery>;
export type ReadLocationChartLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadLocationChartLiveDataSuspenseQuery>;
export type ReadLocationChartLiveDataQueryResult = Apollo.QueryResult<ReadLocationChartLiveDataQuery, ReadLocationChartLiveDataQueryVariables>;
export const ReadRunwayLiveDataDocument = gql`
    query ReadRunwayLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on Runway {
      ...RunwayFields
    }
  }
}
    ${RunwayFieldsFragmentDoc}`;

/**
 * __useReadRunwayLiveDataQuery__
 *
 * To run a query within a React component, call `useReadRunwayLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadRunwayLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadRunwayLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadRunwayLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadRunwayLiveDataQuery, ReadRunwayLiveDataQueryVariables> & ({ variables: ReadRunwayLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadRunwayLiveDataQuery, ReadRunwayLiveDataQueryVariables>(ReadRunwayLiveDataDocument, options);
      }
export function useReadRunwayLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadRunwayLiveDataQuery, ReadRunwayLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadRunwayLiveDataQuery, ReadRunwayLiveDataQueryVariables>(ReadRunwayLiveDataDocument, options);
        }
export function useReadRunwayLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadRunwayLiveDataQuery, ReadRunwayLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadRunwayLiveDataQuery, ReadRunwayLiveDataQueryVariables>(ReadRunwayLiveDataDocument, options);
        }
export type ReadRunwayLiveDataQueryHookResult = ReturnType<typeof useReadRunwayLiveDataQuery>;
export type ReadRunwayLiveDataLazyQueryHookResult = ReturnType<typeof useReadRunwayLiveDataLazyQuery>;
export type ReadRunwayLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadRunwayLiveDataSuspenseQuery>;
export type ReadRunwayLiveDataQueryResult = Apollo.QueryResult<ReadRunwayLiveDataQuery, ReadRunwayLiveDataQueryVariables>;
export const ReadInsightComponentDataDocument = gql`
    query ReadInsightComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!, $limit: Int!, $sort: DimensionSortMode) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on InsightComponent {
        __typename
        sentence
        hovers {
          __typename
          ... on CategoryHover {
            ...CategoryHoverFields
          }
          ... on PartyHover {
            ...PartyHoverFields
          }
          ... on SummaryLineItemHover {
            ...SummaryLineItemHoverFields
          }
        }
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${CategoryHoverFieldsFragmentDoc}
${PartyHoverFieldsFragmentDoc}
${SummaryLineItemHoverFieldsFragmentDoc}`;

/**
 * __useReadInsightComponentDataQuery__
 *
 * To run a query within a React component, call `useReadInsightComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInsightComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInsightComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadInsightComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadInsightComponentDataQuery, ReadInsightComponentDataQueryVariables> & ({ variables: ReadInsightComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInsightComponentDataQuery, ReadInsightComponentDataQueryVariables>(ReadInsightComponentDataDocument, options);
      }
export function useReadInsightComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInsightComponentDataQuery, ReadInsightComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInsightComponentDataQuery, ReadInsightComponentDataQueryVariables>(ReadInsightComponentDataDocument, options);
        }
export function useReadInsightComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadInsightComponentDataQuery, ReadInsightComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadInsightComponentDataQuery, ReadInsightComponentDataQueryVariables>(ReadInsightComponentDataDocument, options);
        }
export type ReadInsightComponentDataQueryHookResult = ReturnType<typeof useReadInsightComponentDataQuery>;
export type ReadInsightComponentDataLazyQueryHookResult = ReturnType<typeof useReadInsightComponentDataLazyQuery>;
export type ReadInsightComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadInsightComponentDataSuspenseQuery>;
export type ReadInsightComponentDataQueryResult = Apollo.QueryResult<ReadInsightComponentDataQuery, ReadInsightComponentDataQueryVariables>;
export const ReadCategorySummaryByTimeComponentDataDocument = gql`
    query ReadCategorySummaryByTimeComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on Chart {
        entity {
          __typename
          ... on EntityCategory {
            ...EntityCategoryFields
          }
        }
        summary {
          time {
            summary {
              ...TransactionSummaryFields
            }
          }
          partialData
        }
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadCategorySummaryByTimeComponentDataQuery__
 *
 * To run a query within a React component, call `useReadCategorySummaryByTimeComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCategorySummaryByTimeComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCategorySummaryByTimeComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadCategorySummaryByTimeComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadCategorySummaryByTimeComponentDataQuery, ReadCategorySummaryByTimeComponentDataQueryVariables> & ({ variables: ReadCategorySummaryByTimeComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCategorySummaryByTimeComponentDataQuery, ReadCategorySummaryByTimeComponentDataQueryVariables>(ReadCategorySummaryByTimeComponentDataDocument, options);
      }
export function useReadCategorySummaryByTimeComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCategorySummaryByTimeComponentDataQuery, ReadCategorySummaryByTimeComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCategorySummaryByTimeComponentDataQuery, ReadCategorySummaryByTimeComponentDataQueryVariables>(ReadCategorySummaryByTimeComponentDataDocument, options);
        }
export function useReadCategorySummaryByTimeComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadCategorySummaryByTimeComponentDataQuery, ReadCategorySummaryByTimeComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadCategorySummaryByTimeComponentDataQuery, ReadCategorySummaryByTimeComponentDataQueryVariables>(ReadCategorySummaryByTimeComponentDataDocument, options);
        }
export type ReadCategorySummaryByTimeComponentDataQueryHookResult = ReturnType<typeof useReadCategorySummaryByTimeComponentDataQuery>;
export type ReadCategorySummaryByTimeComponentDataLazyQueryHookResult = ReturnType<typeof useReadCategorySummaryByTimeComponentDataLazyQuery>;
export type ReadCategorySummaryByTimeComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadCategorySummaryByTimeComponentDataSuspenseQuery>;
export type ReadCategorySummaryByTimeComponentDataQueryResult = Apollo.QueryResult<ReadCategorySummaryByTimeComponentDataQuery, ReadCategorySummaryByTimeComponentDataQueryVariables>;
export const ReadTopPartiesComponentDataDocument = gql`
    query ReadTopPartiesComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!, $limit: Int!, $sort: DimensionSortMode) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on TopEntities {
        entities {
          party(limit: $limit, sort: $sort) {
            partyObject {
              ...EntityPartyFields
            }
            summary {
              ...TransactionSummaryFields
            }
            by {
              category(sort: Active) {
                categoryObject {
                  ...EntityCategoryFields
                }
              }
            }
          }
          partialData
        }
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}`;

/**
 * __useReadTopPartiesComponentDataQuery__
 *
 * To run a query within a React component, call `useReadTopPartiesComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTopPartiesComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTopPartiesComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadTopPartiesComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadTopPartiesComponentDataQuery, ReadTopPartiesComponentDataQueryVariables> & ({ variables: ReadTopPartiesComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTopPartiesComponentDataQuery, ReadTopPartiesComponentDataQueryVariables>(ReadTopPartiesComponentDataDocument, options);
      }
export function useReadTopPartiesComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTopPartiesComponentDataQuery, ReadTopPartiesComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTopPartiesComponentDataQuery, ReadTopPartiesComponentDataQueryVariables>(ReadTopPartiesComponentDataDocument, options);
        }
export function useReadTopPartiesComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTopPartiesComponentDataQuery, ReadTopPartiesComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTopPartiesComponentDataQuery, ReadTopPartiesComponentDataQueryVariables>(ReadTopPartiesComponentDataDocument, options);
        }
export type ReadTopPartiesComponentDataQueryHookResult = ReturnType<typeof useReadTopPartiesComponentDataQuery>;
export type ReadTopPartiesComponentDataLazyQueryHookResult = ReturnType<typeof useReadTopPartiesComponentDataLazyQuery>;
export type ReadTopPartiesComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadTopPartiesComponentDataSuspenseQuery>;
export type ReadTopPartiesComponentDataQueryResult = Apollo.QueryResult<ReadTopPartiesComponentDataQuery, ReadTopPartiesComponentDataQueryVariables>;
export const ReadTextComponentDataDocument = gql`
    query ReadTextComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!, $limit: Int!, $sort: DimensionSortMode) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on EntityHovers {
        hovers {
          __typename
          ... on CategoryHover {
            ...CategoryHoverFields
          }
          ... on PartyHover {
            ...PartyHoverFields
          }
          ... on SummaryLineItemHover {
            ...SummaryLineItemHoverFields
          }
        }
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${CategoryHoverFieldsFragmentDoc}
${PartyHoverFieldsFragmentDoc}
${SummaryLineItemHoverFieldsFragmentDoc}`;

/**
 * __useReadTextComponentDataQuery__
 *
 * To run a query within a React component, call `useReadTextComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTextComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTextComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadTextComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadTextComponentDataQuery, ReadTextComponentDataQueryVariables> & ({ variables: ReadTextComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTextComponentDataQuery, ReadTextComponentDataQueryVariables>(ReadTextComponentDataDocument, options);
      }
export function useReadTextComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTextComponentDataQuery, ReadTextComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTextComponentDataQuery, ReadTextComponentDataQueryVariables>(ReadTextComponentDataDocument, options);
        }
export function useReadTextComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTextComponentDataQuery, ReadTextComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTextComponentDataQuery, ReadTextComponentDataQueryVariables>(ReadTextComponentDataDocument, options);
        }
export type ReadTextComponentDataQueryHookResult = ReturnType<typeof useReadTextComponentDataQuery>;
export type ReadTextComponentDataLazyQueryHookResult = ReturnType<typeof useReadTextComponentDataLazyQuery>;
export type ReadTextComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadTextComponentDataSuspenseQuery>;
export type ReadTextComponentDataQueryResult = Apollo.QueryResult<ReadTextComponentDataQuery, ReadTextComponentDataQueryVariables>;
export const ReadDepartmentSummaryByTimeComponentDataDocument = gql`
    query ReadDepartmentSummaryByTimeComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on Chart {
        entity {
          __typename
          ... on EntityDepartment {
            ...EntityDepartmentFields
          }
        }
        summary {
          department {
            departmentId
            departmentObject {
              ...EntityDepartmentFields
            }
            by {
              time {
                summary {
                  ...TransactionSummaryFields
                }
              }
            }
          }
          partialData
        }
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${EntityDepartmentFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadDepartmentSummaryByTimeComponentDataQuery__
 *
 * To run a query within a React component, call `useReadDepartmentSummaryByTimeComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDepartmentSummaryByTimeComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDepartmentSummaryByTimeComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadDepartmentSummaryByTimeComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadDepartmentSummaryByTimeComponentDataQuery, ReadDepartmentSummaryByTimeComponentDataQueryVariables> & ({ variables: ReadDepartmentSummaryByTimeComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDepartmentSummaryByTimeComponentDataQuery, ReadDepartmentSummaryByTimeComponentDataQueryVariables>(ReadDepartmentSummaryByTimeComponentDataDocument, options);
      }
export function useReadDepartmentSummaryByTimeComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDepartmentSummaryByTimeComponentDataQuery, ReadDepartmentSummaryByTimeComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDepartmentSummaryByTimeComponentDataQuery, ReadDepartmentSummaryByTimeComponentDataQueryVariables>(ReadDepartmentSummaryByTimeComponentDataDocument, options);
        }
export function useReadDepartmentSummaryByTimeComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadDepartmentSummaryByTimeComponentDataQuery, ReadDepartmentSummaryByTimeComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadDepartmentSummaryByTimeComponentDataQuery, ReadDepartmentSummaryByTimeComponentDataQueryVariables>(ReadDepartmentSummaryByTimeComponentDataDocument, options);
        }
export type ReadDepartmentSummaryByTimeComponentDataQueryHookResult = ReturnType<typeof useReadDepartmentSummaryByTimeComponentDataQuery>;
export type ReadDepartmentSummaryByTimeComponentDataLazyQueryHookResult = ReturnType<typeof useReadDepartmentSummaryByTimeComponentDataLazyQuery>;
export type ReadDepartmentSummaryByTimeComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadDepartmentSummaryByTimeComponentDataSuspenseQuery>;
export type ReadDepartmentSummaryByTimeComponentDataQueryResult = Apollo.QueryResult<ReadDepartmentSummaryByTimeComponentDataQuery, ReadDepartmentSummaryByTimeComponentDataQueryVariables>;
export const ReadLocationSummaryByTimeComponentDataDocument = gql`
    query ReadLocationSummaryByTimeComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on Chart {
        entity {
          __typename
          ... on EntityDepartment {
            ...EntityDepartmentFields
          }
        }
        summary {
          department {
            departmentId
            departmentObject {
              ...EntityDepartmentFields
            }
            by {
              time {
                summary {
                  ...TransactionSummaryFields
                }
              }
            }
          }
          partialData
        }
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${EntityDepartmentFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadLocationSummaryByTimeComponentDataQuery__
 *
 * To run a query within a React component, call `useReadLocationSummaryByTimeComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLocationSummaryByTimeComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLocationSummaryByTimeComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadLocationSummaryByTimeComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadLocationSummaryByTimeComponentDataQuery, ReadLocationSummaryByTimeComponentDataQueryVariables> & ({ variables: ReadLocationSummaryByTimeComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLocationSummaryByTimeComponentDataQuery, ReadLocationSummaryByTimeComponentDataQueryVariables>(ReadLocationSummaryByTimeComponentDataDocument, options);
      }
export function useReadLocationSummaryByTimeComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLocationSummaryByTimeComponentDataQuery, ReadLocationSummaryByTimeComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLocationSummaryByTimeComponentDataQuery, ReadLocationSummaryByTimeComponentDataQueryVariables>(ReadLocationSummaryByTimeComponentDataDocument, options);
        }
export function useReadLocationSummaryByTimeComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLocationSummaryByTimeComponentDataQuery, ReadLocationSummaryByTimeComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLocationSummaryByTimeComponentDataQuery, ReadLocationSummaryByTimeComponentDataQueryVariables>(ReadLocationSummaryByTimeComponentDataDocument, options);
        }
export type ReadLocationSummaryByTimeComponentDataQueryHookResult = ReturnType<typeof useReadLocationSummaryByTimeComponentDataQuery>;
export type ReadLocationSummaryByTimeComponentDataLazyQueryHookResult = ReturnType<typeof useReadLocationSummaryByTimeComponentDataLazyQuery>;
export type ReadLocationSummaryByTimeComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadLocationSummaryByTimeComponentDataSuspenseQuery>;
export type ReadLocationSummaryByTimeComponentDataQueryResult = Apollo.QueryResult<ReadLocationSummaryByTimeComponentDataQuery, ReadLocationSummaryByTimeComponentDataQueryVariables>;
export const ReadTopTransactionsComponentDataDocument = gql`
    query ReadTopTransactionsComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on TopTransactions {
        transactions {
          ...EntityTransactionFields
        }
        partialData
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${EntityTransactionFieldsFragmentDoc}`;

/**
 * __useReadTopTransactionsComponentDataQuery__
 *
 * To run a query within a React component, call `useReadTopTransactionsComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTopTransactionsComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTopTransactionsComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadTopTransactionsComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadTopTransactionsComponentDataQuery, ReadTopTransactionsComponentDataQueryVariables> & ({ variables: ReadTopTransactionsComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTopTransactionsComponentDataQuery, ReadTopTransactionsComponentDataQueryVariables>(ReadTopTransactionsComponentDataDocument, options);
      }
export function useReadTopTransactionsComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTopTransactionsComponentDataQuery, ReadTopTransactionsComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTopTransactionsComponentDataQuery, ReadTopTransactionsComponentDataQueryVariables>(ReadTopTransactionsComponentDataDocument, options);
        }
export function useReadTopTransactionsComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTopTransactionsComponentDataQuery, ReadTopTransactionsComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTopTransactionsComponentDataQuery, ReadTopTransactionsComponentDataQueryVariables>(ReadTopTransactionsComponentDataDocument, options);
        }
export type ReadTopTransactionsComponentDataQueryHookResult = ReturnType<typeof useReadTopTransactionsComponentDataQuery>;
export type ReadTopTransactionsComponentDataLazyQueryHookResult = ReturnType<typeof useReadTopTransactionsComponentDataLazyQuery>;
export type ReadTopTransactionsComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadTopTransactionsComponentDataSuspenseQuery>;
export type ReadTopTransactionsComponentDataQueryResult = Apollo.QueryResult<ReadTopTransactionsComponentDataQuery, ReadTopTransactionsComponentDataQueryVariables>;
export const ReadDepartmentSummaryByTimeLiveDataDocument = gql`
    query ReadDepartmentSummaryByTimeLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on Chart {
      entity {
        __typename
        ... on EntityDepartment {
          ...EntityDepartmentFields
        }
      }
      summary {
        department {
          departmentId
          departmentObject {
            ...EntityDepartmentFields
          }
          by {
            time {
              summary {
                ...TransactionSummaryFields
              }
            }
          }
        }
        partialData
      }
    }
  }
}
    ${EntityDepartmentFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadDepartmentSummaryByTimeLiveDataQuery__
 *
 * To run a query within a React component, call `useReadDepartmentSummaryByTimeLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDepartmentSummaryByTimeLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDepartmentSummaryByTimeLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadDepartmentSummaryByTimeLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadDepartmentSummaryByTimeLiveDataQuery, ReadDepartmentSummaryByTimeLiveDataQueryVariables> & ({ variables: ReadDepartmentSummaryByTimeLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDepartmentSummaryByTimeLiveDataQuery, ReadDepartmentSummaryByTimeLiveDataQueryVariables>(ReadDepartmentSummaryByTimeLiveDataDocument, options);
      }
export function useReadDepartmentSummaryByTimeLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDepartmentSummaryByTimeLiveDataQuery, ReadDepartmentSummaryByTimeLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDepartmentSummaryByTimeLiveDataQuery, ReadDepartmentSummaryByTimeLiveDataQueryVariables>(ReadDepartmentSummaryByTimeLiveDataDocument, options);
        }
export function useReadDepartmentSummaryByTimeLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadDepartmentSummaryByTimeLiveDataQuery, ReadDepartmentSummaryByTimeLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadDepartmentSummaryByTimeLiveDataQuery, ReadDepartmentSummaryByTimeLiveDataQueryVariables>(ReadDepartmentSummaryByTimeLiveDataDocument, options);
        }
export type ReadDepartmentSummaryByTimeLiveDataQueryHookResult = ReturnType<typeof useReadDepartmentSummaryByTimeLiveDataQuery>;
export type ReadDepartmentSummaryByTimeLiveDataLazyQueryHookResult = ReturnType<typeof useReadDepartmentSummaryByTimeLiveDataLazyQuery>;
export type ReadDepartmentSummaryByTimeLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadDepartmentSummaryByTimeLiveDataSuspenseQuery>;
export type ReadDepartmentSummaryByTimeLiveDataQueryResult = Apollo.QueryResult<ReadDepartmentSummaryByTimeLiveDataQuery, ReadDepartmentSummaryByTimeLiveDataQueryVariables>;
export const ReadTopCategoriesComponentDataDocument = gql`
    query ReadTopCategoriesComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!, $limit: Int!, $sort: DimensionSortMode) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on TopEntities {
        entities {
          category(limit: $limit, sort: $sort) {
            categoryObject {
              ...EntityCategoryFields
            }
            summary {
              ...TransactionSummaryFields
            }
          }
          partialData
        }
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadTopCategoriesComponentDataQuery__
 *
 * To run a query within a React component, call `useReadTopCategoriesComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTopCategoriesComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTopCategoriesComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadTopCategoriesComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadTopCategoriesComponentDataQuery, ReadTopCategoriesComponentDataQueryVariables> & ({ variables: ReadTopCategoriesComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTopCategoriesComponentDataQuery, ReadTopCategoriesComponentDataQueryVariables>(ReadTopCategoriesComponentDataDocument, options);
      }
export function useReadTopCategoriesComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTopCategoriesComponentDataQuery, ReadTopCategoriesComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTopCategoriesComponentDataQuery, ReadTopCategoriesComponentDataQueryVariables>(ReadTopCategoriesComponentDataDocument, options);
        }
export function useReadTopCategoriesComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTopCategoriesComponentDataQuery, ReadTopCategoriesComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTopCategoriesComponentDataQuery, ReadTopCategoriesComponentDataQueryVariables>(ReadTopCategoriesComponentDataDocument, options);
        }
export type ReadTopCategoriesComponentDataQueryHookResult = ReturnType<typeof useReadTopCategoriesComponentDataQuery>;
export type ReadTopCategoriesComponentDataLazyQueryHookResult = ReturnType<typeof useReadTopCategoriesComponentDataLazyQuery>;
export type ReadTopCategoriesComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadTopCategoriesComponentDataSuspenseQuery>;
export type ReadTopCategoriesComponentDataQueryResult = Apollo.QueryResult<ReadTopCategoriesComponentDataQuery, ReadTopCategoriesComponentDataQueryVariables>;
export const ReadPartySummaryByTimeComponentDataDocument = gql`
    query ReadPartySummaryByTimeComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on Chart {
        entity {
          __typename
          ... on EntityParty {
            ...EntityPartyFields
          }
        }
        summary {
          time {
            summary {
              ...TransactionSummaryFields
            }
          }
          partialData
        }
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadPartySummaryByTimeComponentDataQuery__
 *
 * To run a query within a React component, call `useReadPartySummaryByTimeComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPartySummaryByTimeComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPartySummaryByTimeComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadPartySummaryByTimeComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadPartySummaryByTimeComponentDataQuery, ReadPartySummaryByTimeComponentDataQueryVariables> & ({ variables: ReadPartySummaryByTimeComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPartySummaryByTimeComponentDataQuery, ReadPartySummaryByTimeComponentDataQueryVariables>(ReadPartySummaryByTimeComponentDataDocument, options);
      }
export function useReadPartySummaryByTimeComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPartySummaryByTimeComponentDataQuery, ReadPartySummaryByTimeComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPartySummaryByTimeComponentDataQuery, ReadPartySummaryByTimeComponentDataQueryVariables>(ReadPartySummaryByTimeComponentDataDocument, options);
        }
export function useReadPartySummaryByTimeComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadPartySummaryByTimeComponentDataQuery, ReadPartySummaryByTimeComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadPartySummaryByTimeComponentDataQuery, ReadPartySummaryByTimeComponentDataQueryVariables>(ReadPartySummaryByTimeComponentDataDocument, options);
        }
export type ReadPartySummaryByTimeComponentDataQueryHookResult = ReturnType<typeof useReadPartySummaryByTimeComponentDataQuery>;
export type ReadPartySummaryByTimeComponentDataLazyQueryHookResult = ReturnType<typeof useReadPartySummaryByTimeComponentDataLazyQuery>;
export type ReadPartySummaryByTimeComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadPartySummaryByTimeComponentDataSuspenseQuery>;
export type ReadPartySummaryByTimeComponentDataQueryResult = Apollo.QueryResult<ReadPartySummaryByTimeComponentDataQuery, ReadPartySummaryByTimeComponentDataQueryVariables>;
export const ReadTopPartiesLiveDataDocument = gql`
    query ReadTopPartiesLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!, $limit: Int!, $sort: DimensionSortMode) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on TopEntities {
      entities {
        party(limit: $limit, sort: $sort) {
          partyObject {
            ...EntityPartyFields
          }
          summary {
            ...TransactionSummaryFields
          }
          by {
            category(sort: Active) {
              categoryObject {
                ...EntityCategoryFields
              }
            }
          }
        }
        partialData
      }
    }
  }
}
    ${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}`;

/**
 * __useReadTopPartiesLiveDataQuery__
 *
 * To run a query within a React component, call `useReadTopPartiesLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTopPartiesLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTopPartiesLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadTopPartiesLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadTopPartiesLiveDataQuery, ReadTopPartiesLiveDataQueryVariables> & ({ variables: ReadTopPartiesLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTopPartiesLiveDataQuery, ReadTopPartiesLiveDataQueryVariables>(ReadTopPartiesLiveDataDocument, options);
      }
export function useReadTopPartiesLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTopPartiesLiveDataQuery, ReadTopPartiesLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTopPartiesLiveDataQuery, ReadTopPartiesLiveDataQueryVariables>(ReadTopPartiesLiveDataDocument, options);
        }
export function useReadTopPartiesLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTopPartiesLiveDataQuery, ReadTopPartiesLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTopPartiesLiveDataQuery, ReadTopPartiesLiveDataQueryVariables>(ReadTopPartiesLiveDataDocument, options);
        }
export type ReadTopPartiesLiveDataQueryHookResult = ReturnType<typeof useReadTopPartiesLiveDataQuery>;
export type ReadTopPartiesLiveDataLazyQueryHookResult = ReturnType<typeof useReadTopPartiesLiveDataLazyQuery>;
export type ReadTopPartiesLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadTopPartiesLiveDataSuspenseQuery>;
export type ReadTopPartiesLiveDataQueryResult = Apollo.QueryResult<ReadTopPartiesLiveDataQuery, ReadTopPartiesLiveDataQueryVariables>;
export const ReadCategorySummaryByTimeLiveDataDocument = gql`
    query ReadCategorySummaryByTimeLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on Chart {
      entity {
        __typename
        ... on EntityCategory {
          ...EntityCategoryFields
        }
      }
      summary {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
        partialData
      }
    }
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadCategorySummaryByTimeLiveDataQuery__
 *
 * To run a query within a React component, call `useReadCategorySummaryByTimeLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCategorySummaryByTimeLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCategorySummaryByTimeLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadCategorySummaryByTimeLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadCategorySummaryByTimeLiveDataQuery, ReadCategorySummaryByTimeLiveDataQueryVariables> & ({ variables: ReadCategorySummaryByTimeLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCategorySummaryByTimeLiveDataQuery, ReadCategorySummaryByTimeLiveDataQueryVariables>(ReadCategorySummaryByTimeLiveDataDocument, options);
      }
export function useReadCategorySummaryByTimeLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCategorySummaryByTimeLiveDataQuery, ReadCategorySummaryByTimeLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCategorySummaryByTimeLiveDataQuery, ReadCategorySummaryByTimeLiveDataQueryVariables>(ReadCategorySummaryByTimeLiveDataDocument, options);
        }
export function useReadCategorySummaryByTimeLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadCategorySummaryByTimeLiveDataQuery, ReadCategorySummaryByTimeLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadCategorySummaryByTimeLiveDataQuery, ReadCategorySummaryByTimeLiveDataQueryVariables>(ReadCategorySummaryByTimeLiveDataDocument, options);
        }
export type ReadCategorySummaryByTimeLiveDataQueryHookResult = ReturnType<typeof useReadCategorySummaryByTimeLiveDataQuery>;
export type ReadCategorySummaryByTimeLiveDataLazyQueryHookResult = ReturnType<typeof useReadCategorySummaryByTimeLiveDataLazyQuery>;
export type ReadCategorySummaryByTimeLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadCategorySummaryByTimeLiveDataSuspenseQuery>;
export type ReadCategorySummaryByTimeLiveDataQueryResult = Apollo.QueryResult<ReadCategorySummaryByTimeLiveDataQuery, ReadCategorySummaryByTimeLiveDataQueryVariables>;
export const ReadSummaryLineItemByTimeCategoryLiveDataDocument = gql`
    query ReadSummaryLineItemByTimeCategoryLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  total: liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on DimensionSummary {
      time {
        summary {
          ...TransactionSummaryFields
        }
      }
      partialData
    }
  }
  breakdown: liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on DimensionSummary {
      time {
        by {
          category(sort: Total) {
            categoryObject {
              ...EntityCategoryFields
            }
            summary {
              ...TransactionSummaryFields
            }
          }
        }
      }
      partialData
    }
  }
}
    ${TransactionSummaryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}`;

/**
 * __useReadSummaryLineItemByTimeCategoryLiveDataQuery__
 *
 * To run a query within a React component, call `useReadSummaryLineItemByTimeCategoryLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSummaryLineItemByTimeCategoryLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSummaryLineItemByTimeCategoryLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadSummaryLineItemByTimeCategoryLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadSummaryLineItemByTimeCategoryLiveDataQuery, ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables> & ({ variables: ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSummaryLineItemByTimeCategoryLiveDataQuery, ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables>(ReadSummaryLineItemByTimeCategoryLiveDataDocument, options);
      }
export function useReadSummaryLineItemByTimeCategoryLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSummaryLineItemByTimeCategoryLiveDataQuery, ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSummaryLineItemByTimeCategoryLiveDataQuery, ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables>(ReadSummaryLineItemByTimeCategoryLiveDataDocument, options);
        }
export function useReadSummaryLineItemByTimeCategoryLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSummaryLineItemByTimeCategoryLiveDataQuery, ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSummaryLineItemByTimeCategoryLiveDataQuery, ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables>(ReadSummaryLineItemByTimeCategoryLiveDataDocument, options);
        }
export type ReadSummaryLineItemByTimeCategoryLiveDataQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeCategoryLiveDataQuery>;
export type ReadSummaryLineItemByTimeCategoryLiveDataLazyQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeCategoryLiveDataLazyQuery>;
export type ReadSummaryLineItemByTimeCategoryLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeCategoryLiveDataSuspenseQuery>;
export type ReadSummaryLineItemByTimeCategoryLiveDataQueryResult = Apollo.QueryResult<ReadSummaryLineItemByTimeCategoryLiveDataQuery, ReadSummaryLineItemByTimeCategoryLiveDataQueryVariables>;
export const ReadLayoutDocument = gql`
    query ReadLayout($legalEntityId: String!, $layoutId: String!, $layoutVersionId: String) {
  readLayout(
    legalEntityId: $legalEntityId
    layoutId: $layoutId
    layoutVersionId: $layoutVersionId
  ) {
    ...LayoutFields
  }
}
    ${LayoutFieldsFragmentDoc}`;

/**
 * __useReadLayoutQuery__
 *
 * To run a query within a React component, call `useReadLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLayoutQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      layoutId: // value for 'layoutId'
 *      layoutVersionId: // value for 'layoutVersionId'
 *   },
 * });
 */
export function useReadLayoutQuery(baseOptions: Apollo.QueryHookOptions<ReadLayoutQuery, ReadLayoutQueryVariables> & ({ variables: ReadLayoutQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLayoutQuery, ReadLayoutQueryVariables>(ReadLayoutDocument, options);
      }
export function useReadLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLayoutQuery, ReadLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLayoutQuery, ReadLayoutQueryVariables>(ReadLayoutDocument, options);
        }
export function useReadLayoutSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLayoutQuery, ReadLayoutQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLayoutQuery, ReadLayoutQueryVariables>(ReadLayoutDocument, options);
        }
export type ReadLayoutQueryHookResult = ReturnType<typeof useReadLayoutQuery>;
export type ReadLayoutLazyQueryHookResult = ReturnType<typeof useReadLayoutLazyQuery>;
export type ReadLayoutSuspenseQueryHookResult = ReturnType<typeof useReadLayoutSuspenseQuery>;
export type ReadLayoutQueryResult = Apollo.QueryResult<ReadLayoutQuery, ReadLayoutQueryVariables>;
export const ReadReportStatementComponentDataDocument = gql`
    query ReadReportStatementComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!, $limit: Int!, $sort: DimensionSortMode) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on Statement {
        ...StatementFields
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${StatementFieldsFragmentDoc}`;

/**
 * __useReadReportStatementComponentDataQuery__
 *
 * To run a query within a React component, call `useReadReportStatementComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportStatementComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportStatementComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadReportStatementComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadReportStatementComponentDataQuery, ReadReportStatementComponentDataQueryVariables> & ({ variables: ReadReportStatementComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReportStatementComponentDataQuery, ReadReportStatementComponentDataQueryVariables>(ReadReportStatementComponentDataDocument, options);
      }
export function useReadReportStatementComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReportStatementComponentDataQuery, ReadReportStatementComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReportStatementComponentDataQuery, ReadReportStatementComponentDataQueryVariables>(ReadReportStatementComponentDataDocument, options);
        }
export function useReadReportStatementComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadReportStatementComponentDataQuery, ReadReportStatementComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadReportStatementComponentDataQuery, ReadReportStatementComponentDataQueryVariables>(ReadReportStatementComponentDataDocument, options);
        }
export type ReadReportStatementComponentDataQueryHookResult = ReturnType<typeof useReadReportStatementComponentDataQuery>;
export type ReadReportStatementComponentDataLazyQueryHookResult = ReturnType<typeof useReadReportStatementComponentDataLazyQuery>;
export type ReadReportStatementComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadReportStatementComponentDataSuspenseQuery>;
export type ReadReportStatementComponentDataQueryResult = Apollo.QueryResult<ReadReportStatementComponentDataQuery, ReadReportStatementComponentDataQueryVariables>;
export const ReadRunwayComponentDataDocument = gql`
    query ReadRunwayComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on Runway {
        ...RunwayFields
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${RunwayFieldsFragmentDoc}`;

/**
 * __useReadRunwayComponentDataQuery__
 *
 * To run a query within a React component, call `useReadRunwayComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadRunwayComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadRunwayComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadRunwayComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadRunwayComponentDataQuery, ReadRunwayComponentDataQueryVariables> & ({ variables: ReadRunwayComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadRunwayComponentDataQuery, ReadRunwayComponentDataQueryVariables>(ReadRunwayComponentDataDocument, options);
      }
export function useReadRunwayComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadRunwayComponentDataQuery, ReadRunwayComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadRunwayComponentDataQuery, ReadRunwayComponentDataQueryVariables>(ReadRunwayComponentDataDocument, options);
        }
export function useReadRunwayComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadRunwayComponentDataQuery, ReadRunwayComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadRunwayComponentDataQuery, ReadRunwayComponentDataQueryVariables>(ReadRunwayComponentDataDocument, options);
        }
export type ReadRunwayComponentDataQueryHookResult = ReturnType<typeof useReadRunwayComponentDataQuery>;
export type ReadRunwayComponentDataLazyQueryHookResult = ReturnType<typeof useReadRunwayComponentDataLazyQuery>;
export type ReadRunwayComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadRunwayComponentDataSuspenseQuery>;
export type ReadRunwayComponentDataQueryResult = Apollo.QueryResult<ReadRunwayComponentDataQuery, ReadRunwayComponentDataQueryVariables>;
export const ReadReportStatementLiveDataDocument = gql`
    query ReadReportStatementLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!, $limit: Int!, $sort: DimensionSortMode) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on Statement {
      ...StatementFields
    }
  }
}
    ${StatementFieldsFragmentDoc}`;

/**
 * __useReadReportStatementLiveDataQuery__
 *
 * To run a query within a React component, call `useReadReportStatementLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportStatementLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportStatementLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadReportStatementLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadReportStatementLiveDataQuery, ReadReportStatementLiveDataQueryVariables> & ({ variables: ReadReportStatementLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReportStatementLiveDataQuery, ReadReportStatementLiveDataQueryVariables>(ReadReportStatementLiveDataDocument, options);
      }
export function useReadReportStatementLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReportStatementLiveDataQuery, ReadReportStatementLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReportStatementLiveDataQuery, ReadReportStatementLiveDataQueryVariables>(ReadReportStatementLiveDataDocument, options);
        }
export function useReadReportStatementLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadReportStatementLiveDataQuery, ReadReportStatementLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadReportStatementLiveDataQuery, ReadReportStatementLiveDataQueryVariables>(ReadReportStatementLiveDataDocument, options);
        }
export type ReadReportStatementLiveDataQueryHookResult = ReturnType<typeof useReadReportStatementLiveDataQuery>;
export type ReadReportStatementLiveDataLazyQueryHookResult = ReturnType<typeof useReadReportStatementLiveDataLazyQuery>;
export type ReadReportStatementLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadReportStatementLiveDataSuspenseQuery>;
export type ReadReportStatementLiveDataQueryResult = Apollo.QueryResult<ReadReportStatementLiveDataQuery, ReadReportStatementLiveDataQueryVariables>;
export const ReadSummaryLineItemByTimeComponentDataDocument = gql`
    query ReadSummaryLineItemByTimeComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on DimensionSummary {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
        partialData
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadSummaryLineItemByTimeComponentDataQuery__
 *
 * To run a query within a React component, call `useReadSummaryLineItemByTimeComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSummaryLineItemByTimeComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSummaryLineItemByTimeComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadSummaryLineItemByTimeComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadSummaryLineItemByTimeComponentDataQuery, ReadSummaryLineItemByTimeComponentDataQueryVariables> & ({ variables: ReadSummaryLineItemByTimeComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSummaryLineItemByTimeComponentDataQuery, ReadSummaryLineItemByTimeComponentDataQueryVariables>(ReadSummaryLineItemByTimeComponentDataDocument, options);
      }
export function useReadSummaryLineItemByTimeComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSummaryLineItemByTimeComponentDataQuery, ReadSummaryLineItemByTimeComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSummaryLineItemByTimeComponentDataQuery, ReadSummaryLineItemByTimeComponentDataQueryVariables>(ReadSummaryLineItemByTimeComponentDataDocument, options);
        }
export function useReadSummaryLineItemByTimeComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSummaryLineItemByTimeComponentDataQuery, ReadSummaryLineItemByTimeComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSummaryLineItemByTimeComponentDataQuery, ReadSummaryLineItemByTimeComponentDataQueryVariables>(ReadSummaryLineItemByTimeComponentDataDocument, options);
        }
export type ReadSummaryLineItemByTimeComponentDataQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeComponentDataQuery>;
export type ReadSummaryLineItemByTimeComponentDataLazyQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeComponentDataLazyQuery>;
export type ReadSummaryLineItemByTimeComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeComponentDataSuspenseQuery>;
export type ReadSummaryLineItemByTimeComponentDataQueryResult = Apollo.QueryResult<ReadSummaryLineItemByTimeComponentDataQuery, ReadSummaryLineItemByTimeComponentDataQueryVariables>;
export const ReadTopTransactionsLiveDataDocument = gql`
    query ReadTopTransactionsLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on TopTransactions {
      transactions {
        ...EntityTransactionFields
      }
      partialData
    }
  }
}
    ${EntityTransactionFieldsFragmentDoc}`;

/**
 * __useReadTopTransactionsLiveDataQuery__
 *
 * To run a query within a React component, call `useReadTopTransactionsLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTopTransactionsLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTopTransactionsLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadTopTransactionsLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadTopTransactionsLiveDataQuery, ReadTopTransactionsLiveDataQueryVariables> & ({ variables: ReadTopTransactionsLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTopTransactionsLiveDataQuery, ReadTopTransactionsLiveDataQueryVariables>(ReadTopTransactionsLiveDataDocument, options);
      }
export function useReadTopTransactionsLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTopTransactionsLiveDataQuery, ReadTopTransactionsLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTopTransactionsLiveDataQuery, ReadTopTransactionsLiveDataQueryVariables>(ReadTopTransactionsLiveDataDocument, options);
        }
export function useReadTopTransactionsLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTopTransactionsLiveDataQuery, ReadTopTransactionsLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTopTransactionsLiveDataQuery, ReadTopTransactionsLiveDataQueryVariables>(ReadTopTransactionsLiveDataDocument, options);
        }
export type ReadTopTransactionsLiveDataQueryHookResult = ReturnType<typeof useReadTopTransactionsLiveDataQuery>;
export type ReadTopTransactionsLiveDataLazyQueryHookResult = ReturnType<typeof useReadTopTransactionsLiveDataLazyQuery>;
export type ReadTopTransactionsLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadTopTransactionsLiveDataSuspenseQuery>;
export type ReadTopTransactionsLiveDataQueryResult = Apollo.QueryResult<ReadTopTransactionsLiveDataQuery, ReadTopTransactionsLiveDataQueryVariables>;
export const ReadTopCategoriesLiveDataDocument = gql`
    query ReadTopCategoriesLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!, $limit: Int!, $sort: DimensionSortMode) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on TopEntities {
      entities {
        category(limit: $limit, sort: $sort) {
          categoryObject {
            ...EntityCategoryFields
          }
          summary {
            ...TransactionSummaryFields
          }
        }
        partialData
      }
    }
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadTopCategoriesLiveDataQuery__
 *
 * To run a query within a React component, call `useReadTopCategoriesLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTopCategoriesLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTopCategoriesLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadTopCategoriesLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadTopCategoriesLiveDataQuery, ReadTopCategoriesLiveDataQueryVariables> & ({ variables: ReadTopCategoriesLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTopCategoriesLiveDataQuery, ReadTopCategoriesLiveDataQueryVariables>(ReadTopCategoriesLiveDataDocument, options);
      }
export function useReadTopCategoriesLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTopCategoriesLiveDataQuery, ReadTopCategoriesLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTopCategoriesLiveDataQuery, ReadTopCategoriesLiveDataQueryVariables>(ReadTopCategoriesLiveDataDocument, options);
        }
export function useReadTopCategoriesLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTopCategoriesLiveDataQuery, ReadTopCategoriesLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTopCategoriesLiveDataQuery, ReadTopCategoriesLiveDataQueryVariables>(ReadTopCategoriesLiveDataDocument, options);
        }
export type ReadTopCategoriesLiveDataQueryHookResult = ReturnType<typeof useReadTopCategoriesLiveDataQuery>;
export type ReadTopCategoriesLiveDataLazyQueryHookResult = ReturnType<typeof useReadTopCategoriesLiveDataLazyQuery>;
export type ReadTopCategoriesLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadTopCategoriesLiveDataSuspenseQuery>;
export type ReadTopCategoriesLiveDataQueryResult = Apollo.QueryResult<ReadTopCategoriesLiveDataQuery, ReadTopCategoriesLiveDataQueryVariables>;
export const ReadMetricByTimeLiveDataDocument = gql`
    query ReadMetricByTimeLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on DimensionSummary {
      time {
        summary {
          ...TransactionSummaryFields
        }
      }
      partialData
    }
  }
}
    ${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadMetricByTimeLiveDataQuery__
 *
 * To run a query within a React component, call `useReadMetricByTimeLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMetricByTimeLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMetricByTimeLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadMetricByTimeLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadMetricByTimeLiveDataQuery, ReadMetricByTimeLiveDataQueryVariables> & ({ variables: ReadMetricByTimeLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMetricByTimeLiveDataQuery, ReadMetricByTimeLiveDataQueryVariables>(ReadMetricByTimeLiveDataDocument, options);
      }
export function useReadMetricByTimeLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMetricByTimeLiveDataQuery, ReadMetricByTimeLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMetricByTimeLiveDataQuery, ReadMetricByTimeLiveDataQueryVariables>(ReadMetricByTimeLiveDataDocument, options);
        }
export function useReadMetricByTimeLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadMetricByTimeLiveDataQuery, ReadMetricByTimeLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadMetricByTimeLiveDataQuery, ReadMetricByTimeLiveDataQueryVariables>(ReadMetricByTimeLiveDataDocument, options);
        }
export type ReadMetricByTimeLiveDataQueryHookResult = ReturnType<typeof useReadMetricByTimeLiveDataQuery>;
export type ReadMetricByTimeLiveDataLazyQueryHookResult = ReturnType<typeof useReadMetricByTimeLiveDataLazyQuery>;
export type ReadMetricByTimeLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadMetricByTimeLiveDataSuspenseQuery>;
export type ReadMetricByTimeLiveDataQueryResult = Apollo.QueryResult<ReadMetricByTimeLiveDataQuery, ReadMetricByTimeLiveDataQueryVariables>;
export const ReadMetricByTimeComponentDataDocument = gql`
    query ReadMetricByTimeComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on DimensionSummary {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
        partialData
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadMetricByTimeComponentDataQuery__
 *
 * To run a query within a React component, call `useReadMetricByTimeComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMetricByTimeComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMetricByTimeComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadMetricByTimeComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadMetricByTimeComponentDataQuery, ReadMetricByTimeComponentDataQueryVariables> & ({ variables: ReadMetricByTimeComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMetricByTimeComponentDataQuery, ReadMetricByTimeComponentDataQueryVariables>(ReadMetricByTimeComponentDataDocument, options);
      }
export function useReadMetricByTimeComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMetricByTimeComponentDataQuery, ReadMetricByTimeComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMetricByTimeComponentDataQuery, ReadMetricByTimeComponentDataQueryVariables>(ReadMetricByTimeComponentDataDocument, options);
        }
export function useReadMetricByTimeComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadMetricByTimeComponentDataQuery, ReadMetricByTimeComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadMetricByTimeComponentDataQuery, ReadMetricByTimeComponentDataQueryVariables>(ReadMetricByTimeComponentDataDocument, options);
        }
export type ReadMetricByTimeComponentDataQueryHookResult = ReturnType<typeof useReadMetricByTimeComponentDataQuery>;
export type ReadMetricByTimeComponentDataLazyQueryHookResult = ReturnType<typeof useReadMetricByTimeComponentDataLazyQuery>;
export type ReadMetricByTimeComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadMetricByTimeComponentDataSuspenseQuery>;
export type ReadMetricByTimeComponentDataQueryResult = Apollo.QueryResult<ReadMetricByTimeComponentDataQuery, ReadMetricByTimeComponentDataQueryVariables>;
export const ReadInsightLiveDataDocument = gql`
    query ReadInsightLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!, $limit: Int!, $sort: DimensionSortMode) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on InsightComponent {
      __typename
      sentence
      hovers {
        __typename
        ... on CategoryHover {
          ...CategoryHoverFields
        }
        ... on PartyHover {
          ...PartyHoverFields
        }
        ... on SummaryLineItemHover {
          ...SummaryLineItemHoverFields
        }
      }
    }
  }
}
    ${CategoryHoverFieldsFragmentDoc}
${PartyHoverFieldsFragmentDoc}
${SummaryLineItemHoverFieldsFragmentDoc}`;

/**
 * __useReadInsightLiveDataQuery__
 *
 * To run a query within a React component, call `useReadInsightLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInsightLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInsightLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadInsightLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadInsightLiveDataQuery, ReadInsightLiveDataQueryVariables> & ({ variables: ReadInsightLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInsightLiveDataQuery, ReadInsightLiveDataQueryVariables>(ReadInsightLiveDataDocument, options);
      }
export function useReadInsightLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInsightLiveDataQuery, ReadInsightLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInsightLiveDataQuery, ReadInsightLiveDataQueryVariables>(ReadInsightLiveDataDocument, options);
        }
export function useReadInsightLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadInsightLiveDataQuery, ReadInsightLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadInsightLiveDataQuery, ReadInsightLiveDataQueryVariables>(ReadInsightLiveDataDocument, options);
        }
export type ReadInsightLiveDataQueryHookResult = ReturnType<typeof useReadInsightLiveDataQuery>;
export type ReadInsightLiveDataLazyQueryHookResult = ReturnType<typeof useReadInsightLiveDataLazyQuery>;
export type ReadInsightLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadInsightLiveDataSuspenseQuery>;
export type ReadInsightLiveDataQueryResult = Apollo.QueryResult<ReadInsightLiveDataQuery, ReadInsightLiveDataQueryVariables>;
export const ReadCashSummaryChartComponentDataDocument = gql`
    query ReadCashSummaryChartComponentData($layoutId: String!, $dataId: String!, $legalEntityId: String!) {
  readComponentData(
    layoutId: $layoutId
    dataId: $dataId
    legalEntityId: $legalEntityId
  ) {
    viewId {
      ...ViewIdentifierFields
    }
    data {
      __typename
      ... on CashSummary {
        ...CashSummaryFields
      }
    }
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${CashSummaryFieldsFragmentDoc}`;

/**
 * __useReadCashSummaryChartComponentDataQuery__
 *
 * To run a query within a React component, call `useReadCashSummaryChartComponentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCashSummaryChartComponentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCashSummaryChartComponentDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      dataId: // value for 'dataId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadCashSummaryChartComponentDataQuery(baseOptions: Apollo.QueryHookOptions<ReadCashSummaryChartComponentDataQuery, ReadCashSummaryChartComponentDataQueryVariables> & ({ variables: ReadCashSummaryChartComponentDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCashSummaryChartComponentDataQuery, ReadCashSummaryChartComponentDataQueryVariables>(ReadCashSummaryChartComponentDataDocument, options);
      }
export function useReadCashSummaryChartComponentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCashSummaryChartComponentDataQuery, ReadCashSummaryChartComponentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCashSummaryChartComponentDataQuery, ReadCashSummaryChartComponentDataQueryVariables>(ReadCashSummaryChartComponentDataDocument, options);
        }
export function useReadCashSummaryChartComponentDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadCashSummaryChartComponentDataQuery, ReadCashSummaryChartComponentDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadCashSummaryChartComponentDataQuery, ReadCashSummaryChartComponentDataQueryVariables>(ReadCashSummaryChartComponentDataDocument, options);
        }
export type ReadCashSummaryChartComponentDataQueryHookResult = ReturnType<typeof useReadCashSummaryChartComponentDataQuery>;
export type ReadCashSummaryChartComponentDataLazyQueryHookResult = ReturnType<typeof useReadCashSummaryChartComponentDataLazyQuery>;
export type ReadCashSummaryChartComponentDataSuspenseQueryHookResult = ReturnType<typeof useReadCashSummaryChartComponentDataSuspenseQuery>;
export type ReadCashSummaryChartComponentDataQueryResult = Apollo.QueryResult<ReadCashSummaryChartComponentDataQuery, ReadCashSummaryChartComponentDataQueryVariables>;
export const ReadSummaryLineItemByTimeLiveDataDocument = gql`
    query ReadSummaryLineItemByTimeLiveData($viewId: ViewIdentifierInput!, $config: LayoutComponentConfigInput!) {
  liveComponentData(viewId: $viewId, config: $config) {
    __typename
    ... on DimensionSummary {
      time {
        summary {
          ...TransactionSummaryFields
        }
      }
      partialData
    }
  }
}
    ${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useReadSummaryLineItemByTimeLiveDataQuery__
 *
 * To run a query within a React component, call `useReadSummaryLineItemByTimeLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSummaryLineItemByTimeLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSummaryLineItemByTimeLiveDataQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useReadSummaryLineItemByTimeLiveDataQuery(baseOptions: Apollo.QueryHookOptions<ReadSummaryLineItemByTimeLiveDataQuery, ReadSummaryLineItemByTimeLiveDataQueryVariables> & ({ variables: ReadSummaryLineItemByTimeLiveDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSummaryLineItemByTimeLiveDataQuery, ReadSummaryLineItemByTimeLiveDataQueryVariables>(ReadSummaryLineItemByTimeLiveDataDocument, options);
      }
export function useReadSummaryLineItemByTimeLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSummaryLineItemByTimeLiveDataQuery, ReadSummaryLineItemByTimeLiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSummaryLineItemByTimeLiveDataQuery, ReadSummaryLineItemByTimeLiveDataQueryVariables>(ReadSummaryLineItemByTimeLiveDataDocument, options);
        }
export function useReadSummaryLineItemByTimeLiveDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSummaryLineItemByTimeLiveDataQuery, ReadSummaryLineItemByTimeLiveDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSummaryLineItemByTimeLiveDataQuery, ReadSummaryLineItemByTimeLiveDataQueryVariables>(ReadSummaryLineItemByTimeLiveDataDocument, options);
        }
export type ReadSummaryLineItemByTimeLiveDataQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeLiveDataQuery>;
export type ReadSummaryLineItemByTimeLiveDataLazyQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeLiveDataLazyQuery>;
export type ReadSummaryLineItemByTimeLiveDataSuspenseQueryHookResult = ReturnType<typeof useReadSummaryLineItemByTimeLiveDataSuspenseQuery>;
export type ReadSummaryLineItemByTimeLiveDataQueryResult = Apollo.QueryResult<ReadSummaryLineItemByTimeLiveDataQuery, ReadSummaryLineItemByTimeLiveDataQueryVariables>;
export const ReadActivityFeedDocument = gql`
    query ReadActivityFeed($legalEntityId: String!, $identifier: InputObjectIdentifier, $pagination: Pagination!) {
  activityFeed: readActivityFeed(
    legalEntityId: $legalEntityId
    identifier: $identifier
    page: $pagination
  ) {
    ...ActivityFeedFields
  }
}
    ${ActivityFeedFieldsFragmentDoc}`;

/**
 * __useReadActivityFeedQuery__
 *
 * To run a query within a React component, call `useReadActivityFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadActivityFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadActivityFeedQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      identifier: // value for 'identifier'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useReadActivityFeedQuery(baseOptions: Apollo.QueryHookOptions<ReadActivityFeedQuery, ReadActivityFeedQueryVariables> & ({ variables: ReadActivityFeedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadActivityFeedQuery, ReadActivityFeedQueryVariables>(ReadActivityFeedDocument, options);
      }
export function useReadActivityFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadActivityFeedQuery, ReadActivityFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadActivityFeedQuery, ReadActivityFeedQueryVariables>(ReadActivityFeedDocument, options);
        }
export function useReadActivityFeedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadActivityFeedQuery, ReadActivityFeedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadActivityFeedQuery, ReadActivityFeedQueryVariables>(ReadActivityFeedDocument, options);
        }
export type ReadActivityFeedQueryHookResult = ReturnType<typeof useReadActivityFeedQuery>;
export type ReadActivityFeedLazyQueryHookResult = ReturnType<typeof useReadActivityFeedLazyQuery>;
export type ReadActivityFeedSuspenseQueryHookResult = ReturnType<typeof useReadActivityFeedSuspenseQuery>;
export type ReadActivityFeedQueryResult = Apollo.QueryResult<ReadActivityFeedQuery, ReadActivityFeedQueryVariables>;
export const CloseWorkItemsDocument = gql`
    mutation CloseWorkItems($legalEntityId: String!, $closedWorkItems: [ClosedWorkItem!]!, $viewKey: ViewKey) {
  closeWorkItems(
    legalEntityId: $legalEntityId
    closedWorkItems: $closedWorkItems
    viewKey: $viewKey
  )
}
    `;
export type CloseWorkItemsMutationFn = Apollo.MutationFunction<CloseWorkItemsMutation, CloseWorkItemsMutationVariables>;

/**
 * __useCloseWorkItemsMutation__
 *
 * To run a mutation, you first call `useCloseWorkItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseWorkItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeWorkItemsMutation, { data, loading, error }] = useCloseWorkItemsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      closedWorkItems: // value for 'closedWorkItems'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useCloseWorkItemsMutation(baseOptions?: Apollo.MutationHookOptions<CloseWorkItemsMutation, CloseWorkItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseWorkItemsMutation, CloseWorkItemsMutationVariables>(CloseWorkItemsDocument, options);
      }
export type CloseWorkItemsMutationHookResult = ReturnType<typeof useCloseWorkItemsMutation>;
export type CloseWorkItemsMutationResult = Apollo.MutationResult<CloseWorkItemsMutation>;
export type CloseWorkItemsMutationOptions = Apollo.BaseMutationOptions<CloseWorkItemsMutation, CloseWorkItemsMutationVariables>;
export const PushWorkItemsDocument = gql`
    mutation PushWorkItems($legalEntityId: String!, $workItems: [WorkItemPushParams!]!) {
  pushWorkItems(legalEntityId: $legalEntityId, workItems: $workItems)
}
    `;
export type PushWorkItemsMutationFn = Apollo.MutationFunction<PushWorkItemsMutation, PushWorkItemsMutationVariables>;

/**
 * __usePushWorkItemsMutation__
 *
 * To run a mutation, you first call `usePushWorkItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushWorkItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushWorkItemsMutation, { data, loading, error }] = usePushWorkItemsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      workItems: // value for 'workItems'
 *   },
 * });
 */
export function usePushWorkItemsMutation(baseOptions?: Apollo.MutationHookOptions<PushWorkItemsMutation, PushWorkItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PushWorkItemsMutation, PushWorkItemsMutationVariables>(PushWorkItemsDocument, options);
      }
export type PushWorkItemsMutationHookResult = ReturnType<typeof usePushWorkItemsMutation>;
export type PushWorkItemsMutationResult = Apollo.MutationResult<PushWorkItemsMutation>;
export type PushWorkItemsMutationOptions = Apollo.BaseMutationOptions<PushWorkItemsMutation, PushWorkItemsMutationVariables>;
export const UpdateClientReviewStatesDocument = gql`
    mutation UpdateClientReviewStates($legalEntityId: String!, $responses: [WorkItemResponse!]!) {
  updateClientReviewStates(legalEntityId: $legalEntityId, responses: $responses)
}
    `;
export type UpdateClientReviewStatesMutationFn = Apollo.MutationFunction<UpdateClientReviewStatesMutation, UpdateClientReviewStatesMutationVariables>;

/**
 * __useUpdateClientReviewStatesMutation__
 *
 * To run a mutation, you first call `useUpdateClientReviewStatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientReviewStatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientReviewStatesMutation, { data, loading, error }] = useUpdateClientReviewStatesMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      responses: // value for 'responses'
 *   },
 * });
 */
export function useUpdateClientReviewStatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientReviewStatesMutation, UpdateClientReviewStatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientReviewStatesMutation, UpdateClientReviewStatesMutationVariables>(UpdateClientReviewStatesDocument, options);
      }
export type UpdateClientReviewStatesMutationHookResult = ReturnType<typeof useUpdateClientReviewStatesMutation>;
export type UpdateClientReviewStatesMutationResult = Apollo.MutationResult<UpdateClientReviewStatesMutation>;
export type UpdateClientReviewStatesMutationOptions = Apollo.BaseMutationOptions<UpdateClientReviewStatesMutation, UpdateClientReviewStatesMutationVariables>;
export const DeleteTransactionReviewQuestionDocument = gql`
    mutation DeleteTransactionReviewQuestion($organizationId: String!, $question: String!) {
  deleteTransactionReviewQuestion(
    organizationId: $organizationId
    question: $question
  )
}
    `;
export type DeleteTransactionReviewQuestionMutationFn = Apollo.MutationFunction<DeleteTransactionReviewQuestionMutation, DeleteTransactionReviewQuestionMutationVariables>;

/**
 * __useDeleteTransactionReviewQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteTransactionReviewQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransactionReviewQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransactionReviewQuestionMutation, { data, loading, error }] = useDeleteTransactionReviewQuestionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useDeleteTransactionReviewQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTransactionReviewQuestionMutation, DeleteTransactionReviewQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTransactionReviewQuestionMutation, DeleteTransactionReviewQuestionMutationVariables>(DeleteTransactionReviewQuestionDocument, options);
      }
export type DeleteTransactionReviewQuestionMutationHookResult = ReturnType<typeof useDeleteTransactionReviewQuestionMutation>;
export type DeleteTransactionReviewQuestionMutationResult = Apollo.MutationResult<DeleteTransactionReviewQuestionMutation>;
export type DeleteTransactionReviewQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteTransactionReviewQuestionMutation, DeleteTransactionReviewQuestionMutationVariables>;
export const UpdateTransactionReviewQuestionsDocument = gql`
    mutation UpdateTransactionReviewQuestions($organizationId: String!, $deletedQuestions: [String!], $newQuestions: [String!]) {
  updateTransactionReviewQuestions(
    organizationId: $organizationId
    deletedQuestions: $deletedQuestions
    newQuestions: $newQuestions
  )
}
    `;
export type UpdateTransactionReviewQuestionsMutationFn = Apollo.MutationFunction<UpdateTransactionReviewQuestionsMutation, UpdateTransactionReviewQuestionsMutationVariables>;

/**
 * __useUpdateTransactionReviewQuestionsMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionReviewQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionReviewQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionReviewQuestionsMutation, { data, loading, error }] = useUpdateTransactionReviewQuestionsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      deletedQuestions: // value for 'deletedQuestions'
 *      newQuestions: // value for 'newQuestions'
 *   },
 * });
 */
export function useUpdateTransactionReviewQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionReviewQuestionsMutation, UpdateTransactionReviewQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionReviewQuestionsMutation, UpdateTransactionReviewQuestionsMutationVariables>(UpdateTransactionReviewQuestionsDocument, options);
      }
export type UpdateTransactionReviewQuestionsMutationHookResult = ReturnType<typeof useUpdateTransactionReviewQuestionsMutation>;
export type UpdateTransactionReviewQuestionsMutationResult = Apollo.MutationResult<UpdateTransactionReviewQuestionsMutation>;
export type UpdateTransactionReviewQuestionsMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionReviewQuestionsMutation, UpdateTransactionReviewQuestionsMutationVariables>;
export const ReopenWorkItemDocument = gql`
    mutation ReopenWorkItem($legalEntityId: String!, $workItemId: String!) {
  reopenWorkItem(legalEntityId: $legalEntityId, workItemId: $workItemId)
}
    `;
export type ReopenWorkItemMutationFn = Apollo.MutationFunction<ReopenWorkItemMutation, ReopenWorkItemMutationVariables>;

/**
 * __useReopenWorkItemMutation__
 *
 * To run a mutation, you first call `useReopenWorkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenWorkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenWorkItemMutation, { data, loading, error }] = useReopenWorkItemMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useReopenWorkItemMutation(baseOptions?: Apollo.MutationHookOptions<ReopenWorkItemMutation, ReopenWorkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReopenWorkItemMutation, ReopenWorkItemMutationVariables>(ReopenWorkItemDocument, options);
      }
export type ReopenWorkItemMutationHookResult = ReturnType<typeof useReopenWorkItemMutation>;
export type ReopenWorkItemMutationResult = Apollo.MutationResult<ReopenWorkItemMutation>;
export type ReopenWorkItemMutationOptions = Apollo.BaseMutationOptions<ReopenWorkItemMutation, ReopenWorkItemMutationVariables>;
export const PushDigitsWorkItemsDocument = gql`
    mutation PushDigitsWorkItems($legalEntityId: String!, $workItems: [DigitsWorkItemPushParams!]!) {
  pushDigitsWorkItems(legalEntityId: $legalEntityId, workItems: $workItems)
}
    `;
export type PushDigitsWorkItemsMutationFn = Apollo.MutationFunction<PushDigitsWorkItemsMutation, PushDigitsWorkItemsMutationVariables>;

/**
 * __usePushDigitsWorkItemsMutation__
 *
 * To run a mutation, you first call `usePushDigitsWorkItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushDigitsWorkItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushDigitsWorkItemsMutation, { data, loading, error }] = usePushDigitsWorkItemsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      workItems: // value for 'workItems'
 *   },
 * });
 */
export function usePushDigitsWorkItemsMutation(baseOptions?: Apollo.MutationHookOptions<PushDigitsWorkItemsMutation, PushDigitsWorkItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PushDigitsWorkItemsMutation, PushDigitsWorkItemsMutationVariables>(PushDigitsWorkItemsDocument, options);
      }
export type PushDigitsWorkItemsMutationHookResult = ReturnType<typeof usePushDigitsWorkItemsMutation>;
export type PushDigitsWorkItemsMutationResult = Apollo.MutationResult<PushDigitsWorkItemsMutation>;
export type PushDigitsWorkItemsMutationOptions = Apollo.BaseMutationOptions<PushDigitsWorkItemsMutation, PushDigitsWorkItemsMutationVariables>;
export const UpdateAccountantReviewStatesDocument = gql`
    mutation UpdateAccountantReviewStates($legalEntityId: String!, $organizationId: String!, $period: PeriodInput!, $workItemSubjects: [WorkItemSubject!]!, $message: String) {
  updateAccountantReviewStates(
    legalEntityId: $legalEntityId
    organizationId: $organizationId
    period: $period
    workItemSubjects: $workItemSubjects
    message: $message
  )
}
    `;
export type UpdateAccountantReviewStatesMutationFn = Apollo.MutationFunction<UpdateAccountantReviewStatesMutation, UpdateAccountantReviewStatesMutationVariables>;

/**
 * __useUpdateAccountantReviewStatesMutation__
 *
 * To run a mutation, you first call `useUpdateAccountantReviewStatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountantReviewStatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountantReviewStatesMutation, { data, loading, error }] = useUpdateAccountantReviewStatesMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      organizationId: // value for 'organizationId'
 *      period: // value for 'period'
 *      workItemSubjects: // value for 'workItemSubjects'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUpdateAccountantReviewStatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountantReviewStatesMutation, UpdateAccountantReviewStatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountantReviewStatesMutation, UpdateAccountantReviewStatesMutationVariables>(UpdateAccountantReviewStatesDocument, options);
      }
export type UpdateAccountantReviewStatesMutationHookResult = ReturnType<typeof useUpdateAccountantReviewStatesMutation>;
export type UpdateAccountantReviewStatesMutationResult = Apollo.MutationResult<UpdateAccountantReviewStatesMutation>;
export type UpdateAccountantReviewStatesMutationOptions = Apollo.BaseMutationOptions<UpdateAccountantReviewStatesMutation, UpdateAccountantReviewStatesMutationVariables>;
export const HighlightWorkItemDocument = gql`
    query HighlightWorkItem($similarityType: SimilarityType!, $description: String, $name: String) {
  highlightWorkItem(
    similarityType: $similarityType
    description: $description
    name: $name
  ) {
    ...HighlightedTokensFields
  }
}
    ${HighlightedTokensFieldsFragmentDoc}`;

/**
 * __useHighlightWorkItemQuery__
 *
 * To run a query within a React component, call `useHighlightWorkItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighlightWorkItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlightWorkItemQuery({
 *   variables: {
 *      similarityType: // value for 'similarityType'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useHighlightWorkItemQuery(baseOptions: Apollo.QueryHookOptions<HighlightWorkItemQuery, HighlightWorkItemQueryVariables> & ({ variables: HighlightWorkItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>(HighlightWorkItemDocument, options);
      }
export function useHighlightWorkItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>(HighlightWorkItemDocument, options);
        }
export function useHighlightWorkItemSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>(HighlightWorkItemDocument, options);
        }
export type HighlightWorkItemQueryHookResult = ReturnType<typeof useHighlightWorkItemQuery>;
export type HighlightWorkItemLazyQueryHookResult = ReturnType<typeof useHighlightWorkItemLazyQuery>;
export type HighlightWorkItemSuspenseQueryHookResult = ReturnType<typeof useHighlightWorkItemSuspenseQuery>;
export type HighlightWorkItemQueryResult = Apollo.QueryResult<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>;
export const ListTransactionReviewItemsDocument = gql`
    query ListTransactionReviewItems($legalEntityId: String!, $filter: WorkItemFilter!, $intervalOrigin: IntervalOrigin!, $direction: DirectionFromOrigin!) {
  workItems: listWorkItems(
    filter: $filter
    origin: $intervalOrigin
    direction: $direction
  ) {
    ...WorkItemFields
  }
  rules: listAuditRules(legalEntityId: $legalEntityId) {
    ...AuditRuleFields
  }
  ruleStatuses: listAuditRuleStatuses(legalEntityId: $legalEntityId) {
    ...AuditRuleStatusFields
  }
}
    ${WorkItemFieldsFragmentDoc}
${AuditRuleFieldsFragmentDoc}
${AuditRuleStatusFieldsFragmentDoc}`;

/**
 * __useListTransactionReviewItemsQuery__
 *
 * To run a query within a React component, call `useListTransactionReviewItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionReviewItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionReviewItemsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      filter: // value for 'filter'
 *      intervalOrigin: // value for 'intervalOrigin'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useListTransactionReviewItemsQuery(baseOptions: Apollo.QueryHookOptions<ListTransactionReviewItemsQuery, ListTransactionReviewItemsQueryVariables> & ({ variables: ListTransactionReviewItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTransactionReviewItemsQuery, ListTransactionReviewItemsQueryVariables>(ListTransactionReviewItemsDocument, options);
      }
export function useListTransactionReviewItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionReviewItemsQuery, ListTransactionReviewItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTransactionReviewItemsQuery, ListTransactionReviewItemsQueryVariables>(ListTransactionReviewItemsDocument, options);
        }
export function useListTransactionReviewItemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListTransactionReviewItemsQuery, ListTransactionReviewItemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListTransactionReviewItemsQuery, ListTransactionReviewItemsQueryVariables>(ListTransactionReviewItemsDocument, options);
        }
export type ListTransactionReviewItemsQueryHookResult = ReturnType<typeof useListTransactionReviewItemsQuery>;
export type ListTransactionReviewItemsLazyQueryHookResult = ReturnType<typeof useListTransactionReviewItemsLazyQuery>;
export type ListTransactionReviewItemsSuspenseQueryHookResult = ReturnType<typeof useListTransactionReviewItemsSuspenseQuery>;
export type ListTransactionReviewItemsQueryResult = Apollo.QueryResult<ListTransactionReviewItemsQuery, ListTransactionReviewItemsQueryVariables>;
export const ListWorkItemsDocument = gql`
    query ListWorkItems($filter: WorkItemFilter!, $intervalOrigin: IntervalOrigin!, $direction: DirectionFromOrigin!) {
  listWorkItems(filter: $filter, origin: $intervalOrigin, direction: $direction) {
    ...WorkItemFields
  }
}
    ${WorkItemFieldsFragmentDoc}`;

/**
 * __useListWorkItemsQuery__
 *
 * To run a query within a React component, call `useListWorkItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      intervalOrigin: // value for 'intervalOrigin'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useListWorkItemsQuery(baseOptions: Apollo.QueryHookOptions<ListWorkItemsQuery, ListWorkItemsQueryVariables> & ({ variables: ListWorkItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkItemsQuery, ListWorkItemsQueryVariables>(ListWorkItemsDocument, options);
      }
export function useListWorkItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkItemsQuery, ListWorkItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkItemsQuery, ListWorkItemsQueryVariables>(ListWorkItemsDocument, options);
        }
export function useListWorkItemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListWorkItemsQuery, ListWorkItemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListWorkItemsQuery, ListWorkItemsQueryVariables>(ListWorkItemsDocument, options);
        }
export type ListWorkItemsQueryHookResult = ReturnType<typeof useListWorkItemsQuery>;
export type ListWorkItemsLazyQueryHookResult = ReturnType<typeof useListWorkItemsLazyQuery>;
export type ListWorkItemsSuspenseQueryHookResult = ReturnType<typeof useListWorkItemsSuspenseQuery>;
export type ListWorkItemsQueryResult = Apollo.QueryResult<ListWorkItemsQuery, ListWorkItemsQueryVariables>;
export const ListWorkItemsByIdsDocument = gql`
    query ListWorkItemsByIds($legalEntityId: String!, $workItemIds: [String!]!) {
  listWorkItemsByIds(legalEntityId: $legalEntityId, workItemIds: $workItemIds) {
    ...WorkItemFields
  }
}
    ${WorkItemFieldsFragmentDoc}`;

/**
 * __useListWorkItemsByIdsQuery__
 *
 * To run a query within a React component, call `useListWorkItemsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkItemsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkItemsByIdsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      workItemIds: // value for 'workItemIds'
 *   },
 * });
 */
export function useListWorkItemsByIdsQuery(baseOptions: Apollo.QueryHookOptions<ListWorkItemsByIdsQuery, ListWorkItemsByIdsQueryVariables> & ({ variables: ListWorkItemsByIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkItemsByIdsQuery, ListWorkItemsByIdsQueryVariables>(ListWorkItemsByIdsDocument, options);
      }
export function useListWorkItemsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkItemsByIdsQuery, ListWorkItemsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkItemsByIdsQuery, ListWorkItemsByIdsQueryVariables>(ListWorkItemsByIdsDocument, options);
        }
export function useListWorkItemsByIdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListWorkItemsByIdsQuery, ListWorkItemsByIdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListWorkItemsByIdsQuery, ListWorkItemsByIdsQueryVariables>(ListWorkItemsByIdsDocument, options);
        }
export type ListWorkItemsByIdsQueryHookResult = ReturnType<typeof useListWorkItemsByIdsQuery>;
export type ListWorkItemsByIdsLazyQueryHookResult = ReturnType<typeof useListWorkItemsByIdsLazyQuery>;
export type ListWorkItemsByIdsSuspenseQueryHookResult = ReturnType<typeof useListWorkItemsByIdsSuspenseQuery>;
export type ListWorkItemsByIdsQueryResult = Apollo.QueryResult<ListWorkItemsByIdsQuery, ListWorkItemsByIdsQueryVariables>;
export const SummarizeWorkItemsDocument = gql`
    query SummarizeWorkItems($filter: WorkItemFilter!, $intervalOrigin: IntervalOrigin!, $direction: DirectionFromOrigin!) {
  summarizeWorkItems(
    filter: $filter
    origin: $intervalOrigin
    direction: $direction
  ) {
    ...WorkItemSummaryFields
  }
}
    ${WorkItemSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeWorkItemsQuery__
 *
 * To run a query within a React component, call `useSummarizeWorkItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeWorkItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeWorkItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      intervalOrigin: // value for 'intervalOrigin'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useSummarizeWorkItemsQuery(baseOptions: Apollo.QueryHookOptions<SummarizeWorkItemsQuery, SummarizeWorkItemsQueryVariables> & ({ variables: SummarizeWorkItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeWorkItemsQuery, SummarizeWorkItemsQueryVariables>(SummarizeWorkItemsDocument, options);
      }
export function useSummarizeWorkItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeWorkItemsQuery, SummarizeWorkItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeWorkItemsQuery, SummarizeWorkItemsQueryVariables>(SummarizeWorkItemsDocument, options);
        }
export function useSummarizeWorkItemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeWorkItemsQuery, SummarizeWorkItemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeWorkItemsQuery, SummarizeWorkItemsQueryVariables>(SummarizeWorkItemsDocument, options);
        }
export type SummarizeWorkItemsQueryHookResult = ReturnType<typeof useSummarizeWorkItemsQuery>;
export type SummarizeWorkItemsLazyQueryHookResult = ReturnType<typeof useSummarizeWorkItemsLazyQuery>;
export type SummarizeWorkItemsSuspenseQueryHookResult = ReturnType<typeof useSummarizeWorkItemsSuspenseQuery>;
export type SummarizeWorkItemsQueryResult = Apollo.QueryResult<SummarizeWorkItemsQuery, SummarizeWorkItemsQueryVariables>;
export const ListTransactionReviewQuestionsDocument = gql`
    query ListTransactionReviewQuestions($organizationId: String!) {
  listTransactionReviewQuestions(organizationId: $organizationId) {
    ...TransactionReviewQuestionFields
  }
}
    ${TransactionReviewQuestionFieldsFragmentDoc}`;

/**
 * __useListTransactionReviewQuestionsQuery__
 *
 * To run a query within a React component, call `useListTransactionReviewQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionReviewQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionReviewQuestionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useListTransactionReviewQuestionsQuery(baseOptions: Apollo.QueryHookOptions<ListTransactionReviewQuestionsQuery, ListTransactionReviewQuestionsQueryVariables> & ({ variables: ListTransactionReviewQuestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTransactionReviewQuestionsQuery, ListTransactionReviewQuestionsQueryVariables>(ListTransactionReviewQuestionsDocument, options);
      }
export function useListTransactionReviewQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionReviewQuestionsQuery, ListTransactionReviewQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTransactionReviewQuestionsQuery, ListTransactionReviewQuestionsQueryVariables>(ListTransactionReviewQuestionsDocument, options);
        }
export function useListTransactionReviewQuestionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListTransactionReviewQuestionsQuery, ListTransactionReviewQuestionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListTransactionReviewQuestionsQuery, ListTransactionReviewQuestionsQueryVariables>(ListTransactionReviewQuestionsDocument, options);
        }
export type ListTransactionReviewQuestionsQueryHookResult = ReturnType<typeof useListTransactionReviewQuestionsQuery>;
export type ListTransactionReviewQuestionsLazyQueryHookResult = ReturnType<typeof useListTransactionReviewQuestionsLazyQuery>;
export type ListTransactionReviewQuestionsSuspenseQueryHookResult = ReturnType<typeof useListTransactionReviewQuestionsSuspenseQuery>;
export type ListTransactionReviewQuestionsQueryResult = Apollo.QueryResult<ListTransactionReviewQuestionsQuery, ListTransactionReviewQuestionsQueryVariables>;
export const UpdateAuditRulesDocument = gql`
    mutation UpdateAuditRules($legalEntityId: String!, $rules: [UpdateAuditRule!]!, $isFTE: Boolean, $usedSuggestedCategories: Boolean) {
  updateAuditRules(
    legalEntityId: $legalEntityId
    rules: $rules
    isFTE: $isFTE
    usedSuggestedCategories: $usedSuggestedCategories
  )
}
    `;
export type UpdateAuditRulesMutationFn = Apollo.MutationFunction<UpdateAuditRulesMutation, UpdateAuditRulesMutationVariables>;

/**
 * __useUpdateAuditRulesMutation__
 *
 * To run a mutation, you first call `useUpdateAuditRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuditRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuditRulesMutation, { data, loading, error }] = useUpdateAuditRulesMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      rules: // value for 'rules'
 *      isFTE: // value for 'isFTE'
 *      usedSuggestedCategories: // value for 'usedSuggestedCategories'
 *   },
 * });
 */
export function useUpdateAuditRulesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuditRulesMutation, UpdateAuditRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuditRulesMutation, UpdateAuditRulesMutationVariables>(UpdateAuditRulesDocument, options);
      }
export type UpdateAuditRulesMutationHookResult = ReturnType<typeof useUpdateAuditRulesMutation>;
export type UpdateAuditRulesMutationResult = Apollo.MutationResult<UpdateAuditRulesMutation>;
export type UpdateAuditRulesMutationOptions = Apollo.BaseMutationOptions<UpdateAuditRulesMutation, UpdateAuditRulesMutationVariables>;
export const ListAuditRulesDocument = gql`
    query ListAuditRules($legalEntityId: String!) {
  listAuditRules(legalEntityId: $legalEntityId) {
    ...AuditRuleFields
  }
}
    ${AuditRuleFieldsFragmentDoc}`;

/**
 * __useListAuditRulesQuery__
 *
 * To run a query within a React component, call `useListAuditRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAuditRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAuditRulesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListAuditRulesQuery(baseOptions: Apollo.QueryHookOptions<ListAuditRulesQuery, ListAuditRulesQueryVariables> & ({ variables: ListAuditRulesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAuditRulesQuery, ListAuditRulesQueryVariables>(ListAuditRulesDocument, options);
      }
export function useListAuditRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAuditRulesQuery, ListAuditRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAuditRulesQuery, ListAuditRulesQueryVariables>(ListAuditRulesDocument, options);
        }
export function useListAuditRulesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAuditRulesQuery, ListAuditRulesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAuditRulesQuery, ListAuditRulesQueryVariables>(ListAuditRulesDocument, options);
        }
export type ListAuditRulesQueryHookResult = ReturnType<typeof useListAuditRulesQuery>;
export type ListAuditRulesLazyQueryHookResult = ReturnType<typeof useListAuditRulesLazyQuery>;
export type ListAuditRulesSuspenseQueryHookResult = ReturnType<typeof useListAuditRulesSuspenseQuery>;
export type ListAuditRulesQueryResult = Apollo.QueryResult<ListAuditRulesQuery, ListAuditRulesQueryVariables>;
export const ListShareSuggestedUsersDocument = gql`
    query ListShareSuggestedUsers($legalEntityId: String!) {
  listShareSuggestedUsers(legalEntityId: $legalEntityId) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useListShareSuggestedUsersQuery__
 *
 * To run a query within a React component, call `useListShareSuggestedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShareSuggestedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShareSuggestedUsersQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListShareSuggestedUsersQuery(baseOptions: Apollo.QueryHookOptions<ListShareSuggestedUsersQuery, ListShareSuggestedUsersQueryVariables> & ({ variables: ListShareSuggestedUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListShareSuggestedUsersQuery, ListShareSuggestedUsersQueryVariables>(ListShareSuggestedUsersDocument, options);
      }
export function useListShareSuggestedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListShareSuggestedUsersQuery, ListShareSuggestedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListShareSuggestedUsersQuery, ListShareSuggestedUsersQueryVariables>(ListShareSuggestedUsersDocument, options);
        }
export function useListShareSuggestedUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListShareSuggestedUsersQuery, ListShareSuggestedUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListShareSuggestedUsersQuery, ListShareSuggestedUsersQueryVariables>(ListShareSuggestedUsersDocument, options);
        }
export type ListShareSuggestedUsersQueryHookResult = ReturnType<typeof useListShareSuggestedUsersQuery>;
export type ListShareSuggestedUsersLazyQueryHookResult = ReturnType<typeof useListShareSuggestedUsersLazyQuery>;
export type ListShareSuggestedUsersSuspenseQueryHookResult = ReturnType<typeof useListShareSuggestedUsersSuspenseQuery>;
export type ListShareSuggestedUsersQueryResult = Apollo.QueryResult<ListShareSuggestedUsersQuery, ListShareSuggestedUsersQueryVariables>;
export const ListLimitedSuggestedUsersDocument = gql`
    query ListLimitedSuggestedUsers($identifier: InputObjectIdentifier!) {
  listLimitedSuggestedUsers(object: $identifier) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useListLimitedSuggestedUsersQuery__
 *
 * To run a query within a React component, call `useListLimitedSuggestedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLimitedSuggestedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLimitedSuggestedUsersQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useListLimitedSuggestedUsersQuery(baseOptions: Apollo.QueryHookOptions<ListLimitedSuggestedUsersQuery, ListLimitedSuggestedUsersQueryVariables> & ({ variables: ListLimitedSuggestedUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLimitedSuggestedUsersQuery, ListLimitedSuggestedUsersQueryVariables>(ListLimitedSuggestedUsersDocument, options);
      }
export function useListLimitedSuggestedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLimitedSuggestedUsersQuery, ListLimitedSuggestedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLimitedSuggestedUsersQuery, ListLimitedSuggestedUsersQueryVariables>(ListLimitedSuggestedUsersDocument, options);
        }
export function useListLimitedSuggestedUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListLimitedSuggestedUsersQuery, ListLimitedSuggestedUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListLimitedSuggestedUsersQuery, ListLimitedSuggestedUsersQueryVariables>(ListLimitedSuggestedUsersDocument, options);
        }
export type ListLimitedSuggestedUsersQueryHookResult = ReturnType<typeof useListLimitedSuggestedUsersQuery>;
export type ListLimitedSuggestedUsersLazyQueryHookResult = ReturnType<typeof useListLimitedSuggestedUsersLazyQuery>;
export type ListLimitedSuggestedUsersSuspenseQueryHookResult = ReturnType<typeof useListLimitedSuggestedUsersSuspenseQuery>;
export type ListLimitedSuggestedUsersQueryResult = Apollo.QueryResult<ListLimitedSuggestedUsersQuery, ListLimitedSuggestedUsersQueryVariables>;
export const ListSuggestedUsersDocument = gql`
    query ListSuggestedUsers($organizationId: String!, $identifier: InputObjectIdentifier!) {
  listSuggestedUsers(organizationId: $organizationId, object: $identifier) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useListSuggestedUsersQuery__
 *
 * To run a query within a React component, call `useListSuggestedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuggestedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuggestedUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useListSuggestedUsersQuery(baseOptions: Apollo.QueryHookOptions<ListSuggestedUsersQuery, ListSuggestedUsersQueryVariables> & ({ variables: ListSuggestedUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSuggestedUsersQuery, ListSuggestedUsersQueryVariables>(ListSuggestedUsersDocument, options);
      }
export function useListSuggestedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSuggestedUsersQuery, ListSuggestedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSuggestedUsersQuery, ListSuggestedUsersQueryVariables>(ListSuggestedUsersDocument, options);
        }
export function useListSuggestedUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListSuggestedUsersQuery, ListSuggestedUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListSuggestedUsersQuery, ListSuggestedUsersQueryVariables>(ListSuggestedUsersDocument, options);
        }
export type ListSuggestedUsersQueryHookResult = ReturnType<typeof useListSuggestedUsersQuery>;
export type ListSuggestedUsersLazyQueryHookResult = ReturnType<typeof useListSuggestedUsersLazyQuery>;
export type ListSuggestedUsersSuspenseQueryHookResult = ReturnType<typeof useListSuggestedUsersSuspenseQuery>;
export type ListSuggestedUsersQueryResult = Apollo.QueryResult<ListSuggestedUsersQuery, ListSuggestedUsersQueryVariables>;
export const ListAccountantSuggestedUsersDocument = gql`
    query ListAccountantSuggestedUsers($organizationId: String!, $identifier: InputObjectIdentifier!) {
  listAccountantSuggestedUsers(
    organizationId: $organizationId
    object: $identifier
  ) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useListAccountantSuggestedUsersQuery__
 *
 * To run a query within a React component, call `useListAccountantSuggestedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountantSuggestedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountantSuggestedUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useListAccountantSuggestedUsersQuery(baseOptions: Apollo.QueryHookOptions<ListAccountantSuggestedUsersQuery, ListAccountantSuggestedUsersQueryVariables> & ({ variables: ListAccountantSuggestedUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAccountantSuggestedUsersQuery, ListAccountantSuggestedUsersQueryVariables>(ListAccountantSuggestedUsersDocument, options);
      }
export function useListAccountantSuggestedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAccountantSuggestedUsersQuery, ListAccountantSuggestedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAccountantSuggestedUsersQuery, ListAccountantSuggestedUsersQueryVariables>(ListAccountantSuggestedUsersDocument, options);
        }
export function useListAccountantSuggestedUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAccountantSuggestedUsersQuery, ListAccountantSuggestedUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAccountantSuggestedUsersQuery, ListAccountantSuggestedUsersQueryVariables>(ListAccountantSuggestedUsersDocument, options);
        }
export type ListAccountantSuggestedUsersQueryHookResult = ReturnType<typeof useListAccountantSuggestedUsersQuery>;
export type ListAccountantSuggestedUsersLazyQueryHookResult = ReturnType<typeof useListAccountantSuggestedUsersLazyQuery>;
export type ListAccountantSuggestedUsersSuspenseQueryHookResult = ReturnType<typeof useListAccountantSuggestedUsersSuspenseQuery>;
export type ListAccountantSuggestedUsersQueryResult = Apollo.QueryResult<ListAccountantSuggestedUsersQuery, ListAccountantSuggestedUsersQueryVariables>;
export const ListInvitationProposalsDocument = gql`
    query ListInvitationProposals($type: ProposalType!) {
  listInvitationProposals(proposalType: $type) {
    ...InvitationProposalFields
  }
}
    ${InvitationProposalFieldsFragmentDoc}`;

/**
 * __useListInvitationProposalsQuery__
 *
 * To run a query within a React component, call `useListInvitationProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvitationProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvitationProposalsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListInvitationProposalsQuery(baseOptions: Apollo.QueryHookOptions<ListInvitationProposalsQuery, ListInvitationProposalsQueryVariables> & ({ variables: ListInvitationProposalsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInvitationProposalsQuery, ListInvitationProposalsQueryVariables>(ListInvitationProposalsDocument, options);
      }
export function useListInvitationProposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInvitationProposalsQuery, ListInvitationProposalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInvitationProposalsQuery, ListInvitationProposalsQueryVariables>(ListInvitationProposalsDocument, options);
        }
export function useListInvitationProposalsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListInvitationProposalsQuery, ListInvitationProposalsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListInvitationProposalsQuery, ListInvitationProposalsQueryVariables>(ListInvitationProposalsDocument, options);
        }
export type ListInvitationProposalsQueryHookResult = ReturnType<typeof useListInvitationProposalsQuery>;
export type ListInvitationProposalsLazyQueryHookResult = ReturnType<typeof useListInvitationProposalsLazyQuery>;
export type ListInvitationProposalsSuspenseQueryHookResult = ReturnType<typeof useListInvitationProposalsSuspenseQuery>;
export type ListInvitationProposalsQueryResult = Apollo.QueryResult<ListInvitationProposalsQuery, ListInvitationProposalsQueryVariables>;
export const ReadInvitationProposalDocument = gql`
    query ReadInvitationProposal($proposalId: String!) {
  readInvitationProposal(proposalId: $proposalId) {
    ...InvitationProposalFields
  }
}
    ${InvitationProposalFieldsFragmentDoc}`;

/**
 * __useReadInvitationProposalQuery__
 *
 * To run a query within a React component, call `useReadInvitationProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInvitationProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInvitationProposalQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useReadInvitationProposalQuery(baseOptions: Apollo.QueryHookOptions<ReadInvitationProposalQuery, ReadInvitationProposalQueryVariables> & ({ variables: ReadInvitationProposalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInvitationProposalQuery, ReadInvitationProposalQueryVariables>(ReadInvitationProposalDocument, options);
      }
export function useReadInvitationProposalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInvitationProposalQuery, ReadInvitationProposalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInvitationProposalQuery, ReadInvitationProposalQueryVariables>(ReadInvitationProposalDocument, options);
        }
export function useReadInvitationProposalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadInvitationProposalQuery, ReadInvitationProposalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadInvitationProposalQuery, ReadInvitationProposalQueryVariables>(ReadInvitationProposalDocument, options);
        }
export type ReadInvitationProposalQueryHookResult = ReturnType<typeof useReadInvitationProposalQuery>;
export type ReadInvitationProposalLazyQueryHookResult = ReturnType<typeof useReadInvitationProposalLazyQuery>;
export type ReadInvitationProposalSuspenseQueryHookResult = ReturnType<typeof useReadInvitationProposalSuspenseQuery>;
export type ReadInvitationProposalQueryResult = Apollo.QueryResult<ReadInvitationProposalQuery, ReadInvitationProposalQueryVariables>;
export const UpdateObjectMappingsDocument = gql`
    mutation UpdateObjectMappings($legalEntityId: String!, $mappedTo: ObjectKind!, $lineClassIds: [String!]!, $departmentIds: [String!]!) {
  updateObjectMappings(
    legalEntityId: $legalEntityId
    mappedTo: $mappedTo
    lineClassIds: $lineClassIds
    departmentIds: $departmentIds
  ) {
    lineClassHierarchy {
      ...ObjectMappingHierarchyNodeFields
    }
    departmentHierarchy {
      ...ObjectMappingHierarchyNodeFields
    }
  }
}
    ${ObjectMappingHierarchyNodeFieldsFragmentDoc}`;
export type UpdateObjectMappingsMutationFn = Apollo.MutationFunction<UpdateObjectMappingsMutation, UpdateObjectMappingsMutationVariables>;

/**
 * __useUpdateObjectMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateObjectMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectMappingsMutation, { data, loading, error }] = useUpdateObjectMappingsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      mappedTo: // value for 'mappedTo'
 *      lineClassIds: // value for 'lineClassIds'
 *      departmentIds: // value for 'departmentIds'
 *   },
 * });
 */
export function useUpdateObjectMappingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateObjectMappingsMutation, UpdateObjectMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateObjectMappingsMutation, UpdateObjectMappingsMutationVariables>(UpdateObjectMappingsDocument, options);
      }
export type UpdateObjectMappingsMutationHookResult = ReturnType<typeof useUpdateObjectMappingsMutation>;
export type UpdateObjectMappingsMutationResult = Apollo.MutationResult<UpdateObjectMappingsMutation>;
export type UpdateObjectMappingsMutationOptions = Apollo.BaseMutationOptions<UpdateObjectMappingsMutation, UpdateObjectMappingsMutationVariables>;
export const ListLineClassFactHierarchyDocument = gql`
    query ListLineClassFactHierarchy($legalEntityId: String!) {
  listLineClassFactHierarchy(legalEntityId: $legalEntityId) {
    ...ObjectMappingHierarchyNodeFields
  }
}
    ${ObjectMappingHierarchyNodeFieldsFragmentDoc}`;

/**
 * __useListLineClassFactHierarchyQuery__
 *
 * To run a query within a React component, call `useListLineClassFactHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLineClassFactHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLineClassFactHierarchyQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListLineClassFactHierarchyQuery(baseOptions: Apollo.QueryHookOptions<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables> & ({ variables: ListLineClassFactHierarchyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>(ListLineClassFactHierarchyDocument, options);
      }
export function useListLineClassFactHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>(ListLineClassFactHierarchyDocument, options);
        }
export function useListLineClassFactHierarchySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>(ListLineClassFactHierarchyDocument, options);
        }
export type ListLineClassFactHierarchyQueryHookResult = ReturnType<typeof useListLineClassFactHierarchyQuery>;
export type ListLineClassFactHierarchyLazyQueryHookResult = ReturnType<typeof useListLineClassFactHierarchyLazyQuery>;
export type ListLineClassFactHierarchySuspenseQueryHookResult = ReturnType<typeof useListLineClassFactHierarchySuspenseQuery>;
export type ListLineClassFactHierarchyQueryResult = Apollo.QueryResult<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>;
export const ListDepartmentFactHierarchyDocument = gql`
    query ListDepartmentFactHierarchy($legalEntityId: String!) {
  listDepartmentFactHierarchy(legalEntityId: $legalEntityId) {
    ...ObjectMappingHierarchyNodeFields
  }
}
    ${ObjectMappingHierarchyNodeFieldsFragmentDoc}`;

/**
 * __useListDepartmentFactHierarchyQuery__
 *
 * To run a query within a React component, call `useListDepartmentFactHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDepartmentFactHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDepartmentFactHierarchyQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListDepartmentFactHierarchyQuery(baseOptions: Apollo.QueryHookOptions<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables> & ({ variables: ListDepartmentFactHierarchyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>(ListDepartmentFactHierarchyDocument, options);
      }
export function useListDepartmentFactHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>(ListDepartmentFactHierarchyDocument, options);
        }
export function useListDepartmentFactHierarchySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>(ListDepartmentFactHierarchyDocument, options);
        }
export type ListDepartmentFactHierarchyQueryHookResult = ReturnType<typeof useListDepartmentFactHierarchyQuery>;
export type ListDepartmentFactHierarchyLazyQueryHookResult = ReturnType<typeof useListDepartmentFactHierarchyLazyQuery>;
export type ListDepartmentFactHierarchySuspenseQueryHookResult = ReturnType<typeof useListDepartmentFactHierarchySuspenseQuery>;
export type ListDepartmentFactHierarchyQueryResult = Apollo.QueryResult<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>;
export const UpdateOAuthCredentialDocument = gql`
    mutation UpdateOAuthCredential($code: String!, $service: OAuthService!) {
  updateOAuthCredential(code: $code, service: $service)
}
    `;
export type UpdateOAuthCredentialMutationFn = Apollo.MutationFunction<UpdateOAuthCredentialMutation, UpdateOAuthCredentialMutationVariables>;

/**
 * __useUpdateOAuthCredentialMutation__
 *
 * To run a mutation, you first call `useUpdateOAuthCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOAuthCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOAuthCredentialMutation, { data, loading, error }] = useUpdateOAuthCredentialMutation({
 *   variables: {
 *      code: // value for 'code'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useUpdateOAuthCredentialMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOAuthCredentialMutation, UpdateOAuthCredentialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOAuthCredentialMutation, UpdateOAuthCredentialMutationVariables>(UpdateOAuthCredentialDocument, options);
      }
export type UpdateOAuthCredentialMutationHookResult = ReturnType<typeof useUpdateOAuthCredentialMutation>;
export type UpdateOAuthCredentialMutationResult = Apollo.MutationResult<UpdateOAuthCredentialMutation>;
export type UpdateOAuthCredentialMutationOptions = Apollo.BaseMutationOptions<UpdateOAuthCredentialMutation, UpdateOAuthCredentialMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($givenName: String, $familyName: String, $emailAddress: String) {
  updateUser(
    givenName: $givenName
    familyName: $familyName
    emailAddress: $emailAddress
  )
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AcceptTermsDocument = gql`
    mutation AcceptTerms($version: String!) {
  acceptTerms(version: $version)
}
    `;
export type AcceptTermsMutationFn = Apollo.MutationFunction<AcceptTermsMutation, AcceptTermsMutationVariables>;

/**
 * __useAcceptTermsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsMutation, { data, loading, error }] = useAcceptTermsMutation({
 *   variables: {
 *      version: // value for 'version'
 *   },
 * });
 */
export function useAcceptTermsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTermsMutation, AcceptTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTermsMutation, AcceptTermsMutationVariables>(AcceptTermsDocument, options);
      }
export type AcceptTermsMutationHookResult = ReturnType<typeof useAcceptTermsMutation>;
export type AcceptTermsMutationResult = Apollo.MutationResult<AcceptTermsMutation>;
export type AcceptTermsMutationOptions = Apollo.BaseMutationOptions<AcceptTermsMutation, AcceptTermsMutationVariables>;
export const CreatePasswordResetDocument = gql`
    mutation CreatePasswordReset($emailAddress: String!) {
  createPasswordReset(emailAddress: $emailAddress)
}
    `;
export type CreatePasswordResetMutationFn = Apollo.MutationFunction<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>;

/**
 * __useCreatePasswordResetMutation__
 *
 * To run a mutation, you first call `useCreatePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordResetMutation, { data, loading, error }] = useCreatePasswordResetMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useCreatePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>(CreatePasswordResetDocument, options);
      }
export type CreatePasswordResetMutationHookResult = ReturnType<typeof useCreatePasswordResetMutation>;
export type CreatePasswordResetMutationResult = Apollo.MutationResult<CreatePasswordResetMutation>;
export type CreatePasswordResetMutationOptions = Apollo.BaseMutationOptions<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>;
export const SetDefaultApproverDocument = gql`
    mutation SetDefaultApprover($legalEntityId: String!, $userId: String) {
  setDefaultApprover(legalEntityId: $legalEntityId, userId: $userId)
}
    `;
export type SetDefaultApproverMutationFn = Apollo.MutationFunction<SetDefaultApproverMutation, SetDefaultApproverMutationVariables>;

/**
 * __useSetDefaultApproverMutation__
 *
 * To run a mutation, you first call `useSetDefaultApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultApproverMutation, { data, loading, error }] = useSetDefaultApproverMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetDefaultApproverMutation(baseOptions?: Apollo.MutationHookOptions<SetDefaultApproverMutation, SetDefaultApproverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDefaultApproverMutation, SetDefaultApproverMutationVariables>(SetDefaultApproverDocument, options);
      }
export type SetDefaultApproverMutationHookResult = ReturnType<typeof useSetDefaultApproverMutation>;
export type SetDefaultApproverMutationResult = Apollo.MutationResult<SetDefaultApproverMutation>;
export type SetDefaultApproverMutationOptions = Apollo.BaseMutationOptions<SetDefaultApproverMutation, SetDefaultApproverMutationVariables>;
export const EmailOAuthProviderDocument = gql`
    query EmailOAuthProvider {
  emailOAuthProvider
}
    `;

/**
 * __useEmailOAuthProviderQuery__
 *
 * To run a query within a React component, call `useEmailOAuthProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailOAuthProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailOAuthProviderQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailOAuthProviderQuery(baseOptions?: Apollo.QueryHookOptions<EmailOAuthProviderQuery, EmailOAuthProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailOAuthProviderQuery, EmailOAuthProviderQueryVariables>(EmailOAuthProviderDocument, options);
      }
export function useEmailOAuthProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailOAuthProviderQuery, EmailOAuthProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailOAuthProviderQuery, EmailOAuthProviderQueryVariables>(EmailOAuthProviderDocument, options);
        }
export function useEmailOAuthProviderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailOAuthProviderQuery, EmailOAuthProviderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailOAuthProviderQuery, EmailOAuthProviderQueryVariables>(EmailOAuthProviderDocument, options);
        }
export type EmailOAuthProviderQueryHookResult = ReturnType<typeof useEmailOAuthProviderQuery>;
export type EmailOAuthProviderLazyQueryHookResult = ReturnType<typeof useEmailOAuthProviderLazyQuery>;
export type EmailOAuthProviderSuspenseQueryHookResult = ReturnType<typeof useEmailOAuthProviderSuspenseQuery>;
export type EmailOAuthProviderQueryResult = Apollo.QueryResult<EmailOAuthProviderQuery, EmailOAuthProviderQueryVariables>;
export const ReadUserDocument = gql`
    query ReadUser($id: String!) {
  readUser(id: $id) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useReadUserQuery__
 *
 * To run a query within a React component, call `useReadUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadUserQuery(baseOptions: Apollo.QueryHookOptions<ReadUserQuery, ReadUserQueryVariables> & ({ variables: ReadUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, options);
      }
export function useReadUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserQuery, ReadUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, options);
        }
export function useReadUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadUserQuery, ReadUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, options);
        }
export type ReadUserQueryHookResult = ReturnType<typeof useReadUserQuery>;
export type ReadUserLazyQueryHookResult = ReturnType<typeof useReadUserLazyQuery>;
export type ReadUserSuspenseQueryHookResult = ReturnType<typeof useReadUserSuspenseQuery>;
export type ReadUserQueryResult = Apollo.QueryResult<ReadUserQuery, ReadUserQueryVariables>;
export const ReadDefaultApproverDocument = gql`
    query ReadDefaultApprover($legalEntityId: String!) {
  readDefaultApprover(legalEntityId: $legalEntityId)
}
    `;

/**
 * __useReadDefaultApproverQuery__
 *
 * To run a query within a React component, call `useReadDefaultApproverQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDefaultApproverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDefaultApproverQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadDefaultApproverQuery(baseOptions: Apollo.QueryHookOptions<ReadDefaultApproverQuery, ReadDefaultApproverQueryVariables> & ({ variables: ReadDefaultApproverQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDefaultApproverQuery, ReadDefaultApproverQueryVariables>(ReadDefaultApproverDocument, options);
      }
export function useReadDefaultApproverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDefaultApproverQuery, ReadDefaultApproverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDefaultApproverQuery, ReadDefaultApproverQueryVariables>(ReadDefaultApproverDocument, options);
        }
export function useReadDefaultApproverSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadDefaultApproverQuery, ReadDefaultApproverQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadDefaultApproverQuery, ReadDefaultApproverQueryVariables>(ReadDefaultApproverDocument, options);
        }
export type ReadDefaultApproverQueryHookResult = ReturnType<typeof useReadDefaultApproverQuery>;
export type ReadDefaultApproverLazyQueryHookResult = ReturnType<typeof useReadDefaultApproverLazyQuery>;
export type ReadDefaultApproverSuspenseQueryHookResult = ReturnType<typeof useReadDefaultApproverSuspenseQuery>;
export type ReadDefaultApproverQueryResult = Apollo.QueryResult<ReadDefaultApproverQuery, ReadDefaultApproverQueryVariables>;
export const ReadIdentityTokenDocument = gql`
    query ReadIdentityToken($service: TokenIdentityService!) {
  tokenIdentityProvider(service: $service)
}
    `;

/**
 * __useReadIdentityTokenQuery__
 *
 * To run a query within a React component, call `useReadIdentityTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadIdentityTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadIdentityTokenQuery({
 *   variables: {
 *      service: // value for 'service'
 *   },
 * });
 */
export function useReadIdentityTokenQuery(baseOptions: Apollo.QueryHookOptions<ReadIdentityTokenQuery, ReadIdentityTokenQueryVariables> & ({ variables: ReadIdentityTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadIdentityTokenQuery, ReadIdentityTokenQueryVariables>(ReadIdentityTokenDocument, options);
      }
export function useReadIdentityTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadIdentityTokenQuery, ReadIdentityTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadIdentityTokenQuery, ReadIdentityTokenQueryVariables>(ReadIdentityTokenDocument, options);
        }
export function useReadIdentityTokenSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadIdentityTokenQuery, ReadIdentityTokenQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadIdentityTokenQuery, ReadIdentityTokenQueryVariables>(ReadIdentityTokenDocument, options);
        }
export type ReadIdentityTokenQueryHookResult = ReturnType<typeof useReadIdentityTokenQuery>;
export type ReadIdentityTokenLazyQueryHookResult = ReturnType<typeof useReadIdentityTokenLazyQuery>;
export type ReadIdentityTokenSuspenseQueryHookResult = ReturnType<typeof useReadIdentityTokenSuspenseQuery>;
export type ReadIdentityTokenQueryResult = Apollo.QueryResult<ReadIdentityTokenQuery, ReadIdentityTokenQueryVariables>;
export const ReadDepartmentDocument = gql`
    query ReadDepartment($viewKey: ViewKey!, $departmentId: String!) {
  readDepartment(viewKey: $viewKey, departmentId: $departmentId) {
    ...DepartmentFields
  }
}
    ${DepartmentFieldsFragmentDoc}`;

/**
 * __useReadDepartmentQuery__
 *
 * To run a query within a React component, call `useReadDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDepartmentQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      departmentId: // value for 'departmentId'
 *   },
 * });
 */
export function useReadDepartmentQuery(baseOptions: Apollo.QueryHookOptions<ReadDepartmentQuery, ReadDepartmentQueryVariables> & ({ variables: ReadDepartmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDepartmentQuery, ReadDepartmentQueryVariables>(ReadDepartmentDocument, options);
      }
export function useReadDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDepartmentQuery, ReadDepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDepartmentQuery, ReadDepartmentQueryVariables>(ReadDepartmentDocument, options);
        }
export function useReadDepartmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadDepartmentQuery, ReadDepartmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadDepartmentQuery, ReadDepartmentQueryVariables>(ReadDepartmentDocument, options);
        }
export type ReadDepartmentQueryHookResult = ReturnType<typeof useReadDepartmentQuery>;
export type ReadDepartmentLazyQueryHookResult = ReturnType<typeof useReadDepartmentLazyQuery>;
export type ReadDepartmentSuspenseQueryHookResult = ReturnType<typeof useReadDepartmentSuspenseQuery>;
export type ReadDepartmentQueryResult = Apollo.QueryResult<ReadDepartmentQuery, ReadDepartmentQueryVariables>;
export const ListDepartmentsDocument = gql`
    query ListDepartments($viewKey: ViewKey!) {
  listDepartments(viewKey: $viewKey) {
    ...DepartmentFields
  }
}
    ${DepartmentFieldsFragmentDoc}`;

/**
 * __useListDepartmentsQuery__
 *
 * To run a query within a React component, call `useListDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDepartmentsQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useListDepartmentsQuery(baseOptions: Apollo.QueryHookOptions<ListDepartmentsQuery, ListDepartmentsQueryVariables> & ({ variables: ListDepartmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDepartmentsQuery, ListDepartmentsQueryVariables>(ListDepartmentsDocument, options);
      }
export function useListDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDepartmentsQuery, ListDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDepartmentsQuery, ListDepartmentsQueryVariables>(ListDepartmentsDocument, options);
        }
export function useListDepartmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDepartmentsQuery, ListDepartmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDepartmentsQuery, ListDepartmentsQueryVariables>(ListDepartmentsDocument, options);
        }
export type ListDepartmentsQueryHookResult = ReturnType<typeof useListDepartmentsQuery>;
export type ListDepartmentsLazyQueryHookResult = ReturnType<typeof useListDepartmentsLazyQuery>;
export type ListDepartmentsSuspenseQueryHookResult = ReturnType<typeof useListDepartmentsSuspenseQuery>;
export type ListDepartmentsQueryResult = Apollo.QueryResult<ListDepartmentsQuery, ListDepartmentsQueryVariables>;
export const UpdateTransactionReviewReminderMessageDocument = gql`
    mutation UpdateTransactionReviewReminderMessage($legalEntityId: String!, $message: String!) {
  updateTransactionReviewReminderMessage(
    legalEntityId: $legalEntityId
    message: $message
  )
}
    `;
export type UpdateTransactionReviewReminderMessageMutationFn = Apollo.MutationFunction<UpdateTransactionReviewReminderMessageMutation, UpdateTransactionReviewReminderMessageMutationVariables>;

/**
 * __useUpdateTransactionReviewReminderMessageMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionReviewReminderMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionReviewReminderMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionReviewReminderMessageMutation, { data, loading, error }] = useUpdateTransactionReviewReminderMessageMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUpdateTransactionReviewReminderMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionReviewReminderMessageMutation, UpdateTransactionReviewReminderMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionReviewReminderMessageMutation, UpdateTransactionReviewReminderMessageMutationVariables>(UpdateTransactionReviewReminderMessageDocument, options);
      }
export type UpdateTransactionReviewReminderMessageMutationHookResult = ReturnType<typeof useUpdateTransactionReviewReminderMessageMutation>;
export type UpdateTransactionReviewReminderMessageMutationResult = Apollo.MutationResult<UpdateTransactionReviewReminderMessageMutation>;
export type UpdateTransactionReviewReminderMessageMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionReviewReminderMessageMutation, UpdateTransactionReviewReminderMessageMutationVariables>;
export const SendTransactionReviewReminderDocument = gql`
    mutation SendTransactionReviewReminder($legalEntityId: String!, $message: String!) {
  sendTransactionReviewReminder(legalEntityId: $legalEntityId, message: $message)
}
    `;
export type SendTransactionReviewReminderMutationFn = Apollo.MutationFunction<SendTransactionReviewReminderMutation, SendTransactionReviewReminderMutationVariables>;

/**
 * __useSendTransactionReviewReminderMutation__
 *
 * To run a mutation, you first call `useSendTransactionReviewReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTransactionReviewReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTransactionReviewReminderMutation, { data, loading, error }] = useSendTransactionReviewReminderMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendTransactionReviewReminderMutation(baseOptions?: Apollo.MutationHookOptions<SendTransactionReviewReminderMutation, SendTransactionReviewReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTransactionReviewReminderMutation, SendTransactionReviewReminderMutationVariables>(SendTransactionReviewReminderDocument, options);
      }
export type SendTransactionReviewReminderMutationHookResult = ReturnType<typeof useSendTransactionReviewReminderMutation>;
export type SendTransactionReviewReminderMutationResult = Apollo.MutationResult<SendTransactionReviewReminderMutation>;
export type SendTransactionReviewReminderMutationOptions = Apollo.BaseMutationOptions<SendTransactionReviewReminderMutation, SendTransactionReviewReminderMutationVariables>;
export const UpdateTransactionReviewScheduleDocument = gql`
    mutation UpdateTransactionReviewSchedule($legalEntityId: String!, $daysOfWeek: [DayOfWeek!], $daysOfMonth: [DayOfMonth!], $enabled: Boolean!) {
  updateTransactionReviewSchedule(
    legalEntityId: $legalEntityId
    daysOfWeek: $daysOfWeek
    daysOfMonth: $daysOfMonth
    enabled: $enabled
  )
}
    `;
export type UpdateTransactionReviewScheduleMutationFn = Apollo.MutationFunction<UpdateTransactionReviewScheduleMutation, UpdateTransactionReviewScheduleMutationVariables>;

/**
 * __useUpdateTransactionReviewScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionReviewScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionReviewScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionReviewScheduleMutation, { data, loading, error }] = useUpdateTransactionReviewScheduleMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      daysOfWeek: // value for 'daysOfWeek'
 *      daysOfMonth: // value for 'daysOfMonth'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateTransactionReviewScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionReviewScheduleMutation, UpdateTransactionReviewScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionReviewScheduleMutation, UpdateTransactionReviewScheduleMutationVariables>(UpdateTransactionReviewScheduleDocument, options);
      }
export type UpdateTransactionReviewScheduleMutationHookResult = ReturnType<typeof useUpdateTransactionReviewScheduleMutation>;
export type UpdateTransactionReviewScheduleMutationResult = Apollo.MutationResult<UpdateTransactionReviewScheduleMutation>;
export type UpdateTransactionReviewScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionReviewScheduleMutation, UpdateTransactionReviewScheduleMutationVariables>;
export const ReadTransactionReviewScheduleDocument = gql`
    query ReadTransactionReviewSchedule($legalEntityId: String!) {
  readTransactionReviewSchedule(legalEntityId: $legalEntityId) {
    ...TransactionReviewScheduleFields
  }
}
    ${TransactionReviewScheduleFieldsFragmentDoc}`;

/**
 * __useReadTransactionReviewScheduleQuery__
 *
 * To run a query within a React component, call `useReadTransactionReviewScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTransactionReviewScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTransactionReviewScheduleQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadTransactionReviewScheduleQuery(baseOptions: Apollo.QueryHookOptions<ReadTransactionReviewScheduleQuery, ReadTransactionReviewScheduleQueryVariables> & ({ variables: ReadTransactionReviewScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTransactionReviewScheduleQuery, ReadTransactionReviewScheduleQueryVariables>(ReadTransactionReviewScheduleDocument, options);
      }
export function useReadTransactionReviewScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTransactionReviewScheduleQuery, ReadTransactionReviewScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTransactionReviewScheduleQuery, ReadTransactionReviewScheduleQueryVariables>(ReadTransactionReviewScheduleDocument, options);
        }
export function useReadTransactionReviewScheduleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTransactionReviewScheduleQuery, ReadTransactionReviewScheduleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTransactionReviewScheduleQuery, ReadTransactionReviewScheduleQueryVariables>(ReadTransactionReviewScheduleDocument, options);
        }
export type ReadTransactionReviewScheduleQueryHookResult = ReturnType<typeof useReadTransactionReviewScheduleQuery>;
export type ReadTransactionReviewScheduleLazyQueryHookResult = ReturnType<typeof useReadTransactionReviewScheduleLazyQuery>;
export type ReadTransactionReviewScheduleSuspenseQueryHookResult = ReturnType<typeof useReadTransactionReviewScheduleSuspenseQuery>;
export type ReadTransactionReviewScheduleQueryResult = Apollo.QueryResult<ReadTransactionReviewScheduleQuery, ReadTransactionReviewScheduleQueryVariables>;
export const ReadTransactionReviewNextReminderDocument = gql`
    query ReadTransactionReviewNextReminder($legalEntityId: String!) {
  readTransactionReviewNextReminder(legalEntityId: $legalEntityId)
}
    `;

/**
 * __useReadTransactionReviewNextReminderQuery__
 *
 * To run a query within a React component, call `useReadTransactionReviewNextReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTransactionReviewNextReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTransactionReviewNextReminderQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadTransactionReviewNextReminderQuery(baseOptions: Apollo.QueryHookOptions<ReadTransactionReviewNextReminderQuery, ReadTransactionReviewNextReminderQueryVariables> & ({ variables: ReadTransactionReviewNextReminderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTransactionReviewNextReminderQuery, ReadTransactionReviewNextReminderQueryVariables>(ReadTransactionReviewNextReminderDocument, options);
      }
export function useReadTransactionReviewNextReminderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTransactionReviewNextReminderQuery, ReadTransactionReviewNextReminderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTransactionReviewNextReminderQuery, ReadTransactionReviewNextReminderQueryVariables>(ReadTransactionReviewNextReminderDocument, options);
        }
export function useReadTransactionReviewNextReminderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTransactionReviewNextReminderQuery, ReadTransactionReviewNextReminderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTransactionReviewNextReminderQuery, ReadTransactionReviewNextReminderQueryVariables>(ReadTransactionReviewNextReminderDocument, options);
        }
export type ReadTransactionReviewNextReminderQueryHookResult = ReturnType<typeof useReadTransactionReviewNextReminderQuery>;
export type ReadTransactionReviewNextReminderLazyQueryHookResult = ReturnType<typeof useReadTransactionReviewNextReminderLazyQuery>;
export type ReadTransactionReviewNextReminderSuspenseQueryHookResult = ReturnType<typeof useReadTransactionReviewNextReminderSuspenseQuery>;
export type ReadTransactionReviewNextReminderQueryResult = Apollo.QueryResult<ReadTransactionReviewNextReminderQuery, ReadTransactionReviewNextReminderQueryVariables>;
export const ReadTransactionReviewReminderMessageDocument = gql`
    query ReadTransactionReviewReminderMessage($legalEntityId: String!) {
  readTransactionReviewReminderMessage(legalEntityId: $legalEntityId) {
    ...TransactionReviewReminderMessageFields
  }
}
    ${TransactionReviewReminderMessageFieldsFragmentDoc}`;

/**
 * __useReadTransactionReviewReminderMessageQuery__
 *
 * To run a query within a React component, call `useReadTransactionReviewReminderMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTransactionReviewReminderMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTransactionReviewReminderMessageQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadTransactionReviewReminderMessageQuery(baseOptions: Apollo.QueryHookOptions<ReadTransactionReviewReminderMessageQuery, ReadTransactionReviewReminderMessageQueryVariables> & ({ variables: ReadTransactionReviewReminderMessageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTransactionReviewReminderMessageQuery, ReadTransactionReviewReminderMessageQueryVariables>(ReadTransactionReviewReminderMessageDocument, options);
      }
export function useReadTransactionReviewReminderMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTransactionReviewReminderMessageQuery, ReadTransactionReviewReminderMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTransactionReviewReminderMessageQuery, ReadTransactionReviewReminderMessageQueryVariables>(ReadTransactionReviewReminderMessageDocument, options);
        }
export function useReadTransactionReviewReminderMessageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTransactionReviewReminderMessageQuery, ReadTransactionReviewReminderMessageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTransactionReviewReminderMessageQuery, ReadTransactionReviewReminderMessageQueryVariables>(ReadTransactionReviewReminderMessageDocument, options);
        }
export type ReadTransactionReviewReminderMessageQueryHookResult = ReturnType<typeof useReadTransactionReviewReminderMessageQuery>;
export type ReadTransactionReviewReminderMessageLazyQueryHookResult = ReturnType<typeof useReadTransactionReviewReminderMessageLazyQuery>;
export type ReadTransactionReviewReminderMessageSuspenseQueryHookResult = ReturnType<typeof useReadTransactionReviewReminderMessageSuspenseQuery>;
export type ReadTransactionReviewReminderMessageQueryResult = Apollo.QueryResult<ReadTransactionReviewReminderMessageQuery, ReadTransactionReviewReminderMessageQueryVariables>;
export const CreateAffiliationFromOAuthServiceDocument = gql`
    mutation CreateAffiliationFromOAuthService($organizationId: String!, $code: String!, $service: OAuthService!, $proposalOrganizationId: String) {
  createAffiliationFromOAuthService(
    organizationId: $organizationId
    code: $code
    service: $service
    proposalOrganizationId: $proposalOrganizationId
  ) {
    legalEntity {
      ...LegalEntityFields
    }
    quickBooksCompany {
      ...QuickBooksCompanyFields
    }
  }
}
    ${LegalEntityFieldsFragmentDoc}
${QuickBooksCompanyFieldsFragmentDoc}`;
export type CreateAffiliationFromOAuthServiceMutationFn = Apollo.MutationFunction<CreateAffiliationFromOAuthServiceMutation, CreateAffiliationFromOAuthServiceMutationVariables>;

/**
 * __useCreateAffiliationFromOAuthServiceMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationFromOAuthServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationFromOAuthServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationFromOAuthServiceMutation, { data, loading, error }] = useCreateAffiliationFromOAuthServiceMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      code: // value for 'code'
 *      service: // value for 'service'
 *      proposalOrganizationId: // value for 'proposalOrganizationId'
 *   },
 * });
 */
export function useCreateAffiliationFromOAuthServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateAffiliationFromOAuthServiceMutation, CreateAffiliationFromOAuthServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAffiliationFromOAuthServiceMutation, CreateAffiliationFromOAuthServiceMutationVariables>(CreateAffiliationFromOAuthServiceDocument, options);
      }
export type CreateAffiliationFromOAuthServiceMutationHookResult = ReturnType<typeof useCreateAffiliationFromOAuthServiceMutation>;
export type CreateAffiliationFromOAuthServiceMutationResult = Apollo.MutationResult<CreateAffiliationFromOAuthServiceMutation>;
export type CreateAffiliationFromOAuthServiceMutationOptions = Apollo.BaseMutationOptions<CreateAffiliationFromOAuthServiceMutation, CreateAffiliationFromOAuthServiceMutationVariables>;
export const SubmitAccountantOnboardingDocument = gql`
    mutation SubmitAccountantOnboarding($legalEntityId: String!) {
  submitAccountantOnboarding(legalEntityId: $legalEntityId)
}
    `;
export type SubmitAccountantOnboardingMutationFn = Apollo.MutationFunction<SubmitAccountantOnboardingMutation, SubmitAccountantOnboardingMutationVariables>;

/**
 * __useSubmitAccountantOnboardingMutation__
 *
 * To run a mutation, you first call `useSubmitAccountantOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAccountantOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAccountantOnboardingMutation, { data, loading, error }] = useSubmitAccountantOnboardingMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useSubmitAccountantOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAccountantOnboardingMutation, SubmitAccountantOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAccountantOnboardingMutation, SubmitAccountantOnboardingMutationVariables>(SubmitAccountantOnboardingDocument, options);
      }
export type SubmitAccountantOnboardingMutationHookResult = ReturnType<typeof useSubmitAccountantOnboardingMutation>;
export type SubmitAccountantOnboardingMutationResult = Apollo.MutationResult<SubmitAccountantOnboardingMutation>;
export type SubmitAccountantOnboardingMutationOptions = Apollo.BaseMutationOptions<SubmitAccountantOnboardingMutation, SubmitAccountantOnboardingMutationVariables>;
export const ConfirmOperatorTrialDocument = gql`
    mutation ConfirmOperatorTrial($legalEntityId: String!, $organizationId: String!) {
  confirmOperatorTrial(
    organizationId: $organizationId
    legalEntityId: $legalEntityId
  )
}
    `;
export type ConfirmOperatorTrialMutationFn = Apollo.MutationFunction<ConfirmOperatorTrialMutation, ConfirmOperatorTrialMutationVariables>;

/**
 * __useConfirmOperatorTrialMutation__
 *
 * To run a mutation, you first call `useConfirmOperatorTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOperatorTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOperatorTrialMutation, { data, loading, error }] = useConfirmOperatorTrialMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useConfirmOperatorTrialMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmOperatorTrialMutation, ConfirmOperatorTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmOperatorTrialMutation, ConfirmOperatorTrialMutationVariables>(ConfirmOperatorTrialDocument, options);
      }
export type ConfirmOperatorTrialMutationHookResult = ReturnType<typeof useConfirmOperatorTrialMutation>;
export type ConfirmOperatorTrialMutationResult = Apollo.MutationResult<ConfirmOperatorTrialMutation>;
export type ConfirmOperatorTrialMutationOptions = Apollo.BaseMutationOptions<ConfirmOperatorTrialMutation, ConfirmOperatorTrialMutationVariables>;
export const SubmitOperatorOnboardingDocument = gql`
    mutation SubmitOperatorOnboarding($legalEntityId: String!) {
  submitOperatorOnboarding(legalEntityId: $legalEntityId)
}
    `;
export type SubmitOperatorOnboardingMutationFn = Apollo.MutationFunction<SubmitOperatorOnboardingMutation, SubmitOperatorOnboardingMutationVariables>;

/**
 * __useSubmitOperatorOnboardingMutation__
 *
 * To run a mutation, you first call `useSubmitOperatorOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOperatorOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOperatorOnboardingMutation, { data, loading, error }] = useSubmitOperatorOnboardingMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useSubmitOperatorOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<SubmitOperatorOnboardingMutation, SubmitOperatorOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitOperatorOnboardingMutation, SubmitOperatorOnboardingMutationVariables>(SubmitOperatorOnboardingDocument, options);
      }
export type SubmitOperatorOnboardingMutationHookResult = ReturnType<typeof useSubmitOperatorOnboardingMutation>;
export type SubmitOperatorOnboardingMutationResult = Apollo.MutationResult<SubmitOperatorOnboardingMutation>;
export type SubmitOperatorOnboardingMutationOptions = Apollo.BaseMutationOptions<SubmitOperatorOnboardingMutation, SubmitOperatorOnboardingMutationVariables>;
export const UpdateQuickBooksCredentialsDocument = gql`
    mutation UpdateQuickBooksCredentials($legalEntityId: String!, $code: String!, $service: OAuthService!) {
  updateQuickBooksCredentials(
    legalEntityId: $legalEntityId
    code: $code
    service: $service
  )
}
    `;
export type UpdateQuickBooksCredentialsMutationFn = Apollo.MutationFunction<UpdateQuickBooksCredentialsMutation, UpdateQuickBooksCredentialsMutationVariables>;

/**
 * __useUpdateQuickBooksCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateQuickBooksCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickBooksCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickBooksCredentialsMutation, { data, loading, error }] = useUpdateQuickBooksCredentialsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      code: // value for 'code'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useUpdateQuickBooksCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuickBooksCredentialsMutation, UpdateQuickBooksCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuickBooksCredentialsMutation, UpdateQuickBooksCredentialsMutationVariables>(UpdateQuickBooksCredentialsDocument, options);
      }
export type UpdateQuickBooksCredentialsMutationHookResult = ReturnType<typeof useUpdateQuickBooksCredentialsMutation>;
export type UpdateQuickBooksCredentialsMutationResult = Apollo.MutationResult<UpdateQuickBooksCredentialsMutation>;
export type UpdateQuickBooksCredentialsMutationOptions = Apollo.BaseMutationOptions<UpdateQuickBooksCredentialsMutation, UpdateQuickBooksCredentialsMutationVariables>;
export const CreateLegalEntityFromOAuthServiceDocument = gql`
    mutation CreateLegalEntityFromOAuthService($code: String!, $service: OAuthService!) {
  createLegalEntityFromOAuthService(code: $code, service: $service) {
    ...LegalEntityUserInfoFields
  }
}
    ${LegalEntityUserInfoFieldsFragmentDoc}`;
export type CreateLegalEntityFromOAuthServiceMutationFn = Apollo.MutationFunction<CreateLegalEntityFromOAuthServiceMutation, CreateLegalEntityFromOAuthServiceMutationVariables>;

/**
 * __useCreateLegalEntityFromOAuthServiceMutation__
 *
 * To run a mutation, you first call `useCreateLegalEntityFromOAuthServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLegalEntityFromOAuthServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLegalEntityFromOAuthServiceMutation, { data, loading, error }] = useCreateLegalEntityFromOAuthServiceMutation({
 *   variables: {
 *      code: // value for 'code'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useCreateLegalEntityFromOAuthServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateLegalEntityFromOAuthServiceMutation, CreateLegalEntityFromOAuthServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLegalEntityFromOAuthServiceMutation, CreateLegalEntityFromOAuthServiceMutationVariables>(CreateLegalEntityFromOAuthServiceDocument, options);
      }
export type CreateLegalEntityFromOAuthServiceMutationHookResult = ReturnType<typeof useCreateLegalEntityFromOAuthServiceMutation>;
export type CreateLegalEntityFromOAuthServiceMutationResult = Apollo.MutationResult<CreateLegalEntityFromOAuthServiceMutation>;
export type CreateLegalEntityFromOAuthServiceMutationOptions = Apollo.BaseMutationOptions<CreateLegalEntityFromOAuthServiceMutation, CreateLegalEntityFromOAuthServiceMutationVariables>;
export const CreateAffiliationFromOAuthIdentityDocument = gql`
    mutation CreateAffiliationFromOAuthIdentity($organizationId: String!, $realmId: String!, $service: OAuthService!, $proposalOrganizationId: String) {
  createAffiliationFromOAuthIdentity(
    organizationId: $organizationId
    realmId: $realmId
    service: $service
    proposalOrganizationId: $proposalOrganizationId
  ) {
    legalEntity {
      ...LegalEntityFields
    }
    quickBooksCompany {
      ...QuickBooksCompanyFields
    }
  }
}
    ${LegalEntityFieldsFragmentDoc}
${QuickBooksCompanyFieldsFragmentDoc}`;
export type CreateAffiliationFromOAuthIdentityMutationFn = Apollo.MutationFunction<CreateAffiliationFromOAuthIdentityMutation, CreateAffiliationFromOAuthIdentityMutationVariables>;

/**
 * __useCreateAffiliationFromOAuthIdentityMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationFromOAuthIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationFromOAuthIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationFromOAuthIdentityMutation, { data, loading, error }] = useCreateAffiliationFromOAuthIdentityMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      realmId: // value for 'realmId'
 *      service: // value for 'service'
 *      proposalOrganizationId: // value for 'proposalOrganizationId'
 *   },
 * });
 */
export function useCreateAffiliationFromOAuthIdentityMutation(baseOptions?: Apollo.MutationHookOptions<CreateAffiliationFromOAuthIdentityMutation, CreateAffiliationFromOAuthIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAffiliationFromOAuthIdentityMutation, CreateAffiliationFromOAuthIdentityMutationVariables>(CreateAffiliationFromOAuthIdentityDocument, options);
      }
export type CreateAffiliationFromOAuthIdentityMutationHookResult = ReturnType<typeof useCreateAffiliationFromOAuthIdentityMutation>;
export type CreateAffiliationFromOAuthIdentityMutationResult = Apollo.MutationResult<CreateAffiliationFromOAuthIdentityMutation>;
export type CreateAffiliationFromOAuthIdentityMutationOptions = Apollo.BaseMutationOptions<CreateAffiliationFromOAuthIdentityMutation, CreateAffiliationFromOAuthIdentityMutationVariables>;
export const SendAffiliateDocument = gql`
    mutation SendAffiliate($givenName: String!, $familyName: String!, $emailAddress: String!, $legalEntityId: String!, $invitationType: String!, $proposalType: String, $message: String) {
  sendAffiliate(
    recipient: {givenName: $givenName, familyName: $familyName, emailAddress: $emailAddress}
    legalEntityId: $legalEntityId
    invitationType: $invitationType
    proposalType: $proposalType
    message: $message
  )
}
    `;
export type SendAffiliateMutationFn = Apollo.MutationFunction<SendAffiliateMutation, SendAffiliateMutationVariables>;

/**
 * __useSendAffiliateMutation__
 *
 * To run a mutation, you first call `useSendAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAffiliateMutation, { data, loading, error }] = useSendAffiliateMutation({
 *   variables: {
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      emailAddress: // value for 'emailAddress'
 *      legalEntityId: // value for 'legalEntityId'
 *      invitationType: // value for 'invitationType'
 *      proposalType: // value for 'proposalType'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendAffiliateMutation(baseOptions?: Apollo.MutationHookOptions<SendAffiliateMutation, SendAffiliateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAffiliateMutation, SendAffiliateMutationVariables>(SendAffiliateDocument, options);
      }
export type SendAffiliateMutationHookResult = ReturnType<typeof useSendAffiliateMutation>;
export type SendAffiliateMutationResult = Apollo.MutationResult<SendAffiliateMutation>;
export type SendAffiliateMutationOptions = Apollo.BaseMutationOptions<SendAffiliateMutation, SendAffiliateMutationVariables>;
export const AcceptAffiliationDocument = gql`
    mutation AcceptAffiliation($proposalId: String!, $organizationId: String!) {
  acceptAffiliation(proposalId: $proposalId, organizationId: $organizationId)
}
    `;
export type AcceptAffiliationMutationFn = Apollo.MutationFunction<AcceptAffiliationMutation, AcceptAffiliationMutationVariables>;

/**
 * __useAcceptAffiliationMutation__
 *
 * To run a mutation, you first call `useAcceptAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAffiliationMutation, { data, loading, error }] = useAcceptAffiliationMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAcceptAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptAffiliationMutation, AcceptAffiliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptAffiliationMutation, AcceptAffiliationMutationVariables>(AcceptAffiliationDocument, options);
      }
export type AcceptAffiliationMutationHookResult = ReturnType<typeof useAcceptAffiliationMutation>;
export type AcceptAffiliationMutationResult = Apollo.MutationResult<AcceptAffiliationMutation>;
export type AcceptAffiliationMutationOptions = Apollo.BaseMutationOptions<AcceptAffiliationMutation, AcceptAffiliationMutationVariables>;
export const OnboardLegalEntityFromOAuthServiceDocument = gql`
    mutation OnboardLegalEntityFromOAuthService($code: String!, $service: OAuthService!) {
  onboardLegalEntityFromOAuthService(code: $code, service: $service) {
    ...AIBookkeeperLegalEntityFields
  }
}
    ${AiBookkeeperLegalEntityFieldsFragmentDoc}`;
export type OnboardLegalEntityFromOAuthServiceMutationFn = Apollo.MutationFunction<OnboardLegalEntityFromOAuthServiceMutation, OnboardLegalEntityFromOAuthServiceMutationVariables>;

/**
 * __useOnboardLegalEntityFromOAuthServiceMutation__
 *
 * To run a mutation, you first call `useOnboardLegalEntityFromOAuthServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardLegalEntityFromOAuthServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardLegalEntityFromOAuthServiceMutation, { data, loading, error }] = useOnboardLegalEntityFromOAuthServiceMutation({
 *   variables: {
 *      code: // value for 'code'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useOnboardLegalEntityFromOAuthServiceMutation(baseOptions?: Apollo.MutationHookOptions<OnboardLegalEntityFromOAuthServiceMutation, OnboardLegalEntityFromOAuthServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardLegalEntityFromOAuthServiceMutation, OnboardLegalEntityFromOAuthServiceMutationVariables>(OnboardLegalEntityFromOAuthServiceDocument, options);
      }
export type OnboardLegalEntityFromOAuthServiceMutationHookResult = ReturnType<typeof useOnboardLegalEntityFromOAuthServiceMutation>;
export type OnboardLegalEntityFromOAuthServiceMutationResult = Apollo.MutationResult<OnboardLegalEntityFromOAuthServiceMutation>;
export type OnboardLegalEntityFromOAuthServiceMutationOptions = Apollo.BaseMutationOptions<OnboardLegalEntityFromOAuthServiceMutation, OnboardLegalEntityFromOAuthServiceMutationVariables>;
export const OnboardLegalEntityDocument = gql`
    mutation OnboardLegalEntity($name: String!, $contact: ContactInput!, $websiteUrl: String) {
  onboardLegalEntity(name: $name, contact: $contact, websiteUrl: $websiteUrl) {
    ...AIBookkeeperLegalEntityFields
  }
}
    ${AiBookkeeperLegalEntityFieldsFragmentDoc}`;
export type OnboardLegalEntityMutationFn = Apollo.MutationFunction<OnboardLegalEntityMutation, OnboardLegalEntityMutationVariables>;

/**
 * __useOnboardLegalEntityMutation__
 *
 * To run a mutation, you first call `useOnboardLegalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardLegalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardLegalEntityMutation, { data, loading, error }] = useOnboardLegalEntityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      contact: // value for 'contact'
 *      websiteUrl: // value for 'websiteUrl'
 *   },
 * });
 */
export function useOnboardLegalEntityMutation(baseOptions?: Apollo.MutationHookOptions<OnboardLegalEntityMutation, OnboardLegalEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardLegalEntityMutation, OnboardLegalEntityMutationVariables>(OnboardLegalEntityDocument, options);
      }
export type OnboardLegalEntityMutationHookResult = ReturnType<typeof useOnboardLegalEntityMutation>;
export type OnboardLegalEntityMutationResult = Apollo.MutationResult<OnboardLegalEntityMutation>;
export type OnboardLegalEntityMutationOptions = Apollo.BaseMutationOptions<OnboardLegalEntityMutation, OnboardLegalEntityMutationVariables>;
export const RejectAffiliationDocument = gql`
    mutation RejectAffiliation($proposalId: String!) {
  rejectAffiliation(proposalId: $proposalId)
}
    `;
export type RejectAffiliationMutationFn = Apollo.MutationFunction<RejectAffiliationMutation, RejectAffiliationMutationVariables>;

/**
 * __useRejectAffiliationMutation__
 *
 * To run a mutation, you first call `useRejectAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAffiliationMutation, { data, loading, error }] = useRejectAffiliationMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useRejectAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<RejectAffiliationMutation, RejectAffiliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectAffiliationMutation, RejectAffiliationMutationVariables>(RejectAffiliationDocument, options);
      }
export type RejectAffiliationMutationHookResult = ReturnType<typeof useRejectAffiliationMutation>;
export type RejectAffiliationMutationResult = Apollo.MutationResult<RejectAffiliationMutation>;
export type RejectAffiliationMutationOptions = Apollo.BaseMutationOptions<RejectAffiliationMutation, RejectAffiliationMutationVariables>;
export const CreateLegalEntityFromOAuthIdentityDocument = gql`
    mutation CreateLegalEntityFromOAuthIdentity($realmId: String!, $service: OAuthService!) {
  createLegalEntityFromOAuthIdentity(realmId: $realmId, service: $service) {
    ...LegalEntityFields
  }
}
    ${LegalEntityFieldsFragmentDoc}`;
export type CreateLegalEntityFromOAuthIdentityMutationFn = Apollo.MutationFunction<CreateLegalEntityFromOAuthIdentityMutation, CreateLegalEntityFromOAuthIdentityMutationVariables>;

/**
 * __useCreateLegalEntityFromOAuthIdentityMutation__
 *
 * To run a mutation, you first call `useCreateLegalEntityFromOAuthIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLegalEntityFromOAuthIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLegalEntityFromOAuthIdentityMutation, { data, loading, error }] = useCreateLegalEntityFromOAuthIdentityMutation({
 *   variables: {
 *      realmId: // value for 'realmId'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useCreateLegalEntityFromOAuthIdentityMutation(baseOptions?: Apollo.MutationHookOptions<CreateLegalEntityFromOAuthIdentityMutation, CreateLegalEntityFromOAuthIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLegalEntityFromOAuthIdentityMutation, CreateLegalEntityFromOAuthIdentityMutationVariables>(CreateLegalEntityFromOAuthIdentityDocument, options);
      }
export type CreateLegalEntityFromOAuthIdentityMutationHookResult = ReturnType<typeof useCreateLegalEntityFromOAuthIdentityMutation>;
export type CreateLegalEntityFromOAuthIdentityMutationResult = Apollo.MutationResult<CreateLegalEntityFromOAuthIdentityMutation>;
export type CreateLegalEntityFromOAuthIdentityMutationOptions = Apollo.BaseMutationOptions<CreateLegalEntityFromOAuthIdentityMutation, CreateLegalEntityFromOAuthIdentityMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($companyName: String!, $iconUrl: String, $websiteUrl: String) {
  createOrganization(
    name: $companyName
    iconUrl: $iconUrl
    websiteUrl: $websiteUrl
  ) {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      companyName: // value for 'companyName'
 *      iconUrl: // value for 'iconUrl'
 *      websiteUrl: // value for 'websiteUrl'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const SearchGlobalPartiesDocument = gql`
    query SearchGlobalParties($viewType: ViewType, $text: String!, $offset: Int!, $limit: Int!, $sort: [SearchEntitiesSort!], $sortHitsLeftmost: Boolean) {
  search: searchGlobalParties(
    viewType: $viewType
    text: $text
    sort: $sort
    sortHitsLeftmost: $sortHitsLeftmost
    page: {offset: $offset, limit: $limit}
  ) {
    ...SearchResultFields
  }
}
    ${SearchResultFieldsFragmentDoc}`;

/**
 * __useSearchGlobalPartiesQuery__
 *
 * To run a query within a React component, call `useSearchGlobalPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGlobalPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGlobalPartiesQuery({
 *   variables: {
 *      viewType: // value for 'viewType'
 *      text: // value for 'text'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      sortHitsLeftmost: // value for 'sortHitsLeftmost'
 *   },
 * });
 */
export function useSearchGlobalPartiesQuery(baseOptions: Apollo.QueryHookOptions<SearchGlobalPartiesQuery, SearchGlobalPartiesQueryVariables> & ({ variables: SearchGlobalPartiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchGlobalPartiesQuery, SearchGlobalPartiesQueryVariables>(SearchGlobalPartiesDocument, options);
      }
export function useSearchGlobalPartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchGlobalPartiesQuery, SearchGlobalPartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchGlobalPartiesQuery, SearchGlobalPartiesQueryVariables>(SearchGlobalPartiesDocument, options);
        }
export function useSearchGlobalPartiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchGlobalPartiesQuery, SearchGlobalPartiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchGlobalPartiesQuery, SearchGlobalPartiesQueryVariables>(SearchGlobalPartiesDocument, options);
        }
export type SearchGlobalPartiesQueryHookResult = ReturnType<typeof useSearchGlobalPartiesQuery>;
export type SearchGlobalPartiesLazyQueryHookResult = ReturnType<typeof useSearchGlobalPartiesLazyQuery>;
export type SearchGlobalPartiesSuspenseQueryHookResult = ReturnType<typeof useSearchGlobalPartiesSuspenseQuery>;
export type SearchGlobalPartiesQueryResult = Apollo.QueryResult<SearchGlobalPartiesQuery, SearchGlobalPartiesQueryVariables>;
export const SearchEntitiesDocument = gql`
    query SearchEntities($legalEntityId: String!, $viewVersion: String, $viewType: ViewType, $text: String!, $offset: Int!, $limit: Int!, $kind: ObjectKind!, $sort: [SearchEntitiesSort!], $occurredBefore: Float, $occurredAfter: Float, $sortHitsLeftmost: Boolean) {
  search: searchEntities(
    legalEntityId: $legalEntityId
    viewVersion: $viewVersion
    viewType: $viewType
    text: $text
    kind: $kind
    sort: $sort
    occurredBefore: $occurredBefore
    occurredAfter: $occurredAfter
    sortHitsLeftmost: $sortHitsLeftmost
    page: {offset: $offset, limit: $limit}
  ) {
    ...SearchResultFields
  }
}
    ${SearchResultFieldsFragmentDoc}`;

/**
 * __useSearchEntitiesQuery__
 *
 * To run a query within a React component, call `useSearchEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEntitiesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      viewVersion: // value for 'viewVersion'
 *      viewType: // value for 'viewType'
 *      text: // value for 'text'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      kind: // value for 'kind'
 *      sort: // value for 'sort'
 *      occurredBefore: // value for 'occurredBefore'
 *      occurredAfter: // value for 'occurredAfter'
 *      sortHitsLeftmost: // value for 'sortHitsLeftmost'
 *   },
 * });
 */
export function useSearchEntitiesQuery(baseOptions: Apollo.QueryHookOptions<SearchEntitiesQuery, SearchEntitiesQueryVariables> & ({ variables: SearchEntitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchEntitiesQuery, SearchEntitiesQueryVariables>(SearchEntitiesDocument, options);
      }
export function useSearchEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchEntitiesQuery, SearchEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchEntitiesQuery, SearchEntitiesQueryVariables>(SearchEntitiesDocument, options);
        }
export function useSearchEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchEntitiesQuery, SearchEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchEntitiesQuery, SearchEntitiesQueryVariables>(SearchEntitiesDocument, options);
        }
export type SearchEntitiesQueryHookResult = ReturnType<typeof useSearchEntitiesQuery>;
export type SearchEntitiesLazyQueryHookResult = ReturnType<typeof useSearchEntitiesLazyQuery>;
export type SearchEntitiesSuspenseQueryHookResult = ReturnType<typeof useSearchEntitiesSuspenseQuery>;
export type SearchEntitiesQueryResult = Apollo.QueryResult<SearchEntitiesQuery, SearchEntitiesQueryVariables>;
export const SearchTermsDocument = gql`
    query SearchTerms($legalEntityId: String!, $viewVersion: String, $viewType: ViewType, $text: String!, $kinds: [ObjectKind!]) {
  searchTerms(
    legalEntityId: $legalEntityId
    viewVersion: $viewVersion
    viewType: $viewType
    text: $text
    kinds: $kinds
  ) {
    ...SearchResultFields
  }
}
    ${SearchResultFieldsFragmentDoc}`;

/**
 * __useSearchTermsQuery__
 *
 * To run a query within a React component, call `useSearchTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTermsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      viewVersion: // value for 'viewVersion'
 *      viewType: // value for 'viewType'
 *      text: // value for 'text'
 *      kinds: // value for 'kinds'
 *   },
 * });
 */
export function useSearchTermsQuery(baseOptions: Apollo.QueryHookOptions<SearchTermsQuery, SearchTermsQueryVariables> & ({ variables: SearchTermsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTermsQuery, SearchTermsQueryVariables>(SearchTermsDocument, options);
      }
export function useSearchTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTermsQuery, SearchTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTermsQuery, SearchTermsQueryVariables>(SearchTermsDocument, options);
        }
export function useSearchTermsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchTermsQuery, SearchTermsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchTermsQuery, SearchTermsQueryVariables>(SearchTermsDocument, options);
        }
export type SearchTermsQueryHookResult = ReturnType<typeof useSearchTermsQuery>;
export type SearchTermsLazyQueryHookResult = ReturnType<typeof useSearchTermsLazyQuery>;
export type SearchTermsSuspenseQueryHookResult = ReturnType<typeof useSearchTermsSuspenseQuery>;
export type SearchTermsQueryResult = Apollo.QueryResult<SearchTermsQuery, SearchTermsQueryVariables>;
export const AutoConfigureProductDocument = gql`
    mutation AutoConfigureProduct($legalEntityId: String!, $product: CategoryToLabelMappingProduct!) {
  autoConfigureProduct(legalEntityId: $legalEntityId, product: $product)
}
    `;
export type AutoConfigureProductMutationFn = Apollo.MutationFunction<AutoConfigureProductMutation, AutoConfigureProductMutationVariables>;

/**
 * __useAutoConfigureProductMutation__
 *
 * To run a mutation, you first call `useAutoConfigureProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoConfigureProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoConfigureProductMutation, { data, loading, error }] = useAutoConfigureProductMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useAutoConfigureProductMutation(baseOptions?: Apollo.MutationHookOptions<AutoConfigureProductMutation, AutoConfigureProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AutoConfigureProductMutation, AutoConfigureProductMutationVariables>(AutoConfigureProductDocument, options);
      }
export type AutoConfigureProductMutationHookResult = ReturnType<typeof useAutoConfigureProductMutation>;
export type AutoConfigureProductMutationResult = Apollo.MutationResult<AutoConfigureProductMutation>;
export type AutoConfigureProductMutationOptions = Apollo.BaseMutationOptions<AutoConfigureProductMutation, AutoConfigureProductMutationVariables>;
export const CreateCategoryMappingDocument = gql`
    mutation CreateCategoryMapping($legalEntityId: String!, $mappingValue: String!, $mappingType: CategoryToLabelMappingType!, $product: CategoryToLabelMappingProduct!, $categoryId: String!) {
  createCategoryMapping(
    legalEntityId: $legalEntityId
    mappingValue: $mappingValue
    mappingType: $mappingType
    product: $product
    categoryId: $categoryId
  )
}
    `;
export type CreateCategoryMappingMutationFn = Apollo.MutationFunction<CreateCategoryMappingMutation, CreateCategoryMappingMutationVariables>;

/**
 * __useCreateCategoryMappingMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMappingMutation, { data, loading, error }] = useCreateCategoryMappingMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      mappingValue: // value for 'mappingValue'
 *      mappingType: // value for 'mappingType'
 *      product: // value for 'product'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCreateCategoryMappingMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMappingMutation, CreateCategoryMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMappingMutation, CreateCategoryMappingMutationVariables>(CreateCategoryMappingDocument, options);
      }
export type CreateCategoryMappingMutationHookResult = ReturnType<typeof useCreateCategoryMappingMutation>;
export type CreateCategoryMappingMutationResult = Apollo.MutationResult<CreateCategoryMappingMutation>;
export type CreateCategoryMappingMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMappingMutation, CreateCategoryMappingMutationVariables>;
export const ValidateCategoryMappingsDocument = gql`
    query ValidateCategoryMappings($legalEntityId: String!, $product: CategoryToLabelMappingProduct!) {
  validateCategoryMappings(legalEntityId: $legalEntityId, product: $product)
}
    `;

/**
 * __useValidateCategoryMappingsQuery__
 *
 * To run a query within a React component, call `useValidateCategoryMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateCategoryMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateCategoryMappingsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useValidateCategoryMappingsQuery(baseOptions: Apollo.QueryHookOptions<ValidateCategoryMappingsQuery, ValidateCategoryMappingsQueryVariables> & ({ variables: ValidateCategoryMappingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateCategoryMappingsQuery, ValidateCategoryMappingsQueryVariables>(ValidateCategoryMappingsDocument, options);
      }
export function useValidateCategoryMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateCategoryMappingsQuery, ValidateCategoryMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateCategoryMappingsQuery, ValidateCategoryMappingsQueryVariables>(ValidateCategoryMappingsDocument, options);
        }
export function useValidateCategoryMappingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ValidateCategoryMappingsQuery, ValidateCategoryMappingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ValidateCategoryMappingsQuery, ValidateCategoryMappingsQueryVariables>(ValidateCategoryMappingsDocument, options);
        }
export type ValidateCategoryMappingsQueryHookResult = ReturnType<typeof useValidateCategoryMappingsQuery>;
export type ValidateCategoryMappingsLazyQueryHookResult = ReturnType<typeof useValidateCategoryMappingsLazyQuery>;
export type ValidateCategoryMappingsSuspenseQueryHookResult = ReturnType<typeof useValidateCategoryMappingsSuspenseQuery>;
export type ValidateCategoryMappingsQueryResult = Apollo.QueryResult<ValidateCategoryMappingsQuery, ValidateCategoryMappingsQueryVariables>;
export const ListCategoryMappingsDocument = gql`
    query ListCategoryMappings($legalEntityId: String!, $product: CategoryToLabelMappingProduct!) {
  listCategoryMappings(legalEntityId: $legalEntityId, product: $product) {
    ...CategoryMappingFields
  }
}
    ${CategoryMappingFieldsFragmentDoc}`;

/**
 * __useListCategoryMappingsQuery__
 *
 * To run a query within a React component, call `useListCategoryMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCategoryMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCategoryMappingsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useListCategoryMappingsQuery(baseOptions: Apollo.QueryHookOptions<ListCategoryMappingsQuery, ListCategoryMappingsQueryVariables> & ({ variables: ListCategoryMappingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCategoryMappingsQuery, ListCategoryMappingsQueryVariables>(ListCategoryMappingsDocument, options);
      }
export function useListCategoryMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCategoryMappingsQuery, ListCategoryMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCategoryMappingsQuery, ListCategoryMappingsQueryVariables>(ListCategoryMappingsDocument, options);
        }
export function useListCategoryMappingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListCategoryMappingsQuery, ListCategoryMappingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCategoryMappingsQuery, ListCategoryMappingsQueryVariables>(ListCategoryMappingsDocument, options);
        }
export type ListCategoryMappingsQueryHookResult = ReturnType<typeof useListCategoryMappingsQuery>;
export type ListCategoryMappingsLazyQueryHookResult = ReturnType<typeof useListCategoryMappingsLazyQuery>;
export type ListCategoryMappingsSuspenseQueryHookResult = ReturnType<typeof useListCategoryMappingsSuspenseQuery>;
export type ListCategoryMappingsQueryResult = Apollo.QueryResult<ListCategoryMappingsQuery, ListCategoryMappingsQueryVariables>;
export const CreateGustoConnectionDocument = gql`
    mutation CreateGustoConnection($legalEntityId: String!, $connectorId: String!, $authorizationCode: String!) {
  createGustoConnection(
    legalEntityId: $legalEntityId
    connectorId: $connectorId
    authorizationCode: $authorizationCode
  ) {
    ...CreateConnectionResponseFields
  }
}
    ${CreateConnectionResponseFieldsFragmentDoc}`;
export type CreateGustoConnectionMutationFn = Apollo.MutationFunction<CreateGustoConnectionMutation, CreateGustoConnectionMutationVariables>;

/**
 * __useCreateGustoConnectionMutation__
 *
 * To run a mutation, you first call `useCreateGustoConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGustoConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGustoConnectionMutation, { data, loading, error }] = useCreateGustoConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectorId: // value for 'connectorId'
 *      authorizationCode: // value for 'authorizationCode'
 *   },
 * });
 */
export function useCreateGustoConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateGustoConnectionMutation, CreateGustoConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGustoConnectionMutation, CreateGustoConnectionMutationVariables>(CreateGustoConnectionDocument, options);
      }
export type CreateGustoConnectionMutationHookResult = ReturnType<typeof useCreateGustoConnectionMutation>;
export type CreateGustoConnectionMutationResult = Apollo.MutationResult<CreateGustoConnectionMutation>;
export type CreateGustoConnectionMutationOptions = Apollo.BaseMutationOptions<CreateGustoConnectionMutation, CreateGustoConnectionMutationVariables>;
export const SyncConnectionDocument = gql`
    mutation SyncConnection($legalEntityId: String!, $connectionId: String!) {
  syncConnection(legalEntityId: $legalEntityId, connectionId: $connectionId)
}
    `;
export type SyncConnectionMutationFn = Apollo.MutationFunction<SyncConnectionMutation, SyncConnectionMutationVariables>;

/**
 * __useSyncConnectionMutation__
 *
 * To run a mutation, you first call `useSyncConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncConnectionMutation, { data, loading, error }] = useSyncConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useSyncConnectionMutation(baseOptions?: Apollo.MutationHookOptions<SyncConnectionMutation, SyncConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncConnectionMutation, SyncConnectionMutationVariables>(SyncConnectionDocument, options);
      }
export type SyncConnectionMutationHookResult = ReturnType<typeof useSyncConnectionMutation>;
export type SyncConnectionMutationResult = Apollo.MutationResult<SyncConnectionMutation>;
export type SyncConnectionMutationOptions = Apollo.BaseMutationOptions<SyncConnectionMutation, SyncConnectionMutationVariables>;
export const UpdateConnectPlaidLinkTokenDocument = gql`
    mutation UpdateConnectPlaidLinkToken($legalEntityId: String!, $connectionId: String!) {
  updateConnectPlaidLinkToken(
    legalEntityId: $legalEntityId
    connectionId: $connectionId
  ) {
    token
  }
}
    `;
export type UpdateConnectPlaidLinkTokenMutationFn = Apollo.MutationFunction<UpdateConnectPlaidLinkTokenMutation, UpdateConnectPlaidLinkTokenMutationVariables>;

/**
 * __useUpdateConnectPlaidLinkTokenMutation__
 *
 * To run a mutation, you first call `useUpdateConnectPlaidLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectPlaidLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectPlaidLinkTokenMutation, { data, loading, error }] = useUpdateConnectPlaidLinkTokenMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useUpdateConnectPlaidLinkTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConnectPlaidLinkTokenMutation, UpdateConnectPlaidLinkTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConnectPlaidLinkTokenMutation, UpdateConnectPlaidLinkTokenMutationVariables>(UpdateConnectPlaidLinkTokenDocument, options);
      }
export type UpdateConnectPlaidLinkTokenMutationHookResult = ReturnType<typeof useUpdateConnectPlaidLinkTokenMutation>;
export type UpdateConnectPlaidLinkTokenMutationResult = Apollo.MutationResult<UpdateConnectPlaidLinkTokenMutation>;
export type UpdateConnectPlaidLinkTokenMutationOptions = Apollo.BaseMutationOptions<UpdateConnectPlaidLinkTokenMutation, UpdateConnectPlaidLinkTokenMutationVariables>;
export const ActivateRampConnectionDocument = gql`
    mutation ActivateRampConnection($legalEntityId: String!, $connectionId: String!) {
  activateRampConnection(
    legalEntityId: $legalEntityId
    connectionId: $connectionId
  )
}
    `;
export type ActivateRampConnectionMutationFn = Apollo.MutationFunction<ActivateRampConnectionMutation, ActivateRampConnectionMutationVariables>;

/**
 * __useActivateRampConnectionMutation__
 *
 * To run a mutation, you first call `useActivateRampConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateRampConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateRampConnectionMutation, { data, loading, error }] = useActivateRampConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useActivateRampConnectionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateRampConnectionMutation, ActivateRampConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateRampConnectionMutation, ActivateRampConnectionMutationVariables>(ActivateRampConnectionDocument, options);
      }
export type ActivateRampConnectionMutationHookResult = ReturnType<typeof useActivateRampConnectionMutation>;
export type ActivateRampConnectionMutationResult = Apollo.MutationResult<ActivateRampConnectionMutation>;
export type ActivateRampConnectionMutationOptions = Apollo.BaseMutationOptions<ActivateRampConnectionMutation, ActivateRampConnectionMutationVariables>;
export const ArchiveConnectionDocument = gql`
    mutation ArchiveConnection($legalEntityId: String!, $connectionId: String!) {
  archiveConnection(legalEntityId: $legalEntityId, connectionId: $connectionId)
}
    `;
export type ArchiveConnectionMutationFn = Apollo.MutationFunction<ArchiveConnectionMutation, ArchiveConnectionMutationVariables>;

/**
 * __useArchiveConnectionMutation__
 *
 * To run a mutation, you first call `useArchiveConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveConnectionMutation, { data, loading, error }] = useArchiveConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useArchiveConnectionMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveConnectionMutation, ArchiveConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveConnectionMutation, ArchiveConnectionMutationVariables>(ArchiveConnectionDocument, options);
      }
export type ArchiveConnectionMutationHookResult = ReturnType<typeof useArchiveConnectionMutation>;
export type ArchiveConnectionMutationResult = Apollo.MutationResult<ArchiveConnectionMutation>;
export type ArchiveConnectionMutationOptions = Apollo.BaseMutationOptions<ArchiveConnectionMutation, ArchiveConnectionMutationVariables>;
export const DestroyConnectionDocument = gql`
    mutation DestroyConnection($legalEntityId: String!, $connectionId: String!) {
  destroyConnection(legalEntityId: $legalEntityId, connectionId: $connectionId)
}
    `;
export type DestroyConnectionMutationFn = Apollo.MutationFunction<DestroyConnectionMutation, DestroyConnectionMutationVariables>;

/**
 * __useDestroyConnectionMutation__
 *
 * To run a mutation, you first call `useDestroyConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyConnectionMutation, { data, loading, error }] = useDestroyConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useDestroyConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DestroyConnectionMutation, DestroyConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyConnectionMutation, DestroyConnectionMutationVariables>(DestroyConnectionDocument, options);
      }
export type DestroyConnectionMutationHookResult = ReturnType<typeof useDestroyConnectionMutation>;
export type DestroyConnectionMutationResult = Apollo.MutationResult<DestroyConnectionMutation>;
export type DestroyConnectionMutationOptions = Apollo.BaseMutationOptions<DestroyConnectionMutation, DestroyConnectionMutationVariables>;
export const AutoConfigureConnectionDocument = gql`
    mutation AutoConfigureConnection($legalEntityId: String!, $connectionId: String!) {
  autoConfigureConnection(
    legalEntityId: $legalEntityId
    connectionId: $connectionId
  )
}
    `;
export type AutoConfigureConnectionMutationFn = Apollo.MutationFunction<AutoConfigureConnectionMutation, AutoConfigureConnectionMutationVariables>;

/**
 * __useAutoConfigureConnectionMutation__
 *
 * To run a mutation, you first call `useAutoConfigureConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoConfigureConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoConfigureConnectionMutation, { data, loading, error }] = useAutoConfigureConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useAutoConfigureConnectionMutation(baseOptions?: Apollo.MutationHookOptions<AutoConfigureConnectionMutation, AutoConfigureConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AutoConfigureConnectionMutation, AutoConfigureConnectionMutationVariables>(AutoConfigureConnectionDocument, options);
      }
export type AutoConfigureConnectionMutationHookResult = ReturnType<typeof useAutoConfigureConnectionMutation>;
export type AutoConfigureConnectionMutationResult = Apollo.MutationResult<AutoConfigureConnectionMutation>;
export type AutoConfigureConnectionMutationOptions = Apollo.BaseMutationOptions<AutoConfigureConnectionMutation, AutoConfigureConnectionMutationVariables>;
export const UpdateConnectionDocument = gql`
    mutation UpdateConnection($legalEntityId: String!, $connectionId: String!, $settings: ConnectionSettingsInput!) {
  updateConnection(
    legalEntityId: $legalEntityId
    connectionId: $connectionId
    settings: $settings
  )
}
    `;
export type UpdateConnectionMutationFn = Apollo.MutationFunction<UpdateConnectionMutation, UpdateConnectionMutationVariables>;

/**
 * __useUpdateConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionMutation, { data, loading, error }] = useUpdateConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateConnectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConnectionMutation, UpdateConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConnectionMutation, UpdateConnectionMutationVariables>(UpdateConnectionDocument, options);
      }
export type UpdateConnectionMutationHookResult = ReturnType<typeof useUpdateConnectionMutation>;
export type UpdateConnectionMutationResult = Apollo.MutationResult<UpdateConnectionMutation>;
export type UpdateConnectionMutationOptions = Apollo.BaseMutationOptions<UpdateConnectionMutation, UpdateConnectionMutationVariables>;
export const UpdatePlaidConnectionDocument = gql`
    mutation UpdatePlaidConnection($legalEntityId: String!, $connectionId: String!, $accounts: [PlaidAccount!]!) {
  updatePlaidConnection(
    legalEntityId: $legalEntityId
    connectionId: $connectionId
    accounts: $accounts
  )
}
    `;
export type UpdatePlaidConnectionMutationFn = Apollo.MutationFunction<UpdatePlaidConnectionMutation, UpdatePlaidConnectionMutationVariables>;

/**
 * __useUpdatePlaidConnectionMutation__
 *
 * To run a mutation, you first call `useUpdatePlaidConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaidConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaidConnectionMutation, { data, loading, error }] = useUpdatePlaidConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *      accounts: // value for 'accounts'
 *   },
 * });
 */
export function useUpdatePlaidConnectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaidConnectionMutation, UpdatePlaidConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaidConnectionMutation, UpdatePlaidConnectionMutationVariables>(UpdatePlaidConnectionDocument, options);
      }
export type UpdatePlaidConnectionMutationHookResult = ReturnType<typeof useUpdatePlaidConnectionMutation>;
export type UpdatePlaidConnectionMutationResult = Apollo.MutationResult<UpdatePlaidConnectionMutation>;
export type UpdatePlaidConnectionMutationOptions = Apollo.BaseMutationOptions<UpdatePlaidConnectionMutation, UpdatePlaidConnectionMutationVariables>;
export const CreateStripeConnectionDocument = gql`
    mutation CreateStripeConnection($legalEntityId: String!, $connectorId: String!, $externalId: String!) {
  createStripeConnection(
    legalEntityId: $legalEntityId
    connectorId: $connectorId
    externalId: $externalId
  ) {
    ...CreateConnectionResponseFields
  }
}
    ${CreateConnectionResponseFieldsFragmentDoc}`;
export type CreateStripeConnectionMutationFn = Apollo.MutationFunction<CreateStripeConnectionMutation, CreateStripeConnectionMutationVariables>;

/**
 * __useCreateStripeConnectionMutation__
 *
 * To run a mutation, you first call `useCreateStripeConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeConnectionMutation, { data, loading, error }] = useCreateStripeConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectorId: // value for 'connectorId'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useCreateStripeConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeConnectionMutation, CreateStripeConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeConnectionMutation, CreateStripeConnectionMutationVariables>(CreateStripeConnectionDocument, options);
      }
export type CreateStripeConnectionMutationHookResult = ReturnType<typeof useCreateStripeConnectionMutation>;
export type CreateStripeConnectionMutationResult = Apollo.MutationResult<CreateStripeConnectionMutation>;
export type CreateStripeConnectionMutationOptions = Apollo.BaseMutationOptions<CreateStripeConnectionMutation, CreateStripeConnectionMutationVariables>;
export const CreatePlaidConnectionDocument = gql`
    mutation CreatePlaidConnection($legalEntityId: String!, $connectorId: String!, $publicToken: String!, $institutionId: String!, $institutionName: String!, $accounts: [PlaidAccount!]!) {
  createPlaidConnection(
    legalEntityId: $legalEntityId
    connectorId: $connectorId
    publicToken: $publicToken
    institutionId: $institutionId
    institutionName: $institutionName
    accounts: $accounts
  ) {
    ...CreateConnectionResponseFields
  }
}
    ${CreateConnectionResponseFieldsFragmentDoc}`;
export type CreatePlaidConnectionMutationFn = Apollo.MutationFunction<CreatePlaidConnectionMutation, CreatePlaidConnectionMutationVariables>;

/**
 * __useCreatePlaidConnectionMutation__
 *
 * To run a mutation, you first call `useCreatePlaidConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaidConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaidConnectionMutation, { data, loading, error }] = useCreatePlaidConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectorId: // value for 'connectorId'
 *      publicToken: // value for 'publicToken'
 *      institutionId: // value for 'institutionId'
 *      institutionName: // value for 'institutionName'
 *      accounts: // value for 'accounts'
 *   },
 * });
 */
export function useCreatePlaidConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaidConnectionMutation, CreatePlaidConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaidConnectionMutation, CreatePlaidConnectionMutationVariables>(CreatePlaidConnectionDocument, options);
      }
export type CreatePlaidConnectionMutationHookResult = ReturnType<typeof useCreatePlaidConnectionMutation>;
export type CreatePlaidConnectionMutationResult = Apollo.MutationResult<CreatePlaidConnectionMutation>;
export type CreatePlaidConnectionMutationOptions = Apollo.BaseMutationOptions<CreatePlaidConnectionMutation, CreatePlaidConnectionMutationVariables>;
export const CreateRampConnectionDocument = gql`
    mutation CreateRampConnection($legalEntityId: String!, $connectorId: String!, $authorizationCode: String!) {
  createRampConnection(
    legalEntityId: $legalEntityId
    connectorId: $connectorId
    authorizationCode: $authorizationCode
  ) {
    ...CreateConnectionResponseFields
  }
}
    ${CreateConnectionResponseFieldsFragmentDoc}`;
export type CreateRampConnectionMutationFn = Apollo.MutationFunction<CreateRampConnectionMutation, CreateRampConnectionMutationVariables>;

/**
 * __useCreateRampConnectionMutation__
 *
 * To run a mutation, you first call `useCreateRampConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRampConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRampConnectionMutation, { data, loading, error }] = useCreateRampConnectionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectorId: // value for 'connectorId'
 *      authorizationCode: // value for 'authorizationCode'
 *   },
 * });
 */
export function useCreateRampConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateRampConnectionMutation, CreateRampConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRampConnectionMutation, CreateRampConnectionMutationVariables>(CreateRampConnectionDocument, options);
      }
export type CreateRampConnectionMutationHookResult = ReturnType<typeof useCreateRampConnectionMutation>;
export type CreateRampConnectionMutationResult = Apollo.MutationResult<CreateRampConnectionMutation>;
export type CreateRampConnectionMutationOptions = Apollo.BaseMutationOptions<CreateRampConnectionMutation, CreateRampConnectionMutationVariables>;
export const CreateConnectPlaidLinkTokenDocument = gql`
    mutation CreateConnectPlaidLinkToken($routingNumber: String) {
  createConnectPlaidLinkToken(routingNumber: $routingNumber) {
    token
  }
}
    `;
export type CreateConnectPlaidLinkTokenMutationFn = Apollo.MutationFunction<CreateConnectPlaidLinkTokenMutation, CreateConnectPlaidLinkTokenMutationVariables>;

/**
 * __useCreateConnectPlaidLinkTokenMutation__
 *
 * To run a mutation, you first call `useCreateConnectPlaidLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectPlaidLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectPlaidLinkTokenMutation, { data, loading, error }] = useCreateConnectPlaidLinkTokenMutation({
 *   variables: {
 *      routingNumber: // value for 'routingNumber'
 *   },
 * });
 */
export function useCreateConnectPlaidLinkTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateConnectPlaidLinkTokenMutation, CreateConnectPlaidLinkTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConnectPlaidLinkTokenMutation, CreateConnectPlaidLinkTokenMutationVariables>(CreateConnectPlaidLinkTokenDocument, options);
      }
export type CreateConnectPlaidLinkTokenMutationHookResult = ReturnType<typeof useCreateConnectPlaidLinkTokenMutation>;
export type CreateConnectPlaidLinkTokenMutationResult = Apollo.MutationResult<CreateConnectPlaidLinkTokenMutation>;
export type CreateConnectPlaidLinkTokenMutationOptions = Apollo.BaseMutationOptions<CreateConnectPlaidLinkTokenMutation, CreateConnectPlaidLinkTokenMutationVariables>;
export const UpdateConnectionAccountsDocument = gql`
    mutation UpdateConnectionAccounts($legalEntityId: String!, $connectionId: String!, $accounts: [ConnectionAccountInput!]!) {
  updateConnectionAccounts(
    legalEntityId: $legalEntityId
    connectionId: $connectionId
    accounts: $accounts
  )
}
    `;
export type UpdateConnectionAccountsMutationFn = Apollo.MutationFunction<UpdateConnectionAccountsMutation, UpdateConnectionAccountsMutationVariables>;

/**
 * __useUpdateConnectionAccountsMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionAccountsMutation, { data, loading, error }] = useUpdateConnectionAccountsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *      accounts: // value for 'accounts'
 *   },
 * });
 */
export function useUpdateConnectionAccountsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConnectionAccountsMutation, UpdateConnectionAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConnectionAccountsMutation, UpdateConnectionAccountsMutationVariables>(UpdateConnectionAccountsDocument, options);
      }
export type UpdateConnectionAccountsMutationHookResult = ReturnType<typeof useUpdateConnectionAccountsMutation>;
export type UpdateConnectionAccountsMutationResult = Apollo.MutationResult<UpdateConnectionAccountsMutation>;
export type UpdateConnectionAccountsMutationOptions = Apollo.BaseMutationOptions<UpdateConnectionAccountsMutation, UpdateConnectionAccountsMutationVariables>;
export const SuggestedConnectionsDocument = gql`
    query SuggestedConnections($legalEntityId: String!, $filter: SuggestedConnectionsFilter!) {
  suggestedConnections(legalEntityId: $legalEntityId, filter: $filter) {
    ...SuggestedConnectionFields
  }
}
    ${SuggestedConnectionFieldsFragmentDoc}`;

/**
 * __useSuggestedConnectionsQuery__
 *
 * To run a query within a React component, call `useSuggestedConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedConnectionsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSuggestedConnectionsQuery(baseOptions: Apollo.QueryHookOptions<SuggestedConnectionsQuery, SuggestedConnectionsQueryVariables> & ({ variables: SuggestedConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedConnectionsQuery, SuggestedConnectionsQueryVariables>(SuggestedConnectionsDocument, options);
      }
export function useSuggestedConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedConnectionsQuery, SuggestedConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedConnectionsQuery, SuggestedConnectionsQueryVariables>(SuggestedConnectionsDocument, options);
        }
export function useSuggestedConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SuggestedConnectionsQuery, SuggestedConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedConnectionsQuery, SuggestedConnectionsQueryVariables>(SuggestedConnectionsDocument, options);
        }
export type SuggestedConnectionsQueryHookResult = ReturnType<typeof useSuggestedConnectionsQuery>;
export type SuggestedConnectionsLazyQueryHookResult = ReturnType<typeof useSuggestedConnectionsLazyQuery>;
export type SuggestedConnectionsSuspenseQueryHookResult = ReturnType<typeof useSuggestedConnectionsSuspenseQuery>;
export type SuggestedConnectionsQueryResult = Apollo.QueryResult<SuggestedConnectionsQuery, SuggestedConnectionsQueryVariables>;
export const OnboardConnectionsDocument = gql`
    query OnboardConnections($legalEntityId: String!, $institutionProducts: [InstitutionProduct!]!) {
  onboardConnections(
    legalEntityId: $legalEntityId
    institutionProducts: $institutionProducts
  ) {
    ...SuggestedConnectionFields
  }
}
    ${SuggestedConnectionFieldsFragmentDoc}`;

/**
 * __useOnboardConnectionsQuery__
 *
 * To run a query within a React component, call `useOnboardConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardConnectionsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      institutionProducts: // value for 'institutionProducts'
 *   },
 * });
 */
export function useOnboardConnectionsQuery(baseOptions: Apollo.QueryHookOptions<OnboardConnectionsQuery, OnboardConnectionsQueryVariables> & ({ variables: OnboardConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardConnectionsQuery, OnboardConnectionsQueryVariables>(OnboardConnectionsDocument, options);
      }
export function useOnboardConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardConnectionsQuery, OnboardConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardConnectionsQuery, OnboardConnectionsQueryVariables>(OnboardConnectionsDocument, options);
        }
export function useOnboardConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OnboardConnectionsQuery, OnboardConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardConnectionsQuery, OnboardConnectionsQueryVariables>(OnboardConnectionsDocument, options);
        }
export type OnboardConnectionsQueryHookResult = ReturnType<typeof useOnboardConnectionsQuery>;
export type OnboardConnectionsLazyQueryHookResult = ReturnType<typeof useOnboardConnectionsLazyQuery>;
export type OnboardConnectionsSuspenseQueryHookResult = ReturnType<typeof useOnboardConnectionsSuspenseQuery>;
export type OnboardConnectionsQueryResult = Apollo.QueryResult<OnboardConnectionsQuery, OnboardConnectionsQueryVariables>;
export const ListConnectionsDocument = gql`
    query ListConnections($legalEntityId: String!) {
  listConnections(legalEntityId: $legalEntityId) {
    ...ConnectionSummaryFields
  }
}
    ${ConnectionSummaryFieldsFragmentDoc}`;

/**
 * __useListConnectionsQuery__
 *
 * To run a query within a React component, call `useListConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConnectionsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListConnectionsQuery(baseOptions: Apollo.QueryHookOptions<ListConnectionsQuery, ListConnectionsQueryVariables> & ({ variables: ListConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListConnectionsQuery, ListConnectionsQueryVariables>(ListConnectionsDocument, options);
      }
export function useListConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListConnectionsQuery, ListConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListConnectionsQuery, ListConnectionsQueryVariables>(ListConnectionsDocument, options);
        }
export function useListConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListConnectionsQuery, ListConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListConnectionsQuery, ListConnectionsQueryVariables>(ListConnectionsDocument, options);
        }
export type ListConnectionsQueryHookResult = ReturnType<typeof useListConnectionsQuery>;
export type ListConnectionsLazyQueryHookResult = ReturnType<typeof useListConnectionsLazyQuery>;
export type ListConnectionsSuspenseQueryHookResult = ReturnType<typeof useListConnectionsSuspenseQuery>;
export type ListConnectionsQueryResult = Apollo.QueryResult<ListConnectionsQuery, ListConnectionsQueryVariables>;
export const FindConnectionsByInstitutionDocument = gql`
    query FindConnectionsByInstitution($legalEntityId: String!, $institutionName: String!) {
  findConnectionsByInstitution(
    legalEntityId: $legalEntityId
    institutionName: $institutionName
  ) {
    ...ConnectionSummaryFields
  }
}
    ${ConnectionSummaryFieldsFragmentDoc}`;

/**
 * __useFindConnectionsByInstitutionQuery__
 *
 * To run a query within a React component, call `useFindConnectionsByInstitutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindConnectionsByInstitutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindConnectionsByInstitutionQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      institutionName: // value for 'institutionName'
 *   },
 * });
 */
export function useFindConnectionsByInstitutionQuery(baseOptions: Apollo.QueryHookOptions<FindConnectionsByInstitutionQuery, FindConnectionsByInstitutionQueryVariables> & ({ variables: FindConnectionsByInstitutionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindConnectionsByInstitutionQuery, FindConnectionsByInstitutionQueryVariables>(FindConnectionsByInstitutionDocument, options);
      }
export function useFindConnectionsByInstitutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindConnectionsByInstitutionQuery, FindConnectionsByInstitutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindConnectionsByInstitutionQuery, FindConnectionsByInstitutionQueryVariables>(FindConnectionsByInstitutionDocument, options);
        }
export function useFindConnectionsByInstitutionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindConnectionsByInstitutionQuery, FindConnectionsByInstitutionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindConnectionsByInstitutionQuery, FindConnectionsByInstitutionQueryVariables>(FindConnectionsByInstitutionDocument, options);
        }
export type FindConnectionsByInstitutionQueryHookResult = ReturnType<typeof useFindConnectionsByInstitutionQuery>;
export type FindConnectionsByInstitutionLazyQueryHookResult = ReturnType<typeof useFindConnectionsByInstitutionLazyQuery>;
export type FindConnectionsByInstitutionSuspenseQueryHookResult = ReturnType<typeof useFindConnectionsByInstitutionSuspenseQuery>;
export type FindConnectionsByInstitutionQueryResult = Apollo.QueryResult<FindConnectionsByInstitutionQuery, FindConnectionsByInstitutionQueryVariables>;
export const ListConnectorsDocument = gql`
    query ListConnectors($legalEntityId: String!) {
  listConnectors(legalEntityId: $legalEntityId) {
    ...ConnectorFields
  }
}
    ${ConnectorFieldsFragmentDoc}`;

/**
 * __useListConnectorsQuery__
 *
 * To run a query within a React component, call `useListConnectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConnectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConnectorsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListConnectorsQuery(baseOptions: Apollo.QueryHookOptions<ListConnectorsQuery, ListConnectorsQueryVariables> & ({ variables: ListConnectorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListConnectorsQuery, ListConnectorsQueryVariables>(ListConnectorsDocument, options);
      }
export function useListConnectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListConnectorsQuery, ListConnectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListConnectorsQuery, ListConnectorsQueryVariables>(ListConnectorsDocument, options);
        }
export function useListConnectorsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListConnectorsQuery, ListConnectorsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListConnectorsQuery, ListConnectorsQueryVariables>(ListConnectorsDocument, options);
        }
export type ListConnectorsQueryHookResult = ReturnType<typeof useListConnectorsQuery>;
export type ListConnectorsLazyQueryHookResult = ReturnType<typeof useListConnectorsLazyQuery>;
export type ListConnectorsSuspenseQueryHookResult = ReturnType<typeof useListConnectorsSuspenseQuery>;
export type ListConnectorsQueryResult = Apollo.QueryResult<ListConnectorsQuery, ListConnectorsQueryVariables>;
export const ListPaymentConnectorsDocument = gql`
    query ListPaymentConnectors($legalEntityId: String!) {
  listConnectors(legalEntityId: $legalEntityId) {
    ...ConnectorFields
  }
}
    ${ConnectorFieldsFragmentDoc}`;

/**
 * __useListPaymentConnectorsQuery__
 *
 * To run a query within a React component, call `useListPaymentConnectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPaymentConnectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPaymentConnectorsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListPaymentConnectorsQuery(baseOptions: Apollo.QueryHookOptions<ListPaymentConnectorsQuery, ListPaymentConnectorsQueryVariables> & ({ variables: ListPaymentConnectorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPaymentConnectorsQuery, ListPaymentConnectorsQueryVariables>(ListPaymentConnectorsDocument, options);
      }
export function useListPaymentConnectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPaymentConnectorsQuery, ListPaymentConnectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPaymentConnectorsQuery, ListPaymentConnectorsQueryVariables>(ListPaymentConnectorsDocument, options);
        }
export function useListPaymentConnectorsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListPaymentConnectorsQuery, ListPaymentConnectorsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPaymentConnectorsQuery, ListPaymentConnectorsQueryVariables>(ListPaymentConnectorsDocument, options);
        }
export type ListPaymentConnectorsQueryHookResult = ReturnType<typeof useListPaymentConnectorsQuery>;
export type ListPaymentConnectorsLazyQueryHookResult = ReturnType<typeof useListPaymentConnectorsLazyQuery>;
export type ListPaymentConnectorsSuspenseQueryHookResult = ReturnType<typeof useListPaymentConnectorsSuspenseQuery>;
export type ListPaymentConnectorsQueryResult = Apollo.QueryResult<ListPaymentConnectorsQuery, ListPaymentConnectorsQueryVariables>;
export const ListAuthConnectionsDocument = gql`
    query ListAuthConnections($legalEntityId: String!) {
  listAuthConnections(legalEntityId: $legalEntityId) {
    ...ConnectionSummaryFields
  }
}
    ${ConnectionSummaryFieldsFragmentDoc}`;

/**
 * __useListAuthConnectionsQuery__
 *
 * To run a query within a React component, call `useListAuthConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAuthConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAuthConnectionsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListAuthConnectionsQuery(baseOptions: Apollo.QueryHookOptions<ListAuthConnectionsQuery, ListAuthConnectionsQueryVariables> & ({ variables: ListAuthConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAuthConnectionsQuery, ListAuthConnectionsQueryVariables>(ListAuthConnectionsDocument, options);
      }
export function useListAuthConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAuthConnectionsQuery, ListAuthConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAuthConnectionsQuery, ListAuthConnectionsQueryVariables>(ListAuthConnectionsDocument, options);
        }
export function useListAuthConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAuthConnectionsQuery, ListAuthConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAuthConnectionsQuery, ListAuthConnectionsQueryVariables>(ListAuthConnectionsDocument, options);
        }
export type ListAuthConnectionsQueryHookResult = ReturnType<typeof useListAuthConnectionsQuery>;
export type ListAuthConnectionsLazyQueryHookResult = ReturnType<typeof useListAuthConnectionsLazyQuery>;
export type ListAuthConnectionsSuspenseQueryHookResult = ReturnType<typeof useListAuthConnectionsSuspenseQuery>;
export type ListAuthConnectionsQueryResult = Apollo.QueryResult<ListAuthConnectionsQuery, ListAuthConnectionsQueryVariables>;
export const ReadConnectionDocument = gql`
    query ReadConnection($legalEntityId: String!, $connectionId: String!) {
  readConnection(legalEntityId: $legalEntityId, connectionId: $connectionId) {
    ...ConnectionFields
  }
}
    ${ConnectionFieldsFragmentDoc}`;

/**
 * __useReadConnectionQuery__
 *
 * To run a query within a React component, call `useReadConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadConnectionQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useReadConnectionQuery(baseOptions: Apollo.QueryHookOptions<ReadConnectionQuery, ReadConnectionQueryVariables> & ({ variables: ReadConnectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadConnectionQuery, ReadConnectionQueryVariables>(ReadConnectionDocument, options);
      }
export function useReadConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadConnectionQuery, ReadConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadConnectionQuery, ReadConnectionQueryVariables>(ReadConnectionDocument, options);
        }
export function useReadConnectionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadConnectionQuery, ReadConnectionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadConnectionQuery, ReadConnectionQueryVariables>(ReadConnectionDocument, options);
        }
export type ReadConnectionQueryHookResult = ReturnType<typeof useReadConnectionQuery>;
export type ReadConnectionLazyQueryHookResult = ReturnType<typeof useReadConnectionLazyQuery>;
export type ReadConnectionSuspenseQueryHookResult = ReturnType<typeof useReadConnectionSuspenseQuery>;
export type ReadConnectionQueryResult = Apollo.QueryResult<ReadConnectionQuery, ReadConnectionQueryVariables>;
export const UpdateTransactionFieldsDocument = gql`
    mutation UpdateTransactionFields($legalEntityId: String!, $viewKey: ViewKey!, $updates: [InputUpdateFields!]!) {
  updateTransactionFields(
    legalEntityId: $legalEntityId
    viewKey: $viewKey
    updates: $updates
  )
}
    `;
export type UpdateTransactionFieldsMutationFn = Apollo.MutationFunction<UpdateTransactionFieldsMutation, UpdateTransactionFieldsMutationVariables>;

/**
 * __useUpdateTransactionFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionFieldsMutation, { data, loading, error }] = useUpdateTransactionFieldsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      viewKey: // value for 'viewKey'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateTransactionFieldsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionFieldsMutation, UpdateTransactionFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionFieldsMutation, UpdateTransactionFieldsMutationVariables>(UpdateTransactionFieldsDocument, options);
      }
export type UpdateTransactionFieldsMutationHookResult = ReturnType<typeof useUpdateTransactionFieldsMutation>;
export type UpdateTransactionFieldsMutationResult = Apollo.MutationResult<UpdateTransactionFieldsMutation>;
export type UpdateTransactionFieldsMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionFieldsMutation, UpdateTransactionFieldsMutationVariables>;
export const CreateTransactionDocument = gql`
    mutation CreateTransaction($legalEntityId: String!, $transaction: InputTransaction!) {
  createTransaction(legalEntityId: $legalEntityId, transaction: $transaction) {
    transactionFactIds
  }
}
    `;
export type CreateTransactionMutationFn = Apollo.MutationFunction<CreateTransactionMutation, CreateTransactionMutationVariables>;

/**
 * __useCreateTransactionMutation__
 *
 * To run a mutation, you first call `useCreateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionMutation, { data, loading, error }] = useCreateTransactionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      transaction: // value for 'transaction'
 *   },
 * });
 */
export function useCreateTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransactionMutation, CreateTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransactionMutation, CreateTransactionMutationVariables>(CreateTransactionDocument, options);
      }
export type CreateTransactionMutationHookResult = ReturnType<typeof useCreateTransactionMutation>;
export type CreateTransactionMutationResult = Apollo.MutationResult<CreateTransactionMutation>;
export type CreateTransactionMutationOptions = Apollo.BaseMutationOptions<CreateTransactionMutation, CreateTransactionMutationVariables>;
export const DeleteTransactionsDocument = gql`
    mutation DeleteTransactions($legalEntityId: String!, $factIds: [String!]!) {
  deleteTransactions(legalEntityId: $legalEntityId, factIds: $factIds)
}
    `;
export type DeleteTransactionsMutationFn = Apollo.MutationFunction<DeleteTransactionsMutation, DeleteTransactionsMutationVariables>;

/**
 * __useDeleteTransactionsMutation__
 *
 * To run a mutation, you first call `useDeleteTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransactionsMutation, { data, loading, error }] = useDeleteTransactionsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      factIds: // value for 'factIds'
 *   },
 * });
 */
export function useDeleteTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTransactionsMutation, DeleteTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTransactionsMutation, DeleteTransactionsMutationVariables>(DeleteTransactionsDocument, options);
      }
export type DeleteTransactionsMutationHookResult = ReturnType<typeof useDeleteTransactionsMutation>;
export type DeleteTransactionsMutationResult = Apollo.MutationResult<DeleteTransactionsMutation>;
export type DeleteTransactionsMutationOptions = Apollo.BaseMutationOptions<DeleteTransactionsMutation, DeleteTransactionsMutationVariables>;
export const UploadBankFeedEntriesDocument = gql`
    mutation UploadBankFeedEntries($legalEntityId: String!, $fileName: String!, $categoryId: String!, $bankFeedEntries: [BankFeedEntry!]!) {
  uploadBankFeedEntries(
    legalEntityId: $legalEntityId
    fileName: $fileName
    categoryId: $categoryId
    bankFeedEntries: $bankFeedEntries
  ) {
    transactionFactIds
  }
}
    `;
export type UploadBankFeedEntriesMutationFn = Apollo.MutationFunction<UploadBankFeedEntriesMutation, UploadBankFeedEntriesMutationVariables>;

/**
 * __useUploadBankFeedEntriesMutation__
 *
 * To run a mutation, you first call `useUploadBankFeedEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBankFeedEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBankFeedEntriesMutation, { data, loading, error }] = useUploadBankFeedEntriesMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      fileName: // value for 'fileName'
 *      categoryId: // value for 'categoryId'
 *      bankFeedEntries: // value for 'bankFeedEntries'
 *   },
 * });
 */
export function useUploadBankFeedEntriesMutation(baseOptions?: Apollo.MutationHookOptions<UploadBankFeedEntriesMutation, UploadBankFeedEntriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBankFeedEntriesMutation, UploadBankFeedEntriesMutationVariables>(UploadBankFeedEntriesDocument, options);
      }
export type UploadBankFeedEntriesMutationHookResult = ReturnType<typeof useUploadBankFeedEntriesMutation>;
export type UploadBankFeedEntriesMutationResult = Apollo.MutationResult<UploadBankFeedEntriesMutation>;
export type UploadBankFeedEntriesMutationOptions = Apollo.BaseMutationOptions<UploadBankFeedEntriesMutation, UploadBankFeedEntriesMutationVariables>;
export const UploadJournalEntriesDocument = gql`
    mutation UploadJournalEntries($legalEntityId: String!, $filename: String!, $transactions: [InputTransaction!]!) {
  uploadJournalEntries(
    legalEntityId: $legalEntityId
    filename: $filename
    transactions: $transactions
  ) {
    transactionFactIds
  }
}
    `;
export type UploadJournalEntriesMutationFn = Apollo.MutationFunction<UploadJournalEntriesMutation, UploadJournalEntriesMutationVariables>;

/**
 * __useUploadJournalEntriesMutation__
 *
 * To run a mutation, you first call `useUploadJournalEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadJournalEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadJournalEntriesMutation, { data, loading, error }] = useUploadJournalEntriesMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      filename: // value for 'filename'
 *      transactions: // value for 'transactions'
 *   },
 * });
 */
export function useUploadJournalEntriesMutation(baseOptions?: Apollo.MutationHookOptions<UploadJournalEntriesMutation, UploadJournalEntriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadJournalEntriesMutation, UploadJournalEntriesMutationVariables>(UploadJournalEntriesDocument, options);
      }
export type UploadJournalEntriesMutationHookResult = ReturnType<typeof useUploadJournalEntriesMutation>;
export type UploadJournalEntriesMutationResult = Apollo.MutationResult<UploadJournalEntriesMutation>;
export type UploadJournalEntriesMutationOptions = Apollo.BaseMutationOptions<UploadJournalEntriesMutation, UploadJournalEntriesMutationVariables>;
export const UpdateTransactionDocument = gql`
    mutation UpdateTransaction($legalEntityId: String!, $transaction: InputTransaction!, $filter: TransactionFilter!) {
  updateTransaction(
    legalEntityId: $legalEntityId
    transaction: $transaction
    filter: $filter
  )
}
    `;
export type UpdateTransactionMutationFn = Apollo.MutationFunction<UpdateTransactionMutation, UpdateTransactionMutationVariables>;

/**
 * __useUpdateTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionMutation, { data, loading, error }] = useUpdateTransactionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      transaction: // value for 'transaction'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateTransactionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionMutation, UpdateTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionMutation, UpdateTransactionMutationVariables>(UpdateTransactionDocument, options);
      }
export type UpdateTransactionMutationHookResult = ReturnType<typeof useUpdateTransactionMutation>;
export type UpdateTransactionMutationResult = Apollo.MutationResult<UpdateTransactionMutation>;
export type UpdateTransactionMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionMutation, UpdateTransactionMutationVariables>;
export const ListTransactionsDocument = gql`
    query ListTransactions($pagination: Pagination!, $paginationDirection: DirectionFromOrigin!, $sortColumnName: TransactionColumnName!, $sortDirection: SortDirection!, $filter: TransactionFilter!, $intervalOrigin: IntervalOrigin!, $naturalFlow: Flow) {
  listTransactions(
    filter: $filter
    origin: $intervalOrigin
    pagination: $pagination
    direction: $paginationDirection
    order: {transactionOrderPairs: [{columnName: $sortColumnName, direction: $sortDirection}]}
    naturalFlow: $naturalFlow
  ) {
    ...TransactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;

/**
 * __useListTransactionsQuery__
 *
 * To run a query within a React component, call `useListTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      paginationDirection: // value for 'paginationDirection'
 *      sortColumnName: // value for 'sortColumnName'
 *      sortDirection: // value for 'sortDirection'
 *      filter: // value for 'filter'
 *      intervalOrigin: // value for 'intervalOrigin'
 *      naturalFlow: // value for 'naturalFlow'
 *   },
 * });
 */
export function useListTransactionsQuery(baseOptions: Apollo.QueryHookOptions<ListTransactionsQuery, ListTransactionsQueryVariables> & ({ variables: ListTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTransactionsQuery, ListTransactionsQueryVariables>(ListTransactionsDocument, options);
      }
export function useListTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionsQuery, ListTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTransactionsQuery, ListTransactionsQueryVariables>(ListTransactionsDocument, options);
        }
export function useListTransactionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListTransactionsQuery, ListTransactionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListTransactionsQuery, ListTransactionsQueryVariables>(ListTransactionsDocument, options);
        }
export type ListTransactionsQueryHookResult = ReturnType<typeof useListTransactionsQuery>;
export type ListTransactionsLazyQueryHookResult = ReturnType<typeof useListTransactionsLazyQuery>;
export type ListTransactionsSuspenseQueryHookResult = ReturnType<typeof useListTransactionsSuspenseQuery>;
export type ListTransactionsQueryResult = Apollo.QueryResult<ListTransactionsQuery, ListTransactionsQueryVariables>;
export const SummarizeTransactionsByCategoryByTimeDocument = gql`
    query SummarizeTransactionsByCategoryByTime($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $sort: DimensionSortMode, $limit: Int, $masks: [[SummaryDimension!]!], $asPermanent: Boolean, $directBookings: Boolean) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    masks: $masks
    asPermanent: $asPermanent
    directBookings: $directBookings
  ) {
    category(limit: $limit, sort: $sort) {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
    partialData
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByCategoryByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByCategoryByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByCategoryByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByCategoryByTimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      masks: // value for 'masks'
 *      asPermanent: // value for 'asPermanent'
 *      directBookings: // value for 'directBookings'
 *   },
 * });
 */
export function useSummarizeTransactionsByCategoryByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByCategoryByTimeQuery, SummarizeTransactionsByCategoryByTimeQueryVariables> & ({ variables: SummarizeTransactionsByCategoryByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByCategoryByTimeQuery, SummarizeTransactionsByCategoryByTimeQueryVariables>(SummarizeTransactionsByCategoryByTimeDocument, options);
      }
export function useSummarizeTransactionsByCategoryByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByCategoryByTimeQuery, SummarizeTransactionsByCategoryByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByCategoryByTimeQuery, SummarizeTransactionsByCategoryByTimeQueryVariables>(SummarizeTransactionsByCategoryByTimeDocument, options);
        }
export function useSummarizeTransactionsByCategoryByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByCategoryByTimeQuery, SummarizeTransactionsByCategoryByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByCategoryByTimeQuery, SummarizeTransactionsByCategoryByTimeQueryVariables>(SummarizeTransactionsByCategoryByTimeDocument, options);
        }
export type SummarizeTransactionsByCategoryByTimeQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategoryByTimeQuery>;
export type SummarizeTransactionsByCategoryByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategoryByTimeLazyQuery>;
export type SummarizeTransactionsByCategoryByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategoryByTimeSuspenseQuery>;
export type SummarizeTransactionsByCategoryByTimeQueryResult = Apollo.QueryResult<SummarizeTransactionsByCategoryByTimeQuery, SummarizeTransactionsByCategoryByTimeQueryVariables>;
export const ReadTransactionFactIdDocument = gql`
    query ReadTransactionFactId($legalEntityId: String!, $dataSourceId: String!, $externalId: String!, $externalLineItemId: String!) {
  readFactId(
    legalEntityId: $legalEntityId
    dataSourceId: $dataSourceId
    externalId: $externalId
    externalLineItemId: $externalLineItemId
  )
}
    `;

/**
 * __useReadTransactionFactIdQuery__
 *
 * To run a query within a React component, call `useReadTransactionFactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTransactionFactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTransactionFactIdQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      dataSourceId: // value for 'dataSourceId'
 *      externalId: // value for 'externalId'
 *      externalLineItemId: // value for 'externalLineItemId'
 *   },
 * });
 */
export function useReadTransactionFactIdQuery(baseOptions: Apollo.QueryHookOptions<ReadTransactionFactIdQuery, ReadTransactionFactIdQueryVariables> & ({ variables: ReadTransactionFactIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTransactionFactIdQuery, ReadTransactionFactIdQueryVariables>(ReadTransactionFactIdDocument, options);
      }
export function useReadTransactionFactIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTransactionFactIdQuery, ReadTransactionFactIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTransactionFactIdQuery, ReadTransactionFactIdQueryVariables>(ReadTransactionFactIdDocument, options);
        }
export function useReadTransactionFactIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTransactionFactIdQuery, ReadTransactionFactIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTransactionFactIdQuery, ReadTransactionFactIdQueryVariables>(ReadTransactionFactIdDocument, options);
        }
export type ReadTransactionFactIdQueryHookResult = ReturnType<typeof useReadTransactionFactIdQuery>;
export type ReadTransactionFactIdLazyQueryHookResult = ReturnType<typeof useReadTransactionFactIdLazyQuery>;
export type ReadTransactionFactIdSuspenseQueryHookResult = ReturnType<typeof useReadTransactionFactIdSuspenseQuery>;
export type ReadTransactionFactIdQueryResult = Apollo.QueryResult<ReadTransactionFactIdQuery, ReadTransactionFactIdQueryVariables>;
export const SummarizeTransactionsByTimeByPartyDocument = gql`
    query SummarizeTransactionsByTimeByParty($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $sort: DimensionSortMode, $limit: Int, $asPermanent: Boolean, $masks: [[SummaryDimension!]!], $matchBy: Matcher, $directBookings: Boolean, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Time, Party]
    masks: $masks
    asPermanent: $asPermanent
    matchBy: $matchBy
    directBookings: $directBookings
    defaultCategoryType: $defaultCategoryType
  ) {
    time {
      summary {
        ...TransactionSummaryFields
      }
      by {
        party(limit: $limit, sort: $sort) {
          partyObject {
            ...EntityPartyFields
          }
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
    partialData
  }
}
    ${TransactionSummaryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByTimeByPartyQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByTimeByPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByTimeByPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByTimeByPartyQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      asPermanent: // value for 'asPermanent'
 *      masks: // value for 'masks'
 *      matchBy: // value for 'matchBy'
 *      directBookings: // value for 'directBookings'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeTransactionsByTimeByPartyQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByTimeByPartyQuery, SummarizeTransactionsByTimeByPartyQueryVariables> & ({ variables: SummarizeTransactionsByTimeByPartyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByTimeByPartyQuery, SummarizeTransactionsByTimeByPartyQueryVariables>(SummarizeTransactionsByTimeByPartyDocument, options);
      }
export function useSummarizeTransactionsByTimeByPartyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByTimeByPartyQuery, SummarizeTransactionsByTimeByPartyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByTimeByPartyQuery, SummarizeTransactionsByTimeByPartyQueryVariables>(SummarizeTransactionsByTimeByPartyDocument, options);
        }
export function useSummarizeTransactionsByTimeByPartySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByTimeByPartyQuery, SummarizeTransactionsByTimeByPartyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByTimeByPartyQuery, SummarizeTransactionsByTimeByPartyQueryVariables>(SummarizeTransactionsByTimeByPartyDocument, options);
        }
export type SummarizeTransactionsByTimeByPartyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByPartyQuery>;
export type SummarizeTransactionsByTimeByPartyLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByPartyLazyQuery>;
export type SummarizeTransactionsByTimeByPartySuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByPartySuspenseQuery>;
export type SummarizeTransactionsByTimeByPartyQueryResult = Apollo.QueryResult<SummarizeTransactionsByTimeByPartyQuery, SummarizeTransactionsByTimeByPartyQueryVariables>;
export const SummarizeTransactionsByCategoryDocument = gql`
    query SummarizeTransactionsByCategory($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $sort: DimensionSortMode, $limit: Int, $asPermanent: Boolean, $directBookings: Boolean, $naturalFlow: Flow, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Category]
    asPermanent: $asPermanent
    directBookings: $directBookings
    naturalFlow: $naturalFlow
    defaultCategoryType: $defaultCategoryType
  ) {
    category(limit: $limit, sort: $sort) {
      categoryObject {
        ...EntityCategoryFields
      }
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByCategoryQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByCategoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      asPermanent: // value for 'asPermanent'
 *      directBookings: // value for 'directBookings'
 *      naturalFlow: // value for 'naturalFlow'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeTransactionsByCategoryQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByCategoryQuery, SummarizeTransactionsByCategoryQueryVariables> & ({ variables: SummarizeTransactionsByCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByCategoryQuery, SummarizeTransactionsByCategoryQueryVariables>(SummarizeTransactionsByCategoryDocument, options);
      }
export function useSummarizeTransactionsByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByCategoryQuery, SummarizeTransactionsByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByCategoryQuery, SummarizeTransactionsByCategoryQueryVariables>(SummarizeTransactionsByCategoryDocument, options);
        }
export function useSummarizeTransactionsByCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByCategoryQuery, SummarizeTransactionsByCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByCategoryQuery, SummarizeTransactionsByCategoryQueryVariables>(SummarizeTransactionsByCategoryDocument, options);
        }
export type SummarizeTransactionsByCategoryQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategoryQuery>;
export type SummarizeTransactionsByCategoryLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategoryLazyQuery>;
export type SummarizeTransactionsByCategorySuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategorySuspenseQuery>;
export type SummarizeTransactionsByCategoryQueryResult = Apollo.QueryResult<SummarizeTransactionsByCategoryQuery, SummarizeTransactionsByCategoryQueryVariables>;
export const SummarizeTransactionsByPartyDocument = gql`
    query SummarizeTransactionsByParty($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $asPermanent: Boolean, $sort: DimensionSortMode, $limit: Int, $matchBy: Matcher, $masks: [[SummaryDimension!]!], $directBookings: Boolean, $naturalFlow: Flow, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Party]
    asPermanent: $asPermanent
    matchBy: $matchBy
    masks: $masks
    directBookings: $directBookings
    naturalFlow: $naturalFlow
    defaultCategoryType: $defaultCategoryType
  ) {
    party(limit: $limit, sort: $sort) {
      partyObject {
        ...EntityPartyFields
      }
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
}
    ${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByPartyQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByPartyQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      asPermanent: // value for 'asPermanent'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      matchBy: // value for 'matchBy'
 *      masks: // value for 'masks'
 *      directBookings: // value for 'directBookings'
 *      naturalFlow: // value for 'naturalFlow'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeTransactionsByPartyQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByPartyQuery, SummarizeTransactionsByPartyQueryVariables> & ({ variables: SummarizeTransactionsByPartyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByPartyQuery, SummarizeTransactionsByPartyQueryVariables>(SummarizeTransactionsByPartyDocument, options);
      }
export function useSummarizeTransactionsByPartyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByPartyQuery, SummarizeTransactionsByPartyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByPartyQuery, SummarizeTransactionsByPartyQueryVariables>(SummarizeTransactionsByPartyDocument, options);
        }
export function useSummarizeTransactionsByPartySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByPartyQuery, SummarizeTransactionsByPartyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByPartyQuery, SummarizeTransactionsByPartyQueryVariables>(SummarizeTransactionsByPartyDocument, options);
        }
export type SummarizeTransactionsByPartyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartyQuery>;
export type SummarizeTransactionsByPartyLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartyLazyQuery>;
export type SummarizeTransactionsByPartySuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartySuspenseQuery>;
export type SummarizeTransactionsByPartyQueryResult = Apollo.QueryResult<SummarizeTransactionsByPartyQuery, SummarizeTransactionsByPartyQueryVariables>;
export const ReadCategorySuggestionsDocument = gql`
    query ReadCategorySuggestions($transactionFactId: String!, $legalEntityId: String!, $entryType: EntryType!) {
  readCategorySuggestions(
    transactionFactId: $transactionFactId
    legalEntityId: $legalEntityId
    entryType: $entryType
  ) {
    ...CategorySuggestionFields
  }
}
    ${CategorySuggestionFieldsFragmentDoc}`;

/**
 * __useReadCategorySuggestionsQuery__
 *
 * To run a query within a React component, call `useReadCategorySuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCategorySuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCategorySuggestionsQuery({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *      legalEntityId: // value for 'legalEntityId'
 *      entryType: // value for 'entryType'
 *   },
 * });
 */
export function useReadCategorySuggestionsQuery(baseOptions: Apollo.QueryHookOptions<ReadCategorySuggestionsQuery, ReadCategorySuggestionsQueryVariables> & ({ variables: ReadCategorySuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCategorySuggestionsQuery, ReadCategorySuggestionsQueryVariables>(ReadCategorySuggestionsDocument, options);
      }
export function useReadCategorySuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCategorySuggestionsQuery, ReadCategorySuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCategorySuggestionsQuery, ReadCategorySuggestionsQueryVariables>(ReadCategorySuggestionsDocument, options);
        }
export function useReadCategorySuggestionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadCategorySuggestionsQuery, ReadCategorySuggestionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadCategorySuggestionsQuery, ReadCategorySuggestionsQueryVariables>(ReadCategorySuggestionsDocument, options);
        }
export type ReadCategorySuggestionsQueryHookResult = ReturnType<typeof useReadCategorySuggestionsQuery>;
export type ReadCategorySuggestionsLazyQueryHookResult = ReturnType<typeof useReadCategorySuggestionsLazyQuery>;
export type ReadCategorySuggestionsSuspenseQueryHookResult = ReturnType<typeof useReadCategorySuggestionsSuspenseQuery>;
export type ReadCategorySuggestionsQueryResult = Apollo.QueryResult<ReadCategorySuggestionsQuery, ReadCategorySuggestionsQueryVariables>;
export const SummarizeTransactionsByPartyByTimeDocument = gql`
    query SummarizeTransactionsByPartyByTime($filter: SummaryFilterInput!, $paginationDirection: DirectionFromOrigin!, $intervalOrigin: IntervalOrigin!, $asPermanent: Boolean, $sort: DimensionSortMode, $limit: Int, $masks: [[SummaryDimension!]!], $matchBy: Matcher, $directBookings: Boolean, $naturalFlow: Flow, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $intervalOrigin
    direction: $paginationDirection
    dimensions: [Party, Time]
    asPermanent: $asPermanent
    matchBy: $matchBy
    masks: $masks
    directBookings: $directBookings
    naturalFlow: $naturalFlow
    defaultCategoryType: $defaultCategoryType
  ) {
    party(limit: $limit, sort: $sort) {
      partyObject {
        ...EntityPartyFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
    partialData
  }
}
    ${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByPartyByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByPartyByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByPartyByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByPartyByTimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paginationDirection: // value for 'paginationDirection'
 *      intervalOrigin: // value for 'intervalOrigin'
 *      asPermanent: // value for 'asPermanent'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      masks: // value for 'masks'
 *      matchBy: // value for 'matchBy'
 *      directBookings: // value for 'directBookings'
 *      naturalFlow: // value for 'naturalFlow'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeTransactionsByPartyByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByPartyByTimeQuery, SummarizeTransactionsByPartyByTimeQueryVariables> & ({ variables: SummarizeTransactionsByPartyByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByPartyByTimeQuery, SummarizeTransactionsByPartyByTimeQueryVariables>(SummarizeTransactionsByPartyByTimeDocument, options);
      }
export function useSummarizeTransactionsByPartyByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByPartyByTimeQuery, SummarizeTransactionsByPartyByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByPartyByTimeQuery, SummarizeTransactionsByPartyByTimeQueryVariables>(SummarizeTransactionsByPartyByTimeDocument, options);
        }
export function useSummarizeTransactionsByPartyByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByPartyByTimeQuery, SummarizeTransactionsByPartyByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByPartyByTimeQuery, SummarizeTransactionsByPartyByTimeQueryVariables>(SummarizeTransactionsByPartyByTimeDocument, options);
        }
export type SummarizeTransactionsByPartyByTimeQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartyByTimeQuery>;
export type SummarizeTransactionsByPartyByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartyByTimeLazyQuery>;
export type SummarizeTransactionsByPartyByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartyByTimeSuspenseQuery>;
export type SummarizeTransactionsByPartyByTimeQueryResult = Apollo.QueryResult<SummarizeTransactionsByPartyByTimeQuery, SummarizeTransactionsByPartyByTimeQueryVariables>;
export const SummarizeTransactionsByDepartmentByTimeDocument = gql`
    query SummarizeTransactionsByDepartmentByTime($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $directBookings: Boolean) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Department, Time]
    directBookings: $directBookings
  ) {
    department {
      departmentId
      departmentObject {
        ...EntityDepartmentFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
    partialData
  }
}
    ${EntityDepartmentFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByDepartmentByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByDepartmentByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByDepartmentByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByDepartmentByTimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      directBookings: // value for 'directBookings'
 *   },
 * });
 */
export function useSummarizeTransactionsByDepartmentByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByDepartmentByTimeQuery, SummarizeTransactionsByDepartmentByTimeQueryVariables> & ({ variables: SummarizeTransactionsByDepartmentByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByDepartmentByTimeQuery, SummarizeTransactionsByDepartmentByTimeQueryVariables>(SummarizeTransactionsByDepartmentByTimeDocument, options);
      }
export function useSummarizeTransactionsByDepartmentByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByDepartmentByTimeQuery, SummarizeTransactionsByDepartmentByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByDepartmentByTimeQuery, SummarizeTransactionsByDepartmentByTimeQueryVariables>(SummarizeTransactionsByDepartmentByTimeDocument, options);
        }
export function useSummarizeTransactionsByDepartmentByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByDepartmentByTimeQuery, SummarizeTransactionsByDepartmentByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByDepartmentByTimeQuery, SummarizeTransactionsByDepartmentByTimeQueryVariables>(SummarizeTransactionsByDepartmentByTimeDocument, options);
        }
export type SummarizeTransactionsByDepartmentByTimeQueryHookResult = ReturnType<typeof useSummarizeTransactionsByDepartmentByTimeQuery>;
export type SummarizeTransactionsByDepartmentByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByDepartmentByTimeLazyQuery>;
export type SummarizeTransactionsByDepartmentByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByDepartmentByTimeSuspenseQuery>;
export type SummarizeTransactionsByDepartmentByTimeQueryResult = Apollo.QueryResult<SummarizeTransactionsByDepartmentByTimeQuery, SummarizeTransactionsByDepartmentByTimeQueryVariables>;
export const ListSimilarTransactionsDocument = gql`
    query ListSimilarTransactions($viewKey: ViewKey!, $factId: String!, $entryType: EntryType!, $categoryId: String, $numNeighbors: Int) {
  listSimilarTransactions: fetchSimilarTransactions(
    viewKey: $viewKey
    factId: $factId
    entryType: $entryType
    categoryId: $categoryId
    numNeighbors: $numNeighbors
  ) {
    ...TransactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;

/**
 * __useListSimilarTransactionsQuery__
 *
 * To run a query within a React component, call `useListSimilarTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSimilarTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSimilarTransactionsQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      factId: // value for 'factId'
 *      entryType: // value for 'entryType'
 *      categoryId: // value for 'categoryId'
 *      numNeighbors: // value for 'numNeighbors'
 *   },
 * });
 */
export function useListSimilarTransactionsQuery(baseOptions: Apollo.QueryHookOptions<ListSimilarTransactionsQuery, ListSimilarTransactionsQueryVariables> & ({ variables: ListSimilarTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSimilarTransactionsQuery, ListSimilarTransactionsQueryVariables>(ListSimilarTransactionsDocument, options);
      }
export function useListSimilarTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSimilarTransactionsQuery, ListSimilarTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSimilarTransactionsQuery, ListSimilarTransactionsQueryVariables>(ListSimilarTransactionsDocument, options);
        }
export function useListSimilarTransactionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListSimilarTransactionsQuery, ListSimilarTransactionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListSimilarTransactionsQuery, ListSimilarTransactionsQueryVariables>(ListSimilarTransactionsDocument, options);
        }
export type ListSimilarTransactionsQueryHookResult = ReturnType<typeof useListSimilarTransactionsQuery>;
export type ListSimilarTransactionsLazyQueryHookResult = ReturnType<typeof useListSimilarTransactionsLazyQuery>;
export type ListSimilarTransactionsSuspenseQueryHookResult = ReturnType<typeof useListSimilarTransactionsSuspenseQuery>;
export type ListSimilarTransactionsQueryResult = Apollo.QueryResult<ListSimilarTransactionsQuery, ListSimilarTransactionsQueryVariables>;
export const SummarizeTransactionsByTimeByCategoryDocument = gql`
    query SummarizeTransactionsByTimeByCategory($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $sort: DimensionSortMode!, $limit: Int!, $asPermanent: Boolean, $directBookings: Boolean) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Time, Category]
    asPermanent: $asPermanent
    directBookings: $directBookings
  ) {
    time {
      by {
        category(limit: $limit, sort: $sort) {
          categoryObject {
            ...EntityCategoryFields
          }
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
    partialData
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByTimeByCategoryQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByTimeByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByTimeByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByTimeByCategoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      asPermanent: // value for 'asPermanent'
 *      directBookings: // value for 'directBookings'
 *   },
 * });
 */
export function useSummarizeTransactionsByTimeByCategoryQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByTimeByCategoryQuery, SummarizeTransactionsByTimeByCategoryQueryVariables> & ({ variables: SummarizeTransactionsByTimeByCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByTimeByCategoryQuery, SummarizeTransactionsByTimeByCategoryQueryVariables>(SummarizeTransactionsByTimeByCategoryDocument, options);
      }
export function useSummarizeTransactionsByTimeByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByTimeByCategoryQuery, SummarizeTransactionsByTimeByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByTimeByCategoryQuery, SummarizeTransactionsByTimeByCategoryQueryVariables>(SummarizeTransactionsByTimeByCategoryDocument, options);
        }
export function useSummarizeTransactionsByTimeByCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByTimeByCategoryQuery, SummarizeTransactionsByTimeByCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByTimeByCategoryQuery, SummarizeTransactionsByTimeByCategoryQueryVariables>(SummarizeTransactionsByTimeByCategoryDocument, options);
        }
export type SummarizeTransactionsByTimeByCategoryQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByCategoryQuery>;
export type SummarizeTransactionsByTimeByCategoryLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByCategoryLazyQuery>;
export type SummarizeTransactionsByTimeByCategorySuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByCategorySuspenseQuery>;
export type SummarizeTransactionsByTimeByCategoryQueryResult = Apollo.QueryResult<SummarizeTransactionsByTimeByCategoryQuery, SummarizeTransactionsByTimeByCategoryQueryVariables>;
export const ExportTransactionsDocument = gql`
    query ExportTransactions($filter: TransactionFilter!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $fileName: String, $temporary: Boolean, $singleColumnAmount: Boolean, $fields: [CsvExportField!], $orderPairs: [TransactionOrderPair!] = []) {
  exportTransactions(
    filter: $filter
    direction: $direction
    origin: $origin
    fileName: $fileName
    temporary: $temporary
    singleColumnAmount: $singleColumnAmount
    fields: $fields
    order: {transactionOrderPairs: $orderPairs}
  ) {
    ...DocumentInitialSegmentFields
  }
}
    ${DocumentInitialSegmentFieldsFragmentDoc}`;

/**
 * __useExportTransactionsQuery__
 *
 * To run a query within a React component, call `useExportTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTransactionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      fileName: // value for 'fileName'
 *      temporary: // value for 'temporary'
 *      singleColumnAmount: // value for 'singleColumnAmount'
 *      fields: // value for 'fields'
 *      orderPairs: // value for 'orderPairs'
 *   },
 * });
 */
export function useExportTransactionsQuery(baseOptions: Apollo.QueryHookOptions<ExportTransactionsQuery, ExportTransactionsQueryVariables> & ({ variables: ExportTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportTransactionsQuery, ExportTransactionsQueryVariables>(ExportTransactionsDocument, options);
      }
export function useExportTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportTransactionsQuery, ExportTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportTransactionsQuery, ExportTransactionsQueryVariables>(ExportTransactionsDocument, options);
        }
export function useExportTransactionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExportTransactionsQuery, ExportTransactionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportTransactionsQuery, ExportTransactionsQueryVariables>(ExportTransactionsDocument, options);
        }
export type ExportTransactionsQueryHookResult = ReturnType<typeof useExportTransactionsQuery>;
export type ExportTransactionsLazyQueryHookResult = ReturnType<typeof useExportTransactionsLazyQuery>;
export type ExportTransactionsSuspenseQueryHookResult = ReturnType<typeof useExportTransactionsSuspenseQuery>;
export type ExportTransactionsQueryResult = Apollo.QueryResult<ExportTransactionsQuery, ExportTransactionsQueryVariables>;
export const ListTransactionsByFactIdsDocument = gql`
    query ListTransactionsByFactIds($factIds: [String!]!, $viewKey: ViewKey!) {
  listTransactionsByFactIds(factIds: $factIds, viewKey: $viewKey) {
    ...TransactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;

/**
 * __useListTransactionsByFactIdsQuery__
 *
 * To run a query within a React component, call `useListTransactionsByFactIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionsByFactIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionsByFactIdsQuery({
 *   variables: {
 *      factIds: // value for 'factIds'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useListTransactionsByFactIdsQuery(baseOptions: Apollo.QueryHookOptions<ListTransactionsByFactIdsQuery, ListTransactionsByFactIdsQueryVariables> & ({ variables: ListTransactionsByFactIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTransactionsByFactIdsQuery, ListTransactionsByFactIdsQueryVariables>(ListTransactionsByFactIdsDocument, options);
      }
export function useListTransactionsByFactIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionsByFactIdsQuery, ListTransactionsByFactIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTransactionsByFactIdsQuery, ListTransactionsByFactIdsQueryVariables>(ListTransactionsByFactIdsDocument, options);
        }
export function useListTransactionsByFactIdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListTransactionsByFactIdsQuery, ListTransactionsByFactIdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListTransactionsByFactIdsQuery, ListTransactionsByFactIdsQueryVariables>(ListTransactionsByFactIdsDocument, options);
        }
export type ListTransactionsByFactIdsQueryHookResult = ReturnType<typeof useListTransactionsByFactIdsQuery>;
export type ListTransactionsByFactIdsLazyQueryHookResult = ReturnType<typeof useListTransactionsByFactIdsLazyQuery>;
export type ListTransactionsByFactIdsSuspenseQueryHookResult = ReturnType<typeof useListTransactionsByFactIdsSuspenseQuery>;
export type ListTransactionsByFactIdsQueryResult = Apollo.QueryResult<ListTransactionsByFactIdsQuery, ListTransactionsByFactIdsQueryVariables>;
export const ReadTransactionDetailsDocument = gql`
    query ReadTransactionDetails($transactionFactId: String!, $filter: TransactionFilter!) {
  readTransactionDetails(transactionFactId: $transactionFactId, filter: $filter) {
    transaction {
      ...TransactionFields
    }
    details {
      ...TransactionViewDetailFields
    }
    isInBooksClosedPeriod
  }
}
    ${TransactionFieldsFragmentDoc}
${TransactionViewDetailFieldsFragmentDoc}`;

/**
 * __useReadTransactionDetailsQuery__
 *
 * To run a query within a React component, call `useReadTransactionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTransactionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTransactionDetailsQuery({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useReadTransactionDetailsQuery(baseOptions: Apollo.QueryHookOptions<ReadTransactionDetailsQuery, ReadTransactionDetailsQueryVariables> & ({ variables: ReadTransactionDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTransactionDetailsQuery, ReadTransactionDetailsQueryVariables>(ReadTransactionDetailsDocument, options);
      }
export function useReadTransactionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTransactionDetailsQuery, ReadTransactionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTransactionDetailsQuery, ReadTransactionDetailsQueryVariables>(ReadTransactionDetailsDocument, options);
        }
export function useReadTransactionDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTransactionDetailsQuery, ReadTransactionDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTransactionDetailsQuery, ReadTransactionDetailsQueryVariables>(ReadTransactionDetailsDocument, options);
        }
export type ReadTransactionDetailsQueryHookResult = ReturnType<typeof useReadTransactionDetailsQuery>;
export type ReadTransactionDetailsLazyQueryHookResult = ReturnType<typeof useReadTransactionDetailsLazyQuery>;
export type ReadTransactionDetailsSuspenseQueryHookResult = ReturnType<typeof useReadTransactionDetailsSuspenseQuery>;
export type ReadTransactionDetailsQueryResult = Apollo.QueryResult<ReadTransactionDetailsQuery, ReadTransactionDetailsQueryVariables>;
export const SummarizeTransactionsByCategoryHierarchyByTimeDocument = gql`
    query SummarizeTransactionsByCategoryHierarchyByTime($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $asPermanent: Boolean, $directBookings: Boolean) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: $asPermanent
    directBookings: $directBookings
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
    partialData
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByCategoryHierarchyByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByCategoryHierarchyByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByCategoryHierarchyByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByCategoryHierarchyByTimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      asPermanent: // value for 'asPermanent'
 *      directBookings: // value for 'directBookings'
 *   },
 * });
 */
export function useSummarizeTransactionsByCategoryHierarchyByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByCategoryHierarchyByTimeQuery, SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables> & ({ variables: SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByCategoryHierarchyByTimeQuery, SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables>(SummarizeTransactionsByCategoryHierarchyByTimeDocument, options);
      }
export function useSummarizeTransactionsByCategoryHierarchyByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByCategoryHierarchyByTimeQuery, SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByCategoryHierarchyByTimeQuery, SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables>(SummarizeTransactionsByCategoryHierarchyByTimeDocument, options);
        }
export function useSummarizeTransactionsByCategoryHierarchyByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByCategoryHierarchyByTimeQuery, SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByCategoryHierarchyByTimeQuery, SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables>(SummarizeTransactionsByCategoryHierarchyByTimeDocument, options);
        }
export type SummarizeTransactionsByCategoryHierarchyByTimeQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategoryHierarchyByTimeQuery>;
export type SummarizeTransactionsByCategoryHierarchyByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategoryHierarchyByTimeLazyQuery>;
export type SummarizeTransactionsByCategoryHierarchyByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByCategoryHierarchyByTimeSuspenseQuery>;
export type SummarizeTransactionsByCategoryHierarchyByTimeQueryResult = Apollo.QueryResult<SummarizeTransactionsByCategoryHierarchyByTimeQuery, SummarizeTransactionsByCategoryHierarchyByTimeQueryVariables>;
export const SummarizeCategoryTransactionsByTimeDocument = gql`
    query SummarizeCategoryTransactionsByTime($filter: SummaryFilterInput!, $origin: IntervalOrigin!, $direction: DirectionFromOrigin!, $asPermanent: Boolean, $directBookings: Boolean, $naturalFlow: Flow, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Time, Category]
    masks: [Time]
    asPermanent: $asPermanent
    directBookings: $directBookings
    naturalFlow: $naturalFlow
    defaultCategoryType: $defaultCategoryType
  ) {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
}
    ${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeCategoryTransactionsByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeCategoryTransactionsByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeCategoryTransactionsByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeCategoryTransactionsByTimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      origin: // value for 'origin'
 *      direction: // value for 'direction'
 *      asPermanent: // value for 'asPermanent'
 *      directBookings: // value for 'directBookings'
 *      naturalFlow: // value for 'naturalFlow'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeCategoryTransactionsByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeCategoryTransactionsByTimeQuery, SummarizeCategoryTransactionsByTimeQueryVariables> & ({ variables: SummarizeCategoryTransactionsByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeCategoryTransactionsByTimeQuery, SummarizeCategoryTransactionsByTimeQueryVariables>(SummarizeCategoryTransactionsByTimeDocument, options);
      }
export function useSummarizeCategoryTransactionsByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeCategoryTransactionsByTimeQuery, SummarizeCategoryTransactionsByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeCategoryTransactionsByTimeQuery, SummarizeCategoryTransactionsByTimeQueryVariables>(SummarizeCategoryTransactionsByTimeDocument, options);
        }
export function useSummarizeCategoryTransactionsByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeCategoryTransactionsByTimeQuery, SummarizeCategoryTransactionsByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeCategoryTransactionsByTimeQuery, SummarizeCategoryTransactionsByTimeQueryVariables>(SummarizeCategoryTransactionsByTimeDocument, options);
        }
export type SummarizeCategoryTransactionsByTimeQueryHookResult = ReturnType<typeof useSummarizeCategoryTransactionsByTimeQuery>;
export type SummarizeCategoryTransactionsByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeCategoryTransactionsByTimeLazyQuery>;
export type SummarizeCategoryTransactionsByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeCategoryTransactionsByTimeSuspenseQuery>;
export type SummarizeCategoryTransactionsByTimeQueryResult = Apollo.QueryResult<SummarizeCategoryTransactionsByTimeQuery, SummarizeCategoryTransactionsByTimeQueryVariables>;
export const ListMetricTransactionsDocument = gql`
    query ListMetricTransactions($viewKey: ViewKey!, $metricSummaryType: MetricSummaryItem, $summaryLineType: SummaryLineItem, $pagination: Pagination!, $sortColumnName: TransactionColumnName!, $sortDirection: SortDirection!, $intervalOrigin: IntervalOrigin!) {
  listTransactions: listMetricTransactions(
    viewKey: $viewKey
    metricSummaryType: $metricSummaryType
    summaryLineType: $summaryLineType
    origin: $intervalOrigin
    pagination: $pagination
    order: {transactionOrderPairs: [{columnName: $sortColumnName, direction: $sortDirection}]}
  ) {
    ...TransactionFields
  }
}
    ${TransactionFieldsFragmentDoc}`;

/**
 * __useListMetricTransactionsQuery__
 *
 * To run a query within a React component, call `useListMetricTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMetricTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMetricTransactionsQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      metricSummaryType: // value for 'metricSummaryType'
 *      summaryLineType: // value for 'summaryLineType'
 *      pagination: // value for 'pagination'
 *      sortColumnName: // value for 'sortColumnName'
 *      sortDirection: // value for 'sortDirection'
 *      intervalOrigin: // value for 'intervalOrigin'
 *   },
 * });
 */
export function useListMetricTransactionsQuery(baseOptions: Apollo.QueryHookOptions<ListMetricTransactionsQuery, ListMetricTransactionsQueryVariables> & ({ variables: ListMetricTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMetricTransactionsQuery, ListMetricTransactionsQueryVariables>(ListMetricTransactionsDocument, options);
      }
export function useListMetricTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMetricTransactionsQuery, ListMetricTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMetricTransactionsQuery, ListMetricTransactionsQueryVariables>(ListMetricTransactionsDocument, options);
        }
export function useListMetricTransactionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListMetricTransactionsQuery, ListMetricTransactionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListMetricTransactionsQuery, ListMetricTransactionsQueryVariables>(ListMetricTransactionsDocument, options);
        }
export type ListMetricTransactionsQueryHookResult = ReturnType<typeof useListMetricTransactionsQuery>;
export type ListMetricTransactionsLazyQueryHookResult = ReturnType<typeof useListMetricTransactionsLazyQuery>;
export type ListMetricTransactionsSuspenseQueryHookResult = ReturnType<typeof useListMetricTransactionsSuspenseQuery>;
export type ListMetricTransactionsQueryResult = Apollo.QueryResult<ListMetricTransactionsQuery, ListMetricTransactionsQueryVariables>;
export const ReadLedgerTransactionByIdDocument = gql`
    query ReadLedgerTransactionById($ledgerTransactionId: String!, $viewKey: ViewKey!) {
  readLedgerTransactionById(
    ledgerTransactionId: $ledgerTransactionId
    viewKey: $viewKey
  ) {
    transaction {
      ...LedgerTransactionFields
    }
  }
}
    ${LedgerTransactionFieldsFragmentDoc}`;

/**
 * __useReadLedgerTransactionByIdQuery__
 *
 * To run a query within a React component, call `useReadLedgerTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLedgerTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLedgerTransactionByIdQuery({
 *   variables: {
 *      ledgerTransactionId: // value for 'ledgerTransactionId'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useReadLedgerTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadLedgerTransactionByIdQuery, ReadLedgerTransactionByIdQueryVariables> & ({ variables: ReadLedgerTransactionByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLedgerTransactionByIdQuery, ReadLedgerTransactionByIdQueryVariables>(ReadLedgerTransactionByIdDocument, options);
      }
export function useReadLedgerTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLedgerTransactionByIdQuery, ReadLedgerTransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLedgerTransactionByIdQuery, ReadLedgerTransactionByIdQueryVariables>(ReadLedgerTransactionByIdDocument, options);
        }
export function useReadLedgerTransactionByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLedgerTransactionByIdQuery, ReadLedgerTransactionByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLedgerTransactionByIdQuery, ReadLedgerTransactionByIdQueryVariables>(ReadLedgerTransactionByIdDocument, options);
        }
export type ReadLedgerTransactionByIdQueryHookResult = ReturnType<typeof useReadLedgerTransactionByIdQuery>;
export type ReadLedgerTransactionByIdLazyQueryHookResult = ReturnType<typeof useReadLedgerTransactionByIdLazyQuery>;
export type ReadLedgerTransactionByIdSuspenseQueryHookResult = ReturnType<typeof useReadLedgerTransactionByIdSuspenseQuery>;
export type ReadLedgerTransactionByIdQueryResult = Apollo.QueryResult<ReadLedgerTransactionByIdQuery, ReadLedgerTransactionByIdQueryVariables>;
export const SummarizeMetricTransactionsByTimeDocument = gql`
    query SummarizeMetricTransactionsByTime($viewKey: ViewKey!, $origin: IntervalOrigin!, $metricSummaryType: MetricSummaryItem, $summaryLineType: SummaryLineItem) {
  metricTransactionSummary(
    viewKey: $viewKey
    origin: $origin
    metricSummaryType: $metricSummaryType
    summaryLineType: $summaryLineType
  ) {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
}
    ${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeMetricTransactionsByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeMetricTransactionsByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeMetricTransactionsByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeMetricTransactionsByTimeQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      origin: // value for 'origin'
 *      metricSummaryType: // value for 'metricSummaryType'
 *      summaryLineType: // value for 'summaryLineType'
 *   },
 * });
 */
export function useSummarizeMetricTransactionsByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeMetricTransactionsByTimeQuery, SummarizeMetricTransactionsByTimeQueryVariables> & ({ variables: SummarizeMetricTransactionsByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeMetricTransactionsByTimeQuery, SummarizeMetricTransactionsByTimeQueryVariables>(SummarizeMetricTransactionsByTimeDocument, options);
      }
export function useSummarizeMetricTransactionsByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeMetricTransactionsByTimeQuery, SummarizeMetricTransactionsByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeMetricTransactionsByTimeQuery, SummarizeMetricTransactionsByTimeQueryVariables>(SummarizeMetricTransactionsByTimeDocument, options);
        }
export function useSummarizeMetricTransactionsByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeMetricTransactionsByTimeQuery, SummarizeMetricTransactionsByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeMetricTransactionsByTimeQuery, SummarizeMetricTransactionsByTimeQueryVariables>(SummarizeMetricTransactionsByTimeDocument, options);
        }
export type SummarizeMetricTransactionsByTimeQueryHookResult = ReturnType<typeof useSummarizeMetricTransactionsByTimeQuery>;
export type SummarizeMetricTransactionsByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeMetricTransactionsByTimeLazyQuery>;
export type SummarizeMetricTransactionsByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeMetricTransactionsByTimeSuspenseQuery>;
export type SummarizeMetricTransactionsByTimeQueryResult = Apollo.QueryResult<SummarizeMetricTransactionsByTimeQuery, SummarizeMetricTransactionsByTimeQueryVariables>;
export const MinMaxAmountDocument = gql`
    query MinMaxAmount($filter: TransactionFilter!, $origin: IntervalOrigin!, $direction: DirectionFromOrigin!) {
  minMaxAmount(filter: $filter, origin: $origin, direction: $direction) {
    ...MinMaxAmountFields
  }
}
    ${MinMaxAmountFieldsFragmentDoc}`;

/**
 * __useMinMaxAmountQuery__
 *
 * To run a query within a React component, call `useMinMaxAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinMaxAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinMaxAmountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      origin: // value for 'origin'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useMinMaxAmountQuery(baseOptions: Apollo.QueryHookOptions<MinMaxAmountQuery, MinMaxAmountQueryVariables> & ({ variables: MinMaxAmountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MinMaxAmountQuery, MinMaxAmountQueryVariables>(MinMaxAmountDocument, options);
      }
export function useMinMaxAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MinMaxAmountQuery, MinMaxAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MinMaxAmountQuery, MinMaxAmountQueryVariables>(MinMaxAmountDocument, options);
        }
export function useMinMaxAmountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MinMaxAmountQuery, MinMaxAmountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MinMaxAmountQuery, MinMaxAmountQueryVariables>(MinMaxAmountDocument, options);
        }
export type MinMaxAmountQueryHookResult = ReturnType<typeof useMinMaxAmountQuery>;
export type MinMaxAmountLazyQueryHookResult = ReturnType<typeof useMinMaxAmountLazyQuery>;
export type MinMaxAmountSuspenseQueryHookResult = ReturnType<typeof useMinMaxAmountSuspenseQuery>;
export type MinMaxAmountQueryResult = Apollo.QueryResult<MinMaxAmountQuery, MinMaxAmountQueryVariables>;
export const ListBulkCategorySuggestionsDocument = gql`
    query ListBulkCategorySuggestions($legalEntityId: String!, $transactionFacts: [TransactionFactEntryType!]!) {
  listBulkCategorySuggestions(
    legalEntityId: $legalEntityId
    transactionFacts: $transactionFacts
  ) {
    ...ListBulkCategorySuggestionsResponseFields
  }
}
    ${ListBulkCategorySuggestionsResponseFieldsFragmentDoc}`;

/**
 * __useListBulkCategorySuggestionsQuery__
 *
 * To run a query within a React component, call `useListBulkCategorySuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBulkCategorySuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBulkCategorySuggestionsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      transactionFacts: // value for 'transactionFacts'
 *   },
 * });
 */
export function useListBulkCategorySuggestionsQuery(baseOptions: Apollo.QueryHookOptions<ListBulkCategorySuggestionsQuery, ListBulkCategorySuggestionsQueryVariables> & ({ variables: ListBulkCategorySuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBulkCategorySuggestionsQuery, ListBulkCategorySuggestionsQueryVariables>(ListBulkCategorySuggestionsDocument, options);
      }
export function useListBulkCategorySuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBulkCategorySuggestionsQuery, ListBulkCategorySuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBulkCategorySuggestionsQuery, ListBulkCategorySuggestionsQueryVariables>(ListBulkCategorySuggestionsDocument, options);
        }
export function useListBulkCategorySuggestionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListBulkCategorySuggestionsQuery, ListBulkCategorySuggestionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListBulkCategorySuggestionsQuery, ListBulkCategorySuggestionsQueryVariables>(ListBulkCategorySuggestionsDocument, options);
        }
export type ListBulkCategorySuggestionsQueryHookResult = ReturnType<typeof useListBulkCategorySuggestionsQuery>;
export type ListBulkCategorySuggestionsLazyQueryHookResult = ReturnType<typeof useListBulkCategorySuggestionsLazyQuery>;
export type ListBulkCategorySuggestionsSuspenseQueryHookResult = ReturnType<typeof useListBulkCategorySuggestionsSuspenseQuery>;
export type ListBulkCategorySuggestionsQueryResult = Apollo.QueryResult<ListBulkCategorySuggestionsQuery, ListBulkCategorySuggestionsQueryVariables>;
export const ReadLedgerTransactionDocument = gql`
    query ReadLedgerTransaction($transactionFactId: String!, $filter: TransactionFilter!) {
  readLedgerTransaction(transactionFactId: $transactionFactId, filter: $filter) {
    transaction {
      ...LedgerTransactionFields
    }
  }
}
    ${LedgerTransactionFieldsFragmentDoc}`;

/**
 * __useReadLedgerTransactionQuery__
 *
 * To run a query within a React component, call `useReadLedgerTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLedgerTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLedgerTransactionQuery({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useReadLedgerTransactionQuery(baseOptions: Apollo.QueryHookOptions<ReadLedgerTransactionQuery, ReadLedgerTransactionQueryVariables> & ({ variables: ReadLedgerTransactionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLedgerTransactionQuery, ReadLedgerTransactionQueryVariables>(ReadLedgerTransactionDocument, options);
      }
export function useReadLedgerTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLedgerTransactionQuery, ReadLedgerTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLedgerTransactionQuery, ReadLedgerTransactionQueryVariables>(ReadLedgerTransactionDocument, options);
        }
export function useReadLedgerTransactionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLedgerTransactionQuery, ReadLedgerTransactionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLedgerTransactionQuery, ReadLedgerTransactionQueryVariables>(ReadLedgerTransactionDocument, options);
        }
export type ReadLedgerTransactionQueryHookResult = ReturnType<typeof useReadLedgerTransactionQuery>;
export type ReadLedgerTransactionLazyQueryHookResult = ReturnType<typeof useReadLedgerTransactionLazyQuery>;
export type ReadLedgerTransactionSuspenseQueryHookResult = ReturnType<typeof useReadLedgerTransactionSuspenseQuery>;
export type ReadLedgerTransactionQueryResult = Apollo.QueryResult<ReadLedgerTransactionQuery, ReadLedgerTransactionQueryVariables>;
export const SummarizeTransactionsByTimeByPartyByCategoryDocument = gql`
    query SummarizeTransactionsByTimeByPartyByCategory($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $sort: DimensionSortMode!, $limit: Int!, $asPermanent: Boolean, $masks: [[SummaryDimension!]!], $matchBy: Matcher, $directBookings: Boolean) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Time, Party, Category]
    masks: $masks
    asPermanent: $asPermanent
    matchBy: $matchBy
    directBookings: $directBookings
  ) {
    time {
      by {
        party(limit: $limit, sort: $sort) {
          partyObject {
            ...EntityPartyFields
          }
          summary {
            ...TransactionSummaryFields
          }
          by {
            category(sort: Active) {
              categoryObject {
                ...EntityCategoryFields
              }
            }
          }
        }
      }
    }
    partialData
  }
}
    ${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByTimeByPartyByCategoryQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByTimeByPartyByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByTimeByPartyByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByTimeByPartyByCategoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      asPermanent: // value for 'asPermanent'
 *      masks: // value for 'masks'
 *      matchBy: // value for 'matchBy'
 *      directBookings: // value for 'directBookings'
 *   },
 * });
 */
export function useSummarizeTransactionsByTimeByPartyByCategoryQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByTimeByPartyByCategoryQuery, SummarizeTransactionsByTimeByPartyByCategoryQueryVariables> & ({ variables: SummarizeTransactionsByTimeByPartyByCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByTimeByPartyByCategoryQuery, SummarizeTransactionsByTimeByPartyByCategoryQueryVariables>(SummarizeTransactionsByTimeByPartyByCategoryDocument, options);
      }
export function useSummarizeTransactionsByTimeByPartyByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByTimeByPartyByCategoryQuery, SummarizeTransactionsByTimeByPartyByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByTimeByPartyByCategoryQuery, SummarizeTransactionsByTimeByPartyByCategoryQueryVariables>(SummarizeTransactionsByTimeByPartyByCategoryDocument, options);
        }
export function useSummarizeTransactionsByTimeByPartyByCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByTimeByPartyByCategoryQuery, SummarizeTransactionsByTimeByPartyByCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByTimeByPartyByCategoryQuery, SummarizeTransactionsByTimeByPartyByCategoryQueryVariables>(SummarizeTransactionsByTimeByPartyByCategoryDocument, options);
        }
export type SummarizeTransactionsByTimeByPartyByCategoryQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByPartyByCategoryQuery>;
export type SummarizeTransactionsByTimeByPartyByCategoryLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByPartyByCategoryLazyQuery>;
export type SummarizeTransactionsByTimeByPartyByCategorySuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeByPartyByCategorySuspenseQuery>;
export type SummarizeTransactionsByTimeByPartyByCategoryQueryResult = Apollo.QueryResult<SummarizeTransactionsByTimeByPartyByCategoryQuery, SummarizeTransactionsByTimeByPartyByCategoryQueryVariables>;
export const SummarizeTransactionsByPartyByCategoryDocument = gql`
    query SummarizeTransactionsByPartyByCategory($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $asPermanent: Boolean, $matchBy: Matcher, $masks: [[SummaryDimension!]!], $directBookings: Boolean, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Party, Category]
    asPermanent: $asPermanent
    matchBy: $matchBy
    masks: $masks
    directBookings: $directBookings
    defaultCategoryType: $defaultCategoryType
  ) {
    party {
      partyObject {
        ...EntityPartyFields
      }
      summary {
        ...TransactionSummaryFields
      }
      by {
        category(sort: Active) {
          categoryObject {
            ...EntityCategoryFields
          }
        }
      }
    }
    partialData
  }
}
    ${EntityPartyFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByPartyByCategoryQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByPartyByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByPartyByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByPartyByCategoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      asPermanent: // value for 'asPermanent'
 *      matchBy: // value for 'matchBy'
 *      masks: // value for 'masks'
 *      directBookings: // value for 'directBookings'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeTransactionsByPartyByCategoryQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByPartyByCategoryQuery, SummarizeTransactionsByPartyByCategoryQueryVariables> & ({ variables: SummarizeTransactionsByPartyByCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByPartyByCategoryQuery, SummarizeTransactionsByPartyByCategoryQueryVariables>(SummarizeTransactionsByPartyByCategoryDocument, options);
      }
export function useSummarizeTransactionsByPartyByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByPartyByCategoryQuery, SummarizeTransactionsByPartyByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByPartyByCategoryQuery, SummarizeTransactionsByPartyByCategoryQueryVariables>(SummarizeTransactionsByPartyByCategoryDocument, options);
        }
export function useSummarizeTransactionsByPartyByCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByPartyByCategoryQuery, SummarizeTransactionsByPartyByCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByPartyByCategoryQuery, SummarizeTransactionsByPartyByCategoryQueryVariables>(SummarizeTransactionsByPartyByCategoryDocument, options);
        }
export type SummarizeTransactionsByPartyByCategoryQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartyByCategoryQuery>;
export type SummarizeTransactionsByPartyByCategoryLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartyByCategoryLazyQuery>;
export type SummarizeTransactionsByPartyByCategorySuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByPartyByCategorySuspenseQuery>;
export type SummarizeTransactionsByPartyByCategoryQueryResult = Apollo.QueryResult<SummarizeTransactionsByPartyByCategoryQuery, SummarizeTransactionsByPartyByCategoryQueryVariables>;
export const ListTransactionEditingHistoryDocument = gql`
    query ListTransactionEditingHistory($legalEntityId: String!, $factId: String!) {
  listTransactionEditingHistory(legalEntityId: $legalEntityId, factId: $factId) {
    ...TransactionSourceFields
  }
}
    ${TransactionSourceFieldsFragmentDoc}`;

/**
 * __useListTransactionEditingHistoryQuery__
 *
 * To run a query within a React component, call `useListTransactionEditingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionEditingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionEditingHistoryQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      factId: // value for 'factId'
 *   },
 * });
 */
export function useListTransactionEditingHistoryQuery(baseOptions: Apollo.QueryHookOptions<ListTransactionEditingHistoryQuery, ListTransactionEditingHistoryQueryVariables> & ({ variables: ListTransactionEditingHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTransactionEditingHistoryQuery, ListTransactionEditingHistoryQueryVariables>(ListTransactionEditingHistoryDocument, options);
      }
export function useListTransactionEditingHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionEditingHistoryQuery, ListTransactionEditingHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTransactionEditingHistoryQuery, ListTransactionEditingHistoryQueryVariables>(ListTransactionEditingHistoryDocument, options);
        }
export function useListTransactionEditingHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListTransactionEditingHistoryQuery, ListTransactionEditingHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListTransactionEditingHistoryQuery, ListTransactionEditingHistoryQueryVariables>(ListTransactionEditingHistoryDocument, options);
        }
export type ListTransactionEditingHistoryQueryHookResult = ReturnType<typeof useListTransactionEditingHistoryQuery>;
export type ListTransactionEditingHistoryLazyQueryHookResult = ReturnType<typeof useListTransactionEditingHistoryLazyQuery>;
export type ListTransactionEditingHistorySuspenseQueryHookResult = ReturnType<typeof useListTransactionEditingHistorySuspenseQuery>;
export type ListTransactionEditingHistoryQueryResult = Apollo.QueryResult<ListTransactionEditingHistoryQuery, ListTransactionEditingHistoryQueryVariables>;
export const CheckLedgerTransactionStaleDocument = gql`
    query CheckLedgerTransactionStale($transactionFactId: String!, $legalEntityId: String!, $viewKey: ViewKey!) {
  checkLedgerTransactionStale(
    factId: $transactionFactId
    legalEntityId: $legalEntityId
    viewKey: $viewKey
  ) {
    ...LedgerTransactionStaleFields
  }
}
    ${LedgerTransactionStaleFieldsFragmentDoc}`;

/**
 * __useCheckLedgerTransactionStaleQuery__
 *
 * To run a query within a React component, call `useCheckLedgerTransactionStaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckLedgerTransactionStaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckLedgerTransactionStaleQuery({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *      legalEntityId: // value for 'legalEntityId'
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useCheckLedgerTransactionStaleQuery(baseOptions: Apollo.QueryHookOptions<CheckLedgerTransactionStaleQuery, CheckLedgerTransactionStaleQueryVariables> & ({ variables: CheckLedgerTransactionStaleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckLedgerTransactionStaleQuery, CheckLedgerTransactionStaleQueryVariables>(CheckLedgerTransactionStaleDocument, options);
      }
export function useCheckLedgerTransactionStaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckLedgerTransactionStaleQuery, CheckLedgerTransactionStaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckLedgerTransactionStaleQuery, CheckLedgerTransactionStaleQueryVariables>(CheckLedgerTransactionStaleDocument, options);
        }
export function useCheckLedgerTransactionStaleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckLedgerTransactionStaleQuery, CheckLedgerTransactionStaleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckLedgerTransactionStaleQuery, CheckLedgerTransactionStaleQueryVariables>(CheckLedgerTransactionStaleDocument, options);
        }
export type CheckLedgerTransactionStaleQueryHookResult = ReturnType<typeof useCheckLedgerTransactionStaleQuery>;
export type CheckLedgerTransactionStaleLazyQueryHookResult = ReturnType<typeof useCheckLedgerTransactionStaleLazyQuery>;
export type CheckLedgerTransactionStaleSuspenseQueryHookResult = ReturnType<typeof useCheckLedgerTransactionStaleSuspenseQuery>;
export type CheckLedgerTransactionStaleQueryResult = Apollo.QueryResult<CheckLedgerTransactionStaleQuery, CheckLedgerTransactionStaleQueryVariables>;
export const TransactionDashboardDimensionalSummaryDocument = gql`
    query TransactionDashboardDimensionalSummary($viewKey: ViewKey!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!) {
  assetsSummary: dimensionalTransactionSummary(
    filter: {viewKey: $viewKey, productArea: None, categoryType: Assets}
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: true
    directBookings: true
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
  }
  liabilitiesSummary: dimensionalTransactionSummary(
    filter: {viewKey: $viewKey, productArea: None, categoryType: Liabilities}
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: true
    directBookings: true
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
  }
  equitySummary: dimensionalTransactionSummary(
    filter: {viewKey: $viewKey, productArea: None, categoryType: Equity}
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: true
    directBookings: true
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
  }
  incomeSummary: dimensionalTransactionSummary(
    filter: {viewKey: $viewKey, productArea: None, categoryType: Income}
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: false
    directBookings: true
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
  }
  costOfGoodsSoldSummary: dimensionalTransactionSummary(
    filter: {viewKey: $viewKey, productArea: None, categoryType: CostOfGoodsSold}
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: false
    directBookings: true
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
  }
  expensesSummary: dimensionalTransactionSummary(
    filter: {viewKey: $viewKey, productArea: None, categoryType: Expenses}
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: false
    directBookings: true
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
  }
  otherExpensesSummary: dimensionalTransactionSummary(
    filter: {viewKey: $viewKey, productArea: None, categoryType: OtherExpenses}
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: false
    directBookings: true
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
  }
  otherIncomeSummary: dimensionalTransactionSummary(
    filter: {viewKey: $viewKey, productArea: None, categoryType: OtherIncome}
    origin: $origin
    direction: $direction
    dimensions: [Category, Time]
    asPermanent: false
    directBookings: true
  ) {
    category {
      categoryObject {
        ...EntityCategoryFields
      }
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
  }
}
    ${EntityCategoryFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useTransactionDashboardDimensionalSummaryQuery__
 *
 * To run a query within a React component, call `useTransactionDashboardDimensionalSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionDashboardDimensionalSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionDashboardDimensionalSummaryQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useTransactionDashboardDimensionalSummaryQuery(baseOptions: Apollo.QueryHookOptions<TransactionDashboardDimensionalSummaryQuery, TransactionDashboardDimensionalSummaryQueryVariables> & ({ variables: TransactionDashboardDimensionalSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionDashboardDimensionalSummaryQuery, TransactionDashboardDimensionalSummaryQueryVariables>(TransactionDashboardDimensionalSummaryDocument, options);
      }
export function useTransactionDashboardDimensionalSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionDashboardDimensionalSummaryQuery, TransactionDashboardDimensionalSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionDashboardDimensionalSummaryQuery, TransactionDashboardDimensionalSummaryQueryVariables>(TransactionDashboardDimensionalSummaryDocument, options);
        }
export function useTransactionDashboardDimensionalSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TransactionDashboardDimensionalSummaryQuery, TransactionDashboardDimensionalSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TransactionDashboardDimensionalSummaryQuery, TransactionDashboardDimensionalSummaryQueryVariables>(TransactionDashboardDimensionalSummaryDocument, options);
        }
export type TransactionDashboardDimensionalSummaryQueryHookResult = ReturnType<typeof useTransactionDashboardDimensionalSummaryQuery>;
export type TransactionDashboardDimensionalSummaryLazyQueryHookResult = ReturnType<typeof useTransactionDashboardDimensionalSummaryLazyQuery>;
export type TransactionDashboardDimensionalSummarySuspenseQueryHookResult = ReturnType<typeof useTransactionDashboardDimensionalSummarySuspenseQuery>;
export type TransactionDashboardDimensionalSummaryQueryResult = Apollo.QueryResult<TransactionDashboardDimensionalSummaryQuery, TransactionDashboardDimensionalSummaryQueryVariables>;
export const ReadTransactionWorkItemIdDocument = gql`
    query ReadTransactionWorkItemID($transactionFactId: String!, $legalEntityId: String!) {
  readTransactionWorkItemId(
    factId: $transactionFactId
    legalEntityId: $legalEntityId
  )
}
    `;

/**
 * __useReadTransactionWorkItemIdQuery__
 *
 * To run a query within a React component, call `useReadTransactionWorkItemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTransactionWorkItemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTransactionWorkItemIdQuery({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadTransactionWorkItemIdQuery(baseOptions: Apollo.QueryHookOptions<ReadTransactionWorkItemIdQuery, ReadTransactionWorkItemIdQueryVariables> & ({ variables: ReadTransactionWorkItemIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTransactionWorkItemIdQuery, ReadTransactionWorkItemIdQueryVariables>(ReadTransactionWorkItemIdDocument, options);
      }
export function useReadTransactionWorkItemIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTransactionWorkItemIdQuery, ReadTransactionWorkItemIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTransactionWorkItemIdQuery, ReadTransactionWorkItemIdQueryVariables>(ReadTransactionWorkItemIdDocument, options);
        }
export function useReadTransactionWorkItemIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTransactionWorkItemIdQuery, ReadTransactionWorkItemIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTransactionWorkItemIdQuery, ReadTransactionWorkItemIdQueryVariables>(ReadTransactionWorkItemIdDocument, options);
        }
export type ReadTransactionWorkItemIdQueryHookResult = ReturnType<typeof useReadTransactionWorkItemIdQuery>;
export type ReadTransactionWorkItemIdLazyQueryHookResult = ReturnType<typeof useReadTransactionWorkItemIdLazyQuery>;
export type ReadTransactionWorkItemIdSuspenseQueryHookResult = ReturnType<typeof useReadTransactionWorkItemIdSuspenseQuery>;
export type ReadTransactionWorkItemIdQueryResult = Apollo.QueryResult<ReadTransactionWorkItemIdQuery, ReadTransactionWorkItemIdQueryVariables>;
export const SummarizeTransactionsByTimeDocument = gql`
    query SummarizeTransactionsByTime($filter: SummaryFilterInput!, $origin: IntervalOrigin!, $direction: DirectionFromOrigin!, $asPermanent: Boolean, $directBookings: Boolean, $naturalFlow: Flow, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Time]
    asPermanent: $asPermanent
    directBookings: $directBookings
    naturalFlow: $naturalFlow
    defaultCategoryType: $defaultCategoryType
  ) {
    time {
      summary {
        ...TransactionSummaryFields
      }
    }
    partialData
  }
}
    ${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByTimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      origin: // value for 'origin'
 *      direction: // value for 'direction'
 *      asPermanent: // value for 'asPermanent'
 *      directBookings: // value for 'directBookings'
 *      naturalFlow: // value for 'naturalFlow'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeTransactionsByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByTimeQuery, SummarizeTransactionsByTimeQueryVariables> & ({ variables: SummarizeTransactionsByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByTimeQuery, SummarizeTransactionsByTimeQueryVariables>(SummarizeTransactionsByTimeDocument, options);
      }
export function useSummarizeTransactionsByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByTimeQuery, SummarizeTransactionsByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByTimeQuery, SummarizeTransactionsByTimeQueryVariables>(SummarizeTransactionsByTimeDocument, options);
        }
export function useSummarizeTransactionsByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByTimeQuery, SummarizeTransactionsByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByTimeQuery, SummarizeTransactionsByTimeQueryVariables>(SummarizeTransactionsByTimeDocument, options);
        }
export type SummarizeTransactionsByTimeQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeQuery>;
export type SummarizeTransactionsByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeLazyQuery>;
export type SummarizeTransactionsByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByTimeSuspenseQuery>;
export type SummarizeTransactionsByTimeQueryResult = Apollo.QueryResult<SummarizeTransactionsByTimeQuery, SummarizeTransactionsByTimeQueryVariables>;
export const ReadMetricEquationDocument = gql`
    query ReadMetricEquation($viewKey: ViewKey!, $summaryLineType: SummaryLineItem, $metricSummaryType: MetricSummaryItem, $layoutComponentConfig: LayoutComponentConfigInput, $origin: IntervalOrigin) {
  readMetricEquation(
    viewKey: $viewKey
    summaryLineType: $summaryLineType
    metricSummaryType: $metricSummaryType
    layoutComponentConfig: $layoutComponentConfig
    origin: $origin
  ) {
    ...MetricEquationFields
  }
}
    ${MetricEquationFieldsFragmentDoc}`;

/**
 * __useReadMetricEquationQuery__
 *
 * To run a query within a React component, call `useReadMetricEquationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMetricEquationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMetricEquationQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      summaryLineType: // value for 'summaryLineType'
 *      metricSummaryType: // value for 'metricSummaryType'
 *      layoutComponentConfig: // value for 'layoutComponentConfig'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useReadMetricEquationQuery(baseOptions: Apollo.QueryHookOptions<ReadMetricEquationQuery, ReadMetricEquationQueryVariables> & ({ variables: ReadMetricEquationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMetricEquationQuery, ReadMetricEquationQueryVariables>(ReadMetricEquationDocument, options);
      }
export function useReadMetricEquationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMetricEquationQuery, ReadMetricEquationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMetricEquationQuery, ReadMetricEquationQueryVariables>(ReadMetricEquationDocument, options);
        }
export function useReadMetricEquationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadMetricEquationQuery, ReadMetricEquationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadMetricEquationQuery, ReadMetricEquationQueryVariables>(ReadMetricEquationDocument, options);
        }
export type ReadMetricEquationQueryHookResult = ReturnType<typeof useReadMetricEquationQuery>;
export type ReadMetricEquationLazyQueryHookResult = ReturnType<typeof useReadMetricEquationLazyQuery>;
export type ReadMetricEquationSuspenseQueryHookResult = ReturnType<typeof useReadMetricEquationSuspenseQuery>;
export type ReadMetricEquationQueryResult = Apollo.QueryResult<ReadMetricEquationQuery, ReadMetricEquationQueryVariables>;
export const SummarizeTransactionsWithoutDimensionDocument = gql`
    query SummarizeTransactionsWithoutDimension($filter: SummaryFilterInput!, $origin: IntervalOrigin!, $direction: DirectionFromOrigin!, $asPermanent: Boolean, $directBookings: Boolean, $naturalFlow: Flow, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: []
    asPermanent: $asPermanent
    directBookings: $directBookings
    naturalFlow: $naturalFlow
    defaultCategoryType: $defaultCategoryType
  ) {
    summary {
      ...TransactionSummaryFields
    }
    partialData
  }
}
    ${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsWithoutDimensionQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsWithoutDimensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsWithoutDimensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsWithoutDimensionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      origin: // value for 'origin'
 *      direction: // value for 'direction'
 *      asPermanent: // value for 'asPermanent'
 *      directBookings: // value for 'directBookings'
 *      naturalFlow: // value for 'naturalFlow'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeTransactionsWithoutDimensionQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsWithoutDimensionQuery, SummarizeTransactionsWithoutDimensionQueryVariables> & ({ variables: SummarizeTransactionsWithoutDimensionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsWithoutDimensionQuery, SummarizeTransactionsWithoutDimensionQueryVariables>(SummarizeTransactionsWithoutDimensionDocument, options);
      }
export function useSummarizeTransactionsWithoutDimensionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsWithoutDimensionQuery, SummarizeTransactionsWithoutDimensionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsWithoutDimensionQuery, SummarizeTransactionsWithoutDimensionQueryVariables>(SummarizeTransactionsWithoutDimensionDocument, options);
        }
export function useSummarizeTransactionsWithoutDimensionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsWithoutDimensionQuery, SummarizeTransactionsWithoutDimensionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsWithoutDimensionQuery, SummarizeTransactionsWithoutDimensionQueryVariables>(SummarizeTransactionsWithoutDimensionDocument, options);
        }
export type SummarizeTransactionsWithoutDimensionQueryHookResult = ReturnType<typeof useSummarizeTransactionsWithoutDimensionQuery>;
export type SummarizeTransactionsWithoutDimensionLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsWithoutDimensionLazyQuery>;
export type SummarizeTransactionsWithoutDimensionSuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsWithoutDimensionSuspenseQuery>;
export type SummarizeTransactionsWithoutDimensionQueryResult = Apollo.QueryResult<SummarizeTransactionsWithoutDimensionQuery, SummarizeTransactionsWithoutDimensionQueryVariables>;
export const SummarizeTransactionsByLocationByTimeDocument = gql`
    query SummarizeTransactionsByLocationByTime($filter: SummaryFilterInput!, $direction: DirectionFromOrigin!, $origin: IntervalOrigin!, $directBookings: Boolean, $naturalFlow: Flow!, $defaultCategoryType: CategoryType) {
  dimensionalTransactionSummary(
    filter: $filter
    origin: $origin
    direction: $direction
    dimensions: [Location, Time]
    directBookings: $directBookings
    naturalFlow: $naturalFlow
    defaultCategoryType: $defaultCategoryType
  ) {
    location {
      locationObject {
        ...EntityLocationFields
      }
      locationId
      by {
        time {
          summary {
            ...TransactionSummaryFields
          }
        }
      }
    }
    partialData
  }
}
    ${EntityLocationFieldsFragmentDoc}
${TransactionSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeTransactionsByLocationByTimeQuery__
 *
 * To run a query within a React component, call `useSummarizeTransactionsByLocationByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTransactionsByLocationByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTransactionsByLocationByTimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      origin: // value for 'origin'
 *      directBookings: // value for 'directBookings'
 *      naturalFlow: // value for 'naturalFlow'
 *      defaultCategoryType: // value for 'defaultCategoryType'
 *   },
 * });
 */
export function useSummarizeTransactionsByLocationByTimeQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTransactionsByLocationByTimeQuery, SummarizeTransactionsByLocationByTimeQueryVariables> & ({ variables: SummarizeTransactionsByLocationByTimeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTransactionsByLocationByTimeQuery, SummarizeTransactionsByLocationByTimeQueryVariables>(SummarizeTransactionsByLocationByTimeDocument, options);
      }
export function useSummarizeTransactionsByLocationByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTransactionsByLocationByTimeQuery, SummarizeTransactionsByLocationByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTransactionsByLocationByTimeQuery, SummarizeTransactionsByLocationByTimeQueryVariables>(SummarizeTransactionsByLocationByTimeDocument, options);
        }
export function useSummarizeTransactionsByLocationByTimeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTransactionsByLocationByTimeQuery, SummarizeTransactionsByLocationByTimeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTransactionsByLocationByTimeQuery, SummarizeTransactionsByLocationByTimeQueryVariables>(SummarizeTransactionsByLocationByTimeDocument, options);
        }
export type SummarizeTransactionsByLocationByTimeQueryHookResult = ReturnType<typeof useSummarizeTransactionsByLocationByTimeQuery>;
export type SummarizeTransactionsByLocationByTimeLazyQueryHookResult = ReturnType<typeof useSummarizeTransactionsByLocationByTimeLazyQuery>;
export type SummarizeTransactionsByLocationByTimeSuspenseQueryHookResult = ReturnType<typeof useSummarizeTransactionsByLocationByTimeSuspenseQuery>;
export type SummarizeTransactionsByLocationByTimeQueryResult = Apollo.QueryResult<SummarizeTransactionsByLocationByTimeQuery, SummarizeTransactionsByLocationByTimeQueryVariables>;
export const ListActionItemsBadgeCountsDocument = gql`
    query ListActionItemsBadgeCounts($direction: DirectionFromOrigin, $filter: ActionItemFilter!, $legalEntityIds: [String!], $legalEntities: [LegalEntityPermission], $origin: IntervalOrigin!) {
  listActionItemsBadgeCounts(
    direction: $direction
    origin: $origin
    legalEntityIds: $legalEntityIds
    legalEntities: $legalEntities
    filter: $filter
  ) {
    ...LegalEntityBadgeCountFields
  }
}
    ${LegalEntityBadgeCountFieldsFragmentDoc}`;

/**
 * __useListActionItemsBadgeCountsQuery__
 *
 * To run a query within a React component, call `useListActionItemsBadgeCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActionItemsBadgeCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActionItemsBadgeCountsQuery({
 *   variables: {
 *      direction: // value for 'direction'
 *      filter: // value for 'filter'
 *      legalEntityIds: // value for 'legalEntityIds'
 *      legalEntities: // value for 'legalEntities'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useListActionItemsBadgeCountsQuery(baseOptions: Apollo.QueryHookOptions<ListActionItemsBadgeCountsQuery, ListActionItemsBadgeCountsQueryVariables> & ({ variables: ListActionItemsBadgeCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListActionItemsBadgeCountsQuery, ListActionItemsBadgeCountsQueryVariables>(ListActionItemsBadgeCountsDocument, options);
      }
export function useListActionItemsBadgeCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActionItemsBadgeCountsQuery, ListActionItemsBadgeCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListActionItemsBadgeCountsQuery, ListActionItemsBadgeCountsQueryVariables>(ListActionItemsBadgeCountsDocument, options);
        }
export function useListActionItemsBadgeCountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListActionItemsBadgeCountsQuery, ListActionItemsBadgeCountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListActionItemsBadgeCountsQuery, ListActionItemsBadgeCountsQueryVariables>(ListActionItemsBadgeCountsDocument, options);
        }
export type ListActionItemsBadgeCountsQueryHookResult = ReturnType<typeof useListActionItemsBadgeCountsQuery>;
export type ListActionItemsBadgeCountsLazyQueryHookResult = ReturnType<typeof useListActionItemsBadgeCountsLazyQuery>;
export type ListActionItemsBadgeCountsSuspenseQueryHookResult = ReturnType<typeof useListActionItemsBadgeCountsSuspenseQuery>;
export type ListActionItemsBadgeCountsQueryResult = Apollo.QueryResult<ListActionItemsBadgeCountsQuery, ListActionItemsBadgeCountsQueryVariables>;
export const ListReportsBadgeCountsDocument = gql`
    query ListReportsBadgeCounts($direction: DirectionFromOrigin!, $legalEntityIds: [String!]!, $origin: IntervalOrigin!) {
  listReportsBadgeCounts(
    direction: $direction
    legalEntityIds: $legalEntityIds
    origin: $origin
  ) {
    ...LegalEntityBadgeCountFields
  }
}
    ${LegalEntityBadgeCountFieldsFragmentDoc}`;

/**
 * __useListReportsBadgeCountsQuery__
 *
 * To run a query within a React component, call `useListReportsBadgeCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportsBadgeCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportsBadgeCountsQuery({
 *   variables: {
 *      direction: // value for 'direction'
 *      legalEntityIds: // value for 'legalEntityIds'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useListReportsBadgeCountsQuery(baseOptions: Apollo.QueryHookOptions<ListReportsBadgeCountsQuery, ListReportsBadgeCountsQueryVariables> & ({ variables: ListReportsBadgeCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReportsBadgeCountsQuery, ListReportsBadgeCountsQueryVariables>(ListReportsBadgeCountsDocument, options);
      }
export function useListReportsBadgeCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportsBadgeCountsQuery, ListReportsBadgeCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReportsBadgeCountsQuery, ListReportsBadgeCountsQueryVariables>(ListReportsBadgeCountsDocument, options);
        }
export function useListReportsBadgeCountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListReportsBadgeCountsQuery, ListReportsBadgeCountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListReportsBadgeCountsQuery, ListReportsBadgeCountsQueryVariables>(ListReportsBadgeCountsDocument, options);
        }
export type ListReportsBadgeCountsQueryHookResult = ReturnType<typeof useListReportsBadgeCountsQuery>;
export type ListReportsBadgeCountsLazyQueryHookResult = ReturnType<typeof useListReportsBadgeCountsLazyQuery>;
export type ListReportsBadgeCountsSuspenseQueryHookResult = ReturnType<typeof useListReportsBadgeCountsSuspenseQuery>;
export type ListReportsBadgeCountsQueryResult = Apollo.QueryResult<ListReportsBadgeCountsQuery, ListReportsBadgeCountsQueryVariables>;
export const ListDataSourcesBadgeCountsDocument = gql`
    query ListDataSourcesBadgeCounts($legalEntityIds: [String!]!) {
  listDataSourcesBadgeCounts(legalEntityIds: $legalEntityIds) {
    ...LegalEntityBadgeCountFields
  }
}
    ${LegalEntityBadgeCountFieldsFragmentDoc}`;

/**
 * __useListDataSourcesBadgeCountsQuery__
 *
 * To run a query within a React component, call `useListDataSourcesBadgeCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDataSourcesBadgeCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDataSourcesBadgeCountsQuery({
 *   variables: {
 *      legalEntityIds: // value for 'legalEntityIds'
 *   },
 * });
 */
export function useListDataSourcesBadgeCountsQuery(baseOptions: Apollo.QueryHookOptions<ListDataSourcesBadgeCountsQuery, ListDataSourcesBadgeCountsQueryVariables> & ({ variables: ListDataSourcesBadgeCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDataSourcesBadgeCountsQuery, ListDataSourcesBadgeCountsQueryVariables>(ListDataSourcesBadgeCountsDocument, options);
      }
export function useListDataSourcesBadgeCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDataSourcesBadgeCountsQuery, ListDataSourcesBadgeCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDataSourcesBadgeCountsQuery, ListDataSourcesBadgeCountsQueryVariables>(ListDataSourcesBadgeCountsDocument, options);
        }
export function useListDataSourcesBadgeCountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDataSourcesBadgeCountsQuery, ListDataSourcesBadgeCountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDataSourcesBadgeCountsQuery, ListDataSourcesBadgeCountsQueryVariables>(ListDataSourcesBadgeCountsDocument, options);
        }
export type ListDataSourcesBadgeCountsQueryHookResult = ReturnType<typeof useListDataSourcesBadgeCountsQuery>;
export type ListDataSourcesBadgeCountsLazyQueryHookResult = ReturnType<typeof useListDataSourcesBadgeCountsLazyQuery>;
export type ListDataSourcesBadgeCountsSuspenseQueryHookResult = ReturnType<typeof useListDataSourcesBadgeCountsSuspenseQuery>;
export type ListDataSourcesBadgeCountsQueryResult = Apollo.QueryResult<ListDataSourcesBadgeCountsQuery, ListDataSourcesBadgeCountsQueryVariables>;
export const ListConnectionBadgeCountsDocument = gql`
    query ListConnectionBadgeCounts($filter: ConnectionBadgeCountFilter!) {
  listConnectionBadgeCounts(filter: $filter) {
    ...LegalEntityBadgeCountFields
  }
}
    ${LegalEntityBadgeCountFieldsFragmentDoc}`;

/**
 * __useListConnectionBadgeCountsQuery__
 *
 * To run a query within a React component, call `useListConnectionBadgeCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConnectionBadgeCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConnectionBadgeCountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListConnectionBadgeCountsQuery(baseOptions: Apollo.QueryHookOptions<ListConnectionBadgeCountsQuery, ListConnectionBadgeCountsQueryVariables> & ({ variables: ListConnectionBadgeCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListConnectionBadgeCountsQuery, ListConnectionBadgeCountsQueryVariables>(ListConnectionBadgeCountsDocument, options);
      }
export function useListConnectionBadgeCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListConnectionBadgeCountsQuery, ListConnectionBadgeCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListConnectionBadgeCountsQuery, ListConnectionBadgeCountsQueryVariables>(ListConnectionBadgeCountsDocument, options);
        }
export function useListConnectionBadgeCountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListConnectionBadgeCountsQuery, ListConnectionBadgeCountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListConnectionBadgeCountsQuery, ListConnectionBadgeCountsQueryVariables>(ListConnectionBadgeCountsDocument, options);
        }
export type ListConnectionBadgeCountsQueryHookResult = ReturnType<typeof useListConnectionBadgeCountsQuery>;
export type ListConnectionBadgeCountsLazyQueryHookResult = ReturnType<typeof useListConnectionBadgeCountsLazyQuery>;
export type ListConnectionBadgeCountsSuspenseQueryHookResult = ReturnType<typeof useListConnectionBadgeCountsSuspenseQuery>;
export type ListConnectionBadgeCountsQueryResult = Apollo.QueryResult<ListConnectionBadgeCountsQuery, ListConnectionBadgeCountsQueryVariables>;
export const ListBoostBadgeCountsDocument = gql`
    query ListBoostBadgeCounts($direction: DirectionFromOrigin!, $filter: WorkItemBadgeCountFilter!, $origin: IntervalOrigin!) {
  listBoostBadgeCounts(direction: $direction, origin: $origin, filter: $filter) {
    ...LegalEntityBadgeCountFields
  }
}
    ${LegalEntityBadgeCountFieldsFragmentDoc}`;

/**
 * __useListBoostBadgeCountsQuery__
 *
 * To run a query within a React component, call `useListBoostBadgeCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBoostBadgeCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBoostBadgeCountsQuery({
 *   variables: {
 *      direction: // value for 'direction'
 *      filter: // value for 'filter'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useListBoostBadgeCountsQuery(baseOptions: Apollo.QueryHookOptions<ListBoostBadgeCountsQuery, ListBoostBadgeCountsQueryVariables> & ({ variables: ListBoostBadgeCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBoostBadgeCountsQuery, ListBoostBadgeCountsQueryVariables>(ListBoostBadgeCountsDocument, options);
      }
export function useListBoostBadgeCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBoostBadgeCountsQuery, ListBoostBadgeCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBoostBadgeCountsQuery, ListBoostBadgeCountsQueryVariables>(ListBoostBadgeCountsDocument, options);
        }
export function useListBoostBadgeCountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListBoostBadgeCountsQuery, ListBoostBadgeCountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListBoostBadgeCountsQuery, ListBoostBadgeCountsQueryVariables>(ListBoostBadgeCountsDocument, options);
        }
export type ListBoostBadgeCountsQueryHookResult = ReturnType<typeof useListBoostBadgeCountsQuery>;
export type ListBoostBadgeCountsLazyQueryHookResult = ReturnType<typeof useListBoostBadgeCountsLazyQuery>;
export type ListBoostBadgeCountsSuspenseQueryHookResult = ReturnType<typeof useListBoostBadgeCountsSuspenseQuery>;
export type ListBoostBadgeCountsQueryResult = Apollo.QueryResult<ListBoostBadgeCountsQuery, ListBoostBadgeCountsQueryVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($legalEntityId: String!, $viewKey: ViewKey!, $category: UpdateCategoryInput!) {
  updateCategory(
    legalEntityId: $legalEntityId
    viewKey: $viewKey
    category: $category
  )
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      viewKey: // value for 'viewKey'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const UpdateCategoryStatusDocument = gql`
    mutation UpdateCategoryStatus($legalEntityId: String!, $viewKey: ViewKey!, $categoryId: String!, $enabled: Boolean!) {
  updateCategoryStatus(
    legalEntityId: $legalEntityId
    viewKey: $viewKey
    categoryId: $categoryId
    enabled: $enabled
  )
}
    `;
export type UpdateCategoryStatusMutationFn = Apollo.MutationFunction<UpdateCategoryStatusMutation, UpdateCategoryStatusMutationVariables>;

/**
 * __useUpdateCategoryStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryStatusMutation, { data, loading, error }] = useUpdateCategoryStatusMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      viewKey: // value for 'viewKey'
 *      categoryId: // value for 'categoryId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateCategoryStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryStatusMutation, UpdateCategoryStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryStatusMutation, UpdateCategoryStatusMutationVariables>(UpdateCategoryStatusDocument, options);
      }
export type UpdateCategoryStatusMutationHookResult = ReturnType<typeof useUpdateCategoryStatusMutation>;
export type UpdateCategoryStatusMutationResult = Apollo.MutationResult<UpdateCategoryStatusMutation>;
export type UpdateCategoryStatusMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryStatusMutation, UpdateCategoryStatusMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($legalEntityId: String!, $category: CreateCategoryInput!) {
  createCategory(category: $category, legalEntityId: $legalEntityId) {
    categoryId
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const ListCategoriesDocument = gql`
    query ListCategories($viewKey: ViewKey!, $categoryType: CategoryType) {
  listCategories(viewKey: $viewKey, categoryType: $categoryType) {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;

/**
 * __useListCategoriesQuery__
 *
 * To run a query within a React component, call `useListCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCategoriesQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      categoryType: // value for 'categoryType'
 *   },
 * });
 */
export function useListCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ListCategoriesQuery, ListCategoriesQueryVariables> & ({ variables: ListCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(ListCategoriesDocument, options);
      }
export function useListCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCategoriesQuery, ListCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(ListCategoriesDocument, options);
        }
export function useListCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListCategoriesQuery, ListCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(ListCategoriesDocument, options);
        }
export type ListCategoriesQueryHookResult = ReturnType<typeof useListCategoriesQuery>;
export type ListCategoriesLazyQueryHookResult = ReturnType<typeof useListCategoriesLazyQuery>;
export type ListCategoriesSuspenseQueryHookResult = ReturnType<typeof useListCategoriesSuspenseQuery>;
export type ListCategoriesQueryResult = Apollo.QueryResult<ListCategoriesQuery, ListCategoriesQueryVariables>;
export const ListCategoryIntegrationsDocument = gql`
    query ListCategoryIntegrations($legalEntityId: String!) {
  listCategoryIntegrations(legalEntityId: $legalEntityId) {
    ...CategoryIntegrationsFields
  }
}
    ${CategoryIntegrationsFieldsFragmentDoc}`;

/**
 * __useListCategoryIntegrationsQuery__
 *
 * To run a query within a React component, call `useListCategoryIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCategoryIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCategoryIntegrationsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListCategoryIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<ListCategoryIntegrationsQuery, ListCategoryIntegrationsQueryVariables> & ({ variables: ListCategoryIntegrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCategoryIntegrationsQuery, ListCategoryIntegrationsQueryVariables>(ListCategoryIntegrationsDocument, options);
      }
export function useListCategoryIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCategoryIntegrationsQuery, ListCategoryIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCategoryIntegrationsQuery, ListCategoryIntegrationsQueryVariables>(ListCategoryIntegrationsDocument, options);
        }
export function useListCategoryIntegrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListCategoryIntegrationsQuery, ListCategoryIntegrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCategoryIntegrationsQuery, ListCategoryIntegrationsQueryVariables>(ListCategoryIntegrationsDocument, options);
        }
export type ListCategoryIntegrationsQueryHookResult = ReturnType<typeof useListCategoryIntegrationsQuery>;
export type ListCategoryIntegrationsLazyQueryHookResult = ReturnType<typeof useListCategoryIntegrationsLazyQuery>;
export type ListCategoryIntegrationsSuspenseQueryHookResult = ReturnType<typeof useListCategoryIntegrationsSuspenseQuery>;
export type ListCategoryIntegrationsQueryResult = Apollo.QueryResult<ListCategoryIntegrationsQuery, ListCategoryIntegrationsQueryVariables>;
export const GrantTeamDocument = gql`
    mutation GrantTeam($grants: [NewObjectGrant!]!, $teamId: String!) {
  grantTeam(grants: $grants, teamId: $teamId)
}
    `;
export type GrantTeamMutationFn = Apollo.MutationFunction<GrantTeamMutation, GrantTeamMutationVariables>;

/**
 * __useGrantTeamMutation__
 *
 * To run a mutation, you first call `useGrantTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantTeamMutation, { data, loading, error }] = useGrantTeamMutation({
 *   variables: {
 *      grants: // value for 'grants'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGrantTeamMutation(baseOptions?: Apollo.MutationHookOptions<GrantTeamMutation, GrantTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GrantTeamMutation, GrantTeamMutationVariables>(GrantTeamDocument, options);
      }
export type GrantTeamMutationHookResult = ReturnType<typeof useGrantTeamMutation>;
export type GrantTeamMutationResult = Apollo.MutationResult<GrantTeamMutation>;
export type GrantTeamMutationOptions = Apollo.BaseMutationOptions<GrantTeamMutation, GrantTeamMutationVariables>;
export const InviteTeamMembersDocument = gql`
    mutation InviteTeamMembers($legalEntityId: String!, $teamId: String!, $emailAddresses: [String!]!, $role: TeamRole!) {
  inviteTeamMembers(
    legalEntityId: $legalEntityId
    teamId: $teamId
    emailAddresses: $emailAddresses
    role: $role
  )
}
    `;
export type InviteTeamMembersMutationFn = Apollo.MutationFunction<InviteTeamMembersMutation, InviteTeamMembersMutationVariables>;

/**
 * __useInviteTeamMembersMutation__
 *
 * To run a mutation, you first call `useInviteTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteTeamMembersMutation, { data, loading, error }] = useInviteTeamMembersMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      teamId: // value for 'teamId'
 *      emailAddresses: // value for 'emailAddresses'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useInviteTeamMembersMutation(baseOptions?: Apollo.MutationHookOptions<InviteTeamMembersMutation, InviteTeamMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteTeamMembersMutation, InviteTeamMembersMutationVariables>(InviteTeamMembersDocument, options);
      }
export type InviteTeamMembersMutationHookResult = ReturnType<typeof useInviteTeamMembersMutation>;
export type InviteTeamMembersMutationResult = Apollo.MutationResult<InviteTeamMembersMutation>;
export type InviteTeamMembersMutationOptions = Apollo.BaseMutationOptions<InviteTeamMembersMutation, InviteTeamMembersMutationVariables>;
export const AddTeamMembersDocument = gql`
    mutation AddTeamMembers($legalEntityId: String!, $teamId: String!, $userIds: [String!]!, $role: TeamRole!) {
  addTeamMembers(
    legalEntityId: $legalEntityId
    teamId: $teamId
    userIds: $userIds
    role: $role
  )
}
    `;
export type AddTeamMembersMutationFn = Apollo.MutationFunction<AddTeamMembersMutation, AddTeamMembersMutationVariables>;

/**
 * __useAddTeamMembersMutation__
 *
 * To run a mutation, you first call `useAddTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMembersMutation, { data, loading, error }] = useAddTeamMembersMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      teamId: // value for 'teamId'
 *      userIds: // value for 'userIds'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddTeamMembersMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamMembersMutation, AddTeamMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeamMembersMutation, AddTeamMembersMutationVariables>(AddTeamMembersDocument, options);
      }
export type AddTeamMembersMutationHookResult = ReturnType<typeof useAddTeamMembersMutation>;
export type AddTeamMembersMutationResult = Apollo.MutationResult<AddTeamMembersMutation>;
export type AddTeamMembersMutationOptions = Apollo.BaseMutationOptions<AddTeamMembersMutation, AddTeamMembersMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($legalEntityId: String!, $teamId: String!) {
  deleteTeam(legalEntityId: $legalEntityId, teamId: $teamId)
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, options);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($legalEntityId: String!, $name: String!, $description: String, $iconBase64: String) {
  createTeam(
    legalEntityId: $legalEntityId
    name: $name
    description: $description
    iconBase64: $iconBase64
  ) {
    ...TeamFields
  }
}
    ${TeamFieldsFragmentDoc}`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      iconBase64: // value for 'iconBase64'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const RevokeTeamGrantDocument = gql`
    mutation RevokeTeamGrant($teamGrantId: String!) {
  revokeTeamGrant(teamGrantId: $teamGrantId)
}
    `;
export type RevokeTeamGrantMutationFn = Apollo.MutationFunction<RevokeTeamGrantMutation, RevokeTeamGrantMutationVariables>;

/**
 * __useRevokeTeamGrantMutation__
 *
 * To run a mutation, you first call `useRevokeTeamGrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTeamGrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeTeamGrantMutation, { data, loading, error }] = useRevokeTeamGrantMutation({
 *   variables: {
 *      teamGrantId: // value for 'teamGrantId'
 *   },
 * });
 */
export function useRevokeTeamGrantMutation(baseOptions?: Apollo.MutationHookOptions<RevokeTeamGrantMutation, RevokeTeamGrantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeTeamGrantMutation, RevokeTeamGrantMutationVariables>(RevokeTeamGrantDocument, options);
      }
export type RevokeTeamGrantMutationHookResult = ReturnType<typeof useRevokeTeamGrantMutation>;
export type RevokeTeamGrantMutationResult = Apollo.MutationResult<RevokeTeamGrantMutation>;
export type RevokeTeamGrantMutationOptions = Apollo.BaseMutationOptions<RevokeTeamGrantMutation, RevokeTeamGrantMutationVariables>;
export const RemoveTeamMemberDocument = gql`
    mutation RemoveTeamMember($legalEntityId: String!, $teamId: String!, $userId: String!) {
  removeTeamMember(
    legalEntityId: $legalEntityId
    teamId: $teamId
    teamMemberId: $userId
  )
}
    `;
export type RemoveTeamMemberMutationFn = Apollo.MutationFunction<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;

/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberMutation, { data, loading, error }] = useRemoveTeamMemberMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, options);
      }
export type RemoveTeamMemberMutationHookResult = ReturnType<typeof useRemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationResult = Apollo.MutationResult<RemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationOptions = Apollo.BaseMutationOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($legalEntityId: String!, $teamId: String!, $name: String!, $description: String, $iconBase64: String) {
  updateTeam(
    legalEntityId: $legalEntityId
    teamId: $teamId
    name: $name
    description: $description
    iconBase64: $iconBase64
  )
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      teamId: // value for 'teamId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      iconBase64: // value for 'iconBase64'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const ListTeamsDocument = gql`
    query ListTeams($legalEntityId: String!, $memberOnly: Boolean) {
  listTeams(legalEntityId: $legalEntityId, memberOnly: $memberOnly) {
    ...TeamFields
  }
}
    ${TeamFieldsFragmentDoc}`;

/**
 * __useListTeamsQuery__
 *
 * To run a query within a React component, call `useListTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTeamsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      memberOnly: // value for 'memberOnly'
 *   },
 * });
 */
export function useListTeamsQuery(baseOptions: Apollo.QueryHookOptions<ListTeamsQuery, ListTeamsQueryVariables> & ({ variables: ListTeamsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTeamsQuery, ListTeamsQueryVariables>(ListTeamsDocument, options);
      }
export function useListTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTeamsQuery, ListTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTeamsQuery, ListTeamsQueryVariables>(ListTeamsDocument, options);
        }
export function useListTeamsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListTeamsQuery, ListTeamsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListTeamsQuery, ListTeamsQueryVariables>(ListTeamsDocument, options);
        }
export type ListTeamsQueryHookResult = ReturnType<typeof useListTeamsQuery>;
export type ListTeamsLazyQueryHookResult = ReturnType<typeof useListTeamsLazyQuery>;
export type ListTeamsSuspenseQueryHookResult = ReturnType<typeof useListTeamsSuspenseQuery>;
export type ListTeamsQueryResult = Apollo.QueryResult<ListTeamsQuery, ListTeamsQueryVariables>;
export const ReadTeamDocument = gql`
    query ReadTeam($legalEntityId: String!, $teamId: String!) {
  readTeam(legalEntityId: $legalEntityId, teamId: $teamId) {
    ...TeamFields
  }
}
    ${TeamFieldsFragmentDoc}`;

/**
 * __useReadTeamQuery__
 *
 * To run a query within a React component, call `useReadTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTeamQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useReadTeamQuery(baseOptions: Apollo.QueryHookOptions<ReadTeamQuery, ReadTeamQueryVariables> & ({ variables: ReadTeamQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTeamQuery, ReadTeamQueryVariables>(ReadTeamDocument, options);
      }
export function useReadTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTeamQuery, ReadTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTeamQuery, ReadTeamQueryVariables>(ReadTeamDocument, options);
        }
export function useReadTeamSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadTeamQuery, ReadTeamQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadTeamQuery, ReadTeamQueryVariables>(ReadTeamDocument, options);
        }
export type ReadTeamQueryHookResult = ReturnType<typeof useReadTeamQuery>;
export type ReadTeamLazyQueryHookResult = ReturnType<typeof useReadTeamLazyQuery>;
export type ReadTeamSuspenseQueryHookResult = ReturnType<typeof useReadTeamSuspenseQuery>;
export type ReadTeamQueryResult = Apollo.QueryResult<ReadTeamQuery, ReadTeamQueryVariables>;
export const ListAffiliatesDocument = gql`
    query ListAffiliates($legalEntityId: String!, $affiliateType: AffiliateType!) {
  listAffiliates(legalEntityId: $legalEntityId, affiliateType: $affiliateType) {
    ...AffiliateOrganizationsFields
  }
}
    ${AffiliateOrganizationsFieldsFragmentDoc}`;

/**
 * __useListAffiliatesQuery__
 *
 * To run a query within a React component, call `useListAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAffiliatesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      affiliateType: // value for 'affiliateType'
 *   },
 * });
 */
export function useListAffiliatesQuery(baseOptions: Apollo.QueryHookOptions<ListAffiliatesQuery, ListAffiliatesQueryVariables> & ({ variables: ListAffiliatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAffiliatesQuery, ListAffiliatesQueryVariables>(ListAffiliatesDocument, options);
      }
export function useListAffiliatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAffiliatesQuery, ListAffiliatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAffiliatesQuery, ListAffiliatesQueryVariables>(ListAffiliatesDocument, options);
        }
export function useListAffiliatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAffiliatesQuery, ListAffiliatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAffiliatesQuery, ListAffiliatesQueryVariables>(ListAffiliatesDocument, options);
        }
export type ListAffiliatesQueryHookResult = ReturnType<typeof useListAffiliatesQuery>;
export type ListAffiliatesLazyQueryHookResult = ReturnType<typeof useListAffiliatesLazyQuery>;
export type ListAffiliatesSuspenseQueryHookResult = ReturnType<typeof useListAffiliatesSuspenseQuery>;
export type ListAffiliatesQueryResult = Apollo.QueryResult<ListAffiliatesQuery, ListAffiliatesQueryVariables>;
export const ActivateLegalEntityDocument = gql`
    mutation ActivateLegalEntity($id: String!) {
  activateLegalEntity(id: $id)
}
    `;
export type ActivateLegalEntityMutationFn = Apollo.MutationFunction<ActivateLegalEntityMutation, ActivateLegalEntityMutationVariables>;

/**
 * __useActivateLegalEntityMutation__
 *
 * To run a mutation, you first call `useActivateLegalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateLegalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateLegalEntityMutation, { data, loading, error }] = useActivateLegalEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateLegalEntityMutation(baseOptions?: Apollo.MutationHookOptions<ActivateLegalEntityMutation, ActivateLegalEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateLegalEntityMutation, ActivateLegalEntityMutationVariables>(ActivateLegalEntityDocument, options);
      }
export type ActivateLegalEntityMutationHookResult = ReturnType<typeof useActivateLegalEntityMutation>;
export type ActivateLegalEntityMutationResult = Apollo.MutationResult<ActivateLegalEntityMutation>;
export type ActivateLegalEntityMutationOptions = Apollo.BaseMutationOptions<ActivateLegalEntityMutation, ActivateLegalEntityMutationVariables>;
export const UpdateCogsInExpensesDocument = gql`
    mutation UpdateCogsInExpenses($legalEntityId: String!, $isCogsInExpenses: Boolean!) {
  updateCogsInExpenses(
    legalEntityId: $legalEntityId
    isCogsInExpenses: $isCogsInExpenses
  )
}
    `;
export type UpdateCogsInExpensesMutationFn = Apollo.MutationFunction<UpdateCogsInExpensesMutation, UpdateCogsInExpensesMutationVariables>;

/**
 * __useUpdateCogsInExpensesMutation__
 *
 * To run a mutation, you first call `useUpdateCogsInExpensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCogsInExpensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCogsInExpensesMutation, { data, loading, error }] = useUpdateCogsInExpensesMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      isCogsInExpenses: // value for 'isCogsInExpenses'
 *   },
 * });
 */
export function useUpdateCogsInExpensesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCogsInExpensesMutation, UpdateCogsInExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCogsInExpensesMutation, UpdateCogsInExpensesMutationVariables>(UpdateCogsInExpensesDocument, options);
      }
export type UpdateCogsInExpensesMutationHookResult = ReturnType<typeof useUpdateCogsInExpensesMutation>;
export type UpdateCogsInExpensesMutationResult = Apollo.MutationResult<UpdateCogsInExpensesMutation>;
export type UpdateCogsInExpensesMutationOptions = Apollo.BaseMutationOptions<UpdateCogsInExpensesMutation, UpdateCogsInExpensesMutationVariables>;
export const RequestAccessDocument = gql`
    mutation RequestAccess($legalEntityId: String!) {
  requestAccess(legalEntityId: $legalEntityId)
}
    `;
export type RequestAccessMutationFn = Apollo.MutationFunction<RequestAccessMutation, RequestAccessMutationVariables>;

/**
 * __useRequestAccessMutation__
 *
 * To run a mutation, you first call `useRequestAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccessMutation, { data, loading, error }] = useRequestAccessMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useRequestAccessMutation(baseOptions?: Apollo.MutationHookOptions<RequestAccessMutation, RequestAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAccessMutation, RequestAccessMutationVariables>(RequestAccessDocument, options);
      }
export type RequestAccessMutationHookResult = ReturnType<typeof useRequestAccessMutation>;
export type RequestAccessMutationResult = Apollo.MutationResult<RequestAccessMutation>;
export type RequestAccessMutationOptions = Apollo.BaseMutationOptions<RequestAccessMutation, RequestAccessMutationVariables>;
export const UpdateAiBookkeeperCutoverDateDocument = gql`
    mutation UpdateAIBookkeeperCutoverDate($legalEntityId: String!, $cutoverDate: DateTime!) {
  updateAIBookkeeperCutoverDate(
    legalEntityId: $legalEntityId
    cutoverDate: $cutoverDate
  )
}
    `;
export type UpdateAiBookkeeperCutoverDateMutationFn = Apollo.MutationFunction<UpdateAiBookkeeperCutoverDateMutation, UpdateAiBookkeeperCutoverDateMutationVariables>;

/**
 * __useUpdateAiBookkeeperCutoverDateMutation__
 *
 * To run a mutation, you first call `useUpdateAiBookkeeperCutoverDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAiBookkeeperCutoverDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAiBookkeeperCutoverDateMutation, { data, loading, error }] = useUpdateAiBookkeeperCutoverDateMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      cutoverDate: // value for 'cutoverDate'
 *   },
 * });
 */
export function useUpdateAiBookkeeperCutoverDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAiBookkeeperCutoverDateMutation, UpdateAiBookkeeperCutoverDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAiBookkeeperCutoverDateMutation, UpdateAiBookkeeperCutoverDateMutationVariables>(UpdateAiBookkeeperCutoverDateDocument, options);
      }
export type UpdateAiBookkeeperCutoverDateMutationHookResult = ReturnType<typeof useUpdateAiBookkeeperCutoverDateMutation>;
export type UpdateAiBookkeeperCutoverDateMutationResult = Apollo.MutationResult<UpdateAiBookkeeperCutoverDateMutation>;
export type UpdateAiBookkeeperCutoverDateMutationOptions = Apollo.BaseMutationOptions<UpdateAiBookkeeperCutoverDateMutation, UpdateAiBookkeeperCutoverDateMutationVariables>;
export const UpdateLegalEntityDocument = gql`
    mutation UpdateLegalEntity($id: String!, $name: String, $street: String, $streetAdditional: String, $city: String, $region: String, $postalCode: String, $countryCode: String, $lat: Float, $long: Float, $timezone: String, $industry: String, $websiteUrl: String, $incorporatedOn: DateInput, $fiscalYearStartMonth: Int, $fiscalYearNumberOffset: Int) {
  updateLegalEntity(
    id: $id
    name: $name
    address: {street: $street, streetAdditional: $streetAdditional, city: $city, region: $region, postalCode: $postalCode, countryCode: $countryCode, lat: $lat, long: $long}
    timezone: $timezone
    industry: $industry
    websiteUrl: $websiteUrl
    incorporatedOn: $incorporatedOn
    fiscalYearStartMonth: $fiscalYearStartMonth
    fiscalYearNumberOffset: $fiscalYearNumberOffset
  )
}
    `;
export type UpdateLegalEntityMutationFn = Apollo.MutationFunction<UpdateLegalEntityMutation, UpdateLegalEntityMutationVariables>;

/**
 * __useUpdateLegalEntityMutation__
 *
 * To run a mutation, you first call `useUpdateLegalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalEntityMutation, { data, loading, error }] = useUpdateLegalEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      street: // value for 'street'
 *      streetAdditional: // value for 'streetAdditional'
 *      city: // value for 'city'
 *      region: // value for 'region'
 *      postalCode: // value for 'postalCode'
 *      countryCode: // value for 'countryCode'
 *      lat: // value for 'lat'
 *      long: // value for 'long'
 *      timezone: // value for 'timezone'
 *      industry: // value for 'industry'
 *      websiteUrl: // value for 'websiteUrl'
 *      incorporatedOn: // value for 'incorporatedOn'
 *      fiscalYearStartMonth: // value for 'fiscalYearStartMonth'
 *      fiscalYearNumberOffset: // value for 'fiscalYearNumberOffset'
 *   },
 * });
 */
export function useUpdateLegalEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLegalEntityMutation, UpdateLegalEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLegalEntityMutation, UpdateLegalEntityMutationVariables>(UpdateLegalEntityDocument, options);
      }
export type UpdateLegalEntityMutationHookResult = ReturnType<typeof useUpdateLegalEntityMutation>;
export type UpdateLegalEntityMutationResult = Apollo.MutationResult<UpdateLegalEntityMutation>;
export type UpdateLegalEntityMutationOptions = Apollo.BaseMutationOptions<UpdateLegalEntityMutation, UpdateLegalEntityMutationVariables>;
export const ReadLegalEntityMetadataDocument = gql`
    query readLegalEntityMetadata($viewKey: ViewKey!) {
  readLegalEntityMetadata(viewKey: $viewKey) {
    ...ViewMetadataFields
  }
}
    ${ViewMetadataFieldsFragmentDoc}`;

/**
 * __useReadLegalEntityMetadataQuery__
 *
 * To run a query within a React component, call `useReadLegalEntityMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLegalEntityMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLegalEntityMetadataQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *   },
 * });
 */
export function useReadLegalEntityMetadataQuery(baseOptions: Apollo.QueryHookOptions<ReadLegalEntityMetadataQuery, ReadLegalEntityMetadataQueryVariables> & ({ variables: ReadLegalEntityMetadataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLegalEntityMetadataQuery, ReadLegalEntityMetadataQueryVariables>(ReadLegalEntityMetadataDocument, options);
      }
export function useReadLegalEntityMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLegalEntityMetadataQuery, ReadLegalEntityMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLegalEntityMetadataQuery, ReadLegalEntityMetadataQueryVariables>(ReadLegalEntityMetadataDocument, options);
        }
export function useReadLegalEntityMetadataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLegalEntityMetadataQuery, ReadLegalEntityMetadataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLegalEntityMetadataQuery, ReadLegalEntityMetadataQueryVariables>(ReadLegalEntityMetadataDocument, options);
        }
export type ReadLegalEntityMetadataQueryHookResult = ReturnType<typeof useReadLegalEntityMetadataQuery>;
export type ReadLegalEntityMetadataLazyQueryHookResult = ReturnType<typeof useReadLegalEntityMetadataLazyQuery>;
export type ReadLegalEntityMetadataSuspenseQueryHookResult = ReturnType<typeof useReadLegalEntityMetadataSuspenseQuery>;
export type ReadLegalEntityMetadataQueryResult = Apollo.QueryResult<ReadLegalEntityMetadataQuery, ReadLegalEntityMetadataQueryVariables>;
export const ReadLegalEntityDocument = gql`
    query ReadLegalEntity($id: String!) {
  readLegalEntity(id: $id) {
    ...LegalEntityFields
  }
}
    ${LegalEntityFieldsFragmentDoc}`;

/**
 * __useReadLegalEntityQuery__
 *
 * To run a query within a React component, call `useReadLegalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLegalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLegalEntityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadLegalEntityQuery(baseOptions: Apollo.QueryHookOptions<ReadLegalEntityQuery, ReadLegalEntityQueryVariables> & ({ variables: ReadLegalEntityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>(ReadLegalEntityDocument, options);
      }
export function useReadLegalEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>(ReadLegalEntityDocument, options);
        }
export function useReadLegalEntitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>(ReadLegalEntityDocument, options);
        }
export type ReadLegalEntityQueryHookResult = ReturnType<typeof useReadLegalEntityQuery>;
export type ReadLegalEntityLazyQueryHookResult = ReturnType<typeof useReadLegalEntityLazyQuery>;
export type ReadLegalEntitySuspenseQueryHookResult = ReturnType<typeof useReadLegalEntitySuspenseQuery>;
export type ReadLegalEntityQueryResult = Apollo.QueryResult<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>;
export const ReadLegalEntityPreferencesDocument = gql`
    query ReadLegalEntityPreferences($legalEntityId: String!) {
  readLegalEntityPreferences(legalEntityId: $legalEntityId) {
    legalEntityId
    isCogsInExpenses
    lineClassDisplayName
    lineClassPluralDisplayName
    bookkeeperAiCutoverAt
  }
}
    `;

/**
 * __useReadLegalEntityPreferencesQuery__
 *
 * To run a query within a React component, call `useReadLegalEntityPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLegalEntityPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLegalEntityPreferencesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadLegalEntityPreferencesQuery(baseOptions: Apollo.QueryHookOptions<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables> & ({ variables: ReadLegalEntityPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>(ReadLegalEntityPreferencesDocument, options);
      }
export function useReadLegalEntityPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>(ReadLegalEntityPreferencesDocument, options);
        }
export function useReadLegalEntityPreferencesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>(ReadLegalEntityPreferencesDocument, options);
        }
export type ReadLegalEntityPreferencesQueryHookResult = ReturnType<typeof useReadLegalEntityPreferencesQuery>;
export type ReadLegalEntityPreferencesLazyQueryHookResult = ReturnType<typeof useReadLegalEntityPreferencesLazyQuery>;
export type ReadLegalEntityPreferencesSuspenseQueryHookResult = ReturnType<typeof useReadLegalEntityPreferencesSuspenseQuery>;
export type ReadLegalEntityPreferencesQueryResult = Apollo.QueryResult<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>;
export const UpdatePartyFieldsDocument = gql`
    mutation UpdatePartyFields($legalEntityId: String!, $updates: [InputUpdatePartyFields!]!) {
  updatePartyFields(legalEntityId: $legalEntityId, updates: $updates)
}
    `;
export type UpdatePartyFieldsMutationFn = Apollo.MutationFunction<UpdatePartyFieldsMutation, UpdatePartyFieldsMutationVariables>;

/**
 * __useUpdatePartyFieldsMutation__
 *
 * To run a mutation, you first call `useUpdatePartyFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartyFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartyFieldsMutation, { data, loading, error }] = useUpdatePartyFieldsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdatePartyFieldsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartyFieldsMutation, UpdatePartyFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartyFieldsMutation, UpdatePartyFieldsMutationVariables>(UpdatePartyFieldsDocument, options);
      }
export type UpdatePartyFieldsMutationHookResult = ReturnType<typeof useUpdatePartyFieldsMutation>;
export type UpdatePartyFieldsMutationResult = Apollo.MutationResult<UpdatePartyFieldsMutation>;
export type UpdatePartyFieldsMutationOptions = Apollo.BaseMutationOptions<UpdatePartyFieldsMutation, UpdatePartyFieldsMutationVariables>;
export const CreatePartiesDocument = gql`
    mutation CreateParties($legalEntityId: String!, $partyNames: [String!]!) {
  createParties(legalEntityId: $legalEntityId, partyNames: $partyNames) {
    ...PartyFields
  }
}
    ${PartyFieldsFragmentDoc}`;
export type CreatePartiesMutationFn = Apollo.MutationFunction<CreatePartiesMutation, CreatePartiesMutationVariables>;

/**
 * __useCreatePartiesMutation__
 *
 * To run a mutation, you first call `useCreatePartiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartiesMutation, { data, loading, error }] = useCreatePartiesMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      partyNames: // value for 'partyNames'
 *   },
 * });
 */
export function useCreatePartiesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartiesMutation, CreatePartiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartiesMutation, CreatePartiesMutationVariables>(CreatePartiesDocument, options);
      }
export type CreatePartiesMutationHookResult = ReturnType<typeof useCreatePartiesMutation>;
export type CreatePartiesMutationResult = Apollo.MutationResult<CreatePartiesMutation>;
export type CreatePartiesMutationOptions = Apollo.BaseMutationOptions<CreatePartiesMutation, CreatePartiesMutationVariables>;
export const ReadPartyDocument = gql`
    query ReadParty($viewKey: ViewKey!, $partyId: String!) {
  readParty(viewKey: $viewKey, partyId: $partyId) {
    ...PartyFields
  }
}
    ${PartyFieldsFragmentDoc}`;

/**
 * __useReadPartyQuery__
 *
 * To run a query within a React component, call `useReadPartyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPartyQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useReadPartyQuery(baseOptions: Apollo.QueryHookOptions<ReadPartyQuery, ReadPartyQueryVariables> & ({ variables: ReadPartyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPartyQuery, ReadPartyQueryVariables>(ReadPartyDocument, options);
      }
export function useReadPartyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPartyQuery, ReadPartyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPartyQuery, ReadPartyQueryVariables>(ReadPartyDocument, options);
        }
export function useReadPartySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadPartyQuery, ReadPartyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadPartyQuery, ReadPartyQueryVariables>(ReadPartyDocument, options);
        }
export type ReadPartyQueryHookResult = ReturnType<typeof useReadPartyQuery>;
export type ReadPartyLazyQueryHookResult = ReturnType<typeof useReadPartyLazyQuery>;
export type ReadPartySuspenseQueryHookResult = ReturnType<typeof useReadPartySuspenseQuery>;
export type ReadPartyQueryResult = Apollo.QueryResult<ReadPartyQuery, ReadPartyQueryVariables>;
export const ListActivePartyRolesBatchDocument = gql`
    query ListActivePartyRolesBatch($inputs: [SummarizeInput!]!) {
  listActivePartyRolesBatch(inputs: $inputs) {
    partyId
    roles
  }
}
    `;

/**
 * __useListActivePartyRolesBatchQuery__
 *
 * To run a query within a React component, call `useListActivePartyRolesBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActivePartyRolesBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActivePartyRolesBatchQuery({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useListActivePartyRolesBatchQuery(baseOptions: Apollo.QueryHookOptions<ListActivePartyRolesBatchQuery, ListActivePartyRolesBatchQueryVariables> & ({ variables: ListActivePartyRolesBatchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListActivePartyRolesBatchQuery, ListActivePartyRolesBatchQueryVariables>(ListActivePartyRolesBatchDocument, options);
      }
export function useListActivePartyRolesBatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActivePartyRolesBatchQuery, ListActivePartyRolesBatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListActivePartyRolesBatchQuery, ListActivePartyRolesBatchQueryVariables>(ListActivePartyRolesBatchDocument, options);
        }
export function useListActivePartyRolesBatchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListActivePartyRolesBatchQuery, ListActivePartyRolesBatchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListActivePartyRolesBatchQuery, ListActivePartyRolesBatchQueryVariables>(ListActivePartyRolesBatchDocument, options);
        }
export type ListActivePartyRolesBatchQueryHookResult = ReturnType<typeof useListActivePartyRolesBatchQuery>;
export type ListActivePartyRolesBatchLazyQueryHookResult = ReturnType<typeof useListActivePartyRolesBatchLazyQuery>;
export type ListActivePartyRolesBatchSuspenseQueryHookResult = ReturnType<typeof useListActivePartyRolesBatchSuspenseQuery>;
export type ListActivePartyRolesBatchQueryResult = Apollo.QueryResult<ListActivePartyRolesBatchQuery, ListActivePartyRolesBatchQueryVariables>;
export const ListPartyTaxInfoStatsDocument = gql`
    query ListPartyTaxInfoStats($filter: TransactionFilter!, $origin: IntervalOrigin!, $direction: DirectionFromOrigin!) {
  listPartyTaxInfoStats(filter: $filter, origin: $origin, direction: $direction) {
    totalPartyCount
    taxpayerIdentificationCounts {
      ...TaxpayerIdentificationCountFields
    }
  }
}
    ${TaxpayerIdentificationCountFieldsFragmentDoc}`;

/**
 * __useListPartyTaxInfoStatsQuery__
 *
 * To run a query within a React component, call `useListPartyTaxInfoStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPartyTaxInfoStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartyTaxInfoStatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      origin: // value for 'origin'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useListPartyTaxInfoStatsQuery(baseOptions: Apollo.QueryHookOptions<ListPartyTaxInfoStatsQuery, ListPartyTaxInfoStatsQueryVariables> & ({ variables: ListPartyTaxInfoStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPartyTaxInfoStatsQuery, ListPartyTaxInfoStatsQueryVariables>(ListPartyTaxInfoStatsDocument, options);
      }
export function useListPartyTaxInfoStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPartyTaxInfoStatsQuery, ListPartyTaxInfoStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPartyTaxInfoStatsQuery, ListPartyTaxInfoStatsQueryVariables>(ListPartyTaxInfoStatsDocument, options);
        }
export function useListPartyTaxInfoStatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListPartyTaxInfoStatsQuery, ListPartyTaxInfoStatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPartyTaxInfoStatsQuery, ListPartyTaxInfoStatsQueryVariables>(ListPartyTaxInfoStatsDocument, options);
        }
export type ListPartyTaxInfoStatsQueryHookResult = ReturnType<typeof useListPartyTaxInfoStatsQuery>;
export type ListPartyTaxInfoStatsLazyQueryHookResult = ReturnType<typeof useListPartyTaxInfoStatsLazyQuery>;
export type ListPartyTaxInfoStatsSuspenseQueryHookResult = ReturnType<typeof useListPartyTaxInfoStatsSuspenseQuery>;
export type ListPartyTaxInfoStatsQueryResult = Apollo.QueryResult<ListPartyTaxInfoStatsQuery, ListPartyTaxInfoStatsQueryVariables>;
export const ListLatestPartyHistoriesDocument = gql`
    query ListLatestPartyHistories($legalEntityId: String!, $partyIds: [String!]!) {
  listLatestPartyHistories(legalEntityId: $legalEntityId, partyIds: $partyIds) {
    partyId
    taxpayerIdentificationClassification {
      ...PartyHistoryEntryFields
    }
    entityTypeClassification {
      ...PartyHistoryEntryFields
    }
  }
}
    ${PartyHistoryEntryFieldsFragmentDoc}`;

/**
 * __useListLatestPartyHistoriesQuery__
 *
 * To run a query within a React component, call `useListLatestPartyHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLatestPartyHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLatestPartyHistoriesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      partyIds: // value for 'partyIds'
 *   },
 * });
 */
export function useListLatestPartyHistoriesQuery(baseOptions: Apollo.QueryHookOptions<ListLatestPartyHistoriesQuery, ListLatestPartyHistoriesQueryVariables> & ({ variables: ListLatestPartyHistoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLatestPartyHistoriesQuery, ListLatestPartyHistoriesQueryVariables>(ListLatestPartyHistoriesDocument, options);
      }
export function useListLatestPartyHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLatestPartyHistoriesQuery, ListLatestPartyHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLatestPartyHistoriesQuery, ListLatestPartyHistoriesQueryVariables>(ListLatestPartyHistoriesDocument, options);
        }
export function useListLatestPartyHistoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListLatestPartyHistoriesQuery, ListLatestPartyHistoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListLatestPartyHistoriesQuery, ListLatestPartyHistoriesQueryVariables>(ListLatestPartyHistoriesDocument, options);
        }
export type ListLatestPartyHistoriesQueryHookResult = ReturnType<typeof useListLatestPartyHistoriesQuery>;
export type ListLatestPartyHistoriesLazyQueryHookResult = ReturnType<typeof useListLatestPartyHistoriesLazyQuery>;
export type ListLatestPartyHistoriesSuspenseQueryHookResult = ReturnType<typeof useListLatestPartyHistoriesSuspenseQuery>;
export type ListLatestPartyHistoriesQueryResult = Apollo.QueryResult<ListLatestPartyHistoriesQuery, ListLatestPartyHistoriesQueryVariables>;
export const ListPartyTaxInfoDocument = gql`
    query ListPartyTaxInfo($filter: TransactionFilter!, $origin: IntervalOrigin!, $direction: DirectionFromOrigin!) {
  listPartyTaxInfo(filter: $filter, origin: $origin, direction: $direction) {
    partyId
    taxpayerIdentification
    entityType
  }
}
    `;

/**
 * __useListPartyTaxInfoQuery__
 *
 * To run a query within a React component, call `useListPartyTaxInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPartyTaxInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartyTaxInfoQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      origin: // value for 'origin'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useListPartyTaxInfoQuery(baseOptions: Apollo.QueryHookOptions<ListPartyTaxInfoQuery, ListPartyTaxInfoQueryVariables> & ({ variables: ListPartyTaxInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPartyTaxInfoQuery, ListPartyTaxInfoQueryVariables>(ListPartyTaxInfoDocument, options);
      }
export function useListPartyTaxInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPartyTaxInfoQuery, ListPartyTaxInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPartyTaxInfoQuery, ListPartyTaxInfoQueryVariables>(ListPartyTaxInfoDocument, options);
        }
export function useListPartyTaxInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListPartyTaxInfoQuery, ListPartyTaxInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPartyTaxInfoQuery, ListPartyTaxInfoQueryVariables>(ListPartyTaxInfoDocument, options);
        }
export type ListPartyTaxInfoQueryHookResult = ReturnType<typeof useListPartyTaxInfoQuery>;
export type ListPartyTaxInfoLazyQueryHookResult = ReturnType<typeof useListPartyTaxInfoLazyQuery>;
export type ListPartyTaxInfoSuspenseQueryHookResult = ReturnType<typeof useListPartyTaxInfoSuspenseQuery>;
export type ListPartyTaxInfoQueryResult = Apollo.QueryResult<ListPartyTaxInfoQuery, ListPartyTaxInfoQueryVariables>;
export const ListActivePartyRolesDocument = gql`
    query ListActivePartyRoles($filter: SummaryFilterInput!, $direction: DirectionFromOrigin, $intervalOrigin: IntervalOrigin) {
  listActivePartyRoles(
    filter: $filter
    origin: $intervalOrigin
    direction: $direction
  )
}
    `;

/**
 * __useListActivePartyRolesQuery__
 *
 * To run a query within a React component, call `useListActivePartyRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActivePartyRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActivePartyRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      direction: // value for 'direction'
 *      intervalOrigin: // value for 'intervalOrigin'
 *   },
 * });
 */
export function useListActivePartyRolesQuery(baseOptions: Apollo.QueryHookOptions<ListActivePartyRolesQuery, ListActivePartyRolesQueryVariables> & ({ variables: ListActivePartyRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListActivePartyRolesQuery, ListActivePartyRolesQueryVariables>(ListActivePartyRolesDocument, options);
      }
export function useListActivePartyRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActivePartyRolesQuery, ListActivePartyRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListActivePartyRolesQuery, ListActivePartyRolesQueryVariables>(ListActivePartyRolesDocument, options);
        }
export function useListActivePartyRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListActivePartyRolesQuery, ListActivePartyRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListActivePartyRolesQuery, ListActivePartyRolesQueryVariables>(ListActivePartyRolesDocument, options);
        }
export type ListActivePartyRolesQueryHookResult = ReturnType<typeof useListActivePartyRolesQuery>;
export type ListActivePartyRolesLazyQueryHookResult = ReturnType<typeof useListActivePartyRolesLazyQuery>;
export type ListActivePartyRolesSuspenseQueryHookResult = ReturnType<typeof useListActivePartyRolesSuspenseQuery>;
export type ListActivePartyRolesQueryResult = Apollo.QueryResult<ListActivePartyRolesQuery, ListActivePartyRolesQueryVariables>;
export const CreateAspectRequestDocument = gql`
    mutation CreateAspectRequest($legalEntityId: String!, $code: String!) {
  createAspectRequest(legalEntityId: $legalEntityId, code: $code)
}
    `;
export type CreateAspectRequestMutationFn = Apollo.MutationFunction<CreateAspectRequestMutation, CreateAspectRequestMutationVariables>;

/**
 * __useCreateAspectRequestMutation__
 *
 * To run a mutation, you first call `useCreateAspectRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAspectRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAspectRequestMutation, { data, loading, error }] = useCreateAspectRequestMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCreateAspectRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateAspectRequestMutation, CreateAspectRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAspectRequestMutation, CreateAspectRequestMutationVariables>(CreateAspectRequestDocument, options);
      }
export type CreateAspectRequestMutationHookResult = ReturnType<typeof useCreateAspectRequestMutation>;
export type CreateAspectRequestMutationResult = Apollo.MutationResult<CreateAspectRequestMutation>;
export type CreateAspectRequestMutationOptions = Apollo.BaseMutationOptions<CreateAspectRequestMutation, CreateAspectRequestMutationVariables>;
export const ReadAspectRequestMetaDocument = gql`
    query ReadAspectRequestMeta($legalEntityId: String!, $code: String!) {
  readAspectRequestMeta(legalEntityId: $legalEntityId, code: $code) {
    requestor {
      ...EntityUserFields
    }
    createdAt
  }
}
    ${EntityUserFieldsFragmentDoc}`;

/**
 * __useReadAspectRequestMetaQuery__
 *
 * To run a query within a React component, call `useReadAspectRequestMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAspectRequestMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAspectRequestMetaQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useReadAspectRequestMetaQuery(baseOptions: Apollo.QueryHookOptions<ReadAspectRequestMetaQuery, ReadAspectRequestMetaQueryVariables> & ({ variables: ReadAspectRequestMetaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAspectRequestMetaQuery, ReadAspectRequestMetaQueryVariables>(ReadAspectRequestMetaDocument, options);
      }
export function useReadAspectRequestMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAspectRequestMetaQuery, ReadAspectRequestMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAspectRequestMetaQuery, ReadAspectRequestMetaQueryVariables>(ReadAspectRequestMetaDocument, options);
        }
export function useReadAspectRequestMetaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAspectRequestMetaQuery, ReadAspectRequestMetaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAspectRequestMetaQuery, ReadAspectRequestMetaQueryVariables>(ReadAspectRequestMetaDocument, options);
        }
export type ReadAspectRequestMetaQueryHookResult = ReturnType<typeof useReadAspectRequestMetaQuery>;
export type ReadAspectRequestMetaLazyQueryHookResult = ReturnType<typeof useReadAspectRequestMetaLazyQuery>;
export type ReadAspectRequestMetaSuspenseQueryHookResult = ReturnType<typeof useReadAspectRequestMetaSuspenseQuery>;
export type ReadAspectRequestMetaQueryResult = Apollo.QueryResult<ReadAspectRequestMetaQuery, ReadAspectRequestMetaQueryVariables>;
export const SendInvoiceManuallyDocument = gql`
    mutation SendInvoiceManually($legalEntityId: String!, $invoiceId: String!) {
  sendInvoiceManually(legalEntityId: $legalEntityId, invoiceId: $invoiceId)
}
    `;
export type SendInvoiceManuallyMutationFn = Apollo.MutationFunction<SendInvoiceManuallyMutation, SendInvoiceManuallyMutationVariables>;

/**
 * __useSendInvoiceManuallyMutation__
 *
 * To run a mutation, you first call `useSendInvoiceManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceManuallyMutation, { data, loading, error }] = useSendInvoiceManuallyMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useSendInvoiceManuallyMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceManuallyMutation, SendInvoiceManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceManuallyMutation, SendInvoiceManuallyMutationVariables>(SendInvoiceManuallyDocument, options);
      }
export type SendInvoiceManuallyMutationHookResult = ReturnType<typeof useSendInvoiceManuallyMutation>;
export type SendInvoiceManuallyMutationResult = Apollo.MutationResult<SendInvoiceManuallyMutation>;
export type SendInvoiceManuallyMutationOptions = Apollo.BaseMutationOptions<SendInvoiceManuallyMutation, SendInvoiceManuallyMutationVariables>;
export const DeleteDraftInvoiceDocument = gql`
    mutation DeleteDraftInvoice($legalEntityId: String!, $invoiceId: String!) {
  deleteDraftInvoice(legalEntityId: $legalEntityId, invoiceId: $invoiceId)
}
    `;
export type DeleteDraftInvoiceMutationFn = Apollo.MutationFunction<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;

/**
 * __useDeleteDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftInvoiceMutation, { data, loading, error }] = useDeleteDraftInvoiceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useDeleteDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>(DeleteDraftInvoiceDocument, options);
      }
export type DeleteDraftInvoiceMutationHookResult = ReturnType<typeof useDeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationResult = Apollo.MutationResult<DeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;
export const SaveInvoiceDiscountDocument = gql`
    mutation SaveInvoiceDiscount($legalEntityId: String!, $discount: InputDataSourceDiscount!) {
  saveInvoiceDiscount(legalEntityId: $legalEntityId, discount: $discount) {
    ...DiscountFields
  }
}
    ${DiscountFieldsFragmentDoc}`;
export type SaveInvoiceDiscountMutationFn = Apollo.MutationFunction<SaveInvoiceDiscountMutation, SaveInvoiceDiscountMutationVariables>;

/**
 * __useSaveInvoiceDiscountMutation__
 *
 * To run a mutation, you first call `useSaveInvoiceDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInvoiceDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInvoiceDiscountMutation, { data, loading, error }] = useSaveInvoiceDiscountMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useSaveInvoiceDiscountMutation(baseOptions?: Apollo.MutationHookOptions<SaveInvoiceDiscountMutation, SaveInvoiceDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInvoiceDiscountMutation, SaveInvoiceDiscountMutationVariables>(SaveInvoiceDiscountDocument, options);
      }
export type SaveInvoiceDiscountMutationHookResult = ReturnType<typeof useSaveInvoiceDiscountMutation>;
export type SaveInvoiceDiscountMutationResult = Apollo.MutationResult<SaveInvoiceDiscountMutation>;
export type SaveInvoiceDiscountMutationOptions = Apollo.BaseMutationOptions<SaveInvoiceDiscountMutation, SaveInvoiceDiscountMutationVariables>;
export const PayInvoiceDocument = gql`
    mutation PayInvoice($legalEntityId: String!, $invoiceId: String!, $paymentInstrumentId: String!) {
  payInvoice(
    legalEntityId: $legalEntityId
    invoiceId: $invoiceId
    paymentInstrumentId: $paymentInstrumentId
  ) {
    ...PayInvoiceSummaryFields
  }
}
    ${PayInvoiceSummaryFieldsFragmentDoc}`;
export type PayInvoiceMutationFn = Apollo.MutationFunction<PayInvoiceMutation, PayInvoiceMutationVariables>;

/**
 * __usePayInvoiceMutation__
 *
 * To run a mutation, you first call `usePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoiceMutation, { data, loading, error }] = usePayInvoiceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceId: // value for 'invoiceId'
 *      paymentInstrumentId: // value for 'paymentInstrumentId'
 *   },
 * });
 */
export function usePayInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<PayInvoiceMutation, PayInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayInvoiceMutation, PayInvoiceMutationVariables>(PayInvoiceDocument, options);
      }
export type PayInvoiceMutationHookResult = ReturnType<typeof usePayInvoiceMutation>;
export type PayInvoiceMutationResult = Apollo.MutationResult<PayInvoiceMutation>;
export type PayInvoiceMutationOptions = Apollo.BaseMutationOptions<PayInvoiceMutation, PayInvoiceMutationVariables>;
export const SaveInvoiceCustomerDocument = gql`
    mutation SaveInvoiceCustomer($legalEntityId: String!, $customer: InputCustomer!) {
  saveInvoiceCustomer(legalEntityId: $legalEntityId, customer: $customer) {
    ...CustomerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;
export type SaveInvoiceCustomerMutationFn = Apollo.MutationFunction<SaveInvoiceCustomerMutation, SaveInvoiceCustomerMutationVariables>;

/**
 * __useSaveInvoiceCustomerMutation__
 *
 * To run a mutation, you first call `useSaveInvoiceCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInvoiceCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInvoiceCustomerMutation, { data, loading, error }] = useSaveInvoiceCustomerMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useSaveInvoiceCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SaveInvoiceCustomerMutation, SaveInvoiceCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInvoiceCustomerMutation, SaveInvoiceCustomerMutationVariables>(SaveInvoiceCustomerDocument, options);
      }
export type SaveInvoiceCustomerMutationHookResult = ReturnType<typeof useSaveInvoiceCustomerMutation>;
export type SaveInvoiceCustomerMutationResult = Apollo.MutationResult<SaveInvoiceCustomerMutation>;
export type SaveInvoiceCustomerMutationOptions = Apollo.BaseMutationOptions<SaveInvoiceCustomerMutation, SaveInvoiceCustomerMutationVariables>;
export const SaveInvoicingProfileDocument = gql`
    mutation SaveInvoicingProfile($legalEntityId: String!, $profile: InputInvoicingProfile!) {
  saveInvoicingProfile(legalEntityId: $legalEntityId, profile: $profile) {
    ...InvoicingProfileFields
  }
}
    ${InvoicingProfileFieldsFragmentDoc}`;
export type SaveInvoicingProfileMutationFn = Apollo.MutationFunction<SaveInvoicingProfileMutation, SaveInvoicingProfileMutationVariables>;

/**
 * __useSaveInvoicingProfileMutation__
 *
 * To run a mutation, you first call `useSaveInvoicingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInvoicingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInvoicingProfileMutation, { data, loading, error }] = useSaveInvoicingProfileMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useSaveInvoicingProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveInvoicingProfileMutation, SaveInvoicingProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInvoicingProfileMutation, SaveInvoicingProfileMutationVariables>(SaveInvoicingProfileDocument, options);
      }
export type SaveInvoicingProfileMutationHookResult = ReturnType<typeof useSaveInvoicingProfileMutation>;
export type SaveInvoicingProfileMutationResult = Apollo.MutationResult<SaveInvoicingProfileMutation>;
export type SaveInvoicingProfileMutationOptions = Apollo.BaseMutationOptions<SaveInvoicingProfileMutation, SaveInvoicingProfileMutationVariables>;
export const SaveInvoiceProductDocument = gql`
    mutation SaveInvoiceProduct($legalEntityId: String!, $product: InputInvoiceProduct!) {
  saveInvoiceProduct(legalEntityId: $legalEntityId, product: $product) {
    ...InvoiceProductFields
  }
}
    ${InvoiceProductFieldsFragmentDoc}`;
export type SaveInvoiceProductMutationFn = Apollo.MutationFunction<SaveInvoiceProductMutation, SaveInvoiceProductMutationVariables>;

/**
 * __useSaveInvoiceProductMutation__
 *
 * To run a mutation, you first call `useSaveInvoiceProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInvoiceProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInvoiceProductMutation, { data, loading, error }] = useSaveInvoiceProductMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      product: // value for 'product'
 *   },
 * });
 */
export function useSaveInvoiceProductMutation(baseOptions?: Apollo.MutationHookOptions<SaveInvoiceProductMutation, SaveInvoiceProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInvoiceProductMutation, SaveInvoiceProductMutationVariables>(SaveInvoiceProductDocument, options);
      }
export type SaveInvoiceProductMutationHookResult = ReturnType<typeof useSaveInvoiceProductMutation>;
export type SaveInvoiceProductMutationResult = Apollo.MutationResult<SaveInvoiceProductMutation>;
export type SaveInvoiceProductMutationOptions = Apollo.BaseMutationOptions<SaveInvoiceProductMutation, SaveInvoiceProductMutationVariables>;
export const SaveDraftInvoiceDocument = gql`
    mutation SaveDraftInvoice($legalEntityId: String!, $invoice: InputInvoice!) {
  saveDraftInvoice(legalEntityId: $legalEntityId, invoice: $invoice)
}
    `;
export type SaveDraftInvoiceMutationFn = Apollo.MutationFunction<SaveDraftInvoiceMutation, SaveDraftInvoiceMutationVariables>;

/**
 * __useSaveDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useSaveDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDraftInvoiceMutation, { data, loading, error }] = useSaveDraftInvoiceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function useSaveDraftInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<SaveDraftInvoiceMutation, SaveDraftInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDraftInvoiceMutation, SaveDraftInvoiceMutationVariables>(SaveDraftInvoiceDocument, options);
      }
export type SaveDraftInvoiceMutationHookResult = ReturnType<typeof useSaveDraftInvoiceMutation>;
export type SaveDraftInvoiceMutationResult = Apollo.MutationResult<SaveDraftInvoiceMutation>;
export type SaveDraftInvoiceMutationOptions = Apollo.BaseMutationOptions<SaveDraftInvoiceMutation, SaveDraftInvoiceMutationVariables>;
export const VoidInvoiceDocument = gql`
    mutation VoidInvoice($legalEntityId: String!, $invoiceId: String!, $internalNote: String) {
  voidInvoice(
    legalEntityId: $legalEntityId
    invoiceId: $invoiceId
    internalNote: $internalNote
  )
}
    `;
export type VoidInvoiceMutationFn = Apollo.MutationFunction<VoidInvoiceMutation, VoidInvoiceMutationVariables>;

/**
 * __useVoidInvoiceMutation__
 *
 * To run a mutation, you first call `useVoidInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidInvoiceMutation, { data, loading, error }] = useVoidInvoiceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceId: // value for 'invoiceId'
 *      internalNote: // value for 'internalNote'
 *   },
 * });
 */
export function useVoidInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidInvoiceMutation, VoidInvoiceMutationVariables>(VoidInvoiceDocument, options);
      }
export type VoidInvoiceMutationHookResult = ReturnType<typeof useVoidInvoiceMutation>;
export type VoidInvoiceMutationResult = Apollo.MutationResult<VoidInvoiceMutation>;
export type VoidInvoiceMutationOptions = Apollo.BaseMutationOptions<VoidInvoiceMutation, VoidInvoiceMutationVariables>;
export const SendInvoiceDocument = gql`
    mutation SendInvoice($legalEntityId: String!, $invoiceId: String!) {
  sendInvoice(legalEntityId: $legalEntityId, invoiceId: $invoiceId)
}
    `;
export type SendInvoiceMutationFn = Apollo.MutationFunction<SendInvoiceMutation, SendInvoiceMutationVariables>;

/**
 * __useSendInvoiceMutation__
 *
 * To run a mutation, you first call `useSendInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceMutation, { data, loading, error }] = useSendInvoiceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useSendInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceMutation, SendInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceMutation, SendInvoiceMutationVariables>(SendInvoiceDocument, options);
      }
export type SendInvoiceMutationHookResult = ReturnType<typeof useSendInvoiceMutation>;
export type SendInvoiceMutationResult = Apollo.MutationResult<SendInvoiceMutation>;
export type SendInvoiceMutationOptions = Apollo.BaseMutationOptions<SendInvoiceMutation, SendInvoiceMutationVariables>;
export const MarkInvoiceAsPaidDocument = gql`
    mutation MarkInvoiceAsPaid($legalEntityId: String!, $invoiceId: String!, $internalNote: String, $datePaid: DateTime!) {
  markInvoiceAsPaid(
    legalEntityId: $legalEntityId
    invoiceId: $invoiceId
    internalNote: $internalNote
    datePaid: $datePaid
  )
}
    `;
export type MarkInvoiceAsPaidMutationFn = Apollo.MutationFunction<MarkInvoiceAsPaidMutation, MarkInvoiceAsPaidMutationVariables>;

/**
 * __useMarkInvoiceAsPaidMutation__
 *
 * To run a mutation, you first call `useMarkInvoiceAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvoiceAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInvoiceAsPaidMutation, { data, loading, error }] = useMarkInvoiceAsPaidMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceId: // value for 'invoiceId'
 *      internalNote: // value for 'internalNote'
 *      datePaid: // value for 'datePaid'
 *   },
 * });
 */
export function useMarkInvoiceAsPaidMutation(baseOptions?: Apollo.MutationHookOptions<MarkInvoiceAsPaidMutation, MarkInvoiceAsPaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkInvoiceAsPaidMutation, MarkInvoiceAsPaidMutationVariables>(MarkInvoiceAsPaidDocument, options);
      }
export type MarkInvoiceAsPaidMutationHookResult = ReturnType<typeof useMarkInvoiceAsPaidMutation>;
export type MarkInvoiceAsPaidMutationResult = Apollo.MutationResult<MarkInvoiceAsPaidMutation>;
export type MarkInvoiceAsPaidMutationOptions = Apollo.BaseMutationOptions<MarkInvoiceAsPaidMutation, MarkInvoiceAsPaidMutationVariables>;
export const SearchInvoiceProductsDocument = gql`
    query SearchInvoiceProducts($legalEntityId: String!, $text: String!) {
  searchInvoiceProducts(legalEntityId: $legalEntityId, text: $text) {
    ...InvoiceProductFields
  }
}
    ${InvoiceProductFieldsFragmentDoc}`;

/**
 * __useSearchInvoiceProductsQuery__
 *
 * To run a query within a React component, call `useSearchInvoiceProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInvoiceProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInvoiceProductsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSearchInvoiceProductsQuery(baseOptions: Apollo.QueryHookOptions<SearchInvoiceProductsQuery, SearchInvoiceProductsQueryVariables> & ({ variables: SearchInvoiceProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchInvoiceProductsQuery, SearchInvoiceProductsQueryVariables>(SearchInvoiceProductsDocument, options);
      }
export function useSearchInvoiceProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchInvoiceProductsQuery, SearchInvoiceProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchInvoiceProductsQuery, SearchInvoiceProductsQueryVariables>(SearchInvoiceProductsDocument, options);
        }
export function useSearchInvoiceProductsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchInvoiceProductsQuery, SearchInvoiceProductsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchInvoiceProductsQuery, SearchInvoiceProductsQueryVariables>(SearchInvoiceProductsDocument, options);
        }
export type SearchInvoiceProductsQueryHookResult = ReturnType<typeof useSearchInvoiceProductsQuery>;
export type SearchInvoiceProductsLazyQueryHookResult = ReturnType<typeof useSearchInvoiceProductsLazyQuery>;
export type SearchInvoiceProductsSuspenseQueryHookResult = ReturnType<typeof useSearchInvoiceProductsSuspenseQuery>;
export type SearchInvoiceProductsQueryResult = Apollo.QueryResult<SearchInvoiceProductsQuery, SearchInvoiceProductsQueryVariables>;
export const ReadSentInvoiceDocument = gql`
    query ReadSentInvoice($legalEntityId: String!, $id: String!) {
  readSentInvoice(legalEntityId: $legalEntityId, id: $id) {
    ...SentInvoiceUsersFields
  }
}
    ${SentInvoiceUsersFieldsFragmentDoc}`;

/**
 * __useReadSentInvoiceQuery__
 *
 * To run a query within a React component, call `useReadSentInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSentInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSentInvoiceQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadSentInvoiceQuery(baseOptions: Apollo.QueryHookOptions<ReadSentInvoiceQuery, ReadSentInvoiceQueryVariables> & ({ variables: ReadSentInvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSentInvoiceQuery, ReadSentInvoiceQueryVariables>(ReadSentInvoiceDocument, options);
      }
export function useReadSentInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSentInvoiceQuery, ReadSentInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSentInvoiceQuery, ReadSentInvoiceQueryVariables>(ReadSentInvoiceDocument, options);
        }
export function useReadSentInvoiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSentInvoiceQuery, ReadSentInvoiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSentInvoiceQuery, ReadSentInvoiceQueryVariables>(ReadSentInvoiceDocument, options);
        }
export type ReadSentInvoiceQueryHookResult = ReturnType<typeof useReadSentInvoiceQuery>;
export type ReadSentInvoiceLazyQueryHookResult = ReturnType<typeof useReadSentInvoiceLazyQuery>;
export type ReadSentInvoiceSuspenseQueryHookResult = ReturnType<typeof useReadSentInvoiceSuspenseQuery>;
export type ReadSentInvoiceQueryResult = Apollo.QueryResult<ReadSentInvoiceQuery, ReadSentInvoiceQueryVariables>;
export const ReadDraftInvoiceDocument = gql`
    query ReadDraftInvoice($legalEntityId: String!, $id: String!) {
  readDraftInvoice(legalEntityId: $legalEntityId, id: $id) {
    ...DraftInvoiceFields
  }
}
    ${DraftInvoiceFieldsFragmentDoc}`;

/**
 * __useReadDraftInvoiceQuery__
 *
 * To run a query within a React component, call `useReadDraftInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDraftInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDraftInvoiceQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadDraftInvoiceQuery(baseOptions: Apollo.QueryHookOptions<ReadDraftInvoiceQuery, ReadDraftInvoiceQueryVariables> & ({ variables: ReadDraftInvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDraftInvoiceQuery, ReadDraftInvoiceQueryVariables>(ReadDraftInvoiceDocument, options);
      }
export function useReadDraftInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDraftInvoiceQuery, ReadDraftInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDraftInvoiceQuery, ReadDraftInvoiceQueryVariables>(ReadDraftInvoiceDocument, options);
        }
export function useReadDraftInvoiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadDraftInvoiceQuery, ReadDraftInvoiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadDraftInvoiceQuery, ReadDraftInvoiceQueryVariables>(ReadDraftInvoiceDocument, options);
        }
export type ReadDraftInvoiceQueryHookResult = ReturnType<typeof useReadDraftInvoiceQuery>;
export type ReadDraftInvoiceLazyQueryHookResult = ReturnType<typeof useReadDraftInvoiceLazyQuery>;
export type ReadDraftInvoiceSuspenseQueryHookResult = ReturnType<typeof useReadDraftInvoiceSuspenseQuery>;
export type ReadDraftInvoiceQueryResult = Apollo.QueryResult<ReadDraftInvoiceQuery, ReadDraftInvoiceQueryVariables>;
export const SearchInvoiceDiscountsDocument = gql`
    query SearchInvoiceDiscounts($legalEntityId: String!, $text: String!) {
  searchInvoiceDiscounts(legalEntityId: $legalEntityId, text: $text) {
    ...DiscountFields
  }
}
    ${DiscountFieldsFragmentDoc}`;

/**
 * __useSearchInvoiceDiscountsQuery__
 *
 * To run a query within a React component, call `useSearchInvoiceDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInvoiceDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInvoiceDiscountsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSearchInvoiceDiscountsQuery(baseOptions: Apollo.QueryHookOptions<SearchInvoiceDiscountsQuery, SearchInvoiceDiscountsQueryVariables> & ({ variables: SearchInvoiceDiscountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchInvoiceDiscountsQuery, SearchInvoiceDiscountsQueryVariables>(SearchInvoiceDiscountsDocument, options);
      }
export function useSearchInvoiceDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchInvoiceDiscountsQuery, SearchInvoiceDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchInvoiceDiscountsQuery, SearchInvoiceDiscountsQueryVariables>(SearchInvoiceDiscountsDocument, options);
        }
export function useSearchInvoiceDiscountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchInvoiceDiscountsQuery, SearchInvoiceDiscountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchInvoiceDiscountsQuery, SearchInvoiceDiscountsQueryVariables>(SearchInvoiceDiscountsDocument, options);
        }
export type SearchInvoiceDiscountsQueryHookResult = ReturnType<typeof useSearchInvoiceDiscountsQuery>;
export type SearchInvoiceDiscountsLazyQueryHookResult = ReturnType<typeof useSearchInvoiceDiscountsLazyQuery>;
export type SearchInvoiceDiscountsSuspenseQueryHookResult = ReturnType<typeof useSearchInvoiceDiscountsSuspenseQuery>;
export type SearchInvoiceDiscountsQueryResult = Apollo.QueryResult<SearchInvoiceDiscountsQuery, SearchInvoiceDiscountsQueryVariables>;
export const ListDraftInvoicesDocument = gql`
    query ListDraftInvoices($legalEntityId: String!) {
  listDraftInvoices(legalEntityId: $legalEntityId) {
    ...DraftInvoiceSummaryFields
  }
}
    ${DraftInvoiceSummaryFieldsFragmentDoc}`;

/**
 * __useListDraftInvoicesQuery__
 *
 * To run a query within a React component, call `useListDraftInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDraftInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDraftInvoicesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListDraftInvoicesQuery(baseOptions: Apollo.QueryHookOptions<ListDraftInvoicesQuery, ListDraftInvoicesQueryVariables> & ({ variables: ListDraftInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDraftInvoicesQuery, ListDraftInvoicesQueryVariables>(ListDraftInvoicesDocument, options);
      }
export function useListDraftInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDraftInvoicesQuery, ListDraftInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDraftInvoicesQuery, ListDraftInvoicesQueryVariables>(ListDraftInvoicesDocument, options);
        }
export function useListDraftInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDraftInvoicesQuery, ListDraftInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDraftInvoicesQuery, ListDraftInvoicesQueryVariables>(ListDraftInvoicesDocument, options);
        }
export type ListDraftInvoicesQueryHookResult = ReturnType<typeof useListDraftInvoicesQuery>;
export type ListDraftInvoicesLazyQueryHookResult = ReturnType<typeof useListDraftInvoicesLazyQuery>;
export type ListDraftInvoicesSuspenseQueryHookResult = ReturnType<typeof useListDraftInvoicesSuspenseQuery>;
export type ListDraftInvoicesQueryResult = Apollo.QueryResult<ListDraftInvoicesQuery, ListDraftInvoicesQueryVariables>;
export const ListInvoicingProfilesDocument = gql`
    query ListInvoicingProfiles($legalEntityId: String!) {
  listInvoicingProfiles(legalEntityId: $legalEntityId) {
    ...InvoicingProfileFields
  }
}
    ${InvoicingProfileFieldsFragmentDoc}`;

/**
 * __useListInvoicingProfilesQuery__
 *
 * To run a query within a React component, call `useListInvoicingProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvoicingProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoicingProfilesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListInvoicingProfilesQuery(baseOptions: Apollo.QueryHookOptions<ListInvoicingProfilesQuery, ListInvoicingProfilesQueryVariables> & ({ variables: ListInvoicingProfilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInvoicingProfilesQuery, ListInvoicingProfilesQueryVariables>(ListInvoicingProfilesDocument, options);
      }
export function useListInvoicingProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInvoicingProfilesQuery, ListInvoicingProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInvoicingProfilesQuery, ListInvoicingProfilesQueryVariables>(ListInvoicingProfilesDocument, options);
        }
export function useListInvoicingProfilesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListInvoicingProfilesQuery, ListInvoicingProfilesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListInvoicingProfilesQuery, ListInvoicingProfilesQueryVariables>(ListInvoicingProfilesDocument, options);
        }
export type ListInvoicingProfilesQueryHookResult = ReturnType<typeof useListInvoicingProfilesQuery>;
export type ListInvoicingProfilesLazyQueryHookResult = ReturnType<typeof useListInvoicingProfilesLazyQuery>;
export type ListInvoicingProfilesSuspenseQueryHookResult = ReturnType<typeof useListInvoicingProfilesSuspenseQuery>;
export type ListInvoicingProfilesQueryResult = Apollo.QueryResult<ListInvoicingProfilesQuery, ListInvoicingProfilesQueryVariables>;
export const GetNextInvoiceNumberDocument = gql`
    query GetNextInvoiceNumber($legalEntityId: String!, $invoiceProfileId: String!) {
  getNextInvoiceNumber(
    legalEntityId: $legalEntityId
    invoiceProfileId: $invoiceProfileId
  ) {
    ...InvoiceNumberFields
  }
}
    ${InvoiceNumberFieldsFragmentDoc}`;

/**
 * __useGetNextInvoiceNumberQuery__
 *
 * To run a query within a React component, call `useGetNextInvoiceNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextInvoiceNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextInvoiceNumberQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceProfileId: // value for 'invoiceProfileId'
 *   },
 * });
 */
export function useGetNextInvoiceNumberQuery(baseOptions: Apollo.QueryHookOptions<GetNextInvoiceNumberQuery, GetNextInvoiceNumberQueryVariables> & ({ variables: GetNextInvoiceNumberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNextInvoiceNumberQuery, GetNextInvoiceNumberQueryVariables>(GetNextInvoiceNumberDocument, options);
      }
export function useGetNextInvoiceNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNextInvoiceNumberQuery, GetNextInvoiceNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNextInvoiceNumberQuery, GetNextInvoiceNumberQueryVariables>(GetNextInvoiceNumberDocument, options);
        }
export function useGetNextInvoiceNumberSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetNextInvoiceNumberQuery, GetNextInvoiceNumberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNextInvoiceNumberQuery, GetNextInvoiceNumberQueryVariables>(GetNextInvoiceNumberDocument, options);
        }
export type GetNextInvoiceNumberQueryHookResult = ReturnType<typeof useGetNextInvoiceNumberQuery>;
export type GetNextInvoiceNumberLazyQueryHookResult = ReturnType<typeof useGetNextInvoiceNumberLazyQuery>;
export type GetNextInvoiceNumberSuspenseQueryHookResult = ReturnType<typeof useGetNextInvoiceNumberSuspenseQuery>;
export type GetNextInvoiceNumberQueryResult = Apollo.QueryResult<GetNextInvoiceNumberQuery, GetNextInvoiceNumberQueryVariables>;
export const ReadSentInvoiceFilesDocument = gql`
    query ReadSentInvoiceFiles($legalEntityId: String!, $id: String!) {
  readSentInvoiceFiles(legalEntityId: $legalEntityId, id: $id) {
    ...InvoiceFileFields
  }
}
    ${InvoiceFileFieldsFragmentDoc}`;

/**
 * __useReadSentInvoiceFilesQuery__
 *
 * To run a query within a React component, call `useReadSentInvoiceFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSentInvoiceFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSentInvoiceFilesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadSentInvoiceFilesQuery(baseOptions: Apollo.QueryHookOptions<ReadSentInvoiceFilesQuery, ReadSentInvoiceFilesQueryVariables> & ({ variables: ReadSentInvoiceFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSentInvoiceFilesQuery, ReadSentInvoiceFilesQueryVariables>(ReadSentInvoiceFilesDocument, options);
      }
export function useReadSentInvoiceFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSentInvoiceFilesQuery, ReadSentInvoiceFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSentInvoiceFilesQuery, ReadSentInvoiceFilesQueryVariables>(ReadSentInvoiceFilesDocument, options);
        }
export function useReadSentInvoiceFilesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSentInvoiceFilesQuery, ReadSentInvoiceFilesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSentInvoiceFilesQuery, ReadSentInvoiceFilesQueryVariables>(ReadSentInvoiceFilesDocument, options);
        }
export type ReadSentInvoiceFilesQueryHookResult = ReturnType<typeof useReadSentInvoiceFilesQuery>;
export type ReadSentInvoiceFilesLazyQueryHookResult = ReturnType<typeof useReadSentInvoiceFilesLazyQuery>;
export type ReadSentInvoiceFilesSuspenseQueryHookResult = ReturnType<typeof useReadSentInvoiceFilesSuspenseQuery>;
export type ReadSentInvoiceFilesQueryResult = Apollo.QueryResult<ReadSentInvoiceFilesQuery, ReadSentInvoiceFilesQueryVariables>;
export const SearchInvoiceCustomersDocument = gql`
    query SearchInvoiceCustomers($legalEntityId: String!, $text: String!) {
  searchInvoiceCustomers(legalEntityId: $legalEntityId, text: $text) {
    customers {
      ...CustomerFields
    }
  }
}
    ${CustomerFieldsFragmentDoc}`;

/**
 * __useSearchInvoiceCustomersQuery__
 *
 * To run a query within a React component, call `useSearchInvoiceCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchInvoiceCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchInvoiceCustomersQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSearchInvoiceCustomersQuery(baseOptions: Apollo.QueryHookOptions<SearchInvoiceCustomersQuery, SearchInvoiceCustomersQueryVariables> & ({ variables: SearchInvoiceCustomersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchInvoiceCustomersQuery, SearchInvoiceCustomersQueryVariables>(SearchInvoiceCustomersDocument, options);
      }
export function useSearchInvoiceCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchInvoiceCustomersQuery, SearchInvoiceCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchInvoiceCustomersQuery, SearchInvoiceCustomersQueryVariables>(SearchInvoiceCustomersDocument, options);
        }
export function useSearchInvoiceCustomersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchInvoiceCustomersQuery, SearchInvoiceCustomersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchInvoiceCustomersQuery, SearchInvoiceCustomersQueryVariables>(SearchInvoiceCustomersDocument, options);
        }
export type SearchInvoiceCustomersQueryHookResult = ReturnType<typeof useSearchInvoiceCustomersQuery>;
export type SearchInvoiceCustomersLazyQueryHookResult = ReturnType<typeof useSearchInvoiceCustomersLazyQuery>;
export type SearchInvoiceCustomersSuspenseQueryHookResult = ReturnType<typeof useSearchInvoiceCustomersSuspenseQuery>;
export type SearchInvoiceCustomersQueryResult = Apollo.QueryResult<SearchInvoiceCustomersQuery, SearchInvoiceCustomersQueryVariables>;
export const ListSentInvoicesDocument = gql`
    query ListSentInvoices($legalEntityId: String!) {
  listSentInvoices(legalEntityId: $legalEntityId) {
    ...InvoiceSummaryFields
  }
}
    ${InvoiceSummaryFieldsFragmentDoc}`;

/**
 * __useListSentInvoicesQuery__
 *
 * To run a query within a React component, call `useListSentInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSentInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSentInvoicesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListSentInvoicesQuery(baseOptions: Apollo.QueryHookOptions<ListSentInvoicesQuery, ListSentInvoicesQueryVariables> & ({ variables: ListSentInvoicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSentInvoicesQuery, ListSentInvoicesQueryVariables>(ListSentInvoicesDocument, options);
      }
export function useListSentInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSentInvoicesQuery, ListSentInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSentInvoicesQuery, ListSentInvoicesQueryVariables>(ListSentInvoicesDocument, options);
        }
export function useListSentInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListSentInvoicesQuery, ListSentInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListSentInvoicesQuery, ListSentInvoicesQueryVariables>(ListSentInvoicesDocument, options);
        }
export type ListSentInvoicesQueryHookResult = ReturnType<typeof useListSentInvoicesQuery>;
export type ListSentInvoicesLazyQueryHookResult = ReturnType<typeof useListSentInvoicesLazyQuery>;
export type ListSentInvoicesSuspenseQueryHookResult = ReturnType<typeof useListSentInvoicesSuspenseQuery>;
export type ListSentInvoicesQueryResult = Apollo.QueryResult<ListSentInvoicesQuery, ListSentInvoicesQueryVariables>;
export const PreviewInvoiceEmailDocument = gql`
    query PreviewInvoiceEmail($legalEntityId: String!, $invoiceId: String!) {
  previewInvoiceEmail(legalEntityId: $legalEntityId, invoiceId: $invoiceId)
}
    `;

/**
 * __usePreviewInvoiceEmailQuery__
 *
 * To run a query within a React component, call `usePreviewInvoiceEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewInvoiceEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewInvoiceEmailQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function usePreviewInvoiceEmailQuery(baseOptions: Apollo.QueryHookOptions<PreviewInvoiceEmailQuery, PreviewInvoiceEmailQueryVariables> & ({ variables: PreviewInvoiceEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewInvoiceEmailQuery, PreviewInvoiceEmailQueryVariables>(PreviewInvoiceEmailDocument, options);
      }
export function usePreviewInvoiceEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewInvoiceEmailQuery, PreviewInvoiceEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewInvoiceEmailQuery, PreviewInvoiceEmailQueryVariables>(PreviewInvoiceEmailDocument, options);
        }
export function usePreviewInvoiceEmailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PreviewInvoiceEmailQuery, PreviewInvoiceEmailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreviewInvoiceEmailQuery, PreviewInvoiceEmailQueryVariables>(PreviewInvoiceEmailDocument, options);
        }
export type PreviewInvoiceEmailQueryHookResult = ReturnType<typeof usePreviewInvoiceEmailQuery>;
export type PreviewInvoiceEmailLazyQueryHookResult = ReturnType<typeof usePreviewInvoiceEmailLazyQuery>;
export type PreviewInvoiceEmailSuspenseQueryHookResult = ReturnType<typeof usePreviewInvoiceEmailSuspenseQuery>;
export type PreviewInvoiceEmailQueryResult = Apollo.QueryResult<PreviewInvoiceEmailQuery, PreviewInvoiceEmailQueryVariables>;
export const GetDraftInvoiceDefaultsDocument = gql`
    query GetDraftInvoiceDefaults($legalEntityId: String!, $invoiceProfileId: String!) {
  getDraftInvoiceDefaults(
    legalEntityId: $legalEntityId
    invoiceProfileId: $invoiceProfileId
  ) {
    ...InvoiceDefaultsFields
  }
}
    ${InvoiceDefaultsFieldsFragmentDoc}`;

/**
 * __useGetDraftInvoiceDefaultsQuery__
 *
 * To run a query within a React component, call `useGetDraftInvoiceDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftInvoiceDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftInvoiceDefaultsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      invoiceProfileId: // value for 'invoiceProfileId'
 *   },
 * });
 */
export function useGetDraftInvoiceDefaultsQuery(baseOptions: Apollo.QueryHookOptions<GetDraftInvoiceDefaultsQuery, GetDraftInvoiceDefaultsQueryVariables> & ({ variables: GetDraftInvoiceDefaultsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDraftInvoiceDefaultsQuery, GetDraftInvoiceDefaultsQueryVariables>(GetDraftInvoiceDefaultsDocument, options);
      }
export function useGetDraftInvoiceDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDraftInvoiceDefaultsQuery, GetDraftInvoiceDefaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDraftInvoiceDefaultsQuery, GetDraftInvoiceDefaultsQueryVariables>(GetDraftInvoiceDefaultsDocument, options);
        }
export function useGetDraftInvoiceDefaultsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDraftInvoiceDefaultsQuery, GetDraftInvoiceDefaultsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDraftInvoiceDefaultsQuery, GetDraftInvoiceDefaultsQueryVariables>(GetDraftInvoiceDefaultsDocument, options);
        }
export type GetDraftInvoiceDefaultsQueryHookResult = ReturnType<typeof useGetDraftInvoiceDefaultsQuery>;
export type GetDraftInvoiceDefaultsLazyQueryHookResult = ReturnType<typeof useGetDraftInvoiceDefaultsLazyQuery>;
export type GetDraftInvoiceDefaultsSuspenseQueryHookResult = ReturnType<typeof useGetDraftInvoiceDefaultsSuspenseQuery>;
export type GetDraftInvoiceDefaultsQueryResult = Apollo.QueryResult<GetDraftInvoiceDefaultsQuery, GetDraftInvoiceDefaultsQueryVariables>;
export const ReadCategoryDocument = gql`
    query ReadCategory($viewKey: ViewKey!, $categoryId: String!) {
  readCategory(viewKey: $viewKey, categoryId: $categoryId) {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;

/**
 * __useReadCategoryQuery__
 *
 * To run a query within a React component, call `useReadCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCategoryQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useReadCategoryQuery(baseOptions: Apollo.QueryHookOptions<ReadCategoryQuery, ReadCategoryQueryVariables> & ({ variables: ReadCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCategoryQuery, ReadCategoryQueryVariables>(ReadCategoryDocument, options);
      }
export function useReadCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCategoryQuery, ReadCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCategoryQuery, ReadCategoryQueryVariables>(ReadCategoryDocument, options);
        }
export function useReadCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadCategoryQuery, ReadCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadCategoryQuery, ReadCategoryQueryVariables>(ReadCategoryDocument, options);
        }
export type ReadCategoryQueryHookResult = ReturnType<typeof useReadCategoryQuery>;
export type ReadCategoryLazyQueryHookResult = ReturnType<typeof useReadCategoryLazyQuery>;
export type ReadCategorySuspenseQueryHookResult = ReturnType<typeof useReadCategorySuspenseQuery>;
export type ReadCategoryQueryResult = Apollo.QueryResult<ReadCategoryQuery, ReadCategoryQueryVariables>;
export const UpdateNotificationStatusDocument = gql`
    mutation UpdateNotificationStatus($id: String!, $status: WebNotificationStatus!) {
  updateNotificationStatus(id: $id, status: $status)
}
    `;
export type UpdateNotificationStatusMutationFn = Apollo.MutationFunction<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>;

/**
 * __useUpdateNotificationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationStatusMutation, { data, loading, error }] = useUpdateNotificationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateNotificationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>(UpdateNotificationStatusDocument, options);
      }
export type UpdateNotificationStatusMutationHookResult = ReturnType<typeof useUpdateNotificationStatusMutation>;
export type UpdateNotificationStatusMutationResult = Apollo.MutationResult<UpdateNotificationStatusMutation>;
export type UpdateNotificationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>;
export const UpdateNotificationSettingDocument = gql`
    mutation UpdateNotificationSetting($legalEntityId: String!, $eventType: EventType!, $enabled: Boolean!) {
  updateNotificationSetting(
    legalEntityId: $legalEntityId
    eventType: $eventType
    enabled: $enabled
  )
}
    `;
export type UpdateNotificationSettingMutationFn = Apollo.MutationFunction<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>;

/**
 * __useUpdateNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingMutation, { data, loading, error }] = useUpdateNotificationSettingMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      eventType: // value for 'eventType'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>(UpdateNotificationSettingDocument, options);
      }
export type UpdateNotificationSettingMutationHookResult = ReturnType<typeof useUpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationResult = Apollo.MutationResult<UpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingMutation, UpdateNotificationSettingMutationVariables>;
export const NotificationCreatedDocument = gql`
    subscription NotificationCreated {
  notificationCreated {
    ...WebNotificationFields
  }
}
    ${WebNotificationFieldsFragmentDoc}`;

/**
 * __useNotificationCreatedSubscription__
 *
 * To run a query within a React component, call `useNotificationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationCreatedSubscription, NotificationCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationCreatedSubscription, NotificationCreatedSubscriptionVariables>(NotificationCreatedDocument, options);
      }
export type NotificationCreatedSubscriptionHookResult = ReturnType<typeof useNotificationCreatedSubscription>;
export type NotificationCreatedSubscriptionResult = Apollo.SubscriptionResult<NotificationCreatedSubscription>;
export const ListNotificationsDocument = gql`
    query ListNotifications {
  listNotifications {
    ...WebNotificationFields
  }
}
    ${WebNotificationFieldsFragmentDoc}`;

/**
 * __useListNotificationsQuery__
 *
 * To run a query within a React component, call `useListNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<ListNotificationsQuery, ListNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListNotificationsQuery, ListNotificationsQueryVariables>(ListNotificationsDocument, options);
      }
export function useListNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListNotificationsQuery, ListNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListNotificationsQuery, ListNotificationsQueryVariables>(ListNotificationsDocument, options);
        }
export function useListNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListNotificationsQuery, ListNotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListNotificationsQuery, ListNotificationsQueryVariables>(ListNotificationsDocument, options);
        }
export type ListNotificationsQueryHookResult = ReturnType<typeof useListNotificationsQuery>;
export type ListNotificationsLazyQueryHookResult = ReturnType<typeof useListNotificationsLazyQuery>;
export type ListNotificationsSuspenseQueryHookResult = ReturnType<typeof useListNotificationsSuspenseQuery>;
export type ListNotificationsQueryResult = Apollo.QueryResult<ListNotificationsQuery, ListNotificationsQueryVariables>;
export const LogCustomerEventsDocument = gql`
    mutation LogCustomerEvents($events: [CustomerEvent!]!) {
  logCustomerEvents(events: $events)
}
    `;
export type LogCustomerEventsMutationFn = Apollo.MutationFunction<LogCustomerEventsMutation, LogCustomerEventsMutationVariables>;

/**
 * __useLogCustomerEventsMutation__
 *
 * To run a mutation, you first call `useLogCustomerEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogCustomerEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logCustomerEventsMutation, { data, loading, error }] = useLogCustomerEventsMutation({
 *   variables: {
 *      events: // value for 'events'
 *   },
 * });
 */
export function useLogCustomerEventsMutation(baseOptions?: Apollo.MutationHookOptions<LogCustomerEventsMutation, LogCustomerEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogCustomerEventsMutation, LogCustomerEventsMutationVariables>(LogCustomerEventsDocument, options);
      }
export type LogCustomerEventsMutationHookResult = ReturnType<typeof useLogCustomerEventsMutation>;
export type LogCustomerEventsMutationResult = Apollo.MutationResult<LogCustomerEventsMutation>;
export type LogCustomerEventsMutationOptions = Apollo.BaseMutationOptions<LogCustomerEventsMutation, LogCustomerEventsMutationVariables>;
export const ProvisionTrialBillingSubscriptionDocument = gql`
    mutation ProvisionTrialBillingSubscription($organizationId: String!, $priceId: String!, $stripePaymentMethodId: String!) {
  provisionTrialBillingSubscription(
    organizationId: $organizationId
    priceId: $priceId
    stripePaymentMethodId: $stripePaymentMethodId
  ) {
    ...NewTrialBillingSubscriptionFields
  }
}
    ${NewTrialBillingSubscriptionFieldsFragmentDoc}`;
export type ProvisionTrialBillingSubscriptionMutationFn = Apollo.MutationFunction<ProvisionTrialBillingSubscriptionMutation, ProvisionTrialBillingSubscriptionMutationVariables>;

/**
 * __useProvisionTrialBillingSubscriptionMutation__
 *
 * To run a mutation, you first call `useProvisionTrialBillingSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionTrialBillingSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionTrialBillingSubscriptionMutation, { data, loading, error }] = useProvisionTrialBillingSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      priceId: // value for 'priceId'
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useProvisionTrialBillingSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ProvisionTrialBillingSubscriptionMutation, ProvisionTrialBillingSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProvisionTrialBillingSubscriptionMutation, ProvisionTrialBillingSubscriptionMutationVariables>(ProvisionTrialBillingSubscriptionDocument, options);
      }
export type ProvisionTrialBillingSubscriptionMutationHookResult = ReturnType<typeof useProvisionTrialBillingSubscriptionMutation>;
export type ProvisionTrialBillingSubscriptionMutationResult = Apollo.MutationResult<ProvisionTrialBillingSubscriptionMutation>;
export type ProvisionTrialBillingSubscriptionMutationOptions = Apollo.BaseMutationOptions<ProvisionTrialBillingSubscriptionMutation, ProvisionTrialBillingSubscriptionMutationVariables>;
export const CreateBillingSubscriptionDocument = gql`
    mutation CreateBillingSubscription($organizationId: String!, $priceId: String!, $stripePaymentMethodId: String) {
  createBillingSubscription(
    organizationId: $organizationId
    priceId: $priceId
    stripePaymentMethodId: $stripePaymentMethodId
  ) {
    ...NewBillingSubscriptionFields
  }
}
    ${NewBillingSubscriptionFieldsFragmentDoc}`;
export type CreateBillingSubscriptionMutationFn = Apollo.MutationFunction<CreateBillingSubscriptionMutation, CreateBillingSubscriptionMutationVariables>;

/**
 * __useCreateBillingSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateBillingSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingSubscriptionMutation, { data, loading, error }] = useCreateBillingSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      priceId: // value for 'priceId'
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useCreateBillingSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingSubscriptionMutation, CreateBillingSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillingSubscriptionMutation, CreateBillingSubscriptionMutationVariables>(CreateBillingSubscriptionDocument, options);
      }
export type CreateBillingSubscriptionMutationHookResult = ReturnType<typeof useCreateBillingSubscriptionMutation>;
export type CreateBillingSubscriptionMutationResult = Apollo.MutationResult<CreateBillingSubscriptionMutation>;
export type CreateBillingSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateBillingSubscriptionMutation, CreateBillingSubscriptionMutationVariables>;
export const UpdateBillingPaymentMethodDocument = gql`
    mutation UpdateBillingPaymentMethod($organizationId: String!, $stripePaymentMethodId: String!) {
  updateBillingPaymentMethod(
    organizationId: $organizationId
    stripePaymentMethodId: $stripePaymentMethodId
  )
}
    `;
export type UpdateBillingPaymentMethodMutationFn = Apollo.MutationFunction<UpdateBillingPaymentMethodMutation, UpdateBillingPaymentMethodMutationVariables>;

/**
 * __useUpdateBillingPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateBillingPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingPaymentMethodMutation, { data, loading, error }] = useUpdateBillingPaymentMethodMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useUpdateBillingPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingPaymentMethodMutation, UpdateBillingPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingPaymentMethodMutation, UpdateBillingPaymentMethodMutationVariables>(UpdateBillingPaymentMethodDocument, options);
      }
export type UpdateBillingPaymentMethodMutationHookResult = ReturnType<typeof useUpdateBillingPaymentMethodMutation>;
export type UpdateBillingPaymentMethodMutationResult = Apollo.MutationResult<UpdateBillingPaymentMethodMutation>;
export type UpdateBillingPaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdateBillingPaymentMethodMutation, UpdateBillingPaymentMethodMutationVariables>;
export const CreateTrialBillingSubscriptionDocument = gql`
    mutation CreateTrialBillingSubscription($organizationId: String!, $priceId: String!, $stripePaymentMethodId: String!) {
  createTrialBillingSubscription(
    organizationId: $organizationId
    priceId: $priceId
    stripePaymentMethodId: $stripePaymentMethodId
  ) {
    ...NewTrialBillingSubscriptionFields
  }
}
    ${NewTrialBillingSubscriptionFieldsFragmentDoc}`;
export type CreateTrialBillingSubscriptionMutationFn = Apollo.MutationFunction<CreateTrialBillingSubscriptionMutation, CreateTrialBillingSubscriptionMutationVariables>;

/**
 * __useCreateTrialBillingSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateTrialBillingSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrialBillingSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrialBillingSubscriptionMutation, { data, loading, error }] = useCreateTrialBillingSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      priceId: // value for 'priceId'
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useCreateTrialBillingSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrialBillingSubscriptionMutation, CreateTrialBillingSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrialBillingSubscriptionMutation, CreateTrialBillingSubscriptionMutationVariables>(CreateTrialBillingSubscriptionDocument, options);
      }
export type CreateTrialBillingSubscriptionMutationHookResult = ReturnType<typeof useCreateTrialBillingSubscriptionMutation>;
export type CreateTrialBillingSubscriptionMutationResult = Apollo.MutationResult<CreateTrialBillingSubscriptionMutation>;
export type CreateTrialBillingSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateTrialBillingSubscriptionMutation, CreateTrialBillingSubscriptionMutationVariables>;
export const CreateBillingOnboardingSubscriptionDocument = gql`
    mutation CreateBillingOnboardingSubscription($organizationId: String!) {
  createBillingOnboardingSubscription(organizationId: $organizationId) {
    ...CreateBillingOnboardingSubscriptionFields
  }
}
    ${CreateBillingOnboardingSubscriptionFieldsFragmentDoc}`;
export type CreateBillingOnboardingSubscriptionMutationFn = Apollo.MutationFunction<CreateBillingOnboardingSubscriptionMutation, CreateBillingOnboardingSubscriptionMutationVariables>;

/**
 * __useCreateBillingOnboardingSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateBillingOnboardingSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingOnboardingSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingOnboardingSubscriptionMutation, { data, loading, error }] = useCreateBillingOnboardingSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateBillingOnboardingSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingOnboardingSubscriptionMutation, CreateBillingOnboardingSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillingOnboardingSubscriptionMutation, CreateBillingOnboardingSubscriptionMutationVariables>(CreateBillingOnboardingSubscriptionDocument, options);
      }
export type CreateBillingOnboardingSubscriptionMutationHookResult = ReturnType<typeof useCreateBillingOnboardingSubscriptionMutation>;
export type CreateBillingOnboardingSubscriptionMutationResult = Apollo.MutationResult<CreateBillingOnboardingSubscriptionMutation>;
export type CreateBillingOnboardingSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateBillingOnboardingSubscriptionMutation, CreateBillingOnboardingSubscriptionMutationVariables>;
export const UpdateBillingSubscriptionDocument = gql`
    mutation UpdateBillingSubscription($organizationId: String!, $priceId: String!) {
  updateBillingSubscription(organizationId: $organizationId, priceId: $priceId)
}
    `;
export type UpdateBillingSubscriptionMutationFn = Apollo.MutationFunction<UpdateBillingSubscriptionMutation, UpdateBillingSubscriptionMutationVariables>;

/**
 * __useUpdateBillingSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateBillingSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingSubscriptionMutation, { data, loading, error }] = useUpdateBillingSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      priceId: // value for 'priceId'
 *   },
 * });
 */
export function useUpdateBillingSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingSubscriptionMutation, UpdateBillingSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillingSubscriptionMutation, UpdateBillingSubscriptionMutationVariables>(UpdateBillingSubscriptionDocument, options);
      }
export type UpdateBillingSubscriptionMutationHookResult = ReturnType<typeof useUpdateBillingSubscriptionMutation>;
export type UpdateBillingSubscriptionMutationResult = Apollo.MutationResult<UpdateBillingSubscriptionMutation>;
export type UpdateBillingSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateBillingSubscriptionMutation, UpdateBillingSubscriptionMutationVariables>;
export const CancelBillingSubscriptionDocument = gql`
    mutation CancelBillingSubscription($organizationId: String!, $comment: String!) {
  cancelBillingSubscription(organizationId: $organizationId, comment: $comment)
}
    `;
export type CancelBillingSubscriptionMutationFn = Apollo.MutationFunction<CancelBillingSubscriptionMutation, CancelBillingSubscriptionMutationVariables>;

/**
 * __useCancelBillingSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelBillingSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBillingSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBillingSubscriptionMutation, { data, loading, error }] = useCancelBillingSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCancelBillingSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelBillingSubscriptionMutation, CancelBillingSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelBillingSubscriptionMutation, CancelBillingSubscriptionMutationVariables>(CancelBillingSubscriptionDocument, options);
      }
export type CancelBillingSubscriptionMutationHookResult = ReturnType<typeof useCancelBillingSubscriptionMutation>;
export type CancelBillingSubscriptionMutationResult = Apollo.MutationResult<CancelBillingSubscriptionMutation>;
export type CancelBillingSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelBillingSubscriptionMutation, CancelBillingSubscriptionMutationVariables>;
export const ConfirmBillingSubscriptionDocument = gql`
    mutation ConfirmBillingSubscription($organizationId: String!, $customerId: String!, $stripePaymentMethodId: String!) {
  confirmBillingSubscription(
    organizationId: $organizationId
    customerId: $customerId
    stripePaymentMethodId: $stripePaymentMethodId
  )
}
    `;
export type ConfirmBillingSubscriptionMutationFn = Apollo.MutationFunction<ConfirmBillingSubscriptionMutation, ConfirmBillingSubscriptionMutationVariables>;

/**
 * __useConfirmBillingSubscriptionMutation__
 *
 * To run a mutation, you first call `useConfirmBillingSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBillingSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBillingSubscriptionMutation, { data, loading, error }] = useConfirmBillingSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      customerId: // value for 'customerId'
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useConfirmBillingSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmBillingSubscriptionMutation, ConfirmBillingSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmBillingSubscriptionMutation, ConfirmBillingSubscriptionMutationVariables>(ConfirmBillingSubscriptionDocument, options);
      }
export type ConfirmBillingSubscriptionMutationHookResult = ReturnType<typeof useConfirmBillingSubscriptionMutation>;
export type ConfirmBillingSubscriptionMutationResult = Apollo.MutationResult<ConfirmBillingSubscriptionMutation>;
export type ConfirmBillingSubscriptionMutationOptions = Apollo.BaseMutationOptions<ConfirmBillingSubscriptionMutation, ConfirmBillingSubscriptionMutationVariables>;
export const ReadBillingPlansDocument = gql`
    query ReadBillingPlans($organizationId: String!, $productNames: [String!]!) {
  readBillingPlans(organizationId: $organizationId, productNames: $productNames) {
    product {
      ...BillingProductFields
    }
    price {
      ...BillingPriceFields
    }
  }
}
    ${BillingProductFieldsFragmentDoc}
${BillingPriceFieldsFragmentDoc}`;

/**
 * __useReadBillingPlansQuery__
 *
 * To run a query within a React component, call `useReadBillingPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBillingPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBillingPlansQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      productNames: // value for 'productNames'
 *   },
 * });
 */
export function useReadBillingPlansQuery(baseOptions: Apollo.QueryHookOptions<ReadBillingPlansQuery, ReadBillingPlansQueryVariables> & ({ variables: ReadBillingPlansQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBillingPlansQuery, ReadBillingPlansQueryVariables>(ReadBillingPlansDocument, options);
      }
export function useReadBillingPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBillingPlansQuery, ReadBillingPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBillingPlansQuery, ReadBillingPlansQueryVariables>(ReadBillingPlansDocument, options);
        }
export function useReadBillingPlansSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadBillingPlansQuery, ReadBillingPlansQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadBillingPlansQuery, ReadBillingPlansQueryVariables>(ReadBillingPlansDocument, options);
        }
export type ReadBillingPlansQueryHookResult = ReturnType<typeof useReadBillingPlansQuery>;
export type ReadBillingPlansLazyQueryHookResult = ReturnType<typeof useReadBillingPlansLazyQuery>;
export type ReadBillingPlansSuspenseQueryHookResult = ReturnType<typeof useReadBillingPlansSuspenseQuery>;
export type ReadBillingPlansQueryResult = Apollo.QueryResult<ReadBillingPlansQuery, ReadBillingPlansQueryVariables>;
export const ReadBillingSubscriptionHistoryDocument = gql`
    query ReadBillingSubscriptionHistory($organizationId: String!) {
  readBillingSubscriptionHistory(organizationId: $organizationId) {
    createdAt
    paymentIntentStatus
    amount {
      amount
      currencyMultiplier
      iso4217CurrencyCode
    }
    interval
    productName
    productDisplayName
  }
}
    `;

/**
 * __useReadBillingSubscriptionHistoryQuery__
 *
 * To run a query within a React component, call `useReadBillingSubscriptionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBillingSubscriptionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBillingSubscriptionHistoryQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useReadBillingSubscriptionHistoryQuery(baseOptions: Apollo.QueryHookOptions<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables> & ({ variables: ReadBillingSubscriptionHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>(ReadBillingSubscriptionHistoryDocument, options);
      }
export function useReadBillingSubscriptionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>(ReadBillingSubscriptionHistoryDocument, options);
        }
export function useReadBillingSubscriptionHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>(ReadBillingSubscriptionHistoryDocument, options);
        }
export type ReadBillingSubscriptionHistoryQueryHookResult = ReturnType<typeof useReadBillingSubscriptionHistoryQuery>;
export type ReadBillingSubscriptionHistoryLazyQueryHookResult = ReturnType<typeof useReadBillingSubscriptionHistoryLazyQuery>;
export type ReadBillingSubscriptionHistorySuspenseQueryHookResult = ReturnType<typeof useReadBillingSubscriptionHistorySuspenseQuery>;
export type ReadBillingSubscriptionHistoryQueryResult = Apollo.QueryResult<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>;
export const PreviewBillingSubscriptionDocument = gql`
    query PreviewBillingSubscription($organizationId: String!, $priceId: String!) {
  previewBillingSubscription(organizationId: $organizationId, priceId: $priceId) {
    ...MonetaryValueFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}`;

/**
 * __usePreviewBillingSubscriptionQuery__
 *
 * To run a query within a React component, call `usePreviewBillingSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewBillingSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewBillingSubscriptionQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      priceId: // value for 'priceId'
 *   },
 * });
 */
export function usePreviewBillingSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<PreviewBillingSubscriptionQuery, PreviewBillingSubscriptionQueryVariables> & ({ variables: PreviewBillingSubscriptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewBillingSubscriptionQuery, PreviewBillingSubscriptionQueryVariables>(PreviewBillingSubscriptionDocument, options);
      }
export function usePreviewBillingSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewBillingSubscriptionQuery, PreviewBillingSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewBillingSubscriptionQuery, PreviewBillingSubscriptionQueryVariables>(PreviewBillingSubscriptionDocument, options);
        }
export function usePreviewBillingSubscriptionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PreviewBillingSubscriptionQuery, PreviewBillingSubscriptionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreviewBillingSubscriptionQuery, PreviewBillingSubscriptionQueryVariables>(PreviewBillingSubscriptionDocument, options);
        }
export type PreviewBillingSubscriptionQueryHookResult = ReturnType<typeof usePreviewBillingSubscriptionQuery>;
export type PreviewBillingSubscriptionLazyQueryHookResult = ReturnType<typeof usePreviewBillingSubscriptionLazyQuery>;
export type PreviewBillingSubscriptionSuspenseQueryHookResult = ReturnType<typeof usePreviewBillingSubscriptionSuspenseQuery>;
export type PreviewBillingSubscriptionQueryResult = Apollo.QueryResult<PreviewBillingSubscriptionQuery, PreviewBillingSubscriptionQueryVariables>;
export const ReadBillingCustomerProfileDocument = gql`
    query ReadBillingCustomerProfile($organizationId: String!) {
  readBillingCustomerProfile(organizationId: $organizationId) {
    cardDetails {
      name
      last4
      brand
    }
  }
}
    `;

/**
 * __useReadBillingCustomerProfileQuery__
 *
 * To run a query within a React component, call `useReadBillingCustomerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBillingCustomerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBillingCustomerProfileQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useReadBillingCustomerProfileQuery(baseOptions: Apollo.QueryHookOptions<ReadBillingCustomerProfileQuery, ReadBillingCustomerProfileQueryVariables> & ({ variables: ReadBillingCustomerProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBillingCustomerProfileQuery, ReadBillingCustomerProfileQueryVariables>(ReadBillingCustomerProfileDocument, options);
      }
export function useReadBillingCustomerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBillingCustomerProfileQuery, ReadBillingCustomerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBillingCustomerProfileQuery, ReadBillingCustomerProfileQueryVariables>(ReadBillingCustomerProfileDocument, options);
        }
export function useReadBillingCustomerProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadBillingCustomerProfileQuery, ReadBillingCustomerProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadBillingCustomerProfileQuery, ReadBillingCustomerProfileQueryVariables>(ReadBillingCustomerProfileDocument, options);
        }
export type ReadBillingCustomerProfileQueryHookResult = ReturnType<typeof useReadBillingCustomerProfileQuery>;
export type ReadBillingCustomerProfileLazyQueryHookResult = ReturnType<typeof useReadBillingCustomerProfileLazyQuery>;
export type ReadBillingCustomerProfileSuspenseQueryHookResult = ReturnType<typeof useReadBillingCustomerProfileSuspenseQuery>;
export type ReadBillingCustomerProfileQueryResult = Apollo.QueryResult<ReadBillingCustomerProfileQuery, ReadBillingCustomerProfileQueryVariables>;
export const CheckBillingOrganizationPlanDocument = gql`
    query CheckBillingOrganizationPlan($organizationId: String!) {
  checkBillingOrganizationPlan(organizationId: $organizationId) {
    exists
    billingPlan {
      ...BillingPlanFields
    }
    trial {
      ...BillingTrialFields
    }
    subscriptionDetails {
      ...SubscriptionDetailsFields
    }
  }
}
    ${BillingPlanFieldsFragmentDoc}
${BillingTrialFieldsFragmentDoc}
${SubscriptionDetailsFieldsFragmentDoc}`;

/**
 * __useCheckBillingOrganizationPlanQuery__
 *
 * To run a query within a React component, call `useCheckBillingOrganizationPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBillingOrganizationPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBillingOrganizationPlanQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCheckBillingOrganizationPlanQuery(baseOptions: Apollo.QueryHookOptions<CheckBillingOrganizationPlanQuery, CheckBillingOrganizationPlanQueryVariables> & ({ variables: CheckBillingOrganizationPlanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckBillingOrganizationPlanQuery, CheckBillingOrganizationPlanQueryVariables>(CheckBillingOrganizationPlanDocument, options);
      }
export function useCheckBillingOrganizationPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckBillingOrganizationPlanQuery, CheckBillingOrganizationPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckBillingOrganizationPlanQuery, CheckBillingOrganizationPlanQueryVariables>(CheckBillingOrganizationPlanDocument, options);
        }
export function useCheckBillingOrganizationPlanSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckBillingOrganizationPlanQuery, CheckBillingOrganizationPlanQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckBillingOrganizationPlanQuery, CheckBillingOrganizationPlanQueryVariables>(CheckBillingOrganizationPlanDocument, options);
        }
export type CheckBillingOrganizationPlanQueryHookResult = ReturnType<typeof useCheckBillingOrganizationPlanQuery>;
export type CheckBillingOrganizationPlanLazyQueryHookResult = ReturnType<typeof useCheckBillingOrganizationPlanLazyQuery>;
export type CheckBillingOrganizationPlanSuspenseQueryHookResult = ReturnType<typeof useCheckBillingOrganizationPlanSuspenseQuery>;
export type CheckBillingOrganizationPlanQueryResult = Apollo.QueryResult<CheckBillingOrganizationPlanQuery, CheckBillingOrganizationPlanQueryVariables>;
export const ReadOrganizationBillingPlanDocument = gql`
    query ReadOrganizationBillingPlan($organizationId: String!) {
  readOrganizationBillingPlan(organizationId: $organizationId) {
    plan {
      ...BillingPlanFields
    }
    trial {
      ...BillingTrialFields
    }
  }
}
    ${BillingPlanFieldsFragmentDoc}
${BillingTrialFieldsFragmentDoc}`;

/**
 * __useReadOrganizationBillingPlanQuery__
 *
 * To run a query within a React component, call `useReadOrganizationBillingPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrganizationBillingPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrganizationBillingPlanQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useReadOrganizationBillingPlanQuery(baseOptions: Apollo.QueryHookOptions<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables> & ({ variables: ReadOrganizationBillingPlanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>(ReadOrganizationBillingPlanDocument, options);
      }
export function useReadOrganizationBillingPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>(ReadOrganizationBillingPlanDocument, options);
        }
export function useReadOrganizationBillingPlanSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>(ReadOrganizationBillingPlanDocument, options);
        }
export type ReadOrganizationBillingPlanQueryHookResult = ReturnType<typeof useReadOrganizationBillingPlanQuery>;
export type ReadOrganizationBillingPlanLazyQueryHookResult = ReturnType<typeof useReadOrganizationBillingPlanLazyQuery>;
export type ReadOrganizationBillingPlanSuspenseQueryHookResult = ReturnType<typeof useReadOrganizationBillingPlanSuspenseQuery>;
export type ReadOrganizationBillingPlanQueryResult = Apollo.QueryResult<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>;
export const AddDocumentFeatureDocument = gql`
    mutation AddDocumentFeature($documentId: String!, $documentCollectionId: String!, $feature: NewDocumentFeature!, $correctedFeatureId: String) {
  addDocumentFeature(
    documentId: $documentId
    documentCollectionId: $documentCollectionId
    feature: $feature
    correctedFeatureId: $correctedFeatureId
  ) {
    featureId
    invalid
  }
}
    `;
export type AddDocumentFeatureMutationFn = Apollo.MutationFunction<AddDocumentFeatureMutation, AddDocumentFeatureMutationVariables>;

/**
 * __useAddDocumentFeatureMutation__
 *
 * To run a mutation, you first call `useAddDocumentFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentFeatureMutation, { data, loading, error }] = useAddDocumentFeatureMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      documentCollectionId: // value for 'documentCollectionId'
 *      feature: // value for 'feature'
 *      correctedFeatureId: // value for 'correctedFeatureId'
 *   },
 * });
 */
export function useAddDocumentFeatureMutation(baseOptions?: Apollo.MutationHookOptions<AddDocumentFeatureMutation, AddDocumentFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDocumentFeatureMutation, AddDocumentFeatureMutationVariables>(AddDocumentFeatureDocument, options);
      }
export type AddDocumentFeatureMutationHookResult = ReturnType<typeof useAddDocumentFeatureMutation>;
export type AddDocumentFeatureMutationResult = Apollo.MutationResult<AddDocumentFeatureMutation>;
export type AddDocumentFeatureMutationOptions = Apollo.BaseMutationOptions<AddDocumentFeatureMutation, AddDocumentFeatureMutationVariables>;
export const DocumentDownloadUrlDocument = gql`
    query DocumentDownloadUrl($id: String!, $legalEntityId: String!) {
  documentDownloadUrl(id: $id, legalEntityId: $legalEntityId) {
    url
  }
}
    `;

/**
 * __useDocumentDownloadUrlQuery__
 *
 * To run a query within a React component, call `useDocumentDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDownloadUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useDocumentDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<DocumentDownloadUrlQuery, DocumentDownloadUrlQueryVariables> & ({ variables: DocumentDownloadUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDownloadUrlQuery, DocumentDownloadUrlQueryVariables>(DocumentDownloadUrlDocument, options);
      }
export function useDocumentDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDownloadUrlQuery, DocumentDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDownloadUrlQuery, DocumentDownloadUrlQueryVariables>(DocumentDownloadUrlDocument, options);
        }
export function useDocumentDownloadUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocumentDownloadUrlQuery, DocumentDownloadUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentDownloadUrlQuery, DocumentDownloadUrlQueryVariables>(DocumentDownloadUrlDocument, options);
        }
export type DocumentDownloadUrlQueryHookResult = ReturnType<typeof useDocumentDownloadUrlQuery>;
export type DocumentDownloadUrlLazyQueryHookResult = ReturnType<typeof useDocumentDownloadUrlLazyQuery>;
export type DocumentDownloadUrlSuspenseQueryHookResult = ReturnType<typeof useDocumentDownloadUrlSuspenseQuery>;
export type DocumentDownloadUrlQueryResult = Apollo.QueryResult<DocumentDownloadUrlQuery, DocumentDownloadUrlQueryVariables>;
export const ListVaultFilesDocument = gql`
    query ListVaultFiles($legalEntityId: String!, $pagination: Pagination, $filter: DocumentFilter!, $order: DocumentSort) {
  listDocuments(
    legalEntityId: $legalEntityId
    filter: $filter
    order: $order
    pagination: $pagination
  ) {
    ...VaultFileFields
  }
  summarizeDocuments(legalEntityId: $legalEntityId, filter: $filter) {
    count
  }
}
    ${VaultFileFieldsFragmentDoc}`;

/**
 * __useListVaultFilesQuery__
 *
 * To run a query within a React component, call `useListVaultFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVaultFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVaultFilesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListVaultFilesQuery(baseOptions: Apollo.QueryHookOptions<ListVaultFilesQuery, ListVaultFilesQueryVariables> & ({ variables: ListVaultFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListVaultFilesQuery, ListVaultFilesQueryVariables>(ListVaultFilesDocument, options);
      }
export function useListVaultFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListVaultFilesQuery, ListVaultFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListVaultFilesQuery, ListVaultFilesQueryVariables>(ListVaultFilesDocument, options);
        }
export function useListVaultFilesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListVaultFilesQuery, ListVaultFilesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListVaultFilesQuery, ListVaultFilesQueryVariables>(ListVaultFilesDocument, options);
        }
export type ListVaultFilesQueryHookResult = ReturnType<typeof useListVaultFilesQuery>;
export type ListVaultFilesLazyQueryHookResult = ReturnType<typeof useListVaultFilesLazyQuery>;
export type ListVaultFilesSuspenseQueryHookResult = ReturnType<typeof useListVaultFilesSuspenseQuery>;
export type ListVaultFilesQueryResult = Apollo.QueryResult<ListVaultFilesQuery, ListVaultFilesQueryVariables>;
export const ReadVaultFileDocument = gql`
    query ReadVaultFile($id: String!, $legalEntityId: String!) {
  readVaultFile: readDocument(id: $id, legalEntityId: $legalEntityId) {
    ...VaultFileFields
  }
}
    ${VaultFileFieldsFragmentDoc}`;

/**
 * __useReadVaultFileQuery__
 *
 * To run a query within a React component, call `useReadVaultFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadVaultFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadVaultFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadVaultFileQuery(baseOptions: Apollo.QueryHookOptions<ReadVaultFileQuery, ReadVaultFileQueryVariables> & ({ variables: ReadVaultFileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadVaultFileQuery, ReadVaultFileQueryVariables>(ReadVaultFileDocument, options);
      }
export function useReadVaultFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadVaultFileQuery, ReadVaultFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadVaultFileQuery, ReadVaultFileQueryVariables>(ReadVaultFileDocument, options);
        }
export function useReadVaultFileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadVaultFileQuery, ReadVaultFileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadVaultFileQuery, ReadVaultFileQueryVariables>(ReadVaultFileDocument, options);
        }
export type ReadVaultFileQueryHookResult = ReturnType<typeof useReadVaultFileQuery>;
export type ReadVaultFileLazyQueryHookResult = ReturnType<typeof useReadVaultFileLazyQuery>;
export type ReadVaultFileSuspenseQueryHookResult = ReturnType<typeof useReadVaultFileSuspenseQuery>;
export type ReadVaultFileQueryResult = Apollo.QueryResult<ReadVaultFileQuery, ReadVaultFileQueryVariables>;
export const UpdateExecutiveSummaryDocument = gql`
    mutation UpdateExecutiveSummary($legalEntityId: String!, $documentId: String!, $title: String, $summary: String) {
  updateExecutiveSummary(
    legalEntityId: $legalEntityId
    documentId: $documentId
    title: $title
    summary: $summary
  )
}
    `;
export type UpdateExecutiveSummaryMutationFn = Apollo.MutationFunction<UpdateExecutiveSummaryMutation, UpdateExecutiveSummaryMutationVariables>;

/**
 * __useUpdateExecutiveSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateExecutiveSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExecutiveSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExecutiveSummaryMutation, { data, loading, error }] = useUpdateExecutiveSummaryMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      documentId: // value for 'documentId'
 *      title: // value for 'title'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateExecutiveSummaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExecutiveSummaryMutation, UpdateExecutiveSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExecutiveSummaryMutation, UpdateExecutiveSummaryMutationVariables>(UpdateExecutiveSummaryDocument, options);
      }
export type UpdateExecutiveSummaryMutationHookResult = ReturnType<typeof useUpdateExecutiveSummaryMutation>;
export type UpdateExecutiveSummaryMutationResult = Apollo.MutationResult<UpdateExecutiveSummaryMutation>;
export type UpdateExecutiveSummaryMutationOptions = Apollo.BaseMutationOptions<UpdateExecutiveSummaryMutation, UpdateExecutiveSummaryMutationVariables>;
export const UpdateReportAutomationSettingsDocument = gql`
    mutation UpdateReportAutomationSettings($organizationId: String!, $legalEntityId: String, $interval: Interval!, $autoCreateDraft: Boolean!, $autoPublishDraft: Boolean!, $autoPublishDraftDay: Int!, $autoPublishFinal: Boolean!, $autoPublishFinalDay: Int!) {
  updateReportAutomationSettings(
    organizationId: $organizationId
    legalEntityId: $legalEntityId
    interval: $interval
    autoCreateDraft: $autoCreateDraft
    autoPublishDraft: $autoPublishDraft
    autoPublishDraftDay: $autoPublishDraftDay
    autoPublishFinal: $autoPublishFinal
    autoPublishFinalDay: $autoPublishFinalDay
  )
}
    `;
export type UpdateReportAutomationSettingsMutationFn = Apollo.MutationFunction<UpdateReportAutomationSettingsMutation, UpdateReportAutomationSettingsMutationVariables>;

/**
 * __useUpdateReportAutomationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateReportAutomationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportAutomationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportAutomationSettingsMutation, { data, loading, error }] = useUpdateReportAutomationSettingsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      legalEntityId: // value for 'legalEntityId'
 *      interval: // value for 'interval'
 *      autoCreateDraft: // value for 'autoCreateDraft'
 *      autoPublishDraft: // value for 'autoPublishDraft'
 *      autoPublishDraftDay: // value for 'autoPublishDraftDay'
 *      autoPublishFinal: // value for 'autoPublishFinal'
 *      autoPublishFinalDay: // value for 'autoPublishFinalDay'
 *   },
 * });
 */
export function useUpdateReportAutomationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportAutomationSettingsMutation, UpdateReportAutomationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportAutomationSettingsMutation, UpdateReportAutomationSettingsMutationVariables>(UpdateReportAutomationSettingsDocument, options);
      }
export type UpdateReportAutomationSettingsMutationHookResult = ReturnType<typeof useUpdateReportAutomationSettingsMutation>;
export type UpdateReportAutomationSettingsMutationResult = Apollo.MutationResult<UpdateReportAutomationSettingsMutation>;
export type UpdateReportAutomationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateReportAutomationSettingsMutation, UpdateReportAutomationSettingsMutationVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($reportId: String!, $legalEntityId: String!) {
  deleteReport(reportId: $reportId, legalEntityId: $legalEntityId)
}
    `;
export type DeleteReportMutationFn = Apollo.MutationFunction<DeleteReportMutation, DeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportMutation, DeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationResult = Apollo.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const PublishReportPackageDocument = gql`
    mutation PublishReportPackage($legalEntityId: String!, $packageId: String!, $packageVersionId: String!, $sendNotification: Boolean, $publishAsDraft: Boolean!) {
  publishReportPackage(
    legalEntityId: $legalEntityId
    packageId: $packageId
    packageVersionId: $packageVersionId
    sendNotification: $sendNotification
    publishAsDraft: $publishAsDraft
  )
}
    `;
export type PublishReportPackageMutationFn = Apollo.MutationFunction<PublishReportPackageMutation, PublishReportPackageMutationVariables>;

/**
 * __usePublishReportPackageMutation__
 *
 * To run a mutation, you first call `usePublishReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishReportPackageMutation, { data, loading, error }] = usePublishReportPackageMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      packageId: // value for 'packageId'
 *      packageVersionId: // value for 'packageVersionId'
 *      sendNotification: // value for 'sendNotification'
 *      publishAsDraft: // value for 'publishAsDraft'
 *   },
 * });
 */
export function usePublishReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<PublishReportPackageMutation, PublishReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishReportPackageMutation, PublishReportPackageMutationVariables>(PublishReportPackageDocument, options);
      }
export type PublishReportPackageMutationHookResult = ReturnType<typeof usePublishReportPackageMutation>;
export type PublishReportPackageMutationResult = Apollo.MutationResult<PublishReportPackageMutation>;
export type PublishReportPackageMutationOptions = Apollo.BaseMutationOptions<PublishReportPackageMutation, PublishReportPackageMutationVariables>;
export const DeleteReportPackageDocument = gql`
    mutation DeleteReportPackage($packageId: String!, $legalEntityId: String!) {
  deleteReportPackage(packageId: $packageId, legalEntityId: $legalEntityId)
}
    `;
export type DeleteReportPackageMutationFn = Apollo.MutationFunction<DeleteReportPackageMutation, DeleteReportPackageMutationVariables>;

/**
 * __useDeleteReportPackageMutation__
 *
 * To run a mutation, you first call `useDeleteReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportPackageMutation, { data, loading, error }] = useDeleteReportPackageMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useDeleteReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportPackageMutation, DeleteReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportPackageMutation, DeleteReportPackageMutationVariables>(DeleteReportPackageDocument, options);
      }
export type DeleteReportPackageMutationHookResult = ReturnType<typeof useDeleteReportPackageMutation>;
export type DeleteReportPackageMutationResult = Apollo.MutationResult<DeleteReportPackageMutation>;
export type DeleteReportPackageMutationOptions = Apollo.BaseMutationOptions<DeleteReportPackageMutation, DeleteReportPackageMutationVariables>;
export const RefreshReportPackageDocument = gql`
    mutation RefreshReportPackage($viewKey: ViewKey!, $packageId: String!, $packageVersionId: String) {
  refreshReportPackage(
    viewKey: $viewKey
    packageId: $packageId
    packageVersionId: $packageVersionId
  ) {
    requestId
  }
}
    `;
export type RefreshReportPackageMutationFn = Apollo.MutationFunction<RefreshReportPackageMutation, RefreshReportPackageMutationVariables>;

/**
 * __useRefreshReportPackageMutation__
 *
 * To run a mutation, you first call `useRefreshReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshReportPackageMutation, { data, loading, error }] = useRefreshReportPackageMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      packageId: // value for 'packageId'
 *      packageVersionId: // value for 'packageVersionId'
 *   },
 * });
 */
export function useRefreshReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<RefreshReportPackageMutation, RefreshReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshReportPackageMutation, RefreshReportPackageMutationVariables>(RefreshReportPackageDocument, options);
      }
export type RefreshReportPackageMutationHookResult = ReturnType<typeof useRefreshReportPackageMutation>;
export type RefreshReportPackageMutationResult = Apollo.MutationResult<RefreshReportPackageMutation>;
export type RefreshReportPackageMutationOptions = Apollo.BaseMutationOptions<RefreshReportPackageMutation, RefreshReportPackageMutationVariables>;
export const CopyReportPackageDocument = gql`
    mutation CopyReportPackage($viewKey: ViewKey!, $packageId: String!, $title: String!, $periodStartAt: DateInput!, $periodEndAt: DateInput!, $interval: Interval!, $attachments: NewReportAttachments!) {
  copyReportPackage(
    viewKey: $viewKey
    packageId: $packageId
    title: $title
    periodStartAt: $periodStartAt
    periodEndAt: $periodEndAt
    interval: $interval
    attachments: $attachments
  ) {
    ...ReportPackageMetadataFields
  }
}
    ${ReportPackageMetadataFieldsFragmentDoc}`;
export type CopyReportPackageMutationFn = Apollo.MutationFunction<CopyReportPackageMutation, CopyReportPackageMutationVariables>;

/**
 * __useCopyReportPackageMutation__
 *
 * To run a mutation, you first call `useCopyReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyReportPackageMutation, { data, loading, error }] = useCopyReportPackageMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      packageId: // value for 'packageId'
 *      title: // value for 'title'
 *      periodStartAt: // value for 'periodStartAt'
 *      periodEndAt: // value for 'periodEndAt'
 *      interval: // value for 'interval'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCopyReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<CopyReportPackageMutation, CopyReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyReportPackageMutation, CopyReportPackageMutationVariables>(CopyReportPackageDocument, options);
      }
export type CopyReportPackageMutationHookResult = ReturnType<typeof useCopyReportPackageMutation>;
export type CopyReportPackageMutationResult = Apollo.MutationResult<CopyReportPackageMutation>;
export type CopyReportPackageMutationOptions = Apollo.BaseMutationOptions<CopyReportPackageMutation, CopyReportPackageMutationVariables>;
export const EditReportPackageDocument = gql`
    mutation EditReportPackage($viewKey: ViewKey!, $packageId: String!, $packageVersionId: String!) {
  editReportPackage(
    viewKey: $viewKey
    packageId: $packageId
    packageVersionId: $packageVersionId
  ) {
    ...ReportPackageMetadataFields
  }
}
    ${ReportPackageMetadataFieldsFragmentDoc}`;
export type EditReportPackageMutationFn = Apollo.MutationFunction<EditReportPackageMutation, EditReportPackageMutationVariables>;

/**
 * __useEditReportPackageMutation__
 *
 * To run a mutation, you first call `useEditReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReportPackageMutation, { data, loading, error }] = useEditReportPackageMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      packageId: // value for 'packageId'
 *      packageVersionId: // value for 'packageVersionId'
 *   },
 * });
 */
export function useEditReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<EditReportPackageMutation, EditReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReportPackageMutation, EditReportPackageMutationVariables>(EditReportPackageDocument, options);
      }
export type EditReportPackageMutationHookResult = ReturnType<typeof useEditReportPackageMutation>;
export type EditReportPackageMutationResult = Apollo.MutationResult<EditReportPackageMutation>;
export type EditReportPackageMutationOptions = Apollo.BaseMutationOptions<EditReportPackageMutation, EditReportPackageMutationVariables>;
export const AttachReportPackageDocumentDocument = gql`
    mutation AttachReportPackageDocument($viewKey: ViewKey!, $origin: IntervalOrigin!, $packageId: String!, $attachments: NewReportAttachments!) {
  attachReportPackageDocument(
    viewKey: $viewKey
    origin: $origin
    packageId: $packageId
    attachments: $attachments
  )
}
    `;
export type AttachReportPackageDocumentMutationFn = Apollo.MutationFunction<AttachReportPackageDocumentMutation, AttachReportPackageDocumentMutationVariables>;

/**
 * __useAttachReportPackageDocumentMutation__
 *
 * To run a mutation, you first call `useAttachReportPackageDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachReportPackageDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachReportPackageDocumentMutation, { data, loading, error }] = useAttachReportPackageDocumentMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      origin: // value for 'origin'
 *      packageId: // value for 'packageId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAttachReportPackageDocumentMutation(baseOptions?: Apollo.MutationHookOptions<AttachReportPackageDocumentMutation, AttachReportPackageDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachReportPackageDocumentMutation, AttachReportPackageDocumentMutationVariables>(AttachReportPackageDocumentDocument, options);
      }
export type AttachReportPackageDocumentMutationHookResult = ReturnType<typeof useAttachReportPackageDocumentMutation>;
export type AttachReportPackageDocumentMutationResult = Apollo.MutationResult<AttachReportPackageDocumentMutation>;
export type AttachReportPackageDocumentMutationOptions = Apollo.BaseMutationOptions<AttachReportPackageDocumentMutation, AttachReportPackageDocumentMutationVariables>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($legalEntityId: String!, $reportId: String!, $title: String, $interval: Interval, $reportFor: DateInput) {
  updateReport(
    legalEntityId: $legalEntityId
    reportId: $reportId
    title: $title
    interval: $interval
    reportFor: $reportFor
  )
}
    `;
export type UpdateReportMutationFn = Apollo.MutationFunction<UpdateReportMutation, UpdateReportMutationVariables>;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reportId: // value for 'reportId'
 *      title: // value for 'title'
 *      interval: // value for 'interval'
 *      reportFor: // value for 'reportFor'
 *   },
 * });
 */
export function useUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportMutation, UpdateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument, options);
      }
export type UpdateReportMutationHookResult = ReturnType<typeof useUpdateReportMutation>;
export type UpdateReportMutationResult = Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>;
export const CreateReportPackageDocument = gql`
    mutation CreateReportPackage($viewKey: ViewKey!, $title: String!, $periodStartAt: DateInput!, $periodEndAt: DateInput!, $interval: Interval!, $attachments: NewReportAttachments!, $coverUrl: String) {
  createReportPackage(
    viewKey: $viewKey
    title: $title
    periodStartAt: $periodStartAt
    periodEndAt: $periodEndAt
    interval: $interval
    attachments: $attachments
    coverUrl: $coverUrl
  ) {
    ...ReportPackageMetadataFields
  }
}
    ${ReportPackageMetadataFieldsFragmentDoc}`;
export type CreateReportPackageMutationFn = Apollo.MutationFunction<CreateReportPackageMutation, CreateReportPackageMutationVariables>;

/**
 * __useCreateReportPackageMutation__
 *
 * To run a mutation, you first call `useCreateReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportPackageMutation, { data, loading, error }] = useCreateReportPackageMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      title: // value for 'title'
 *      periodStartAt: // value for 'periodStartAt'
 *      periodEndAt: // value for 'periodEndAt'
 *      interval: // value for 'interval'
 *      attachments: // value for 'attachments'
 *      coverUrl: // value for 'coverUrl'
 *   },
 * });
 */
export function useCreateReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportPackageMutation, CreateReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportPackageMutation, CreateReportPackageMutationVariables>(CreateReportPackageDocument, options);
      }
export type CreateReportPackageMutationHookResult = ReturnType<typeof useCreateReportPackageMutation>;
export type CreateReportPackageMutationResult = Apollo.MutationResult<CreateReportPackageMutation>;
export type CreateReportPackageMutationOptions = Apollo.BaseMutationOptions<CreateReportPackageMutation, CreateReportPackageMutationVariables>;
export const UpdateReportPackageDocument = gql`
    mutation UpdateReportPackage($legalEntityId: String!, $packageId: String!, $packageVersionId: String!, $title: String, $customFooter: String, $preparedBy: String, $accountNumbersDisplay: AccountNumbersDisplay, $coverUrl: String, $coverLogoSource: CoverLogoSource, $publishedAsDraft: Boolean) {
  updateReportPackage(
    legalEntityId: $legalEntityId
    packageId: $packageId
    packageVersionId: $packageVersionId
    title: $title
    customFooter: $customFooter
    preparedBy: $preparedBy
    accountNumbersDisplay: $accountNumbersDisplay
    coverUrl: $coverUrl
    coverLogoSource: $coverLogoSource
    publishedAsDraft: $publishedAsDraft
  )
}
    `;
export type UpdateReportPackageMutationFn = Apollo.MutationFunction<UpdateReportPackageMutation, UpdateReportPackageMutationVariables>;

/**
 * __useUpdateReportPackageMutation__
 *
 * To run a mutation, you first call `useUpdateReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportPackageMutation, { data, loading, error }] = useUpdateReportPackageMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      packageId: // value for 'packageId'
 *      packageVersionId: // value for 'packageVersionId'
 *      title: // value for 'title'
 *      customFooter: // value for 'customFooter'
 *      preparedBy: // value for 'preparedBy'
 *      accountNumbersDisplay: // value for 'accountNumbersDisplay'
 *      coverUrl: // value for 'coverUrl'
 *      coverLogoSource: // value for 'coverLogoSource'
 *      publishedAsDraft: // value for 'publishedAsDraft'
 *   },
 * });
 */
export function useUpdateReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportPackageMutation, UpdateReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportPackageMutation, UpdateReportPackageMutationVariables>(UpdateReportPackageDocument, options);
      }
export type UpdateReportPackageMutationHookResult = ReturnType<typeof useUpdateReportPackageMutation>;
export type UpdateReportPackageMutationResult = Apollo.MutationResult<UpdateReportPackageMutation>;
export type UpdateReportPackageMutationOptions = Apollo.BaseMutationOptions<UpdateReportPackageMutation, UpdateReportPackageMutationVariables>;
export const UpdateReportOptionsDocument = gql`
    mutation UpdateReportOptions($legalEntityId: String!, $reportPackageId: String!, $packageVersionId: String!, $reportId: String!, $reportVersionId: String!, $sparklineLookbackPeriods: Int, $deltaMonthOverMonth: ReportOptionComparison, $deltaYearOverYear: ReportOptionComparison, $comparedToTotal: ReportOptionComparison, $comparedToIncome: ReportOptionComparison, $collapsedSections: CollapsedSectionsInput) {
  updateReportOptions(
    legalEntityId: $legalEntityId
    reportPackageId: $reportPackageId
    packageVersionId: $packageVersionId
    reportId: $reportId
    reportVersionId: $reportVersionId
    sparklineLookbackPeriods: $sparklineLookbackPeriods
    deltaMonthOverMonth: $deltaMonthOverMonth
    deltaYearOverYear: $deltaYearOverYear
    comparedToTotal: $comparedToTotal
    comparedToIncome: $comparedToIncome
    collapsedSections: $collapsedSections
  )
}
    `;
export type UpdateReportOptionsMutationFn = Apollo.MutationFunction<UpdateReportOptionsMutation, UpdateReportOptionsMutationVariables>;

/**
 * __useUpdateReportOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateReportOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportOptionsMutation, { data, loading, error }] = useUpdateReportOptionsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reportPackageId: // value for 'reportPackageId'
 *      packageVersionId: // value for 'packageVersionId'
 *      reportId: // value for 'reportId'
 *      reportVersionId: // value for 'reportVersionId'
 *      sparklineLookbackPeriods: // value for 'sparklineLookbackPeriods'
 *      deltaMonthOverMonth: // value for 'deltaMonthOverMonth'
 *      deltaYearOverYear: // value for 'deltaYearOverYear'
 *      comparedToTotal: // value for 'comparedToTotal'
 *      comparedToIncome: // value for 'comparedToIncome'
 *      collapsedSections: // value for 'collapsedSections'
 *   },
 * });
 */
export function useUpdateReportOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportOptionsMutation, UpdateReportOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportOptionsMutation, UpdateReportOptionsMutationVariables>(UpdateReportOptionsDocument, options);
      }
export type UpdateReportOptionsMutationHookResult = ReturnType<typeof useUpdateReportOptionsMutation>;
export type UpdateReportOptionsMutationResult = Apollo.MutationResult<UpdateReportOptionsMutation>;
export type UpdateReportOptionsMutationOptions = Apollo.BaseMutationOptions<UpdateReportOptionsMutation, UpdateReportOptionsMutationVariables>;
export const CreateReportFromUploadDocument = gql`
    mutation CreateReportFromUpload($legalEntityId: String!, $reports: [NewReportUpload!]!) {
  createReportFromUpload(legalEntityId: $legalEntityId, reports: $reports) {
    ...ReportFields
  }
}
    ${ReportFieldsFragmentDoc}`;
export type CreateReportFromUploadMutationFn = Apollo.MutationFunction<CreateReportFromUploadMutation, CreateReportFromUploadMutationVariables>;

/**
 * __useCreateReportFromUploadMutation__
 *
 * To run a mutation, you first call `useCreateReportFromUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportFromUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportFromUploadMutation, { data, loading, error }] = useCreateReportFromUploadMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reports: // value for 'reports'
 *   },
 * });
 */
export function useCreateReportFromUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportFromUploadMutation, CreateReportFromUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportFromUploadMutation, CreateReportFromUploadMutationVariables>(CreateReportFromUploadDocument, options);
      }
export type CreateReportFromUploadMutationHookResult = ReturnType<typeof useCreateReportFromUploadMutation>;
export type CreateReportFromUploadMutationResult = Apollo.MutationResult<CreateReportFromUploadMutation>;
export type CreateReportFromUploadMutationOptions = Apollo.BaseMutationOptions<CreateReportFromUploadMutation, CreateReportFromUploadMutationVariables>;
export const ListReportTemplatesDocument = gql`
    query ListReportTemplates($legalEntityId: String!, $interval: Interval!, $limit: Int!) {
  listReportTemplates(
    legalEntityId: $legalEntityId
    interval: $interval
    limit: $limit
  ) {
    id
    name
    hasExecutiveSummary
    hasProfitAndLoss
    hasBalanceSheet
    hasCashFlow
  }
}
    `;

/**
 * __useListReportTemplatesQuery__
 *
 * To run a query within a React component, call `useListReportTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportTemplatesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      interval: // value for 'interval'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListReportTemplatesQuery(baseOptions: Apollo.QueryHookOptions<ListReportTemplatesQuery, ListReportTemplatesQueryVariables> & ({ variables: ListReportTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReportTemplatesQuery, ListReportTemplatesQueryVariables>(ListReportTemplatesDocument, options);
      }
export function useListReportTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportTemplatesQuery, ListReportTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReportTemplatesQuery, ListReportTemplatesQueryVariables>(ListReportTemplatesDocument, options);
        }
export function useListReportTemplatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListReportTemplatesQuery, ListReportTemplatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListReportTemplatesQuery, ListReportTemplatesQueryVariables>(ListReportTemplatesDocument, options);
        }
export type ListReportTemplatesQueryHookResult = ReturnType<typeof useListReportTemplatesQuery>;
export type ListReportTemplatesLazyQueryHookResult = ReturnType<typeof useListReportTemplatesLazyQuery>;
export type ListReportTemplatesSuspenseQueryHookResult = ReturnType<typeof useListReportTemplatesSuspenseQuery>;
export type ListReportTemplatesQueryResult = Apollo.QueryResult<ListReportTemplatesQuery, ListReportTemplatesQueryVariables>;
export const RefreshReportPackageStatusDocument = gql`
    query RefreshReportPackageStatus($legalEntityId: String!, $packageId: String!, $requestId: String!) {
  refreshReportPackageStatus(
    legalEntityId: $legalEntityId
    packageId: $packageId
    requestId: $requestId
  ) {
    reportId
    versionId
    status
  }
}
    `;

/**
 * __useRefreshReportPackageStatusQuery__
 *
 * To run a query within a React component, call `useRefreshReportPackageStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshReportPackageStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshReportPackageStatusQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      packageId: // value for 'packageId'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useRefreshReportPackageStatusQuery(baseOptions: Apollo.QueryHookOptions<RefreshReportPackageStatusQuery, RefreshReportPackageStatusQueryVariables> & ({ variables: RefreshReportPackageStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshReportPackageStatusQuery, RefreshReportPackageStatusQueryVariables>(RefreshReportPackageStatusDocument, options);
      }
export function useRefreshReportPackageStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshReportPackageStatusQuery, RefreshReportPackageStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshReportPackageStatusQuery, RefreshReportPackageStatusQueryVariables>(RefreshReportPackageStatusDocument, options);
        }
export function useRefreshReportPackageStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RefreshReportPackageStatusQuery, RefreshReportPackageStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RefreshReportPackageStatusQuery, RefreshReportPackageStatusQueryVariables>(RefreshReportPackageStatusDocument, options);
        }
export type RefreshReportPackageStatusQueryHookResult = ReturnType<typeof useRefreshReportPackageStatusQuery>;
export type RefreshReportPackageStatusLazyQueryHookResult = ReturnType<typeof useRefreshReportPackageStatusLazyQuery>;
export type RefreshReportPackageStatusSuspenseQueryHookResult = ReturnType<typeof useRefreshReportPackageStatusSuspenseQuery>;
export type RefreshReportPackageStatusQueryResult = Apollo.QueryResult<RefreshReportPackageStatusQuery, RefreshReportPackageStatusQueryVariables>;
export const ReadReportPackageDocument = gql`
    query ReadReportPackage($legalEntityId: String!, $packageId: String!, $packageVersionId: String) {
  readReportPackage(
    legalEntityId: $legalEntityId
    packageId: $packageId
    packageVersionId: $packageVersionId
  ) {
    ...ReportPackageFields
  }
}
    ${ReportPackageFieldsFragmentDoc}`;

/**
 * __useReadReportPackageQuery__
 *
 * To run a query within a React component, call `useReadReportPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportPackageQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      packageId: // value for 'packageId'
 *      packageVersionId: // value for 'packageVersionId'
 *   },
 * });
 */
export function useReadReportPackageQuery(baseOptions: Apollo.QueryHookOptions<ReadReportPackageQuery, ReadReportPackageQueryVariables> & ({ variables: ReadReportPackageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReportPackageQuery, ReadReportPackageQueryVariables>(ReadReportPackageDocument, options);
      }
export function useReadReportPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReportPackageQuery, ReadReportPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReportPackageQuery, ReadReportPackageQueryVariables>(ReadReportPackageDocument, options);
        }
export function useReadReportPackageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadReportPackageQuery, ReadReportPackageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadReportPackageQuery, ReadReportPackageQueryVariables>(ReadReportPackageDocument, options);
        }
export type ReadReportPackageQueryHookResult = ReturnType<typeof useReadReportPackageQuery>;
export type ReadReportPackageLazyQueryHookResult = ReturnType<typeof useReadReportPackageLazyQuery>;
export type ReadReportPackageSuspenseQueryHookResult = ReturnType<typeof useReadReportPackageSuspenseQuery>;
export type ReadReportPackageQueryResult = Apollo.QueryResult<ReadReportPackageQuery, ReadReportPackageQueryVariables>;
export const ReadReportPackageCountDocument = gql`
    query ReadReportPackageCount($legalEntityId: String!) {
  readReportPackageCount(legalEntityId: $legalEntityId) {
    counts {
      interval
      total
    }
    total
  }
}
    `;

/**
 * __useReadReportPackageCountQuery__
 *
 * To run a query within a React component, call `useReadReportPackageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportPackageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportPackageCountQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadReportPackageCountQuery(baseOptions: Apollo.QueryHookOptions<ReadReportPackageCountQuery, ReadReportPackageCountQueryVariables> & ({ variables: ReadReportPackageCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReportPackageCountQuery, ReadReportPackageCountQueryVariables>(ReadReportPackageCountDocument, options);
      }
export function useReadReportPackageCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReportPackageCountQuery, ReadReportPackageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReportPackageCountQuery, ReadReportPackageCountQueryVariables>(ReadReportPackageCountDocument, options);
        }
export function useReadReportPackageCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadReportPackageCountQuery, ReadReportPackageCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadReportPackageCountQuery, ReadReportPackageCountQueryVariables>(ReadReportPackageCountDocument, options);
        }
export type ReadReportPackageCountQueryHookResult = ReturnType<typeof useReadReportPackageCountQuery>;
export type ReadReportPackageCountLazyQueryHookResult = ReturnType<typeof useReadReportPackageCountLazyQuery>;
export type ReadReportPackageCountSuspenseQueryHookResult = ReturnType<typeof useReadReportPackageCountSuspenseQuery>;
export type ReadReportPackageCountQueryResult = Apollo.QueryResult<ReadReportPackageCountQuery, ReadReportPackageCountQueryVariables>;
export const GenerateStatementSummaryDocument = gql`
    query generateStatementSummary($input: String!, $reportType: String!) {
  generateStatementSummary(input: $input, reportType: $reportType) {
    ...StatementSummaryFields
  }
}
    ${StatementSummaryFieldsFragmentDoc}`;

/**
 * __useGenerateStatementSummaryQuery__
 *
 * To run a query within a React component, call `useGenerateStatementSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateStatementSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateStatementSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      reportType: // value for 'reportType'
 *   },
 * });
 */
export function useGenerateStatementSummaryQuery(baseOptions: Apollo.QueryHookOptions<GenerateStatementSummaryQuery, GenerateStatementSummaryQueryVariables> & ({ variables: GenerateStatementSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateStatementSummaryQuery, GenerateStatementSummaryQueryVariables>(GenerateStatementSummaryDocument, options);
      }
export function useGenerateStatementSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateStatementSummaryQuery, GenerateStatementSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateStatementSummaryQuery, GenerateStatementSummaryQueryVariables>(GenerateStatementSummaryDocument, options);
        }
export function useGenerateStatementSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenerateStatementSummaryQuery, GenerateStatementSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateStatementSummaryQuery, GenerateStatementSummaryQueryVariables>(GenerateStatementSummaryDocument, options);
        }
export type GenerateStatementSummaryQueryHookResult = ReturnType<typeof useGenerateStatementSummaryQuery>;
export type GenerateStatementSummaryLazyQueryHookResult = ReturnType<typeof useGenerateStatementSummaryLazyQuery>;
export type GenerateStatementSummarySuspenseQueryHookResult = ReturnType<typeof useGenerateStatementSummarySuspenseQuery>;
export type GenerateStatementSummaryQueryResult = Apollo.QueryResult<GenerateStatementSummaryQuery, GenerateStatementSummaryQueryVariables>;
export const SummarizeTrialBalanceDocument = gql`
    query SummarizeTrialBalance($viewKey: ViewKey!, $origin: IntervalOrigin!) {
  summarizeTrialBalance(viewKey: $viewKey, origin: $origin) {
    ...TrialBalanceFields
  }
}
    ${TrialBalanceFieldsFragmentDoc}`;

/**
 * __useSummarizeTrialBalanceQuery__
 *
 * To run a query within a React component, call `useSummarizeTrialBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeTrialBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeTrialBalanceQuery({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useSummarizeTrialBalanceQuery(baseOptions: Apollo.QueryHookOptions<SummarizeTrialBalanceQuery, SummarizeTrialBalanceQueryVariables> & ({ variables: SummarizeTrialBalanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeTrialBalanceQuery, SummarizeTrialBalanceQueryVariables>(SummarizeTrialBalanceDocument, options);
      }
export function useSummarizeTrialBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeTrialBalanceQuery, SummarizeTrialBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeTrialBalanceQuery, SummarizeTrialBalanceQueryVariables>(SummarizeTrialBalanceDocument, options);
        }
export function useSummarizeTrialBalanceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeTrialBalanceQuery, SummarizeTrialBalanceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeTrialBalanceQuery, SummarizeTrialBalanceQueryVariables>(SummarizeTrialBalanceDocument, options);
        }
export type SummarizeTrialBalanceQueryHookResult = ReturnType<typeof useSummarizeTrialBalanceQuery>;
export type SummarizeTrialBalanceLazyQueryHookResult = ReturnType<typeof useSummarizeTrialBalanceLazyQuery>;
export type SummarizeTrialBalanceSuspenseQueryHookResult = ReturnType<typeof useSummarizeTrialBalanceSuspenseQuery>;
export type SummarizeTrialBalanceQueryResult = Apollo.QueryResult<SummarizeTrialBalanceQuery, SummarizeTrialBalanceQueryVariables>;
export const ReadReportAutomationSettingsDocument = gql`
    query ReadReportAutomationSettings($organizationId: String!, $legalEntityId: String, $interval: Interval!) {
  readReportAutomationSettings(
    organizationId: $organizationId
    legalEntityId: $legalEntityId
    interval: $interval
  ) {
    ...ReportAutomationSettingsFields
  }
}
    ${ReportAutomationSettingsFieldsFragmentDoc}`;

/**
 * __useReadReportAutomationSettingsQuery__
 *
 * To run a query within a React component, call `useReadReportAutomationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportAutomationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportAutomationSettingsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      legalEntityId: // value for 'legalEntityId'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useReadReportAutomationSettingsQuery(baseOptions: Apollo.QueryHookOptions<ReadReportAutomationSettingsQuery, ReadReportAutomationSettingsQueryVariables> & ({ variables: ReadReportAutomationSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReportAutomationSettingsQuery, ReadReportAutomationSettingsQueryVariables>(ReadReportAutomationSettingsDocument, options);
      }
export function useReadReportAutomationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReportAutomationSettingsQuery, ReadReportAutomationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReportAutomationSettingsQuery, ReadReportAutomationSettingsQueryVariables>(ReadReportAutomationSettingsDocument, options);
        }
export function useReadReportAutomationSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadReportAutomationSettingsQuery, ReadReportAutomationSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadReportAutomationSettingsQuery, ReadReportAutomationSettingsQueryVariables>(ReadReportAutomationSettingsDocument, options);
        }
export type ReadReportAutomationSettingsQueryHookResult = ReturnType<typeof useReadReportAutomationSettingsQuery>;
export type ReadReportAutomationSettingsLazyQueryHookResult = ReturnType<typeof useReadReportAutomationSettingsLazyQuery>;
export type ReadReportAutomationSettingsSuspenseQueryHookResult = ReturnType<typeof useReadReportAutomationSettingsSuspenseQuery>;
export type ReadReportAutomationSettingsQueryResult = Apollo.QueryResult<ReadReportAutomationSettingsQuery, ReadReportAutomationSettingsQueryVariables>;
export const ListReportPackagesDocument = gql`
    query ListReportPackages($legalEntityId: String!, $interval: Interval!, $intervalCount: Int, $year: Int!, $index: Int!, $paginationLimit: Int!, $paginationOffset: Int!, $direction: DirectionFromOrigin!, $sort: PackagesSort) {
  listReportPackages(
    legalEntityId: $legalEntityId
    origin: {interval: $interval, year: $year, index: $index, intervalCount: $intervalCount}
    pagination: {limit: $paginationLimit, offset: $paginationOffset}
    direction: $direction
    sort: $sort
  ) {
    ...ReportPackageMetadataFields
  }
}
    ${ReportPackageMetadataFieldsFragmentDoc}`;

/**
 * __useListReportPackagesQuery__
 *
 * To run a query within a React component, call `useListReportPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportPackagesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      interval: // value for 'interval'
 *      intervalCount: // value for 'intervalCount'
 *      year: // value for 'year'
 *      index: // value for 'index'
 *      paginationLimit: // value for 'paginationLimit'
 *      paginationOffset: // value for 'paginationOffset'
 *      direction: // value for 'direction'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListReportPackagesQuery(baseOptions: Apollo.QueryHookOptions<ListReportPackagesQuery, ListReportPackagesQueryVariables> & ({ variables: ListReportPackagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReportPackagesQuery, ListReportPackagesQueryVariables>(ListReportPackagesDocument, options);
      }
export function useListReportPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportPackagesQuery, ListReportPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReportPackagesQuery, ListReportPackagesQueryVariables>(ListReportPackagesDocument, options);
        }
export function useListReportPackagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListReportPackagesQuery, ListReportPackagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListReportPackagesQuery, ListReportPackagesQueryVariables>(ListReportPackagesDocument, options);
        }
export type ListReportPackagesQueryHookResult = ReturnType<typeof useListReportPackagesQuery>;
export type ListReportPackagesLazyQueryHookResult = ReturnType<typeof useListReportPackagesLazyQuery>;
export type ListReportPackagesSuspenseQueryHookResult = ReturnType<typeof useListReportPackagesSuspenseQuery>;
export type ListReportPackagesQueryResult = Apollo.QueryResult<ListReportPackagesQuery, ListReportPackagesQueryVariables>;
export const ReportDownloadUrlDocument = gql`
    query ReportDownloadUrl($legalEntityId: String!, $reportId: String!, $reportVersionId: String!, $reportPackageId: String!, $reportFileSource: ReportFileSource) {
  reportDownloadUrl(
    legalEntityId: $legalEntityId
    reportId: $reportId
    reportVersionId: $reportVersionId
    reportPackageId: $reportPackageId
    reportFileSource: $reportFileSource
  ) {
    url
  }
}
    `;

/**
 * __useReportDownloadUrlQuery__
 *
 * To run a query within a React component, call `useReportDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportDownloadUrlQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reportId: // value for 'reportId'
 *      reportVersionId: // value for 'reportVersionId'
 *      reportPackageId: // value for 'reportPackageId'
 *      reportFileSource: // value for 'reportFileSource'
 *   },
 * });
 */
export function useReportDownloadUrlQuery(baseOptions: Apollo.QueryHookOptions<ReportDownloadUrlQuery, ReportDownloadUrlQueryVariables> & ({ variables: ReportDownloadUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportDownloadUrlQuery, ReportDownloadUrlQueryVariables>(ReportDownloadUrlDocument, options);
      }
export function useReportDownloadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportDownloadUrlQuery, ReportDownloadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportDownloadUrlQuery, ReportDownloadUrlQueryVariables>(ReportDownloadUrlDocument, options);
        }
export function useReportDownloadUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReportDownloadUrlQuery, ReportDownloadUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportDownloadUrlQuery, ReportDownloadUrlQueryVariables>(ReportDownloadUrlDocument, options);
        }
export type ReportDownloadUrlQueryHookResult = ReturnType<typeof useReportDownloadUrlQuery>;
export type ReportDownloadUrlLazyQueryHookResult = ReturnType<typeof useReportDownloadUrlLazyQuery>;
export type ReportDownloadUrlSuspenseQueryHookResult = ReturnType<typeof useReportDownloadUrlSuspenseQuery>;
export type ReportDownloadUrlQueryResult = Apollo.QueryResult<ReportDownloadUrlQuery, ReportDownloadUrlQueryVariables>;
export const SummarizeReportPackagesDocument = gql`
    query SummarizeReportPackages($legalEntityId: String!, $intervalOrigin: IntervalOrigin!, $direction: DirectionFromOrigin!) {
  summarizeReportPackages(
    legalEntityId: $legalEntityId
    origin: $intervalOrigin
    direction: $direction
  ) {
    period {
      ...PeriodFields
    }
    packagesCount
    publishedCount
    draftCount
  }
}
    ${PeriodFieldsFragmentDoc}`;

/**
 * __useSummarizeReportPackagesQuery__
 *
 * To run a query within a React component, call `useSummarizeReportPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeReportPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeReportPackagesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      intervalOrigin: // value for 'intervalOrigin'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useSummarizeReportPackagesQuery(baseOptions: Apollo.QueryHookOptions<SummarizeReportPackagesQuery, SummarizeReportPackagesQueryVariables> & ({ variables: SummarizeReportPackagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeReportPackagesQuery, SummarizeReportPackagesQueryVariables>(SummarizeReportPackagesDocument, options);
      }
export function useSummarizeReportPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeReportPackagesQuery, SummarizeReportPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeReportPackagesQuery, SummarizeReportPackagesQueryVariables>(SummarizeReportPackagesDocument, options);
        }
export function useSummarizeReportPackagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeReportPackagesQuery, SummarizeReportPackagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeReportPackagesQuery, SummarizeReportPackagesQueryVariables>(SummarizeReportPackagesDocument, options);
        }
export type SummarizeReportPackagesQueryHookResult = ReturnType<typeof useSummarizeReportPackagesQuery>;
export type SummarizeReportPackagesLazyQueryHookResult = ReturnType<typeof useSummarizeReportPackagesLazyQuery>;
export type SummarizeReportPackagesSuspenseQueryHookResult = ReturnType<typeof useSummarizeReportPackagesSuspenseQuery>;
export type SummarizeReportPackagesQueryResult = Apollo.QueryResult<SummarizeReportPackagesQuery, SummarizeReportPackagesQueryVariables>;
export const ReadReportArchiveDocument = gql`
    query ReadReportArchive($legalEntityId: String!, $reportId: String!, $reportVersionId: String!, $reportPackageId: String!, $reportFileSource: ReportFileSource, $limit: Int!, $sort: DimensionSortMode) {
  readReportArchive(
    legalEntityId: $legalEntityId
    reportId: $reportId
    reportVersionId: $reportVersionId
    reportPackageId: $reportPackageId
    reportFileSource: $reportFileSource
  ) {
    ...StatementFields
  }
}
    ${StatementFieldsFragmentDoc}`;

/**
 * __useReadReportArchiveQuery__
 *
 * To run a query within a React component, call `useReadReportArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportArchiveQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reportId: // value for 'reportId'
 *      reportVersionId: // value for 'reportVersionId'
 *      reportPackageId: // value for 'reportPackageId'
 *      reportFileSource: // value for 'reportFileSource'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadReportArchiveQuery(baseOptions: Apollo.QueryHookOptions<ReadReportArchiveQuery, ReadReportArchiveQueryVariables> & ({ variables: ReadReportArchiveQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReportArchiveQuery, ReadReportArchiveQueryVariables>(ReadReportArchiveDocument, options);
      }
export function useReadReportArchiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReportArchiveQuery, ReadReportArchiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReportArchiveQuery, ReadReportArchiveQueryVariables>(ReadReportArchiveDocument, options);
        }
export function useReadReportArchiveSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadReportArchiveQuery, ReadReportArchiveQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadReportArchiveQuery, ReadReportArchiveQueryVariables>(ReadReportArchiveDocument, options);
        }
export type ReadReportArchiveQueryHookResult = ReturnType<typeof useReadReportArchiveQuery>;
export type ReadReportArchiveLazyQueryHookResult = ReturnType<typeof useReadReportArchiveLazyQuery>;
export type ReadReportArchiveSuspenseQueryHookResult = ReturnType<typeof useReadReportArchiveSuspenseQuery>;
export type ReadReportArchiveQueryResult = Apollo.QueryResult<ReadReportArchiveQuery, ReadReportArchiveQueryVariables>;
export const ActivateCurrentUserEmploymentDocument = gql`
    mutation ActivateCurrentUserEmployment($organizationId: String!) {
  activateCurrentUserEmployment(organizationId: $organizationId)
}
    `;
export type ActivateCurrentUserEmploymentMutationFn = Apollo.MutationFunction<ActivateCurrentUserEmploymentMutation, ActivateCurrentUserEmploymentMutationVariables>;

/**
 * __useActivateCurrentUserEmploymentMutation__
 *
 * To run a mutation, you first call `useActivateCurrentUserEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCurrentUserEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCurrentUserEmploymentMutation, { data, loading, error }] = useActivateCurrentUserEmploymentMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useActivateCurrentUserEmploymentMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCurrentUserEmploymentMutation, ActivateCurrentUserEmploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateCurrentUserEmploymentMutation, ActivateCurrentUserEmploymentMutationVariables>(ActivateCurrentUserEmploymentDocument, options);
      }
export type ActivateCurrentUserEmploymentMutationHookResult = ReturnType<typeof useActivateCurrentUserEmploymentMutation>;
export type ActivateCurrentUserEmploymentMutationResult = Apollo.MutationResult<ActivateCurrentUserEmploymentMutation>;
export type ActivateCurrentUserEmploymentMutationOptions = Apollo.BaseMutationOptions<ActivateCurrentUserEmploymentMutation, ActivateCurrentUserEmploymentMutationVariables>;
export const CreateEmployeeAffiliationsDocument = gql`
    mutation CreateEmployeeAffiliations($organizationId: String!, $organizationRole: String!, $employeeId: String, $givenName: String!, $familyName: String!, $emailAddress: String!, $affiliations: [LegalEntityRole!]!) {
  createEmployeeAffiliations(
    organizationId: $organizationId
    organizationRole: $organizationRole
    employeeId: $employeeId
    givenName: $givenName
    familyName: $familyName
    emailAddress: $emailAddress
    affiliations: $affiliations
  )
}
    `;
export type CreateEmployeeAffiliationsMutationFn = Apollo.MutationFunction<CreateEmployeeAffiliationsMutation, CreateEmployeeAffiliationsMutationVariables>;

/**
 * __useCreateEmployeeAffiliationsMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeAffiliationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeAffiliationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeAffiliationsMutation, { data, loading, error }] = useCreateEmployeeAffiliationsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      organizationRole: // value for 'organizationRole'
 *      employeeId: // value for 'employeeId'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      emailAddress: // value for 'emailAddress'
 *      affiliations: // value for 'affiliations'
 *   },
 * });
 */
export function useCreateEmployeeAffiliationsMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeAffiliationsMutation, CreateEmployeeAffiliationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeAffiliationsMutation, CreateEmployeeAffiliationsMutationVariables>(CreateEmployeeAffiliationsDocument, options);
      }
export type CreateEmployeeAffiliationsMutationHookResult = ReturnType<typeof useCreateEmployeeAffiliationsMutation>;
export type CreateEmployeeAffiliationsMutationResult = Apollo.MutationResult<CreateEmployeeAffiliationsMutation>;
export type CreateEmployeeAffiliationsMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeAffiliationsMutation, CreateEmployeeAffiliationsMutationVariables>;
export const DeactivateEmployeeDocument = gql`
    mutation DeactivateEmployee($employeeId: String!, $organizationId: String!) {
  deactivateEmployee(employeeId: $employeeId, organizationId: $organizationId)
}
    `;
export type DeactivateEmployeeMutationFn = Apollo.MutationFunction<DeactivateEmployeeMutation, DeactivateEmployeeMutationVariables>;

/**
 * __useDeactivateEmployeeMutation__
 *
 * To run a mutation, you first call `useDeactivateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateEmployeeMutation, { data, loading, error }] = useDeactivateEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeactivateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateEmployeeMutation, DeactivateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateEmployeeMutation, DeactivateEmployeeMutationVariables>(DeactivateEmployeeDocument, options);
      }
export type DeactivateEmployeeMutationHookResult = ReturnType<typeof useDeactivateEmployeeMutation>;
export type DeactivateEmployeeMutationResult = Apollo.MutationResult<DeactivateEmployeeMutation>;
export type DeactivateEmployeeMutationOptions = Apollo.BaseMutationOptions<DeactivateEmployeeMutation, DeactivateEmployeeMutationVariables>;
export const UpdateEmployeeAffiliationsDocument = gql`
    mutation UpdateEmployeeAffiliations($employeeIdentifier: EmployeeIdentifier!, $affiliations: [LegalEntityRole!]!) {
  updateEmployeeAffiliations(
    employeeIdentifier: $employeeIdentifier
    affiliations: $affiliations
  )
}
    `;
export type UpdateEmployeeAffiliationsMutationFn = Apollo.MutationFunction<UpdateEmployeeAffiliationsMutation, UpdateEmployeeAffiliationsMutationVariables>;

/**
 * __useUpdateEmployeeAffiliationsMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeAffiliationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeAffiliationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeAffiliationsMutation, { data, loading, error }] = useUpdateEmployeeAffiliationsMutation({
 *   variables: {
 *      employeeIdentifier: // value for 'employeeIdentifier'
 *      affiliations: // value for 'affiliations'
 *   },
 * });
 */
export function useUpdateEmployeeAffiliationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeAffiliationsMutation, UpdateEmployeeAffiliationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeAffiliationsMutation, UpdateEmployeeAffiliationsMutationVariables>(UpdateEmployeeAffiliationsDocument, options);
      }
export type UpdateEmployeeAffiliationsMutationHookResult = ReturnType<typeof useUpdateEmployeeAffiliationsMutation>;
export type UpdateEmployeeAffiliationsMutationResult = Apollo.MutationResult<UpdateEmployeeAffiliationsMutation>;
export type UpdateEmployeeAffiliationsMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeAffiliationsMutation, UpdateEmployeeAffiliationsMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($employeeId: String!, $organizationId: String!, $emailAddress: String, $givenName: String, $familyName: String, $avatarUrl: String, $jobTitle: String, $role: RoleIdentifier, $hidden: Boolean, $fieldToUpdate: UpdatableEmployeeField!) {
  updateEmployee(
    employeeId: $employeeId
    organizationId: $organizationId
    emailAddress: $emailAddress
    givenName: $givenName
    familyName: $familyName
    avatarUrl: $avatarUrl
    jobTitle: $jobTitle
    role: $role
    hidden: $hidden
    fieldToUpdate: $fieldToUpdate
  )
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      organizationId: // value for 'organizationId'
 *      emailAddress: // value for 'emailAddress'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      avatarUrl: // value for 'avatarUrl'
 *      jobTitle: // value for 'jobTitle'
 *      role: // value for 'role'
 *      hidden: // value for 'hidden'
 *      fieldToUpdate: // value for 'fieldToUpdate'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const DeleteEmployeeAffiliationsDocument = gql`
    mutation DeleteEmployeeAffiliations($employeeIdentifier: EmployeeIdentifier!, $legalEntityIds: [String!]!) {
  deleteEmployeeAffiliations(
    employeeIdentifier: $employeeIdentifier
    legalEntityIds: $legalEntityIds
  )
}
    `;
export type DeleteEmployeeAffiliationsMutationFn = Apollo.MutationFunction<DeleteEmployeeAffiliationsMutation, DeleteEmployeeAffiliationsMutationVariables>;

/**
 * __useDeleteEmployeeAffiliationsMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeAffiliationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeAffiliationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeAffiliationsMutation, { data, loading, error }] = useDeleteEmployeeAffiliationsMutation({
 *   variables: {
 *      employeeIdentifier: // value for 'employeeIdentifier'
 *      legalEntityIds: // value for 'legalEntityIds'
 *   },
 * });
 */
export function useDeleteEmployeeAffiliationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeAffiliationsMutation, DeleteEmployeeAffiliationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeAffiliationsMutation, DeleteEmployeeAffiliationsMutationVariables>(DeleteEmployeeAffiliationsDocument, options);
      }
export type DeleteEmployeeAffiliationsMutationHookResult = ReturnType<typeof useDeleteEmployeeAffiliationsMutation>;
export type DeleteEmployeeAffiliationsMutationResult = Apollo.MutationResult<DeleteEmployeeAffiliationsMutation>;
export type DeleteEmployeeAffiliationsMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeAffiliationsMutation, DeleteEmployeeAffiliationsMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($organizationId: String!, $emailAddress: String, $givenName: String, $familyName: String, $avatarUrl: String, $jobTitle: String, $role: RoleIdentifier!) {
  createEmployee(
    organizationId: $organizationId
    emailAddress: $emailAddress
    givenName: $givenName
    familyName: $familyName
    avatarUrl: $avatarUrl
    jobTitle: $jobTitle
    role: $role
  ) {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      emailAddress: // value for 'emailAddress'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      avatarUrl: // value for 'avatarUrl'
 *      jobTitle: // value for 'jobTitle'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const CountEmployeesDocument = gql`
    query CountEmployees($filter: EmployeeFilter!) {
  countEmployees(filter: $filter) {
    count
  }
}
    `;

/**
 * __useCountEmployeesQuery__
 *
 * To run a query within a React component, call `useCountEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountEmployeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountEmployeesQuery(baseOptions: Apollo.QueryHookOptions<CountEmployeesQuery, CountEmployeesQueryVariables> & ({ variables: CountEmployeesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountEmployeesQuery, CountEmployeesQueryVariables>(CountEmployeesDocument, options);
      }
export function useCountEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountEmployeesQuery, CountEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountEmployeesQuery, CountEmployeesQueryVariables>(CountEmployeesDocument, options);
        }
export function useCountEmployeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CountEmployeesQuery, CountEmployeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountEmployeesQuery, CountEmployeesQueryVariables>(CountEmployeesDocument, options);
        }
export type CountEmployeesQueryHookResult = ReturnType<typeof useCountEmployeesQuery>;
export type CountEmployeesLazyQueryHookResult = ReturnType<typeof useCountEmployeesLazyQuery>;
export type CountEmployeesSuspenseQueryHookResult = ReturnType<typeof useCountEmployeesSuspenseQuery>;
export type CountEmployeesQueryResult = Apollo.QueryResult<CountEmployeesQuery, CountEmployeesQueryVariables>;
export const ListEmployeesAffiliationsDocument = gql`
    query ListEmployeesAffiliations($filter: EmployeeFilter!, $pagination: Pagination!, $sort: EmployeeSort) {
  listEmployeesAffiliations(filter: $filter, pagination: $pagination, sort: $sort) {
    ...EmployeeAffiliationsFields
  }
}
    ${EmployeeAffiliationsFieldsFragmentDoc}`;

/**
 * __useListEmployeesAffiliationsQuery__
 *
 * To run a query within a React component, call `useListEmployeesAffiliationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesAffiliationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesAffiliationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListEmployeesAffiliationsQuery(baseOptions: Apollo.QueryHookOptions<ListEmployeesAffiliationsQuery, ListEmployeesAffiliationsQueryVariables> & ({ variables: ListEmployeesAffiliationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeesAffiliationsQuery, ListEmployeesAffiliationsQueryVariables>(ListEmployeesAffiliationsDocument, options);
      }
export function useListEmployeesAffiliationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeesAffiliationsQuery, ListEmployeesAffiliationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeesAffiliationsQuery, ListEmployeesAffiliationsQueryVariables>(ListEmployeesAffiliationsDocument, options);
        }
export function useListEmployeesAffiliationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListEmployeesAffiliationsQuery, ListEmployeesAffiliationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEmployeesAffiliationsQuery, ListEmployeesAffiliationsQueryVariables>(ListEmployeesAffiliationsDocument, options);
        }
export type ListEmployeesAffiliationsQueryHookResult = ReturnType<typeof useListEmployeesAffiliationsQuery>;
export type ListEmployeesAffiliationsLazyQueryHookResult = ReturnType<typeof useListEmployeesAffiliationsLazyQuery>;
export type ListEmployeesAffiliationsSuspenseQueryHookResult = ReturnType<typeof useListEmployeesAffiliationsSuspenseQuery>;
export type ListEmployeesAffiliationsQueryResult = Apollo.QueryResult<ListEmployeesAffiliationsQuery, ListEmployeesAffiliationsQueryVariables>;
export const ListEmployeesDocument = gql`
    query ListEmployees($filter: EmployeeFilter!, $pagination: Pagination!, $sort: EmployeeSort) {
  listEmployees(filter: $filter, pagination: $pagination, sort: $sort) {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

/**
 * __useListEmployeesQuery__
 *
 * To run a query within a React component, call `useListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useListEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables> & ({ variables: ListEmployeesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
      }
export function useListEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export function useListEmployeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export type ListEmployeesQueryHookResult = ReturnType<typeof useListEmployeesQuery>;
export type ListEmployeesLazyQueryHookResult = ReturnType<typeof useListEmployeesLazyQuery>;
export type ListEmployeesSuspenseQueryHookResult = ReturnType<typeof useListEmployeesSuspenseQuery>;
export type ListEmployeesQueryResult = Apollo.QueryResult<ListEmployeesQuery, ListEmployeesQueryVariables>;
export const SendManagedOperatorInvitationDocument = gql`
    mutation SendManagedOperatorInvitation($recipient: ContactInput!, $legalEntityId: String!, $message: String, $redirectPath: String, $contextObject: InputObjectIdentifier) {
  sendManagedOperatorInvitation(
    recipient: $recipient
    legalEntityId: $legalEntityId
    message: $message
    redirectPath: $redirectPath
    contextObject: $contextObject
  )
}
    `;
export type SendManagedOperatorInvitationMutationFn = Apollo.MutationFunction<SendManagedOperatorInvitationMutation, SendManagedOperatorInvitationMutationVariables>;

/**
 * __useSendManagedOperatorInvitationMutation__
 *
 * To run a mutation, you first call `useSendManagedOperatorInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendManagedOperatorInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendManagedOperatorInvitationMutation, { data, loading, error }] = useSendManagedOperatorInvitationMutation({
 *   variables: {
 *      recipient: // value for 'recipient'
 *      legalEntityId: // value for 'legalEntityId'
 *      message: // value for 'message'
 *      redirectPath: // value for 'redirectPath'
 *      contextObject: // value for 'contextObject'
 *   },
 * });
 */
export function useSendManagedOperatorInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendManagedOperatorInvitationMutation, SendManagedOperatorInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendManagedOperatorInvitationMutation, SendManagedOperatorInvitationMutationVariables>(SendManagedOperatorInvitationDocument, options);
      }
export type SendManagedOperatorInvitationMutationHookResult = ReturnType<typeof useSendManagedOperatorInvitationMutation>;
export type SendManagedOperatorInvitationMutationResult = Apollo.MutationResult<SendManagedOperatorInvitationMutation>;
export type SendManagedOperatorInvitationMutationOptions = Apollo.BaseMutationOptions<SendManagedOperatorInvitationMutation, SendManagedOperatorInvitationMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation ResendInvitation($token: String!) {
  resendInvitation(token: $token)
}
    `;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const ResendManagedOperatorInvitationDocument = gql`
    mutation ResendManagedOperatorInvitation($legalEntityId: String!, $token: String!, $emailAddress: String!, $message: String!) {
  resendManagedOperatorInvitation(
    legalEntityId: $legalEntityId
    token: $token
    emailAddress: $emailAddress
    message: $message
  )
}
    `;
export type ResendManagedOperatorInvitationMutationFn = Apollo.MutationFunction<ResendManagedOperatorInvitationMutation, ResendManagedOperatorInvitationMutationVariables>;

/**
 * __useResendManagedOperatorInvitationMutation__
 *
 * To run a mutation, you first call `useResendManagedOperatorInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendManagedOperatorInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendManagedOperatorInvitationMutation, { data, loading, error }] = useResendManagedOperatorInvitationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      token: // value for 'token'
 *      emailAddress: // value for 'emailAddress'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useResendManagedOperatorInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendManagedOperatorInvitationMutation, ResendManagedOperatorInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendManagedOperatorInvitationMutation, ResendManagedOperatorInvitationMutationVariables>(ResendManagedOperatorInvitationDocument, options);
      }
export type ResendManagedOperatorInvitationMutationHookResult = ReturnType<typeof useResendManagedOperatorInvitationMutation>;
export type ResendManagedOperatorInvitationMutationResult = Apollo.MutationResult<ResendManagedOperatorInvitationMutation>;
export type ResendManagedOperatorInvitationMutationOptions = Apollo.BaseMutationOptions<ResendManagedOperatorInvitationMutation, ResendManagedOperatorInvitationMutationVariables>;
export const SendInstructionsAffiliateDocument = gql`
    mutation SendInstructionsAffiliate($givenName: String!, $familyName: String!, $emailAddress: String!, $destinationOrganizationId: String!, $invitationType: String!, $proposalType: String!) {
  sendInstructionsAffiliate(
    recipient: {givenName: $givenName, familyName: $familyName, emailAddress: $emailAddress}
    destinationOrganizationId: $destinationOrganizationId
    invitationType: $invitationType
    proposalType: $proposalType
  )
}
    `;
export type SendInstructionsAffiliateMutationFn = Apollo.MutationFunction<SendInstructionsAffiliateMutation, SendInstructionsAffiliateMutationVariables>;

/**
 * __useSendInstructionsAffiliateMutation__
 *
 * To run a mutation, you first call `useSendInstructionsAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInstructionsAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInstructionsAffiliateMutation, { data, loading, error }] = useSendInstructionsAffiliateMutation({
 *   variables: {
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      emailAddress: // value for 'emailAddress'
 *      destinationOrganizationId: // value for 'destinationOrganizationId'
 *      invitationType: // value for 'invitationType'
 *      proposalType: // value for 'proposalType'
 *   },
 * });
 */
export function useSendInstructionsAffiliateMutation(baseOptions?: Apollo.MutationHookOptions<SendInstructionsAffiliateMutation, SendInstructionsAffiliateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInstructionsAffiliateMutation, SendInstructionsAffiliateMutationVariables>(SendInstructionsAffiliateDocument, options);
      }
export type SendInstructionsAffiliateMutationHookResult = ReturnType<typeof useSendInstructionsAffiliateMutation>;
export type SendInstructionsAffiliateMutationResult = Apollo.MutationResult<SendInstructionsAffiliateMutation>;
export type SendInstructionsAffiliateMutationOptions = Apollo.BaseMutationOptions<SendInstructionsAffiliateMutation, SendInstructionsAffiliateMutationVariables>;
export const CreateAffiliationDocument = gql`
    mutation CreateAffiliation($organizationId: String!, $name: String!, $iconBase64: String, $websiteUrl: String, $givenName: String!, $familyName: String!, $emailAddress: String!, $message: String) {
  createAffiliation(
    organizationId: $organizationId
    name: $name
    iconBase64: $iconBase64
    websiteUrl: $websiteUrl
    recipient: {givenName: $givenName, familyName: $familyName, emailAddress: $emailAddress}
    message: $message
  ) {
    ...LegalEntityFields
  }
}
    ${LegalEntityFieldsFragmentDoc}`;
export type CreateAffiliationMutationFn = Apollo.MutationFunction<CreateAffiliationMutation, CreateAffiliationMutationVariables>;

/**
 * __useCreateAffiliationMutation__
 *
 * To run a mutation, you first call `useCreateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAffiliationMutation, { data, loading, error }] = useCreateAffiliationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      iconBase64: // value for 'iconBase64'
 *      websiteUrl: // value for 'websiteUrl'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      emailAddress: // value for 'emailAddress'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCreateAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAffiliationMutation, CreateAffiliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAffiliationMutation, CreateAffiliationMutationVariables>(CreateAffiliationDocument, options);
      }
export type CreateAffiliationMutationHookResult = ReturnType<typeof useCreateAffiliationMutation>;
export type CreateAffiliationMutationResult = Apollo.MutationResult<CreateAffiliationMutation>;
export type CreateAffiliationMutationOptions = Apollo.BaseMutationOptions<CreateAffiliationMutation, CreateAffiliationMutationVariables>;
export const CancelInvitationDocument = gql`
    mutation CancelInvitation($token: String!) {
  cancelInvitation(token: $token)
}
    `;
export type CancelInvitationMutationFn = Apollo.MutationFunction<CancelInvitationMutation, CancelInvitationMutationVariables>;

/**
 * __useCancelInvitationMutation__
 *
 * To run a mutation, you first call `useCancelInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvitationMutation, { data, loading, error }] = useCancelInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCancelInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CancelInvitationMutation, CancelInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInvitationMutation, CancelInvitationMutationVariables>(CancelInvitationDocument, options);
      }
export type CancelInvitationMutationHookResult = ReturnType<typeof useCancelInvitationMutation>;
export type CancelInvitationMutationResult = Apollo.MutationResult<CancelInvitationMutation>;
export type CancelInvitationMutationOptions = Apollo.BaseMutationOptions<CancelInvitationMutation, CancelInvitationMutationVariables>;
export const SendInstructionsDocument = gql`
    mutation SendInstructions($givenName: String!, $familyName: String!, $emailAddress: String!, $destinationOrganizationId: String!, $invitationType: String!, $proposalType: String) {
  sendInstructions(
    recipient: {givenName: $givenName, familyName: $familyName, emailAddress: $emailAddress}
    destinationOrganizationId: $destinationOrganizationId
    invitationType: $invitationType
    proposalType: $proposalType
  )
}
    `;
export type SendInstructionsMutationFn = Apollo.MutationFunction<SendInstructionsMutation, SendInstructionsMutationVariables>;

/**
 * __useSendInstructionsMutation__
 *
 * To run a mutation, you first call `useSendInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInstructionsMutation, { data, loading, error }] = useSendInstructionsMutation({
 *   variables: {
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      emailAddress: // value for 'emailAddress'
 *      destinationOrganizationId: // value for 'destinationOrganizationId'
 *      invitationType: // value for 'invitationType'
 *      proposalType: // value for 'proposalType'
 *   },
 * });
 */
export function useSendInstructionsMutation(baseOptions?: Apollo.MutationHookOptions<SendInstructionsMutation, SendInstructionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInstructionsMutation, SendInstructionsMutationVariables>(SendInstructionsDocument, options);
      }
export type SendInstructionsMutationHookResult = ReturnType<typeof useSendInstructionsMutation>;
export type SendInstructionsMutationResult = Apollo.MutationResult<SendInstructionsMutation>;
export type SendInstructionsMutationOptions = Apollo.BaseMutationOptions<SendInstructionsMutation, SendInstructionsMutationVariables>;
export const SendInvitationDocument = gql`
    mutation SendInvitation($givenName: String, $familyName: String, $emailAddress: String!, $employeeId: String, $destinationOrganizationId: String, $destinationOrganizationRole: String, $legalEntityId: String, $invitationType: String!, $proposalType: String, $message: String) {
  sendInvitation(
    recipient: {givenName: $givenName, familyName: $familyName, emailAddress: $emailAddress}
    employeeId: $employeeId
    destinationOrganizationId: $destinationOrganizationId
    destinationOrganizationRole: $destinationOrganizationRole
    legalEntityId: $legalEntityId
    invitationType: $invitationType
    proposalType: $proposalType
    message: $message
  )
}
    `;
export type SendInvitationMutationFn = Apollo.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      emailAddress: // value for 'emailAddress'
 *      employeeId: // value for 'employeeId'
 *      destinationOrganizationId: // value for 'destinationOrganizationId'
 *      destinationOrganizationRole: // value for 'destinationOrganizationRole'
 *      legalEntityId: // value for 'legalEntityId'
 *      invitationType: // value for 'invitationType'
 *      proposalType: // value for 'proposalType'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, options);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<SendInvitationMutation, SendInvitationMutationVariables>;
export const ReadUserInvitationDocument = gql`
    query ReadUserInvitation {
  readUserInvitation {
    recipient {
      givenName
      familyName
      emailAddress
    }
    sender {
      givenName
      avatarUrl
      emailAddress
    }
    organization {
      id
      slug
      name
    }
    service
    invitationType
    organizationTemplateId
  }
}
    `;

/**
 * __useReadUserInvitationQuery__
 *
 * To run a query within a React component, call `useReadUserInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserInvitationQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadUserInvitationQuery(baseOptions?: Apollo.QueryHookOptions<ReadUserInvitationQuery, ReadUserInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserInvitationQuery, ReadUserInvitationQueryVariables>(ReadUserInvitationDocument, options);
      }
export function useReadUserInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserInvitationQuery, ReadUserInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserInvitationQuery, ReadUserInvitationQueryVariables>(ReadUserInvitationDocument, options);
        }
export function useReadUserInvitationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadUserInvitationQuery, ReadUserInvitationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadUserInvitationQuery, ReadUserInvitationQueryVariables>(ReadUserInvitationDocument, options);
        }
export type ReadUserInvitationQueryHookResult = ReturnType<typeof useReadUserInvitationQuery>;
export type ReadUserInvitationLazyQueryHookResult = ReturnType<typeof useReadUserInvitationLazyQuery>;
export type ReadUserInvitationSuspenseQueryHookResult = ReturnType<typeof useReadUserInvitationSuspenseQuery>;
export type ReadUserInvitationQueryResult = Apollo.QueryResult<ReadUserInvitationQuery, ReadUserInvitationQueryVariables>;