import envHelper from "@digits-shared/helpers/envHelper"
import "es-iterator-helpers/auto"

if (envHelper.isTest() || !Array.prototype.toSorted) {
  Array.prototype.toSorted = function toSortedPolyfill<T>(
    compareFn?: ((a: T, b: T) => number) | undefined
  ): T[] {
    // Create a copy of the array to maintain immutability
    const copy = [...this]
    return copy.sort(compareFn)
  }
}
