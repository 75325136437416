// This shasum of an empty string is intended as an additional
// protection measure against refresh loops caused by bugs in the
// process of determining BREAKING_VERSION in CI.

import type Session from "@digits-shared/session/Session"

const SHASUM_EMPTY_STRING = "da39a3ee5e6b4b0d3255bfef95601890afd80709"

interface DetectorOptions {
  skipConfirmation?: boolean
}

/**
 * Used to detect a breaking version of our API. If our API schema changes and the webapp has not
 * reloaded yet, then we risk making requests with bad fields. Doing so will trigger a request error
 * and prevent the dashboard from functioning correctly. This class is responsible for hitting an
 * endpoint which provides a break version code. If the breaking version changes over the lifetime
 * of the webapp, then the user will be prompted that they need to reload to prevent errors.
 */
export default class BreakingVersionDetector {
  // This process.env.BREAKING_VERSION variable is built into
  // the app at compile time via vite, and is intended to
  // remain static for the lifetime of this app version.
  static BREAKING_VERSION = process.env.BREAKING_VERSION
  static THROTTLE_DURATION = 5 * 60 * 1000 // 5 minutes in milliseconds
  static LAST_SHOWN = 0 // static so counter is across all instances (errors, version tracker, etc)

  readonly session: Session
  readonly options: DetectorOptions
  checkingForBreakingVersion: Promise<void | Response> | undefined

  constructor(
    session: Session,
    options: DetectorOptions = {
      skipConfirmation: false, // confirmation is ON by default,
    }
  ) {
    this.session = session
    this.options = options
  }

  onPageRefreshRequired = (version: string) => {
    const now = Date.now()
    // if it is a logged out session or confirmation is skipped, just reload
    if (this.options.skipConfirmation || !this.session.hasUserData) {
      return window.location.reload()
    }

    const lastShown = BreakingVersionDetector.LAST_SHOWN
    // Show dialog only if 5 minutes have passed since the last show or it's never been shown
    if (!lastShown || now - lastShown > BreakingVersionDetector.THROTTLE_DURATION) {
      BreakingVersionDetector.LAST_SHOWN = now

      if (
        // eslint-disable-next-line no-alert
        confirm(
          "A new version of Digits is available! Press OK to reload now or Cancel to update later."
        )
      ) {
        window.location.reload()
      }
    }
  }

  checkBreakingVersion() {
    if (this.checkingForBreakingVersion) return
    this.checkingForBreakingVersion = fetch("/breaking-version")
      .then((response: Response) => response.text())
      .then(this.handleBreakingVersion)
      .finally(() => {
        this.checkingForBreakingVersion = undefined
      })
  }

  handleBreakingVersion = (version = "") => {
    // Guard: don't cause refresh loops if these values are empty
    if (!(version && BreakingVersionDetector.BREAKING_VERSION)) return

    // Ensure the version string from the server does not include a trailing newline.
    const cleanVersion = version.trim()

    // Guard: don't cause refresh loops if breaking version is a shasum of emptystring
    if (
      cleanVersion === SHASUM_EMPTY_STRING ||
      BreakingVersionDetector.BREAKING_VERSION === SHASUM_EMPTY_STRING
    ) {
      return
    }

    // Primary case: if we don't have the same version as the current breaking
    // version, time to cause a page reload.
    if (cleanVersion !== BreakingVersionDetector.BREAKING_VERSION) {
      TrackJS?.console.info(
        `Breaking Version Detected (version: ${BreakingVersionDetector.BREAKING_VERSION}) (new: ${cleanVersion})`
      )
      this.onPageRefreshRequired(version)
    }
  }
}
