import * as React from "react"
import BreakingVersionDetector from "@digits-shared/components/Error/BreakingVersionDetector"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import routes from "src/frontend/routes"
import BackgroundTask from "src/shared/components/Background/BackgroundTask"

/*
 COMPONENTS
*/

const TASK_FREQUENCY = 30 * 60 * 1000 // 30 minutes in milliseconds

// If there is a breaking version while detected while in the Tab is in the background AND not in a details view,
// the page will be reload without confirmation
const ClientVersionTracker: React.FC = () => {
  const { current: breakingVersionDetector } = React.useRef(
    new BreakingVersionDetector(useSession(), { skipConfirmation: true })
  )
  const { location } = useRouter()

  // Check every 30 mins
  // Disabled if the current page is a details page.
  // Details pages tend to have forms or work that is done that we dont want to load
  return (
    <BackgroundTask
      frequency={TASK_FREQUENCY}
      task={() => breakingVersionDetector.checkBreakingVersion()}
      disabled={routes.pathIsDetailsView(location.name)}
    />
  )
}
export default ClientVersionTracker
