import type * as React from "react"
import { Link } from "react-router-dom"
import { responsiveStyles } from "@digits-shared/components/Responsive/responsiveStyles"
import { H2, H3 } from "@digits-shared/components/UI/Elements/Header"
import { GLASS_LAYER } from "@digits-shared/DesignSystem/Containers/Glass"
import { isDigitsMacApp, isMobile, isSafari } from "@digits-shared/helpers/devicesHelper"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { createGlobalStyle, css } from "styled-components"

export const TOP_MARGIN = isDigitsMacApp ? 135 : 111

export const SITE_WIDTH = 1200
export const SITE_PADDING = 10
export const SITE_CENTER_CONTENT_PADDING = 145
export const SITE_CENTER_CONTENT_WIDTH = SITE_WIDTH - SITE_CENTER_CONTENT_PADDING * 2

export const TOP_NAV_HEIGHT = 50
export const PAGE_HEADER_HEIGHT = isDigitsMacApp ? 140 : 152

export const PageHeader = styled.header`
  width: var(--application-pane-width);
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 16px;
  border-top-left-radius: ${borders.radius.default}px;
  border-top-right-radius: ${borders.radius.default}px;
  text-transform: uppercase;
  color: ${colors.secondary};
  height: ${PAGE_HEADER_HEIGHT}px;
  min-height: ${PAGE_HEADER_HEIGHT}px;

  ${isDigitsMacApp &&
  css`
    height: ${PAGE_HEADER_HEIGHT + 40}px;
    min-height: ${PAGE_HEADER_HEIGHT + 40}px;
  `}
`

export const AnalysisPageHeader = styled.header`
  ${GLASS_LAYER};
  border-bottom: 1px solid ${colors.translucentWhite90};
  margin-top: ${TOP_MARGIN}px;
  display: flex;
  justify-content: space-between;
  border-radius: 16px 16px 0 0;
  padding: 20px;
`

export const PageHeaderTitle = styled(H2)`
  text-transform: none;
  margin: 2px 0;
  line-height: 22px;
  font-weight: ${fonts.weight.black};
  font-size: 20px;
  color: ${colors.secondary};
`

export const LinkTitle = styled(Link)<{ disabled?: boolean }>`
  &[disabled] {
    pointer-events: none;
  }

  &:not([disabled]) ${PageHeaderTitle} {
    transition: color 0.2s ease-in-out;
    color: ${colors.translucentSecondary20};

    &:hover {
      color: ${colors.secondary};
    }
  }
`

export const PageHeaderSubtitle = styled(H3)`
  text-transform: none;
  margin: 2px 0;
  font-weight: ${fonts.weight.medium};
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: ${colors.secondary};
`

export const CENTERED_DIALOG_CONTAINER_HEIGHT = "700px"
export const CENTERED_DIALOG_CONTAINER_WIDTH = "460px"

export interface CenteredDialogContainerProps {
  width?: string
  height?: string
  children?: React.ReactNode
}

const centeredDialogResponsiveStyles = responsiveStyles({
  desktop: css`
    padding: 80px 20px 0 20px;
    @media only screen and (max-height: 800px) {
      padding-top: 50px;
    }
  `,
  mobile: css`
    padding-top: 50px;
  `,
})

export const CENTERED_DIALOG_CONTAINER_PARENT_STYLES = css`
  ${centeredDialogResponsiveStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
`

export const CenteredDialogContainer = styled.section<CenteredDialogContainerProps>`
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: ${fonts.weight.normal};
  font-size: 20px;
  height: ${({ height }) => height ?? CENTERED_DIALOG_CONTAINER_HEIGHT};
  width: ${({ width }) => width ?? CENTERED_DIALOG_CONTAINER_WIDTH};
  max-width: ${({ width }) => width ?? CENTERED_DIALOG_CONTAINER_WIDTH};
  box-sizing: content-box;

  ${isMobile &&
  css`
    height: auto;
    width: 100%;
  `}

  background: radial-gradient(50% 40% at 50% 0%,
  rgba(24, 81, 114, 0.35) 0%,
  rgba(18, 53, 63, 0) 100%),
  radial-gradient(50% 20% at 50% 100%, #02273566 0%, #12353f00 100%) rgb(8 26 42);
  box-shadow: rgb(15, 42, 68) 0 0 1000px;

  backdrop-filter: blur(3px);
  border-radius: ${borders.radius.card}px;

  ${!isSafari && // back to IE6
  css`
    border: 0.5px solid transparent;
  `}
  border-image: linear-gradient(55deg, #071a23, #0b7d83, #081231, #06bde2, #070f27);
  border-image-slice: 1;
  border-image-width: 1px;
`

export const CenteredDialogBodyStyles = createGlobalStyle`
  body {
    overflow-x: hidden;
  }

  html,
  body,
  main {
    ${
      isMobile
        ? css`
            min-height: 100%;
          `
        : css`
            min-height: 850px;
          `
    };
  }
`
