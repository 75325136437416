import { InsightType, PartyRole, ProductArea } from "@digits-graphql/frontend/graphql-bearer"
import { SvgAddTransaction } from "@digits-shared/components/SVGIcons/line/AddTransaction.svg"
import { SvgBank } from "@digits-shared/components/SVGIcons/line/Bank.svg"
import { SvgBuilding02 } from "@digits-shared/components/SVGIcons/line/Building02.svg"
import { SvgCalendarDollar01 } from "@digits-shared/components/SVGIcons/line/CalendarDollar01.svg"
import { SvgCoinsHand } from "@digits-shared/components/SVGIcons/line/CoinsHand.svg"
import { SvgCoinsHand02 } from "@digits-shared/components/SVGIcons/line/CoinsHand02.svg"
import { SvgCreditCard02 } from "@digits-shared/components/SVGIcons/line/CreditCard02.svg"
import { SvgDollarHand } from "@digits-shared/components/SVGIcons/line/DollarHand.svg"
import { SvgDollarRefresh01 } from "@digits-shared/components/SVGIcons/line/DollarRefresh01.svg"
import { SvgHandShakeDollar01 } from "@digits-shared/components/SVGIcons/line/HandShakeDollar01.svg"
import { SvgIou } from "@digits-shared/components/SVGIcons/line/Iou.svg"
import { SvgPackageCheck } from "@digits-shared/components/SVGIcons/line/PackageCheck.svg"
import { SvgPieChart05 } from "@digits-shared/components/SVGIcons/line/PieChart05.svg"
import { SvgRefreshCw05 } from "@digits-shared/components/SVGIcons/line/RefreshCw05.svg"
import { SvgSwitchHorizontal01 } from "@digits-shared/components/SVGIcons/line/SwitchHorizontal01.svg"
import { SvgTag01 } from "@digits-shared/components/SVGIcons/line/Tag01.svg"
import { SvgTagDollar } from "@digits-shared/components/SVGIcons/line/TagDollar.svg"
import { SvgTrendUp01 } from "@digits-shared/components/SVGIcons/line/TrendUp01.svg"
import { SvgUserCoins01 } from "@digits-shared/components/SVGIcons/line/UserCoins01.svg"
import { SvgUsers01 } from "@digits-shared/components/SVGIcons/line/Users01.svg"
import { SvgSearchCustomer } from "src/shared/components/SVG/SearchCustomer.svg"
import { SvgSearchLender } from "src/shared/components/SVG/SearchLender.svg"
import { SvgSearchOwedVendor } from "src/shared/components/SVG/SearchOwedVendor.svg"
import { SvgSearchPrepaidVendor } from "src/shared/components/SVG/SearchPrepaidVendor.svg"
import { SvgSearchShareholder } from "src/shared/components/SVG/SearchShareholder.svg"
import { SvgSearchSupplier } from "src/shared/components/SVG/SearchSupplier.svg"
import { SvgSearchUnpaidCustomer } from "src/shared/components/SVG/SearchUnpaidCustomer.svg"
import { SvgSearchVendor } from "src/shared/components/SVG/SearchVendor.svg"

const QUALIFIED_PREFIX = "com.digits.party.role." as const
type FullyQualifiedPartyRole = `${typeof QUALIFIED_PREFIX}${keyof typeof PartyRole}`

// Canonical source of mapping a PartyRole to its different associated types and strings.
//
// The Order of the fields controls the order in which they appear in the UI - order provided by Yass on 2025-02-26
export class FrontendPartyRole {
  static readonly Customer = new FrontendPartyRole({
    partyRole: PartyRole.EntityCustomerRole,
    productArea: ProductArea.Revenue,
    urlKey: "customer",
    invertValues: false,
    displayName: "Customer",
    pluralDisplayName: "Customers",
    SvgIcon: SvgTagDollar,
    insightType: InsightType.Income,
    SearchSvgIcon: SvgSearchCustomer,
    showsBalanceSummary: false,
  })

  static readonly RevenuePartner = new FrontendPartyRole({
    partyRole: PartyRole.EntityRevenuePartnerRole,
    productArea: ProductArea.All,
    urlKey: "revenue-partner",
    invertValues: false,
    displayName: "Revenue Partner",
    pluralDisplayName: "Revenue Partners",
    SvgIcon: SvgHandShakeDollar01,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Vendor = new FrontendPartyRole({
    partyRole: PartyRole.EntityVendorRole,
    productArea: ProductArea.Expenses,
    urlKey: "vendor",
    invertValues: true,
    displayName: "Vendor",
    pluralDisplayName: "Vendors",
    SvgIcon: SvgBuilding02,
    insightType: InsightType.Expenses,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly AssetVendor = new FrontendPartyRole({
    partyRole: PartyRole.EntityAssetVendorRole,
    productArea: ProductArea.All,
    urlKey: "asset-vendor",
    invertValues: false,
    displayName: "Vendor",
    pluralDisplayName: "Vendors",
    SvgIcon: SvgBuilding02,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly PrepaidVendor = new FrontendPartyRole({
    partyRole: PartyRole.EntityPrepaidVendorRole,
    productArea: ProductArea.All,
    urlKey: "prepaid-vendor",
    invertValues: true,
    displayName: "Prepaid Vendor",
    pluralDisplayName: "Prepaid Vendors",
    SvgIcon: SvgCalendarDollar01,
    insightType: InsightType.AssetsPrepaid,
    SearchSvgIcon: SvgSearchPrepaidVendor,
    showsBalanceSummary: true,
  })

  static readonly Supplier = new FrontendPartyRole({
    partyRole: PartyRole.EntitySupplierRole,
    productArea: ProductArea.Revenue,
    urlKey: "supplier",
    invertValues: true,
    displayName: "Supplier",
    pluralDisplayName: "Suppliers",
    SvgIcon: SvgPackageCheck,
    insightType: InsightType.CostOfGoodsSold,
    SearchSvgIcon: SvgSearchSupplier,
    showsBalanceSummary: false,
  })

  static readonly Asset = new FrontendPartyRole({
    partyRole: PartyRole.EntityAssetRole,
    productArea: ProductArea.All,
    urlKey: "asset",
    invertValues: false,
    displayName: "Supplier",
    pluralDisplayName: "Suppliers",
    SvgIcon: SvgPackageCheck,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly AssetSupplier = new FrontendPartyRole({
    partyRole: PartyRole.EntityAssetSupplierRole,
    productArea: ProductArea.All,
    urlKey: "asset-supplier",
    invertValues: false,
    displayName: "Supplier",
    pluralDisplayName: "Suppliers",
    SvgIcon: SvgPackageCheck,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly OtherIncome = new FrontendPartyRole({
    partyRole: PartyRole.EntityOtherIncomeRole,
    productArea: ProductArea.Revenue,
    urlKey: "other-income",
    invertValues: false,
    displayName: "Other Income Source",
    pluralDisplayName: "Other Income Sources",
    SvgIcon: SvgPieChart05,
    insightType: InsightType.Income,
    SearchSvgIcon: SvgSearchCustomer,
    showsBalanceSummary: false,
  })

  static readonly OtherExpenses = new FrontendPartyRole({
    partyRole: PartyRole.EntityOtherExpensesRole,
    productArea: ProductArea.Expenses,
    urlKey: "other-expenses",
    invertValues: true,
    displayName: "Other Expense Source",
    pluralDisplayName: "Other Expense Sources",
    SvgIcon: SvgAddTransaction,
    insightType: InsightType.Expenses,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly UnpaidCustomer = new FrontendPartyRole({
    partyRole: PartyRole.EntityUnpaidCustomerRole,
    productArea: ProductArea.All,
    urlKey: "receivable",
    invertValues: false,
    displayName: "Unpaid Customer",
    pluralDisplayName: "Unpaid Customers",
    SvgIcon: SvgCoinsHand,
    insightType: InsightType.AssetsReceivable,
    SearchSvgIcon: SvgSearchUnpaidCustomer,
    showsBalanceSummary: true,
  })

  static readonly Debtor = new FrontendPartyRole({
    partyRole: PartyRole.EntityDebtorRole,
    productArea: ProductArea.All,
    urlKey: "debtor",
    invertValues: false,
    displayName: "Debtor",
    pluralDisplayName: "Debtors",
    SvgIcon: SvgDollarHand,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Investee = new FrontendPartyRole({
    partyRole: PartyRole.EntityInvesteeRole,
    productArea: ProductArea.All,
    urlKey: "investee",
    invertValues: false,
    displayName: "Investee",
    pluralDisplayName: "Investees",
    SvgIcon: SvgTrendUp01,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly OwedVendor = new FrontendPartyRole({
    partyRole: PartyRole.EntityOwedVendorRole,
    productArea: ProductArea.All,
    urlKey: "payable",
    invertValues: true,
    displayName: "Owed Vendor",
    pluralDisplayName: "Owed Vendors",
    SvgIcon: SvgCoinsHand02,
    insightType: InsightType.LiabilitiesPayable,
    SearchSvgIcon: SvgSearchOwedVendor,
    showsBalanceSummary: true,
  })

  static readonly Lender = new FrontendPartyRole({
    partyRole: PartyRole.EntityLenderRole,
    productArea: ProductArea.All,
    urlKey: "lender",
    invertValues: false,
    displayName: "Lender",
    pluralDisplayName: "Lenders",
    SvgIcon: SvgIou,
    insightType: InsightType.LiabilitiesLongTerm,
    SearchSvgIcon: SvgSearchLender,
    showsBalanceSummary: true,
  })

  static readonly Liabilities = new FrontendPartyRole({
    partyRole: PartyRole.EntityLiabilitiesRole,
    productArea: ProductArea.All,
    urlKey: "liability",
    invertValues: true,
    displayName: "Lender",
    pluralDisplayName: "Lenders",
    SvgIcon: SvgIou,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly OtherLiabilities = new FrontendPartyRole({
    partyRole: PartyRole.EntityOtherLiabilitiesRole,
    productArea: ProductArea.All,
    urlKey: "other-liabilities",
    invertValues: false,
    displayName: "Other Liability",
    pluralDisplayName: "Other Liabilities",
    SvgIcon: SvgIou,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Shareholder = new FrontendPartyRole({
    partyRole: PartyRole.EntityShareholderRole,
    productArea: ProductArea.All,
    urlKey: "shareholder",
    invertValues: false,
    displayName: "Shareholder",
    pluralDisplayName: "Shareholders",
    SvgIcon: SvgUserCoins01,
    insightType: InsightType.Equity,
    SearchSvgIcon: SvgSearchShareholder,
    showsBalanceSummary: true,
  })

  static readonly Processor = new FrontendPartyRole({
    partyRole: PartyRole.EntityProcessorRole,
    productArea: ProductArea.All,
    urlKey: "processor",
    invertValues: false,
    displayName: "Processor",
    pluralDisplayName: "Processors",
    SvgIcon: SvgSwitchHorizontal01,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly CreditCards = new FrontendPartyRole({
    partyRole: PartyRole.EntityCreditCardsRole,
    productArea: ProductArea.All,
    urlKey: "credit-cards",
    invertValues: false,
    displayName: "Credit Card",
    pluralDisplayName: "Credit Cards",
    SvgIcon: SvgCreditCard02,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Banks = new FrontendPartyRole({
    partyRole: PartyRole.EntityBanksRole,
    productArea: ProductArea.All,
    urlKey: "banks",
    invertValues: false,
    displayName: "Bank",
    pluralDisplayName: "Banks",
    SvgIcon: SvgBank,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly CreditInstitution = new FrontendPartyRole({
    partyRole: PartyRole.InstitutionCreditRole,
    productArea: ProductArea.All,
    urlKey: "credit-institution",
    invertValues: false,
    displayName: "Credit Institution",
    pluralDisplayName: "Credit Institutions",
    SvgIcon: SvgCreditCard02,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly DebitInstitution = new FrontendPartyRole({
    partyRole: PartyRole.InstitutionDebitRole,
    productArea: ProductArea.All,
    urlKey: "debit-institution",
    invertValues: false,
    displayName: "Debit Institution",
    pluralDisplayName: "Debit Institutions",
    SvgIcon: SvgBank,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Facilitator = new FrontendPartyRole({
    partyRole: PartyRole.FacilitatorRole,
    productArea: ProductArea.All,
    urlKey: "facilitator",
    invertValues: false,
    displayName: "Facilitator",
    pluralDisplayName: "Facilitator",
    SvgIcon: SvgDollarRefresh01,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly PaymentProcessor = new FrontendPartyRole({
    partyRole: PartyRole.PaymentProcessorRole,
    productArea: ProductArea.All,
    urlKey: "payment-processor",
    invertValues: false,
    displayName: "Payment Processor",
    pluralDisplayName: "Payment Processors",
    SvgIcon: SvgRefreshCw05,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Owner = new FrontendPartyRole({
    partyRole: PartyRole.OwnerRole,
    productArea: ProductArea.All,
    urlKey: "owner",
    invertValues: false,
    displayName: "Owner",
    pluralDisplayName: "Owners",
    SvgIcon: SvgUsers01,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Unspecified = new FrontendPartyRole({
    partyRole: PartyRole.EntityUnspecifiedRole,
    productArea: ProductArea.All,
    urlKey: "unspecified",
    invertValues: false,
    displayName: "Unspecified",
    pluralDisplayName: "Unspecified",
    SvgIcon: SvgTag01,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })

  static readonly Unknown = new FrontendPartyRole({
    partyRole: PartyRole.UnknownRole,
    productArea: ProductArea.All,
    urlKey: "unknown",
    invertValues: false,
    displayName: "Unknown",
    pluralDisplayName: "Unknown",
    SvgIcon: SvgTag01,
    SearchSvgIcon: SvgSearchVendor,
    showsBalanceSummary: false,
  })
  readonly partyRole: PartyRole
  readonly productArea: ProductArea
  readonly urlKey: string
  readonly invertValues: boolean
  readonly displayName: string
  readonly pluralDisplayName: string
  readonly SvgIcon: React.FC
  readonly insightType?: InsightType
  readonly SearchSvgIcon: React.FC
  readonly showsBalanceSummary: boolean

  constructor({
    partyRole,
    productArea,
    urlKey,
    invertValues,
    displayName,
    pluralDisplayName,
    SvgIcon,
    insightType,
    SearchSvgIcon,
    showsBalanceSummary,
  }: {
    partyRole: PartyRole
    productArea: ProductArea
    urlKey: string
    invertValues: boolean
    displayName: string
    pluralDisplayName: string
    SvgIcon: React.FC
    insightType?: InsightType
    SearchSvgIcon: React.FC
    showsBalanceSummary: boolean
  }) {
    this.partyRole = partyRole
    this.productArea = productArea
    this.urlKey = urlKey
    this.invertValues = invertValues
    this.displayName = displayName
    this.pluralDisplayName = pluralDisplayName
    this.SvgIcon = SvgIcon
    this.insightType = insightType
    this.SearchSvgIcon = SearchSvgIcon
    this.showsBalanceSummary = showsBalanceSummary
  }

  static all(): FrontendPartyRole[] {
    return Object.values(FrontendPartyRole)
  }

  static findByURLKey(urlKey?: string): FrontendPartyRole {
    return this.all().find((role) => role.urlKey === urlKey) || FrontendPartyRole.Unknown
  }

  static findByRole(partyRole?: PartyRole | null): FrontendPartyRole {
    return this.all().find((role) => role.partyRole === partyRole) || FrontendPartyRole.Unknown
  }

  static findByFullyQualifiedPartyRole(fullyQualifiedPartyRole?: string | null) {
    const role =
      (fullyQualifiedPartyRole?.substring(QUALIFIED_PREFIX.length) as PartyRole) ||
      PartyRole.UnknownRole

    return FrontendPartyRole.findByRole(
      fullyQualifiedPartyRole?.indexOf(QUALIFIED_PREFIX) === 0
        ? PartyRole[role]
        : PartyRole.UnknownRole
    )
  }

  static findByInsightType(insightType?: InsightType): FrontendPartyRole {
    return this.all().find((role) => role.insightType === insightType) || FrontendPartyRole.Unknown
  }

  static displayStringForRole(role?: PartyRole, plural = false) {
    const feRole = FrontendPartyRole.findByRole(role)
    return plural ? feRole.pluralDisplayName : feRole.displayName
  }

  static roleURLKey(role?: PartyRole | null): string {
    return FrontendPartyRole.findByRole(role).urlKey
  }

  static rolesForProductArea(productArea: ProductArea) {
    switch (productArea) {
      case ProductArea.All:
      case ProductArea.None:
      case ProductArea.Expenses:
        return [PartyRole.EntityVendorRole]
      case ProductArea.Cash:
        return [PartyRole.EntityCustomerRole, PartyRole.EntityVendorRole]
      case ProductArea.Revenue:
        return [PartyRole.EntityCustomerRole, PartyRole.EntitySupplierRole]
    }
  }

  static roleDisplayStringForProductArea(productArea: ProductArea, plural: boolean = false) {
    return FrontendPartyRole.displayStringForRole(
      FrontendPartyRole.rolesForProductArea(productArea)[0],
      plural
    )
  }

  static fullyQualifiedPartyRole(pr?: PartyRole): FullyQualifiedPartyRole | undefined {
    if (pr) {
      return `${QUALIFIED_PREFIX}${pr}`
    }
    return undefined
  }

  static extractPartyDetailsParams(s?: string | null) {
    const { partyRole } = FrontendPartyRole.findByFullyQualifiedPartyRole(s)
    return {
      partyRole,
    }
  }
}

const UNSUPPORTED_PARTY_ROLES: Set<FrontendPartyRole> = new Set([
  FrontendPartyRole.Processor,
  FrontendPartyRole.Banks,
  FrontendPartyRole.CreditCards,
])

export const SUPPORTED_FRONTEND_PARTY_ROLES = FrontendPartyRole.all().filter(
  (r) => !UNSUPPORTED_PARTY_ROLES.has(r)
)

export const SUPPORTED_PARTY_ROLES: PartyRole[] = SUPPORTED_FRONTEND_PARTY_ROLES.map(
  (fRole) => fRole.partyRole
)
export const SUPPORTED_PARTY_ROLES_URL_KEYS: string[] = SUPPORTED_FRONTEND_PARTY_ROLES.map(
  (fRole) => fRole.urlKey
)
