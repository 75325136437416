import {
  type Date as GQLDate,
  DirectionFromOrigin,
  Interval,
  type IntervalOrigin,
  type Period,
} from "@digits-graphql/frontend/graphql-bearer"
import { type RouteParameters } from "@digits-shared/components/Router/DigitsRoute"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { exists } from "@digits-shared/helpers/typehelper"
import dateParser from "any-date-parser"
import dayjs, { type Dayjs } from "@digits-shared/initializers/dayjs/dayjs"

export const INTERVAL_NOT_SUPPORTED_MESSAGE = "Interval is not supported"

export enum DateFormat {
  Short = "Short",
  Default = "Default",
  Micro = "Micro",
  Tiny = "Tiny",
  Medium = "Medium",
}

interface DateFormatOpts {
  day: string
  week: string
  month: string
  quarter: string
  year: string
}

export enum RouteDefaultIntervalOrigin {
  PreviousMonth = "PreviousMonth",
  CurrentMonth = "CurrentMonth",
  AtLeast15Days = "AtLeast15Days",
  Today = "Today",
}

export interface IntervalFromRouteOptions {
  interval: Interval
  defaultIntervalOrigin?: RouteDefaultIntervalOrigin
  allowFutureDates?: boolean
}

const DateFormats = new Map<DateFormat, DateFormatOpts>()
DateFormats.set(DateFormat.Default, {
  day: "MMMM D, YYYY",
  week: "WW",
  month: "MMMM YYYY",
  quarter: "Q YYYY",
  year: "YYYY",
})
DateFormats.set(DateFormat.Micro, {
  day: "D",
  week: "W",
  month: "MMM",
  quarter: "Q",
  year: "'YY",
})
DateFormats.set(DateFormat.Tiny, {
  day: "M/D",
  week: "WW",
  month: "MMM",
  quarter: "Q",
  year: "'YY",
})
DateFormats.set(DateFormat.Short, {
  day: "MMM D",
  week: "WW",
  month: "MMMM",
  quarter: "Q",
  year: "YYYY",
})
DateFormats.set(DateFormat.Medium, {
  day: "M/D/YYYY",
  week: "WW",
  month: "MMM YYYY",
  quarter: "Q YYYY",
  year: "YYYY",
})

export interface PeriodToOriginProps {
  startedAt: number
  endedAt?: number
  interval: Interval
}

export interface RangeFormatOptions {
  reverseChronological?: boolean
  fullFormatString?: string
  delimiter?: string
  monthFormatString?: string
}

export type TimeRange = Omit<Period, "name">

export interface DayjsRange {
  from: Dayjs
  to: Dayjs
}

export default {
  /**
   * Turns the provided IntervalOrigin into a corresponding set of params for use in generating
   * a route path.
   */
  pathParamsFromIntervalOrigin(intervalOrigin: IntervalOrigin): Record<string, string> {
    const params: Record<string, string> = {
      year: intervalOrigin.year.toString(),
      interval: intervalOrigin.interval,
      index: intervalOrigin.index.toString(),
    }
    if (intervalOrigin.intervalCount) {
      params.intervalCount = intervalOrigin.intervalCount.toString()
    }
    return params
  },

  /**
   * Format the display name from a date {@link Dayjs}
   */
  displayNameFromDayjs(
    date: Dayjs,
    interval: Interval,
    dateFormat: DateFormat = DateFormat.Default
  ) {
    const format = DateFormats.get(dateFormat) || DateFormats.get(DateFormat.Default)
    if (!format) throw new Error("Unsupported DateFormat")

    switch (interval) {
      case Interval.Day:
        return date.format(format.day)
      case Interval.Week:
        return `Week ${date.format(format.week)}`
      case Interval.Month:
        return date.format(format.month)
      case Interval.Quarter:
        return `Q${date.format(format.quarter)}`
      case Interval.Year:
        return date.format(format.year)
      case Interval.IntervalNone:
        return date.format(format.month)
      default:
        return date.format(format.day)
    }
  },

  /**
   * Format the display name from an unix {@link timestamp}
   */
  displayNameFromUnixTimestamp(
    timestamp: number,
    interval: Interval,
    dateFormat: DateFormat = DateFormat.Default
  ) {
    return this.displayNameFromDayjs(dayjs.unix(timestamp).utc(), interval, dateFormat)
  },

  /**
   * Counts the intervals between two unix timestamps.
   *
   * This count is inclusive, ie May 2023 - May 2023 is 1 interval.
   */
  intervalCount(endedAt: number, startedAt: number, interval: Interval) {
    const diff = dayjs
      .unix(endedAt)
      .utc()
      .diff(dayjs.unix(startedAt).utc(), this.unitOfTimeForInterval(interval))

    return Math.abs(diff) + 1
  },

  /**
   * Converts an {@link Interval} to an unit of time.
   *
   * @param {@link Interval} interval to be converted
   */
  unitOfTimeForInterval(interval: Interval): dayjs.ManipulateType {
    switch (interval) {
      case Interval.Minute:
        return "minute"
      case Interval.Hour:
        return "hour"
      case Interval.Day:
        return "day"
      case Interval.Week:
        return "week"
      case Interval.Month:
        return "month"
      case Interval.Quarter:
        return "quarter" as dayjs.ManipulateType
      case Interval.Year:
        return "year"
    }
    throw new Error(`${interval} not supported.`)
  },

  /**
   * Abbreviates an {@link Interval}
   */
  intervalAbbrName(interval: Interval): string {
    switch (interval) {
      case Interval.Minute:
        return "min"
      case Interval.Hour:
        return "hr"
      case Interval.Day:
        return "day"
      case Interval.Week:
        return "wk"
      case Interval.Month:
        return "mo"
      case Interval.Quarter:
        return "qtr"
      case Interval.Year:
        return "yr"
    }
    throw new Error(`${interval} not supported.`)
  },

  /**
   * Format the display name from a {@link Period}
   *
   * @param {@link Period} period to be converted to display name
   */
  displayNameFromPeriod(period: PeriodToOriginProps, dateFormat: DateFormat = DateFormat.Default) {
    return this.displayNameFromUnixTimestamp(period.startedAt, period.interval, dateFormat)
  },

  /**
   * Format the display name from a {@link Date}
   *
   * @param {@link GQLDate} date
   * @param {@link Interval} interval to choose layout
   * @param {@link DateFormat} dateFormat Format of date
   */
  displayNameFromDate(
    gqlDate: GQLDate,
    interval: Interval,
    dateFormat: DateFormat = DateFormat.Default
  ) {
    return this.displayNameFromDayjs(this.dayjsFromGQLDate(gqlDate), interval, dateFormat)
  },

  /**
   * Format the display name from an {@link IntervalOrigin}
   *
   * @param {@link IntervalOrigin} intervalOrigin to be converted to display name
   * @param {@link DateFormat} dateFormat Format of date
   */
  displayNameFromIntervalOrigin(
    intervalOrigin: IntervalOrigin,
    dateFormat: DateFormat = DateFormat.Default
  ) {
    if (!intervalOrigin.intervalCount || intervalOrigin.intervalCount < 2) {
      const date = this.dayjsFromIntervalOrigin(intervalOrigin)
      return this.displayNameFromDayjs(date, intervalOrigin.interval, dateFormat)
    }
    const range = this.dayjsRangeFromIntervalOrigin(intervalOrigin)
    return this.displayNameForDayjsRange(range, intervalOrigin.interval)
  },

  /**
   * Return the display name of an {@link IntervalOrigin} Interval
   *
   * @param {@link IntervalOrigin} interval to be converted to display name
   * @param intervalCount optional value to determine if the interval should be pluralized
   */
  displayNameForInterval(interval: Interval, intervalCount?: number) {
    const plural = intervalCount === undefined || intervalCount === 1 ? "" : "s"

    switch (interval) {
      case Interval.Minute:
        return `minute${plural}`
      case Interval.Hour:
        return `hour${plural}`
      case Interval.Day:
        return `day${plural}`
      case Interval.Week:
        return `week${plural}`
      case Interval.Biweek:
        return `fortnight${plural}`
      case Interval.Month:
        return `month${plural}`
      case Interval.Quarter:
        return `quarter${plural}`
      case Interval.Year:
        return `year${plural}`
      case Interval.IntervalNone:
        return "none"
      default:
        throw new Error(`${interval} not supported.`)
    }
  },

  /**
   * Return the display name of an {@link IntervalOrigin} Interval in an adverb form
   *
   * @param {@link IntervalOrigin} interval to be converted to display name
   */
  displayNameForIntervalAdverb(interval: Interval) {
    switch (interval) {
      case Interval.Hour:
        return "hourly"
      case Interval.Day:
        return "daily"
      case Interval.Week:
        return "weekly"
      case Interval.Biweek:
        return "biweekly"
      case Interval.Month:
        return "monthly"
      case Interval.Quarter:
        return "quarterly"
      case Interval.Year:
        return "yearly"
      case Interval.IntervalNone:
        return "none"
      default:
        throw new Error(`${interval} not supported.`)
    }
  },

  displayNameFromRange(range: TimeRange, delimiter: string = " to ") {
    return this.displayNameForTimeRange(range.startedAt, range.endedAt, range.interval, {
      delimiter,
    })
  },

  /**
   * Return the display name of a dayjs range
   *
   */
  displayNameForDayjsRange(
    { from: originalFrom, to: originalTo }: DayjsRange,
    interval: Interval,
    options?: RangeFormatOptions
  ) {
    const {
      reverseChronological = false,
      fullFormatString,
      delimiter = " - ",
      monthFormatString = "MMMM",
    } = options || {}

    const from = reverseChronological ? originalTo : originalFrom
    const to = reverseChronological ? originalFrom : originalTo

    const displayFrom = fullFormatString
      ? from.format(fullFormatString)
      : this.displayNameFromDayjs(from, interval, DateFormat.Default)
    const displayTo = fullFormatString
      ? to.format(fullFormatString)
      : this.displayNameFromDayjs(to, interval, DateFormat.Default)

    switch (interval) {
      case Interval.Year:
        if (from.year() === to.year()) {
          return displayFrom
        }
        return `${displayFrom}${delimiter}${displayTo}`
      case Interval.Quarter:
        if (from.isSame(to, "quarter")) {
          return displayFrom
        }
        if (from.isSame(to, "year")) {
          return `${this.displayNameFromDayjs(
            from,
            interval,
            DateFormat.Short
          )}${delimiter}${displayTo}`
        }
        return `${displayFrom}${delimiter}${displayTo}`
      case Interval.Month:
        if (from.isSame(to, "month")) {
          return displayFrom
        }
        if (from.isSame(to, "year")) {
          const displayToSameYear = fullFormatString
            ? to.format(fullFormatString)
            : to.format(`${monthFormatString}, YYYY`)
          return `${from.format(monthFormatString)}${delimiter}${displayToSameYear}`
        }
        return `${displayFrom}${delimiter}${displayTo}`
      case Interval.Day:
        if (from === to) {
          return displayFrom
        }
        if (from.isSame(to, "month")) {
          return `${from.format(`${monthFormatString} D`)}-${to.format("D, YYYY")}`
        }
        if (from.isSame(to, "year")) {
          return `${from.format(`${monthFormatString} D`)}-${to.format(`${monthFormatString} D, YYYY`)}`
        }
        return `${displayFrom}${delimiter}${displayTo}`
      default:
        return `${displayFrom}${delimiter}${displayTo}`
    }
  },

  /**
   * Return the display name of a range
   *
   */
  displayNameForIntervalOriginRange(intervalOrigin: IntervalOrigin, options?: RangeFormatOptions) {
    const { interval } = intervalOrigin
    return this.displayNameForDayjsRange(
      this.dayjsRangeFromIntervalOrigin(intervalOrigin),
      interval,
      options
    )
  },

  /**
   * Return the display name of a time range
   *
   */
  displayNameForTimeRange(
    startedAt: number,
    endedAt: number,
    interval: Interval,
    options?: RangeFormatOptions
  ) {
    const from = dayjs.unix(Math.min(startedAt, endedAt)).utc()
    const to = dayjs.unix(Math.max(startedAt, endedAt)).utc()
    return this.displayNameForDayjsRange({ from, to }, interval, options)
  },

  /**
   * Return the display name of an interval count and {@link IntervalOrigin} Interval
   * e.g. 100 days
   * @param {@link IntervalOrigin} interval to be converted to display name
   * @param intervalCount optional value to determine if the interval should be pluralized
   */
  displayNameForIntervalCount(interval: Interval, intervalCount: number) {
    const intervalName = this.displayNameForInterval(interval, intervalCount)
    return `${intervalCount} ${intervalName}`
  },

  coerceDate(date: GQLDate): GQLDate {
    // The `Date` type this method accepts is from our backend,
    // which 1-indexes the months, so coerce this month down by
    // one to move it to the dayjs-expected value
    return {
      year: date.year,
      month: date.month - 1,
      day: date.day,
    }
  },

  coerceDateFromDayjs(date: Dayjs): GQLDate {
    const djs = date
    return {
      year: djs.year(),
      month: djs.month() + 1,
      day: djs.date(),
    }
  },

  coerceDateFromIntervalOrigin(intervalOrigin: IntervalOrigin): GQLDate {
    const djs = this.dayjsFromIntervalOrigin(intervalOrigin)
    return this.coerceDateFromDayjs(djs)
  },

  coerceDateFromUnixTimestamp(unixTimestamp: number): GQLDate {
    return this.coerceDateFromDayjs(dayjs.unix(unixTimestamp).utc())
  },

  coerceDateFromString(dateString: string): GQLDate | undefined {
    const date = this.parseDateFromString(dateString)
    if (!date) return undefined
    return this.coerceDateFromDayjs(date)
  },

  coerceDateFromJSDate(date: Date): GQLDate {
    return this.coerceDateFromDayjs(dayjs(date))
  },

  parseDateFromString(dateString: string): Dayjs | undefined {
    const date = dateParser.fromString(dateString, "en-US")
    if (!date.isValid()) return undefined
    return dayjs.utc(date).startOf("day")
  },

  /**
   * Converts UTC Dayjs To Local Date ignoring Timezone
   */
  dayjsToLocalDate(djs: Dayjs): Date {
    const from = djs.utc().toDate()
    return new Date(
      from.getUTCFullYear(),
      from.getUTCMonth(),
      from.getUTCDate(),
      from.getUTCHours(),
      from.getUTCMinutes(),
      from.getUTCSeconds()
    )
  },

  /**
   * Returns the relative number of intervals in a string
   *
   * @param {@link Interval} interval
   * @param {number} intervalCount
   * @param {boolean} intervalEndsAtPresentDay
   */
  intervalsAgo(interval: Interval, intervalCount: number, intervalEndsAtPresentDay: boolean) {
    let formattedCount = intervalCount || 0
    const intervalName = this.displayNameForInterval(interval, intervalCount)

    switch (interval) {
      case Interval.Day:
        if (formattedCount < 0) {
          formattedCount = (dayjs().utc().isLeapYear() ? 366 : 365) + formattedCount
        }
        if (formattedCount === 0) {
          return "Today"
        }
        break
      case Interval.Week:
        if (formattedCount < 0) {
          formattedCount = dayjs().utc().isoWeeksInYear() + formattedCount
        }
        if (formattedCount === 0) {
          return "This week"
        }
        break
      case Interval.Month:
        if (formattedCount < 0) {
          formattedCount = 12 + formattedCount
        }
        if (formattedCount === 0) {
          return "This month"
        }
        break
      case Interval.Quarter:
        if (formattedCount < 0) {
          formattedCount = 4 + formattedCount
        }
        if (formattedCount === 0) {
          return "This quarter"
        }
        break
      case Interval.Year:
        if (formattedCount < 0) {
          throw new Error("Negative years")
        }
        break
      case Interval.IntervalNone:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${interval}`)
    }

    return `${intervalEndsAtPresentDay ? "Last" : "Prior"} ${formattedCount} ${intervalName}`
  },

  /**
   * Set default active interval origin. For interval `Month` or when no interval is specified, this
   * will be the current year and previous month. Otherwise it will be today all other intervals.
   *
   * @return {@link IntervalOrigin}
   */
  defaultIntervalOrigin(interval?: Interval): IntervalOrigin {
    if (interval && interval !== Interval.Month) return this.todayIntervalOrigin(interval)

    const currentDate = dayjs().utc()

    // If the current day is before this day, return the previous month, otherwise the current month.
    const dayOfMonthCutoff = 15

    // dayjs's month() is 0 for January, but we want to return 1 in that case.
    const month = currentDate.month() + (currentDate.date() > dayOfMonthCutoff ? 1 : 0)
    // previous year
    const year = currentDate.year() - (month < 1 ? 1 : 0)
    const index = month < 1 ? 12 : month

    return {
      year,
      index,
      interval: Interval.Month,
    }
  },

  /**
   * Derive an interval origin by first looking at what parameters exist in the route properties.
   * Will use default if parameters are not present or invalid.
   *
   * @param {RouteParameters} params Params that contain time context pieces
   * @param {IntervalFromRouteOptions?} options? Should use the today origin instead of the default
   * @return {@link IntervalOrigin}
   */
  intervalOriginFromRoute(
    params: RouteParameters = {},
    defaultOrigin: IntervalOrigin,
    options: IntervalFromRouteOptions | undefined = undefined
  ): IntervalOrigin {
    const year = parseInt(params.year as string, 10)

    let index = parseInt(params.index as string, 10)

    const interval = params.interval
      ? Interval[stringHelper.capitalize(params.interval.toString()) as Interval]
      : undefined

    const intervalCount =
      parseInt(params.intervalCount as string, 10) || defaultOrigin.intervalCount

    if (!year || !index || !interval) return defaultOrigin

    // Don't allow requesting any future years
    if (year > dayjs().utc().year() && !options?.allowFutureDates) {
      return defaultOrigin
    }

    switch (interval) {
      case Interval.Day: {
        const todayOrigin = this.todayIntervalOrigin(interval)
        const daysInYear = dayjs().utc().year(year).isLeapYear() ? 366 : 365
        // Use default if index is greater than number of days in the year or less than 1
        if (index > daysInYear || index < 1) {
          return defaultOrigin
        }
        // If its the today year and index (day) is greater than the today day, set to default
        if (year === todayOrigin.year && index > todayOrigin.index && !options?.allowFutureDates) {
          return defaultOrigin
        }
        break
      }

      case Interval.Week: {
        const todayOrigin = this.todayIntervalOrigin(interval)
        // Use default if index is greater than 53 or less than 1
        if (index > 53 || index < 1) {
          return defaultOrigin
        }

        // If its the today year and index (week) is greater than the today week, set to default
        if (year === todayOrigin.year && index > todayOrigin.index && !options?.allowFutureDates) {
          return defaultOrigin
        }
        break
      }

      case Interval.Month: {
        const todayOrigin = this.todayIntervalOrigin(interval)
        // Use default if index is greater than 12 or less than 1
        if (index > 12 || index < 1) {
          return defaultOrigin
        }

        // If its the today year and index (month) is greater than the today month, set to default
        if (year === todayOrigin.year && index > todayOrigin.index && !options?.allowFutureDates) {
          return defaultOrigin
        }
        break
      }

      case Interval.Quarter: {
        const todayOrigin = this.todayIntervalOrigin(interval)
        // Use default if index is greater than 4 or less than 1
        if (index > 4 || index < 1) {
          return defaultOrigin
        }

        // If its the today year and index (quarter) is greater than the today month, set to default
        if (year === todayOrigin.year && index > todayOrigin.index && !options?.allowFutureDates) {
          return defaultOrigin
        }
        break
      }

      case Interval.Year:
        // Ensure the index is the year
        if (index !== year) {
          index = year
        }
        break

      default:
        console.warn(`Interval ${interval} not supported yet. Reverting to default.`)
        return defaultOrigin
    }

    return {
      interval,
      index,
      year,
      intervalCount,
    }
  },

  defaultIntervalOriginFromRoute(
    routeDefaultIntervalOrigin: RouteDefaultIntervalOrigin | undefined,
    interval?: Interval
  ): IntervalOrigin {
    switch (routeDefaultIntervalOrigin) {
      case RouteDefaultIntervalOrigin.PreviousMonth: {
        const previousMonth = dayjs().utc().subtract(1, "month")

        return {
          year: previousMonth.year(),
          index: previousMonth.month() + 1,
          interval: Interval.Month,
        }
      }

      case RouteDefaultIntervalOrigin.CurrentMonth: {
        const currentMonth = dayjs().utc()
        return {
          year: currentMonth.year(),
          index: currentMonth.month() + 1,
          interval: Interval.Month,
        }
      }

      case RouteDefaultIntervalOrigin.Today:
        return this.todayIntervalOrigin(interval ?? Interval.Month)

      // DEFAULT
      case RouteDefaultIntervalOrigin.AtLeast15Days:
      case undefined:
        if (interval && interval !== Interval.Month) return this.todayIntervalOrigin(interval)
        return this.defaultIntervalOrigin(Interval.Month)
    }
  },

  /**
   * Determines if a route params are valid interval origin properties.
   *
   * @param {RouteParameters} params Params that contain time context pieces
   */
  isValidIntervalOriginInRoute(params: RouteParameters) {
    const interval = params.interval
      ? Interval[stringHelper.capitalize(params.interval.toString()) as Interval]
      : undefined

    return (
      !!interval &&
      !!parseInt(params.year as string, 10) &&
      !!parseInt(params.index as string, 10) &&
      (!params.intervalCount || !!parseInt(params.intervalCount as string, 10))
    )
  },

  /**
   * Create an `IntervalOrigin` from a `Period`
   *
   * @param {@link Period} period
   * @param {@link number} intervalCount
   * @return {@link IntervalOrigin}
   */
  intervalOriginFromPeriod(period: PeriodToOriginProps, intervalCount?: number): IntervalOrigin {
    const date = dayjs.unix(period.startedAt).utc()

    let index: number
    switch (period.interval) {
      case Interval.Day:
        index = date.dayOfYear()
        break
      case Interval.Week:
        index = date.isoWeek()
        break
      case Interval.Month:
        index = date.month() + 1
        break
      case Interval.Quarter:
        index = date.quarter()
        break
      case Interval.Year:
        index = date.year()
        break
      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${period.interval}`)
    }

    return {
      index,
      intervalCount,
      interval: period.interval,
      year: date.year(),
    }
  },

  /**
   * Create an `IntervalOrigin` from a `Period` range
   *
   * `intervalOriginFromPeriod` should be this implementation but there are so many places misusing it
   * (intervalCount assumed undefined if not passed in, instead of 1)
   *
   * @param {@link Period} period
   * @param {@link number} intervalCount
   * @return {@link IntervalOrigin}
   */
  intervalOriginFromPeriodRange(period: TimeRange, allowFutureDates = false): IntervalOrigin {
    const now = dayjs().utc().unix()
    const endTime = allowFutureDates ? period.endedAt : Math.min(now, period.endedAt)
    const endDate = dayjs.unix(endTime).utc()
    const intervalCount = this.intervalCount(endTime, period.startedAt, period.interval)

    let index: number
    switch (period.interval) {
      case Interval.Day:
        index = endDate.dayOfYear()
        break
      case Interval.Week:
        index = endDate.isoWeek()
        break
      case Interval.Month:
        index = endDate.month() + 1
        break
      case Interval.Quarter:
        index = endDate.quarter()
        break
      case Interval.Year:
        index = endDate.year()
        break
      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${period.interval}`)
    }

    return {
      index,
      intervalCount,
      interval: period.interval,
      year: endDate.year(),
    }
  },

  intervalOriginFromRange(range: TimeRange, intervalCount?: number): IntervalOrigin {
    const defaultIntervalCount = this.intervalCount(range.endedAt, range.startedAt, range.interval)
    return this.intervalOriginFromDayjs(
      dayjs.unix(range.endedAt).utc(),
      range.interval,
      intervalCount ?? defaultIntervalCount
    )
  },
  /**
   * Create an `IntervalOrigin` from a `Date`
   *
   * @param {@link GQLDate} gqlDate
   * @param {@link Interval} interval
   * @param {@link boolean} includeIntervalCount
   * @return {@link IntervalOrigin}
   */
  intervalOriginFromDate(
    gqlDate: GQLDate,
    interval: Interval,
    includeIntervalCount: boolean | number = true
  ): IntervalOrigin {
    const date = this.dayjsFromGQLDate(gqlDate).utc()
    return this.intervalOriginFromDayjs(date, interval, includeIntervalCount)
  },

  /**
   * Create an `IntervalOrigin` from a `Dayjs`
   *
   * @param {@link dayjs.Dayjs} date
   * @param {@link Interval} interval
   * @param {@link boolean} includedIntervalCount
   * @return {@link IntervalOrigin}
   */
  intervalOriginFromDayjs(
    date: dayjs.Dayjs,
    interval: Interval,
    includedIntervalCount: boolean | number = true
  ): IntervalOrigin {
    let index: number
    switch (interval) {
      case Interval.Day:
        index = date.dayOfYear()
        break
      case Interval.Week:
        // Starts Monday
        index = date.isoWeek()
        break
      case Interval.Month:
        index = date.month() + 1
        break
      case Interval.Quarter:
        index = date.quarter()
        break
      case Interval.Year:
        index = date.year()
        break
      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${interval}`)
    }

    const origin: IntervalOrigin = {
      index,
      interval,
      year: date.year(),
    }
    if (includedIntervalCount) {
      if (typeof includedIntervalCount === "boolean") {
        origin.intervalCount = 1
      } else {
        origin.intervalCount = includedIntervalCount
      }
    }

    return origin
  },

  /**
   * Create an `IntervalOrigin` from today's date
   * @param {Interval} interval
   * @param {number} intervalCount
   * @return {@link IntervalOrigin}
   */
  todayIntervalOrigin(interval: Interval, intervalCount?: number): IntervalOrigin {
    const period = {
      interval,
      // TODO: Update once we support legal entity timezone
      startedAt: dayjs().utc().unix(),
    }
    return this.intervalOriginFromPeriod(period, intervalCount)
  },

  /**
   * Create a `dayjs.Dayjs` from the end of an interval origin
   * @param {IntervalOrigin} intervalOrigin
   * @return {@link dayjs.Dayjs}
   */
  endOfIntervalOrigin(intervalOrigin: IntervalOrigin): dayjs.Dayjs {
    const intervalDate = this.dayjsFromIntervalOrigin(intervalOrigin)
    switch (intervalOrigin.interval) {
      case Interval.Day:
        return intervalDate.endOf("day").utc()
      case Interval.Week:
        return intervalDate.endOf("isoWeek").utc()
      case Interval.Month:
        return intervalDate.endOf("month").utc()
      case Interval.Year:
        return intervalDate.endOf("year").utc()
      case Interval.Quarter:
        return intervalDate.endOf("quarter").utc()
      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${intervalOrigin.interval}`)
    }
  },

  /**
   * Create a `dayjs.Dayjs` from the start of an interval origin
   * @param {IntervalOrigin} intervalOrigin
   * @return {@link dayjs.Dayjs}
   */
  startOfIntervalOrigin(intervalOrigin: IntervalOrigin): dayjs.Dayjs {
    const intervalDate = this.dayjsFromIntervalOrigin(intervalOrigin)
    switch (intervalOrigin.interval) {
      case Interval.Day:
        return intervalDate.subtract(intervalOrigin.intervalCount ?? 0, "day").utc()
      case Interval.Week:
        return intervalDate.subtract(intervalOrigin.intervalCount ?? 0, "week").utc()
      case Interval.Month:
        return intervalDate.subtract(intervalOrigin.intervalCount ?? 0, "month").utc()
      case Interval.Year:
        return intervalDate.subtract(intervalOrigin.intervalCount ?? 0, "year").utc()
      case Interval.Quarter:
        return intervalDate.subtract(intervalOrigin.intervalCount ?? 0, "quarter").utc()
      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${intervalOrigin.interval}`)
    }
  },

  /**
   * Converts a {@link number} timestamp into a {@link Period}
   *
   * @param {@link number} timestamp
   * @param {@link Interval} interval
   * @return {@link Period}
   */
  periodFromTimestamp(timestamp: number, interval: Interval): Period {
    return this.periodFromDayjs(dayjs.unix(timestamp).utc(), interval)
  },

  /**
   * Converts a {@link dayjs.Dayjs} date into a {@link Period}
   *
   * @param {@link dayjs.Dayjs} date
   * @param {@link Interval} interval
   * @return {@link Period}
   */
  periodFromDayjs(date: dayjs.Dayjs, interval: Interval): Period {
    switch (interval) {
      case Interval.Day:
        return {
          interval,
          startedAt: date.startOf("day").unix(),
          endedAt: date.endOf("day").unix(),
          name: date.format("dddd, M/D"),
        }
      case Interval.Week:
        return {
          interval,
          startedAt: date.startOf("isoWeek").unix(),
          endedAt: date.endOf("isoWeek").unix(),
          name: `Week ${date.format("WW")}`,
        }
      case Interval.Month:
        return {
          interval,
          startedAt: date.startOf("month").unix(),
          endedAt: date.endOf("month").unix(),
          name: date.format("MMMM"),
        }
      case Interval.Year:
        return {
          interval,
          startedAt: date.startOf("year").unix(),
          endedAt: date.endOf("year").unix(),
          name: date.format("YYYY"),
        }
      case Interval.Quarter:
        return {
          interval,
          startedAt: date.startOf("quarter").unix(),
          endedAt: date.endOf("quarter").unix(),
          name: date.format("[Q]Q"),
        }
      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${interval}`)
    }
  },

  /**
   * Converts a {@link IntervalOrigin} intervalOrigin into a {@link Period}
   *
   * @param {@link IntervalOrigin} intervalOrigin
   * @param {@link DirectionFromOrigin} direction
   * @return {@link Period}
   */
  periodFromIntervalOrigin(
    intervalOrigin: IntervalOrigin,
    direction: DirectionFromOrigin = DirectionFromOrigin.Past
  ): Period {
    const periods = this.periodsFromIntervalOrigin(intervalOrigin, direction)

    switch (periods.length) {
      case 0:
        throw new Error(`Invalid interval origin ${JSON.stringify(intervalOrigin)}`)
      case 1: {
        const period = periods[0]
        if (!exists(period)) {
          throw new Error(`Invalid interval origin ${JSON.stringify(intervalOrigin)}`)
        }
        return period
      }
      default: {
        const startPeriod = periods[periods.length - 1]
        const endPeriod = periods[0]
        if (!exists(startPeriod) || !exists(endPeriod)) {
          throw new Error(`Invalid interval origin ${JSON.stringify(intervalOrigin)}`)
        }

        const name = `${this.displayNameFromPeriod(startPeriod)} - ${this.displayNameFromPeriod(
          endPeriod
        )}`

        return {
          ...startPeriod,
          name,
          endedAt: endPeriod.endedAt,
        }
      }
    }
  },

  /**
   * Converts an interval described by a {@link IntervalOrigin} intervalOrigin into a list of {@link Period} periods
   *
   * @param {@link IntervalOrigin} intervalOrigin
   * @param {@link DirectionFromOrigin} direction
   * @return {@link Period[]}
   */
  periodsFromIntervalOrigin(
    intervalOrigin: IntervalOrigin,
    direction: DirectionFromOrigin
  ): Period[] {
    const { intervalCount, interval } = intervalOrigin
    const djs = this.dayjsFromIntervalOrigin(intervalOrigin)
    if (!intervalOrigin.intervalCount) {
      return [this.periodFromDayjs(djs, interval)]
    }

    const increment = direction === DirectionFromOrigin.Future ? 1 : -1

    let ctr = djs
    const periods = [this.periodFromDayjs(djs, interval)]
    Array.from({ length: (intervalCount ?? 1) - 1 }).forEach(() => {
      ctr = this.addIntervalToDayjs(ctr, interval, increment)
      periods.push(this.periodFromDayjs(ctr, interval))
    })
    return periods
  },

  /**
   * Create dayjs from interval origin
   *
   * @param {@link IntervalOrigin} intervalOrigin
   * @return {@link Period}
   */
  dayjsFromIntervalOrigin(intervalOrigin: IntervalOrigin): dayjs.Dayjs {
    switch (intervalOrigin.interval) {
      case Interval.Day:
        return dayjs()
          .utc()
          .year(intervalOrigin.year)
          .dayOfYear(intervalOrigin.index)
          .startOf("day")
      case Interval.Week:
        return dayjs()
          .utc()
          .year(intervalOrigin.year)
          .isoWeek(intervalOrigin.index)
          .startOf("isoWeek")
      case Interval.Month: {
        return dayjs()
          .utc()
          .year(intervalOrigin.year)
          .month(intervalOrigin.index - 1)
          .startOf("month")
      }
      case Interval.Quarter:
        return dayjs()
          .utc()
          .year(intervalOrigin.year)
          .quarter(intervalOrigin.index)
          .startOf("quarter")
      case Interval.Year: {
        return dayjs().utc().year(intervalOrigin.year).startOf("year")
      }
      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${intervalOrigin.interval}`)
    }
  },

  dayjsFromGQLDate(date: GQLDate): dayjs.Dayjs {
    const { year, month, day } = this.coerceDate(date)
    return dayjs(new Date(year, month, day))
  },

  /**
   * Create dayjs range (from, to) from interval origin
   *
   * @param {@link IntervalOrigin} intervalOrigin
   * @return {@link DayjsRange}
   */
  dayjsRangeFromIntervalOrigin(intervalOrigin: IntervalOrigin): DayjsRange {
    const { intervalCount, interval } = intervalOrigin
    const to = this.dayjsFromIntervalOrigin(intervalOrigin).endOf(
      this.unitOfTimeForInterval(interval)
    )
    const offset = (intervalCount ?? 1) - 1 // offset by 1 because intervals are 1-based
    const from = this.addIntervalToDayjs(to, interval, -offset).startOf(
      this.unitOfTimeForInterval(interval)
    )
    return { from, to }
  },

  /**
   * Create a dayjs from a timestamp or a Date
   *
   * @param {@link timestamp} value
   * @return {@link Dayjs}
   */
  dayjsFromTimestamp(value: number | Date): dayjs.Dayjs {
    const djs = value instanceof Date ? dayjs(value) : dayjs.unix(value)
    return djs.utc()
  },

  /**
   * Get a period object for today given an interval
   * e.g. if today is May 16th 2019, for an interval of month, we should get back a
   * {@link Period} with start a started time at the first second in May and ended time at
   * the last second in May.
   *
   * @param {@link Interval} interval
   * @return {@link Period}
   */
  todayPeriodForInterval(interval: Interval): Period {
    return this.periodFromIntervalOrigin(this.todayIntervalOrigin(interval))
  },

  /**
   *Determine if the provided period is the same period as the interval origin.
   *
   * @param {@link Period} period
   * @param {@link IntervalOrigin} intervalOrigin
   */
  isPeriodEqualToIntervalOrigin(period: PeriodToOriginProps, intervalOrigin: IntervalOrigin) {
    const periodDate = dayjs.unix(period.startedAt).utc()

    if (intervalOrigin.interval !== period.interval || intervalOrigin.year !== periodDate.year()) {
      return false
    }

    switch (intervalOrigin.interval) {
      case Interval.Day:
        return intervalOrigin.index === periodDate.dayOfYear()
      case Interval.Week:
        return intervalOrigin.index === periodDate.isoWeek()
      case Interval.Month:
        return intervalOrigin.index === periodDate.month() + 1
      case Interval.Year:
        // For years we ignore the index
        return true
      case Interval.Quarter:
        return intervalOrigin.index === periodDate.quarter()
      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${intervalOrigin.interval}`)
    }
  },

  /**
   * Determine if 2 dates are equal
   *
   * @param {@link GQLDate} date1
   * @param {@link GQLDate} date2
   */
  areDatesEqual(date1?: Partial<GQLDate>, date2?: Partial<GQLDate>) {
    if (!date1 || !date2) return false

    return date1.year === date2.year && date1.month === date2.month && date1.day === date2.day
  },

  /**
   * Determine if 2 period are equal
   *
   * @param {@link Period} period1
   * @param {@link Period} period2
   */
  arePeriodsEqual(period1?: TimeRange | Period, period2?: TimeRange | Period) {
    if (!period1 || !period2) return false

    const name1 = (period1 as Period)?.name
    const name2 = (period2 as Period)?.name
    const namesEqual = name1 === name2

    return (
      period1.interval === period2.interval &&
      period1.startedAt === period2.startedAt &&
      period1.endedAt === period2.endedAt &&
      namesEqual
    )
  },

  /**
   * Derive unique key for period.
   *
   *
   * @param {@link Period} period
   */
  periodUniqueKey(period: Period) {
    return `${period.interval}:${period.startedAt}:${period.endedAt}`
  },

  isPeriodYearToDate(period: Period) {
    const { startedAt, endedAt, interval } = period

    const startMmt = this.dayjsFromTimestamp(startedAt)
    if (startMmt.dayOfYear() !== 1) return false

    const endMmt = this.dayjsFromTimestamp(endedAt)
    // YTD Periods for january and Q1 are not returned as multiple
    // because we cant tell if the Period is YTD, better to disable those options in the UI and not show it
    switch (interval) {
      case Interval.Month:
        return endMmt.diff(startMmt, "month", true) > 1
      case Interval.Quarter:
        return endMmt.diff(startMmt, "quarter", true) > 1

      // Unsupported intervals
      default:
        return false
    }
  },

  /**
   * Determine if 2 interval origins are equal.
   *
   * @param {@link IntervalOrigin} intervalOrigin1
   * @param {@link IntervalOrigin} intervalOrigin2
   * @param {boolean} ignoreIntervalCount whether or not to ignore interval count in comparison
   */
  areIntervalOriginsEqual(
    intervalOrigin1: Partial<IntervalOrigin>,
    intervalOrigin2: Partial<IntervalOrigin>,
    ignoreIntervalCount = false
  ) {
    return (
      intervalOrigin1.interval === intervalOrigin2.interval &&
      intervalOrigin1.index === intervalOrigin2.index &&
      intervalOrigin1.year === intervalOrigin2.year &&
      (ignoreIntervalCount || intervalOrigin1.intervalCount === intervalOrigin2.intervalCount)
    )
  },

  /**
   * Determine if a route parameters interval origin and interval origin are equal.
   *
   * @param {Record<string, string>} parameterIntervalOrigin
   * @param {@link IntervalOrigin} intervalOrigin
   * @param {boolean} ignoreIntervalCount whether or not to ignore interval count in comparison
   */
  areRouteAndIntervalOriginEqual(
    parameterIntervalOrigin: Record<string, string>,
    intervalOrigin: Partial<IntervalOrigin>,
    ignoreIntervalCount = false
  ) {
    const interval = parameterIntervalOrigin.interval
      ? Interval[stringHelper.capitalize(parameterIntervalOrigin.interval.toString()) as Interval]
      : undefined
    return (
      interval === intervalOrigin.interval &&
      parseInt(parameterIntervalOrigin.index || "", 10) === intervalOrigin.index &&
      parseInt(parameterIntervalOrigin.year || "", 10) === intervalOrigin.year &&
      (ignoreIntervalCount ||
        (intervalOrigin.intervalCount === undefined &&
          parameterIntervalOrigin.intervalCount === undefined) ||
        parseInt(parameterIntervalOrigin.intervalCount || "", 10) === intervalOrigin.intervalCount)
    )
  },

  /**
   * Determines if interval origin is the current interval
   *
   * @param {@link IntervalOrigin} intervalOrigin in question to confirm if its the today interval.
   */
  isCurrentInterval(intervalOrigin: IntervalOrigin) {
    return this.areIntervalOriginsEqual(
      intervalOrigin,
      this.todayIntervalOrigin(intervalOrigin.interval),
      true
    )
  },

  /**
   * Determines if interval origin is for a custom range
   *
   * @param {@link IntervalOrigin} intervalOrigin in question to confirm if it is a custom range interval.
   */
  isIntervalCustomRange(intervalOrigin: IntervalOrigin) {
    return intervalOrigin?.intervalCount && intervalOrigin.intervalCount > 1
  },

  /**
   * Create a dayjs for the provided {@link IntervalOrigin} and add/subtract intervals from it.
   *
   * @param {@link IntervalOrigin} intervalOrigin to add intervals to.
   * @param numberOfIntervals number of intervals to add to interval origin
   */
  addIntervalOriginToDayjs(intervalOrigin: IntervalOrigin, numberOfIntervals: number) {
    const djs = this.dayjsFromIntervalOrigin(intervalOrigin)
    return this.addIntervalToDayjs(djs, intervalOrigin.interval, numberOfIntervals)
  },

  /**
   * Add/subtract intervals from it.
   *
   * @param {@link Dayjs} djs to add intervals to.
   * @param {@link Interval} interval
   * @param numberOfIntervals number of intervals to add to interval origin
   */
  addIntervalToDayjs(djs: dayjs.Dayjs, interval: Interval, numberOfIntervals: number) {
    if (
      [Interval.Day, Interval.Week, Interval.Month, Interval.Quarter, Interval.Year].indexOf(
        interval
      ) < 0
    ) {
      throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${interval}`)
    }

    const unit = this.unitOfTimeForInterval(interval) as dayjs.ManipulateType
    return djs.add(numberOfIntervals, unit)
  },

  /**
   * Add a specified number of intervals to a provided interval origin. Providing a negative
   * number will subtract that number of intervals.
   *
   * @param {@link IntervalOrigin} intervalOrigin
   * @param {@link number} numberOfIntervals
   * @return {@link IntervalOrigin}
   */
  addIntervalToOrigin(intervalOrigin: IntervalOrigin, numberOfIntervals: number): IntervalOrigin {
    const newOrigin = { ...intervalOrigin }
    const djs = this.dayjsFromIntervalOrigin(intervalOrigin)

    let date
    switch (intervalOrigin.interval) {
      case Interval.Day:
        date = djs.add(numberOfIntervals, "days")
        newOrigin.index = date.dayOfYear()
        break

      case Interval.Week:
        date = djs.add(numberOfIntervals, "weeks")
        newOrigin.index = date.isoWeek()
        break

      case Interval.Month:
        date = djs.add(numberOfIntervals, "months")
        newOrigin.index = date.month() + 1
        break

      case Interval.Year:
        date = djs.add(numberOfIntervals, "years")
        newOrigin.index = date.year()
        break

      case Interval.Quarter:
        date = djs.add(numberOfIntervals, "quarters")
        newOrigin.index = date.quarter()
        break

      default:
        throw new Error(`${INTERVAL_NOT_SUPPORTED_MESSAGE}: ${intervalOrigin.interval}`)
    }
    newOrigin.year = date.year()
    return newOrigin
  },

  changeIntervalInOrigin(
    intervalOrigin: IntervalOrigin,
    newInterval: Interval,
    intervalCount: number | undefined
  ) {
    const newOrigin = this.intervalOriginFromPeriodRange({
      ...this.periodFromIntervalOrigin(intervalOrigin),
      interval: newInterval,
    })
    newOrigin.intervalCount = intervalCount
    return newOrigin
  },

  /**
   * Splits the IntervalOrigin into a list of single-intervalCount IntervalOrigins.
   */
  splitIntervalOrigin(
    intervalOrigin: IntervalOrigin,
    direction: DirectionFromOrigin = DirectionFromOrigin.Past
  ): IntervalOrigin[] {
    const periods = this.periodsFromIntervalOrigin(intervalOrigin, direction)
    return periods.map((period) => this.intervalOriginFromPeriod(period))
  },

  /**
   * Splits the Period range into a list of single-intervalCount Periods.
   */
  splitPeriodRange(period?: TimeRange | null) {
    if (!period) return []
    const intervalOrigin = this.intervalOriginFromPeriodRange(period)
    return this.periodsFromIntervalOrigin(intervalOrigin, DirectionFromOrigin.Past)
  },

  /**
   * Determines if an interval origin occurred after the other interval origin.
   *
   * @param {@link IntervalOrigin} intervalOrigin subject origin
   * @param {@link IntervalOrigin} after origin to compare against
   * @return {boolean}
   */
  occurredAfterIntervalOrigin(intervalOrigin: IntervalOrigin, after: IntervalOrigin): boolean {
    return (
      intervalOrigin.year > after.year ||
      (intervalOrigin.year === after.year && intervalOrigin.index > after.index)
    )
  },

  /**
   * Determines if an interval origin occurred after the today interval origin.
   *
   * @param {@link IntervalOrigin} intervalOrigin
   * @return {boolean}
   */
  occurredAfterToday(intervalOrigin: IntervalOrigin): boolean {
    const todayOrigin = this.todayIntervalOrigin(intervalOrigin.interval)

    return this.occurredAfterIntervalOrigin(intervalOrigin, todayOrigin)
  },

  /**
   * Given a unix timestamp, create a `Date` object that is the same display time (locally) as the
   * timestamp represents in UTC.
   *
   * (e.g. unix timestamp for 12:00AM Oct 10, 2020 UTC will create a Date object for 12:00AM Oct 10, 2020 EDT)
   *
   * @param {number} unixTimestamp
   */
  coerceUTCToLocalDate(unixTimestamp: number) {
    const utc = dayjs.unix(unixTimestamp).utc()
    return new Date(utc.year(), utc.month(), utc.date())
  },

  /**
   * Given an Interval, return a number representing the intervals size relative to other valid
   * Intervals.
   *
   * (e.g. Interval.Week is small than Interval.Year)
   *
   * @param {Interval} interval
   * @return number
   */
  intervalRelativeSize(interval: Interval): number {
    switch (interval) {
      case Interval.Minute:
        return 1
      case Interval.Hour:
        return 2
      case Interval.Day:
        return 3
      case Interval.Week:
        return 4
      case Interval.Biweek:
        return 5
      case Interval.Month:
        return 6
      case Interval.Quarter:
        return 7
      case Interval.Year:
        return 8
      case Interval.IntervalNone:
        return 0
    }
  },

  /**
   * Given a JavaScript Date, return a unix timestamp at the start of the day
   * Note: Even though JS dates are in local time, we treat it as if the user is in UTC
   *
   * @param {Date} date
   * @return {number}
   */
  dateToUnixStartOfDay(date: Date): number {
    return dayjs(date).utc().startOf("day").unix()
  },

  /**
   * Given a timestamp, return a dayjs object in UTC
   *
   * @param {number} timestamp
   * @return {Dayjs}
   */
  utcDayjsFromTimestamp(timestamp: number): dayjs.Dayjs {
    return dayjs.unix(timestamp).utc()
  },

  /**
   * Return the current unix timestamp in seconds
   *
   * @return {number}
   */
  unixNowSeconds(): number {
    return Math.floor(Date.now() / 1000)
  },

  /**
   * Creates a year-to-date interval origin from a given interval origin
   * Starts from day 1 of the year and ends in the current month/quarter
   * Only supports Month and Quarter intervals
   *
   * @param {IntervalOrigin} intervalOrigin The source interval origin
   * @return {IntervalOrigin} A new interval origin representing YTD
   */
  yearToDateOrigin(intervalOrigin: IntervalOrigin): IntervalOrigin {
    const { year } = intervalOrigin

    // Calculate interval count from start of year to current period
    let { intervalCount, interval } = intervalOrigin

    // Get the current date in the specified year
    const currentDate = dayjs().utc().year(year)

    switch (interval) {
      case Interval.Quarter: {
        intervalCount = currentDate.quarter()
        break
      }

      default:
      case Interval.Month: {
        interval = Interval.Month // (default) intervals fall back to month
        intervalCount = currentDate.month() + 1 // months are 0-based
        break
      }
    }
    const index = intervalCount // last month in the range

    return {
      year,
      interval,
      index,
      intervalCount,
    }
  },
}
