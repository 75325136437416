function getGlobalValue<T>(name: string): T | undefined {
  return window?.[name as GlobalKeyType] as T | undefined
}

// Install Stream Polyfills if they are not supported by browser (e.g. Firefox, Node)
const nativeTS = getGlobalValue<TransformStream>("TransformStream")
const nativeRS = getGlobalValue<ReadableStream>("ReadableStream")
if (typeof nativeTS !== "function" || typeof nativeRS !== "function") {
  import("web-streams-polyfill").then(
    ({ TransformStream: TransformStreamPonyfill, ReadableStream: ReadableStreamPonyfill }) => {
      if (!nativeTS) {
        // eslint-disable-next-line
        // @ts-ignore
        window["TransformStream"] = TransformStreamPonyfill
      }
      if (!nativeRS) {
        // eslint-disable-next-line
        // @ts-ignore
        window["ReadableStream"] = ReadableStreamPonyfill
      }
    }
  )
}

// Install Compression Polyfills if they are not supported by browser (e.g. old Safari, Node)
const nativeCS = getGlobalValue<CompressionStream>("CompressionStream")
if (typeof nativeCS !== "function") {
  import("./compression_stream").then(({ CompressionStream: CompressionStreamPonyfill }) => {
    // eslint-disable-next-line
    // @ts-ignore
    window["CompressionStream"] = CompressionStreamPonyfill
  })
}

const nativeDS = getGlobalValue<DecompressionStream>("DecompressionStream")
if (typeof nativeDS !== "function") {
  import("./decompression_stream").then(({ DecompressionStream: DecompressionStreamPonyfill }) => {
    // eslint-disable-next-line
    // @ts-ignore
    window["DecompressionStream"] = DecompressionStreamPonyfill
  })
}
