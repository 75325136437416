import { type IntervalOrigin, type ObjectKind } from "@digits-graphql/frontend/graphql-bearer"
import { type DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import dateTimeHelper, {
  type IntervalFromRouteOptions,
} from "@digits-shared/helpers/dateTimeHelper"
import envHelper from "@digits-shared/helpers/envHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { type LocationDescriptorObject } from "history"

export const MODULE_FROM_ROUTE_REG_EXP = /[^/](.[^/]+)/g
const OPTIONAL_TIME_PARAM_NAMES = new Set(["year", "interval", "index"])
const TIME_TOKENS = "/:year/:interval/:index"
const INTENT_ID_QUERY_PARAM = "iid"
const PARAM_PREFIX_REGEX = /^@?:/
// Support capturing groups (e.g. :param(foo|bar)).
const GROUP_PARAM_PREFIX_REGEX = /:[^(]+\(/

export enum DetailsViewAction {
  Push = "Push",
  Replace = "Replace",
  /** Mark a DetailsView as "protected" to prevent it from appearing in the details view "back" button */
  Protected = "Protected",
}

export interface GeneratePathOptions {
  location: DigitsLocation
  currentRoute: DigitsRoute
  parameters?: RouteParameters
  includedQueryParams?: IncludedQueryParams
  excludedQueryParams?: string[]
  contextOrigin?: IntervalOrigin
}

// Info derived from a route and its current params that identifies
// a shared object by its ID and its kind.
export interface SharedObjectInfo {
  kind: ObjectKind
  id: string
  legalEntitySlug: string
}

type RouteConfigModuleName = (params: Record<string, string>) => string

export type RouteParams = Record<string, string | number | boolean | undefined | null>

/**
 * Config interface for a route
 */
export interface RouteConfig {
  parameterizedPath: string
  parentRoute?: StaticRouteConfig
  overrideParentParameterizedPath?: boolean
  timeParameterOptions?: IntervalFromRouteOptions
  detailsViewAction?: DetailsViewAction
  // Provides SharedObjectInfo for this route and its current params.
  // Having an implementation defines a route as representing an object that can be shared.
  getSharedObjectInfo?: (routeParams: Record<string, string>) => SharedObjectInfo | undefined
  moduleName?: string | RouteConfigModuleName
}

type StaticRouteConfig = RouteConfig | string

export type RouteParameters = Record<string, string | boolean | number | null | undefined>

/**
 * Interface for loaded routes
 */
export type Routes<StaticRoutesClass extends StaticRoutes> = StaticRoutes & {
  [key in keyof StaticRoutesClass]: DigitsRoute
}

/**
 * Used as the parent class for all static route classes any webapp. Contains shared methods for
 * all static route objects to get.
 */
export class StaticRoutes {
  /**
   * Get all paths of routes that have time tokens included in their paths.
   * @return {string[]}
   */
  timeTokenPaths(): string[] {
    return Object.values(this).reduce((timeTokenPaths: string[], route: DigitsRoute) => {
      if (route.config.timeParameterOptions) timeTokenPaths.push(route.parameterizedPath)
      return timeTokenPaths
    }, [])
  }

  /**
   * Get all paths of routes that have object sharing.
   * @return {string[]}
   */
  objectSharingPaths(): string[] {
    return Object.values(this).reduce((objectSharingPaths: string[], route: DigitsRoute) => {
      if (route.sharedObjectInfo()) {
        // Take the time tokens off the paths so that email links which don't specify a specific
        // interval origin are still recognized in the route matchers that use these paths.
        objectSharingPaths.push(route.parameterizedPath.replace(TIME_TOKENS, ""))
      }
      return objectSharingPaths
    }, [])
  }

  pathIsDetailsView(locationName: string): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return !!this[locationName]?.config.detailsViewAction
  }
}

/**
 * Interface for the StaticRoutes constructor object.
 */
interface StaticRoutesConstructor<T extends StaticRoutes> {
  new (): T
}

/**
 * Takes a class of route properties and creates an object with keys of the route name and
 * values of a {@link DigitsRoute} class.
 * @param {any} StaticRoutesClass
 * @return { [key in keyof RouteClass]: Route }
 */
export function createRoutes<SRC extends StaticRoutes>(
  StaticRoutesClass: StaticRoutesConstructor<SRC>
): Routes<SRC> {
  const staticRouteConfig = new StaticRoutesClass() as Routes<SRC>
  const staticRoutes = new StaticRoutesClass() as Routes<SRC>

  // Dynamically build key value pairs of route name given and a `Route` class
  // constructed with the given routes url scheme
  objectHelper.keysOf(staticRoutes).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    staticRoutes[key] = new DigitsRoute(
      key.toString(),
      staticRouteConfig[key],
      staticRouteConfig,
      staticRoutes
    )
  })

  return staticRoutes
}

export type IncludedQueryParams = "includeAllQueryParams" | "omitAllQueryParams" | string[]

type DigitsRouteConfig = Omit<RouteConfig, "parentRoute"> & { parentRoute?: DigitsRoute }

/**
 * Wraps a provide route that may be parameterized with convenience methods
 * for returning the url with or without the parameters replace.
 */
export class DigitsRoute {
  readonly name: string
  readonly parameterizedPath: string
  readonly config: DigitsRouteConfig
  readonly moduleName?: RouteConfigModuleName

  constructor(
    name: string,
    config: string | RouteConfig,
    staticRouteConfigs?: Routes<StaticRoutes>,
    constructedRoutes?: Routes<StaticRoutes>
  ) {
    if (typeof config === "string") {
      this.config = { parameterizedPath: config }
      this.parameterizedPath = config
    } else {
      // Assume a more complex route object with configurations
      const parentKey = parentRouteKey(name, staticRouteConfigs)
      const parentRoute = parentKey ? (constructedRoutes?.[parentKey] as DigitsRoute) : undefined
      this.config = assembleConfig(name, config, parentRoute)
      this.parameterizedPath = assemblePath(this.config, parentRoute)
      this.config.parameterizedPath = this.parameterizedPath
    }

    // If there is no module name specified fallback to the default module name.
    if (!this.config.moduleName) {
      let fallbackModuleName = this.config.parameterizedPath
      const fallbackModuleNames = this.config.parameterizedPath.match(MODULE_FROM_ROUTE_REG_EXP)
      if (fallbackModuleNames?.length) {
        fallbackModuleName = fallbackModuleNames[1] ?? fallbackModuleNames[0]
      }
      this.config.moduleName = fallbackModuleName
    }

    this.name = name
    const { moduleName } = this.config
    this.moduleName = typeof moduleName === "string" ? () => moduleName : moduleName

    if (this.config.timeParameterOptions) {
      this.parameterizedPath = `${this.parameterizedPath}${TIME_TOKENS}`
    }
  }

  /**
   * Put values into a tokenized url. Any keys not found as tokens in url
   * will be appended as query string.
   *
   * @param {any} parameters Key value pair of parameters.
   * @return {string}
   */
  generate(parameters: RouteParameters = {}, contextOrigin?: IntervalOrigin) {
    // Clone params so we don't mutate the object passed
    let parameterValues = { ...parameters }
    let routeWithParams = this.parameterizedPath

    if (this.config.timeParameterOptions) {
      // If we are trying to generate a route that has time tokens, gracefully handle the route if
      // incomplete or invalid interval origin is passed by using the default origin based on route.
      if (!dateTimeHelper.isValidIntervalOriginInRoute(parameterValues)) {
        const defaultOrigin = dateTimeHelper.defaultIntervalOriginFromRoute(
          this.config.timeParameterOptions?.defaultIntervalOrigin,
          this.config.timeParameterOptions?.interval
        )

        const intervalOrigin =
          contextOrigin ??
          dateTimeHelper.intervalOriginFromRoute(
            parameterValues,
            defaultOrigin,
            this.config.timeParameterOptions
          )

        parameterValues = { ...parameterValues, ...intervalOrigin }
      }

      // Ensure interval origin is always downcased for vanity's sake
      if (parameterValues.interval) {
        const interval = parameterValues.interval as string
        parameterValues.interval = interval.toLowerCase()
      }
    }

    // Used to track what params get used in the url so we
    // can put remaining parameter values as a query string
    const unusedParams = { ...parameterValues }

    Object.keys(parameterValues).forEach((token) => {
      const previousRouteWithParams = routeWithParams
      const value = parameterValues[token]
      if (value === undefined || value === null) {
        delete unusedParams[token]
        return
      }

      routeWithParams = routeWithParams.replace(new RegExp(`:${token}[^/]*`), value.toString())

      // If routes changed, this means we replaced a parameter with a value, so remove
      // from our params copy being used to tracked used params
      if (previousRouteWithParams !== routeWithParams) delete unusedParams[token]
    })

    // If unused params, create query string and append to end of route
    if (Object.keys(unusedParams).length) {
      routeWithParams = `${routeWithParams}?${new URLSearchParams(
        unusedParams as Record<string, string>
      ).toString()}`
    }

    return routeWithParams
  }

  /**
   * Generates a route using values that are in the current url for any parameters required.
   * First checks the parameters passed to replace tokenized portions of the url, otherwise
   * fall back to what string is in that slot from the current url. Any unused parameters
   * passed will be added as a query string.
   *
   * @param {Object} parameters Key value pair of parameters.
   * @param {boolean} includedQueryParams Whether or not to include query parameters
   *  from current route
   * @param {Object} excludedQueryParams Key value pair of query parameters to exclude.
   * @return {string}
   */
  generateFromCurrentPath(
    parameters: RouteParameters = {},
    includedQueryParams: IncludedQueryParams = "omitAllQueryParams",
    excludedQueryParams: string[] = []
  ) {
    // Clone params so we don't mutate the object passed
    const parameterValues = { ...parameters }
    const currentPath = this.currentPath()

    const routeParts = this.parameterizedPath.split("/")
    const pathParts = currentPath.split("/")
    for (let i = 1; i < routeParts.length && i < pathParts.length; i += 1) {
      const routePart = routeParts[i] || ""
      const pathPart = pathParts[i] || ""
      // if this route part is a param (:foo), set it to `parameterValues` if not present
      if (PARAM_PREFIX_REGEX.test(routePart)) {
        // Support capturing groups (e.g. :param(foo|bar)).
        if (GROUP_PARAM_PREFIX_REGEX.test(routePart)) {
          const routeParam = routePart.replace(/:([^(]+).*/, (match, p1) => p1)
          if (!parameterValues[routeParam]) {
            parameterValues[routeParam] = pathParts[i]
          }
        } else {
          const routeParam = routePart.replace(/^@?:/, "")
          if (!parameterValues[routeParam]) {
            parameterValues[routeParam] = pathPart.replace(/^@/, "")
          }
        }
        // if it is not a route param, check if both parts match, otherwise break out loop
      } else if (routeParts[i] !== pathParts[i]) {
        break
      }
    }

    // Display the key/value pairs
    let paramsToGenerateWith: RouteParameters
    if (includedQueryParams !== "omitAllQueryParams") {
      const queryParams: { [key: string]: string } = {}
      this.currentQueryParams().forEach((value, key) => {
        const notInParameterValues = parameterValues[key] === undefined
        if (Array.isArray(includedQueryParams)) {
          if (includedQueryParams.indexOf(key) !== -1 && notInParameterValues) {
            queryParams[key] = value
          }
        } else if (excludedQueryParams.indexOf(key) === -1 && notInParameterValues) {
          queryParams[key] = value
        }
      })
      paramsToGenerateWith = { ...parameterValues, ...queryParams }
    } else {
      paramsToGenerateWith = parameterValues
    }

    return this.generate(paramsToGenerateWith)
  }

  /**
   * Prototype path generation method which requires providing the current location and current route
   * objects, to more accurately map current path parameters into the path being generated for this
   * other router.
   */
  generatePathFromCurrentRoute({
    location,
    currentRoute,
    parameters = {},
    includedQueryParams = "omitAllQueryParams",
    excludedQueryParams = [],
    contextOrigin,
  }: GeneratePathOptions) {
    const currentPath = location.pathname

    const thisRouteParts = this.parameterizedPath.split("/")
    const thisRouteParamNames = thisRouteParts.flatMap((part) => {
      // Support capturing groups (e.g. :param(foo|bar)).
      if (GROUP_PARAM_PREFIX_REGEX.test(part)) {
        const match = part.match(/:([^(]+).*/)?.[1]
        return match ? [match] : []
      }
      return PARAM_PREFIX_REGEX.test(part) ? [part.replace(PARAM_PREFIX_REGEX, "")] : []
    })

    const currentRouteParams = currentRoute.getParametersFromPath(currentPath)

    // Track working params in Maps so that we can easily remove items as we
    // account for their usage.
    const inputParamsMap = new Map(Object.entries(parameters))
    const currentRouteParamsMap = new Map(Object.entries(currentRouteParams))

    const queryParams: Record<string, string> = {}
    let nextParams: RouteParameters = {}

    // Map values needed by this route by name from the other param sources:
    //   - provided input params
    //   - values from the current route/path
    //   - values from the current query params)
    // in that order of precedence.
    thisRouteParamNames.forEach((thisRouteParamName) => {
      const inputParamValue = inputParamsMap.get(thisRouteParamName)
      inputParamsMap.delete(thisRouteParamName)

      const currentRouteParamValue = currentRouteParamsMap.get(thisRouteParamName)
      currentRouteParamsMap.delete(thisRouteParamName)

      nextParams[thisRouteParamName] =
        inputParamValue || currentRouteParamValue || queryParams[thisRouteParamName]
    })

    // Handle carrying-over of query params, following include/exclude lists, if
    // the options allow it.
    if (includedQueryParams !== "omitAllQueryParams") {
      Object.entries(location.queryParams || {}).forEach(([key, value]) => {
        if (Array.isArray(includedQueryParams)) {
          if (includedQueryParams.includes(key)) {
            queryParams[key] = value
          }
        } else if (!excludedQueryParams.includes(key)) {
          queryParams[key] = value
        }
      })
    }

    // Filter remaining input parameters against the excludedQueryParams list before
    // allowing them to become query params.
    excludedQueryParams.forEach((excludedParam) => inputParamsMap.delete(excludedParam))
    inputParamsMap.delete("__typename")

    // The next query params are:
    //   - the params which were directly mapped through by name
    //   - any query params which were in the current URL, which were allowed to be included
    //   - any remaining input params which were not excluded from becoming query params
    nextParams = {
      ...nextParams,
      ...queryParams,
      ...Object.fromEntries(inputParamsMap.entries()),
    }

    // If nextParams contains any keys which were given a value of undefined, a needed param
    // binding was missing. Print a warning indicating which were missing.
    const missingDestParamNames = Object.entries(nextParams)
      // Time parameters are always optional. Don't warn about them being missing
      .filter(([key, _]) => !OPTIONAL_TIME_PARAM_NAMES.has(key))
      .filter(([_, value]) => value === undefined)
      .map(([key, _]) => key)
    if (missingDestParamNames.length) {
      const msg = `The following parameters were not provided for route "${
        this.name
      }": ${missingDestParamNames.join(", ")}`
      console.error(msg)
      if (envHelper.isDevelopment()) {
        throw new Error(msg)
      }
    }

    return this.generate(nextParams, contextOrigin)
  }

  /**
   * Generates a route using values that are in the current url for any parameters required.
   * Some as `generateFromCurrentPath` but additionally persists the location state.
   *
   * @param {Object} parameters Key value pair of parameters.
   * @param {DigitsLocation} location object for derive the state.
   * @param {boolean} includedQueryParams Whether or not to include query parameters
   *  from current route
   * @param {Object} excludedQueryParams Key value pair of query parameters to exclude.
   * @return {string}
   */
  generateFromCurrentPathWithState(
    parameters = {},
    location: DigitsLocation,
    includedQueryParams: IncludedQueryParams = "omitAllQueryParams",
    excludedQueryParams: string[] = []
  ): LocationDescriptorObject {
    return {
      pathname: this.generateFromCurrentPath(parameters, includedQueryParams, excludedQueryParams),
      state: location.state,
    }
  }

  /**
   * Generate a parameters object that takes the tokenized portions of a route and finds their
   * values in a provided pathname.
   * e.g. route /foo/:bar/:baz, path => /foo/forks/spoons => { bar: "forks", baz: "spoons" }
   *
   * @param {@link string} pathname Pathname that parameters need to be found in.
   * @return {Object} Key value paired object of token to value found in url
   */
  getParametersFromPath(pathname: string) {
    const parameterValues = {} as Record<string, string>

    const paramPrefix = /^@?:/
    const atTokenPrefix = /^@:/
    const routeParts = this.parameterizedPath.split("/")
    const pathParts = pathname.split("/")

    for (let i = 1; i < routeParts.length && i < pathParts.length; i += 1) {
      const routePart = routeParts[i] || ""
      const pathPart = pathParts[i] || ""
      // if this route part is a param (:foo), set it to `parameterValues`.
      // if the route has a `@:` token, and the path has an `@` strip it, otherwise do not match it
      const hasAtToken = atTokenPrefix.test(routePart)
      const validAtTokenValue = hasAtToken && /^@/.test(pathPart)
      // Support capturing groups (e.g. :param(foo|bar)).
      if (GROUP_PARAM_PREFIX_REGEX.test(routePart)) {
        const routeParam = routePart.replace(/:([^(]+).*/, (match, p1) => p1)
        if (!parameterValues[routeParam]) {
          parameterValues[routeParam] = pathPart
        }
      } else if (paramPrefix.test(routePart) && (!hasAtToken || validAtTokenValue)) {
        const routeParam = routePart.replace(/^@?:/, "")
        parameterValues[routeParam] = pathPart.replace(/^@/, "")
      }
    }

    return parameterValues
  }

  // Used for test mocking
  currentPath = () => window.location.pathname

  // Used for test mocking
  currentQueryParams = () => new URLSearchParams(window.location.search.slice(1))

  toString() {
    return this.parameterizedPath.toString()
  }

  // @deprecated use {@link isRouteOrChildOfRoute} instead.
  isCurrentLocation(location: DigitsLocation) {
    return location.name === this.name
  }

  // @deprecated use {@link isRouteOrChildOfRoute} instead.
  isCurrentParentLocation(location: DigitsLocation): boolean {
    return (
      !!location.name.match(new RegExp(`^${this.name}`)) ||
      !!location.pathname.match(new RegExp(`^${this.generateFromCurrentPath()}`))
    )
  }

  // Is current route
  isRoute(route: DigitsRoute | undefined): boolean {
    if (!route) {
      console.error("No route passed to isRoute of route:", this.name)
      return false
    }

    return route.name === this.name
  }

  // Is current route or is a child of the provided route
  isRouteOrChildOfRoute(route: DigitsRoute | undefined): boolean {
    if (!route) {
      console.error("No route passed to isRouteOrChildOfRoute of route:", this.name)
      return false
    }

    return (
      this.isRoute(route) ||
      (route.config.parentRoute ? this.isRouteOrChildOfRoute(route.config.parentRoute) : false)
    )
  }

  sharedObjectInfo() {
    return this.config.getSharedObjectInfo?.(this.getParametersFromPath(this.currentPath()))
  }

  sharingIntentId() {
    return (
      this.sharedObjectInfo() && (this.currentQueryParams().get(INTENT_ID_QUERY_PARAM) || undefined)
    )
  }

  /**
   * Finds the nearest route to this one that does not contain any path parameters. Note: the LE slug does
   * not count as a path parameter for this purpose. This is useful when changing legal entities. We'd like
   * to stay on close to the same page, but trying to load a route with an ID that doesn't belong to the
   * new LE is not useful.
   */
  nearestUnparameterizedRoute(): DigitsRoute | undefined {
    if (!this.config.parameterizedPath.match(/[^@]:/)) {
      return this
    }
    if (!this.config.parentRoute) {
      return undefined
    }
    return this.config.parentRoute.nearestUnparameterizedRoute()
  }
}

const parentRouteKey = (name: string, staticRouteConfigs?: Routes<StaticRoutes>) => {
  if (!staticRouteConfigs) return

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const parentRouteConfig: StaticRouteConfig | undefined = staticRouteConfigs[name]?.parentRoute

  return objectHelper.keysOf(staticRouteConfigs).find((keyToMatchParent) => {
    const potentialParentConfig: StaticRouteConfig | undefined =
      staticRouteConfigs[keyToMatchParent]

    return potentialParentConfig && parentRouteConfig && potentialParentConfig === parentRouteConfig
  })
}

/**
 * Creates a composite config that is comprised of the fully constructed parent DigitsRoute config
 * and the config that will be constructed into a DigitsRoute.
 */
const assembleConfig = (
  name: string,
  config: RouteConfig,
  parentRoute?: DigitsRoute
): DigitsRouteConfig => {
  const parentConfig = parentRoute?.config || {}
  const { parentRoute: _, ...restConfig } = config
  return {
    ...parentConfig,
    // Override parent parameterized path should not be inherited from parent
    overrideParentParameterizedPath: undefined,
    ...restConfig,
    parentRoute,
  }
}

/**
 * Assemble a parameterizedPath to be inclusive of the config parameterizedPath and
 * all parent parameterizedPath values.
 *
 * e.g. child /one -> parent /two -> grandparent /three = /one/two/three
 */
const assemblePath = (config: RouteConfig, parentRoute?: DigitsRoute): string => {
  if (!config.parentRoute || !parentRoute || config.overrideParentParameterizedPath) {
    return config.parameterizedPath
  }

  return parentRoute.config.parameterizedPath + config.parameterizedPath
}
