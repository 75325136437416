import * as React from "react"
import { useLogOutMutation } from "@digits-graphql/admin/graphql-public"
import { SvgDigitsLogoNoBackground } from "@digits-shared/components/SVGIcons/digits/DigitsLogoNoBackground.svg"
import { CenteredDialogContainer } from "@digits-shared/components/UI/Elements/Container"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import useConstant from "@digits-shared/hooks/useConstant"
import useSession from "@digits-shared/hooks/useSession"
import { Theme } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import styled, { ThemeContext } from "styled-components"
import type AdminSession from "src/admin/session/AdminSession"

/*
  STYLES
*/

const LogOutStyled = styled.div`
  padding-top: 90px;
`

const LogOutContainer = styled(CenteredDialogContainer)`
  margin: 0 auto;
`

const Logo = styled(SvgDigitsLogoNoBackground)`
  width: 150px;
  height: 150px;
  fill: ${colors.white};
  margin-bottom: 35px;
`

const H2Styled = styled.div`
  font-size: 22px;
  margin-bottom: 50px;
`

/*
  COMPONENTS
*/

const LogOut: React.FC = () => {
  const session = useSession<AdminSession>()

  const onSuccessfulLogOut = React.useCallback(async () => {
    await session.clear()
  }, [session])

  const [logOut] = useLogOutMutation({
    onCompleted: onSuccessfulLogOut,
    context: { publicAPI: true },
  })

  const onClick = React.useCallback(() => logOut(), [logOut])
  const theme = useConstant({ mode: Theme.Dark })
  return (
    <ThemeContext.Provider value={theme}>
      <LogOutStyled>
        <LogOutContainer>
          <Logo />
          <H2Styled>Are you sure you want to sign out?</H2Styled>
          <DigitsButton width={360} onClick={onClick}>
            Sign Out
          </DigitsButton>
        </LogOutContainer>
      </LogOutStyled>
    </ThemeContext.Provider>
  )
}
export default LogOut
